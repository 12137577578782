import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { 
  addCampo, 
  updateCampo,
  getCamposBySeccion
} from '../../slices/formularioSlice';
import PropiedadesAdicionalesForm from './PropiedadesAdicionalesForm';
import CondicionalMostrarForm from './CondicionalMostrarForm';

interface CampoFormProps {
  seccionId: string;
  campoId: string | null;
  isEditMode: boolean;
  onClose: () => void;
}

const CampoForm: React.FC<CampoFormProps> = ({
  seccionId,
  campoId,
  isEditMode,
  onClose
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { secciones, loading } = useSelector((state: RootState) => state.formulario);
  
  // Encontrar la sección actual
  const seccion = secciones.find(s => s.id === seccionId);
  
  // Estados del formulario
  const [tipo, setTipo] = useState('text');
  const [etiqueta, setEtiqueta] = useState('');
  const [nombre, setNombre] = useState('');
  const [valorPredeterminado, setValorPredeterminado] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [requerido, setRequerido] = useState(false);
  const [orden, setOrden] = useState(0);
  const [ancho, setAncho] = useState(12); // 12 es el ancho completo en grid
  const [condicionalMostrar, setCondicionalMostrar] = useState('');
  const [propiedadesAdicionales, setPropiedadesAdicionales] = useState('');
  
  const [formErrors, setFormErrors] = useState({
    tipo: '',
    etiqueta: '',
    nombre: '',
    orden: ''
  });

  // Tipos de campo disponibles
  const tiposCampo = [
    { value: 'texto', label: 'Texto' },
    { value: 'texto_largo', label: 'Área de texto' },
    { value: 'numero', label: 'Número' },
    { value: 'rango', label: 'Rango' },
    { value: 'email', label: 'Email' },
    { value: 'telefono', label: 'Teléfono' },
    { value: 'fecha', label: 'Fecha' },
    { value: 'hora', label: 'Hora' },
    { value: 'checkbox', label: 'Casillas de verificación' },
    { value: 'radio', label: 'Radio buttons' },
    { value: 'select', label: 'Selector' },
    { value: 'select_multiple', label: 'Selector Múltiple' },
    { value: 'archivo', label: 'Archivo' },
    { value: 'firma', label: 'Firma' },
    { value: 'puntuacion', label: 'Puntuación' },
    { value: 'etiqueta', label: 'Etiqueta' },
    { value: 'seccion', label: 'Sección' },
    { value: 'tabla_puntuaciones', label: 'Tabla de Puntuaciones' }
  ];

  // Ejemplos de propiedades adicionales por tipo de campo
  const ejemplosPropiedadesAdicionales: { [key: string]: string } = {
    rango: JSON.stringify({
      min: 0,
      max: 10,
      paso: 1,
      etiquetas: {
        "0": "Muy insatisfecho",
        "5": "Neutral",
        "10": "Muy satisfecho"
      }
    }, null, 2),
    tabla_puntuaciones: JSON.stringify({
      filas: [
        { id: "fila1", etiqueta: "Atención al cliente" },
        { id: "fila2", etiqueta: "Calidad del servicio" }
      ],
      columnas: [
        { valor: "1", etiqueta: "Muy malo" },
        { valor: "2", etiqueta: "Malo" },
        { valor: "3", etiqueta: "Regular" },
        { valor: "4", etiqueta: "Bueno" },
        { valor: "5", etiqueta: "Muy bueno" }
      ]
    }, null, 2),
    archivo: JSON.stringify({
      tiposPermitidos: ["image/jpeg", "image/png", "application/pdf"],
      tamañoMaximo: 5242880,
      cantidadMaxima: 3,
      rutaGuardado: "/uploads/documentos"
    }, null, 2),
    firma: JSON.stringify({
      ancho: 500,
      alto: 200,
      color: "#000000",
      grosorLinea: 2
    }, null, 2),
    puntuacion: JSON.stringify({
      maximo: 5,
      estrellas: true,
      mostrarNumeros: true,
      color: "#FFD700"
    }, null, 2),
    select: JSON.stringify({
      busqueda: true,
      placeholder: "Seleccione una opción",
      permitirLimpiar: true,
      mostrarTodos: true
    }, null, 2),
    select_multiple: JSON.stringify({
      busqueda: true,
      placeholder: "Seleccione varias opciones",
      permitirLimpiar: true,
      mostrarTodos: true,
      maximoSeleccion: 5
    }, null, 2),
    checkbox: JSON.stringify({
      alineacion: "horizontal",
      mostrarTodos: true,
      maximoSeleccion: 3
    }, null, 2),
    radio: JSON.stringify({
      alineacion: "vertical",
      permitirLimpiar: true
    }, null, 2),
    texto: JSON.stringify({
      minCaracteres: 3,
      maxCaracteres: 50,
      patron: "^[a-zA-Z0-9]+$",
      mensajeError: "Solo se permiten letras y números"
    }, null, 2),
    texto_largo: JSON.stringify({
      minCaracteres: 10,
      maxCaracteres: 500,
      filas: 4,
      autoajustar: true
    }, null, 2),
    numero: JSON.stringify({
      min: 0,
      max: 100,
      decimales: 2,
      separadorMiles: ",",
      separadorDecimal: "."
    }, null, 2),
    email: JSON.stringify({
      dominiosPermitidos: ["@empresa.com", "@cliente.com"],
      validacionPersonalizada: true
    }, null, 2),
    telefono: JSON.stringify({
      formato: "(XXX) XXX-XXXX",
      pais: "MX",
      validacionInternacional: true
    }, null, 2),
    fecha: JSON.stringify({
      formato: "DD/MM/YYYY",
      min: "2024-01-01",
      max: "2024-12-31",
      mostrarCalendario: true
    }, null, 2),
    hora: JSON.stringify({
      formato: "24h",
      intervalo: 30,
      mostrarReloj: true
    }, null, 2),
    etiqueta: JSON.stringify({
      color: "#FF0000",
      tamaño: "16px",
      negrita: true,
      cursiva: false
    }, null, 2),
    seccion: JSON.stringify({
      estilo: "card",
      colorFondo: "#F5F5F5",
      borde: true,
      espaciado: 20
    }, null, 2)
  };

  useEffect(() => {
    if (isEditMode && campoId && seccion) {
      // Buscar el campo en la sección actual
      const campo = seccion.campos?.find(c => c.id === campoId);
      
      if (campo) {
        setTipo(campo.tipo);
        setEtiqueta(campo.etiqueta);
        setNombre(campo.nombre);
        setValorPredeterminado(campo.valorPredeterminado || '');
        setPlaceholder(campo.placeholder || '');
        setRequerido(campo.requerido);
        setOrden(campo.orden);
        setAncho(campo.ancho);
        setCondicionalMostrar(campo.condicionalMostrar || '');
        setPropiedadesAdicionales(campo.propiedadesAdicionales ? JSON.stringify(campo.propiedadesAdicionales) : '');
      }
    } else {
      // Valores por defecto para un nuevo campo
      const camposEnSeccion = seccion?.campos || [];
      const maxOrden = camposEnSeccion.reduce((max, campo) => 
        campo.orden > max ? campo.orden : max, 0);
      
      setTipo('text');
      setEtiqueta('');
      setNombre('');
      setValorPredeterminado('');
      setPlaceholder('');
      setRequerido(false);
      setOrden(maxOrden + 1);
      setAncho(12);
      setCondicionalMostrar('');
      setPropiedadesAdicionales('');
    }
  }, [isEditMode, campoId, seccion]);

  const validateForm = () => {
    const errors = {
      tipo: '',
      etiqueta: '',
      nombre: '',
      orden: ''
    };
    let isValid = true;

    if (!tipo) {
      errors.tipo = 'El tipo de campo es requerido';
      isValid = false;
    }

    if (!etiqueta.trim()) {
      errors.etiqueta = 'La etiqueta es requerida';
      isValid = false;
    }

    if (!nombre.trim()) {
      errors.nombre = 'El nombre es requerido';
      isValid = false;
    } else if (!/^[a-zA-Z][a-zA-Z0-9_]*$/.test(nombre)) {
      errors.nombre = 'El nombre debe empezar con una letra y contener solo letras, números y guion bajo';
      isValid = false;
    }

    if (orden <= 0) {
      errors.orden = 'El orden debe ser mayor a 0';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    // Validar propiedades adicionales si existen
    let propiedadesAdicionalesObj = null;
    if (propiedadesAdicionales.trim()) {
      try {
        propiedadesAdicionalesObj = JSON.parse(propiedadesAdicionales);
      } catch (error) {
        alert('El formato de las propiedades adicionales no es válido. Debe ser un objeto JSON válido.');
        return;
      }
    }

    if (isEditMode && campoId) {
      dispatch(updateCampo({
        updateCampoId: campoId,
        tipo,
        etiqueta,
        nombre,
        valorPredeterminado,
        placeholder,
        requerido,
        orden,
        ancho,
        condicionalMostrar,
        propiedadesAdicionales: propiedadesAdicionalesObj
      }));
    } else {
      dispatch(addCampo({
        seccionId,
        tipo,
        etiqueta,
        nombre,
        valorPredeterminado,
        placeholder,
        requerido,
        orden,
        ancho,
        condicionalMostrar,
        propiedadesAdicionales: propiedadesAdicionalesObj
      }));
    }

    // Refrescar los campos
    dispatch(getCamposBySeccion(seccionId));

    onClose();
  };

  // Formatear valor del slider para mostrar
  const valuetext = (value: number) => {
    const anchosTexto: {[key: number]: string} = {
      3: '1/4',
      4: '1/3',
      6: '1/2',
      8: '2/3',
      9: '3/4',
      12: 'Ancho completo'
    };
    
    return anchosTexto[value] || `${value}/12`;
  };

  // Función para cargar ejemplo de propiedades adicionales
  const cargarEjemploPropiedades = () => {
    if (ejemplosPropiedadesAdicionales[tipo]) {
      setPropiedadesAdicionales(ejemplosPropiedadesAdicionales[tipo]);
    }
  };

  // Manejar cambio de tipo para cambiar el nombre normalizado
  const handleTipoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const nuevoTipo = e.target.value;
    setTipo(nuevoTipo);
    
    // Si el nombre aún no ha sido personalizado, actualizarlo automáticamente
    if (!nombre || nombre === tipo.toLowerCase().replace(/\s+/g, '_')) {
      setNombre(nuevoTipo.toLowerCase().replace(/\s+/g, '_'));
    }

    // Limpiar propiedades adicionales al cambiar el tipo
    setPropiedadesAdicionales('');
  };

  // Obtener todos los campos de la sección actual
  const camposDisponibles = seccion?.campos?.filter(c => c.id !== campoId) || [];

  if (loading) return <div className="p-4">Cargando...</div>;

  return (
    <form onSubmit={handleSubmit} className="p-8 bg-gradient-to-br from-white to-gray-50 rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.06)] backdrop-blur-sm border border-gray-100">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div className="col-span-1">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="tipo-campo">
              Tipo de Campo
            </label>
            <select
              id="tipo-campo"
              value={tipo}
              onChange={handleTipoChange}
              className={`w-full p-3 border-2 rounded-xl transition-all duration-300 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 ${
                formErrors.tipo ? 'border-red-300 bg-red-50' : 'border-gray-200 hover:border-blue-200'
              }`}
            >
              <option value="">Selecciona un tipo</option>
              {tiposCampo.map((opcion) => (
                <option key={opcion.value} value={opcion.value}>
                  {opcion.label}
                </option>
              ))}
            </select>
            {formErrors.tipo && (
              <p className="mt-2 text-xs text-red-500 font-medium">{formErrors.tipo}</p>
            )}
          </div>
        </div>
        <div className="col-span-1">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="nombre-campo">
              Nombre
            </label>
            <input
              id="nombre-campo"
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              className={`w-full p-3 border-2 rounded-xl transition-all duration-300 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 ${
                formErrors.nombre ? 'border-red-300 bg-red-50' : 'border-gray-200 hover:border-blue-200'
              }`}
            />
            {formErrors.nombre ? (
              <p className="mt-2 text-xs text-red-500 font-medium">{formErrors.nombre}</p>
            ) : (
              <p className="mt-2 text-xs text-gray-500">Identificador único</p>
            )}
          </div>
        </div>
        <div className="col-span-1">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="etiqueta-campo">
              Etiqueta
            </label>
            <input
              id="etiqueta-campo"
              type="text"
              value={etiqueta}
              onChange={(e) => setEtiqueta(e.target.value)}
              className={`w-full p-3 border-2 rounded-xl transition-all duration-300 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 ${
                formErrors.etiqueta ? 'border-red-300 bg-red-50' : 'border-gray-200 hover:border-blue-200'
              }`}
            />
            {formErrors.etiqueta ? (
              <p className="mt-2 text-xs text-red-500 font-medium">{formErrors.etiqueta}</p>
            ) : (
              <p className="mt-2 text-xs text-gray-500">Texto visible</p>
            )}
          </div>
        </div>

        <div className="col-span-1">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="placeholder-campo">
              Placeholder
            </label>
            <input
              id="placeholder-campo"
              type="text"
              value={placeholder}
              onChange={(e) => setPlaceholder(e.target.value)}
              className="w-full p-3 border-2 border-gray-200 rounded-xl transition-all duration-300 hover:border-blue-200 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
            />
            <p className="mt-2 text-xs text-gray-500">Texto de ayuda</p>
          </div>
        </div>

        <div className="col-span-1">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="valor-predeterminado">
              Valor Inicial
            </label>
            <input
              id="valor-predeterminado"
              type="text"
              value={valorPredeterminado}
              onChange={(e) => setValorPredeterminado(e.target.value)}
              className="w-full p-3 border-2 border-gray-200 rounded-xl transition-all duration-300 hover:border-blue-200 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
            />
            <p className="mt-2 text-xs text-gray-500">Valor por defecto</p>
          </div>
        </div>

        <div className="col-span-1">
          <div className="mb-6">
            <label className="inline-flex items-center p-3 border-2 border-gray-200 rounded-xl hover:border-blue-200 transition-all duration-300 cursor-pointer bg-white hover:bg-gray-50">
              <input
                type="checkbox"
                checked={requerido}
                onChange={(e) => setRequerido(e.target.checked)}
                className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-400"
              />
              <span className="ml-2 text-sm font-medium text-gray-700">Obligatorio</span>
            </label>
          </div>
        </div>

        <div className="col-span-1">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="orden-campo">
              Orden
            </label>
            <input
              id="orden-campo"
              type="number"
              min="1"
              value={orden}
              onChange={(e) => setOrden(parseInt(e.target.value))}
              className={`w-full p-3 border-2 rounded-xl transition-all duration-300 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 ${
                formErrors.orden ? 'border-red-300 bg-red-50' : 'border-gray-200 hover:border-blue-200'
              }`}
            />
            {formErrors.orden && (
              <p className="mt-2 text-xs text-red-500 font-medium">{formErrors.orden}</p>
            )}
          </div>
        </div>

        <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-3">Ancho del campo</label>
            <div className="px-4 py-3 bg-white rounded-xl border-2 border-gray-200 shadow-sm">
              <input 
                type="range" 
                min="3" 
                max="12" 
                step="3"
                value={ancho} 
                onChange={(e) => setAncho(parseInt(e.target.value))}
                className="w-full h-2 bg-gray-100 rounded-lg appearance-none cursor-pointer accent-blue-500"
              />
              <div className="flex justify-between text-xs text-gray-500 px-1 mt-2">
                <span>1/4</span>
                <span>1/2</span>
                <span>3/4</span>
                <span>Completo</span>
              </div>
              <p className="text-center mt-3 text-sm font-medium text-blue-500">{valuetext(ancho)}</p>
            </div>
          </div>
        </div>

        <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4">
          <div className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent my-6"></div>
          <h3 className="text-xl font-bold text-gray-800 mb-4">
            Configuración avanzada
          </h3>
        </div>

        <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2">
              Condición para mostrar
            </label>
            <CondicionalMostrarForm
              campos={camposDisponibles}
              condicionalMostrar={condicionalMostrar}
              onChange={setCondicionalMostrar}
            />
          </div>
        </div>

        <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4">
          <div className="mb-6">
            <div className="flex justify-between items-center mb-3">
              <label className="block text-sm font-semibold text-gray-700">
                Propiedades adicionales
              </label>
              {ejemplosPropiedadesAdicionales[tipo] && (
                <button
                  type="button"
                  onClick={cargarEjemploPropiedades}
                  className="text-xs text-blue-500 hover:text-blue-600 font-medium transition-colors duration-200 hover:underline"
                >
                  Cargar ejemplo
                </button>
              )}
            </div>
            <PropiedadesAdicionalesForm
              tipo={tipo}
              propiedadesAdicionales={propiedadesAdicionales}
              onChange={setPropiedadesAdicionales}
            />
          </div>
        </div>

        <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4">
          <div className="flex justify-end space-x-4 mt-6">
            <button 
              type="button" 
              onClick={onClose} 
              className="px-6 py-2.5 text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200 transition-all duration-300 font-medium text-sm shadow-sm hover:shadow-md"
            >
              Cancelar
            </button>
            <button 
              type="submit" 
              className="px-6 py-2.5 text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-xl hover:from-blue-600 hover:to-blue-700 transition-all duration-300 font-medium text-sm shadow-md hover:shadow-lg"
            >
              {isEditMode ? 'Actualizar' : 'Crear'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CampoForm; 