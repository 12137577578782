import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import AccessDenied from '../AccessDenied/AccessDenied';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredSlug: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredSlug }) => {
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();

  // Verificar si el usuario está autenticado
  if (!user.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Si el usuario no tiene un rol definido, negar acceso
  if (!user.role || !user.role.menusPermissions) {
    return <AccessDenied />;
  }

  // Buscar el menú correspondiente al slug requerido
  const menuPermission = user.role.menusPermissions.find(
    (menu) => menu.menuID.slug.toLowerCase() === requiredSlug.toLowerCase()
  );

  // Si no tiene el menú o al menos permiso de ver, mostrar AccessDenied
  if (!menuPermission || !menuPermission.permissions.ver) {
    return <AccessDenied />;
  }

  // Si tiene permiso, renderizar el componente hijo
  return <>{children}</>;
};

export default ProtectedRoute;
