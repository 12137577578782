import React, { useState } from 'react';
import Button from '../../../components/Buttons/Button';
import { consultarRucService } from '../../../services/proveedorService';

interface ProveedorFormData {
  razon_social: string;
  ruc: string;
  direccion?: string;
  nombre_comercial?: string;
  rubro?: string;
  estado?: string;
  tipo?: string;
  actividad?: string;
  correo?: string;
}

interface FormErrors {
  razon_social?: string;
  ruc?: string;
  nombre_comercial?: string;
}

interface ProveedorFormProps {
  initialValues?: Partial<ProveedorFormData>;
  onSubmit: (data: ProveedorFormData) => void;
}

const ProveedorForm: React.FC<ProveedorFormProps> = ({ initialValues, onSubmit }) => {
  const [formData, setFormData] = useState<ProveedorFormData>({
    razon_social: initialValues?.razon_social || '',
    ruc: initialValues?.ruc || '',
    direccion: initialValues?.direccion || '',
    nombre_comercial: initialValues?.nombre_comercial || '',
    rubro: initialValues?.rubro || '',
    estado: initialValues?.estado || '',
    tipo: initialValues?.tipo || '',
    actividad: initialValues?.actividad || '',
    correo: initialValues?.correo || '',
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [isSearching, setIsSearching] = useState(false);

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!formData.razon_social) {
      newErrors.razon_social = 'La razón social es requerida';
    }

    if (!formData.ruc) {
      newErrors.ruc = 'El RUC es requerido';
    } else if (formData.ruc.length !== 11) {
      newErrors.ruc = 'El RUC debe tener 11 caracteres';
    }

    if (!formData.nombre_comercial) {
      newErrors.nombre_comercial = 'El nombre comercial es requerido';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const handleRucSearch = async () => {
    if (!formData.ruc || formData.ruc.length !== 11) {
      setErrors(prev => ({ ...prev, ruc: 'El RUC debe tener 11 caracteres' }));
      return;
    }

    try {
      setIsSearching(true);
      const result = await consultarRucService(formData.ruc);
      setFormData(prev => ({
        ...prev,
        ruc: result.numeroDocumento,
        razon_social: result.razonSocial,
        direccion: result.direccion || '',
      }));
    } catch (error) {
      setErrors(prev => ({ ...prev, ruc: 'Error al consultar el RUC' }));
      console.error(error);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full m-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
        {/* Primera columna */}
        <div className="space-y-4 md:space-y-6">
          {/* Mantener los grupos de input pero ajustar el espaciado */}
          <div className="group">
            <label htmlFor="ruc" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              RUC
            </label>
            <div className="flex gap-1 md:gap-2">
              <input
                type="text"
                id="ruc"
                name="ruc"
                maxLength={11}
                placeholder="Ingrese RUC"
                value={formData.ruc}
                onChange={handleChange}
                className="block w-full rounded-lg border-gray-300 shadow-md bg-white px-2 h-9 md:h-10 text-sm 
                         focus:ring-2 focus:ring-blue-500 focus:border-transparent
                         transition duration-200 hover:shadow-lg"
              />
              <button
                type="button"
                onClick={handleRucSearch}
                disabled={isSearching}
                className={`
                  px-2 md:px-4 py-1 md:py-2 rounded-lg text-xs font-medium text-white shadow-md
                  transition-all duration-200 ease-in-out hover:shadow-lg whitespace-nowrap
                  ${isSearching 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 active:transform active:scale-95'}
                `}
              >
                {isSearching ? "..." : "Buscar"}
              </button>
            </div>
            {errors.ruc && <p className="mt-1 text-xs text-red-600">{errors.ruc}</p>}
          </div>

          <div className="group">
            <label htmlFor="razon_social" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              Razón Social
            </label>
            <input
              id="razon_social"
              name="razon_social"
              placeholder="Razón Social del Proveedor"
              value={formData.razon_social}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-md bg-white pl-2 h-10 text-sm
                       focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition duration-200 hover:shadow-lg"
            />
            {errors.razon_social && <p className="mt-1 text-xs text-red-600">{errors.razon_social}</p>}
          </div>

          <div className="group">
            <label htmlFor="nombre_comercial" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              Nombre Comercial
            </label>
            <input
              id="nombre_comercial"
              name="nombre_comercial"
              placeholder="Nombre Comercial"
              value={formData.nombre_comercial}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-md bg-white pl-2 h-10 text-sm 
                       focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition duration-200 hover:shadow-lg"
            />
            {errors.nombre_comercial && <p className="mt-1 text-xs text-red-600">{errors.nombre_comercial}</p>}
          </div>

          <div className="group">
            <label htmlFor="direccion" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              Dirección
            </label>
            <input
              id="direccion"
              name="direccion"
              placeholder="Dirección del Proveedor"
              value={formData.direccion}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-md bg-white pl-2 h-10 text-sm 
                       focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition duration-200 hover:shadow-lg"
            />
          </div>

          <div className="group">
            <label htmlFor="correo" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              Correo
            </label>
            <input
              id="correo"
              name="correo"
              placeholder="Correo del Proveedor"
              value={formData.correo}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-md bg-white pl-2 h-10 text-sm 
                       focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition duration-200 hover:shadow-lg"
            />
          </div>
        </div>

        {/* Segunda columna */}
        <div className="space-y-6">
          <div className="group">
            <label htmlFor="rubro" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              Rubro
            </label>
            <input
              id="rubro"
              name="rubro"
              placeholder="Rubro del Proveedor"
              value={formData.rubro}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-md bg-white pl-2 h-10 text-sm 
                       focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition duration-200 hover:shadow-lg"
            />
          </div>

          <div className="group">
            <label htmlFor="estado" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              Estado
            </label>
            <select
              id="estado"
              name="estado"
              value={formData.estado}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-md bg-white pl-2 h-10 text-sm 
                       focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition duration-200 hover:shadow-lg"
            >
              <option value="">Seleccione un estado</option>
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>

          <div className="group">
            <label htmlFor="tipo" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              Tipo (Bien / Servicio)
            </label>
            <select
              id="tipo"
              name="tipo"
              value={formData.tipo}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-md bg-white pl-2 h-10 text-sm 
                       focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition duration-200 hover:shadow-lg"
            >
              <option value="">Seleccione un tipo</option>
              <option value="BIENES">BIENES</option>
              <option value="SERVICIOS">SERVICIOS</option>
              <option value="BIENES Y SERVICIOS">BIENES Y SERVICIOS</option>
              <option value="CAJA CHICA">CAJA CHICA</option>
            </select>
          </div>

          <div className="group">
            <label htmlFor="actividad" className="block text-xs font-medium text-gray-700 mb-1 hover:text-blue-600">
              Actividad
            </label>
            <input
              id="actividad"
              name="actividad"
              placeholder="Actividad del Proveedor"
              value={formData.actividad}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-md bg-white pl-2 h-10 text-sm 
                       focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition duration-200 hover:shadow-lg"
            />
          </div>
        </div>
      </div>

      <div className="mt-6 md:mt-8 flex justify-center">
        <Button
          text={initialValues ? 'Actualizar' : 'Crear'}
          color="verde"
          className="w-full md:w-auto px-4 md:px-8 py-2 md:py-3 text-xs font-medium rounded-lg shadow-md
                    transform transition-all duration-200 
                    hover:shadow-xl hover:scale-105
                    active:transform active:scale-100"
        />
      </div>
    </form>
  );
};

export default ProveedorForm;
