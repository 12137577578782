import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { AppDispatch, RootState } from '../../store/store';
import { fetchOrdenCompraRecursosByOrdenId } from '../../slices/ordenCompraRecursosSlice';
import LoaderPage from '../../components/Loader/LoaderPage';
import PDFOCGenerator from './PDFOCGenerator';
import { Unidad } from '../../types/ordenCompra.types';
import { DataPDFOrdenCompra } from '../../services/ordenCompraService';
import { formatDate } from '../../components/Utils/dateUtils';
import defaultImage from '../../../src/assets/NoImage.webp';
import LoaderOverlay from '../../components/Loader/LoaderOverlay';
import { FiDownload, FiEye } from 'react-icons/fi';
import { fetchCotizacionesByProveedor } from '../../slices/cotizacionProveedoresRecursoSlice';

interface OrdenCompraDetalleProps {
  DataDPF: DataPDFOrdenCompra | null;
}

const OrdenCompraDetalle: React.FC<OrdenCompraDetalleProps> = ({ DataDPF }) => {
  const dispatch = useDispatch<AppDispatch>();

  const fetchData = useCallback(() => {
    if (DataDPF?.id) {
      dispatch(fetchOrdenCompraRecursosByOrdenId(DataDPF.id));
    }
    if (DataDPF?.cotizacion_proveedor.id) {
      dispatch(fetchCotizacionesByProveedor(DataDPF.cotizacion_proveedor.id));
    }
  }, [DataDPF?.id, DataDPF?.cotizacion_proveedor.id, dispatch]);

  useEffect(() => {
    fetchData();

    // Cleanup function
    return () => {
      // Opcional: Si tienes acciones de limpieza específicas para estos slices
      // dispatch(clearOrdenCompraRecursos());
      // dispatch(clearCotizacionesProveedor());
    };
  }, [fetchData]);

  const { ordenCompraRecursosByOrdenId, loading } = useSelector(
    (state: RootState) => state.ordenCompraRecursos
  );
  const unidades = useSelector((state: RootState) => state.unidad.unidades) as Unidad[];
  const usuarios = useSelector((state: RootState) => state.usuario.usuarios);
  const nombreSolicita = usuarios.find(usuario => usuario.id === DataDPF?.cotizacion_id?.usuario_id)?.nombres || "NA";
  const recursosProveedores = useSelector((state: RootState) => state.cotizacionProveedoresRecurso.cotizacionProveedoresRecursos);
  const [showPDF, setShowPDF] = useState(false);

  //Añadir detección de dispositivo móvil
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const formatCurrency = (value: number) => `${DataDPF?.cotizacion_proveedor.divisa.simbolo} ${value.toFixed(2)}`;

  const documentFileName = `OC-${DataDPF?.codigo_orden}.pdf`;

  //Función para manejar la visualización/descarga del PDF
  const handlePDFAction = async () => {
    if (isMobile) {
      // En móviles, crear y descargar el PDF usando react-pdf
      const { pdf } = await import('@react-pdf/renderer');
      const blob = await pdf(
        <PDFOCGenerator
          ordenCompra={DataDPF as DataPDFOrdenCompra}
          nombreSolicita={nombreSolicita}
          recursos={recursosCompletos}
          documentFileName={documentFileName}
        />
      ).toBlob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = documentFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      // En desktop, mostrar vista previa
      setShowPDF(!showPDF);
    }
  };

  const recursosCompletos = ordenCompraRecursosByOrdenId.map(recurso => ({
    ...recurso,
    precio: recurso.costo_real, // Usar el costo real como precio
    igv: true, // Valor por defecto
    descuento: 0, // Valor por defecto
    subTotal: recurso.costo_real * recurso.cantidad, // Calcular el subtotal
    sr: "", // Campo vacío por defecto
    notas: "" // Campo vacío por defecto
  }));

  const color = DataDPF?.cotizacion_id?.solicitud_compra_id?.requerimiento?.obra?.empresa_id?.color || 'bg-gray-100';

  if (loading) return <LoaderPage />;

  return (
    <>
      {!DataDPF ? <LoaderOverlay message={"Cargando Información"} /> : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="max-w-7xl mx-auto bg-gray-50 p-6"
        >
          <div className="bg-white p-6 rounded-xl shadow-lg border border-gray-200">
            {/* Header Section */}
            <div className="mb-6 flex justify-between gap-6 items-center border-b border-gray-200 pb-4">
              <div className='flex items-center space-x-6'>
                <div className={`border-2 px-3 border-[${color.replace('#', '')}] rounded-lg w-36 h-12 flex items-center justify-center bg-white shadow-sm`}>
                  <img src={DataDPF?.cotizacion_id?.solicitud_compra_id?.requerimiento?.obra?.empresa_id?.imagenes || defaultImage}
                    alt="LogoEmpresa" className="object-contain" />
                </div>
                <div>
                  <h2 className="text-2xl font-bold text-gray-900 tracking-tight">
                    OC-{DataDPF?.codigo_orden}
                  </h2>
                </div>
              </div>
              <button
                onClick={handlePDFAction}
                className="bg-indigo-600 hover:bg-indigo-700 text-white text-sm font-semibold py-2.5 px-4 rounded-lg shadow-sm transition duration-150 ease-in-out flex items-center"
              >
                {isMobile ? <FiDownload className="mr-2" /> : <FiEye className="mr-2" />}
                {isMobile ? 'Descargar PDF' : (showPDF ? 'Ver Tabla' : 'Ver PDF')}
              </button>
            </div>

            {showPDF ? (
              <div id="pdf-container">
                <PDFOCGenerator
                  ordenCompra={DataDPF as DataPDFOrdenCompra}
                  nombreSolicita={nombreSolicita}
                  recursos={recursosCompletos}
                  documentFileName={documentFileName}
                />
              </div>
            ) : (
              <>
                {/* Table Section */}
                <div className="mb-8">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4">Detalle de Recursos</h3>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr className="bg-gray-50">
                          <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Código</th>
                          <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                          <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Unidad</th>
                          <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Resta</th>
                          <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Cantidad</th>
                          <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Costo Real</th>
                          <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Costo Aprox.</th>
                          <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {ordenCompraRecursosByOrdenId.map((recurso) => (
                          <tr key={recurso.id} className="hover:bg-gray-50 text-xs">
                            <td className="px-4 py-2 text-center whitespace-nowrap font-medium text-gray-900">{recurso.id_recurso.codigo}</td>
                            <td className="px-4 py-2 text-gray-700">{recurso.id_recurso.nombre}</td>
                            <td className="px-4 py-2 text-center text-gray-600">
                              {unidades.find(u => u.id === recurso.id_recurso.unidad_id)?.nombre || 'N/A'}
                            </td>
                            <td className="px-4 py-2 text-center text-gray-700">{recurso.cantidad}</td>
                            <td className="px-4 py-2 text-center text-gray-700">{recursosProveedores.find(rec => rec.recurso_id.id === recurso.id_recurso.id)?.cantidad}</td>
                            <td className="px-4 py-2 text-center font-medium text-gray-900">{formatCurrency(recurso.costo_real)}</td>
                            <td className="px-4 py-2 text-center text-gray-600">{formatCurrency(recurso.costo_aproximado)}</td>
                            <td className="px-4 py-2 text-center">
                              <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                              ${recurso.estado === 'pendiente' ? 'bg-yellow-100 text-yellow-800' :
                                  recurso.estado === 'completado' ? 'bg-green-100 text-green-800' :
                                    'bg-gray-100 text-gray-800'}`}>
                                {recurso.estado}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-gray-50 font-medium text-xs">
                          <td colSpan={5} className="px-4 py-3 text-right text-gray-700">Totales:</td>
                          <td className="px-4 py-3 text-center text-gray-900">
                            {formatCurrency(ordenCompraRecursosByOrdenId.reduce((sum, item) => sum + item.costo_real * (recursosProveedores.find(rec => rec.recurso_id.id === item.id_recurso.id)?.cantidad || 0), 0))}
                          </td>
                          <td className="px-4 py-3 text-center text-gray-600">
                            {formatCurrency(ordenCompraRecursosByOrdenId.reduce((sum, item) => sum + item.costo_aproximado * (recursosProveedores.find(rec => rec.recurso_id.id === item.id_recurso.id)?.cantidad || 0), 0))}
                          </td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                {/* Details Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                  {/* Información General */}
                  <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                    <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Información General</h3>
                    <div className="space-y-3 text-xs">
                      <div className="flex justify-between">
                        <span className="text-gray-600">Emisión:</span>
                        <span className="font-medium">{formatDate(DataDPF?.fecha_ini || "24/10/1991", 'dd/mm/yyyy')}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Entrega Deseable:</span>
                        <span className="font-medium">{formatDate(DataDPF?.fecha_fin || "24/10/1991", 'dd/mm/yyyy')}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Moneda:</span>
                        <span className="font-medium">
                        {DataDPF?.cotizacion_proveedor?.divisa?.nombre || 'N/A'} - {DataDPF?.cotizacion_proveedor?.divisa?.abreviatura || 'N/A'} - {DataDPF?.cotizacion_proveedor?.divisa?.simbolo || 'N/A'}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Solicitante:</span>
                        <span className="font-medium">{nombreSolicita}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Obra:</span>
                        <span className="font-medium">
                          {DataDPF?.cotizacion_id?.solicitud_compra_id?.requerimiento?.obra?.titulo || 'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Proveedor */}
                  <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                    <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Datos del Proveedor</h3>
                    <div className="space-y-3 text-xs">

                      <div>
                        <span className="text-gray-600">RUC:</span>
                        <span className="font-medium ml-2">{DataDPF?.cotizacion_proveedor?.proveedor?.ruc || 'N/A'}</span>
                      </div>
                      <div>
                        <span className="text-gray-600">Razón Social:</span>
                        <span className="font-medium ml-2">{DataDPF?.cotizacion_proveedor?.proveedor?.razon_social || 'N/A'}</span>
                      </div>
                      <div>
                        <span className="text-gray-600">Dirección:</span>
                        <span className="font-medium ml-2">{DataDPF?.cotizacion_proveedor?.proveedor?.direccion || 'N/A'}</span>
                      </div>
                      <div>
                        <span className="text-gray-600">Contacto:</span>
                        <span className="font-medium ml-2">
                          {DataDPF?.cotizacion_proveedor?.proveedor?.contactos?.map((contacto) => (
                            `${contacto.nombres || 'N/A'}: ${contacto.telefono || 'N/A'}`
                          )).join(', ') || 'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Datos Bancarios */}
                  <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                    <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Datos Bancarios</h3>
                    <div className="space-y-3 text-xs">
                      {DataDPF?.cotizacion_proveedor?.proveedor?.medios_pago?.map((medio, index) => (
                        <div key={index} className="grid grid-cols-3 gap-4">
                          <div>
                            <span className="text-gray-600">Banco:</span>
                            <span className="font-medium ml-2">{medio.entidad || 'N/A'}</span>
                          </div>
                          <div>
                            <span className="text-gray-600">Cuenta:</span>
                            <span className="font-medium ml-2">{medio.nro_cuenta || 'N/A'}</span>
                          </div>
                          <div>
                            <span className="text-gray-600">Moneda:</span>
                            <span className="font-medium ml-2">{medio.detalles || 'N/A'}</span>
                          </div>
                        </div>
                      )) || <div>No hay datos bancarios disponibles</div>}
                    </div>
                  </div>

                  {/* Datos de Despacho */}
                  <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
                    <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Datos de Despacho</h3>
                    <div className="space-y-3 text-xs">
                      <div className="grid grid-cols-1 gap-4">
                        <div>
                          <span className="text-gray-600">Obra:</span>
                          <span className="font-medium ml-2">
                            {DataDPF?.cotizacion_id?.solicitud_compra_id?.requerimiento?.obra?.nombre || 'N/A'}
                          </span>
                        </div>
                        <div>
                          <span className="text-gray-600">Despacho:</span>
                          <span className="font-medium ml-2">
                            {DataDPF?.cotizacion_id?.solicitud_compra_id?.requerimiento?.obra?.ubicacion || 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className="text-gray-600">Dirección:</span>
                        <span className="font-medium ml-2">
                          {DataDPF?.cotizacion_id?.solicitud_compra_id?.requerimiento?.obra?.direccion || 'N/A'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </motion.div>
      )}
    </>
  );
};

export default OrdenCompraDetalle;