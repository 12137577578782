import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listComprobantesService,
  addComprobanteService,
  updateComprobanteService,
  deleteComprobanteService,
  getComprobantesByOrderCompraService,
  getDetalleComprobanteOrdenCompraService,
  getDetalleIngresoAlmacenService,
  getDetalleConstanciaService,
  findAllByIdComprobanteService,
} from '../services/comprobanteService';
import type { Comprobante, ComprobanteInput } from '../services/comprobanteService';

// Actualizar o agregar las interfaces necesarias
interface AprobacionComprobanteOrdenCompra {
  id: string;
  estado: string;
  fecha: string;
}

interface ListOrdenCompraRecurso {
  nombre: string;
  cantidad: number;
  precio_real: number;
}

interface DetalleComprobanteOrdenCompra {
  id: string;
  codigo: string;
  monto: number;
  orden_compra_recursos: ListOrdenCompraRecurso[];
  comprobante_ordenes_compra_id: string;
  aprobacion_comprobante_ordenes_compra: AprobacionComprobanteOrdenCompra;
  comprobante_id: string;
}

interface RecursoTransferenciaDetalle {
  nombre: string;
  cantidad: number;
  costo: number;
}

interface AprobacionIngresoAlmacen {
  id: string;
  estado: string;
  fecha: string;
  usuario_id: string;
}

interface DetalleIngresoAlmacenComprobante {
  transferencia_id: string;
  monto: number;
  transferencia_detalle_id: string;
  tipo: string;
  referencia: string;
  fecha_orden: string;
  fecha_ingreso: string;
  recursos_transferencia: RecursoTransferenciaDetalle[];
  comprobante_id: string;
  aprobacion_ingreso_almacen: AprobacionIngresoAlmacen;
}

interface AprobacionConstanciasComprobanteDetalle {
  id: string;
  estado: string;
  fecha: string;
  usuario_id: string;
}

interface MontoDetalle {
  monto: number;
}

interface ArchivoDetalle {
  file: string;
}

interface DetalleConstanciaComprobante {
  codigo: string;
  pago_orden_id: MontoDetalle;
  archivo_pago_id: ArchivoDetalle;
  aprobacion_constancias_comprobante: AprobacionConstanciasComprobanteDetalle;
  comprobante_id: string;
  pago_id: string;
}

interface ComprobanteState {
  comprobantes: Comprobante[];
  comprobantesByOrdenCompra: Comprobante[];
  loading: boolean;
  error: string | null;
  loadingByOrdenCompra: boolean;
  errorByOrdenCompra: string | null;
  detalleOrdenCompra: DetalleComprobanteOrdenCompra[];
  detalleIngresoAlmacen: DetalleIngresoAlmacenComprobante[];
  detalleConstancia: DetalleConstanciaComprobante[];
  loadingDetalle: boolean;
  errorDetalle: string | null;
}

const initialState: ComprobanteState = {
  comprobantes: [],
  comprobantesByOrdenCompra: [],
  loading: false,
  error: null,
  loadingByOrdenCompra: false,
  errorByOrdenCompra: null,
  detalleOrdenCompra: [],
  detalleIngresoAlmacen: [],
  detalleConstancia: [],
  loadingDetalle: false,
  errorDetalle: null,
};

export const fetchComprobantes = createAsyncThunk(
  'comprobante/fetchComprobantes',
  async (_, { rejectWithValue }) => {
    try {
      return await listComprobantesService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchComprobantesByOrdenCompra = createAsyncThunk(
  'comprobante/fetchComprobantesByOrdenCompra',
  async (orderCompraId: string, { rejectWithValue }) => {
    try {
      return await getComprobantesByOrderCompraService(orderCompraId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addComprobante = createAsyncThunk(
  'comprobante/addComprobante',
  async (comprobante: ComprobanteInput, { rejectWithValue }) => {
    try {
      return await addComprobanteService(comprobante);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateComprobante = createAsyncThunk(
  'comprobante/updateComprobante',
  async ({ id, ...data }: Partial<ComprobanteInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateComprobanteService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteComprobante = createAsyncThunk(
  'comprobante/deleteComprobante',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteComprobanteService(id);
      return { id };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchDetalleComprobanteOrdenCompra = createAsyncThunk(
  'comprobante/fetchDetalleComprobanteOrdenCompra',
  async (comprobanteId: string, { rejectWithValue }) => {
    try {
      return await getDetalleComprobanteOrdenCompraService(comprobanteId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchDetalleIngresoAlmacen = createAsyncThunk(
  'comprobante/fetchDetalleIngresoAlmacen',
  async (comprobanteId: string, { rejectWithValue }) => {
    try {
      return await getDetalleIngresoAlmacenService(comprobanteId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchDetalleConstancia = createAsyncThunk(
  'comprobante/fetchDetalleConstancia',
  async (comprobanteId: string, { rejectWithValue }) => {
    try {
      return await getDetalleConstanciaService(comprobanteId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const findAllByIdComprobante = createAsyncThunk(
  'comprobante/findAllByIdComprobante',
  async (comprobanteId: string, { rejectWithValue }) => {
    try {
      return await findAllByIdComprobanteService(comprobanteId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const comprobanteSlice = createSlice({
  name: 'comprobante',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch comprobantes
      .addCase(fetchComprobantes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchComprobantes.fulfilled, (state, action: PayloadAction<Comprobante[]>) => {
        state.loading = false;
        state.comprobantes = action.payload;
      })
      .addCase(fetchComprobantes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch comprobantes by orden compra
      .addCase(fetchComprobantesByOrdenCompra.pending, (state) => {
        state.loadingByOrdenCompra = true;
        state.errorByOrdenCompra = null;
      })
      .addCase(fetchComprobantesByOrdenCompra.fulfilled, (state, action: PayloadAction<Comprobante[]>) => {
        state.loadingByOrdenCompra = false;
        state.comprobantesByOrdenCompra = action.payload;
      })
      .addCase(fetchComprobantesByOrdenCompra.rejected, (state, action) => {
        state.loadingByOrdenCompra = false;
        state.errorByOrdenCompra = action.payload as string;
      })
      // Add comprobante
      .addCase(addComprobante.fulfilled, (state, action: PayloadAction<Comprobante>) => {
        state.comprobantes.push(action.payload);
      })
      // Update comprobante
      .addCase(updateComprobante.fulfilled, (state, action: PayloadAction<Comprobante>) => {
        const index = state.comprobantes.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.comprobantes[index] = action.payload;
        }
      })
      // Delete comprobante
      .addCase(deleteComprobante.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.comprobantes = state.comprobantes.filter(item => item.id !== action.payload.id);
      })
      // Detalle Comprobante Orden Compra
      .addCase(fetchDetalleComprobanteOrdenCompra.pending, (state) => {
        state.loadingDetalle = true;
        state.errorDetalle = null;
      })
      .addCase(fetchDetalleComprobanteOrdenCompra.fulfilled, (state, action) => {
        state.loadingDetalle = false;
        state.detalleOrdenCompra = action.payload;
      })
      .addCase(fetchDetalleComprobanteOrdenCompra.rejected, (state, action) => {
        state.loadingDetalle = false;
        state.errorDetalle = action.payload as string;
      })
      // Detalle Ingreso Almacén
      .addCase(fetchDetalleIngresoAlmacen.pending, (state) => {
        state.loadingDetalle = true;
        state.errorDetalle = null;
      })
      .addCase(fetchDetalleIngresoAlmacen.fulfilled, (state, action) => {
        state.loadingDetalle = false;
        state.detalleIngresoAlmacen = action.payload;
      })
      .addCase(fetchDetalleIngresoAlmacen.rejected, (state, action) => {
        state.loadingDetalle = false;
        state.errorDetalle = action.payload as string;
      })
      // Detalle Constancia
      .addCase(fetchDetalleConstancia.pending, (state) => {
        state.loadingDetalle = true;
        state.errorDetalle = null;
      })
      .addCase(fetchDetalleConstancia.fulfilled, (state, action) => {
        state.loadingDetalle = false;
        state.detalleConstancia = action.payload;
      })
      .addCase(fetchDetalleConstancia.rejected, (state, action) => {
        state.loadingDetalle = false;
        state.errorDetalle = action.payload as string;
      })
      // Agregar casos para findAllByIdComprobante
      .addCase(findAllByIdComprobante.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(findAllByIdComprobante.fulfilled, (state, action: PayloadAction<Comprobante>) => {
        state.loading = false;
        // Aquí puedes decidir cómo manejar los datos, por ejemplo:
        state.comprobantes = [action.payload];
      })
      .addCase(findAllByIdComprobante.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const comprobanteReducer = comprobanteSlice.reducer;
