import React, { useState } from 'react';
import Button from '../../components/Buttons/Button';

interface TipoCostoRecursoFormData {
  nombre: string;
  codigo: string;
}

interface FormComponentProps {
  initialValues?: TipoCostoRecursoFormData;
  onSubmit: (data: TipoCostoRecursoFormData) => void;
}

const TipoCostoRecursoFormComponent: React.FC<FormComponentProps> = ({ initialValues, onSubmit }) => {
  const [formData, setFormData] = useState<TipoCostoRecursoFormData>(
    initialValues || { nombre: '', codigo: '' }
  );
  const [errors, setErrors] = useState<Partial<TipoCostoRecursoFormData>>({});

  const validateForm = (): boolean => {
    const newErrors: Partial<TipoCostoRecursoFormData> = {};
    
    if (!formData.nombre.trim()) {
      newErrors.nombre = 'El nombre es requerido';
    }
    if (!formData.codigo.trim()) {
      newErrors.codigo = 'El código es requerido';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-gradient-to-b from-white to-gray-100 shadow-lg rounded-lg px-6 sm:px-8 py-8 mb-4 max-w-md mx-auto border border-gray-200"
    >
      <div className="mb-6">
        <label htmlFor="nombre" className="block text-blue-700 text-sm font-semibold mb-2">
          Nombre:
        </label>
        <input
          id="nombre"
          name="nombre"
          placeholder="Nombre del tipo de costo"
          value={formData.nombre}
          onChange={handleChange}
          className="shadow-sm appearance-none border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
        />
        {errors.nombre && (
          <p className="text-red-500 text-xs italic mt-1">{errors.nombre}</p>
        )}

        <label htmlFor="codigo" className="block text-blue-700 text-sm font-semibold mb-2 mt-4">
          Código:
        </label>
        <input
          id="codigo"
          name="codigo"
          placeholder="Código del tipo de costo"
          value={formData.codigo}
          onChange={handleChange}
          className="shadow-sm appearance-none border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
        />
        {errors.codigo && (
          <p className="text-red-500 text-xs italic mt-1">{errors.codigo}</p>
        )}
      </div>

      <div className="flex items-center justify-center mt-8">
        <Button
          text={initialValues ? 'Actualizar Tipo de Costo' : 'Crear Tipo de Costo'}
          color="verde"
          className="w-full sm:w-auto px-6 py-3 text-sm font-medium rounded-md shadow-sm hover:shadow-md transition duration-150 ease-in-out"
        />
      </div>
    </form>
  );
};

export default TipoCostoRecursoFormComponent;