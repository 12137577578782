import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SolicitudCompraRecurso } from './solicitudCompraRecursoSlice';

// Solo añadimos cantidadSeleccionada, ya que solicitud_compra_id ya existe en el recurso original
interface RecursoSeleccionado extends SolicitudCompraRecurso {
  cantidadSeleccionada: number;  // Cantidad seleccionada para la cotización
}

// Interfaz mejorada para recursos agrupados
interface RecursoAgrupado {
  recursoId: string;             // ID del recurso base
  cantidadTotal: number;         // Suma total de cantidades
  items: RecursoSeleccionado[];  // Lista de instancias individuales con toda la info original
  
  // Propiedades para mostrar (del primer recurso)
  nombre: string;
  codigo: string;
  unidad: string;
  tipo: string;
  precioUnitario: number;
  imagen?: string;
  precio?: number;  
  precio_partida?: number;
}

// Definir colores para las solicitudes
export const SOLICITUD_COLORS = [
  'bg-cyan-200',
  'bg-slate-300',
  'bg-blue-200',
  'bg-yellow-200',
  'bg-lime-300',
  'bg-indigo-300',
  'bg-teal-300',
  'bg-sky-300',
  'bg-purple-200',
  'bg-green-300',
  'bg-pink-200',
  'bg-red-200',
  'bg-orange-200',
  'bg-violet-300',
  'bg-fuchsia-200',
];

// Estados finales que no permiten añadir recursos
export const ESTADOS_FINALES = [
  'COTIZACION_COMPLETA',
  'FIN_ATENCION_PARCIAL',
  'FIN_COMPLETO',
  'ABORTADA'
];

// Nuevos tipos para manejar la asignación de colores
export interface SolicitudColorAssignment {
  solicitudId: string;
  colorClass: string;
  colorIndex: number; // Añadimos índice para mejor rastreo
}

// Nuevos tipos para estado de cotización
export type EstadoCotizacion = 'COTIZACION_COMPLETA' | 'COTIZACION_PARCIAL' | null;

export interface SolicitudEstadoCotizacion {
  solicitudId: string;
  estado: EstadoCotizacion;
}

interface CompraSelectorState {
  selectedSolicitudId: string | null; // Mantener para compatibilidad
  selectedSolicitudes: string[]; // Nueva propiedad para múltiples selecciones
  solicitudColors: SolicitudColorAssignment[]; // Nueva propiedad para asignar colores
  recursosNoServicios: RecursoSeleccionado[]; // Para mantener compatibilidad
  recursosServicios: RecursoSeleccionado[]; // Para mantener compatibilidad
  recursosNoServiciosAgrupados: { [key: string]: RecursoAgrupado };
  recursosServiciosAgrupados: { [key: string]: RecursoAgrupado };
  obraFilter: string | null;
  isSelectionLocked: boolean; // Nueva propiedad para bloquear la selección
  solicitudEstadosCotizacion: SolicitudEstadoCotizacion[]; // Nueva propiedad para almacenar estado de cotización
}

const initialState: CompraSelectorState = {
  selectedSolicitudId: null,
  selectedSolicitudes: [], // Inicializar como array vacío
  solicitudColors: [], // Inicializar asignación de colores
  recursosNoServicios: [],
  recursosServicios: [],
  recursosNoServiciosAgrupados: {},
  recursosServiciosAgrupados: {},
  obraFilter: null,
  isSelectionLocked: false, // Inicializar como desbloqueado
  solicitudEstadosCotizacion: [], // Inicializar como array vacío
};

// Función para encontrar el primer índice de color disponible
const getNextAvailableColorIndex = (usedColorIndices: number[]): number => {
  // Si no hay colores usados, comenzamos con el primero
  if (usedColorIndices.length === 0) return 0;
  
  // Ordenamos los índices utilizados
  const sortedIndices = [...usedColorIndices].sort((a, b) => a - b);
  
  // Buscamos el primer "hueco" en la secuencia
  for (let i = 0; i < sortedIndices.length; i++) {
    if (i !== sortedIndices[i]) {
      return i; // Encontramos un índice no utilizado
    }
  }
  
  // Si no hay "huecos", usamos el siguiente en la secuencia
  return sortedIndices.length;
};

export const compraSelectorSlice = createSlice({
  name: 'compraSelector',
  initialState,
  reducers: {
    setSelectedSolicitud: (state, action: PayloadAction<string | null>) => {
      state.selectedSolicitudId = action.payload;
      // Cuando seleccionamos una solicitud individual, también la añadimos al array de seleccionados
      if (action.payload && !state.selectedSolicitudes.includes(action.payload)) {
        state.selectedSolicitudes = [action.payload]; // Reemplazar con solo esta selección
      } else if (!action.payload) {
        state.selectedSolicitudes = []; // Limpiar selecciones si es null
      }
    },
    // Nueva acción para manejar múltiples selecciones
    toggleSelectedSolicitud: (state, action: PayloadAction<string>) => {
      const solicitudId = action.payload;
      const index = state.selectedSolicitudes.indexOf(solicitudId);
      
      if (index === -1) {
        // Añadir a seleccionados si no existe
        state.selectedSolicitudes.push(solicitudId);
        
        // Obtenemos todos los índices de colores actualmente en uso
        const usedColorIndices = state.solicitudColors.map(sc => sc.colorIndex);
        
        // Encontramos el primer índice disponible
        const nextColorIndex = getNextAvailableColorIndex(usedColorIndices);
        
        // Aseguramos que no nos pasemos del array de colores (rotamos si es necesario)
        const actualColorIndex = nextColorIndex % SOLICITUD_COLORS.length;
        
        // Asignamos el color
        state.solicitudColors.push({
          solicitudId,
          colorClass: SOLICITUD_COLORS[actualColorIndex],
          colorIndex: nextColorIndex // Guardamos el índice lógico (puede ser mayor que el array)
        });
        
        // También actualizar selectedSolicitudId para mantener compatibilidad
        state.selectedSolicitudId = solicitudId;
      } else {
        // Eliminar de seleccionados si ya existe
        state.selectedSolicitudes.splice(index, 1);
        
        // Eliminar la asignación de color
        state.solicitudColors = state.solicitudColors.filter(sc => sc.solicitudId !== solicitudId);
        
        // Actualizar selectedSolicitudId para mantener compatibilidad
        state.selectedSolicitudId = state.selectedSolicitudes.length > 0 
          ? state.selectedSolicitudes[state.selectedSolicitudes.length - 1] 
          : null;
      }
    },
    // Nueva acción para limpiar todas las selecciones
    clearSelectedSolicitudes: (state) => {
      state.selectedSolicitudes = [];
      state.selectedSolicitudId = null;
      state.solicitudColors = []; // Limpiar asignaciones de colores
    },    
    setObraFilter: (state, action: PayloadAction<string | null>) => {
      console.log("Redux: Estableciendo obraFilter a:", action.payload);
      state.obraFilter = action.payload;
    },
    addRecurso: (state, action: PayloadAction<{recurso: SolicitudCompraRecurso, cantidad: number, solicitudId: string}>) => {
      const { recurso, cantidad } = action.payload;
      
      if (!recurso.recurso_id) return;
      
      const esServicio = recurso.recurso_id.tipo_recurso_nombre === "Servicio";
      
      // Crear objeto RecursoSeleccionado con toda la información original
      // Usamos el solicitud_compra_id que ya viene en el recurso, no el que pasamos como parámetro
      const recursoConCantidad: RecursoSeleccionado = {
        ...recurso,  // Mantener TODA la información original 
        cantidadSeleccionada: cantidad   // Añadir cantidad seleccionada
      };
      
      // Agregar a los arrays según tipo
      if (esServicio) {
        state.recursosServicios.push(recursoConCantidad);
      } else {
        state.recursosNoServicios.push(recursoConCantidad);
      }
      
      // Agregar a los objetos agrupados
      const recursoId = recurso.recurso_id.id;
      const targetMap = esServicio ? state.recursosServiciosAgrupados : state.recursosNoServiciosAgrupados;
      
      // Extraer datos del recurso_id para el agrupado
      const recursoData = {
        recursoId,
        nombre: recurso.recurso_id.nombre || 'Sin nombre',
        codigo: recurso.recurso_id.codigo || 'Sin código',
        unidad: recurso.recurso_id.unidad_id || 'Und',
        tipo: recurso.recurso_id.tipo_recurso_nombre || 'Sin tipo',
        precioUnitario: recurso.recurso_id.precio_actual || 0,
        precio: recurso.precio || 0,
        precio_partida: recurso.precio_partida || 0,
        imagen: recurso.recurso_id.imagenes?.[0]?.file,
      };
      
      if (!targetMap[recursoId]) {
        // Si el recurso no existe, lo creamos con los datos extraídos
        targetMap[recursoId] = {
          ...recursoData,
          cantidadTotal: cantidad,
          items: [recursoConCantidad]  // Mantener el objeto completo en items
        };
      } else {
        // Si el recurso ya existe, actualizamos la cantidad y añadimos el nuevo item
        targetMap[recursoId].cantidadTotal += cantidad;
        targetMap[recursoId].items.push(recursoConCantidad);  // Añadir con toda la info
      }
    },
    removeRecursoNoServicio: (state, action: PayloadAction<string>) => {
      const idToRemove = action.payload;
      const recursoIndex = state.recursosNoServicios.findIndex(r => r.id === idToRemove);
      
      if (recursoIndex !== -1) {
        const recurso = state.recursosNoServicios[recursoIndex];
        if (recurso.recurso_id) {
          const recursoId = recurso.recurso_id.id;
          const agrupado = state.recursosNoServiciosAgrupados[recursoId];
          
          // Eliminar del arreglo de items
          const itemIndex = agrupado.items.findIndex(item => item.id === idToRemove);
          if (itemIndex !== -1) {
            const cantidadARestar = agrupado.items[itemIndex].cantidadSeleccionada;
            agrupado.cantidadTotal -= cantidadARestar;
            agrupado.items.splice(itemIndex, 1);
            
            // Si no quedan items, eliminar el grupo completo
            if (agrupado.items.length === 0) {
              delete state.recursosNoServiciosAgrupados[recursoId];
            }
          }
        }
        
        // Eliminar del array de compatibilidad
        state.recursosNoServicios.splice(recursoIndex, 1);
      }
    },
    removeRecursoServicio: (state, action: PayloadAction<string>) => {
      const idToRemove = action.payload;
      const recursoIndex = state.recursosServicios.findIndex(r => r.id === idToRemove);
      
      if (recursoIndex !== -1) {
        const recurso = state.recursosServicios[recursoIndex];
        if (recurso.recurso_id) {
          const recursoId = recurso.recurso_id.id;
          const agrupado = state.recursosServiciosAgrupados[recursoId];
          
          // Eliminar del arreglo de items
          const itemIndex = agrupado.items.findIndex(item => item.id === idToRemove);
          if (itemIndex !== -1) {
            const cantidadARestar = agrupado.items[itemIndex].cantidadSeleccionada;
            agrupado.cantidadTotal -= cantidadARestar;
            agrupado.items.splice(itemIndex, 1);
            
            // Si no quedan items, eliminar el grupo completo
            if (agrupado.items.length === 0) {
              delete state.recursosServiciosAgrupados[recursoId];
            }
          }
        }
        
        // Eliminar del array de compatibilidad
        state.recursosServicios.splice(recursoIndex, 1);
      }
    },
    clearSeleccion: (state) => {
      state.recursosNoServicios = [];
      state.recursosServicios = [];
      state.recursosNoServiciosAgrupados = {};
      state.recursosServiciosAgrupados = {};
      state.obraFilter = null;
      state.isSelectionLocked = false; // Desbloquear al limpiar la selección
      // No limpiamos solicitudColors para mantener la consistencia de colores
      // mientras existan solicitudes seleccionadas
    },
    // Nueva acción para bloquear la selección
    lockSelection: (state) => {
      state.isSelectionLocked = true;
    },
    
    // Nueva acción para desbloquear la selección
    unlockSelection: (state) => {
      state.isSelectionLocked = false;
    },

    // Nueva acción para eliminar todos los recursos sin afectar las selecciones de solicitudes
    removeAllRecursos: (state) => {
      state.recursosNoServicios = [];
      state.recursosServicios = [];
      state.recursosNoServiciosAgrupados = {};
      state.recursosServiciosAgrupados = {};
      // Mantenemos el filtro de obra y el estado de bloqueo
    },

    // Nueva acción para actualizar la cantidad de un recurso no servicio
    updateRecursoNoServicio: (state, action: PayloadAction<{id: string, cantidad: number}>) => {
      const { id, cantidad } = action.payload;
      const recursoIndex = state.recursosNoServicios.findIndex(r => r.id === id);
      
      if (recursoIndex !== -1) {
        const recurso = state.recursosNoServicios[recursoIndex];
        if (recurso.recurso_id) {
          const recursoId = recurso.recurso_id.id;
          const agrupado = state.recursosNoServiciosAgrupados[recursoId];
          
          // Actualizar la cantidad en el grupo y la cantidad total
          const itemIndex = agrupado.items.findIndex(item => item.id === id);
          if (itemIndex !== -1) {
            const cantidadAnterior = agrupado.items[itemIndex].cantidadSeleccionada;
            agrupado.cantidadTotal = agrupado.cantidadTotal - cantidadAnterior + cantidad;
            agrupado.items[itemIndex].cantidadSeleccionada = cantidad;
          }
          
          // Actualizar el array de compatibilidad
          state.recursosNoServicios[recursoIndex].cantidadSeleccionada = cantidad;
        }
      }
    },
    
    // Nueva acción para actualizar la cantidad de un recurso servicio
    updateRecursoServicio: (state, action: PayloadAction<{id: string, cantidad: number}>) => {
      const { id, cantidad } = action.payload;
      const recursoIndex = state.recursosServicios.findIndex(r => r.id === id);
      
      if (recursoIndex !== -1) {
        const recurso = state.recursosServicios[recursoIndex];
        if (recurso.recurso_id) {
          const recursoId = recurso.recurso_id.id;
          const agrupado = state.recursosServiciosAgrupados[recursoId];
          
          // Actualizar la cantidad en el grupo y la cantidad total
          const itemIndex = agrupado.items.findIndex(item => item.id === id);
          if (itemIndex !== -1) {
            const cantidadAnterior = agrupado.items[itemIndex].cantidadSeleccionada;
            agrupado.cantidadTotal = agrupado.cantidadTotal - cantidadAnterior + cantidad;
            agrupado.items[itemIndex].cantidadSeleccionada = cantidad;
          }
          
          // Actualizar el array de compatibilidad
          state.recursosServicios[recursoIndex].cantidadSeleccionada = cantidad;
        }
      }
    },

    // Nueva acción para actualizar el estado de cotización de una solicitud
    updateSolicitudEstadoCotizacion: (state, action: PayloadAction<SolicitudEstadoCotizacion>) => {
      const { solicitudId, estado } = action.payload;
      const index = state.solicitudEstadosCotizacion.findIndex(item => item.solicitudId === solicitudId);
      
      if (index !== -1) {
        // Actualizar estado existente
        state.solicitudEstadosCotizacion[index].estado = estado;
      } else {
        // Añadir nuevo estado
        state.solicitudEstadosCotizacion.push({ solicitudId, estado });
      }
    },
    
    // Nueva acción para limpiar estados de cotización
    clearSolicitudEstadosCotizacion: (state) => {
      state.solicitudEstadosCotizacion = [];
    },
  }
});

export const { 
  setSelectedSolicitud,
  toggleSelectedSolicitud,
  clearSelectedSolicitudes,
  setObraFilter,
  addRecurso,
  removeRecursoNoServicio,
  removeRecursoServicio,
  clearSeleccion,
  lockSelection,
  unlockSelection,
  removeAllRecursos,
  updateRecursoNoServicio,
  updateRecursoServicio,
  updateSolicitudEstadoCotizacion,
  clearSolicitudEstadosCotizacion
} = compraSelectorSlice.actions;

export default compraSelectorSlice.reducer;
