import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  AprobacionComprobanteOrden,
  AprobacionComprobanteOrdenInput,
  listAprobacionesService,
  getAprobacionesByComprobanteOrdenService,
  addAprobacionService
} from '../services/aprobacionComprobanteOrdenesCompraService';

interface AprobacionComprobanteOrdenState {
  aprobacionesOrdenCompra: AprobacionComprobanteOrden[];
  loading: boolean;
  error: string | null;
}

const initialState: AprobacionComprobanteOrdenState = {
  aprobacionesOrdenCompra: [],
  loading: false,
  error: null
};

// Thunks
export const fetchAprobacionesOrdenCompra = createAsyncThunk(
  'aprobacionComprobanteOrden/fetchAprobaciones',
  async (_, { rejectWithValue }) => {
    try {
      return await listAprobacionesService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchAprobacionesByComprobanteOrden = createAsyncThunk(
  'aprobacionComprobanteOrden/fetchAprobacionesByComprobante',
  async (comprobanteOrdenId: string, { rejectWithValue }) => {
    try {
      return await getAprobacionesByComprobanteOrdenService(comprobanteOrdenId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addAprobacionOrdenCompra = createAsyncThunk(
  'aprobacionComprobanteOrden/addAprobacion',
  async (aprobacion: AprobacionComprobanteOrdenInput, { rejectWithValue }) => {
    try {
      return await addAprobacionService(aprobacion);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const aprobacionComprobanteOrdenSlice = createSlice({
  name: 'aprobacionComprobanteOrden',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchAprobacionesOrdenCompra.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAprobacionesOrdenCompra.fulfilled, (state, action: PayloadAction<AprobacionComprobanteOrden[]>) => {
        state.loading = false;
        state.aprobacionesOrdenCompra = action.payload;
      })
      .addCase(fetchAprobacionesOrdenCompra.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch by comprobante
      .addCase(fetchAprobacionesByComprobanteOrden.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAprobacionesByComprobanteOrden.fulfilled, (state, action: PayloadAction<AprobacionComprobanteOrden[]>) => {
        state.loading = false;
        state.aprobacionesOrdenCompra = action.payload;
      })
      .addCase(fetchAprobacionesByComprobanteOrden.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Add
      .addCase(addAprobacionOrdenCompra.fulfilled, (state, action: PayloadAction<AprobacionComprobanteOrden>) => {
        state.aprobacionesOrdenCompra.push(action.payload);
      });
  },
});

export const aprobacionComprobanteOrdenReducer = aprobacionComprobanteOrdenSlice.reducer;
