import React, { useEffect, useState } from 'react';
import Button from '../../components/Buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { fetchTipoAlmacenes } from '../../slices/tipoAlmacenSlice';
import { fetchEmpresas } from '../../slices/empresaSlice';
import { fetchProyectos } from '../../slices/proyectoSlice';

interface ObraInput {
  titulo: string;
  nombre: string;
  descripcion: string;
  ubicacion: string;
  direccion: string;
  estado: string;
  tipoId: string;
  empresaId: string;
  idProyecto?: string;
}

interface ValidationErrors {
  [key: string]: string;
}

interface FormComponentProps {
  initialValues?: ObraInput;
  onSubmit: (data: ObraInput) => void;
}

const ObraFormComponent: React.FC<FormComponentProps> = ({ initialValues, onSubmit }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tipoAlmacenes } = useSelector((state: RootState) => state.tipoAlmacen);
  const { empresas } = useSelector((state: RootState) => state.empresa);
  const { proyectos } = useSelector((state: RootState) => state.proyecto);
  const [formData, setFormData] = useState<ObraInput>({
    titulo: initialValues?.titulo || '',
    nombre: initialValues?.nombre || '',
    descripcion: initialValues?.descripcion || '',
    ubicacion: initialValues?.ubicacion || '',
    direccion: initialValues?.direccion || '',
    estado: initialValues?.estado || '',
    tipoId: initialValues?.tipoId || '',
    empresaId: initialValues?.empresaId || '',
    idProyecto: initialValues?.idProyecto || ''
  });
  const [errors, setErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    if (tipoAlmacenes.length === 0) {
      dispatch(fetchTipoAlmacenes());
    }
    if (proyectos.length === 0) {
      dispatch(fetchProyectos());
    }
    if (empresas.length === 0) {
      dispatch(fetchEmpresas());
    }
  }, [dispatch, tipoAlmacenes.length, empresas.length]);

  const validateForm = (): boolean => {
    const newErrors: ValidationErrors = {};

    if (!formData.titulo) newErrors.titulo = 'El título es requerido';
    if (!formData.nombre) newErrors.nombre = 'El nombre es requerido';
    if (!formData.descripcion) newErrors.descripcion = 'La descripción es requerida';
    if (!formData.ubicacion) newErrors.ubicacion = 'La ubicación es requerida';
    if (!formData.direccion) newErrors.direccion = 'La dirección es requerida';
    if (!formData.estado) newErrors.estado = 'El estado es requerido';
    if (!formData.tipoId) newErrors.tipoId = 'El tipo de obra es requerido';
    if (!formData.empresaId) newErrors.empresaId = 'La empresa es requerida';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Limpiar error del campo cuando el usuario empiece a escribir
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  return (
    <form
  onSubmit={handleSubmit}
  className="bg-gradient-to-br from-white via-gray-50 to-gray-100 shadow-xl rounded-xl px-8 py-10 mb-6 w-full max-w-4xl mx-auto border border-gray-200"
>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    {/* Título */}
    <div className="w-full">
      <label htmlFor="titulo" className="block text-blue-700 text-base font-semibold mb-2">
        Título
        <span className="text-neutral-400 text-xs ml-2">(Ejm: "CU_PLAN3")</span>
      </label>
      <input
        id="titulo"
        name="titulo"
        placeholder="Título de la obra"
        value={formData.titulo}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
      />
      {errors.titulo && <p className="text-red-500 text-xs font-medium mt-1">{errors.titulo}</p>}
    </div>

    {/* Nombre */}
    <div className="w-full">
      <label htmlFor="nombre" className="block text-blue-700 text-base font-semibold mb-2">
        Nombre
        <span className="text-neutral-400 text-xs ml-2">(Ejm: "CU PLAN3")</span>
      </label>
      <input
        id="nombre"
        name="nombre"
        placeholder="Nombre de la obra"
        value={formData.nombre}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
      />
      {errors.nombre && <p className="text-red-500 text-xs font-medium mt-1">{errors.nombre}</p>}
    </div>

    {/* Descripción - Full width */}
    <div className="w-full md:col-span-2">
      <label htmlFor="descripcion" className="block text-blue-700 text-base font-semibold mb-2">
        Descripción
      </label>
      <textarea
        id="descripcion"
        name="descripcion"
        placeholder="Descripción de la obra"
        value={formData.descripcion}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 h-32 resize-none"
      />
      {errors.descripcion && <p className="text-red-500 text-xs font-medium mt-1">{errors.descripcion}</p>}
    </div>

    {/* Ubicación */}
    <div className="w-full">
      <label htmlFor="ubicacion" className="block text-blue-700 text-base font-semibold mb-2">
        Ubicación
        <span className="text-neutral-400 text-xs ml-2">(Ejm: "-12.456789, -75.654987")</span>
      </label>
      <input
        id="ubicacion"
        name="ubicacion"
        placeholder="Ubicación de la obra"
        value={formData.ubicacion}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
      />
      {errors.ubicacion && <p className="text-red-500 text-xs font-medium mt-1">{errors.ubicacion}</p>}
    </div>

    {/* Dirección */}
    <div className="w-full">
      <label htmlFor="direccion" className="block text-blue-700 text-base font-semibold mb-2">
        Dirección
      </label>
      <input
        id="direccion"
        name="direccion"
        placeholder="Dirección de la obra"
        value={formData.direccion}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
      />
      {errors.direccion && <p className="text-red-500 text-xs font-medium mt-1">{errors.direccion}</p>}
    </div>

    {/* Estado */}
    <div className="w-full">
      <label htmlFor="estado" className="block text-blue-700 text-base font-semibold mb-2">
        Estado
      </label>
      <select
        id="estado"
        name="estado"
        value={formData.estado}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
      >
        <option value="">Seleccione un estado</option>
        <option value="ACTIVO">Activo</option>
        <option value="INACTIVO">Inactivo</option>
        <option value="EN_PROCESO">En Proceso</option>
        <option value="COMPLETADO">Completado</option>
      </select>
      {errors.estado && <p className="text-red-500 text-xs font-medium mt-1">{errors.estado}</p>}
    </div>

    {/* Tipo de Obra */}
    <div className="w-full">
      <label htmlFor="tipoId" className="block text-blue-700 text-base font-semibold mb-2">
        Tipo de Obra
      </label>
      <select
        id="tipoId"
        name="tipoId"
        value={formData.tipoId}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
      >
        <option value="">Seleccione un tipo</option>
        {tipoAlmacenes.map((tipo) => (
          <option key={tipo.id} value={tipo.id}>
            {tipo.nombre}
          </option>
        ))}
      </select>
      {errors.tipoId && <p className="text-red-500 text-xs font-medium mt-1">{errors.tipoId}</p>}
    </div>

    {/* Empresa - Full width */}
    <div className="w-full md:col-span-2">
      <label htmlFor="empresaId" className="block text-blue-700 text-base font-semibold mb-2">
        Empresa
      </label>
      <select
        id="empresaId"
        name="empresaId"
        value={formData.empresaId}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
      >
        <option value="">Seleccione una empresa</option>
        {empresas.map((empresa) => (
          <option key={empresa.id} value={empresa.id}>
            {empresa.nombre_comercial}
          </option>
        ))}
      </select>
      {errors.empresaId && <p className="text-red-500 text-xs font-medium mt-1">{errors.empresaId}</p>}
    </div>

    {/*Proyecto */}
    <div className="w-full md:col-span-2">
      <label htmlFor="idProyecto" className="block text-blue-700 text-base font-semibold mb-2">
        Proyecto
        <span className="text-neutral-400 text-xs ml-2">(Opcional)</span>
      </label>
      <select
        id="idProyecto"
        name="idProyecto"
        value={formData.idProyecto}
        onChange={handleChange}
        className="shadow-sm appearance-none border border-gray-300 rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
      >
        <option value="">Seleccione una empresa</option>
        {proyectos.map((proyecto) => (
          <option key={proyecto.id_proyecto} value={proyecto.id_proyecto}>
            {proyecto.nombre_proyecto}
          </option>
        ))}
      </select>
      {errors.idProyecto && <p className="text-red-500 text-xs font-medium mt-1">{errors.idProyecto}</p>}
    </div>
  </div>

  <div className="flex items-center justify-center mt-10">
    <Button
      text={initialValues ? 'Actualizar Obra' : 'Crear Obra'}
      color="verde"
      className="w-full sm:w-auto px-8 py-4 text-base font-semibold rounded-lg shadow-md hover:shadow-lg transition-all duration-200 ease-in-out"
    />
  </div>
</form>
  );
};

export default ObraFormComponent;