import React, { useState, useEffect } from 'react';
import Button from '../../components/Buttons/Button';

interface HomogenizedClasificacion {
  id: string;
  nombre: string;
  parent_id: string | null;
  nivel: number;
}

interface FormComponentProps {
  initialValues?: {
    id: string;
    nombre: string;
    parent_id: string | null;
    nivel: number;
  };
  onSubmit: (data: { nombre: string; parent_id: string | null }) => void;
  clasificaciones: HomogenizedClasificacion[];
}

// Función para obtener el nombre limpio (sin '>' o espacios al inicio)
const getCleanName = (nombre: string) => nombre.replace(/^[>\s]+/, '').trim();

const ClasificacionRecursoFormComponent: React.FC<FormComponentProps> = ({ initialValues, onSubmit, clasificaciones }) => {
  const [formData, setFormData] = useState({
    nombre: initialValues ? getCleanName(initialValues.nombre) : '',
    parent_id: initialValues?.parent_id || null
  });
  const [errors, setErrors] = useState({
    nombre: ''
  });

  useEffect(() => {
    if (initialValues) {
      setFormData({
        nombre: getCleanName(initialValues.nombre),
        parent_id: initialValues.parent_id
      });
    }
  }, [initialValues]);

  const validateForm = () => {
    const newErrors = {
      nombre: ''
    };
    
    if (!formData.nombre.trim()) {
      newErrors.nombre = 'El nombre es requerido';
    }
    
    setErrors(newErrors);
    return !newErrors.nombre;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  // Función para obtener la jerarquía de una clasificación
  const getHierarchy = (id: string | null): string => {
    if (!id) return "No tiene padre";
    const parent = clasificaciones.find(c => c.id === id);
    if (!parent) return "No tiene padre";
    const grandparent = clasificaciones.find(c => c.id === parent.parent_id);
    if (!grandparent) return getCleanName(parent.nombre);
    return `${getCleanName(grandparent.nombre)} → ${getCleanName(parent.nombre)}`;
  };

  // Filtramos las clasificaciones para el select
  const selectOptions = clasificaciones.filter(c => {
    if (initialValues) {
      return c.id !== initialValues.id && 
             c.parent_id !== initialValues.id && 
             c.nivel <= 1;
    } else {
      return c.nivel <= 1;
    }
  });

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto"
    >
      <div className="mb-4">
        <label htmlFor="nombre" className="block text-gray-700 text-sm font-bold mb-2">
          Nombre:
        </label>
        <input
          id="nombre"
          type="text"
          placeholder="Nombre de la clasificación de recurso"
          value={formData.nombre}
          onChange={(e) => setFormData({ ...formData, nombre: e.target.value })}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.nombre ? 'border-red-500' : ''
          }`}
        />
        {errors.nombre && (
          <p className="text-red-500 text-xs italic mt-1">{errors.nombre}</p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="parent_id" className="block text-gray-700 text-sm font-bold mb-2">
          Clasificación Padre:
        </label>
        <select
          id="parent_id"
          value={formData.parent_id || ''}
          onChange={(e) => setFormData({ ...formData, parent_id: e.target.value === '' ? null : e.target.value })}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">Sin padre</option>
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {getCleanName(option.nombre)}
            </option>
          ))}
        </select>
        {initialValues && (
          <p className="text-sm text-gray-600 mt-2">
            Jerarquía actual: {getHierarchy(initialValues.parent_id)}
          </p>
        )}
      </div>
      <div className="flex items-center justify-center mt-6">
        <Button
          text={initialValues ? 'Actualizar Clasificación de Recurso' : 'Crear Clasificación de Recurso'}
          color="verde"
          className="w-auto px-6 py-2 text-sm font-medium"
        />
      </div>
    </form>
  );
};

export default ClasificacionRecursoFormComponent;