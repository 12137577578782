import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_APROBACIONES = gql`
  query ListAprobacionesConstancias {
    listAprobacionesConstancias {
      id
      constancias_comprobante_id {
        id
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const GET_APROBACIONES_BY_CONSTANCIA = gql`
  query GetAprobacionesByConstancia($constanciaId: ID!) {
    getAprobacionesByConstancia(constanciaId: $constanciaId) {
      id
      constancias_comprobante_id {
        id
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const ADD_APROBACION = gql`
  mutation AddAprobacionConstancia(
    $constancias_comprobante_id: ID!
    $usuario_id: ID!
    $estado: String!
    $fecha: DateTime
  ) {
    addAprobacionConstancia(
      constancias_comprobante_id: $constancias_comprobante_id
      usuario_id: $usuario_id
      estado: $estado
      fecha: $fecha
    ) {
      id
      estado
      fecha
    }
  }
`;

const UPDATE_APROBACION = gql`
  mutation UpdateAprobacionConstancia(
    $id: ID!
    $constancias_comprobante_id: ID
    $usuario_id: ID
    $estado: String
    $fecha: DateTime
  ) {
    updateAprobacionConstancia(
      id: $id
      constancias_comprobante_id: $constancias_comprobante_id
      usuario_id: $usuario_id
      estado: $estado
      fecha: $fecha
    ) {
      id
      estado
      fecha
    }
  }
`;

const DELETE_APROBACION = gql`
  mutation DeleteAprobacionConstancia($id: ID!) {
    deleteAprobacionConstancia(id: $id) {
      id
    }
  }
`;

export interface AprobacionConstanciaInput {
  constancias_comprobante_id: string;
  usuario_id: string;
  estado: string;
  fecha?: Date;
}

export interface AprobacionConstancia {
  id: string;
  constancias_comprobante_id: {
    id: string;
  };
  usuario_id: {
    id: string;
    usuario: string;
  };
  estado: string;
  fecha: string;
}

export const listAprobacionesConstanciasService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_APROBACIONES,
      fetchPolicy: 'network-only',
    });
    return data.listAprobacionesConstancias;
  } catch (error) {
    throw new Error(`Error fetching aprobaciones: ${error}`);
  }
};

export const getAprobacionesByConstanciaService = async (constanciaId: string) => {
  try {
    const { data } = await client.query({
      query: GET_APROBACIONES_BY_CONSTANCIA,
      variables: { constanciaId },
      fetchPolicy: 'network-only',
    });
    return data.getAprobacionesByConstancia;
  } catch (error) {
    throw new Error(`Error fetching aprobaciones by constancia: ${error}`);
  }
};

export const addAprobacionConstanciaService = async (input: AprobacionConstanciaInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_APROBACION,
      variables: input,
    });
    return data.addAprobacionConstancia;
  } catch (error) {
    throw new Error(`Error adding aprobacion: ${error}`);
  }
};

export const updateAprobacionConstanciaService = async (id: string, input: Partial<AprobacionConstanciaInput>) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_APROBACION,
      variables: { id, ...input },
    });
    return data.updateAprobacionConstancia;
  } catch (error) {
    throw new Error(`Error updating aprobacion: ${error}`);
  }
};

export const deleteAprobacionConstanciaService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_APROBACION,
      variables: { id },
    });
    return data.deleteAprobacionConstancia;
  } catch (error) {
    throw new Error(`Error deleting aprobacion: ${error}`);
  }
};
