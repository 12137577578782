import { gql } from '@apollo/client';
import client from '../apolloClient';

interface Permission {
  ver: boolean;
  crear: boolean;
  editar: boolean;
  eliminar: boolean;
}

interface MenuPermission {
  menuID: string;
  permissions: Permission;
}

interface RoleData {
  nombre: string;
  descripcion: string;
  menusPermissions: MenuPermission[];
}

// Función de utilidad para eliminar campos __typename recursivamente
const removeTypename = <T>(data: any): T => {
  if (Array.isArray(data)) {
    return data.map(item => removeTypename(item)) as any;
  }
  
  if (data !== null && typeof data === 'object') {
    const newObj: any = {};
    Object.keys(data).forEach(key => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(data[key]);
      }
    });
    return newObj;
  }
  
  return data;
};

const LIST_ROLES_AND_MENUS_QUERY = gql`
  query ListRolesAndMenus {
    listRoles {
      id
      nombre
      descripcion
      menusPermissions {
        menuID
        permissions {
          ver
          crear
          editar
          eliminar
        }
      }
      createdAt
      updatedAt
      deleted
    }
    listMenus {
      id
      nombre
      slug
      posicion
    }
  }
`;

const GET_ROLE_QUERY = gql`
  query GetRole($getRoleId: ID!) {
    getRole(id: $getRoleId) {
      id
      nombre
      descripcion
      menusPermissions {
        menuID
        permissions {
          ver
          crear
          editar
          eliminar
        }
      }
      createdAt
      updatedAt
      deleted
    }
  }
`;

const ADD_ROLE_MUTATION = gql`
  mutation AddRole($nombre: String!, $descripcion: String!, $menusPermissions: [MenuPermissionInput!]!) {
    addRole(nombre: $nombre, descripcion: $descripcion, menusPermissions: $menusPermissions) {
      id
      nombre
      descripcion
      menusPermissions {
        menuID
        permissions {
          ver
          crear
          editar
          eliminar
        }
      }
      createdAt
      updatedAt
      deleted
    }
  }
`;

const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateRole($updateRoleId: ID!, $nombre: String!, $descripcion: String!, $menusPermissions: [MenuPermissionInput!]!) {
    updateRole(id: $updateRoleId, nombre: $nombre, descripcion: $descripcion, menusPermissions: $menusPermissions) {
      id
      nombre
      descripcion
      menusPermissions {
        menuID
        permissions {
          ver
          crear
          editar
          eliminar
        }
      }
      createdAt
      updatedAt
      deleted
    }
  }
`;

export const listRolesAndMenusService = async () => {
  try {
    const response = await client.query({
      query: LIST_ROLES_AND_MENUS_QUERY,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de roles y menús:', error);
    throw error;
  }
};

export const getRoleService = async (roleId: string) => {
  try {
    const response = await client.query({
      query: GET_ROLE_QUERY,
      variables: { getRoleId: roleId },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.getRole;
  } catch (error) {
    console.error('Error al obtener el rol:', error);
    throw error;
  }
};

export const addRoleService = async (roleData: RoleData) => {
  try {
    // Eliminar los campos __typename antes de enviar los datos
    const cleanedData = removeTypename<RoleData>(roleData);
    
    const response = await client.mutate({
      mutation: ADD_ROLE_MUTATION,
      variables: cleanedData,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.addRole;
  } catch (error) {
    console.error('Error al crear el rol:', error);
    throw error;
  }
};

export const updateRoleService = async (role: RoleData & { id: string }) => {
  try {
    // Eliminar los campos __typename antes de enviar los datos
    const cleanedRole = removeTypename<RoleData & { id: string }>(role);
    
    const response = await client.mutate({
      mutation: UPDATE_ROLE_MUTATION,
      variables: { updateRoleId: cleanedRole.id, ...cleanedRole },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.updateRole;
  } catch (error) {
    console.error('Error al actualizar el rol:', error);
    throw error;
  }
};