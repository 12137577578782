import React from 'react';
import { OrdenCompra } from '../../types/ordenCompra';

export interface KanbanCardServicioProps {
  column: {
    id: string;
    title: string;
    color: string;
    ordenservicio: OrdenCompra;
  };
}

export interface ColumnOrdenServicio {
  id: string;
  title: string;
  color: string;
  ordenservicio: OrdenCompra[];
}

interface KanbanColumnOrdenServicioProps {
  columna: ColumnOrdenServicio;
  CardComponentS: React.ComponentType<{
    column: {
      id: string;
      title: string;
      color: string;
      ordenservicio: OrdenCompra;
    };
  }>;
}

const KanbanColumnOrdenServicio: React.FC<KanbanColumnOrdenServicioProps> = ({
  columna,
  CardComponentS
}) => {
  return (
    <div className="flex-shrink-0 w-[250px] bg-white/10 rounded-lg shadow-lg scale-90 lg:scale-100 min-h-[69vh] max-h-[calc(100vh-300px)] ">
      <div className="p-4 rounded-t-lg" style={{ backgroundColor: columna.color }}>
        <h2 className="text-sm font-semibold flex justify-between items-center text-white">
          {columna.title}
          <span className="text-sm font-normal bg-white bg-opacity-20 text-white px-2 py-1 rounded-full">
            {columna.ordenservicio?.length ?? 0}
          </span>
        </h2>
      </div>
      <div className="p-4 space-y-4 max-h-[calc(80vh-200px)] overflow-y-auto">
        {(columna.ordenservicio ?? []).map((req) => (
          <CardComponentS
            key={req.id} 
            column={{
              id: columna.id,
              title: columna.title,
              color: columna.color,
              ordenservicio: req
            }} 
          />
        ))}
      </div>
    </div>
  );
};

export default KanbanColumnOrdenServicio; 