import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchSolicitudesCompras, SolicitudCompra } from '../../../slices/solicitudCompraSlice';
import {
    setObraFilter,
    clearSeleccion,
    toggleSelectedSolicitud,
    clearSelectedSolicitudes,
} from '../../../slices/compraSelectorSlice';
import {
    clearAccumulatedResources,
    removeAccumulatedBySolicitudId
} from '../../../slices/solicitudCompraRecursoSlice';
import SolicitudCompraCard from './SolicitudCompraCard';
import { formatDateToISO } from '../../../components/Utils/dateUtils';
import { FiChevronDown, FiChevronUp, FiRefreshCcw } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import ModalAlert from '../../../components/Modal/ModalAlert';

const SolicitudList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { solicitudes, loading, error } = useSelector((state: RootState) => state.solicitudCompra);
    const obras = useSelector((state: RootState) => state.obra.obras);
    const {
        selectedSolicitudId,
        selectedSolicitudes, // Obtener array de selecciones múltiples
        obraFilter,
        recursosNoServiciosAgrupados,
        recursosServiciosAgrupados,
        isSelectionLocked // Obtener el estado de bloqueo de selección
    } = useSelector((state: RootState) => state.compraSelector);
    // Estado para el modal de confirmación
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showConfirmModalReset, setShowConfirmModalReset] = useState(false);
    const [newObraValue, setNewObraValue] = useState<string | null>(null);

    // Comprobar si hay recursos seleccionados
    const hayRecursosSeleccionados = Object.keys(recursosNoServiciosAgrupados).length > 0 ||
        Object.keys(recursosServiciosAgrupados).length > 0;
        
    // Estados para filtros y ordenación
    const [filteredSolicitudes, setFilteredSolicitudes] = useState<SolicitudCompra[]>([]);
    const [estadoFilter, setEstadoFilter] = useState<string>('');
    const [codigoFilter, setCodigoFilter] = useState<string>('');
    const [startDate, setStartDate] = useState<string>(getLastWeekDate());
    const [endDate, setEndDate] = useState<string>(getTodayMidnightDate());
    const [dateFilterEnabled, setDateFilterEnabled] = useState<boolean>(true);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [showFilters, setShowFilters] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    // Función para obtener fecha de hace 7 días
    function getLastWeekDate(): string {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        return formatDateToISO(date);
    }

    // Función para obtener la fecha actual a medianoche
    function getTodayMidnightDate(): string {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return formatDateToISO(date);
    }

    useEffect(() => {
        dispatch(fetchSolicitudesCompras());
    }, [dispatch]);

    // Manejador de cambio de obra con verificación
    const handleObraChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // Si la selección está bloqueada, no hacer nada
        if (isSelectionLocked) return;
        
        const newValue = e.target.value || null;

        // Verificar si hay solicitudes seleccionadas o recursos acumulados antes de mostrar el modal
        const haySeleccionesActivas = selectedSolicitudes.length > 0 || hayRecursosSeleccionados;

        if (newValue !== obraFilter) {
            setNewObraValue(newValue);

            // Solo mostrar el modal si hay selecciones activas
            if (haySeleccionesActivas) {
                setShowConfirmModal(true);
            } else {
                // Si no hay selecciones, cambiar directamente sin mostrar el modal
                dispatch(setObraFilter(newValue));
            }
        }
    };

    // Función para confirmar el cambio de obra
    const confirmObraChange = () => {
        // Limpiar los recursos acumulados
        dispatch(clearAccumulatedResources());
        // Limpiar la selección en el selector de compras
        dispatch(clearSeleccion());
        // Limpiar solicitudes seleccionadas
        dispatch(clearSelectedSolicitudes());
        // Aplicar el nuevo filtro de obra
        dispatch(setObraFilter(newObraValue));
        // Cerrar el modal
        setShowConfirmModal(false);
        setShowConfirmModalReset(false);
    };

    // Manejar checkbox para selección múltiple
    const handleCheckboxChange = (id: string, checked: boolean) => {
        // Si la selección está bloqueada, no hacer nada
        if (isSelectionLocked) return;
        
        // Determinar si se está seleccionando o deseleccionando
        const isCurrentlySelected = selectedSolicitudes.includes(id);

        // Si está deseleccionando, eliminar los recursos acumulados para esta solicitud
        if (isCurrentlySelected && !checked) {
            // Eliminar recursos acumulados para esta solicitud
            dispatch(removeAccumulatedBySolicitudId(id));

            // También podríamos verificar si hay recursos seleccionados de esta solicitud
            // en el estado compraSelectorSlice y limpiarlos si es necesario
            // Esto dependería de tu implementación exacta
        }

        // Actualizar la selección de solicitudes
        dispatch(toggleSelectedSolicitud(id));
    };

    // Manejar "Seleccionar todo"
    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Si la selección está bloqueada, no hacer nada
        if (isSelectionLocked) return;
        
        const checked = e.target.checked;
        setSelectAllChecked(checked);

        if (checked) {
            // Seleccionar todas las solicitudes filtradas
            filteredSolicitudes.forEach(sol => {
                if (!selectedSolicitudes.includes(sol.id)) {
                    dispatch(toggleSelectedSolicitud(sol.id));
                }
            });
        } else {
            // Si está deseleccionando todo, eliminar todos los recursos acumulados
            selectedSolicitudes.forEach(id => {
                dispatch(removeAccumulatedBySolicitudId(id));
            });

            // Deseleccionar todas
            dispatch(clearSelectedSolicitudes());
        }
    };

    // Efecto para actualizar el estado de "Seleccionar todo" basado en selecciones
    useEffect(() => {
        if (filteredSolicitudes.length > 0 && selectedSolicitudes.length === filteredSolicitudes.length) {
            setSelectAllChecked(true);
        } else {
            setSelectAllChecked(false);
        }
    }, [selectedSolicitudes, filteredSolicitudes]);

    // Efecto para aplicar filtros y ordenación
    useEffect(() => {
        if (solicitudes) {
            let result = [...solicitudes];

            // Filtrar por estado
            if (estadoFilter) {
                result = result.filter(sol => sol.estado === estadoFilter);
            }

            // Filtrar por obra - añadir log adicional para depuración
            if (obraFilter) {
                result = result.filter(sol => {
                    const matches = sol.requerimiento_id?.obra_id === obraFilter;
                    return matches;
                });
            }

            // Filtrar por código
            if (codigoFilter) {
                result = result.filter(sol =>
                    sol.requerimiento_id?.codigo?.toLowerCase().includes(codigoFilter.toLowerCase())
                );
            }

            // Filtrar por rango de fechas
            if (dateFilterEnabled && startDate && endDate) {
                const start = new Date(startDate).getTime();
                const end = new Date(endDate).getTime();

                result = result.filter(sol => {
                    if (!sol.fecha) return false;
                    const solicitudDate = new Date(sol.fecha).getTime();
                    return solicitudDate >= start && solicitudDate <= end;
                });
            }

            // Definir estados que deben aparecer al final
            const estadosAlFinal = ["COTIZACION_COMPLETA", "FIN_ATENCION_PARCIAL", "FIN_COMPLETO", "ABORTADA"];
            
            // Ordenar primero por prioridad de estado (los estados especificados van al final)
            // y luego por fecha según el sortOrder
            result.sort((a, b) => {
                // Verificar si alguno de los estados está en la lista de estadosAlFinal
                const aEsEstadoFinal = estadosAlFinal.includes(a.estado || '');
                const bEsEstadoFinal = estadosAlFinal.includes(b.estado || '');
                
                // Si uno es estado final y el otro no, el estado final va después
                if (aEsEstadoFinal && !bEsEstadoFinal) return 1;
                if (!aEsEstadoFinal && bEsEstadoFinal) return -1;
                
                // Si ambos son estados finales o ambos no lo son, ordenar por fecha
                const dateA = new Date(a.fecha || 0).getTime();
                const dateB = new Date(b.fecha || 0).getTime();
                return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
            });

            setFilteredSolicitudes(result);
        }
    }, [solicitudes, estadoFilter, obraFilter, codigoFilter, startDate, endDate, dateFilterEnabled, sortOrder]);

    // Lista de estados disponibles
    const estadosDisponibles = [
        'PENDIENTE',
        'COTIZACION_PARCIAL',
        'COTIZACION_COMPLETA',
        'FIN_ATENCION_PARCIAL',
        'FIN_COMPLETO',
        'ABORTADA'
    ];

    const toggleSortOrder = () => {
        setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    };

    const resetFilters = () => {
        // Si la selección está bloqueada, no hacer nada
        if (isSelectionLocked) return;
        
        dispatch(clearAccumulatedResources());
        // Limpiar la selección en el selector de compras
        dispatch(clearSeleccion());
        // Limpiar solicitudes seleccionadas
        dispatch(clearSelectedSolicitudes());

        setEstadoFilter('');
        dispatch(setObraFilter(null)); // Usar la acción de Redux
        setCodigoFilter('');
        setStartDate(getLastWeekDate());
        setEndDate(getTodayMidnightDate());
        setDateFilterEnabled(true);
        // Limpiar selecciones al resetear filtros
        dispatch(clearSelectedSolicitudes());
        setShowConfirmModalReset(false); 
    };

    return (
        <div className={`h-[calc(100vh-17rem)] bg-white/80 backdrop-blur-sm rounded-lg shadow-lg border border-slate-200 p-2 pt-4 overflow-y-auto`}>
            <div className="flex justify-between items-center text-blue-900 border-b pb-2 border-slate-200">
                <div className="flex w-full px-2 md:items-center justify-between">
                    {/* Mostrar selector de obras solo si no hay recursos seleccionados */}
                    {!hayRecursosSeleccionados ? (
                        <select
                            value={obraFilter || ''}
                            onChange={handleObraChange}
                            disabled={isSelectionLocked} // Deshabilitar cuando la selección está bloqueada
                            className={`md:px-2 md:py-1 text-sm rounded-md border border-slate-200 focus:outline-none focus:ring-1 focus:ring-blue-500 ${isSelectionLocked ? 'opacity-60 cursor-not-allowed' : ''}`}
                        >
                            <option value="">Obra: Todas</option>
                            {obras.map(obra => (
                                <option key={obra.id} value={obra.id}>{obra.nombre}</option>
                            ))}
                        </select>
                    ) : (
                        <span className="text-sm text-blue-700 font-medium">
                            {obraFilter ? obras.find(o => o.id === obraFilter)?.nombre || 'Obra seleccionada' : 'Todas las obras'}
                        </span>
                    )}
                    <button
                        onClick={toggleSortOrder}
                        className={`md:p-1 rounded-md ${isSelectionLocked ? 'opacity-60 cursor-not-allowed' : 'hover:bg-slate-100'} transition-colors`}
                        title={sortOrder === 'desc' ? "Mostrando más recientes primero" : "Mostrando más antiguas primero"}
                        disabled={isSelectionLocked} // Deshabilitar cuando la selección está bloqueada
                    >
                        {sortOrder === 'desc' ? (
                            <FiChevronDown className="h-4 md:w-4" />
                        ) : (
                            <FiChevronUp className="h-4 md:w-4" />
                        )}
                    </button>
                    <button
                        onClick={() => !isSelectionLocked && setShowConfirmModalReset(true)}
                        className={`md:p-1 rounded-md ${isSelectionLocked ? 'opacity-60 cursor-not-allowed' : 'hover:bg-slate-100'} transition-colors text-xs`}
                        title="Restablecer filtros"
                        disabled={isSelectionLocked} // Deshabilitar cuando la selección está bloqueada
                    >
                        <FiRefreshCcw className="h-4 w-4" />
                    </button>
                </div>
            </div>

            {/* Opción "Seleccionar todo" */}
            {obraFilter && <div className="flex items-center pb-1 px-2 border-b border-slate-100">
                <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAll}
                    disabled={isSelectionLocked} // Deshabilitar cuando la selección está bloqueada
                    className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 ${isSelectionLocked ? 'opacity-60 cursor-not-allowed' : ''}`}
                />
                <label className={`ml-2 text-xs ${isSelectionLocked ? 'text-slate-400' : 'text-slate-600'}`}>
                    Seleccionar todo ({selectedSolicitudes.length}/{filteredSolicitudes.length})
                </label>
            </div>}

            {/* Filtros con toggle para mostrar/ocultar */}
            <div className={`border-b border-slate-100  ${showFilters ? "pb-2 " : "flex justify-start"}  items-center`}>
                {/* Cabecera de filtros con botón para mostrar/ocultar */}
                <div className="flex justify-between items-center mb-1 mt-1">
                    <motion.button
                        onClick={() => !isSelectionLocked && setShowFilters(prevState => !prevState)}
                        className={`flex items-center text-sm ${isSelectionLocked ? 'text-blue-400 cursor-not-allowed' : 'text-blue-700 hover:text-blue-900'} font-medium`}
                        whileTap={{ scale: isSelectionLocked ? 1 : 0.97 }}
                        disabled={isSelectionLocked} // Deshabilitar cuando la selección está bloqueada
                    >
                        <span>Filtros</span>
                        <motion.div
                            initial={false}
                            animate={{ rotate: showFilters ? 180 : 0 }}
                            transition={{ duration: 0.3 }}
                            className="ml-1"
                        >
                            <FiChevronDown className="h-4 w-4" />
                        </motion.div>
                    </motion.button>
                    <AnimatePresence>
                        {showFilters && !isSelectionLocked && (
                            <motion.button
                                initial={{ opacity: 0, x: 10 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 10 }}
                                transition={{ duration: 0.2 }}
                                onClick={resetFilters}
                                className="text-xs text-slate-500 hover:text-blue-700 flex items-center"
                                disabled={isSelectionLocked} // Deshabilitar cuando la selección está bloqueada
                            >
                                <FiRefreshCcw className="mr-1 h-3 w-3" /> Limpiar
                            </motion.button>
                        )}
                    </AnimatePresence>
                </div>

                {/* Contenido de filtros que se muestra/oculta */}
                <AnimatePresence>
                    {showFilters && !isSelectionLocked && (
                        <motion.div
                            className="space-y-3"
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                            <motion.div
                                className="grid grid-cols-1 gap-2"
                                initial={{ opacity: 0, y: -5 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2, delay: 0.1 }}
                            >
                                <input
                                    type="text"
                                    placeholder="Buscar por código..."
                                    value={codigoFilter}
                                    onChange={(e) => setCodigoFilter(e.target.value)}
                                    className="w-full px-3 py-1.5 text-sm rounded-md border border-slate-200 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                />
                            </motion.div>

                            <motion.div
                                className="grid grid-cols-1 gap-3"
                                initial={{ opacity: 0, y: -5 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2, delay: 0.15 }}
                            >
                                <select
                                    value={estadoFilter}
                                    onChange={(e) => setEstadoFilter(e.target.value)}
                                    className="px-2 py-1.5 text-sm rounded-md border border-slate-200 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                >
                                    <option value="">Estado: Todos</option>
                                    {estadosDisponibles.map(estado => (
                                        <option key={estado} value={estado}>{estado}</option>
                                    ))}
                                </select>
                            </motion.div>

                            {/* Filtro por rango de fechas */}
                            <motion.div
                                className="mt-1"
                                initial={{ opacity: 0, y: -5 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2, delay: 0.2 }}
                            >
                                <div className="flex items-center mb-1.5">
                                    <input
                                        type="checkbox"
                                        id="dateFilterToggle"
                                        checked={dateFilterEnabled}
                                        onChange={(e) => setDateFilterEnabled(e.target.checked)}
                                        className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                                    />
                                    <label htmlFor="dateFilterToggle" className="ml-2 text-sm text-slate-600">
                                        Filtrar por fechas
                                    </label>
                                </div>
                                <div className={`${dateFilterEnabled ? '' : 'opacity-50'} grid grid-cols-2 gap-3`}>
                                    <div>
                                        <label className="block text-xs text-slate-500 mb-1">Desde:</label>
                                        <input
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            disabled={!dateFilterEnabled}
                                            className="w-full px-2 py-1.5 text-sm rounded-md border border-slate-200 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-xs text-slate-500 mb-1">Hasta:</label>
                                        <input
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            disabled={!dateFilterEnabled}
                                            className="w-full px-2 py-1.5 text-sm rounded-md border border-slate-200 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                        />
                                    </div>
                                </div>
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            {loading ? (
                <div className="flex justify-center items-center h-32">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-700"></div>
                </div>
            ) : error ? (
                <div className="text-red-500 text-center p-4 bg-red-50 rounded-md">
                    Error al cargar solicitudes: {error}
                </div>
            ) : (
                <motion.div
                    layout
                    className={`space-y-3 ${showFilters ? "h-[calc(100vh-36.7rem)]" : "h-[calc(100vh-25.5rem)]"} overflow-y-auto pr-1 scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-slate-100`}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                    {filteredSolicitudes.length > 0 ? filteredSolicitudes.map((solicitud) => (
                        <SolicitudCompraCard
                            key={solicitud.id}
                            solicitud={solicitud}
                            isSelected={solicitud.id === selectedSolicitudId}
                            onCheckboxChange={handleCheckboxChange}
                            isSelectionLocked={isSelectionLocked} // Pasar el estado de bloqueo
                        />
                    )) : (
                        <div className="text-center p-4 text-slate-500 bg-slate-50 rounded-md">
                            No se encontraron solicitudes con los filtros seleccionados
                        </div>
                    )}
                </motion.div>
            )}

            {/* Contador de resultados */}
            {!loading && !error && (
                <motion.div
                    layout
                    className="mt-1 text-xs text-slate-500 text-right"
                    transition={{ duration: 0.2 }}
                >
                    {filteredSolicitudes.length} resultado{filteredSolicitudes.length !== 1 ? 's' : ''}
                </motion.div>
            )}

            {/* Modal de confirmación para cambio de obra */}
            {showConfirmModal &&
                <ModalAlert
                    isOpen={showConfirmModal}
                    onCancel={() => setShowConfirmModal(false)}
                    title="Cambiar de obra"
                    message="Al cambiar de obra se eliminarán todas las solicitudes seleccionadas y sus recursos asociados. ¿Desea continuar?"
                    variant="red"
                    onConfirm={confirmObraChange}
                />
            }
            {showConfirmModalReset &&
                <ModalAlert
                    isOpen={showConfirmModalReset}
                    onCancel={() => setShowConfirmModalReset(false)}
                    title="Resetear filtros"
                    message="Al restablecer los filtros se eliminarán todas las solicitudes seleccionadas y sus recursos asociados. ¿Desea continuar?"
                    variant="red"
                    onConfirm={resetFilters}
                />
            }
        </div>
    );
};

export default SolicitudList;
