import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { 
  addSeccion, 
  updateSeccion,
  fetchSeccionesByFormulario
} from '../../slices/formularioSlice';
import CondicionalMostrarSeccionForm from './CondicionalMostrarSeccionForm';

interface SeccionFormProps {
  formularioId: string;
  seccionId: string | null;
  isEditMode: boolean;
  onClose: () => void;
}

const SeccionForm: React.FC<SeccionFormProps> = ({ 
  formularioId, 
  seccionId, 
  isEditMode, 
  onClose 
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { secciones, loading } = useSelector((state: RootState) => state.formulario);
  
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [orden, setOrden] = useState(0);
  const [condicionalMostrar, setCondicionalMostrar] = useState('');
  
  const [formErrors, setFormErrors] = useState({
    titulo: '',
    orden: ''
  });

  // Obtener todos los campos disponibles para el condicional
  const camposDisponibles = secciones
    .filter(s => s.formularioId === formularioId && s.id !== seccionId)
    .flatMap(s => s.campos || []);

  useEffect(() => {
    if (isEditMode && seccionId) {
      const seccion = secciones.find(s => s.id === seccionId);
      
      if (seccion) {
        setTitulo(seccion.titulo);
        setDescripcion(seccion.descripcion || '');
        setOrden(seccion.orden);
        if (seccion.condicionalMostrar) {
          try {
            const condicion = seccion.condicionalMostrar.replace(/\\"/g, '"');
            setCondicionalMostrar(condicion);
          } catch (error) {
            setCondicionalMostrar(seccion.condicionalMostrar);
          }
        } else {
          setCondicionalMostrar('');
        }
      }
    } else {
      // Obtener el orden más alto existente y sumar 1 para la nueva sección
      const maxOrden = secciones.reduce((max, seccion) => 
        seccion.formularioId === formularioId && seccion.orden > max ? seccion.orden : max, 0);
      setOrden(maxOrden + 1);
      setTitulo('');
      setDescripcion('');
      setCondicionalMostrar('');
    }
  }, [isEditMode, seccionId, secciones, formularioId]);

  const validateForm = () => {
    const errors = {
      titulo: '',
      orden: ''
    };
    let isValid = true;

    if (!titulo.trim()) {
      errors.titulo = 'El título es requerido';
      isValid = false;
    }

    if (orden <= 0) {
      errors.orden = 'El orden debe ser mayor a 0';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    if (isEditMode && seccionId) {
      dispatch(updateSeccion({
        updateSeccionId: seccionId,
        titulo,
        descripcion,
        orden,
        condicionalMostrar
      }));
    } else {
      dispatch(addSeccion({
        formularioId,
        titulo,
        descripcion,
        orden,
        condicionalMostrar
      }));
    }
    
    // Refrescar las secciones
    dispatch(fetchSeccionesByFormulario(formularioId));
    
    onClose();
  };

  if (loading) return <div className="p-4">Cargando...</div>;

  return (
    <form onSubmit={handleSubmit} className="p-8 bg-gradient-to-br from-white to-gray-50 rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.06)] backdrop-blur-sm border border-gray-100">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="col-span-1">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="titulo-seccion">
              Título
            </label>
            <input
              id="titulo-seccion"
              type="text"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              className={`w-full p-3 border-2 rounded-xl transition-all duration-300 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 ${
                formErrors.titulo ? 'border-red-300 bg-red-50' : 'border-gray-200 hover:border-blue-200'
              }`}
            />
            {formErrors.titulo ? (
              <p className="mt-2 text-xs text-red-500 font-medium">{formErrors.titulo}</p>
            ) : (
              <p className="mt-2 text-xs text-gray-500">Nombre de la sección</p>
            )}
          </div>
        </div>

        <div className="col-span-1">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="orden-seccion">
              Orden
            </label>
            <input
              id="orden-seccion"
              type="number"
              min="1"
              value={orden}
              onChange={(e) => setOrden(parseInt(e.target.value))}
              className={`w-full p-3 border-2 rounded-xl transition-all duration-300 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 ${
                formErrors.orden ? 'border-red-300 bg-red-50' : 'border-gray-200 hover:border-blue-200'
              }`}
            />
            {formErrors.orden ? (
              <p className="mt-2 text-xs text-red-500 font-medium">{formErrors.orden}</p>
            ) : (
              <p className="mt-2 text-xs text-gray-500">Posición en el formulario</p>
            )}
          </div>
        </div>

        <div className="col-span-1 md:col-span-2">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2" htmlFor="descripcion-seccion">
              Descripción
            </label>
            <textarea
              id="descripcion-seccion"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              rows={3}
              className="w-full p-3 border-2 border-gray-200 rounded-xl transition-all duration-300 hover:border-blue-200 focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
            />
            <p className="mt-2 text-xs text-gray-500">Descripción opcional de la sección</p>
          </div>
        </div>

        <div className="col-span-1 md:col-span-2">
          <div className="mb-6">
            <label className="block text-sm font-semibold text-gray-700 mb-2">
              Condición para mostrar
            </label>
            <CondicionalMostrarSeccionForm
              campos={camposDisponibles}
              condicionalMostrar={condicionalMostrar}
              onChange={setCondicionalMostrar}
            />
          </div>
        </div>

        <div className="col-span-1 md:col-span-2">
          <div className="flex justify-end space-x-4 mt-6">
            <button 
              type="button" 
              onClick={onClose} 
              className="px-6 py-2.5 text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200 transition-all duration-300 font-medium text-sm shadow-sm hover:shadow-md"
            >
              Cancelar
            </button>
            <button 
              type="submit" 
              className="px-6 py-2.5 text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-xl hover:from-blue-600 hover:to-blue-700 transition-all duration-300 font-medium text-sm shadow-md hover:shadow-lg"
            >
              {isEditMode ? 'Actualizar' : 'Crear'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SeccionForm; 