import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import EyeIcon from '../../components/Icons/EyeIcon';
import EyeSlashIcon from '../../components/Icons/EyeSlashIcon';
import Button from '../../components/Buttons/Button';

interface UsuarioFormData {
  nombres: string;
  apellidos: string;
  dni: string;
  usuario: string;
  contrasenna: string;
  cargo_id: string;
  rol_id: string;
}

interface Cargo {
  id: string;
  nombre: string;
  descripcion: string;
  gerarquia: number;
}

interface FormComponentProps {
  initialValues?: UsuarioFormData;
  onSubmit: (data: UsuarioFormData) => void;
  cargos: Cargo[];
}

const UsuarioFormComponent: React.FC<FormComponentProps> = ({ initialValues, onSubmit, cargos }) => {
  const { roles } = useSelector((state: RootState) => state.role);
  const [formData, setFormData] = useState<UsuarioFormData>(initialValues || {
    nombres: '',
    apellidos: '',
    dni: '',
    usuario: '',
    contrasenna: '',
    cargo_id: '',
    rol_id: '1',
  });

  const [errors, setErrors] = useState<Partial<Record<keyof UsuarioFormData, string>>>({});
  const [showPassword, setShowPassword] = useState(false);

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof UsuarioFormData, string>> = {};

    if (!formData.nombres) newErrors.nombres = 'El nombre es requerido';
    if (!formData.apellidos) newErrors.apellidos = 'Los apellidos son requeridos';
    if (!formData.dni) newErrors.dni = 'El DNI es requerido';
    if (formData.dni.length < 8) newErrors.dni = 'El DNI debe tener 8 dígitos';
    if (formData.dni.length > 11) newErrors.dni = 'El DNI debe tener maximo 11 dígitos';
    if (!formData.usuario) newErrors.usuario = 'El usuario es requerido';
    if (!formData.rol_id) newErrors.rol_id = 'El Rol es requerido';
    if (!formData.contrasenna) newErrors.contrasenna = 'La contraseña es requerida';
    if (formData.contrasenna.length < 6) newErrors.contrasenna = 'La contraseña debe tener al menos 6 caracteres';
    if (!formData.cargo_id) newErrors.cargo_id = 'El cargo es requerido';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Limpiar error del campo cuando el usuario empiece a escribir
    if (errors[name as keyof UsuarioFormData]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto">
      {['nombres', 'apellidos', 'usuario'].map((field) => (
        <div key={field} className="mb-4">
          <label htmlFor={field} className="block text-gray-700 text-sm font-bold mb-2">
            {field.charAt(0).toUpperCase() + field.slice(1)}:
          </label>
          <input
            id={field}
            name={field}
            placeholder={`Ingrese ${field}`}
            value={formData[field as keyof UsuarioFormData]}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors[field as keyof UsuarioFormData] && (
            <p className="text-red-500 text-xs italic mt-1">{errors[field as keyof UsuarioFormData]}</p>
          )}
        </div>
      ))}

      <div className="mb-4">
        <label htmlFor="dni" className="block text-gray-700 text-sm font-bold mb-2">DNI:</label>
        <input
          id="dni"
          name="dni"
          type="text"
          placeholder="Ingrese DNI"
          value={formData.dni}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        {errors.dni && <p className="text-red-500 text-xs italic mt-1">{errors.dni}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="contrasenna" className="block text-gray-700 text-sm font-bold mb-2">Contraseña:</label>
        <div className="relative">
          <input
            id="contrasenna"
            name="contrasenna"
            type={showPassword ? 'text' : 'password'}
            placeholder="Ingrese contraseña"
            value={formData.contrasenna}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-10"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            {showPassword ? (
              <EyeSlashIcon className="h-5 w-5 text-gray-500" />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-500" />
            )}
          </button>
          {errors.contrasenna && <p className="text-red-500 text-xs italic mt-1">{errors.contrasenna}</p>}
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="cargo_id" className="block text-gray-700 text-sm font-bold mb-2">Cargo:</label>
        <select
          id="cargo_id"
          name="cargo_id"
          value={formData.cargo_id}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">Seleccione un cargo</option>
          {cargos.map((cargo) => (
            <option key={cargo.id} value={cargo.id}>
              {cargo.nombre}
            </option>
          ))}
        </select>
        {errors.cargo_id && <p className="text-red-500 text-xs italic mt-1">{errors.cargo_id}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="rol_id" className="block text-gray-700 text-sm font-bold mb-2">Rol:</label>
        <select
          id="rol_id"
          name="rol_id"
          value={formData.rol_id}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">Seleccione un cargo</option>
          {roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.nombre}
            </option>
          ))}
        </select>
        {errors.rol_id && <p className="text-red-500 text-xs italic mt-1">{errors.rol_id}</p>}
      </div>

      <div className="flex items-center justify-center mt-6">
        <Button
          text={initialValues ? 'Actualizar Usuario' : 'Crear Usuario'}
          color="verde"
          className="w-auto px-6 py-2 text-sm font-medium"
        />
      </div>
    </form>
  );
};

export default UsuarioFormComponent;