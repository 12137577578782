import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addEspecialidad, Especialidad, CreateEspecialidadDto } from '../../../../slices/especialidadSlice';
import { AppDispatch, RootState } from '../../../../store/store';
import ModalAlert from '../../../../components/Modal/ModalAlert';

interface EspecialidadFormProps {
  onSubmit: (especialidad: Especialidad) => void;
  onCancel: () => void;
}

const EspecialidadForm: React.FC<EspecialidadFormProps> = ({ onSubmit, onCancel }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState<Partial<Especialidad>>({
    nombre: '',
    descripcion: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const especialidades = useSelector((state: RootState) => state.especialidad.especialidades);
  const [especialidadesSimilares, setEspecialidadesSimilares] = useState<Especialidad[]>([]);

  const normalizarTexto = (texto: string): string => {
    return texto.toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, ''); // Elimina diacríticos (acentos)
  };

  const buscarEspecialidadesSimilares = (nombre: string) => {
    if (nombre.length >= 4) {
      const nombreNormalizado = normalizarTexto(nombre);
      const similares = especialidades.filter(esp => {
        const especialidadNormalizada = normalizarTexto(esp.nombre);
        return especialidadNormalizada.includes(nombreNormalizado) &&
               especialidadNormalizada !== nombreNormalizado;
      });
      setEspecialidadesSimilares(similares);
    } else {
      setEspecialidadesSimilares([]);
    }
  };

  const handleNombreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nuevoNombre = e.target.value;
    setFormData({...formData, nombre: nuevoNombre});
    buscarEspecialidadesSimilares(nuevoNombre);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Verificar si ya existe una especialidad con el mismo nombre
    const especialidadExistente = especialidades.find(
      esp => esp.nombre.toLowerCase() === formData.nombre?.toLowerCase()
    );

    if (especialidadExistente) {
      setAlertMessage('Ya existe una especialidad con este nombre.');
      setShowAlert(true);
      return;
    }

    try {
      const nuevaEspecialidad: CreateEspecialidadDto = {
        nombre: formData.nombre || '',
        descripcion: formData.descripcion || '-',
      };
      
      const resultAction = await dispatch(addEspecialidad(nuevaEspecialidad));
      const nuevaEspecialidadConId = resultAction.payload as Especialidad;
      onSubmit(nuevaEspecialidadConId);
    } catch (error) {
      console.error('Error al crear especialidad:', error);
      setAlertMessage('Error al crear la especialidad. Por favor, intente nuevamente.');
      setShowAlert(true);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6 bg-[#0a192f] rounded-xl p-6 shadow-lg">
        <div className="space-y-2 relative">
          <label className="block text-sm font-semibold text-gray-200">
            Nombre de la Especialidad
          </label>
          <input
            type="text"
            value={formData.nombre || ''}
            onChange={handleNombreChange}
            className="mt-1 block w-full rounded-md bg-[#112240] border border-gray-600 text-gray-100 px-4 py-2.5 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200"
            required
            placeholder="Ingrese el nombre de la especialidad"
          />
          
          {/* Lista de especialidades similares */}
          {especialidadesSimilares.length > 0 && (
            <div className="absolute z-10 w-full mt-1 bg-[#112240] border border-gray-600 rounded-md shadow-lg">
              <div className="p-2 text-sm text-gray-300">
                Especialidades similares encontradas:
              </div>
              {especialidadesSimilares.map((esp) => (
                <div 
                  key={esp.id_especialidad} 
                  className="px-4 py-2 text-gray-200 hover:bg-[#1a365d] cursor-pointer border-t border-gray-600"
                >
                  {esp.nombre}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-200">
            Descripción
          </label>
          <textarea
            value={formData.descripcion || ''}
            onChange={(e) => setFormData({...formData, descripcion: e.target.value})}
            className="mt-1 block w-full rounded-md bg-[#112240] border border-gray-600 text-gray-100 px-4 py-2.5 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200"
            rows={3}
            placeholder="Ingrese una descripción (opcional)"
          />
        </div>

        <div className="flex justify-end space-x-4 pt-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2.5 bg-gray-700 text-gray-200 rounded-md hover:bg-gray-600 transition duration-200 font-medium"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="px-4 py-2.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200 font-medium"
          >
            Crear Especialidad
          </button>
        </div>
      </form>

      {showAlert && 
      <ModalAlert
        isOpen={showAlert}
        title="Alerta"
        message={alertMessage}
        onConfirm={() => setShowAlert(false)}
        onCancel={() => setShowAlert(false)}
        variant="red"
      />}
    </>
  );
};

export default EspecialidadForm;
