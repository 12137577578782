import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_APROBACIONES = gql`
  query ListAprobacionesComprobante {
    listAprobacionesComprobante {
      id
      comprobante_id {
        id
        serie
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const GET_APROBACIONES_BY_COMPROBANTE = gql`
  query GetAprobacionesByComprobanteId($comprobanteId: ID!) {
    getAprobacionesByComprobanteId(comprobanteId: $comprobanteId) {
      id
      comprobante_id {
        id
        serie
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const ADD_APROBACION = gql`
  mutation AddAprobacionComprobante(
    $comprobante_id: ID!
    $usuario_id: ID!
    $estado: String!
    $fecha: DateTime
  ) {
    addAprobacionComprobante(
      comprobante_id: $comprobante_id
      usuario_id: $usuario_id
      estado: $estado
      fecha: $fecha
    ) {
      id
      comprobante_id {
        id
        serie
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const UPDATE_APROBACION = gql`
  mutation UpdateAprobacionComprobante(
    $id: ID!
    $comprobante_id: ID
    $usuario_id: ID
    $estado: String
    $fecha: DateTime
  ) {
    updateAprobacionComprobante(
      id: $id
      comprobante_id: $comprobante_id
      usuario_id: $usuario_id
      estado: $estado
      fecha: $fecha
    ) {
      id
      estado
      fecha
    }
  }
`;

const DELETE_APROBACION = gql`
  mutation DeleteAprobacionComprobante($id: ID!) {
    deleteAprobacionComprobante(id: $id) {
      id
    }
  }
`;

export interface AprobacionComprobanteInput {
  comprobante_id: string;
  usuario_id: string;
  estado: string;
  fecha?: Date;
}

export interface AprobacionComprobante {
  id: string;
  comprobante_id: {
    id: string;
    serie: string;
  };
  usuario_id: {
    id: string;
    usuario: string;
  };
  estado: string;
  fecha: string;
}

export const listAprobacionesService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_APROBACIONES,
      fetchPolicy: 'network-only',
    });
    return data.listAprobacionesComprobante;
  } catch (error) {
    throw new Error(`Error fetching aprobaciones: ${error}`);
  }
};

export const getAprobacionesByComprobanteService = async (comprobanteId: string) => {
  try {
    const { data } = await client.query({
      query: GET_APROBACIONES_BY_COMPROBANTE,
      variables: { comprobanteId },
      fetchPolicy: 'network-only',
    });
    return data.getAprobacionesByComprobanteId;
  } catch (error) {
    throw new Error(`Error fetching aprobaciones by comprobante: ${error}`);
  }
};

export const addAprobacionService = async (input: AprobacionComprobanteInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_APROBACION,
      variables: input,
    });
    return data.addAprobacionComprobante;
  } catch (error) {
    throw new Error(`Error adding aprobacion: ${error}`);
  }
};

export const updateAprobacionService = async (id: string, input: Partial<AprobacionComprobanteInput>) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_APROBACION,
      variables: { id, ...input },
    });
    return data.updateAprobacionComprobante;
  } catch (error) {
    throw new Error(`Error updating aprobacion: ${error}`);
  }
};

export const deleteAprobacionService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_APROBACION,
      variables: { id },
    });
    return data.deleteAprobacionComprobante;
  } catch (error) {
    throw new Error(`Error deleting aprobacion: ${error}`);
  }
};
