import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_RECURSOS_COMPOSICION_APU_QUERY = gql`
  query ListRecursosComposicionApu {
    listRecursosComposicionApu {
      id_rec_comp_apu
      recurso_id
      unidad_id
      nombre
      especificaciones
      descripcion
      fecha_creacion
      recurso {
        id
        recurso_id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
        imagenes {
          id
          file
        }
      }
      unidad {
        id
        unidad_id
        nombre
        descripcion
      }
    }
  }
`;

const GET_RECURSO_COMPOSICION_APU_QUERY = gql`
  query GetRecursoComposicionApu($idRecCompApu: String!) {
    getRecursoComposicionApu(id_rec_comp_apu: $idRecCompApu) {
      id_rec_comp_apu
      recurso_id
      unidad_id
      nombre
      especificaciones
      descripcion
      fecha_creacion
      recurso {
        id
        recurso_id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
        imagenes {
          id
          file
        }
      }
      unidad {
        id
        unidad_id
        nombre
        descripcion
      }
    }
  }
`;

const ADD_RECURSO_COMPOSICION_APU_MUTATION = gql`
  mutation AddRecursoComposicionApu(
    $recursoId: String!
    $unidadId: String!
    $nombre: String!
    $especificaciones: String
    $descripcion: String
  ) {
    addRecursoComposicionApu(
      recurso_id: $recursoId
      unidad_id: $unidadId
      nombre: $nombre
      especificaciones: $especificaciones
      descripcion: $descripcion
    ) {
      id_rec_comp_apu
      recurso_id
      unidad_id
      nombre
      especificaciones
      descripcion
      fecha_creacion
      recurso {
        id
        recurso_id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
        imagenes {
          id
          file
        }
      }
      unidad {
        id
        unidad_id
        nombre
        descripcion
      }
    }
  }
`;

const UPDATE_RECURSO_COMPOSICION_APU_MUTATION = gql`
  mutation UpdateRecursoComposicionApu(
    $idRecCompApu: String!
    $recursoId: String
    $unidadId: String
    $nombre: String
    $especificaciones: String
    $descripcion: String
  ) {
    updateRecursoComposicionApu(
      id_rec_comp_apu: $idRecCompApu
      recurso_id: $recursoId
      unidad_id: $unidadId
      nombre: $nombre
      especificaciones: $especificaciones
      descripcion: $descripcion
    ) {
      id_rec_comp_apu
      recurso_id
      unidad_id
      nombre
      especificaciones
      descripcion
      fecha_creacion
      recurso {
        id
        recurso_id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
        imagenes {
          id
          file
        }
      }
      unidad {
        id
        unidad_id
        nombre
        descripcion
      }
    }
  }
`;

const DELETE_RECURSO_COMPOSICION_APU_MUTATION = gql`
  mutation DeleteRecursoComposicionApu($idRecCompApu: String!) {
    deleteRecursoComposicionApu(id_rec_comp_apu: $idRecCompApu) {
      id_rec_comp_apu
    }
  }
`;

export const listRecursosComposicionApuService = async () => {
  try {
    const response = await client.query({
      query: LIST_RECURSOS_COMPOSICION_APU_QUERY,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.listRecursosComposicionApu;
  } catch (error) {
    console.error('Error al obtener la lista de recursos composición APU:', error);
    throw error;
  }
};

export const getRecursoComposicionApuService = async (id_rec_comp_apu: string) => {
  try {
    const response = await client.query({
      query: GET_RECURSO_COMPOSICION_APU_QUERY,
      variables: { idRecCompApu: id_rec_comp_apu },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.getRecursoComposicionApu;
  } catch (error) {
    console.error('Error al obtener el recurso composición APU:', error);
    throw error;
  }
};

export const addRecursoComposicionApuService = async (data: {
  recursoId: string;
  unidadId: string;
  nombre: string;
  especificaciones?: string;
  descripcion?: string;
}) => {
  try {
    const response = await client.mutate({
      mutation: ADD_RECURSO_COMPOSICION_APU_MUTATION,
      variables: data,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.addRecursoComposicionApu;
  } catch (error) {
    console.error('Error al crear el recurso composición APU:', error);
    throw error;
  }
};

export const updateRecursoComposicionApuService = async (data: {
  idRecCompApu: string;
  recursoId?: string;
  unidadId?: string;
  nombre?: string;
  especificaciones?: string;
  descripcion?: string;
}) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_RECURSO_COMPOSICION_APU_MUTATION,
      variables: data,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.updateRecursoComposicionApu;
  } catch (error) {
    console.error('Error al actualizar el recurso composición APU:', error);
    throw error;
  }
};

export const deleteRecursoComposicionApuService = async (id_rec_comp_apu: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_RECURSO_COMPOSICION_APU_MUTATION,
      variables: { idRecCompApu: id_rec_comp_apu },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.deleteRecursoComposicionApu;
  } catch (error) {
    console.error('Error al eliminar el recurso composición APU:', error);
    throw error;
  }
};
