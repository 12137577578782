import React from 'react';

interface ViewToggleButtonProps {
  activeView: 'partidas' | 'productos';
  onToggle: () => void;
}

const ViewToggleButton: React.FC<ViewToggleButtonProps> = ({ activeView, onToggle }) => {
  return (
    <div className="flex w-auto justify-start mb-0">
      <button
        type="button"
        onClick={onToggle}
        className="px-4 py-2 text-sm w-auto font-medium rounded-lg border transition-all duration-200 ease-in-out
          hover:shadow-md
          flex items-center gap-2
          bg-blue-600 text-white border-gray-200 hover:bg-blue-700 hover:text-white"
      >
        <span>
          {activeView === 'partidas' ? 'Ver Productos' : 'Ver Partidas'}
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m-4 6H4m0 0l4 4m-4-4l4-4" />
        </svg>
      </button>
    </div>
  );
};

export default ViewToggleButton;
