import React, { useState, useEffect } from 'react';
import { FiEye, FiFileText, FiPlus, FiTrash2, FiLoader } from 'react-icons/fi';
import Modal from '../../../components/Modal/Modal';
//import TableComponent from '../../../components/Table/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchOrdenCompras } from '../../../slices/ordenCompraSlice';
//import PDFOCGenerator from '../../OrdenCompraPage/PDFOCGenerator';
import { fetchOrdenCompraRecursosByOrdenId } from '../../../slices/ordenCompraRecursosSlice';
import ListConstancias from './ListConstancias';
import { fetchConstanciasForOrden, fetchIngresosAlmacenForOrden } from '../../../slices/ordenPagoSlice';
import SubirComprobante from './SubirComprobante';
import ListOrdenIngreso from './ListOrdenIngreso';
import ListOrdenesCompra from './ListOrdenesCompra';
import { addRegistro } from '../../../slices/registroSlice';
import { addConstanciaComprobante } from '../../../slices/constanciaComprobanteSlice';
import { addIngresoAlmacenComprobante } from '../../../slices/ingresoAlmacenComprobanteSlice';
import { addComprobanteOrdenCompra } from '../../../slices/comprobanteOrdenCompraSlice';
// Agregar import de Toast
import Toast from '../../../components/Toast/Toast';

interface ConstanciaEntry {
    id: string;
    codigo: string;
    monto: number;
    archivo_pago_id: string | null;
    pago_orden_id: string | null;
}

// Agregar nueva interfaz junto a ConstanciaEntry
interface IngresoAlmacenEntry {
    id: string;
    transferencia_id: string;
    monto: number;
    detalle_id: string; // Agregar este campo
}

// Agregar nueva interfaz para las órdenes de compra
interface OrdenCompraEntry {
    id: string;
    codigo: string;
    monto: number;
}

interface NuevoRegistroProps {
    onClose: () => void;
    onSaveSuccess: () => void;
}

const NuevoRegistro: React.FC<NuevoRegistroProps> = ({ onClose, onSaveSuccess }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    //const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
   // const [selectedOrdenCompra, setSelectedOrdenCompra] = useState<any>(null);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedOC, setSelectedOC] = useState<any>(null);
    const [isConstanciaModalOpen, setIsConstanciaModalOpen] = useState(false);
    const [isSubirComprobanteOpen, setIsSubirComprobanteOpen] = useState(false);
    const [isIngresoAlmacenModalOpen, setIsIngresoAlmacenModalOpen] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { ordenCompras } = useSelector((state: RootState) => state.ordenCompra);
    const [ordenCompraInput] = useState('');
    //const [montoInput, setMontoInput] = useState('');
    const [constanciaEntries, setConstanciaEntries] = useState<ConstanciaEntry[]>([]);
    const [documentoInput, setDocumentoInput] = useState('');
    const [documentoMonto, setDocumentoMonto] = useState('');
    //const [ingresoAlmacenInput, setIngresoAlmacenInput] = useState(''); // Agregar este estado
   // const [ingresoAlmacenMonto, setIngresoAlmacenMonto] = useState(''); // Agregar este estado
    const [ingresoAlmacenEntries, setIngresoAlmacenEntries] = useState<IngresoAlmacenEntry[]>([]);
    const [ordenCompraEntries, setOrdenCompraEntries] = useState<OrdenCompraEntry[]>([]);
    const [documentoId, setDocumentoId] = useState<string>('');
    const [documentoInfo, setDocumentoInfo] = useState({
        ruc: '',
        razonSocial: '',
        tipoComprobante: '',
        fechaEmision: '',
        fechaVencimiento: '',
        montoSinIgv: '',
        serie: '',
        monto: '',
        condicionPago: '',
        agenteRetencion: '',
        descripcion: ''
      });

    const userId = useSelector((state: RootState) => state.user.id);
    const nombreUsuario = useSelector((state: RootState) => state.user.usuario);
    //const apellidoUsuario = useSelector((state: RootState) => state.user.apellido);
    const { ordenCompraRecursosByOrdenId } = useSelector((state: RootState) => state.ordenCompraRecursos);
    const { constancias, loadingConstancias } = useSelector((state: RootState) => state.ordenPago);

    // Agregar estado para Toast
    const [toast, setToast] = useState<{
        show: boolean;
        message: string;
        variant: 'success' | 'danger' | 'warning' | 'info';
      }>({
        show: false,
        message: '',
        variant: 'info'
      });
    
      // Agregar función helper para mostrar Toast
      const showToast = (message: string, variant: 'success' | 'danger' | 'warning' | 'info') => {
        setToast({ show: true, message, variant });
      };

    useEffect(() => {
        if (isModalOpen) {
            dispatch(fetchOrdenCompras());
        }
    }, [dispatch, isModalOpen]);


    const handleAddDocument = (oc: any) => {
        setSelectedOC(oc);
        setIsConfirmModalOpen(true);
    };

    // Modificar la función de cálculo
    const calcularMontoTotal = async (ordenCompraId: string) => {
        // Primero obtener los recursos de la orden de compra
        await dispatch(fetchOrdenCompraRecursosByOrdenId(ordenCompraId));
        
        // Calcular el total sumando los montos reales
        const total = ordenCompraRecursosByOrdenId.reduce((sum, recurso) => {
            const montoReal = Number(recurso.costo_real) * Number(recurso.cantidad) || 0;
            return sum + montoReal;
        }, 0);

        // Aplicar IGV
        //const totalConIGV = total * 1.18;
        //return totalConIGV.toFixed(2);
        return total.toFixed(2);
    };

    // useEffect(() => {
    //     if (selectedOC) {
    //         calcularMontoTotal(selectedOC.id).then(montoCalculado => {
    //            // setMontoInput(montoCalculado);
    //         });
    //     }
    // }, [ordenCompraRecursosByOrdenId, selectedOC]);

    // Modificar handleConfirmAdd para incluir el ID real de la orden de compra
    const handleConfirmAdd = async () => {
        if (selectedOC) {
            const montoCalculado = await calcularMontoTotal(selectedOC.id);
            const newEntry = {
                id: selectedOC.id, // Usar el ID real de la orden de compra
                codigo: selectedOC.codigo_orden,
                monto: Number(montoCalculado)
            };
            setOrdenCompraEntries([...ordenCompraEntries, newEntry]);
        }
        setIsConfirmModalOpen(false);
        setSelectedOC(null);
        setIsModalOpen(false);
    };

    // Agregar función para eliminar orden de compra
    const handleRemoveOrdenCompra = (id: string) => {
        setOrdenCompraEntries(entries => entries.filter(entry => entry.id !== id));
    };

    // Calcular total de órdenes de compra
    const totalOrdenesCompra = ordenCompraEntries.reduce((sum, entry) => sum + entry.monto, 0);

    const handleConstanciaClick = () => {
        // Verificamos si hay órdenes de compra agregadas
        if (ordenCompraEntries.length === 0) {
            showToast('Por favor agregue al menos una orden de compra primero', 'warning');
            return;
        }

        // Usamos la última orden de compra agregada para obtener las constancias
        const ultimaOrden = ordenCompras.find(oc => 
            oc.codigo_orden === ordenCompraEntries[ordenCompraEntries.length - 1].codigo
        );

        if (!ultimaOrden) {
            showToast('No se encontró la orden de compra', 'danger');
            return;
        }

        // Establecemos la orden seleccionada y despachamos la acción
        setSelectedOC(ultimaOrden);
        dispatch(fetchConstanciasForOrden(ultimaOrden.id));
        setIsConstanciaModalOpen(true);
    };

    const handleConstanciaSelect = (constancia: any) => {
        console.log("Constancia recibida:", constancia);
        
        const newEntry = {
            id: Math.random().toString(36).substr(2, 9),
            codigo: constancia.codigo,
            monto: constancia.monto_pago || 0,
            archivo_pago_id: constancia.archivo_pago_id,
            pago_orden_id: constancia.pago_orden_id
        };
        
        console.log("Nueva entrada creada:", newEntry);
        setConstanciaEntries([...constanciaEntries, newEntry]);
        setIsConstanciaModalOpen(false);
    };

    const handleRemoveConstancia = (id: string) => {
        setConstanciaEntries(entries => entries.filter(entry => entry.id !== id));
    };

    // Agregar un cálculo del total
    const totalConstancias = constanciaEntries.reduce((sum, entry) => sum + entry.monto, 0);
    const montoRestanteConstancias = totalOrdenesCompra - totalConstancias;

    const handleDocumentoClick = () => {
        // Verificamos si hay órdenes de compra agregadas
        if (ordenCompraEntries.length === 0) {
            showToast('Por favor agregue al menos una orden de compra primero', 'warning');
            return;
        }

        // Usamos la última orden de compra agregada
        const ultimaOrden = ordenCompras.find(oc => 
            oc.codigo_orden === ordenCompraEntries[ordenCompraEntries.length - 1].codigo
        );

        if (!ultimaOrden) {
            showToast('No se encontró la orden de compra', 'danger');
            return;
        }
        setIsSubirComprobanteOpen(true);
    };

    const handleIngresoAlmacenClick = () => {
        // Verificamos si hay órdenes de compra agregadas
        if (ordenCompraEntries.length === 0) {
            showToast('Por favor agregue al menos una orden de compra primero', 'warning');
            return;
        }

        // Usamos la última orden de compra agregada
        const ultimaOrden = ordenCompras.find(oc => 
            oc.codigo_orden === ordenCompraEntries[ordenCompraEntries.length - 1].codigo
        );

        if (!ultimaOrden) {
            showToast('No se encontró la orden de compra', 'danger');
            return;
        }
        dispatch(fetchIngresosAlmacenForOrden(ultimaOrden.id));
        setIsIngresoAlmacenModalOpen(true);
    };

    // Modificar la función handleIngresoSelect
    const handleIngresoSelect = (ingreso: any) => {
        const newEntry = {
            id: Math.random().toString(36).substr(2, 9),
            transferencia_id: ingreso.transferencia_id,
            monto: ingreso.monto_total,
            detalle_id: ingreso._id // Agregar el _id del ingreso
        };
        console.log("Nuevo ingreso agregado:", newEntry); // Para debugging
        setIngresoAlmacenEntries([...ingresoAlmacenEntries, newEntry]);
        setIsIngresoAlmacenModalOpen(false);
    };

    // Agregar función para eliminar ingreso
    const handleRemoveIngreso = (id: string) => {
        setIngresoAlmacenEntries(entries => entries.filter(entry => entry.id !== id));
    };

    // Agregar cálculo del total de ingresos
    const totalIngresos = ingresoAlmacenEntries.reduce((sum, entry) => sum + entry.monto, 0);
    const montoRestanteIngresos = totalOrdenesCompra - totalIngresos;

    const montoPendienteDocumento = totalOrdenesCompra - Number(documentoMonto);

    // Modificar el handleComprobanteCreated para manejar el ID
    const handleComprobanteCreated = (comprobanteId: string, serie: string, monto: number, documentoData: any) => {
        setDocumentoId(comprobanteId);
        setDocumentoInput(serie);
        setDocumentoMonto(monto.toString());
        setDocumentoInfo(documentoData);
      };

    const validateFields = (): boolean => {
        if (ordenCompraEntries.length === 0) {
          showToast('Debe agregar al menos una orden de compra', 'warning');
          return false;
        }
    
        if (!documentoId) {
          showToast('Debe subir un comprobante', 'warning');
          return false;
        }
    
        // Validar que el monto total de constancias no exceda el monto total de órdenes
        // if (totalConstancias > totalOrdenesCompra) {
        //   showToast('El monto total de constancias no puede exceder el monto total de órdenes de compra', 'warning');
        //   return false;
        // }
    
        // Validar que el monto total de ingresos no exceda el monto total de órdenes
        if (totalIngresos > totalOrdenesCompra) {
          showToast('El monto total de ingresos no puede exceder el monto total de órdenes de compra', 'warning');
          return false;
        }
    
        return true;
      };
    
      const [isSaving, setIsSaving] = useState(false);

      const handleSave = async (estado: 'PENDIENTE' | 'REGISTRADO') => {
        if (!validateFields()) return;
    
        // Validar que el usuario exista
        if (!userId) {
          showToast('No se ha encontrado un usuario válido', 'warning');
          return;
        }
    
        setIsSaving(true); // Activar loading

        try {
          // 1. Guardar el registro principal con el userId validado
          const registroData = {
            user_id: userId, // Ahora estamos seguros que no es null
            comprobante_id: documentoId,
            estado: estado,
            monto_total: totalOrdenesCompra,
            fecha_registro: new Date().toISOString()
          };
    
         await dispatch(addRegistro(registroData)).unwrap(); //Guardar el registro principal
          
          // 2. Crear las relaciones con las órdenes de compra
          const ordenCompraPromises = ordenCompraEntries.map(orden => 
            dispatch(addComprobanteOrdenCompra({
              comprobante_id: documentoId,
              orden_compra_id: orden.id
            })).unwrap()
          );
    
          // 3. Guardar las constancias
          const constanciaPromises = constanciaEntries.map(constancia => 
            dispatch(addConstanciaComprobante({
              comprobante_id: documentoId,
              archivos_pago_id: constancia.archivo_pago_id || '',
              pagos_orden_pago_id: constancia.pago_orden_id || ''
    
            })).unwrap()
          );
    
          // 4. Guardar los ingresos de almacén con el detalle_id
          const ingresoPromises = ingresoAlmacenEntries.map(ingreso => 
            dispatch(addIngresoAlmacenComprobante({
              comprobante_id: documentoId,
              transferencia_detalle_id: ingreso.detalle_id, // Usar el detalle_id guardado
            })).unwrap()
          );
    
          // Esperar a que todas las promesas se resuelvan
          await Promise.all([
            ...ordenCompraPromises,
            ...constanciaPromises, 
            ...ingresoPromises
          ]);
    
          showToast(
            estado === 'PENDIENTE' ? 'Registro guardado con éxito' : 'Registro completado con éxito',
            'success'
          );
          
          // Cerrar modal y actualizar datos después de 1 segundo
          setTimeout(() => {
            onSaveSuccess(); // Actualizar datos
            onClose(); // Cerrar modal
          }, 1000);
          
        } catch (error) {
          console.error('Error detallado:', error); // Mejorar el logging de errores
          showToast('Error al guardar el registro: ' + (error as Error).message, 'danger');
        } finally {
          setIsSaving(false); // Desactivar loading
        }
      };

    return (
        <div className="w-full max-w-4xl mx-auto">
            {/* Agregar componente Toast */}
            {toast.show && (
                <Toast
                message={toast.message}
                variant={toast.variant}
                duration={3000}
                index={0}
                onClose={() => setToast(prev => ({ ...prev, show: false }))}
                />
            )}
            <div className="bg-white/80 p-6 rounded-lg">
                <div className="max-w-3xl mx-auto">
                    <div className="mb-6 border-2 border-[#192E58] p-2 rounded-lg">
                        <div className="text-gray-700 mb-5">N° Registro: 0001</div>
                        <div className="flex justify-between text-sm text-gray-500 ">
                            <div>F.Creacion: {new Date().toLocaleDateString()}</div>
                            <div>Usuario: {nombreUsuario}</div>
                        </div>
                    </div>

                    <div className="space-y-4">
                        {/* Orden de Compra */}
                        <div className="flex items-center gap-4 mt-4">
                            <div className="flex-1 w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Orden de Compra
                                </label>           
                            </div>
                            <div className="text-center w-32">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Monto
                                </label>
                            </div>
                            <div className="w-32"></div>
                            <div className="w-10"></div>
                        </div>

                        {ordenCompraEntries.map((entry) => (
                            <div key={entry.id} className="flex items-center gap-4 py-1">
                                <div className="flex-1">
                                    <input 
                                        type="text" 
                                        value={entry.codigo}
                                        readOnly
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <input 
                                        type="text" 
                                        value={entry.monto.toFixed(2)}
                                        readOnly
                                        className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div className="w-32"></div>
                                <div className="flex flex-row space-x-2">
                                    <button 
                                        onClick={() => handleRemoveOrdenCompra(entry.id)}
                                        className="text-red-500 hover:text-red-700"
                                        title="Eliminar"
                                    >
                                        <FiTrash2 size={20} />
                                    </button>
                                    <button className="text-blue-600 hover:text-blue-800">
                                        <FiEye size={20} />
                                    </button>
                                </div>
                            </div>
                        ))}

                        <div className="flex items-center gap-4 mt-1">
                            <div className="flex-1 w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Total Órdenes de Compra
                                </label>           
                            </div>
                            <div className="text-center">
                                <input 
                                    type="text" 
                                    value={totalOrdenesCompra.toFixed(2)}
                                    readOnly
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="w-32"></div>
                            <div className="w-10"></div>
                        </div>

                        {/* Botón para agregar nueva orden de compra */}
                        <div className="flex justify-end">
                            <button 
                                onClick={() => setIsModalOpen(true)}
                                className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                            >
                                <FiPlus size={18} />
                                Agregar Orden de Compra
                            </button>
                        </div>

                        {/* Constancia de Pago */}

                        <div className="flex items-center gap-4 mt-4">
                            <div className="flex-1 w-full">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Constancia de Pago
                                    </label>           
                            </div>
                          
                            <div className="text-center w-32">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Monto
                                    </label>
                            </div>
                          
                            <div className="text-center w-32">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Pendiente
                                    </label>
                            </div>
                          
                            <div className="w-5 w-5 w-3">    
                            </div>
                        </div>
                        
                            {constanciaEntries.map((entry) => (
                                <div key={entry.id} className="flex items-center gap-4 py-1">
                                    <div className="flex-1">
                                        <input 
                                            type="text" 
                                            value={entry.codigo}
                                            readOnly
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        
                                        <input 
                                            type="text" 
                                            value={entry.monto}
                                            readOnly
                                            className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div className="text-center w-32">                                 
                                    </div>
                                    {/* Cambiado de flex-col a flex-row */}
                                    <div className="flex flex-row space-x-2">
                                        <button 
                                            onClick={() => handleRemoveConstancia(entry.id)}
                                            className="text-red-500 hover:text-red-700"
                                            title="Eliminar"
                                        >
                                            <FiTrash2 size={20} />
                                        </button>
                                        <button className="text-blue-600 hover:text-blue-800">
                                            <FiEye size={20} />
                                        </button>
                                    </div>
                                </div>
                            ))}

                         <div className="flex items-center gap-4 mt-1">
                            <div className="flex-1 w-full">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                       Total Constancia de Pago
                                    </label>           
                            </div>
                          
                            <div className="text-center">
                            <input 
                                            type="text" 
                                            value={totalConstancias.toFixed(2)}
                                            readOnly
                                            className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                            </div>
                          
                            <div className="text-center">
                            <input 
                                            type="text" 
                                            value={montoRestanteConstancias.toFixed(2)}
                                            readOnly
                                            className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                            </div>
                          
                            <div className="w-10">    
                            </div>
                        </div>



                            {/* Botón para agregar nueva constancia */}
                            <div className="flex justify-end">
                                <button 
                                    onClick={handleConstanciaClick}
                                    className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                                >
                                    <FiPlus size={18} />
                                    Agregar Constancia
                                </button>
                            </div>
                       

                        {/* Documento */}
                        <div className="flex items-center gap-4">
                            <div className="flex-1">
                                <div className="text-left">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Documento 
                                    </label>
                                </div>
                                <input 
                                    type="text" 
                                    value={documentoInput}
                                    onChange={(e) => setDocumentoInput(e.target.value)}
                                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <div className="text-center">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Monto
                                    </label>
                                </div>
                                <input 
                                    type="text" 
                                    value={documentoMonto}
                                    onChange={(e) => setDocumentoMonto(e.target.value)}
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <div className="text-center">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Pendiente
                                    </label>
                                </div>
                                <input 
                                    type="text" 
                                    value={montoPendienteDocumento.toFixed(2)}
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    readOnly
                                />
                            </div>
                            <div className="flex flex-col space-y-2">
                                <button 
                                    id="documento" 
                                    className="text-green-600 hover:text-green-800 p-2" 
                                    title="Subir Comprobante"
                                    onClick={handleDocumentoClick}
                                >
                                    <div className="relative">
                                        <FiFileText className="w-5 h-5" />
                                        <FiPlus className="w-3 h-3 absolute -top-1 -right-1" />
                                    </div>
                                </button>
                                <button className="text-blue-600 hover:text-blue-800 p-2">
                                    <FiEye className="w-5 h-5" />
                                </button>
                            </div>
                        </div>

                        {/* Ingreso Almacen */}
                        <div className="flex items-center gap-4 mt-4">
                            <div className="flex-1 w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Ingreso Almacén
                                </label>           
                            </div>
                            <div className="text-center w-32">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Monto
                                </label>
                            </div>
                            <div className="text-center w-32">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Pendiente
                                </label>
                            </div>
                            <div className="w-10"></div>
                        </div>

                        {ingresoAlmacenEntries.map((entry) => (
                            <div key={entry.id} className="flex items-center gap-4 py-1">
                                <div className="flex-1">
                                    <input 
                                        type="text" 
                                        value={entry.transferencia_id}
                                        readOnly
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <input 
                                        type="text" 
                                        value={entry.monto.toFixed(2)}
                                        readOnly
                                        className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div className="text-center w-32"></div>
                                <div className="flex flex-row space-x-2">
                                    <button 
                                        onClick={() => handleRemoveIngreso(entry.id)}
                                        className="text-red-500 hover:text-red-700"
                                        title="Eliminar"
                                    >
                                        <FiTrash2 size={20} />
                                    </button>
                                    <button className="text-blue-600 hover:text-blue-800">
                                        <FiEye size={20} />
                                    </button>
                                </div>
                            </div>
                        ))}

                        <div className="flex items-center gap-4 mt-1">
                            <div className="flex-1 w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Total Ingreso Almacén
                                </label>           
                            </div>
                            <div className="text-center">
                                <input 
                                    type="text" 
                                    value={totalIngresos.toFixed(2)}
                                    readOnly
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="text-center">
                                <input 
                                    type="text" 
                                    value={montoRestanteIngresos.toFixed(2)}
                                    readOnly
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="w-10"></div>
                        </div>

                        {/* Botón para agregar nuevo ingreso */}
                        <div className="flex justify-end">
                            <button 
                                onClick={handleIngresoAlmacenClick}
                                className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                            >
                                <FiPlus size={18} />
                                Agregar Ingreso
                            </button>
                        </div>
                    </div>

                    <div className="flex items-center gap-4 mt-8">
                        <button 
                            className="flex-1 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors disabled:opacity-50"
                            disabled={isSaving}
                        >
                            Eliminar
                        </button>
                        <button 
                            className="flex-1 bg-[#0D3588] text-white px-4 py-2 rounded-lg hover:bg-[#0D3588]/90 transition-colors disabled:opacity-50 flex items-center justify-center"
                            onClick={() => handleSave('PENDIENTE')}
                            disabled={isSaving}
                        >
                            {isSaving ? (
                                <FiLoader className="animate-spin mr-2" />
                            ) : null}
                            Guardar
                        </button>
                        <button 
                            className="flex-1 bg-[#192E58] text-white px-4 py-2 rounded-lg hover:bg-[#192E58]/90 transition-colors disabled:opacity-50 flex items-center justify-center"
                            onClick={() => handleSave('REGISTRADO')}
                            disabled={isSaving}
                        >
                            {isSaving ? (
                                <FiLoader className="animate-spin mr-2" />
                            ) : null}
                            Registrar
                        </button>
                    </div>
                </div>
            </div>

            {/* Modal para la tabla de órdenes */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Órdenes de Compra"
            >
                <ListOrdenesCompra
                    onAddDocument={handleAddDocument}
                />
            </Modal>

           

            {/* Modal de confirmación */}
            <Modal
                isOpen={isConfirmModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
                headerBgColor="bg-[#EAF1FF]"
            >
                <div className="p-6">
                    <p className="text-gray-700 text-lg text-center mb-6">
                        ¿Estás seguro de que deseas agregar el Constancia de Pago #{selectedOC?.codigo_orden}?
                    </p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setIsConfirmModalOpen(false)}
                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handleConfirmAdd}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Modal para Constancia de Pago */}
            <Modal
                isOpen={isConstanciaModalOpen}
                onClose={() => setIsConstanciaModalOpen(false)}
                title="Constancias de Pago"
                headerBgColor="bg-blue-900"
            >
                <div className="p-6">
                    <ListConstancias 
                        constancias={constancias.map(c => ({
                            ...c,
                            fecha_archivo: new Date(c.fecha_archivo).toISOString(),
                            estado_verificacion: c.estado_verificacion,
                            fecha_pago: new Date(c.fecha_pago).toISOString(),
                            archivo_pago_id: c.archivo_pago_id || null,  
                            pago_orden_id: c.pago_orden_id || null      
                        }))} 
                        loading={loadingConstancias}
                        onConstanciaSelect={handleConstanciaSelect}
                    />
                </div>
            </Modal>

            {/*  modal para subir comprobante */}
            <Modal
                isOpen={isSubirComprobanteOpen}
                onClose={() => setIsSubirComprobanteOpen(false)}
                title="Subir Archivo"
            >
                <SubirComprobante 
                    ordenCompraId={ordenCompras.find(oc => oc.codigo_orden === ordenCompraInput)?.id}
                    onClose={() => setIsSubirComprobanteOpen(false)}
                    setDocumentoInput={setDocumentoInput}
                    setDocumentoMonto={setDocumentoMonto}
                    ordenCompraEntries={ordenCompraEntries} // Pasar las entradas de órdenes de compra
                    onComprobanteCreated={handleComprobanteCreated} // Pasar la función de creación
                    documentoInfo={documentoInfo}
                />
            </Modal>
      
         
            {/*  modal para subir las ordenes de ingreso  */}

            <Modal
                isOpen={isIngresoAlmacenModalOpen}
                onClose={() => setIsIngresoAlmacenModalOpen(false)}
                title="Órdenes de Ingreso"
                headerBgColor="bg-blue-900"
            >
                <div className="p-6">
                    <ListOrdenIngreso onIngresoSelect={handleIngresoSelect} />
                </div>
            </Modal>
        </div>
    );
};

export default NuevoRegistro;
