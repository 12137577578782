import { gql } from '@apollo/client';
import client from '../apolloClient';

export interface Registro {
  id: string;
  codigo: string;
  fecha_creacion: string;
  fecha_cambio: string;
  comprobante_id: {
    id: string;
  };
  estado: string;
  user_id: {
    id: string;
  };
}

export interface RegistroInput {
  user_id: string; // No puede ser null
  comprobante_id: string;
  estado: 'PENDIENTE' | 'REGISTRADO';
  monto_total: number;
  fecha_registro: string;
}

export interface DocumentoContable {
  id: string; // Añadir campo id
  comprobante_id: string; // Añadir este campo
  registro_codigo: string;
  orden_compra_codigo: string;
  tipo_recurso_nombre: string;
  comprobante_monto: number;
  tipo_comprobante_nombre: string;
  registro_fecha_creacion: string;
  recurso_nombre: string;
  registro_estado: string;
}

// Añadir nueva interfaz para DocumentoContableRegistrado
export interface DocumentoContableRegistrado {
  id: string;
  registro_codigo: string;
  orden_compra_codigo: string;
  comprobante_monto: number;
  comprobante_id: string;
  comprobante_ruc: number;
  comprobante_serie: string;
  tipo_comprobante_nombre: string;
  registro_fecha_creacion: string;
  registro_estado: string;
  estado_consolidado: string;
}

// Añadir la nueva interfaz TotalContable
export interface TotalContable {
  registro_codigo: string;
  orden_compra_codigo: string;
  orden_compra_total: number;
  tipo_comprobante_nombre: string;
  comprobante_monto: number;
  pagos_monto: number;
  transferencia_recurso_total_cantidad: number;
  transferencia_recurso_monto_total: number;
  pendiente_a: number;
  pendiente_b: number;
  pendiente_c: number;
}

const LIST_REGISTROS = gql`
  query ListRegistros {
    listRegistros {
      id
      fecha_creacion
      fecha_cambio
      comprobante_id {
        id
      }
      estado
      user_id {
        id
      }
    }
  }
`;

const GET_REGISTROS_BY_PAGO = gql`
  query GetRegistrosByPago($pagoId: ID!) {
    getRegistrosByPago(pagoId: $pagoId) {
      id
      fecha_creacion
      fecha_cambio
      comprobante_id {
        id
      }
      estado
      user_id {
        id
      }
    }
  }
`;

const ADD_REGISTRO = gql`
  mutation AddRegistro(
    $fecha_creacion: DateTime
    $fecha_cambio: DateTime
    $comprobante_id: ID!
    $estado: String!
    $user_id: ID!
  ) {
    addRegistro(
      fecha_creacion: $fecha_creacion
      fecha_cambio: $fecha_cambio
      comprobante_id: $comprobante_id
      estado: $estado
      user_id: $user_id
    ) {
      id
      fecha_creacion
      fecha_cambio
      comprobante_id {
        id
      }
      estado
      user_id {
        id
      }
    }
  }
`;

const UPDATE_REGISTRO = gql`
  mutation UpdateRegistro(
    $id: ID!
    $fecha_creacion: DateTime
    $fecha_cambio: DateTime
    $comprobante_id: ID
    $estado: String
    $user_id: ID
  ) {
    updateRegistro(
      id: $id
      fecha_creacion: $fecha_creacion
      fecha_cambio: $fecha_cambio
      comprobante_id: $comprobante_id
      estado: $estado
      user_id: $user_id
    ) {
      id
      fecha_creacion
      fecha_cambio
      comprobante_id {
        id
      }
      estado
      user_id {
        id
      }
    }
  }
`;

const DELETE_REGISTRO = gql`
  mutation DeleteRegistro($id: ID!) {
    deleteRegistro(id: $id) {
      id
    }
  }
`;

const LIST_DOCUMENTOS_CONTABLES = gql`
  query ListDocumentosContables {
    list_documentos_contables {
      id
      comprobante_id
      registro_codigo
      orden_compra_codigo
      tipo_recurso_nombre
      comprobante_monto
      tipo_comprobante_nombre
      registro_fecha_creacion
      recurso_nombre
      registro_estado
    }
  }
`;

// Agregar la nueva query
const LIST_DOCUMENTOS_CONTABLES_REGISTRADOS = gql`
  query ListDocumentosContablesRegistrados {
    list_documentos_contables_registrados {
      id
      registro_codigo
      orden_compra_codigo
      comprobante_monto
      comprobante_id
      comprobante_ruc
      comprobante_serie
      tipo_comprobante_nombre
      registro_fecha_creacion
      registro_estado
      estado_consolidado
    }
  }
`;

// Añadir la nueva query
const TOTAL_CONTABLE = gql`
  query TotalContable {
    total_contable {
      registro_codigo
      orden_compra_codigo
      orden_compra_total
      tipo_comprobante_nombre
      comprobante_monto
      pagos_monto
      transferencia_recurso_total_cantidad
      transferencia_recurso_monto_total
      pendiente_a
      pendiente_b
      pendiente_c
    }
  }
`;

// Funciones de servicio
export const listRegistrosService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_REGISTROS,
      fetchPolicy: 'network-only',
    });
    return data.listRegistros;
  } catch (error) {
    throw new Error(`Error al obtener registros: ${error}`);
  }
};

export const getRegistrosByPagoService = async (pagoId: string) => {
  try {
    const { data } = await client.query({
      query: GET_REGISTROS_BY_PAGO,
      variables: { pagoId },
    });
    return data.getRegistrosByPago;
  } catch (error) {
    throw new Error(`Error al obtener registros por pago: ${error}`);
  }
};

export const addRegistroService = async (input: RegistroInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_REGISTRO,
      variables: input,
    });
    return data.addRegistro;
  } catch (error) {
    throw new Error(`Error al crear registro: ${error}`);
  }
};

export const updateRegistroService = async (id: string, input: Partial<RegistroInput>) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_REGISTRO,
      variables: { id, ...input },
    });
    return data.updateRegistro;
  } catch (error) {
    throw new Error(`Error al actualizar registro: ${error}`);
  }
};

export const deleteRegistroService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_REGISTRO,
      variables: { id },
    });
    return data.deleteRegistro;
  } catch (error) {
    throw new Error(`Error al eliminar registro: ${error}`);
  }
};

export const listDocumentosContablesService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_DOCUMENTOS_CONTABLES,
      fetchPolicy: 'network-only',
    });
    return data.list_documentos_contables;
  } catch (error) {
    throw new Error(`Error al obtener documentos contables: ${error}`);
  }
};

// Agregar el nuevo servicio
export const listDocumentosContablesRegistradosService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_DOCUMENTOS_CONTABLES_REGISTRADOS,
      fetchPolicy: 'network-only',
    });
    return data.list_documentos_contables_registrados;
  } catch (error) {
    throw new Error(`Error al obtener documentos contables registrados: ${error}`);
  }
};

// Añadir el nuevo servicio
export const totalContableService = async () => {
  try {
    const { data } = await client.query({
      query: TOTAL_CONTABLE,
      fetchPolicy: 'network-only',
    });
    return data.total_contable;
  } catch (error) {
    throw new Error(`Error al obtener total contable: ${error}`);
  }
};
