import React from 'react';
import { motion } from 'framer-motion';
import { FaTrash, FaToolbox, FaFileContract } from 'react-icons/fa';
import { BsPersonStanding, BsTools } from 'react-icons/bs';
import { MdSmartButton } from 'react-icons/md';
import { IComposicionApu } from '../types/apu.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { formatCurrency } from '../../../../components/Utils/priceFormatUtils';
import { PendingChange, PendingChangesMap } from '../types/apu.actions';

interface APUTableProps {
  composiciones: IComposicionApu[];
  onDelete: (composicion: IComposicionApu) => void;
  onEdit: (composicion: IComposicionApu, field: string, value: number) => void;
  isEditMode: boolean;
  pendingChanges: PendingChangesMap;
}

const getPseudoParcial = (
  comp: IComposicionApu,
  pendingChanges: PendingChangesMap
): number => {
  const changes = pendingChanges.get(comp.id_composicion_apu) || {} as PendingChange;
  const cantidad = changes.cantidad !== undefined ? changes.cantidad : comp.cantidad || 0;
  const precio = changes.precio !== undefined
    ? changes.precio
    : comp.precio?.precio || 0;
  return cantidad * precio;
};

const APUTable: React.FC<APUTableProps> = ({
  composiciones,
  onDelete,
  onEdit,
  isEditMode,
  pendingChanges = new Map()
}) => {
  const tipos = useSelector((state: RootState) => state.tipoCostoRecurso.tipoCostoRecursos);

  // Calcular subtotal de mano de obra
  const subtotalMO = React.useMemo(() => {
    return composiciones.reduce((total, comp) => {
      const tipoRecurso = tipos.find(t => t.id === comp.rec_comp_apu?.recurso?.tipo_costo_recurso_id)?.nombre;
      if (tipoRecurso === 'MANO DE OBRA') {
        const pendingChange = pendingChanges.get(comp.id_composicion_apu);
        const cantidad = pendingChange?.cantidad ?? comp.cantidad ?? 0;
        const precio = pendingChange?.precio ?? comp.precio?.precio ?? 0;
        return total + (cantidad * precio);
      }
      return total;
    }, 0);
  }, [composiciones, pendingChanges, tipos]);

  const getIconByTipo = (tipoId: string) => {
    const tipo = tipos.find(t => t.id === tipoId)?.nombre || '';
    switch (tipo) {
      case 'MANO DE OBRA': return <BsPersonStanding className="text-green-600" />;
      case 'MATERIALES': return <FaToolbox className="text-red-600" />;
      case 'EQUIPO': return <BsTools className="text-blue-600" />;
      case 'SUB-CONTRATOS': return <FaFileContract className="text-lime-500" />;
      default: return <MdSmartButton className="text-pink-500" />;
    }
  };

  const rowVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  // Función auxiliar para obtener el valor actual (pendiente o original)
  const getCurrentValue = (composicion: IComposicionApu, field: string) => {
    const pendingValue = pendingChanges.get(composicion.id_composicion_apu)?.[field];
    if (pendingValue !== undefined) return pendingValue;

    switch (field) {
      case 'precio':
        return composicion.precio?.precio || '';
      case 'cantidad':
        return composicion.cantidad || '';
      case 'cuadrilla':
        return composicion.cuadrilla || '';
      default:
        return '';
    }
  };

  // Función para verificar si el recurso puede tener cuadrilla
  const canShowCuadrilla = (unidad?: string): boolean => {
    return unidad?.toLowerCase() === 'hh' || unidad?.toLowerCase() === 'hm';
  };

  return (
    <div className="overflow-y-auto h-full relative">
      <table className="w-full">
        <thead className="sticky top-0 bg-gray-50 z-10 shadow-sm">
          <tr>
            <th className="px-2 py-1 text-xs font-medium text-gray-600 text-center">Tipos</th>
            <th className="px-2 py-1 text-xs font-medium text-gray-600 text-left">Descripción</th>
            <th className="px-2 py-1 text-xs font-medium text-gray-600 text-center">Und.</th>
            <th className="px-2 py-1 text-xs font-medium text-gray-600 text-center">Cuadrilla</th>
            <th className="px-2 py-1 text-xs font-medium text-gray-600 text-center">Cantidad</th>
            <th className="px-2 py-1 text-xs font-medium text-gray-600 text-right">Precio S/.</th>
            <th className="px-2 py-1 text-xs font-medium text-gray-400 text-right">Precio Ref.</th>
            <th className="px-2 py-1 text-xs font-medium text-gray-600 text-right">Parcial</th>
            <th className="px-2 py-1 text-xs font-medium text-gray-600 text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {composiciones.map((comp) => (
            <motion.tr
              key={comp.id_composicion_apu}
              variants={rowVariants}
              className="border-t border-gray-100 hover:bg-gray-50 transition-colors"
            >
              <td className="px-2 py-1 text-center">
                <div className="flex justify-center">
                  {getIconByTipo(comp.rec_comp_apu?.recurso?.tipo_costo_recurso_id)}
                </div>
              </td>
              <td className="px-2 py-2 text-xs text-gray-700">
                {comp.rec_comp_apu?.nombre}
              </td>
              <td className="px-2 py-1 text-xs text-gray-600 text-center">
                {comp.rec_comp_apu?.unidad?.nombre || '-'}
              </td>
              <td className="px-2 py-1 text-xs text-gray-600 text-center">
                {canShowCuadrilla(comp.rec_comp_apu?.unidad?.nombre) ? (
                  isEditMode ? (
                    <input
                      type="number"
                      className="w-20 text-xs border rounded text-center"
                      value={getCurrentValue(comp, 'cuadrilla')}
                      onChange={(e) => onEdit(comp, 'cuadrilla', Number(e.target.value))}
                    />
                  ) : (
                    formatCurrency(getCurrentValue(comp, 'cuadrilla')) || '-'
                  )
                ) : (
                  ''
                )}
              </td>
              {/* Cantidad */}
              <td className="px-2 py-1 text-xs text-gray-600 text-center">
                {isEditMode ? (
                  <input
                    type="number"
                    step="0.01"
                    className="w-20  text-xs border rounded text-center"
                    value={getCurrentValue(comp, 'cantidad')}
                    onChange={(e) => onEdit(comp, 'cantidad', Number(e.target.value))}
                  />
                ) : (
                  formatCurrency(getCurrentValue(comp, 'cantidad')) || '-'
                )}
                {comp.rec_comp_apu?.unidad?.nombre === "%mo" && '%'}
              </td>
              <td className="px-2 py-1 text-xs text-gray-600 text-right">
                {isEditMode ? (
                  comp.rec_comp_apu?.unidad?.nombre === "%mo" ? (
                    <span className="w-24 text-white text-xs text-right block px-2">
                      {formatCurrency(getCurrentValue(comp, 'precio')) || '-'}
                    </span>
                  ) : (
                    <input
                      type="number"
                      step="0.01"
                      className="w-24 text-xs border rounded text-right"
                      value={getCurrentValue(comp, 'precio')}
                      onChange={(e) => onEdit(comp, 'precio', Number(e.target.value))}
                    />
                  )
                ) : (
                  formatCurrency(getCurrentValue(comp, 'precio')) || '-'
                )}
              </td>
                <td className="px-2 py-1 text-xs text-gray-400 font-medium text-right">
                {formatCurrency(comp.rec_comp_apu?.recurso?.precio_actual || 0)}
                </td>
                <td className="px-2 py-1 text-xs text-gray-700 font-medium text-right">
                {isEditMode
                  ? formatCurrency(getPseudoParcial(comp, pendingChanges))
                  : formatCurrency(
                    comp.rec_comp_apu?.unidad?.nombre === '%mo'
                    ? (comp.cantidad || 0) * (subtotalMO / 100)
                    : (comp.cantidad || 0) * (comp.precio?.precio || 0)
                  )
                }
                </td>
                {isEditMode && <td className=" px-2  w-16 py-1 text-center">
                <button
                  onClick={() => onDelete(comp)}
                  className="px-2 p-1 text-red-600 hover:text-red-800 w-5"
                >
                  <FaTrash size={14} />
                </button>
              </td>}
            </motion.tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default APUTable;
