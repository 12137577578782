import { gql } from '@apollo/client';
import client from '../apolloClient';
import { Empresa } from '../slices/empresaSlice';
import { MediosPagoProveedor as MediosPago } from '../slices/mediosPagoProveedorSlice';

// Agregar la nueva interfaz para el proveedor
export interface Proveedor {
  id: string;
  razon_social: string;
  ruc: string;
  direccion: string;
  nombre_comercial: string;
  correo: string;
}

// Agregar la interfaz para la respuesta
export interface ProveedorOrdenCompraResponse {
  orden_compra: {
    id: string;
  };
  proveedor: Proveedor;
}

export interface Obra {
  id: string;
  titulo: string;
  nombre: string;
  descripcion: string;
  ubicacion: string;
  direccion: string;
  estado: boolean;
  empresa_id: Empresa;
}

export interface Requerimiento {
  id: string;
  usuario: string;
  fecha_solicitud: string;
  fecha_final: string;
  estado_atencion: string;
  sustento: string;
  codigo: string;
  obra_id: string;
  obra: Obra;
}

export interface SolicitudCompra {
  id: string;
  fecha: string;
  requerimiento: Requerimiento;
}

export interface Contacto {
  id: string;
  nombres: string;
  apellidos: string;
  cargo: string;
  telefono: string;
  correo: string;
}

export interface Divisa {
  id: string;
  nombre: string;
  abreviatura: string;
  simbolo: string;
  region: string;
}

export interface CotizacionProveedor {
  id: string;
  proveedor: Proveedor & {
    estado: boolean;
    contactos: Contacto[];
    medios_pago: MediosPago[];
  };
  divisa: Divisa;
  estado: boolean;
  fecha_inicio: string;
  fecha_fin: string;
  entrega: string;
  c_pago: string;
  observaciones: string;
}

export interface DataPDFOrdenCompra {
  id: string;
  codigo_orden: string;
  estado: boolean;
  descripcion: string;
  fecha_ini: string;
  fecha_fin: string;
  cotizacion_id: {
    id: string;
    codigo_cotizacion: string;
    solicitud_compra_id: SolicitudCompra;
    aprobacion: boolean;
    estado: boolean;
    fecha: string;
    usuario_id: string
  };
  cotizacion_proveedor: CotizacionProveedor;
}

const LIST_ORDEN_COMPRAS_QUERY = gql`
  query ListOrdenCompras {
    listOrdenCompras {
      id
      codigo_orden
      cotizacion_id {
        id
        usuario_id {
          nombres
          apellidos
          id
          dni
        }
        fecha
        estado
      }
      estado
      descripcion
      fecha_ini
      fecha_fin
      tipo
    }
  }
`;

const LIST_ORDEN_COMPRAS_WITH_OBRA_QUERY = gql`
query ListOrdenComprasWithObra {
  listOrdenComprasWithObra {
    id
    codigo_orden
    estado
    descripcion
    fecha_ini
    fecha_fin
    tipo
    cotizacion_id {
      id
      codigo_cotizacion
      usuario_id {
        id
        nombres
        apellidos
        dni
      }
      solicitud_compra_id {
        requerimiento_id {
          obra_id
          obra {
            titulo
            nombre
            descripcion
            direccion
            estado
          }
        }
      }
      aprobacion
      estado
      fecha
    }
  }
}
`;

const ADD_ORDEN_COMPRA_MUTATION = gql`
  mutation AddOrdenCompra($codigoOrden: String!, $cotizacionId: ID!, $descripcion: String!, $fechaIni: DateTime!, $fechaFin: DateTime!, $estado: String, $tipo: String) {
    addOrdenCompra(codigo_orden: $codigoOrden, cotizacion_id: $cotizacionId, descripcion: $descripcion, fecha_ini: $fechaIni, fecha_fin: $fechaFin, estado: $estado, tipo: $tipo) {
      id
      codigo_orden
      cotizacion_id {
        id
      }
      estado
      descripcion
      fecha_ini
      fecha_fin
      tipo
    }
  }
`;

const UPDATE_ORDEN_COMPRA_MUTATION = gql`
    mutation UpdateOrdenCompra($updateOrdenCompraId: ID!, $fechaFin: DateTime, $fechaIni: DateTime, $descripcion: String, $estado: String, $cotizacionId: ID, $codigoOrden: String) {
      updateOrdenCompra(id: $updateOrdenCompraId, fecha_fin: $fechaFin, fecha_ini: $fechaIni, descripcion: $descripcion, estado: $estado, cotizacion_id: $cotizacionId, codigo_orden: $codigoOrden) {
      id
      codigo_orden
      cotizacion_id {
        id
      }
      estado
      descripcion
      fecha_ini
      fecha_fin
    }
  }
`;

const DELETE_ORDEN_COMPRA_MUTATION = gql`
  mutation DeleteOrdenCompra($deleteOrdenCompraId: ID!) {
    deleteOrdenCompra(id: $deleteOrdenCompraId) {
      id
    }
  }
`;

// Agregar la nueva consulta GraphQL
const GET_PROVEEDOR_BY_ORDEN_COMPRA_ID = gql`
  query GetProveedorByOrdenCompraId($ordenCompraId: ID!) {
    getProveedorByOrdenCompraId(ordenCompraId: $ordenCompraId) {
      orden_compra {
        id
      }
      proveedor {
        id
        razon_social
        ruc
        direccion
        nombre_comercial
        correo
      } 
    }
  }
`;

// Nueva query
const GET_DATA_PDF_BY_ORDEN_COMPRA_ID = gql`
  query ListDataPDFByOrdenCompraId($ordenCompraId: ID!) {
    listDataPDFByOrdenCompraId(ordenCompraId: $ordenCompraId) {
      id
      codigo_orden
      estado
      descripcion
      fecha_ini
      fecha_fin
      cotizacion_id {
        id
        codigo_cotizacion
        usuario_id
        solicitud_compra_id {
          id
          fecha
          requerimiento {
            id
            usuario
            fecha_solicitud
            fecha_final
            estado_atencion
            sustento
            codigo
            obra_id
            obra {
              id
              titulo
              nombre
              descripcion
              ubicacion
              direccion
              estado
              empresa_id {
                id
                nombre_comercial
                razon_social
                descripcion
                estado
                regimen_fiscal
                ruc
                imagenes
                color
              }
            }
          }
        }
        aprobacion
        estado
        fecha
      }
      cotizacion_proveedor {
        id
        proveedor {
          id
          razon_social
          ruc
          direccion
          nombre_comercial
          correo
          estado
          contactos {
            id
            nombres
            apellidos
            cargo
            telefono
            correo
          }
          medios_pago {
            id
            entidad
            nro_cuenta
            detalles
            titular
            validado
          }
        }
        divisa {
          id
          nombre
          abreviatura
          simbolo
          region
        }
        estado
        fecha_inicio
        fecha_fin
        entrega
        c_pago
        observaciones
      }
    }
  }
`;

export interface OrdenCompra {
  id: string;
  codigo_orden: string;
  cotizacion_id: { id: string };
  estado: string;
  descripcion: string;
  fecha_ini: string;
  fecha_fin: string;
  tipo: string;
}
export interface OrdenCompraUpdate {
  id: string;
  codigo_orden?: string;
  cotizacion_id?: string;
  estado?: string;
  descripcion?: string;
  fecha_ini?: string;
  fecha_fin?: string;
  usuario?: string;
  tipo?: string;
}

export const listOrdenComprasService = async () => {
  try {
    const response = await client.query({
      query: LIST_ORDEN_COMPRAS_QUERY,
    });
    return response.data.listOrdenCompras;
  } catch (error) {
    console.error('Error al listar órdenes de compra:', error);
    throw error;
  }
};

export const listOrdenComprasWithObraService = async () => {
  try {
    const response = await client.query({
      query: LIST_ORDEN_COMPRAS_WITH_OBRA_QUERY,
    });
    // Cambiar esta línea para retornar el resultado correcto
    return response.data.listOrdenComprasWithObra;
  } catch (error) {
    console.error('Error al listar órdenes de compra:', error);
    throw error;
  }
};

export const addOrdenCompraService = async (ordenCompra: Omit<OrdenCompraUpdate, 'id'>) => {
  try {
    const response = await client.mutate({
      mutation: ADD_ORDEN_COMPRA_MUTATION,
      variables: {
        codigoOrden: ordenCompra.codigo_orden,
        cotizacionId: ordenCompra.cotizacion_id,
        descripcion: ordenCompra.descripcion,
        fechaIni: ordenCompra.fecha_ini,
        fechaFin: ordenCompra.fecha_fin,
        estado: ordenCompra.estado,
        usuario: ordenCompra.usuario,
        tipo: ordenCompra.tipo
      },
    });
    return response.data.addOrdenCompra;
  } catch (error) {
    console.error('Error al agregar orden de compra:', error);
    throw error;
  }
};

export const updateOrdenCompraService = async (ordenCompra: Omit<OrdenCompraUpdate, "usuario">) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_ORDEN_COMPRA_MUTATION,
      variables: {
        updateOrdenCompraId: ordenCompra.id,
        codigoOrden: ordenCompra.codigo_orden,
        cotizacionId: ordenCompra.cotizacion_id,
        descripcion: ordenCompra.descripcion,
        fechaIni: ordenCompra.fecha_ini,
        fechaFin: ordenCompra.fecha_fin,
        estado: ordenCompra.estado
      },
    });
    return response.data.updateOrdenCompra;
  } catch (error) {
    console.error('Error al actualizar orden de compra:', error);
    throw error;
  }
};

export const deleteOrdenCompraService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_ORDEN_COMPRA_MUTATION,
      variables: { deleteOrdenCompraId: id },
    });
    return response.data.deleteOrdenCompra;
  } catch (error) {
    console.error('Error al eliminar orden de compra:', error);
    throw error;
  }
};

// Agregar el nuevo servicio
export const getProveedorByOrdenCompraIdService = async (ordenCompraId: string): Promise<ProveedorOrdenCompraResponse> => {
  try {
    const response = await client.query({
      query: GET_PROVEEDOR_BY_ORDEN_COMPRA_ID,
      variables: { ordenCompraId }
    });
    return response.data.getProveedorByOrdenCompraId;
  } catch (error) {
    console.error('Error al obtener proveedor de la orden de compra:', error);
    throw error;
  }
};

// Nuevo servicio
export const getDataPDFByOrdenCompraIdService = async (ordenCompraId: string): Promise<DataPDFOrdenCompra> => {
  try {
    const response = await client.query({
      query: GET_DATA_PDF_BY_ORDEN_COMPRA_ID,
      variables: { ordenCompraId }
    });
    return response.data.listDataPDFByOrdenCompraId;
  } catch (error) {
    console.error('Error al obtener datos para PDF de la orden de compra:', error);
    throw error;
  }
};
