import { gql } from '@apollo/client';
import client from '../apolloClient';
import { IAprobacion } from '../types/aprobacionGeneral';

const LIST_APROBACIONES_GENERALES_QUERY = gql`
  query ListAprobacionGeneral {
    listAprobacionGeneral {
      id
      referencia_id
      referencia
      usuario_id
      fecha
    }
  }
`;

const GET_APROBACION_GENERAL_QUERY = gql`
  query GetAprobacionGeneralByReferenciaId($id: ID!) {
    getAprobacionGeneralByReferenciaId(id: $id) {
      id
      referencia_id
      referencia
      usuario_id
      fecha
    }
  }
`;

const ADD_APROBACION_GENERAL_MUTATION = gql`
  mutation AddAprobacionGeneral($referencia_id: String!, $referencia: String!, $usuario_id: String!, $fecha: Date!) {
    addAprobacionGeneral(referencia_id: $referencia_id, referencia: $referencia, usuario_id: $usuario_id, fecha: $fecha) {
      id
      referencia_id
      referencia
      usuario_id
      fecha
    }
  }
`;

const UPDATE_APROBACION_GENERAL_MUTATION = gql`
  mutation UpdateAprobacionGeneral($id: String!, $fecha: Date) {
    updateAprobacionGeneral(id: $id, fecha: $fecha) {
      id
      referencia_id
      referencia
      usuario_id
      fecha
    }
  }
`;

const DELETE_APROBACION_GENERAL_MUTATION = gql`
  mutation DeleteAprobacionGeneral($id: String!) {
    deleteAprobacionGeneral(id: $id) {
      id
      referencia_id
      referencia
      usuario_id
      fecha
    }
  }
`;

export const listAprobacionesGeneralesService = async (): Promise<IAprobacion[]> => {
  try {
    const response = await client.query({
      query: LIST_APROBACIONES_GENERALES_QUERY,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.listAprobacionGeneral;
  } catch (error) {
    console.error('Error al obtener la lista de aprobaciones generales:', error);
    throw error;
  }
};

export const getAprobacionGeneralService = async (id: string): Promise<IAprobacion> => {
  try {
    const response = await client.query({
      query: GET_APROBACION_GENERAL_QUERY,
      variables: { id },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.getAprobacionGeneralByReferenciaId;
  } catch (error) {
    console.error('Error al obtener la aprobación general:', error);
    throw error;
  }
};

export const addAprobacionGeneralService = async (aprobacionData: {
  referencia_id: string;
  referencia: string;
  usuario_id: string;
  fecha: Date;
}): Promise<IAprobacion> => {
  try {
    // Validar que la fecha sea válida
    if (!(aprobacionData.fecha instanceof Date) || isNaN(aprobacionData.fecha.getTime())) {
      throw new Error('La fecha proporcionada no es válida');
    }

    const response = await client.mutate({
      mutation: ADD_APROBACION_GENERAL_MUTATION,
      variables: aprobacionData,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.addAprobacionGeneral;
  } catch (error) {
    console.error('Error al crear la aprobación general:', error);
    throw error;
  }
};

export const updateAprobacionGeneralService = async (aprobacion: {
  id: string;
  fecha?: Date;
}): Promise<IAprobacion> => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_APROBACION_GENERAL_MUTATION,
      variables: aprobacion,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.updateAprobacionGeneral;
  } catch (error) {
    console.error('Error al actualizar la aprobación general:', error);
    throw error;
  }
};

export const deleteAprobacionGeneralService = async (id: string): Promise<IAprobacion> => {
  try {
    const response = await client.mutate({
      mutation: DELETE_APROBACION_GENERAL_MUTATION,
      variables: { id },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.deleteAprobacionGeneral;
  } catch (error) {
    console.error('Error al eliminar la aprobación general:', error);
    throw error;
  }
}; 