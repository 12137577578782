import { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
// import { TransferenciaDetalle, TransferenciaRecurso } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchTransferenciaRecursosById } from '../../slices/transferenciaRecursoSlice';
import { TransferenciaDetalle } from './types';

interface DetalleTransferenciaProps {
  transferencia_detalle: TransferenciaDetalle;
  onClose: () => void;
}

export function DetalleTransferencia({ transferencia_detalle, onClose }: DetalleTransferenciaProps): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState('info');
  const [, setIsLoading] = useState(true);
  // console.log(isLoading)
  const recursos = useSelector((state: RootState) => state.transferenciaRecurso.transferenciaRecursos)
  // console.log(recursos)

  useEffect(() => {
          const loadData = async () => {
              setIsLoading(true);
              try {
                  await dispatch(fetchTransferenciaRecursosById(transferencia_detalle.id)).unwrap();
              } catch (err) {
                  console.error('Error al cargar los datos:', err);
              } finally {
                  setIsLoading(false);
              }
          };
          loadData();
      }, [dispatch, transferencia_detalle.id]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl p-6 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Detalle de Transferencia</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FiX className="w-6 h-6" />
          </button>
        </div>

        <div className="mb-6">
          <div className="flex space-x-4 border-b">
            <button
              className={`py-2 px-4 ${
                activeTab === 'info'
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500'
              }`}
              onClick={() => setActiveTab('info')}
            >
              Información General
            </button>
            <button
              className={`py-2 px-4 ${
                activeTab === 'recursos'
                  ? 'border-b-2 border-blue-500 text-blue-600'
                  : 'text-gray-500'
              }`}
              onClick={() => setActiveTab('recursos')}
            >
              Recursos
            </button>
          </div>
        </div>

        {activeTab === 'info' && (
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-gray-600">ID Transferencia</h3>
                <p>{transferencia_detalle.id}</p>
              </div>
              <div>
                <h3 className="font-semibold text-gray-600">Fecha</h3>
                <p>{new Date(transferencia_detalle.fecha).toLocaleDateString()}</p>
              </div>
              <div>
                <h3 className="font-semibold text-gray-600">Tipo</h3>
                <p>{transferencia_detalle.tipo}</p>
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-gray-600">Referencia</h3>
                <p>{transferencia_detalle.referencia}</p>
              </div>
              <div>
                <h3 className="font-semibold text-gray-600">ID Referencia</h3>
                <p>{transferencia_detalle.referencia_id}</p>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'recursos' && (
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-2 text-left">Código</th>
                  <th className="px-4 py-2 text-left">Nombre</th>
                  <th className="px-4 py-2 text-left">Cantidad</th>
                  <th className="px-4 py-2 text-left">Costo</th>
                  <th className="px-4 py-2 text-left">Total</th>
                </tr>
              </thead>
              <tbody>
                {recursos.map((recurso) => (
                  <tr key={recurso._id} className="border-b">
                    <td className="px-4 py-2">{recurso.recurso_id.codigo}</td>
                    <td className="px-4 py-2">{recurso.recurso_id.nombre}</td>
                    <td className="px-4 py-2">{recurso.cantidad}</td>
                    <td className="px-4 py-2">S/. {recurso.costo.toFixed(2)}</td>
                    <td className="px-4 py-2">
                      S/. {(recurso.cantidad * recurso.costo).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr className="font-bold">
                  <td colSpan={4} className="px-4 py-2 text-right">Total:</td>
                  <td className="px-4 py-2">
                    S/. {recursos.reduce(
                      (total, recurso) => total + (recurso.cantidad * recurso.costo),
                      0
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
