import React, { useState } from 'react';
import { KanbanCardServicioProps } from './KanbanColumnOrdenServicio';
import { OrdenCompra } from '../../types/ordenCompra';
import Modal from '../../components/Modal/Modal';
import ValidarServicio from '../ComprasPage/ValidarServicio';

const KanbanCardOrdenServicio: React.FC<KanbanCardServicioProps> = ({ column }) => {
  const [modalAprobacionReqSup, setModalAprobacionReqSup] = useState(false);

  const handleModalOpen = () => {
    setModalAprobacionReqSup(true);
  };

  const ordenServicio: OrdenCompra = column.ordenservicio as OrdenCompra;

  return (
    <div className={`bg-cyan-300/90 rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow`}>
      <div className='grid grid-cols-3'>
        <div className='col-span-2'>
          <div className="flex flex-col text-left text-[8px] text-gray-900">
            <p className='text-xs'><span className="font-semibold">Código:</span> {ordenServicio.codigo_orden}</p>
            <p className='text-xs'><span className="font-semibold">Tipo:</span> {ordenServicio.tipo}</p>
            <p className='text-xs'><span className="font-semibold">Estado:</span> {ordenServicio.estado}</p>
          </div>
        </div>
        <div className='col-span-1 flex flex-col justify-around items-center'>
          <button
            className="bg-white p-1 rounded-lg text-blue-600 hover:text-gray-500 font-semibold text-xs"
            onClick={handleModalOpen}
          >
            Validar
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalAprobacionReqSup}
        onClose={() => setModalAprobacionReqSup(false)}
        title="Validación de Orden de Servicio"
      >
        {ordenServicio && (
          <ValidarServicio
            onClose={() => setModalAprobacionReqSup(false)}
            ordenServicio={ordenServicio}
          />
        )}
      </Modal>
    </div>
  );
};

export default KanbanCardOrdenServicio; 