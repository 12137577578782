import { gql } from '@apollo/client';
import client from '../apolloClient';

export interface TipoComprobante {
  id: string;
  nombre: string;
  descripcion?: string;
}

export interface TipoComprobanteInput {
  nombre: string;
  descripcion?: string;
}

const LIST_TIPO_COMPROBANTES = gql`
  query ListTipoComprobantes {
    listTipoComprobantes {
      id
      nombre
      descripcion
    }
  }
`;

const GET_TIPO_COMPROBANTE = gql`
  query GetTipoComprobanteById($id: ID!) {
    getTipoComprobanteById(id: $id) {
      id
      nombre
      descripcion
    }
  }
`;

const ADD_TIPO_COMPROBANTE = gql`
  mutation AddTipoComprobante($nombre: String!, $descripcion: String) {
    addTipoComprobante(nombre: $nombre, descripcion: $descripcion) {
      id
      nombre
      descripcion
    }
  }
`;

const UPDATE_TIPO_COMPROBANTE = gql`
  mutation UpdateTipoComprobante($id: ID!, $nombre: String, $descripcion: String) {
    updateTipoComprobante(id: $id, nombre: $nombre, descripcion: $descripcion) {
      id
      nombre
      descripcion
    }
  }
`;

const DELETE_TIPO_COMPROBANTE = gql`
  mutation DeleteTipoComprobante($id: ID!) {
    deleteTipoComprobante(id: $id) {
      id
      nombre
      descripcion
    }
  }
`;

export const listTipoComprobantesService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_TIPO_COMPROBANTES,
      fetchPolicy: 'network-only',
    });
    return data.listTipoComprobantes;
  } catch (error) {
    throw new Error(`Error fetching tipo comprobantes: ${error}`);
  }
};

export const getTipoComprobanteByIdService = async (id: string) => {
  try {
    const { data } = await client.query({
      query: GET_TIPO_COMPROBANTE,
      variables: { id },
    });
    return data.getTipoComprobanteById;
  } catch (error) {
    throw new Error(`Error fetching tipo comprobante: ${error}`);
  }
};

export const addTipoComprobanteService = async (input: TipoComprobanteInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_TIPO_COMPROBANTE,
      variables: input,
    });
    return data.addTipoComprobante;
  } catch (error) {
    throw new Error(`Error creating tipo comprobante: ${error}`);
  }
};

export const updateTipoComprobanteService = async (id: string, input: Partial<TipoComprobanteInput>) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_TIPO_COMPROBANTE,
      variables: { id, ...input },
    });
    return data.updateTipoComprobante;
  } catch (error) {
    throw new Error(`Error updating tipo comprobante: ${error}`);
  }
};

export const deleteTipoComprobanteService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_TIPO_COMPROBANTE,
      variables: { id },
    });
    return data.deleteTipoComprobante;
  } catch (error) {
    throw new Error(`Error deleting tipo comprobante: ${error}`);
  }
};
