import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store/store';
import { fetchEspecialidades, Especialidad  } from '../../../../slices/especialidadSlice';
import Modal from '../../../../components/Modal/Modal';
import EspecialidadForm from './EspecialidadForm';
import { Titulo } from '../../../../slices/tituloSlice';

interface TituloFormProps {
  titulo: Titulo | null;
  onSubmit: (titulo: Titulo) => void;
  onCancel: () => void;
  titulos: Titulo[];
  tituloParentId?: string; // Nueva prop opcional
  ordenCreate?: number; // Nueva prop opcional
  tipo: 'TITULO' | 'PARTIDA';
}

const TituloForm: React.FC<TituloFormProps> = ({
  titulo,
  onSubmit,
  onCancel,
  titulos,
  tituloParentId,
  ordenCreate,
  tipo
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const inputRef = useRef<HTMLInputElement>(null);
  const activePresupuesto = useSelector((state: RootState) => state.activeData.activePresupuesto);
  const especialidades = useSelector((state: RootState) => state.especialidad.especialidades);
  const [showEspecialidadForm, setShowEspecialidadForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const normalizeText = (text: string): string => {
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };

  const filteredEspecialidades = especialidades.filter(esp =>
    normalizeText(esp.nombre).includes(normalizeText(searchTerm))
  );

  const handleEspecialidadSelect = (especialidadId: string) => {
    setFormData({ ...formData, id_especialidad: especialidadId });
    setSearchTerm(''); // Limpiar búsqueda al seleccionar
    setIsDropdownOpen(false);
  };

  const [formData, setFormData] = useState<Partial<Titulo>>({
    descripcion: '',
    nivel: 1,
    item: '',
    parcial: 0,
    id_especialidad: ''
  });

  useEffect(() => {
    if (especialidades.length === 0) {
      dispatch(fetchEspecialidades());
    }
  }, [dispatch, especialidades.length]);

  useEffect(() => {
    if (titulo) {
      setFormData(titulo);
    } else {
      const tituloParent = tituloParentId ? titulos.find(t => t.id_titulo === tituloParentId) : null;
      const nivelBase = tituloParent ? tituloParent.nivel + 1 : 1;
      const hermanosDirectos = titulos.filter(t => t.id_titulo_padre === tituloParentId);
      const nextOrder = tituloParent
        ? Math.max(...hermanosDirectos.map(t => t.orden || 0), 0) + 1
        : titulos.length + 1;

      setFormData({
        descripcion: '',
        nivel: nivelBase,
        item: '',
        parcial: 0,
        orden: (ordenCreate ? ordenCreate : nextOrder) + 0.5,
        id_titulo_padre: tituloParentId || null,
        id_especialidad: especialidades[0]?.id_especialidad || '',
        tipo: tipo
      });
    }
  }, [titulo, titulos, especialidades, tituloParentId, tipo, ordenCreate]);

  useEffect(() => {
    // Enfocar el input cuando el componente se monte
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedEspecialidad = especialidades.find(
    esp => esp.id_especialidad === formData.id_especialidad
  );

  const handleEspecialidadCreated = (nuevaEspecialidad: Especialidad) => {
    setFormData({ ...formData, id_especialidad: nuevaEspecialidad.id_especialidad });
    setShowEspecialidadForm(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!activePresupuesto) return;

    const tituloData: Titulo = {
      id_titulo: titulo?.id_titulo || 'TEMP_' + Date.now(),
      id_presupuesto: activePresupuesto.id_presupuesto,
      id_titulo_padre: formData.id_titulo_padre || null,
      id_titulo_plantilla: null,
      descripcion: formData.descripcion?.toUpperCase() || '',
      nivel: formData.nivel || 1,
      orden: formData.orden || (ordenCreate ? ordenCreate : titulos.length) + 0.5,
      item: formData.item || '01',
      parcial: formData.parcial || 0,
      fecha_creacion: titulo?.fecha_creacion || new Date().toISOString(),
      id_especialidad: formData.id_especialidad || 'ESP0000000001',
      tipo: tipo || 'TITULO',
    };

    onSubmit(tituloData);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6 bg-[#0a192f] rounded-xl p-6 shadow-lg h-[calc(100vh-20rem)] overflow-auto">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-200">
            Descripción
          </label>
          <input
            ref={inputRef}
            type="text"
            value={formData.descripcion || ''}
            onChange={(e) => setFormData({ ...formData, descripcion: e.target.value })}
            className="mt-1 block w-full rounded-md bg-[#112240] border border-gray-600 text-gray-100 px-4 py-2.5 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200"
            required
            placeholder="Ingrese la descripción del título"
          />
        </div>

        <div className="space-y-2 relative">
          <label className="block text-sm font-semibold text-gray-200">
            Especialidad {formData.id_especialidad && '*'}
          </label>
          <div className="flex gap-2" ref={dropdownRef}>
            <div className="relative w-full">
              {selectedEspecialidad && !isDropdownOpen && (
                <div className="flex items-center justify-between mt-1 px-4 py-2.5 rounded-md bg-[#1a365d] text-white border border-blue-500">
                  <span>{selectedEspecialidad.nombre}</span>
                  <button
                    type="button"
                    onClick={() => {
                      setFormData({ ...formData, id_especialidad: '' });
                      setSearchTerm('');
                    }}
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    ×
                  </button>
                </div>
              )}
              
              {(!selectedEspecialidad || isDropdownOpen) && (
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsDropdownOpen(true);
                  }}
                  onFocus={() => setIsDropdownOpen(true)}
                  placeholder={selectedEspecialidad ? "Buscar otra especialidad..." : "Buscar especialidad..."}
                  className="mt-1 block w-full rounded-md bg-[#112240] border border-gray-600 text-gray-100 px-4 py-2.5 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200"
                  aria-label="Buscar especialidad"
                  autoComplete="off"
                />
              )}

              {isDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 max-h-60 overflow-auto rounded-md bg-[#112240] border border-gray-600 shadow-lg">
                  {filteredEspecialidades.map((esp) => (
                    <div
                      key={esp.id_especialidad}
                      onClick={() => handleEspecialidadSelect(esp.id_especialidad)}
                      className={`px-4 py-2.5 cursor-pointer text-white hover:bg-[#1a365d] transition-colors ${
                        formData.id_especialidad === esp.id_especialidad ? 'bg-[#1a365d] font-medium' : ''
                      }`}
                      role="option"
                      aria-selected={formData.id_especialidad === esp.id_especialidad}
                    >
                      {esp.nombre}
                    </div>
                  ))}
                  {filteredEspecialidades.length === 0 && (
                    <div className="px-4 py-2.5 text-gray-400 italic">
                      No se encontraron resultados
                    </div>
                  )}
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={() => setShowEspecialidadForm(true)}
              className="mt-1 px-4 py-2.5 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-200 font-medium flex items-center"
              title="Agregar nueva especialidad"
            >
              +
            </button>
          </div>
          {formData.id_especialidad && (
            <p className="text-xs text-green-500 mt-1">
              Especialidad seleccionada
            </p>
          )}
        </div>

        <div className="flex justify-end space-x-4 pt-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2.5 bg-gray-700 text-gray-200 rounded-md hover:bg-gray-600 transition duration-200 font-medium"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="px-4 py-2.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200 font-medium"
          >
            {titulo ? 'Actualizar' : 'Crear'}
          </button>
        </div>
      </form>

      {showEspecialidadForm && (
        <Modal
          isOpen={showEspecialidadForm}
          onClose={() => setShowEspecialidadForm(false)}
          title="Nueva Especialidad"
        >
          <EspecialidadForm
            onSubmit={handleEspecialidadCreated}
            onCancel={() => setShowEspecialidadForm(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default TituloForm;
