import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_VERIFICACIONES = gql`
  query ListVerificacionesArchivoPago {
    listVerificacionesArchivoPago {
      id
      archivo_pago_id {
        id
        codigo
        monto
        fecha
        descripcion
      }
      usuario_id {
        id
        nombres
        apellidos
        dni
        usuario
      }
      fecha
      estado
    }
  }
`;

const GET_VERIFICACIONES_BY_ARCHIVO = gql`
  query GetVerificacionesByArchivoPago($archivoPagoId: ID!) {
    getVerificacionesByArchivoPago(archivoPagoId: $archivoPagoId) {
      id
      archivo_pago_id {
        id
        file
        fecha 
      }
      usuario_id {
        id
        nombres
        apellidos
        dni
        usuario
      }
      fecha
      estado
    }
  }
`;

const ADD_VERIFICACION = gql`
  mutation AddVerificacionArchivoPago(
    $archivo_pago_id: ID!
    $usuario_id: ID!
    $fecha: DateTime
    $estado: String!
  ) {
    addVerificacionArchivoPago(
      archivo_pago_id: $archivo_pago_id
      usuario_id: $usuario_id
      fecha: $fecha
      estado: $estado
    ) {
      id
      archivo_pago_id {
        id
      }
      usuario_id {
        id
        nombres
        apellidos
      }
      fecha
      estado
    }
  }
`;

const UPDATE_VERIFICACION = gql`
  mutation UpdateVerificacionArchivoPago(
    $id: ID!
    $archivo_pago_id: ID
    $usuario_id: ID
    $fecha: DateTime
    $estado: String
  ) {
    updateVerificacionArchivoPago(
      id: $id
      archivo_pago_id: $archivo_pago_id
      usuario_id: $usuario_id
      fecha: $fecha
      estado: $estado
    ) {
      id
      archivo_pago_id {
        id
        codigo
      }
      usuario_id {
        id
        nombres
        apellidos
      }
      fecha
      estado
    }
  }
`;

const DELETE_VERIFICACION = gql`
  mutation DeleteVerificacionArchivoPago($id: ID!) {
    deleteVerificacionArchivoPago(id: $id) {
      id
    }
  }
`;

export interface VerificacionArchivoPagoInput {
  archivo_pago_id: string;
  usuario_id: string;
  fecha?: string;
  estado: string;
}

// Servicios
export const listVerificacionesArchivoPagoService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_VERIFICACIONES,
    });
    return data.listVerificacionesArchivoPago;
  } catch (error) {
    throw new Error(`Error fetching verificaciones archivo pago: ${error}`);
  }
};

export const getVerificacionesByArchivoService = async (archivoPagoId: string) => {
  try {
    const { data } = await client.query({
      query: GET_VERIFICACIONES_BY_ARCHIVO,
      variables: { archivoPagoId },
    });
    return data.getVerificacionesByArchivoPago;
  } catch (error) {
    throw new Error(`Error fetching verificaciones by archivo pago: ${error}`);
  }
};

export const addVerificacionArchivoPagoService = async (input: VerificacionArchivoPagoInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_VERIFICACION,
      variables: input,
    });
    return data.addVerificacionArchivoPago;
  } catch (error) {
    throw new Error(`Error adding verificacion archivo pago: ${error}`);
  }
};

export const updateVerificacionArchivoPagoService = async (id: string, input: Partial<VerificacionArchivoPagoInput>) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_VERIFICACION,
      variables: { id, ...input },
    });
    return data.updateVerificacionArchivoPago;
  } catch (error) {
    throw new Error(`Error updating verificacion archivo pago: ${error}`);
  }
};

export const deleteVerificacionArchivoPagoService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_VERIFICACION,
      variables: { id },
    });
    return data.deleteVerificacionArchivoPago;
  } catch (error) {
    throw new Error(`Error deleting verificacion archivo pago: ${error}`);
  }
};
