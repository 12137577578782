import React, { useState } from 'react';
import Button from '../../components/Buttons/Button';

interface MenuFormData {
  nombre: string;
  slug: string;
  posicion: number;
}

interface FormComponentProps {
  initialValues?: MenuFormData;
  onSubmit: (data: MenuFormData) => void;
}

const MenuFormComponent: React.FC<FormComponentProps> = ({ initialValues, onSubmit }) => {
  const [formData, setFormData] = useState<MenuFormData>(
    initialValues || { nombre: '', slug: '', posicion: 0 }
  );
  const [errors, setErrors] = useState<{ [K in keyof MenuFormData]?: string }>({});

  const validateForm = (): boolean => {
    const newErrors: { [K in keyof MenuFormData]?: string } = {};
    
    if (!formData.nombre.trim()) {
      newErrors.nombre = 'El nombre es requerido';
    }
    
    if (!formData.slug.trim()) {
      newErrors.slug = 'El slug es requerido';
    }
    
    if (formData.posicion < 0) {
      newErrors.posicion = 'La posición debe ser un número entero positivo';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'posicion' ? (parseInt(value, 10) || 0) : value
    } as MenuFormData));
    // Limpiar error cuando el usuario empieza a escribir
    if (errors[name as keyof MenuFormData]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto"
    >
      <div className="mb-4">
        <label htmlFor="nombre" className="block text-gray-700 text-sm font-bold mb-2">
          Nombre:
        </label>
        <input
          id="nombre"
          name="nombre"
          placeholder="Nombre del menú"
          value={formData.nombre}
          onChange={handleChange}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.nombre ? 'border-red-500' : ''
          }`}
        />
        {errors.nombre && (
          <p className="text-red-500 text-xs italic mt-1">{errors.nombre}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="slug" className="block text-gray-700 text-sm font-bold mb-2">
          Slug:
        </label>
        <input
          id="slug"
          name="slug"
          placeholder="Slug del menú"
          value={formData.slug}
          onChange={handleChange}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.slug ? 'border-red-500' : ''
          }`}
        />
        {errors.slug && (
          <p className="text-red-500 text-xs italic mt-1">{errors.slug}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="posicion" className="block text-gray-700 text-sm font-bold mb-2">
          Posición:
        </label>
        <input
          id="posicion"
          name="posicion"
          type="number"
          placeholder="Posición del menú"
          value={formData.posicion}
          onChange={handleChange}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.posicion ? 'border-red-500' : ''
          }`}
        />
        {errors.posicion && (
          <p className="text-red-500 text-xs italic mt-1">{errors.posicion}</p>
        )}
      </div>

      <div className="flex items-center justify-center mt-6">
        <Button
          text={initialValues ? 'Actualizar Menú' : 'Crear Menú'}
          color="verde"
          className="w-auto px-6 py-2 text-sm font-medium"
        />
      </div>
    </form>
  );
};

export default MenuFormComponent;