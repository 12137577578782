import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  AprobacionIngresoAlmacen,
  AprobacionIngresoAlmacenInput,
  listAprobacionesIngresoAlmacenService,
  getAprobacionesByIngresoAlmacenService,
  addAprobacionIngresoAlmacenService,
  updateAprobacionIngresoAlmacenService,
  deleteAprobacionIngresoAlmacenService
} from '../services/aprobacionIngresoAlmacenComprobanteService';

interface AprobacionIngresoAlmacenState {
  aprobaciones: AprobacionIngresoAlmacen[];
  loading: boolean;
  error: string | null;
  loadingByIngreso: boolean;
  errorByIngreso: string | null;
}

const initialState: AprobacionIngresoAlmacenState = {
  aprobaciones: [],
  loading: false,
  error: null,
  loadingByIngreso: false,
  errorByIngreso: null
};

export const fetchAprobacionesIngresoAlmacen = createAsyncThunk(
  'aprobacionIngresoAlmacen/fetchAprobaciones',
  async (_, { rejectWithValue }) => {
    try {
      return await listAprobacionesIngresoAlmacenService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchAprobacionesByIngresoAlmacen = createAsyncThunk(
  'aprobacionIngresoAlmacen/fetchAprobacionesByIngreso',
  async (ingresoId: string, { rejectWithValue }) => {
    try {
      return await getAprobacionesByIngresoAlmacenService(ingresoId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addAprobacionIngresoAlmacen = createAsyncThunk(
  'aprobacionIngresoAlmacen/addAprobacion',
  async (aprobacion: AprobacionIngresoAlmacenInput, { rejectWithValue }) => {
    try {
      return await addAprobacionIngresoAlmacenService(aprobacion);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateAprobacionIngresoAlmacen = createAsyncThunk(
  'aprobacionIngresoAlmacen/updateAprobacion',
  async ({ id, ...data }: Partial<AprobacionIngresoAlmacenInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateAprobacionIngresoAlmacenService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteAprobacionIngresoAlmacen = createAsyncThunk(
  'aprobacionIngresoAlmacen/deleteAprobacion',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteAprobacionIngresoAlmacenService(id);
      return { id };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const aprobacionIngresoAlmacenSlice = createSlice({
  name: 'aprobacionIngresoAlmacen',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchAprobacionesIngresoAlmacen.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAprobacionesIngresoAlmacen.fulfilled, (state, action: PayloadAction<AprobacionIngresoAlmacen[]>) => {
        state.loading = false;
        state.aprobaciones = action.payload;
      })
      .addCase(fetchAprobacionesIngresoAlmacen.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch by ingreso
      .addCase(fetchAprobacionesByIngresoAlmacen.pending, (state) => {
        state.loadingByIngreso = true;
        state.errorByIngreso = null;
      })
      .addCase(fetchAprobacionesByIngresoAlmacen.fulfilled, (state, action: PayloadAction<AprobacionIngresoAlmacen[]>) => {
        state.loadingByIngreso = false;
        state.aprobaciones = action.payload;
      })
      .addCase(fetchAprobacionesByIngresoAlmacen.rejected, (state, action) => {
        state.loadingByIngreso = false;
        state.errorByIngreso = action.payload as string;
      })
      // Add
      .addCase(addAprobacionIngresoAlmacen.fulfilled, (state, action: PayloadAction<AprobacionIngresoAlmacen>) => {
        state.aprobaciones.push(action.payload);
      })
      // Update
      .addCase(updateAprobacionIngresoAlmacen.fulfilled, (state, action: PayloadAction<AprobacionIngresoAlmacen>) => {
        const index = state.aprobaciones.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.aprobaciones[index] = action.payload;
        }
      })
      // Delete
      .addCase(deleteAprobacionIngresoAlmacen.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.aprobaciones = state.aprobaciones.filter(item => item.id !== action.payload.id);
      });
  },
});

export const aprobacionIngresoAlmacenReducer = aprobacionIngresoAlmacenSlice.reducer;
