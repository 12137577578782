import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchTotalContable } from '../../slices/registroSlice';
import { FiChevronDown, FiMenu, FiSearch } from 'react-icons/fi';

const ListConsolidado: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { totalContable, loadingTotalContable } = useSelector((state: RootState) => state.registro);

    useEffect(() => {
        dispatch(fetchTotalContable());
    }, [dispatch]);

    if (loadingTotalContable) {
        return (
            <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-900"></div>
            </div>
        );
    }

    return (
        <div className="max-w-full overflow-x-auto shadow-md rounded-lg">
                                        <div className="flex justify-center mb-6">
                                <div className="flex flex-wrap gap-2 items-center">
                                    {/* Barra de búsqueda */}
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className="w-60 border border-gray-300 rounded-l p-2"
                                            placeholder="Buscar..."
                                            
                                        />
                                        <button 
                                            className="absolute right-0 top-0 h-full bg-[#192E58] text-white px-3 rounded-r"
                                           
                                        >
                                            <FiSearch className="h-5 w-5" />
                                        </button>
                                    </div>

                                    {/* Selectores */}
                                    <select
                                        className="border border-gray-300 rounded p-2"
                                      
                                    >
                                        <option value="">Todas las Obras</option>
                                        {/* Agregar más opciones según necesites */}
                                    </select>

                                    <select
                                        className="border border-gray-300 rounded p-2"
                                      
                                    >
                                            <option value="">Rango de días</option>
                                            <option value="0">Hoy</option>
                                            <option value="7">Última semana</option>
                                            <option value="15">Últimos 15 Días</option>
                                            <option value="30">Últimos 30 Días</option>
                                    </select>

                                    {/* Inputs de fecha */}
                                    {/* <div className="relative">
                                        <input
                                            type="date"
                                            className="border border-gray-300 rounded p-2 pr-8 cursor-pointer hover:border-blue-500"
                                          
                                        />
                                     
                                    </div>

                                    <div className="relative">
                                        <input
                                            type="date"
                                            className="border border-gray-300 rounded p-2 pr-8 cursor-pointer hover:border-blue-500"
                                          
                                        />
                                    
                                    </div> */}

                                    {/* Botones */}
                                    <button
                                        className="bg-[#192E58] text-white px-6 py-2 rounded hover:bg-[#192E58]/90"
                                     
                                    >
                                        Buscar
                                    </button>
                                    
                                    <button
                                        className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
                                      
                                    >
                                        Limpiar
                                    </button>

                                    <button className="bg-[#192E58] p-2 rounded hover:bg-[#192E58]/90">
                                        <FiMenu className="h-6 w-6 text-white" />
                                    </button>
                                </div>
                            </div>  
            <table className="w-full text-sm">
                <thead>
                    <tr>
                        <th className="bg-blue-500 text-white p-3">Registro</th>
                        <th className="bg-blue-200 text-black p-3" colSpan={2}>Orden de Compra</th>
                        <th className="bg-yellow-200 text-black p-3" colSpan={2}>Pagos</th>
                        <th className="bg-blue-500 text-white p-3">Pendiente</th>
                        <th className="bg-gray-700 text-white p-3" colSpan={2}>Documento contables</th>
                        <th className="bg-blue-500 text-white p-3">Pendiente</th>
                        <th className="bg-yellow-200 text-black p-3" colSpan={2}>Ingreso a almacen</th>
                        <th className="bg-blue-500 text-white p-3">Pendiente</th>
                    </tr>
                </thead>
                <tbody>
                    {totalContable.map((item, index) => (
                        <tr key={index} className="border-b hover:bg-gray-50">
                            <td className="p-3 flex items-center">
                                {item.registro_codigo}
                                <FiChevronDown className="ml-1 h-4 w-4" />
                            </td>
                            <td className="p-3 border">{item.orden_compra_codigo}</td>
                            <td className="p-3 border">S/ {item.orden_compra_total.toFixed(2)}</td>
                            <td className="p-3 border">{item.registro_codigo}</td>
                            <td className="p-3 border">S/ {item.pagos_monto.toFixed(2)}</td>
                            <td className="p-3 border">S/ {item.pendiente_a.toFixed(2)}</td>
                            <td className="p-3 border">{item.tipo_comprobante_nombre}</td>
                            <td className="p-3 border">S/ {item.comprobante_monto.toFixed(2)}</td>
                            <td className="p-3 border">S/ {item.pendiente_b.toFixed(2)}</td>
                            <td className="p-3 border">{item.transferencia_recurso_total_cantidad}</td>
                            <td className="p-3 border">S/ {item.transferencia_recurso_monto_total.toFixed(2)}</td>
                            <td className="p-3 border">S/ {item.pendiente_c.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListConsolidado;
