import React, { useState } from 'react';

interface Campo {
  id: string;
  nombre: string;
  etiqueta: string;
  tipo: string;
}

interface CondicionalMostrarFormProps {
  campos: Campo[];
  condicionalMostrar: string;
  onChange: (condicion: string) => void;
}

const CondicionalMostrarForm: React.FC<CondicionalMostrarFormProps> = ({
  campos,
  condicionalMostrar,
  onChange
}) => {
  const [tipoCondicion, setTipoCondicion] = useState<'igual' | 'diferente' | 'mayor' | 'menor' | 'contiene' | 'personalizada'>('igual');
  const [campoSeleccionado, setCampoSeleccionado] = useState<string>('');
  const [valorCondicion, setValorCondicion] = useState<string>('');
  const [condicionPersonalizada, setCondicionPersonalizada] = useState<string>(condicionalMostrar);

  const operadoresPorTipo: { [key: string]: string[] } = {
    texto: ['igual', 'diferente', 'contiene'],
    numero: ['igual', 'diferente', 'mayor', 'menor'],
    fecha: ['igual', 'diferente', 'mayor', 'menor'],
    select: ['igual', 'diferente'],
    checkbox: ['igual', 'diferente'],
    radio: ['igual', 'diferente']
  };

  const generarCondicion = () => {
    if (tipoCondicion === 'personalizada') {
      onChange(condicionPersonalizada);
      return;
    }

    if (!campoSeleccionado || !valorCondicion) return;

    const campo = campos.find(c => c.id === campoSeleccionado);
    if (!campo) return;

    let condicion = '';
    switch (tipoCondicion) {
      case 'igual':
        condicion = `${campo.nombre} === '${valorCondicion}'`;
        break;
      case 'diferente':
        condicion = `${campo.nombre} !== '${valorCondicion}'`;
        break;
      case 'mayor':
        condicion = `${campo.nombre} > ${valorCondicion}`;
        break;
      case 'menor':
        condicion = `${campo.nombre} < ${valorCondicion}`;
        break;
      case 'contiene':
        condicion = `${campo.nombre}.includes('${valorCondicion}')`;
        break;
    }

    onChange(condicion);
  };

  const handleTipoCondicionChange = (tipo: typeof tipoCondicion) => {
    setTipoCondicion(tipo);
    if (tipo === 'personalizada') {
      onChange(condicionPersonalizada);
    } else {
      generarCondicion();
    }
  };

  const handleCampoChange = (campoId: string) => {
    setCampoSeleccionado(campoId);
    const campo = campos.find(c => c.id === campoId);
    if (campo) {
      // Actualizar tipos de condición disponibles según el tipo de campo
      const tiposDisponibles = operadoresPorTipo[campo.tipo] || ['igual', 'diferente'];
      if (!tiposDisponibles.includes(tipoCondicion)) {
        setTipoCondicion(tiposDisponibles[0] as typeof tipoCondicion);
      }
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-4">
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="radio"
            id="condicionSimple"
            checked={tipoCondicion !== 'personalizada'}
            onChange={() => handleTipoCondicionChange('igual')}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300"
          />
          <label htmlFor="condicionSimple" className="text-sm text-gray-700">
            Usar constructor de condiciones
          </label>
        </div>
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="radio"
            id="condicionPersonalizada"
            checked={tipoCondicion === 'personalizada'}
            onChange={() => handleTipoCondicionChange('personalizada')}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300"
          />
          <label htmlFor="condicionPersonalizada" className="text-sm text-gray-700">
            Escribir condición personalizada
          </label>
        </div>
      </div>

      {tipoCondicion !== 'personalizada' ? (
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-semibold text-gray-700">
              Campo a evaluar
            </label>
            <select
              value={campoSeleccionado}
              onChange={(e) => handleCampoChange(e.target.value)}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="">Seleccione un campo</option>
              {campos.map((campo) => (
                <option key={campo.id} value={campo.id}>
                  {campo.etiqueta} ({campo.tipo})
                </option>
              ))}
            </select>
          </div>

          {campoSeleccionado && (
            <div className="space-y-2">
              <label className="block text-sm font-semibold text-gray-700">
                Tipo de condición
              </label>
              <select
                value={tipoCondicion}
                onChange={(e) => handleTipoCondicionChange(e.target.value as typeof tipoCondicion)}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                {operadoresPorTipo[campos.find(c => c.id === campoSeleccionado)?.tipo || 'texto']?.map((op) => (
                  <option key={op} value={op}>
                    {op === 'igual' && 'Es igual a'}
                    {op === 'diferente' && 'Es diferente de'}
                    {op === 'mayor' && 'Es mayor que'}
                    {op === 'menor' && 'Es menor que'}
                    {op === 'contiene' && 'Contiene'}
                  </option>
                ))}
              </select>
            </div>
          )}

          {campoSeleccionado && (
            <div className="space-y-2">
              <label className="block text-sm font-semibold text-gray-700">
                Valor a comparar
              </label>
              <input
                type="text"
                value={valorCondicion}
                onChange={(e) => {
                  setValorCondicion(e.target.value);
                  generarCondicion();
                }}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Ingrese el valor a comparar"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-semibold text-gray-700">
              Condición personalizada
            </label>
            <textarea
              value={condicionPersonalizada}
              onChange={(e) => {
                setCondicionPersonalizada(e.target.value);
                onChange(e.target.value);
              }}
              rows={4}
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent font-mono text-sm"
              placeholder="Ejemplo: edad > 18 && tipo === 'adulto'"
            />
            <p className="text-xs text-gray-500">
              Puede usar los nombres de los campos como variables. Ejemplo: edad, nombre, tipo, etc.
            </p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-sm font-semibold text-gray-700 mb-2">Campos disponibles:</h4>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {campos.map((campo) => (
                <div key={campo.id} className="text-xs text-gray-600">
                  {campo.etiqueta}: {campo.nombre}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="bg-blue-50 p-4 rounded-lg">
        <h4 className="text-sm font-semibold text-blue-700 mb-2">Ejemplos de uso:</h4>
        <ul className="text-xs text-blue-600 space-y-1">
          <li>• edad {'>'} 18</li>
          <li>• tipo === 'adulto'</li>
          <li>• nombre.includes('Juan')</li>
          <li>• fecha {'>'} '2024-01-01'</li>
          <li>• edad {'>='} 18 && tipo === 'adulto'</li>
          <li>• edad {'<'} 18 || tipo === 'menor'</li>
        </ul>
      </div>
    </div>
  );
};

export default CondicionalMostrarForm; 