import React, { useState } from 'react';

interface Permission {
  ver: boolean;
  crear: boolean;
  editar: boolean;
  eliminar: boolean;
}

interface MenuPermission {
  menuID: string;
  permissions: Permission;
}

interface Role {
  id: string;
  nombre: string;
  descripcion: string;
  menusPermissions: MenuPermission[];
  createdAt?: string;
  updatedAt?: string;
  deleted?: boolean;
}

interface Menu {
  id: string;
  nombre: string;
  posicion: number;
  slug: string;
}

interface Props {
  initialValues?: Role;
  onSubmit: (values: Role) => void;
  menus: Menu[];
}

const RolesFormComponent: React.FC<Props> = ({ initialValues, onSubmit, menus }) => {
  const [formData, setFormData] = useState<Role>(() => {
    const defaultPermissions = {
      ver: false,
      crear: false,
      editar: false,
      eliminar: false,
    };

    const initialMenusPermissions = menus.map(menu => {
      const existingPermission = initialValues?.menusPermissions.find(mp => mp.menuID === menu.id);
      return {
        menuID: menu.id,
        permissions: existingPermission?.permissions || { ...defaultPermissions },
      };
    });

    return {
      id: initialValues?.id || '',
      nombre: initialValues?.nombre || '',
      descripcion: initialValues?.descripcion || '',
      menusPermissions: initialMenusPermissions,
    };
  });

  const [errors, setErrors] = useState<{
    nombre?: string;
    descripcion?: string;
  }>({});

  const validateForm = (): boolean => {
    const newErrors: { nombre?: string; descripcion?: string } = {};

    if (!formData.nombre.trim()) {
      newErrors.nombre = 'El nombre es requerido';
    }

    if (!formData.descripcion.trim()) {
      newErrors.descripcion = 'La descripción es requerida';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (menuIndex: number, permissionType: keyof MenuPermission['permissions']) => {
    setFormData(prev => {
      const newMenusPermissions = [...prev.menusPermissions];
      newMenusPermissions[menuIndex] = {
        ...newMenusPermissions[menuIndex],
        permissions: {
          ...newMenusPermissions[menuIndex].permissions,
          [permissionType]: !newMenusPermissions[menuIndex].permissions[permissionType],
        },
      };
      return { ...prev, menusPermissions: newMenusPermissions };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-white/65 shadow-md rounded-lg p-8 text-xs md:text-base">
      <div>
        <label htmlFor="nombre" className="block text-sm font-medium text-gray-700 mb-2">Nombre</label>
        <input
          type="text"
          id="nombre"
          name="nombre"
          value={formData.nombre}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2 px-4"
        />
        {errors.nombre && <div className="text-red-500 text-sm mt-1">{errors.nombre}</div>}
      </div>

      <div>
        <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700 mb-2">Descripción</label>
        <textarea
          id="descripcion"
          name="descripcion"
          value={formData.descripcion}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 py-2 px-4"
        />
        {errors.descripcion && <div className="text-red-600 text-sm mt-1">{errors.descripcion}</div>}
      </div>

      <div className="overflow-x-auto bg-gray-50 rounded-lg shadow text-[8px] md:text-base">
        <table className="min-w-full text-black">
          <thead className="bg-gray-300">
            <tr>
              <th className="py-2 px-2 md:py-3 md:px-6 text-left text-[8px] md:text-base font-medium text-gray-500 uppercase tracking-wider">Menú</th>
              <th className="py-2 px-2 md:py-3 md:px-6 text-center text-[8px] md:text-base font-medium text-gray-500 uppercase tracking-wider">Ver</th>
              <th className="py-2 px-2 md:py-3 md:px-6 text-center text-[8px] md:text-base font-medium text-gray-500 uppercase tracking-wider">Crear</th>
              <th className="py-2 px-2 md:py-3 md:px-6 text-center text-[8px] md:text-base font-medium text-gray-500 uppercase tracking-wider">Editar</th>
              <th className="py-2 px-2 md:py-3 md:px-6 text-center text-[8px] md:text-base font-medium text-gray-500 uppercase tracking-wider">Eliminar</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-[10px] md:text-base">
            {menus.map((menu, index) => (
              <tr key={menu.id} className="hover:bg-gray-50">
                <td className="py-2 px-2 md:py-3 md:px-6 text-[10px] md:text-base font-medium text-gray-600">{menu.nombre}</td>
                {['ver', 'crear', 'editar', 'eliminar'].map((permission) => (
                  <td key={permission} className="py-2 px-2 md:py-3 md:px-6 text-center">
                    <input
                      type="checkbox"
                      checked={formData.menusPermissions[index].permissions[permission as keyof MenuPermission['permissions']]}
                      onChange={() => handleCheckboxChange(index, permission as keyof MenuPermission['permissions'])}
                      className="form-checkbox h-3 w-3 md:h-4 md:w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        type="submit"
        className="w-full px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
      >
        {initialValues ? 'Actualizar' : 'Crear'} Rol
      </button>
    </form>
  );
};

export default RolesFormComponent;