import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_FORMULARIOS_QUERY = gql`
  query ListFormularios {
    listFormularios {
      id
      codigo
      version
      fechaAprobacion
      titulo
      descripcion
      activo
      fechaCreacion
      fechaModificacion
      secciones {
        id
        formularioId
        titulo
        descripcion
        orden
        condicionalMostrar
        campos {
          id
          seccionId
          tipo
          etiqueta
          nombre
          valorPredeterminado
          placeholder
          requerido
          orden
          ancho
          condicionalMostrar
          propiedadesAdicionales
          opciones {
            id
            campoId
            etiqueta
            valor
            orden
          }
        }
      }
    }
  }
`;

const GET_FORMULARIO_QUERY = gql`
  query GetFormulario($getFormularioId: ID!) {
    getFormulario(id: $getFormularioId) {
      id
      codigo
      version
      fechaAprobacion
      titulo
      descripcion
      activo
      fechaCreacion
      fechaModificacion
      secciones {
        id
        formularioId
        titulo
        descripcion
        orden
        condicionalMostrar
        campos {
          id
          seccionId
          tipo
          etiqueta
          nombre
          valorPredeterminado
          placeholder
          requerido
          orden
          ancho
          condicionalMostrar
          propiedadesAdicionales
          opciones {
            id
            campoId
            etiqueta
            valor
            orden
          }
        }
      }
    }
  }
`;

const GET_SECCIONES_BY_FORMULARIO_QUERY = gql`
  query GetSeccionesByFormulario($formularioId: ID!) {
    getSeccionesByFormulario(formularioId: $formularioId) {
      id
      formularioId
      titulo
      descripcion
      orden
      condicionalMostrar
      campos {
        id
        seccionId
        tipo
        etiqueta
        nombre
        valorPredeterminado
        placeholder
        requerido
        orden
        ancho
        condicionalMostrar
        propiedadesAdicionales
        opciones {
          id
          campoId
          etiqueta
          valor
          orden
        }
      }
    }
  }
`;

const GET_CAMPOS_BY_SECCION_QUERY = gql`
  query GetCamposBySeccion($seccionId: ID!) {
    getCamposBySeccion(seccionId: $seccionId) {
      id
      seccionId
      tipo
      etiqueta
      nombre
      valorPredeterminado
      placeholder
      requerido
      orden
      ancho
      condicionalMostrar
      propiedadesAdicionales
      opciones {
        id
        campoId
        etiqueta
        valor
        orden
      }
    }
  }
`;

const GET_OPCIONES_BY_CAMPO_QUERY = gql`
  query GetOpcionesByCampo($campoId: ID!) {
    getOpcionesByCampo(campoId: $campoId) {
      id
      campoId
      etiqueta
      valor
      orden
    }
  }
`;

const GET_RESPUESTAS_BY_FORMULARIO_QUERY = gql`
  query GetRespuestasByFormulario($formularioId: ID!) {
    getRespuestasByFormulario(formularioId: $formularioId) {
      id
      formularioId
      fechaCreacion
      fechaModificacion
      datosJson
      usuarioId
    }
  }
`;

const GET_RESPUESTA_QUERY = gql`
  query GetRespuesta($getRespuestaId: ID!) {
    getRespuesta(id: $getRespuestaId) {
      id
      formularioId
      fechaCreacion
      fechaModificacion
      datosJson
      usuarioId
    }
  }
`;

const ADD_FORMULARIO_MUTATION = gql`
  mutation AddFormulario(
    $codigo: String!,
    $version: String!,
    $fechaAprobacion: String!,
    $titulo: String!,
    $descripcion: String,
    $activo: Boolean
  ) {
    addFormulario(
      codigo: $codigo,
      version: $version,
      fechaAprobacion: $fechaAprobacion,
      titulo: $titulo,
      descripcion: $descripcion,
      activo: $activo
    ) {
      id
      codigo
      version
      fechaAprobacion
      titulo
      descripcion
      activo
      fechaCreacion
      fechaModificacion
    }
  }
`;

const UPDATE_FORMULARIO_MUTATION = gql`
  mutation UpdateFormulario(
    $updateFormularioId: ID!,
    $codigo: String,
    $version: String,
    $fechaAprobacion: String,
    $titulo: String,
    $descripcion: String,
    $activo: Boolean
  ) {
    updateFormulario(
      id: $updateFormularioId,
      codigo: $codigo,
      version: $version,
      fechaAprobacion: $fechaAprobacion,
      titulo: $titulo,
      descripcion: $descripcion,
      activo: $activo
    ) {
      id
      codigo
      version
      fechaAprobacion
      titulo
      descripcion
      activo
      fechaCreacion
      fechaModificacion
    }
  }
`;

const DELETE_FORMULARIO_MUTATION = gql`
  mutation DeleteFormulario($deleteFormularioId: ID!) {
    deleteFormulario(id: $deleteFormularioId) {
      id
    }
  }
`;

const ADD_SECCION_MUTATION = gql`
  mutation AddSeccion(
    $formularioId: ID!, 
    $titulo: String!, 
    $orden: Int!, 
    $descripcion: String, 
    $condicionalMostrar: String
  ) {
    addSeccion(
      formularioId: $formularioId, 
      titulo: $titulo, 
      orden: $orden, 
      descripcion: $descripcion, 
      condicionalMostrar: $condicionalMostrar
    ) {
      id
      formularioId
      titulo
      descripcion
      orden
      condicionalMostrar
    }
  }
`;

const UPDATE_SECCION_MUTATION = gql`
  mutation UpdateSeccion(
    $updateSeccionId: ID!, 
    $titulo: String, 
    $descripcion: String, 
    $orden: Int, 
    $condicionalMostrar: String
  ) {
    updateSeccion(
      id: $updateSeccionId, 
      titulo: $titulo, 
      descripcion: $descripcion, 
      orden: $orden, 
      condicionalMostrar: $condicionalMostrar
    ) {
      id
      formularioId
      titulo
      descripcion
      orden
      condicionalMostrar
    }
  }
`;

const DELETE_SECCION_MUTATION = gql`
  mutation DeleteSeccion($deleteSeccionId: ID!) {
    deleteSeccion(id: $deleteSeccionId) {
      id
    }
  }
`;

const ADD_CAMPO_MUTATION = gql`
  mutation AddCampo(
    $seccionId: ID!, 
    $tipo: TipoCampo!, 
    $etiqueta: String!, 
    $nombre: String!, 
    $orden: Int!, 
    $ancho: Int!, 
    $valorPredeterminado: String, 
    $placeholder: String, 
    $requerido: Boolean, 
    $condicionalMostrar: String, 
    $propiedadesAdicionales: String
  ) {
    addCampo(
      seccionId: $seccionId, 
      tipo: $tipo, 
      etiqueta: $etiqueta, 
      nombre: $nombre, 
      orden: $orden, 
      ancho: $ancho, 
      valorPredeterminado: $valorPredeterminado, 
      placeholder: $placeholder, 
      requerido: $requerido, 
      condicionalMostrar: $condicionalMostrar, 
      propiedadesAdicionales: $propiedadesAdicionales
    ) {
      id
      seccionId
      tipo
      etiqueta
      nombre
      valorPredeterminado
      placeholder
      requerido
      orden
      ancho
      condicionalMostrar
      propiedadesAdicionales
    }
  }
`;

const UPDATE_CAMPO_MUTATION = gql`
  mutation UpdateCampo(
    $updateCampoId: ID!, 
    $tipo: TipoCampo, 
    $etiqueta: String, 
    $nombre: String, 
    $valorPredeterminado: String, 
    $placeholder: String, 
    $requerido: Boolean, 
    $orden: Int, 
    $ancho: Int, 
    $condicionalMostrar: String, 
    $propiedadesAdicionales: String
  ) {
    updateCampo(
      id: $updateCampoId, 
      tipo: $tipo, 
      etiqueta: $etiqueta, 
      nombre: $nombre, 
      valorPredeterminado: $valorPredeterminado, 
      placeholder: $placeholder, 
      requerido: $requerido, 
      orden: $orden, 
      ancho: $ancho, 
      condicionalMostrar: $condicionalMostrar, 
      propiedadesAdicionales: $propiedadesAdicionales
    ) {
      id
      seccionId
      tipo
      etiqueta
      nombre
      valorPredeterminado
      placeholder
      requerido
      orden
      ancho
      condicionalMostrar
      propiedadesAdicionales
    }
  }
`;

const DELETE_CAMPO_MUTATION = gql`
  mutation DeleteCampo($deleteCampoId: ID!) {
    deleteCampo(id: $deleteCampoId) {
      id
    }
  }
`;

const ADD_OPCION_MUTATION = gql`
  mutation AddOpcion($campoId: ID!, $etiqueta: String!, $valor: String!, $orden: Int!) {
    addOpcion(campoId: $campoId, etiqueta: $etiqueta, valor: $valor, orden: $orden) {
      id
      campoId
      etiqueta
      valor
      orden
    }
  }
`;

const UPDATE_OPCION_MUTATION = gql`
  mutation UpdateOpcion($updateOpcionId: ID!, $etiqueta: String, $valor: String, $orden: Int) {
    updateOpcion(id: $updateOpcionId, etiqueta: $etiqueta, valor: $valor, orden: $orden) {
      id
      campoId
      etiqueta
      valor
      orden
    }
  }
`;

const DELETE_OPCION_MUTATION = gql`
  mutation DeleteOpcion($deleteOpcionId: ID!) {
    deleteOpcion(id: $deleteOpcionId) {
      id
      campoId
      etiqueta
      valor
      orden
    }
  }
`;

const ADD_RESPUESTA_MUTATION = gql`
  mutation AddRespuesta(
    $formularioId: ID!
    $datosJson: String!
    $usuarioId: String
  ) {
    addRespuesta(
      formularioId: $formularioId
      datosJson: $datosJson
      usuarioId: $usuarioId
    ) {
      id
      formularioId
      fechaCreacion
      fechaModificacion
      datosJson
      usuarioId
    }
  }
`;

const UPDATE_RESPUESTA_MUTATION = gql`
  mutation UpdateRespuesta($updateRespuestaId: ID!, $datosJson: String) {
    updateRespuesta(id: $updateRespuestaId, datosJson: $datosJson) {
      id
      formularioId
      fechaCreacion
      fechaModificacion
      datosJson
      usuarioId
    }
  }
`;

const DELETE_RESPUESTA_MUTATION = gql`
  mutation DeleteRespuesta($deleteRespuestaId: ID!) {
    deleteRespuesta(id: $deleteRespuestaId) {
      id
    }
  }
`;

export const listFormulariosService = async () => {
  try {
    const response = await client.query({
      query: LIST_FORMULARIOS_QUERY,
    });
    return response.data.listFormularios;
  } catch (error) {
    throw new Error(`Error fetching formularios: ${error}`);
  }
};

export const getFormularioService = async (id: string) => {
  try {
    const response = await client.query({
      query: GET_FORMULARIO_QUERY,
      variables: { getFormularioId: id },
    });
    return response.data.getFormulario;
  } catch (error) {
    throw new Error(`Error fetching formulario: ${error}`);
  }
};

export const getSeccionesByFormularioService = async (formularioId: string) => {
  try {
    const response = await client.query({
      query: GET_SECCIONES_BY_FORMULARIO_QUERY,
      variables: { formularioId },
    });
    return response.data.getSeccionesByFormulario;
  } catch (error) {
    throw new Error(`Error fetching secciones by formulario: ${error}`);
  }
};

export const getCamposBySeccionService = async (seccionId: string) => {
  try {
    const response = await client.query({
      query: GET_CAMPOS_BY_SECCION_QUERY,
      variables: { seccionId },
    });
    return response.data.getCamposBySeccion;
  } catch (error) {
    throw new Error(`Error fetching campos by seccion: ${error}`);
  }
};

export const addFormularioService = async (data: {
  codigo: string;
  version: string;
  fechaAprobacion: string;
  titulo: string;
  descripcion?: string;
  activo?: boolean;
}) => {
  try {
    const response = await client.mutate({
      mutation: ADD_FORMULARIO_MUTATION,
      variables: data,
    });
    return response.data.addFormulario;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error adding formulario: ${error.message}`);
    }
    throw error;
  }
};

export const updateFormularioService = async (data: {
  updateFormularioId: string;
  codigo?: string;
  version?: string;
  fechaAprobacion?: string;
  titulo?: string;
  descripcion?: string;
  activo?: boolean;
}) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_FORMULARIO_MUTATION,
      variables: data,
    });
    return response.data.updateFormulario;
  } catch (error) {
    throw new Error(`Error updating formulario: ${error}`);
  }
};

export const deleteFormularioService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_FORMULARIO_MUTATION,
      variables: { deleteFormularioId: id },
    });
    return response.data.deleteFormulario;
  } catch (error) {
    throw new Error(`Error deleting formulario: ${error}`);
  }
};

export const addSeccionService = async (data: {
  formularioId: string;
  titulo: string;
  orden: number;
  descripcion?: string;
  condicionalMostrar?: string;
}) => {
  try {
    const response = await client.mutate({
      mutation: ADD_SECCION_MUTATION,
      variables: data,
    });
    return response.data.addSeccion;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error adding seccion: ${error.message}`);
    }
    throw error;
  }
};

export const updateSeccionService = async (data: {
  updateSeccionId: string;
  titulo?: string;
  descripcion?: string;
  orden?: number;
  condicionalMostrar?: string;
}) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_SECCION_MUTATION,
      variables: data,
    });
    return response.data.updateSeccion;
  } catch (error) {
    throw new Error(`Error updating seccion: ${error}`);
  }
};

export const deleteSeccionService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_SECCION_MUTATION,
      variables: { deleteSeccionId: id },
    });
    return response.data.deleteSeccion;
  } catch (error) {
    throw new Error(`Error deleting seccion: ${error}`);
  }
};

export const addCampoService = async (data: {
  seccionId: string;
  tipo: string;
  etiqueta: string;
  nombre: string;
  orden: number;
  ancho: number;
  valorPredeterminado?: string;
  placeholder?: string;
  requerido?: boolean;
  condicionalMostrar?: string;
  propiedadesAdicionales?: string;
}) => {
  try {
    const response = await client.mutate({
      mutation: ADD_CAMPO_MUTATION,
      variables: data,
    });
    return response.data.addCampo;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error adding campo: ${error.message}`);
    }
    throw error;
  }
};

export const updateCampoService = async (data: {
  updateCampoId: string;
  tipo?: string;
  etiqueta?: string;
  nombre?: string;
  valorPredeterminado?: string;
  placeholder?: string;
  requerido?: boolean;
  orden?: number;
  ancho?: number;
  condicionalMostrar?: string;
  propiedadesAdicionales?: string;
}) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_CAMPO_MUTATION,
      variables: data,
    });
    return response.data.updateCampo;
  } catch (error) {
    throw new Error(`Error updating campo: ${error}`);
  }
};

export const deleteCampoService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_CAMPO_MUTATION,
      variables: { deleteCampoId: id },
    });
    return response.data.deleteCampo;
  } catch (error) {
    throw new Error(`Error deleting campo: ${error}`);
  }
};

export const getOpcionesByCampoService = async (campoId: string) => {
  try {
    const response = await client.query({
      query: GET_OPCIONES_BY_CAMPO_QUERY,
      variables: { campoId },
    });
    return response.data.getOpcionesByCampo;
  } catch (error) {
    throw new Error(`Error fetching opciones by campo: ${error}`);
  }
};

export const getRespuestasByFormularioService = async (formularioId: string) => {
  try {
    const response = await client.query({
      query: GET_RESPUESTAS_BY_FORMULARIO_QUERY,
      variables: { formularioId },
    });
    return response.data.getRespuestasByFormulario;
  } catch (error) {
    throw new Error(`Error fetching respuestas by formulario: ${error}`);
  }
};

export const addOpcionService = async (data: {
  campoId: string;
  etiqueta: string;
  valor: string;
  orden: number;
}) => {
  try {
    const response = await client.mutate({
      mutation: ADD_OPCION_MUTATION,
      variables: data,
    });
    return response.data.addOpcion;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error adding opcion: ${error.message}`);
    }
    throw error;
  }
};

export const updateOpcionService = async (data: {
  updateOpcionId: string;
  etiqueta?: string;
  valor?: string;
  orden?: number;
}) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_OPCION_MUTATION,
      variables: data,
    });
    return response.data.updateOpcion;
  } catch (error) {
    throw new Error(`Error updating opcion: ${error}`);
  }
};

export const deleteOpcionService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_OPCION_MUTATION,
      variables: { deleteOpcionId: id },
    });
    return response.data.deleteOpcion;
  } catch (error) {
    throw new Error(`Error deleting opcion: ${error}`);
  }
};

export const getRespuestaService = async (id: string) => {
  try {
    const response = await client.query({
      query: GET_RESPUESTA_QUERY,
      variables: { getRespuestaId: id },
    });
    return response.data.getRespuesta;
  } catch (error) {
    throw new Error(`Error fetching respuesta: ${error}`);
  }
};

export const addRespuestaService = async (data: {
  formularioId: string;
  datosJson: string;
  usuarioId?: string;
}) => {
  try {
    const response = await client.mutate({
      mutation: ADD_RESPUESTA_MUTATION,
      variables: data,
    });
    return response.data.addRespuesta;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error adding respuesta: ${error.message}`);
    }
    throw error;
  }
};

export const updateRespuestaService = async (data: {
  updateRespuestaId: string;
  datosJson?: string;
}) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_RESPUESTA_MUTATION,
      variables: data,
    });
    return response.data.updateRespuesta;
  } catch (error) {
    throw new Error(`Error updating respuesta: ${error}`);
  }
};

export const deleteRespuestaService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_RESPUESTA_MUTATION,
      variables: { deleteRespuestaId: id },
    });
    return response.data.deleteRespuesta;
  } catch (error) {
    throw new Error(`Error deleting respuesta: ${error}`);
  }
}; 