import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { listPrefijosService, addPrefijoService, updatePrefijoService, deletePrefijoService } from '../services/prefijoService';

// Agregar exportación de interfaces
export interface Prefijo {
  id: string;
  prefijo_id: string;
  nombre_tabla: string;
  prefijo: string;
  cantidad_caracteres: number;
  usa_este_numero: number;
}

export interface PrefijoFormData {
  nombre_tabla: string;
  prefijo: string;
  cantidad_caracteres: number;
}

interface PrefijoState {
  prefijos: Prefijo[];
  loading: boolean;
  error: string | null;
}

const initialState: PrefijoState = {
  prefijos: [],
  loading: false,
  error: null,
};

const handleError = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const fetchPrefijos = createAsyncThunk(
  'prefijo/fetchPrefijos',
  async (_, { rejectWithValue }) => {
    try {
      return await listPrefijosService();
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const addPrefijo = createAsyncThunk(
  'prefijo/addPrefijo',
  async (prefijoData: { nombre_tabla: string, prefijo: string, cantidad_caracteres: number }, { rejectWithValue }) => {
    try {
      return await addPrefijoService(prefijoData);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const updatePrefijo = createAsyncThunk(
  'prefijo/updatePrefijo',
  async (prefijo: { id: string, usa_este_numero: number }, { rejectWithValue }) => {
    try {
      return await updatePrefijoService(prefijo);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const deletePrefijo = createAsyncThunk(
  'prefijo/deletePrefijo',
  async (id: string, { rejectWithValue }) => {
    try {
      await deletePrefijoService(id);
      return id;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

const prefijoSlice = createSlice({
  name: 'prefijo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrefijos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPrefijos.fulfilled, (state, action: PayloadAction<Prefijo[]>) => {
        state.loading = false;
        state.prefijos = action.payload;
      })
      .addCase(fetchPrefijos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addPrefijo.fulfilled, (state, action: PayloadAction<Prefijo>) => {
        state.prefijos.push(action.payload);
      })
      .addCase(updatePrefijo.fulfilled, (state, action: PayloadAction<Prefijo>) => {
        const index = state.prefijos.findIndex(prefijo => prefijo.id === action.payload.id);
        if (index !== -1) {
          state.prefijos[index] = action.payload;
        }
      })
      .addCase(deletePrefijo.fulfilled, (state, action: PayloadAction<string>) => {
        state.prefijos = state.prefijos.filter(prefijo => prefijo.id !== action.payload);
      });
  },
});

export const prefijoReducer = prefijoSlice.reducer;
