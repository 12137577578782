import React, { useState, useEffect } from 'react';
import { OrdenPagoDescuento } from '../../slices/descuentoPagoSlice';
import { calcularDescuento } from '../../components/Utils/calculosUtils';

interface DescuentoPagoFormProps {
  initialValues?: OrdenPagoDescuento;
  onSubmit: (values: Partial<OrdenPagoDescuento>) => void;
  ordenPagoId?: string;
  tipoDescuento?: 'detracciones' | 'retenciones' | null;
}

// Actualizar la interfaz FormValues para incluir todos los campos necesarios
interface FormValues {
  tipo: string;
  monto: number;
  detalle: string;
  porcentaje: number;
  montoBase: number;
  codigo: string;  // Agregar código
}

const DescuentoPagoFormComponent: React.FC<DescuentoPagoFormProps> = ({
  initialValues,
  onSubmit,
  ordenPagoId,
}) => {
  const [formValues, setFormValues] = useState<FormValues>({
    tipo: initialValues?.tipo || '',
    monto: initialValues?.monto || 0,
    detalle: initialValues?.detalle || '',
    porcentaje: initialValues?.tipo === 'retencion' ? 3 : 
                initialValues?.tipo === 'detraccion' ? 12 : 0,
    montoBase: initialValues?.monto || 0,
    codigo: initialValues?.codigo || ''  // Agregar código
  });

  // Actualizar useEffect para manejar los cambios de initialValues
  useEffect(() => {
    if (initialValues) {
      setFormValues({
        tipo: initialValues.tipo,
        monto: initialValues.monto,
        detalle: initialValues.detalle,
        porcentaje: initialValues.tipo === 'retencion' ? 3 : 
                   initialValues.tipo === 'detraccion' ? 12 : 0,
        montoBase: initialValues.monto,
        codigo: initialValues.codigo  // Agregar código
      });
    }
  }, [initialValues]);

  // Handler para cambio de tipo de descuento
  const handleTipoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tipo = e.target.value;
    setFormValues(prev => ({
      ...prev,
      tipo,
      porcentaje: tipo === 'retencion' ? 3 : 
                  tipo === 'detraccion' ? 12 :
                  tipo === 'pendiente' ? 100 : 0
    }));

    // Recalcular monto si hay monto base
    if (formValues.montoBase > 0) {
      const resultado = calcularDescuento(
        tipo,
        formValues.montoBase,
        tipo === 'retencion' ? 3 : formValues.porcentaje
      );
      setFormValues(prev => ({ ...prev, monto: resultado }));
    }
  };

  // Calcular monto cuando cambian porcentaje o monto base
  useEffect(() => {
    if (!formValues.tipo || formValues.montoBase < 0 || formValues.porcentaje < 0) {
      setFormValues(prev => ({ ...prev, monto: 0 }));
      return;
    }

    const resultado = calcularDescuento(
      formValues.tipo,
      formValues.montoBase,
      formValues.tipo === 'retencion' ? 3 : formValues.porcentaje
    );
    
    setFormValues(prev => ({ ...prev, monto: resultado }));
  }, [formValues.tipo, formValues.porcentaje, formValues.montoBase]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      id: initialValues?.id,
      codigo: formValues.codigo,  // Incluir código en la actualización
      tipo: formValues.tipo,
      monto: formValues.montoBase,
      detalle: formValues.detalle,
      orden_pago_id: {
        id: ordenPagoId || '',
        codigo: initialValues?.orden_pago_id?.codigo || '',
        monto_solicitado: initialValues?.orden_pago_id?.monto_solicitado || 0,
        tipo_moneda: initialValues?.orden_pago_id?.tipo_moneda || '',
        tipo_pago: initialValues?.orden_pago_id?.tipo_pago || '',
        estado: initialValues?.orden_pago_id?.estado || '',
        observaciones: initialValues?.orden_pago_id?.observaciones || '',
        comprobante: initialValues?.orden_pago_id?.comprobante || '',
        fecha: initialValues?.orden_pago_id?.fecha || ''
      },
      estado: 'ACTIVO'
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex flex-row gap-4 items-start">
        {/* Select para tipo de descuento */}
        <div className="flex-1 min-w-[200px]">
          <label htmlFor="tipo" className="block text-sm font-medium text-gray-700 mb-1">
            Tipo de Descuento
          </label>
          <select 
            id="tipo" 
            value={formValues.tipo}
            onChange={handleTipoChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">Seleccione</option>
            <option value="detraccion">Detracción (12%)</option>
            <option value="retencion">Retención (3%)</option>
            <option value="pendiente">Pendientes</option>
          </select>

          {/* Contenedor para los inputs lado a lado */}
          <div className="flex gap-4 mt-5">
            <div className="flex-1">
              <label htmlFor="porcentaje" className="block text-sm font-medium text-gray-700 mb-1">
                Porcentaje de cálculo
              </label>
              <input 
                type="number"
                id="porcentaje"
                value={formValues.porcentaje}
                onChange={(e) => setFormValues(prev => ({
                  ...prev,
                  porcentaje: Number(e.target.value)
                }))}
                disabled={formValues.tipo === 'retencion'}
                className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                  ${formValues.tipo === 'retencion' ? 'bg-gray-100' : ''}
                  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                min="0"
                step="0.01"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="montoBase" className="block text-sm font-medium text-gray-700 mb-1">
                Monto para calcular
              </label>
              <input 
                type="number"
                id="montoBase"
                value={formValues.monto}
                onChange={(e) => setFormValues(prev => ({
                  ...prev,
                  monto: Number(e.target.value)
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                min="0"
                step="0.01"
              />
              {formValues.tipo === 'detraccion' && formValues.montoBase < 701 && (
                <p className="text-red-500 text-sm mt-1">
                  El monto tiene que ser mayor a 700 soles, para detracciones.
                </p>
              )}
            </div>
          </div>

          <div className="mt-4 p-3 bg-gray-50 border border-gray-200 rounded-md">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium text-gray-700">Monto calculado:</span>
              <span className="text-lg font-bold text-blue-600">
                S/ {formValues.monto.toFixed(2)}
              </span>
            </div>
            <p className="text-red-500 text-xs mt-2 italic">
              * El monto calculado es solo una referencia de ayuda
            </p>
          </div>
        </div>

        {/* Div con input numérico */}
        <div className="flex-1 min-w-[200px]">
          <div className="space-y-4">
            <div>
              <label htmlFor="monto" className="block text-sm font-medium text-gray-700 mb-1">
                Ingrese monto que se va descontar
              </label>
              <input 
                type="number"
                id="monto"
                value={formValues.montoBase}
                onChange={(e) => setFormValues(prev => ({
                  ...prev,
                  montoBase: Number(e.target.value)
                }))}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
                min="0"
                step="0.01"
              />
            </div>
          </div>
        </div>

        {/* Textarea */}
        <div className="flex-1 min-w-[300px]">
          <label htmlFor="detalle" className="block text-sm font-medium text-gray-700 mb-1">
            Detalle del descuento
          </label>
          <textarea 
            id="detalle"
            value={formValues.detalle}
            onChange={(e) => setFormValues(prev => ({
              ...prev,
              detalle: e.target.value
            }))}
            rows={4}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        {/* Botón Actualizar */}
        <div className="flex items-end pb-1 mt-14">
          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors"
          >
            {initialValues ? 'Actualizar' : 'Crear'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default DescuentoPagoFormComponent;
