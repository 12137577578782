import React, { useState, useRef } from 'react';
import { FiFile, FiTrash2 } from 'react-icons/fi';
import { FiFolderPlus } from 'react-icons/fi'; 
interface MultipleFileUploaderProps {
  onFilesSelected: (files: File[]) => void;
  onUploadComplete?: () => void;  // Opcional
}

const MultipleFileUploader: React.FC<MultipleFileUploaderProps> = ({ 
  onFilesSelected,
  onUploadComplete
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setSelectedFiles(prev => [...prev, ...newFiles]);
      onFilesSelected([...selectedFiles, ...newFiles]);
      onUploadComplete?.();
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles(prev => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      onFilesSelected(newFiles);
      return newFiles;
    });
  };

  return (
    <div className="px-4">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelect}
        className="hidden"
        multiple
        accept=".pdf,.doc,.docx,.xls,.xlsx"
      />

      <div className="mb-4 flex items-start space-x-2">
          <div className="flex-1 flex text-sm text-gray-500 border-2 border-[#192E58] p-2 rounded-lg">
            <div>
              <p className='text-center text-[#192E58]'> Otro archivo </p>  
              <p className='ml-5 mr-5'> (Guia de remisión, Guia de transportista, informe,etc  ) </p>   
            </div>            
            <div className='text-center pt-5'>
            <FiFolderPlus className="w-5 h-5 text-[#192E58]" />
            </div>     
          </div>
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="p-2 hover:bg-gray-100 rounded-md mt-5 mb-5"
          >
            <svg 
              className="w-6 h-6 text-[#192E58]" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M12 4v16m8-8H4" 
              />
            </svg>
          </button>
      </div>

      <div className="space-y-4">
        {selectedFiles.map((file, index) => (
          <div key={index} className="flex items-center justify-between p-3 border rounded-md">
            <div className="flex items-center">
              <FiFile className="mr-2 text-[#192E58]" />
              <span className="text-sm">{file.name}</span>
            </div>
            <button
              type="button"
              onClick={() => handleRemoveFile(index)}
              className="text-red-500 hover:text-red-700"
            >
              <FiTrash2 />
            </button>
          </div>
        ))}
      </div>

      {selectedFiles.length > 0 && (
        <div className="mt-4">
          <span className="text-sm text-gray-500">
            {selectedFiles.length} archivo(s) seleccionado(s)
          </span>
        </div>
      )}
    </div>
  );
};

export default MultipleFileUploader;
