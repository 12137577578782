import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ConstanciaComprobante,
  ConstanciaComprobanteInput,
  listConstanciasComprobanteService,
  getByComprobanteIdService,
  addConstanciaComprobanteService,
  updateConstanciaComprobanteService,
  deleteConstanciaComprobanteService,
} from '../services/constanciaComprobanteService';

interface ConstanciaComprobanteState {
  items: ConstanciaComprobante[];
  byComprobante: ConstanciaComprobante[];
  loading: boolean;
  error: string | null;
  loadingByComprobante: boolean;
  errorByComprobante: string | null;
}

const initialState: ConstanciaComprobanteState = {
  items: [],
  byComprobante: [],
  loading: false,
  error: null,
  loadingByComprobante: false,
  errorByComprobante: null,
};

// Thunks
export const fetchConstanciasComprobante = createAsyncThunk(
  'constanciaComprobante/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await listConstanciasComprobanteService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchByComprobanteId = createAsyncThunk(
  'constanciaComprobante/fetchByComprobanteId',
  async (comprobanteId: string, { rejectWithValue }) => {
    try {
      return await getByComprobanteIdService(comprobanteId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addConstanciaComprobante = createAsyncThunk(
  'constanciaComprobante/add',
  async (data: ConstanciaComprobanteInput, { rejectWithValue }) => {
    try {
      return await addConstanciaComprobanteService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateConstanciaComprobante = createAsyncThunk(
  'constanciaComprobante/update',
  async ({ id, ...data }: Partial<ConstanciaComprobanteInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateConstanciaComprobanteService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteConstanciaComprobante = createAsyncThunk(
  'constanciaComprobante/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteConstanciaComprobanteService(id);
      return { id };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const constanciaComprobanteSlice = createSlice({
  name: 'constanciaComprobante',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchConstanciasComprobante.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConstanciasComprobante.fulfilled, (state, action: PayloadAction<ConstanciaComprobante[]>) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchConstanciasComprobante.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch by comprobante
      .addCase(fetchByComprobanteId.pending, (state) => {
        state.loadingByComprobante = true;
        state.errorByComprobante = null;
      })
      .addCase(fetchByComprobanteId.fulfilled, (state, action: PayloadAction<ConstanciaComprobante[]>) => {
        state.loadingByComprobante = false;
        state.byComprobante = action.payload;
      })
      .addCase(fetchByComprobanteId.rejected, (state, action) => {
        state.loadingByComprobante = false;
        state.errorByComprobante = action.payload as string;
      })
      // Add
      .addCase(addConstanciaComprobante.fulfilled, (state, action: PayloadAction<ConstanciaComprobante>) => {
        state.items.push(action.payload);
      })
      // Update
      .addCase(updateConstanciaComprobante.fulfilled, (state, action: PayloadAction<ConstanciaComprobante>) => {
        const index = state.items.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      // Delete
      .addCase(deleteConstanciaComprobante.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.items = state.items.filter(item => item.id !== action.payload.id);
      });
  },
});

export const constanciaComprobanteReducer = constanciaComprobanteSlice.reducer;
