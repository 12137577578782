import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchBySolicitudId, SolicitudCompraRecurso } from '../../../slices/solicitudCompraRecursoSlice';
import { formatCurrency } from '../../../components/Utils/priceFormatUtils';
import IMG from '../../../components/IMG/IMG';
import noImage from '../../../assets/NoImage.webp';
import ModalAlert from '../../../components/Modal/ModalAlert';
import {
  setObraFilter,
  addRecurso,
  lockSelection,
  unlockSelection,
  removeAllRecursos,
  updateRecursoNoServicio,
  updateRecursoServicio,
  removeRecursoNoServicio,
  removeRecursoServicio,
  updateSolicitudEstadoCotizacion,
  clearSolicitudEstadosCotizacion,
  EstadoCotizacion,
  ESTADOS_FINALES
} from '../../../slices/compraSelectorSlice';
import { FiPlus, FiRefreshCw, FiX } from 'react-icons/fi';
import { setRequerimientosRecursoIds, fetchRequerimientosRecursoAll } from '../../../slices/requerimientoRecursoAllSlice';

const RecursosSolicitudList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { requerimientosRecursoIds, data, loading: loadingRequerimientoRecursosAll } = useSelector((state: RootState) => state.requerimientoRecursoAll);
  const { selectedSolicitudId, obraFilter } = useSelector((state: RootState) => state.compraSelector);
  const {
    loading: loadingSolicitud,
    error,
    acumulatedResources: recursos
  } = useSelector((state: RootState) => state.solicitudCompraRecurso);
  const solicitud = useSelector((state: RootState) =>
    state.solicitudCompra.solicitudes.find(s => s.id === selectedSolicitudId)
  );
  const {
    selectedSolicitudes,
    solicitudColors,
    isSelectionLocked,
    recursosNoServicios,
    recursosServicios,
    solicitudEstadosCotizacion
  } = useSelector((state: RootState) => state.compraSelector);
  // const usuario = useSelector((state: RootState) => state.user);
  const [cantidades, setCantidades] = useState<{ [key: string]: number }>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalVariant, setModalVariant] = useState<'green' | 'yellow' | 'red' | 'blue' | 'white' | 'black'>('blue');
  const [modalConfirmAction, setModalConfirmAction] = useState<() => void>(() => { });

  // Mantener registro de recursos que ya han sido añadidos
  const [addedResources, setAddedResources] = useState<{ [key: string]: boolean }>({});

  // Verificar si la solicitud seleccionada tiene un estado final
  const solicitudTieneEstadoFinal = useMemo(() => {
    if (!solicitud || !solicitud.estado) return false;
    return ESTADOS_FINALES.includes(solicitud.estado);
  }, [solicitud]);

  useEffect(() => {
    if (selectedSolicitudes && selectedSolicitudes.length > 0) {
      // Fetch resources for each selected solicitud
      selectedSolicitudes.forEach(solicitudId => {
        dispatch(fetchBySolicitudId(solicitudId));
      });
    }
  }, [selectedSolicitudes, dispatch]);

  // Obtener el color correspondiente a cada solicitud
  const getResourceColorClass = (solicitudId: string) => {
    const colorAssignment = solicitudColors.find(sc => sc.solicitudId === solicitudId);
    return colorAssignment?.colorClass || '';
  };

  useEffect(() => {
    // dispatch(clearRequerimientoRecursoData());
    dispatch(setRequerimientosRecursoIds(recursos.map(recurso => recurso.requerimiento_recurso_id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recursos]);
  useEffect(() => {
    // dispatch(clearRequerimientoRecursoData());
    dispatch(fetchRequerimientosRecursoAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requerimientosRecursoIds]);

  // Efecto para sincronizar cantidades con recursos añadidos
  useEffect(() => {
    // Crear un objeto para almacenar las cantidades basadas en los recursos ya añadidos
    const cantidadesAgregadas: { [key: string]: number } = {};
    const recursosAgregados: { [key: string]: boolean } = {};

    // Procesar recursos no servicios
    recursosNoServicios.forEach(recurso => {
      cantidadesAgregadas[recurso.id] = recurso.cantidadSeleccionada;
      recursosAgregados[recurso.id] = true;
    });

    // Procesar recursos servicios
    recursosServicios.forEach(recurso => {
      cantidadesAgregadas[recurso.id] = recurso.cantidadSeleccionada;
      recursosAgregados[recurso.id] = true;
    });

    // Ahora también actualizamos los recursos que han sido eliminados
    // Para ello, verificamos qué recursos marcados como agregados ya no existen en Redux
    const currentAddedResources = { ...addedResources };
    let hasChanges = false;

    Object.keys(currentAddedResources).forEach(recursoId => {
      // Si un recurso estaba marcado como añadido pero ya no está en redux
      if (!recursosAgregados[recursoId]) {
        delete currentAddedResources[recursoId];
        // También eliminamos la cantidad del input
        cantidadesAgregadas[recursoId] = 0;
        hasChanges = true;
      }
    });

    // Actualizar el estado local 
    setCantidades(prevCantidades => {
      // Creamos un nuevo objeto para no mutar el estado anterior
      const newCantidades = { ...prevCantidades };

      // Actualizar cantidades existentes
      Object.keys(cantidadesAgregadas).forEach(id => {
        newCantidades[id] = cantidadesAgregadas[id];
      });

      // Eliminar cantidades para recursos que ya no existen
      Object.keys(newCantidades).forEach(id => {
        if (!recursosAgregados[id] && newCantidades[id] > 0) {
          newCantidades[id] = 0;
        }
      });

      return newCantidades;
    });

    // Actualizamos los recursos marcados como añadidos
    if (hasChanges || Object.keys(recursosAgregados).length > 0) {
      setAddedResources(recursosAgregados);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recursosNoServicios, recursosServicios]);

  // Función auxiliar para calcular la cantidad máxima disponible
  const cantidadMaxima = (cantidadPedida: number, cantidadCotizada: number): number => {
    return Math.max(0, cantidadPedida - cantidadCotizada);
  };

  // Nuevo efecto para calcular y actualizar el estado de cotización de cada solicitud
  useEffect(() => {
    if (!isSelectionLocked) {
      // Si no está bloqueada la selección, limpiamos los estados
      dispatch(clearSolicitudEstadosCotizacion());
      return;
    }

    // Combinamos todos los recursos (servicios y no servicios) que ya han sido añadidos
    const recursosAñadidos = [...recursosNoServicios, ...recursosServicios];

    // Si no hay recursos añadidos, no hay nada que evaluar
    if (recursosAñadidos.length === 0) return;

    // Agrupamos los recursos añadidos por solicitud_compra_id
    const recursosPorSolicitud: { [solicitudId: string]: SolicitudCompraRecurso[] } = {};
    recursosAñadidos.forEach(recurso => {
      if (!recursosPorSolicitud[recurso.solicitud_compra_id]) {
        recursosPorSolicitud[recurso.solicitud_compra_id] = [];
      }
      recursosPorSolicitud[recurso.solicitud_compra_id].push(recurso);
    });

    // Evaluamos cada solicitud
    Object.entries(recursosPorSolicitud).forEach(([solicitudId, recursosAñadidosEnSolicitud]) => {
      // Obtenemos todos los recursos disponibles para esta solicitud
      const todosLosRecursosDeLaSolicitud = recursos.filter(
        recurso => recurso.solicitud_compra_id === solicitudId && recurso.cantidad_pendiente > 0
      );
      
      // Si no hay recursos disponibles, no hay nada que evaluar
      if (todosLosRecursosDeLaSolicitud.length === 0) return;
      
      // Verificamos si todos los recursos disponibles están al máximo de su cantidad
      let todosMaximos = true;
      let algunoConCantidad = false;

      // Verificamos cada recurso disponible en la solicitud
      todosLosRecursosDeLaSolicitud.forEach(recurso => {
        const cantidadSeleccionada = cantidades[recurso.id] || 0;
        const maxCantidadDisponible = cantidadMaxima(recurso.cantidad_pendiente, 0);
        
        // Si hay al menos un recurso con cantidad seleccionada
        if (cantidadSeleccionada > 0) {
          algunoConCantidad = true;
        }
        
        // Si hay al menos un recurso que no está seleccionado al máximo o no está seleccionado en absoluto
        if (cantidadSeleccionada < maxCantidadDisponible) {
          todosMaximos = false;
        }
      });

      // Determinamos el estado
      let estado: EstadoCotizacion = null;
      
      // Si hay al menos un recurso con cantidad, determinamos si es parcial o completa
      if (algunoConCantidad) {
        estado = todosMaximos ? 'COTIZACION_COMPLETA' : 'COTIZACION_PARCIAL';
      }
      
      // Si hay recursos añadidos pero no todos los recursos disponibles están al máximo, es parcial
      if (recursosAñadidosEnSolicitud.length > 0 && recursosAñadidosEnSolicitud.length < todosLosRecursosDeLaSolicitud.length) {
        estado = 'COTIZACION_PARCIAL';
      }

      // Actualizamos el estado en Redux
      dispatch(updateSolicitudEstadoCotizacion({
        solicitudId,
        estado
      }));
    });
  }, [cantidades, recursosNoServicios, recursosServicios, isSelectionLocked, dispatch, recursos]);


  // Manejar cambios en los inputs de cantidad
  const handleCantidadChange = (recurso: SolicitudCompraRecurso, cantidad: number) => {
    const recursoId = recurso.id;
    const esServicio = recurso.recurso_id.tipo_recurso_nombre === "Servicio";

    // Actualizar estado local para mantener la UI sincronizada
    setCantidades({
      ...cantidades,
      [recursoId]: cantidad
    });

    // Verificar obra para los recursos
    if (!solicitud?.requerimiento_id?.obra_id) {
      showModal('Error', 'No se puede determinar la obra para los recursos', 'red');
      return;
    }

    // Establecer la obra si aún no está establecida
    if (!obraFilter && solicitud?.requerimiento_id?.obra_id) {
      dispatch(setObraFilter(solicitud.requerimiento_id.obra_id));
    }

    try {
      // Si la cantidad es cero, eliminar el recurso si existe
      if (cantidad <= 0) {
        if (addedResources[recursoId]) {
          // Eliminar el recurso según su tipo
          if (esServicio) {
            dispatch(removeRecursoServicio(recursoId));
          } else {
            dispatch(removeRecursoNoServicio(recursoId));
          }

          // Actualizar el estado local para reflejar que el recurso fue eliminado
          const newAddedResources = { ...addedResources };
          delete newAddedResources[recursoId];
          setAddedResources(newAddedResources);
        }
        return;
      }

      // Si la cantidad es mayor que cero, añadir o actualizar
      if (addedResources[recursoId]) {
        // Si ya existe, actualizamos la cantidad
        if (esServicio) {
          dispatch(updateRecursoServicio({
            id: recursoId,
            cantidad: cantidad
          }));
        } else {
          dispatch(updateRecursoNoServicio({
            id: recursoId,
            cantidad: cantidad
          }));
        }
      } else {
        // Si no existe, lo añadimos nuevo
        if (selectedSolicitudId) {
          dispatch(addRecurso({
            recurso,
            cantidad: cantidad,
            solicitudId: selectedSolicitudId
          }));

          // Marcar como añadido
          setAddedResources({
            ...addedResources,
            [recursoId]: true
          });
        }
      }
    } catch (error) {
      console.error("Error al añadir/actualizar recurso:", error);
      showModal('Error', 'Ocurrió un error al añadir/actualizar el recurso', 'red');
    }
  };

  // Mostrar modal
  const showModal = (title: string, message: string, variant: 'green' | 'yellow' | 'red' | 'blue' | 'white' | 'black' = 'blue', confirmAction: () => void = () => { }) => {
    setModalTitle(title);
    setModalMessage(message);
    setModalVariant(variant);
    setModalConfirmAction(() => confirmAction);
    setModalOpen(true);
  };

  // Cerrar modal
  const closeModal = () => {
    setModalOpen(false);
  };

  // Función para bloquear/desbloquear la selección de recursos
  const handleBlockSelection = () => {
    // Si la solicitud tiene un estado final y no está bloqueada, mostrar mensaje y no hacer nada
    if (solicitudTieneEstadoFinal && !isSelectionLocked) {
      showModal(
        "No se pueden añadir recursos",
        "No es posible añadir recursos de solicitudes con estados finales (Cotización completa, Fin atención parcial, Fin completo o Abortada).",
        "yellow"
      );
      return;
    }

    if (isSelectionLocked) {
      showModal('Cancela Y elimina recursos a cotizar', '¿Desea cancelar la selección de recursos? Se borraran todos los recursos añadidos a la cotización.', 'red', handleUnlockSelection);
    } else {
      showModal('Añadir Recursos para Cotizaciones', 'Se bloqueara el panel izquierdo e iniciara la creación de la cotización y sus recursos.', 'blue', () => dispatch(lockSelection()));
    }
  };

  // Modificar la función de desbloqueo para resetear también los recursos añadidos
  const handleUnlockSelection = () => {
    dispatch(unlockSelection());
    dispatch(removeAllRecursos());
    dispatch(clearSolicitudEstadosCotizacion()); // Limpiamos los estados de cotización
    setAddedResources({});
    setCantidades({});
  };

  // Componente Skeleton para estados de carga
  const Skeleton: React.FC<{ className?: string }> = ({ className }) => (
    <div className={`animate-pulse bg-gray-200 rounded-xl ${className}`}></div>
  );

  // Ordenar recursos según el orden de selectedSolicitudes
  const ordenedResources = useMemo(() => {
    if (!selectedSolicitudes || selectedSolicitudes.length === 0 || recursos.length === 0) {
      return recursos;
    }

    // Crear un mapa de índice para el orden de las solicitudes
    const solicitudesOrderMap = new Map<string, number>();
    selectedSolicitudes.forEach((id, index) => {
      solicitudesOrderMap.set(id, index);
    });

    // Ordenar los recursos por el orden de sus solicitudes correspondientes
    return [...recursos].sort((a, b) => {
      const orderA = solicitudesOrderMap.get(a.solicitud_compra_id) ?? Number.MAX_SAFE_INTEGER;
      const orderB = solicitudesOrderMap.get(b.solicitud_compra_id) ?? Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    });
  }, [recursos, selectedSolicitudes]);



  // Función para obtener el estado de cotización de una solicitud
  const getEstadoCotizacion = (solicitudId: string) => {
    return solicitudEstadosCotizacion.find(item => item.solicitudId === solicitudId)?.estado || null;
  };

  // Función para mostrar un indicador visual del estado de cotización
  const renderEstadoIndicador = (solicitudId: string) => {
    const estado = getEstadoCotizacion(solicitudId);

    if (!estado) return null;

    let bgColor = '';
    let text = '';

    switch (estado) {
      case 'COTIZACION_COMPLETA':
        bgColor = 'bg-green-500';
        text = 'Completa';
        break;
      case 'COTIZACION_PARCIAL':
        bgColor = 'bg-yellow-500';
        text = 'Parcial';
        break;
      default:
        return null;
    }

    return (
      <span className={`${bgColor} text-white text-[8px] px-1 py-0.5 rounded-sm ml-2`}>
        {text}
      </span>
    );
  };

  return (
    <div className="h-full rounded-lg shadow-lg overflow-hidden transition-all duration-300 flex flex-col border border-gray-100">
      {/* Modal para alertas */}
      <ModalAlert
        isOpen={modalOpen}
        title={modalTitle}
        message={modalMessage}
        variant={modalVariant}
        onConfirm={() => {
          modalConfirmAction();
          closeModal();
        }}
        onCancel={closeModal}
      />

      <div className="p-3 border-b border-gray-100 flex items-center justify-between bg-white">
        <h2 className="text-lg font-semibold text-gray-700 flex items-center">
          Recursos de la Solicitud
          {selectedSolicitudId && renderEstadoIndicador(selectedSolicitudId)}
        </h2>

        {/* Información de la solicitud */}
        <h3 className="font-medium text-[12px] text-blue-800">{selectedSolicitudes.length} Solicitudes - {recursos.length} Recursos - {recursos.map(recurso => recurso.cantidad).reduce((a, b) => a + b, 0)} Elementos a Cotizar</h3>

        <div className="flex items-center gap-2">
            <button
            className={`flex items-center space-x-2 px-3 py-1.5 rounded-md text-white shadow-md transition-all duration-300 bg-gradient-to-r from-green-500 to-green-900 hover:from-green-600 hover:to-green-800`}
            onClick={() => dispatch(fetchRequerimientosRecursoAll())}
            title={"Actualiza las cantidades de seguimiento de recursos - Cotizacion - OC - Ingreso"}
            disabled={loadingRequerimientoRecursosAll}
            >
            <FiRefreshCw className={`h-4 w-4 ${loadingRequerimientoRecursosAll ? 'animate-spin' : ''}`} />            
            </button>
        </div>
        <div className="flex items-center gap-2">
          <button
            className={`flex items-center space-x-2 px-3 py-1.5 rounded-md text-white shadow-md transition-all duration-300 ${isSelectionLocked
              ? "bg-gradient-to-r from-red-600 to-red-900 hover:from-red-700 hover:to-red-800"
              : solicitudTieneEstadoFinal
                ? "bg-gradient-to-r from-gray-400 to-gray-500 cursor-not-allowed opacity-70"
                : "bg-gradient-to-r from-blue-500 to-blue-900 hover:from-blue-600 hover:to-blue-800"
              }`}
            onClick={() => {
              if (solicitudTieneEstadoFinal && !isSelectionLocked) {
                showModal(
                  "No se pueden añadir recursos",
                  "No es posible añadir recursos de solicitudes con estados finales (Cotización completa, Fin atención parcial, Fin completo o Abortada).",
                  "yellow"
                );
                return;
              }
              handleBlockSelection();
            }}
            disabled={solicitudTieneEstadoFinal && !isSelectionLocked}
            title={
              solicitudTieneEstadoFinal && !isSelectionLocked
                ? "No se pueden añadir recursos de solicitudes con estados finales"
                : isSelectionLocked
                  ? "Cancelar adición de recursos"
                  : "Iniciar adición de recursos"
            }
          >
            <span className="text-xs font-medium">
              {isSelectionLocked
                ? "Cancelar adición"
                : "Iniciar adición"
              }
            </span>

            {isSelectionLocked
              ? <FiX className="h-4 w-4" />
              : <FiPlus className='h-4 w-4' />
            }
          </button>
        </div>
      </div>

      {/* Mensaje de advertencia para solicitudes con estados finales */}
      {solicitudTieneEstadoFinal && !isSelectionLocked && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-2 mb-2">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                No se pueden añadir recursos de solicitudes con estados finales (Cotización completa, Fin atención parcial, Fin completo o Abortada).
              </p>
            </div>
          </div>
        </div>
      )}

      {!selectedSolicitudId ? (
        <div className="flex justify-center items-center h-full bg-gray-50">
          <div className="text-center text-gray-400">
            <svg
              className="mx-auto h-10 w-10 text-gray-300"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
            <h3 className="mt-2 text-[12px] font-medium text-gray-600">No hay solicitud seleccionada</h3>
            <p className="mt-1 text-[10px] text-gray-400">
              Selecciona una solicitud para ver sus recursos
            </p>
          </div>
        </div>
      ) : (loadingSolicitud) ? (
        <>
          {/* Skeleton para la información de solicitud */}
          <div className="p-3 bg-white border-b rounded-xl border-gray-100">
            <Skeleton className="h-4 w-40 mb-2" />
            <div className="space-y-1">
              <Skeleton className="h-3 w-60" />
              <Skeleton className="h-3 w-24" />
            </div>
          </div>

          {/* Skeleton para la tabla de recursos */}
        </>
      ) : error ? (
        <div className="text-red-500 text-center p-4 bg-red-50 h-full flex items-center justify-center">
          <div>Error al cargar recursos: {error}</div>
        </div>
      ) : (
        <>
          {/* Lista de recursos en tabla */}
          <div className="flex-1 overflow-auto bg-white">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className=" sticky rounded-t-2xl top-0">
                <tr className="">
                  <th className="px-1 py-1 text-center text-[10px] font-medium bg-gray-100 rounded-ss-xl text-gray-500 uppercase tracking-wider">Imagen</th>
                  <th className="px-1 py-1 text-left text-[10px] font-medium bg-gray-100 text-gray-500 uppercase tracking-wider">Código</th>
                  <th className="px-1 py-1 text-left text-[10px] font-medium bg-gray-100 text-gray-500 uppercase tracking-wider">Nombre</th>
                  <th className="px-1 py-1 text-left text-[10px] font-medium bg-gray-100 text-gray-500 uppercase tracking-wider">Tipo</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium text-orange-500 bg-orange-100 rounded-ss-xl uppercase tracking-wider">RQ.</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium text-orange-500 bg-orange-100 uppercase tracking-wider">Alm.</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium text-yellow-500 bg-yellow-100   uppercase tracking-wider">Cotizacion</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium text-yellow-500 bg-yellow-100   uppercase tracking-wider">Cot. Proveedor</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium text-yellow-500 bg-yellow-100 uppercase tracking-wider">OC</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium text-yellow-500 bg-yellow-100 uppercase tracking-wider">Ingreso</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium text-green-500 bg-green-100 rounded-se-xl uppercase tracking-wider">Cant.Solicitada</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium bg-gray-100 text-gray-500 uppercase tracking-wider">
                    {isSelectionLocked ? "Cant.aCotizar" : ""}
                  </th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium bg-gray-100  text-gray-500 uppercase tracking-wider">Precio</th>
                  <th className="px-1 py-1 text-center text-[10px] font-medium bg-gray-100 rounded-se-xl text-gray-500 uppercase tracking-wider">
                    {isSelectionLocked ? "SubTotal" : ""}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white rounded-3xl divide-y divide-gray-100">
                {ordenedResources.length > 0 ? (
                  ordenedResources.map(recurso => {
                    // Obtener color de fondo según la solicitud
                    const colorClass = getResourceColorClass(recurso.solicitud_compra_id);
                    // Verificar si el recurso está añadido para mostrar estado visual
                    const isAdded = addedResources[recurso.id];
                    // Estado de cotización de la solicitud
                    const estadoCotizacion = getEstadoCotizacion(recurso.solicitud_compra_id);

                    return (
                      <tr
                        key={recurso.id}
                        className={`hover:opacity-75 transition-colors duration-150 ${colorClass} ${isAdded ? 'ring-1 ring-inset ring-green-500' : ''} ${estadoCotizacion === 'COTIZACION_COMPLETA' ? 'bg-opacity-20 bg-green-100' :
                          estadoCotizacion === 'COTIZACION_PARCIAL' ? 'bg-opacity-20 bg-yellow-100' : ''
                          }`}
                      >
                        {/*Imagen*/}
                        <td className="px-1 py-1">
                          {recurso.recurso_id?.imagenes && recurso.recurso_id.imagenes.length > 0
                            ? (
                              <div className="h-5 w-12 bg-gray-100 rounded-md flex items-center justify-center">
                                <IMG
                                  src={recurso.recurso_id.imagenes[0].file}
                                  alt={recurso.recurso_id.nombre}
                                  className="h-5 w-12 object-cover rounded-md border border-gray-200"
                                />
                              </div>
                            )
                            : (
                              <div className="h-5 w-12 bg-gray-100 rounded-md flex items-center justify-center">
                                <IMG
                                  src={noImage}
                                  alt="No image available"
                                  className="h-5 w-12 object-cover rounded-md border border-gray-200"
                                />
                              </div>
                            )}
                        </td>
                        {/*Codigo*/}
                        <td className="px-1 py-1 text-[10px] text-gray-600">{recurso.recurso_id?.codigo || 'Sin código'}</td>
                        {/*Nombre*/}
                        <td className="px-1 py-1 text-[10px] text-gray-600">
                          {recurso.recurso_id?.nombre || 'Sin nombre'}
                          {estadoCotizacion && (
                            <span className={`inline-block ml-1 w-2 h-2 rounded-full ${estadoCotizacion === 'COTIZACION_COMPLETA' ? 'bg-green-500' :
                              estadoCotizacion === 'COTIZACION_PARCIAL' ? 'bg-yellow-500' : 'bg-gray-300'
                              }`}></span>
                          )}
                        </td>
                        {/*Tipo*/}
                        <td className="px-1 py-1 text-[10px] text-gray-600">{recurso.recurso_id?.tipo_recurso_nombre || 'Sin tipo'}</td>
                        {/*RQ*/}
                        <td className="px-1 py-1 text-center text-[10px] text-gray-600">
                          {data[recurso.requerimiento_recurso_id] &&
                            data[recurso.requerimiento_recurso_id].requerimiento ?
                            data[recurso.requerimiento_recurso_id].requerimiento.cantidad ?? 0 : 0}
                        </td>
                        {/*Almacen*/}
                        <td className="px-1 py-1 text-center text-[10px] text-gray-600">
                          {(() => {
                            // Verificamos cada nivel de forma segura
                            if (!data) return 0;
                            if (!recurso.requerimiento_recurso_id) return 0;

                            const dataItem = data[recurso.requerimiento_recurso_id];
                            if (!dataItem) return 0;

                            const solicitudAlmacen = dataItem.solicitudAlmacen;
                            if (!solicitudAlmacen || solicitudAlmacen.length === 0) return 0;

                            // Si llegamos aquí, tenemos solicitudAlmacen para mostrar
                            return solicitudAlmacen.map((cot, index) => (
                              <span key={index || `${cot.padreEstado || 'unknown'}-${cot.cantidad || 0}-${index}`}>
                                {cot.padreEstado || 'N/A'}: {cot.cantidad || 0}
                                {index < solicitudAlmacen.length - 1 && ", "}
                              </span>
                            ));
                          })()}
                        </td>
                        {/*Cotizacion*/}
                        <td className="px-1 py-1 text-center text-[10px] text-gray-600">
                          {(() => {
                            // Verificamos cada nivel de forma segura
                            if (!data) return 0;
                            if (!recurso.requerimiento_recurso_id) return 0;

                            const dataItem = data[recurso.requerimiento_recurso_id];
                            if (!dataItem) return 0;

                            const cotizaciones = dataItem.cotizacion;
                            if (!cotizaciones || cotizaciones.length === 0) return 0;

                            // Si llegamos aquí, tenemos cotizaciones para mostrar
                            return cotizaciones.map((cot, index) => (
                              <span key={index || `${cot.padreEstado || 'unknown'}-${cot.cantidad || 0}-${index}`}>
                                {cot.padreEstado || 'N/A'}: {cot.cantidad || 0}
                                {index < cotizaciones.length - 1 && ", "}
                              </span>
                            ));
                          })()}
                        </td>
                        {/*Cotizacion Proveedor*/}
                        <td className="px-1 py-1 text-center text-[10px] text-gray-600">
                          {(() => {
                            // Verificamos cada nivel de forma segura
                            if (!data) return 0;
                            if (!recurso.requerimiento_recurso_id) return 0;

                            const dataItem = data[recurso.requerimiento_recurso_id];
                            if (!dataItem) return 0;

                            const cotizacion_proveedor = dataItem.cotizacion_proveedor;
                            if (!cotizacion_proveedor || cotizacion_proveedor.length === 0) return 0;

                            // Si llegamos aquí, tenemos cotizacion_proveedor para mostrar
                            return cotizacion_proveedor.map((cot, index) => (
                              <span key={index || `${cot.padreEstado || 'unknown'}-${cot.cantidad || 0}-${index}`}>
                                {cot.padreEstado || 'N/A'}: {cot.cantidad || 0}
                                {index < cotizacion_proveedor.length - 1 && ", "}
                              </span>
                            ));
                          })()}
                        </td>
                        {/*OC*/}
                        <td className="px-1 py-1 text-center text-[10px] text-gray-600">
                          {(() => {
                            // Verificamos cada nivel de forma segura
                            if (!data) return 0;
                            if (!recurso.requerimiento_recurso_id) return 0;

                            const dataItem = data[recurso.requerimiento_recurso_id];
                            if (!dataItem) return 0;

                            const orden_compra = dataItem.orden_compra;
                            if (!orden_compra || orden_compra.length === 0) return 0;

                            // Si llegamos aquí, tenemos orden_compra para mostrar
                            return orden_compra.map((cot, index) => (
                              <span key={index || `${cot.padreEstado || 'unknown'}-${cot.cantidad || 0}-${index}`}>
                                {cot.padreEstado || 'N/A'}: {cot.cantidad || 0}
                                {index < orden_compra.length - 1 && ", "}
                              </span>
                            ));
                          })()}
                        </td>
                        {/*INGRESO*/}
                        <td className="px-1 py-1 text-center text-[10px] text-gray-600">
                          {(() => {
                            // Verificamos cada nivel de forma segura
                            if (!data) return 0;
                            if (!recurso.requerimiento_recurso_id) return 0;

                            const dataItem = data[recurso.requerimiento_recurso_id];
                            if (!dataItem) return 0;

                            const transferencia = dataItem.transferencia;
                            if (!transferencia || transferencia.length === 0) return 0;

                            // Si llegamos aquí, tenemos cotizaciones para mostrar
                            return transferencia.map((cot, index) => (
                              <span key={index || `${cot.padreEstado || 'unknown'}-${cot.cantidad || 0}-${index}`}>
                                {cot.padreEstado || 'N/A'}: {cot.cantidad || 0}
                                {index < transferencia.length - 1 && ", "}
                              </span>
                            ));
                          })()}
                        </td>
                        {/*CantidadSolicitada*/}
                        <td className="px-1 py-1 text-[10px] text-center text-gray-600">{recurso.cantidad}</td>
                        {/*INGRESAR CANTIDAD INPUT*/}
                        <td className="px-1 py-1 text-[10px] text-center text-gray-600">
                          {isSelectionLocked && (
                            <div className="flex items-center justify-center">
                              <input
                                type="number"
                                min={0}
                                max={cantidadMaxima(recurso.cantidad_pendiente, 0)}
                                step="0.5"
                                value={cantidades[recurso.id] || ''}
                                onChange={(e) => handleCantidadChange(
                                  recurso,
                                  parseFloat(e.target.value) || 0
                                )}
                                className={`w-16 p-1 text-center border ${isAdded ? (
                                  cantidades[recurso.id] >= cantidadMaxima(recurso.cantidad_pendiente, 0) ?
                                    'border-green-500 bg-green-50' : 'border-yellow-500 bg-yellow-50'
                                ) : 'border-gray-300'
                                  } rounded focus:outline-none focus:ring-1 focus:ring-blue-500`}
                                placeholder="0"
                              />
                            </div>
                          )}
                        </td>
                        {/*Precio*/}
                        <td className="px-1 py-1 text-[10px] text-center pr-2 text-gray-600">
                          {formatCurrency(recurso.recurso_id?.precio_actual || 0)}
                        </td>
                        <td className="px-1 py-1 text-center">
                          {isSelectionLocked && cantidades[recurso.id] && cantidades[recurso.id] > 0 && (
                            <div className="flex items-center text-[10px] justify-center">
                              {formatCurrency(cantidades[recurso.id] * (recurso.recurso_id?.precio_actual || 0))}
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={13} className="px-1 py-4 text-center text-gray-500 bg-gray-50">
                      No hay recursos en esta solicitud
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default RecursosSolicitudList;
