import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store/store';
import { fetchProyectos, Proyecto } from '../../../slices/proyectoSlice';
import { getPresupuestosByProyecto, Presupuesto } from '../../../slices/presupuestoSlice';
import { FiChevronDown, FiChevronRight, FiFileText, FiFolder } from 'react-icons/fi';
import { setActiveProyecto, setActivePresupuesto } from '../../../slices/activeDataSlice';
import { formatCurrency } from '../../../components/Utils/priceFormatUtils';

interface TreeItemProps {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  isProject?: boolean;
  isActive?: boolean;
  children?: React.ReactNode;
  estado?: string;
  proyecto_total?: number;
  loading?: boolean;
}

const TreeItem: React.FC<TreeItemProps> = ({
  title,
  isExpanded,
  onToggle,
  isProject = false,
  isActive = false,
  children,
  estado,
  proyecto_total,
  loading
}) => {
  return (
    <div className="select-none">
      <div
        className={`flex items-center gap-1 px-0 ${isProject ? "pb-6" : "pb-2 mt-2 ml-2"} py-2 cursor-pointer rounded-md transition-all duration-200 relative
          ${isActive
            ? 'bg-gradient-to-r from-indigo-600/20 to-cyan-600/20 text-cyan-100 shadow-sm'
            : 'hover:bg-gray-800/30'}`}
        onClick={onToggle}
      >
        {isProject && <span className="w-4 h-4 flex items-center">
          {isExpanded ? (
            <FiChevronDown className="w-4 h-4 min-w-4 text-cyan-300" />
          ) : (
            <FiChevronRight className="w-4 h-4 min-w-4 text-cyan-300" />
          )}
        </span>}
        {isProject ? (
          <FiFolder className={`w-4 h-4 min-w-4 flex-shrink-0 ${isActive ? 'text-cyan-300' : 'text-blue-400'}`} />
        ) : (
          <FiFileText className="w-4 h-4 min-w-4 text-emerald-400" />
        )}
        <span className={`${isProject ? "text-xs" : "text-[10px]"} text-slate-200 font-medium`}>{title}</span>
        {estado && (
          <span
            className={`absolute w-56 justify-between rounded-lg bottom-1 right-1 inline-flex items-center px-2 py-0.5 text-[10px] font-medium tracking-wide ${{
              'BORRADOR': 'bg-blue-500/10 text-blue-300/70',
              'EN REVISIÓN': 'bg-yellow-500/10 text-yellow-300/70',
              'APROBADO': 'bg-green-500/10 text-green-300/70',
              'EN CURSO': 'bg-cyan-500/10 text-cyan-300/70',
              'SUSPENDIDO': 'bg-orange-500/10 text-orange-300/70',
              'CANCELADO': 'bg-red-500/10 text-red-300/70',
              'COMPLETADO': 'bg-purple-500/10 text-purple-300/70',
              'CERRADO': 'bg-gray-500/10 text-gray-300/70'
            }[estado] || 'bg-gray-500/10 text-gray-300/70'
              }`}
          >
            {loading ? (
              <svg className="animate-spin h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) :
              <span>
                {proyecto_total ? `Total:  S/. ${formatCurrency(proyecto_total)}` : `Total:  S/. ${formatCurrency(0)}`}
              </span>
            }
            <span className=' inline-flex items-center gap-1'>
              <div className={`w-1.5 h-1.5 rounded-full mr-1.5 ${{
                'BORRADOR': 'bg-blue-400',
                'EN REVISIÓN': 'bg-yellow-400',
                'APROBADO': 'bg-green-400',
                'EN CURSO': 'bg-cyan-400',
                'SUSPENDIDO': 'bg-orange-400',
                'CANCELADO': 'bg-red-400',
                'COMPLETADO': 'bg-purple-400',
                'CERRADO': 'bg-gray-400'
              }[estado] || 'bg-gray-400'
                }`} />
              {estado}
            </span>
          </span>
        )}
      </div>
      {isExpanded && (
        <div className="ml-6 border-l border-gray-700/50">
          {children}
        </div>
      )}

    </div>
  );
};

const ListaProyectos: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { proyectos, loading } = useSelector((state: RootState) => state.proyecto);
  const presupuestos = useSelector((state: RootState) => state.presupuesto.presupuestos);
  const activeProyecto = useSelector((state: RootState) => state.activeData.activeProyecto);
  const activePresupuesto = useSelector((state: RootState) => state.activeData.activePresupuesto);
  const [expandedProjects, setExpandedProjects] = useState<Set<string>>(new Set());

  useEffect(() => {
    dispatch(fetchProyectos());
  }, [dispatch]);

  const handleToggleProject = async (project: Proyecto) => {
    // Actualizar el proyecto activo
    dispatch(setActiveProyecto(project));

    // Manejar la expansión/colapso
    const projectId = project.id_proyecto;
    if (!expandedProjects.has(projectId)) {
      await dispatch(getPresupuestosByProyecto(projectId));
    }

    const newExpanded = new Set(expandedProjects);
    if (expandedProjects.has(projectId)) {
      newExpanded.delete(projectId);
    } else {
      newExpanded.add(projectId);
    }
    setExpandedProjects(newExpanded);
  };

  const handlePresupuestoClick = (presupuesto: Presupuesto) => {

    // Luego buscamos y seteamos el proyecto correspondiente
    const proyectoCorrespondiente = proyectos.find(
      proyecto => proyecto.id_proyecto === presupuesto.id_proyecto
    );

    if (proyectoCorrespondiente) {
      dispatch(setActiveProyecto(proyectoCorrespondiente));

      // Nos aseguramos que el árbol esté expandido
      const newExpanded = new Set(expandedProjects);
      newExpanded.add(proyectoCorrespondiente.id_proyecto);
      setExpandedProjects(newExpanded);
    }
    dispatch(setActivePresupuesto(presupuesto));
  };

  const getProjectPresupuestos = (projectId: string) => {
    return presupuestos.filter(p => p.id_proyecto === projectId);
  };

  return (
    <div className="w-full max-w-3xl bg-gradient-to-br from-gray-900/95 to-gray-800/95 rounded-xl shadow-xl border border-gray-700/50 backdrop-blur-sm">
      <div className="p-2">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-sm font-semibold text-gray-100">Lista de Proyectos</h2>
        </div>
        <div className="space-y-2">
          {proyectos.map(proyecto => (
            <TreeItem
              key={proyecto.id_proyecto}
              title={proyecto.nombre_proyecto}
              isExpanded={expandedProjects.has(proyecto.id_proyecto)}
              onToggle={() => handleToggleProject(proyecto)}
              isProject={true}
              isActive={activeProyecto?.id_proyecto === proyecto.id_proyecto}
              estado={proyecto.estado}
              proyecto_total={proyecto.total_proyecto}
            >
              {getProjectPresupuestos(proyecto.id_proyecto).map(presupuesto => (
                <TreeItem
                  key={presupuesto.id_presupuesto}
                  title={`${(presupuesto.numeracion_presupuesto ?? 0).toString().padStart(2, '0')}. ${presupuesto.nombre_presupuesto || 'Sin descripción'}`}
                  isExpanded={false}
                  onToggle={() => handlePresupuestoClick(presupuesto)}
                  isActive={activePresupuesto?.id_presupuesto === presupuesto.id_presupuesto}
                  loading={loading}
                />
              ))}
            </TreeItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListaProyectos;