import React, { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FiPlusCircle, FiTrash2, FiEye } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Buttons/Button';
import Modal from '../../components/Modal/Modal';
import ComparacionTable from './ComparacionTable';
import BuscarProveedoresModal from './BuscarProveedoresModal';
import { AppDispatch, RootState } from '../../store/store';
import { fetchCotizacionProveedoresByCotizacionId, updateCotizacionProveedor } from '../../slices/cotizacionProveedorSlice';
import { updateCotizacion } from '../../slices/cotizacionSlice';
import { addOrdenCompra } from '../../slices/ordenCompraSlice';
import { addOrdenCompraRecurso } from '../../slices/ordenCompraRecursosSlice';
import { fetchCotizacionesByProveedor } from '../../slices/cotizacionProveedoresRecursoSlice';
import GeneracionOCLoader from '../../components/Loaders/GeneracionOCLoader';
import { formatFullTime } from '../../components/Utils/dateUtils';
import { getComentariosByReferenciaId, addComentario } from '../../slices/comentarioSlice';
import { fetchFilesByReferencia, deleteFile } from '../../slices/archivoSustentoSlice';
import LoaderOverlay from '../../components/Loader/LoaderOverlay';
import FileUpload from '../../components/FileUpload/FileUpload';


//Todo Ok

export interface CompararProveedoresProps {
  onClose: () => void;
  recursos: RecursoItem[];
  cotizacion: {
    id?: string;
    codigo_cotizacion?: string;
    estado?: string;
    fecha?: string;
    usuario_id?: {
      nombres: string;
      apellidos: string;
      id: string;
    };
  };
  tipoOrden: 'compra' | 'servicio';  // Add this prop
}

export interface RecursoItem {
  id: string;
  cantidad: number;
  atencion: string;
  costo: number;
  total: number;
  requerimiento_recurso_id: string;
  precio: number;
  precio_partida: number;
  cotizacion_id: {
    codigo_cotizacion: string;
    aprobacion: boolean;
  };
  recurso_id: {
    id: string;
    codigo: string;
    nombre: string;
    descripcion: string;
    fecha: string;
    cantidad: number;
    precio_actual: number;
    vigente: boolean;
    unidad_id: string;
    imagenes: Array<{ file: string }>;
  };
}

export interface ProveedorCotizacion {
  id: string;
  nombre: string;
  estado: string;
  fechaInicio: string;
  fechaFin: string;
  entrega: string;
  items: {
    cantidad: number;
    precio: number;
    subTotal: number;
  }[];
  total: number;
  notas: string;
  divisa_id ?: Divisa;
  monto_divisa ?: number;
}

interface Divisa {
  id: string;
  nombre: string;
  abreviatura: string;
  simbolo: string;
  region: string;
}

const CompararProveedores: React.FC<CompararProveedoresProps> = ({ cotizacion, recursos, tipoOrden }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showProveedorModal, setShowProveedorModal] = useState(false);
  const [showGenerarOCModal, setShowGenerarOCModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showUploadPdfModal, setShowUploadPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [showRechazarOCModal, setShowRechazarOCModal] = useState(false);
  const [motivoRechazo, setMotivoRechazo] = useState('');
  const [isLoadingEnEvaluacion, setIsLoadingEnEvaluacion] = useState(false);
  const [isLoadingRechazar, setIsLoadingRechazar] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const userId = cotizacion.usuario_id?.id || '';
  const cotizacionProveedores = useSelector((state: RootState) =>
    state.cotizacionProveedor.cotizacionProveedores
  );
  const cotizacionProveedoresRecursos = useSelector((state: RootState) => state.cotizacionProveedoresRecurso.cotizacionProveedoresRecursos);
  const UserId = useSelector((state: RootState) => state.user.id);
  const {usuariosCargo} = useSelector((state: RootState) => state.usuario);
  const comentarios = useSelector((state: RootState) => state.comentario.comentariosByReferencia);
  const usuarioHabilitado = usuariosCargo.find(ucargo => ucargo.id === UserId)?.cargo_id.gerarquia || 1
  const [hasPdf, setHasPdf] = useState(false);
  const archivos = useSelector((state: RootState) => state.archivoSustento.archivos);

  const proveedorAdjudicado = useMemo(() => {
    return cotizacionProveedores.find(proveedor => proveedor.estado === 'buenaProAdjudicada');
  }, [cotizacionProveedores]);

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoadingInitial(true);
      try {
        if (cotizacion.id) {
          await Promise.all([
            dispatch(fetchCotizacionProveedoresByCotizacionId(cotizacion.id)),
            dispatch(getComentariosByReferenciaId(cotizacion.id)),
            dispatch(fetchFilesByReferencia({ referencia_id: cotizacion.id, tipo: 'cotizacion' }))
          ]);
        }
        if (proveedorAdjudicado) {
          await dispatch(fetchCotizacionesByProveedor(proveedorAdjudicado.id));
        }
      } catch (error) {
        console.error('Error al cargar datos iniciales:', error);
      } finally {
        setIsLoadingInitial(false);
      }
    };

    fetchInitialData();
  }, [dispatch, proveedorAdjudicado, cotizacion.id]);

  useEffect(() => {
    if (archivos.length > 0) {
      console.log('Archivos en el estado:', archivos);
      const pdfFile = archivos.find(archivo => 
        archivo.referencia_id === cotizacion.id && 
        archivo.tipo === 'cotizacion'
      );
      console.log('PDF encontrado:', pdfFile);
      if (pdfFile) {
        console.log('Actualizando estados con PDF:', pdfFile.url);
        setPdfUrl(pdfFile.url);
        setHasPdf(true);
      } else {
        console.log('No se encontró PDF, reseteando estados');
        setPdfUrl(null);
        setHasPdf(false);
        setShowPdfModal(false);
        setShowUploadPdfModal(false);
      }
    } else {
      console.log('No hay archivos, reseteando estados');
      setPdfUrl(null);
      setHasPdf(false);
      setShowPdfModal(false);
      setShowUploadPdfModal(false);
    }
  }, [archivos, cotizacion.id]);

  useEffect(() => {
    const fetchAndUpdateProveedores = async () => {
      if (cotizacion.id) {
        await dispatch(fetchCotizacionProveedoresByCotizacionId(cotizacion.id));

        // Verifica si el estado de la cotización es "enEvaluacion"
        if (cotizacion.estado === "enEvaluacion" || cotizacion.estado === "rechazada") {
          // Actualiza el estado de todos los proveedores
          const updatePromises = cotizacionProveedores.map(proveedor =>
            dispatch(updateCotizacionProveedor({
              id: proveedor.id,
              estado: "enEvaluacion"
            }))
          );

          try {
            await Promise.all(updatePromises);
          } catch (error) {
            console.error('Error al actualizar el estado de los proveedores:', error);
          }
        }
      }
    };

    fetchAndUpdateProveedores();
  }, [dispatch, cotizacion.id, cotizacion.estado]);

  // Transformar los datos de la API al formato necesario para la tabla
  const proveedores = useMemo(() => {
    return cotizacionProveedores.map(cp => ({
        id: cp.id,
        nombre: cp.proveedor_id.nombre_comercial,
        estado: cp.estado,
        fechaInicio: cp.fecha_inicio,
        fechaFin: cp.fecha_fin,
        entrega: cp.entrega,
        divisa_id: cp.divisa_id ? {
            id: cp.divisa_id.id,
            nombre: cp.divisa_id.nombre,
            abreviatura: cp.divisa_id.abreviatura,
            simbolo: cp.divisa_id.simbolo,
            region: cp.divisa_id.region
        } : undefined,
        monto_divisa: cp.monto_divisa, // Asegurarse de incluir esta propiedad
        items: recursos.map(recurso => ({
            cantidad: recurso.cantidad,
            precio: recurso.recurso_id.precio_actual,
            subTotal: recurso.cantidad * recurso.recurso_id.precio_actual
        })),
        total: recursos.reduce((acc, recurso) =>
            acc + (recurso.cantidad * recurso.recurso_id.precio_actual), 0),
        notas: cp.observaciones || ''
    }));
}, [cotizacionProveedores, recursos]);

  const mejorProveedor = useMemo(() => {
    if (proveedores.length === 0) return null;
    return proveedores.reduce((mejor, actual) =>
      actual.total < mejor.total ? actual : mejor
    );
  }, [proveedores]);

  const handleEnEvaluacion = async () => {
    if (!cotizacion.id) return;

    const todosProveedoresListos = cotizacionProveedores.every(
      proveedor => proveedor.estado === "proformaRecibida" || proveedor.estado === "enEvaluacion"
    );

    if (!todosProveedoresListos) {
      alert('Todos los proveedores deben tener el estado "proformaRecibida", ingresa los precios del Proveedor por favor');
      return;
    }

    setIsLoadingEnEvaluacion(true);
    try {
      await dispatch(updateCotizacion({
        id: cotizacion.id,
        estado: 'enEvaluacion'
      })).unwrap();

      const updatePromises = cotizacionProveedores.map(proveedor =>
        dispatch(updateCotizacionProveedor({
          id: proveedor.id,
          estado: "enEvaluacion"
        }))
      );

      await Promise.all(updatePromises);
    } catch (error) {
      console.error('Error al actualizar estados:', error);
      alert('Error al actualizar los estados');
    } finally {
      setIsLoadingEnEvaluacion(false);
    }
  };

  const handleGenerarOC = () => {
    setShowGenerarOCModal(true);
  };

  const handleConfirmarGenerarOC = async () => {
    if (!proveedorAdjudicado || !cotizacion.id) return;

    setLoading(true);
    setLoadingStep(0);
    setLoadingProgress(0);

    try {
      // Paso 1: Iniciando
      setLoadingStep(0);
      setLoadingProgress(25);
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Paso 2: Creando OC
      setLoadingStep(1);
      setLoadingProgress(50);
      const nuevaOrdenCompra = {
        proveedor_id: proveedorAdjudicado.proveedor_id.id,
        codigo_orden: 'ORD-' + new Date().getTime(),
        cotizacion_id: cotizacion.id,
        estado: 'pendiente',
        descripcion: `Orden de compra ${new Date().toLocaleString('en-US', { timeZone: 'America/Lima' })}`,
        usuario: userId || '',
        fecha: new Date().toISOString(),
        fecha_ini: new Date().toISOString(),
        fecha_fin: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
        tipo: tipoOrden  // Use the tipoOrden prop here
      };

      const ordenCompraCreada = await dispatch(addOrdenCompra(nuevaOrdenCompra)).unwrap();

      // Paso 3: Asociando recursos
      setLoadingStep(2);
      setLoadingProgress(75);
      const recursosOC = cotizacionProveedoresRecursos
        .filter(cpr => cpr.cotizacion_proveedor_id.id === proveedorAdjudicado.id)
        .map(cpr => ({
          orden_compra_id: ordenCompraCreada.id,
          id_recurso: cpr.recurso_id.id,
          cantidad: cpr.cantidad,
          costo_real: cpr.costo,
          costo_aproximado: cpr.costo,
          estado: 'pendiente',
          requerimiento_recurso_id: cpr.requerimiento_recurso_id
        }));

      for (const recurso of recursosOC) {
        await dispatch(addOrdenCompraRecurso(recurso));
      }

      // Paso 4: Actualizando estado de cotización
      setLoadingStep(3);
      setLoadingProgress(90);

      await dispatch(updateCotizacion({
        id: cotizacion.id,
        estado: 'OCGenerada'
      })).unwrap();

      // Paso 5: Actualizando estado de proveedor
      setLoadingStep(4);
      setLoadingProgress(95);
      await dispatch(updateCotizacionProveedor({
        id: proveedorAdjudicado.id,
        estado: "buenaProAdjudicada"
      }));

      // Finalizando
      setLoadingProgress(100);
      await new Promise(resolve => setTimeout(resolve, 500));

      setShowGenerarOCModal(false);
      setLoading(false);

    } catch (error) {
      console.error('Error al generar la Orden de Compra:', error);
      setLoading(false);
    }
  };

  const handleRechazarOC = () => {
    setShowRechazarOCModal(true);
  };

  const handleConfirmarRechazo = async () => {
    if (!cotizacion.id || !proveedorAdjudicado || !motivoRechazo.trim()) return;

    setIsLoadingRechazar(true);
    try {
      await dispatch(addComentario({
        usuario_id: UserId,
        referencia_id: cotizacion.id,
        tabla: 'cotizacion',
        comentario: motivoRechazo
      })).unwrap();

      await dispatch(updateCotizacion({
        id: cotizacion.id,
        estado: 'rechazada'
      })).unwrap();

      await dispatch(getComentariosByReferenciaId(cotizacion.id));

      setShowRechazarOCModal(false);
      setMotivoRechazo('');
    } catch (error) {
      console.error('Error al rechazar la OC:', error);
      alert('Error al rechazar la Orden de Compra');
    } finally {
      setIsLoadingRechazar(false);
    }
  };

  const handleDeletePdf = async () => {
    if (!cotizacion.id) return;
    
    const pdfFile = archivos.find(archivo => 
      archivo.referencia_id === cotizacion.id && 
      archivo.tipo === 'cotizacion'
    );

    if (!pdfFile) return;

    setIsLoadingDelete(true);
    try {
      await dispatch(deleteFile(pdfFile.id)).unwrap();
      setPdfUrl(null);
      setHasPdf(false);
      setShowPdfModal(false);
      setShowUploadPdfModal(false);
    } catch (error) {
      console.error('Error al eliminar el archivo:', error);
      alert('Error al eliminar el archivo');
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.3 }}
      className="w-full bg-white p-6 rounded-xl shadow-lg"
    >
      {isLoadingInitial && <LoaderOverlay message="Cargando datos iniciales..." />}
      {isLoadingEnEvaluacion && <LoaderOverlay message="Actualizando estado de evaluación..." />}
      {isLoadingRechazar && <LoaderOverlay message="Procesando rechazo..." />}
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 mt-1 text-gray-500 text-sm">
            <div>
              <span className="font-semibold">Cotización:</span> {cotizacion.codigo_cotizacion}
            </div>
            <div>
              <span className="font-semibold">Estado:</span> {cotizacion.estado}
            </div>
            <div>
              <span className="font-semibold">Generada por:</span> {cotizacion.usuario_id?.nombres.split(" ")[0]} {cotizacion.usuario_id?.apellidos.split(" ")[0]}
            </div>
            <div>
              <span className="font-semibold">F.Emision:</span> {formatFullTime(cotizacion.fecha ?? new Date().toISOString())}
            </div>
          </div>
        </div>
        <div className="flex gap-2 mt-3 md:mt-0 mx-2">
          {/* Mostrar botón de Proveedor solo en estados iniciales */}
          {['vacio', 'pendiente', 'iniciada', 'rechazada', 'cotizada'].includes(cotizacion.estado || '') && (
            <Button
              text="Proveedor"
              color="rojo"
              className="text-sm shadow-md min-w-32"
              icon={<FiPlusCircle className="w-4 h-4" />}
              onClick={() => setShowProveedorModal(true)}
            />
          )}

          {/* Mostrar botón de IniciarAdjudicar */}
          {['iniciada', 'cotizada', 'rechazada'].includes(cotizacion.estado || '') && (
            <Button
              onClick={handleEnEvaluacion}
              text="IniciarAdjudicar"
              color="azul"
              className="text-sm shadow-md min-w-36"
            />
          )}

          {/* Mostrar botón de Generar OC solo si hay proveedor adjudicado */}
          {cotizacion.estado === 'adjudicada' && usuarioHabilitado === 4 && proveedorAdjudicado && (
            <>
              <Button
                text="GenerarOC"
                color="amarillo"
                className="text-sm shadow-md"
                onClick={handleGenerarOC}
              />
              <Button
                text="Rechazar"
                color="rojo"
                className="text-sm shadow-md"
                onClick={handleRechazarOC}
              />
            </>
          )}
        </div>
        <div className="flex gap-2 mt-2 md:mt-0 ml-2">
          {cotizacion.estado === "adjudicada" && (
            <>
              {!hasPdf ? (
                <Button
                  text="PDF"
                  color="azul"
                  className="text-sm shadow-md"
                  icon={<FiPlusCircle className="w-4 h-4" />}
                  onClick={() => setShowUploadPdfModal(true)}
                />
              ) : (
                <div className="flex gap-2">
                  <Button
                    text="Ver PDF"
                    color="verde"
                    className="text-sm shadow-md"
                    icon={<FiEye className="w-4 h-4" />}
                    onClick={() => setShowPdfModal(true)}
                  />
                  <Button
                    text="PDF"
                    color="rojo"
                    className="text-sm shadow-md"
                    icon={<FiTrash2 className="w-4 h-4" />}
                    onClick={handleDeletePdf}
                    disabled={isLoadingDelete}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {comentarios.length > 0 && cotizacion.estado === 'rechazada' && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg">
          <h3 className="text-red-800 font-semibold mb-2">Motivos de rechazo:</h3>
          <ul className="list-disc pl-5">
            {comentarios.map(comentario => (
              <li key={comentario.id} className="text-red-700">
                <span className="font-medium">{comentario.usuario_id.nombres} {comentario.usuario_id.apellidos}:</span> {comentario.comentario}
              </li>
            ))}
          </ul>
        </div>
      )}

      <ComparacionTable
        recursos={recursos}
        proveedores={proveedores}
        estadoCotizacion={cotizacion.estado || ''}
        mejorProveedor={mejorProveedor || {} as ProveedorCotizacion}
        cotizacion_id={cotizacion.id || ''}
      />

      {showProveedorModal && (
        <Modal
          isOpen={showProveedorModal}
          onClose={() => setShowProveedorModal(false)}
          title="Buscar Proveedor"
        >
          <BuscarProveedoresModal
            cotizacion_id={cotizacion.id || ''}
            cotizacionEstado={cotizacion.estado || ''}
            proveedoresActuales={cotizacionProveedores.map(cp => ({
              id: cp.proveedor_id.id || ''
            }))}
            onClose={() => setShowProveedorModal(false)}
          />
        </Modal>
      )}

      {showGenerarOCModal && (
        <Modal
          isOpen={showGenerarOCModal}
          onClose={() => setShowGenerarOCModal(false)}
          title="Confirmar generación de Orden de Compra"
        >
          {loading ? (
            <GeneracionOCLoader step={loadingStep} progress={loadingProgress} />
          ) : (
            <div className="p-4">
              <p>
                ¿Está seguro que desea generar la Orden de Compra para el proveedor {proveedorAdjudicado?.proveedor_id.nombre_comercial}?
              </p>
              <div className="flex justify-end gap-2 mt-4">
                <Button text="Cancelar" color="rojo" onClick={() => setShowGenerarOCModal(false)} />
                <Button text="Confirmar" color="verde" onClick={handleConfirmarGenerarOC} />
              </div>
            </div>
          )}
        </Modal>
      )}

      {showRechazarOCModal && (
        <Modal
          isOpen={showRechazarOCModal}
          onClose={() => setShowRechazarOCModal(false)}
          title="Motivo de rechazo"
          headerBgColor="bg-red-700"
        >
          <div className="p-4">
            <p className="mb-4">
              Por favor, indique el motivo por el cual rechaza la Orden de Compra para el proveedor {proveedorAdjudicado?.proveedor_id.nombre_comercial}:
            </p>
            <textarea
              className="w-full h-32 border rounded p-2 mb-4"
              placeholder="Escriba aquí el motivo del rechazo..."
              value={motivoRechazo}
              onChange={(e) => setMotivoRechazo(e.target.value)}
            />
            <div className="flex justify-end gap-2 mt-4">
              <Button text="Cancelar" color="azul" onClick={() => setShowRechazarOCModal(false)} />
              <Button 
                text="Confirmar rechazo" 
                color="rojo" 
                onClick={handleConfirmarRechazo}
                disabled={!motivoRechazo.trim()} 
              />
            </div>
          </div>
        </Modal>
      )}

      {/* Modal para mostrar PDF */}
      <Modal
        isOpen={showPdfModal}
        onClose={() => setShowPdfModal(false)}
        title="Documento PDF"
        className="w-[80vw] h-[80vh]"
      >
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            className="w-full h-full"
            title="PDF Viewer"
          />
        )}
      </Modal>

      {/* Modal para carga de PDF */}
      <Modal
        isOpen={showUploadPdfModal}
        onClose={() => setShowUploadPdfModal(false)}
        title="Cargar Documento PDF"
        className="w-[80vw]"
      >
        <div className="p-6">
          <FileUpload
            tipo="cotizacion"
            referencia_id={cotizacion.id || ''}
            maxFileSize={10}
            accept=".pdf"
            multiple={false}
            onFilesChange={(files) => {
              console.log('onFilesChange recibido con archivos:', files);
              if (files.length > 0) {
                const pdfFile = files[0];
                console.log('Procesando archivo PDF:', pdfFile);
                setShowUploadPdfModal(false);
              }
            }}
          />
        </div>
      </Modal>
    </motion.div>
  );
};

export default CompararProveedores;