import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_PREFIJOS_QUERY = gql`
  query ListPrefijos {
    listPrefijos {
      id
      prefijo_id
      nombre_tabla
      prefijo
      cantidad_caracteres
      usa_este_numero
    }
  }
`;

const ADD_PREFIJO_MUTATION = gql`
  mutation AddPrefijo($nombreTabla: String!, $prefijo: String!, $cantidadCaracteres: Int!) {
    addPrefijo(nombre_tabla: $nombreTabla, prefijo: $prefijo, cantidad_caracteres: $cantidadCaracteres) {
      id
      prefijo_id
      nombre_tabla
      prefijo
      cantidad_caracteres
      usa_este_numero
    }
  }
`;

const UPDATE_PREFIJO_MUTATION = gql`
  mutation UpdatePrefijo($updatePrefijoId: ID!, $usaEsteNumero: Int) {
    updatePrefijo(id: $updatePrefijoId, usa_este_numero: $usaEsteNumero) {
      id
      prefijo_id
      nombre_tabla
      prefijo
      cantidad_caracteres
      usa_este_numero
    }
  }
`;

const DELETE_PREFIJO_MUTATION = gql`
  mutation DeletePrefijo($deletePrefijoId: ID!) {
    deletePrefijo(id: $deletePrefijoId) {
      id
    }
  }
`;

export const listPrefijosService = async () => {
  try {
    const response = await client.query({
      query: LIST_PREFIJOS_QUERY,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.listPrefijos;
  } catch (error) {
    console.error('Error al obtener la lista de prefijos:', error);
    throw error;
  }
};

export const addPrefijoService = async (prefijoData: { nombre_tabla: string, prefijo: string, cantidad_caracteres: number }) => {
  try {
    const response = await client.mutate({
      mutation: ADD_PREFIJO_MUTATION,
      variables: {
        nombreTabla: prefijoData.nombre_tabla,
        prefijo: prefijoData.prefijo,
        cantidadCaracteres: prefijoData.cantidad_caracteres
      },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.addPrefijo;
  } catch (error) {
    console.error('Error al crear el prefijo:', error);
    throw error;
  }
};

export const updatePrefijoService = async (prefijo: { id: string, usa_este_numero: number }) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_PREFIJO_MUTATION,
      variables: {
        updatePrefijoId: prefijo.id,
        usaEsteNumero: prefijo.usa_este_numero
      },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.updatePrefijo;
  } catch (error) {
    console.error('Error al actualizar el prefijo:', error);
    throw error;
  }
};

export const deletePrefijoService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_PREFIJO_MUTATION,
      variables: { deletePrefijoId: id },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.deletePrefijo;
  } catch (error) {
    console.error('Error al eliminar el prefijo:', error);
    throw error;
  }
};
