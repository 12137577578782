import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IAprobacion } from '../types/aprobacionGeneral';
import {
  listAprobacionesGeneralesService,
  getAprobacionGeneralService,
  addAprobacionGeneralService,
  updateAprobacionGeneralService,
  deleteAprobacionGeneralService,
} from '../services/aprobacionGeneralService';

interface AprobacionState {
  aprobaciones: IAprobacion[];
  selectedAprobacion: IAprobacion | null;
  loading: boolean;
  error: string | null;
}

const initialState: AprobacionState = {
  aprobaciones: [],
  selectedAprobacion: null,
  loading: false,
  error: null,
};

// Thunks
export const fetchAprobacionesGenerales = createAsyncThunk(
  'aprobacionGeneral/fetchAll',
  async () => {
    const response = await listAprobacionesGeneralesService();
    return response;
  }
);

export const fetchAprobacionGeneralById = createAsyncThunk(
  'aprobacionGeneral/fetchById',
  async (id: string) => {
    const response = await getAprobacionGeneralService(id);
    return response;
  }
);

export const createAprobacionGeneral = createAsyncThunk(
  'aprobacionGeneral/create',
  async (data: {
    referencia_id: string;
    referencia: string;
    usuario_id: string;
    fecha: Date;
  }) => {
    const response = await addAprobacionGeneralService(data);
    return response;
  }
);

export const updateAprobacionGeneral = createAsyncThunk(
  'aprobacionGeneral/update',
  async (data: { id: string; fecha?: Date }) => {
    const response = await updateAprobacionGeneralService(data);
    return response;
  }
);

export const deleteAprobacionGeneral = createAsyncThunk(
  'aprobacionGeneral/delete',
  async (id: string) => {
    const response = await deleteAprobacionGeneralService(id);
    return response;
  }
);

const aprobacionGeneralSlice = createSlice({
  name: 'aprobacionGeneral',
  initialState,
  reducers: {
    clearSelectedAprobacionGeneral: (state) => {
      state.selectedAprobacion = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch All
      .addCase(fetchAprobacionesGenerales.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAprobacionesGenerales.fulfilled, (state, action) => {
        state.loading = false;
        state.aprobaciones = action.payload;
      })
      .addCase(fetchAprobacionesGenerales.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al obtener las aprobaciones generales';
      })
      // Fetch By Id
      .addCase(fetchAprobacionGeneralById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAprobacionGeneralById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedAprobacion = action.payload;
      })
      .addCase(fetchAprobacionGeneralById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al obtener la aprobación general';
      })
      // Create
      .addCase(createAprobacionGeneral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAprobacionGeneral.fulfilled, (state, action) => {
        state.loading = false;
        state.aprobaciones.push(action.payload);
      })
      .addCase(createAprobacionGeneral.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al crear la aprobación general';
      })
      // Update
      .addCase(updateAprobacionGeneral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAprobacionGeneral.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.aprobaciones.findIndex(
          (aprobacion) => aprobacion.id === action.payload.id
        );
        if (index !== -1) {
          state.aprobaciones[index] = action.payload;
        }
        if (state.selectedAprobacion?.id === action.payload.id) {
          state.selectedAprobacion = action.payload;
        }
      })
      .addCase(updateAprobacionGeneral.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al actualizar la aprobación general';
      })
      // Delete
      .addCase(deleteAprobacionGeneral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAprobacionGeneral.fulfilled, (state, action) => {
        state.loading = false;
        state.aprobaciones = state.aprobaciones.filter(
          (aprobacion) => aprobacion.id !== action.payload.id
        );
        if (state.selectedAprobacion?.id === action.payload.id) {
          state.selectedAprobacion = null;
        }
      })
      .addCase(deleteAprobacionGeneral.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al eliminar la aprobación general';
      });
  },
});

export const { clearSelectedAprobacionGeneral, clearError } = aprobacionGeneralSlice.actions;

// Selectores
export const selectAprobacionesByReferencia = (state: { aprobacionGeneral: AprobacionState }, referencia: string) => 
  state.aprobacionGeneral.aprobaciones.filter(aprobacion => aprobacion.referencia === referencia);

export const selectAprobacionesByUsuario = (state: { aprobacionGeneral: AprobacionState }, usuarioId: string) => 
  state.aprobacionGeneral.aprobaciones.filter(aprobacion => aprobacion.usuario_id === usuarioId);

export const aprobacionGeneralReducer = aprobacionGeneralSlice.reducer; 