import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_REQUERIMIENTO_RECURSO_ALL_QUERY = gql`
  query ListRequerimientoRecursoAll($requerimientoRecursoId: String!) {
    listRequerimientoRecursoAll(requerimiento_recurso_id: $requerimientoRecursoId) {
      requerimiento_recurso_id
      requerimiento {
        cantidad
      }
      solicitudCompra {
        padreEstado
        cantidad
      }
      solicitudAlmacen {
        padreEstado
        cantidad
      }
      cotizacion {
        padreEstado
        cantidad
      }
      cotizacion_proveedor {
        padreEstado
        cantidad
      }
      orden_compra {
        padreEstado
        cantidad
      }
      transferencia {
        padreEstado
        cantidad
      }
    }
  }
`;

export interface RequerimientoRecursoAllResponse {
  requerimiento_recurso_id: string;
  requerimiento: {
    cantidad: number;
  };
  solicitudCompra: {
    padreEstado: string;
    cantidad: number;
  };
  solicitudAlmacen: {
    padreEstado: string;
    cantidad: number;
  };
  cotizacion: {
    padreEstado: string;
    cantidad: number;
  };
  cotizacion_proveedor: {
    padreEstado: string;
    cantidad: number;
  };
  orden_compra: {
    padreEstado: string;
    cantidad: number;
  };
  transferencia: {
    padreEstado: string;
    cantidad: number;
  };
}

/**
 * Servicio para consultar el seguimiento completo de un recurso de requerimiento
 * a través de todo el proceso de compra
 * @param requerimientoRecursoId ID del recurso de requerimiento a consultar
 * @returns Información del seguimiento del recurso en las distintas etapas
 */
export const listRequerimientoRecursoAllService = async (requerimientoRecursoId: string): Promise<RequerimientoRecursoAllResponse> => {
  try {
    const response = await client.query({
      query: LIST_REQUERIMIENTO_RECURSO_ALL_QUERY,
      variables: { requerimientoRecursoId },
      fetchPolicy: 'network-only' // Asegura que siempre se obtenga la información más reciente
    });
    
    return response.data.listRequerimientoRecursoAll;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error consultando seguimiento del recurso: ${error.message}`);
    }
    throw error;
  }
};
