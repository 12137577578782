import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../../components/Buttons/Button';
import Modal from '../../components/Modal/Modal';
import TableComponent from '../../components/Table/TableComponent';
import { fetchDataPDFByOrdenCompraId, fetchOrdenCompras, clearDataPDF } from '../../slices/ordenCompraSlice';
import { FiEye, FiRefreshCw } from 'react-icons/fi';
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import { useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from '../../store/store';
import OrdenCompraDetalle from './OrdenCompraDetalle';
import { DataPDFOrdenCompra } from '../../services/ordenCompraService';
import LoaderOverlay from '../../components/Loader/LoaderOverlay';

// Definir interfaces
interface OrdenCompra {
    id: string;
    codigo_orden: string;
    cotizacion_id: string;
    estado: string;
    descripcion: string;
    fecha_ini: string;
    fecha_fin: string;
    monto_total?: number;
    proveedor?: {
        razon_social?: string;
    };
}

const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
};

const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
};

const OrdenCompraPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { ordenCompras, loading, error } = useSelector((state: RootState) => state.ordenCompra);
    const [editingOrdenCompra, setEditingOrdenCompra] = useState<OrdenCompra | null>(null);
    const [activeFilter, setActiveFilter] = useState('todos');
    const [showDetalleModal, setShowDetalleModal] = useState(false);
    const [DataDPF, setDataDPF] = useState<DataPDFOrdenCompra | null>(null);

    useEffect(() => {
        dispatch(fetchOrdenCompras());
    }, [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            if (editingOrdenCompra?.id) {
                try {
                    const result = await dispatch(fetchDataPDFByOrdenCompraId(editingOrdenCompra.id)).unwrap();
                    setDataDPF(result);
                } catch (error) {
                    console.error('Error fetching PDF data:', error);
                }
            }
        };
        fetchData();
    }, [editingOrdenCompra?.id, dispatch]);

    const handleView = (ordenCompra: OrdenCompra) => {
        setEditingOrdenCompra(ordenCompra);
        setShowDetalleModal(true);
    };

    const handleCloseDetalleModal = () => {
        setShowDetalleModal(false);
        setEditingOrdenCompra(null);
        setDataDPF(null);
        dispatch(clearDataPDF());
    };

    const handleRefresh = () => {
        dispatch(fetchOrdenCompras());
    };

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const getFilteredOrdenCompras = () => {
        switch (activeFilter) {
            case 'activas':
                return ordenCompras.filter(oc => oc.estado);
            case 'inactivas':
                return ordenCompras.filter(oc => !oc.estado);
            default:
                return ordenCompras;
        }
    };

    const navigate = useNavigate();
    const handleNavigateToPayments = (ordenCompra: OrdenCompra) => {
        navigate(`/dashboard/ordenPago`, {
            state: {
                ordenCompraId: ordenCompra.id,
                codigoOrden: ordenCompra.codigo_orden
            }
        });
    };

    const tableData = useMemo(() => ({
        filterSelect: [true, true],
        filter: [
            // true,
            true,
            true,
            true,
            true,
            true
        ],
        headers: [
            // "id",
            "fecha inicio",
            "fecha fin",
            "codigo",
            "descripción",
            "estado",
            "Pagos",
            "opciones"
        ],
        rows: getFilteredOrdenCompras().map(oc => ({
            // id: oc.id,
            codigo: oc.codigo_orden,
            descripción: oc.descripcion,
            "fecha inicio": formatDate(oc.fecha_ini),
            "fecha fin": formatDate(oc.fecha_fin),
            estado: oc.estado ? 'Activo' : 'Inactivo',
            Pagos: (
                <button
                    className="text-blue-500 hover:text-blue-700 transition-colors"
                    onClick={() => handleNavigateToPayments(oc)}
                    title="Ver pagos"
                >
                    <LiaMoneyBillWaveAltSolid size={22} className="text-green-500" />
                </button>
            ),
            opciones: (
                <div className='flex flex-row gap-2'>
                    <button className='text-yellow-500' onClick={() => handleView(oc)}>
                        <FiEye />
                    </button>

                </div>
            )
        }))
    }), [ordenCompras, activeFilter, navigate]);

    
    if (error) return <div>Error: {error}</div>;

    return (
        <motion.div
            className="flex flex-col h-full"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
        >
            {(showDetalleModal && editingOrdenCompra) && (
                <AnimatePresence>
                    <Modal
                        title="Detalle de Orden de Compra"
                        isOpen={showDetalleModal}
                        onClose={handleCloseDetalleModal}
                    >
                        <OrdenCompraDetalle DataDPF={DataDPF} />
                    </Modal>
                </AnimatePresence>) } {<>
                <motion.div className="text-white pb-4 px-4 flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h1 className="text-2xl font-bold text-blue-50">Órdenes de Compra</h1>
                    </div>
                    <Button
                        text='Actualizar'
                        color='blanco'
                        onClick={handleRefresh}
                        className="rounded w-auto"
                        icon={<FiRefreshCw className="text-green-500 text-center h-3 w-3" />}
                    />
                </motion.div>

                <motion.div className="flex flex-1 overflow-hidden rounded-xl">
                    <main className="w-full flex flex-col flex-grow p-4 bg-white/80 overflow-hidden">
                        <div className="mb-4 space-x-2">
                            <button
                                className={`px-3 py-1 ${activeFilter === 'activas' ? 'bg-green-600' : 'bg-green-500'} text-white rounded-md text-xs`}
                                onClick={() => setActiveFilter('activas')}
                            >
                                Activas
                            </button>
                            <button
                                className={`px-3 py-1 ${activeFilter === 'inactivas' ? 'bg-red-600' : 'bg-red-500'} text-white rounded-md text-xs`}
                                onClick={() => setActiveFilter('inactivas')}
                            >
                                Inactivas
                            </button>
                            <button
                                className={`px-3 py-1 ${activeFilter === 'todos' ? 'bg-blue-600' : 'bg-blue-500'} text-white rounded-md text-xs`}
                                onClick={() => setActiveFilter('todos')}
                            >
                                Todas
                            </button>
                        </div>
                        <motion.div className="flex-grow border rounded-lg overflow-hidden">
                            <div className="h-full overflow-auto">
                                <TableComponent tableData={tableData} />
                            </div>
                        </motion.div>
                    </main>
                </motion.div>
                {(loading) && <LoaderOverlay message='CARGANDO PDF ...' />}
            </>
            }
        </motion.div>
    );
};

export default OrdenCompraPage;
