import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { listRolesAndMenusService, getRoleService, addRoleService, updateRoleService } from '../services/roleService';

// Interfaces
interface Permission {
  ver: boolean;
  crear: boolean;
  editar: boolean;
  eliminar: boolean;
}

interface MenuPermission {
  menuID: string;
  permissions: Permission;
}

interface Role {
  id: string;
  nombre: string;
  descripcion: string;
  menusPermissions: MenuPermission[];
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
}

interface Menu {
  id: string;
  nombre: string;
  slug: string;
  posicion: number;
}

interface RoleState {
  roles: Role[];
  menus: Menu[];
  selectedRole: Role | null;
  loading: boolean;
  error: string | null;
}

// Estado inicial
const initialState: RoleState = {
  roles: [],
  menus: [],
  selectedRole: null,
  loading: false,
  error: null,
};

// Función auxiliar para manejar errores
const handleError = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

// Thunks
export const fetchRolesAndMenus = createAsyncThunk(
  'role/fetchRolesAndMenus',
  async (_, { rejectWithValue }) => {
    try {
      return await listRolesAndMenusService();
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const fetchRoleById = createAsyncThunk(
  'role/fetchRoleById',
  async (roleId: string, { rejectWithValue }) => {
    try {
      return await getRoleService(roleId);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const addRole = createAsyncThunk(
  'role/addRole',
  async (roleData: Omit<Role, 'id' | 'createdAt' | 'updatedAt' | 'deleted'>, { rejectWithValue }) => {
    try {
      return await addRoleService(roleData);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const updateRole = createAsyncThunk(
  'role/updateRole',
  async (role: Pick<Role, 'id' | 'nombre' | 'descripcion' | 'menusPermissions'>, { rejectWithValue }) => {
    try {
      return await updateRoleService(role);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

// Slice
const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    clearSelectedRole: (state) => {
      state.selectedRole = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRolesAndMenus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRolesAndMenus.fulfilled, (state, action: PayloadAction<{ listRoles: Role[], listMenus: Menu[] }>) => {
        state.loading = false;
        state.roles = action.payload.listRoles;
        state.menus = action.payload.listMenus;
      })
      .addCase(fetchRolesAndMenus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchRoleById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoleById.fulfilled, (state, action: PayloadAction<Role>) => {
        state.loading = false;
        state.selectedRole = action.payload;
      })
      .addCase(fetchRoleById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addRole.fulfilled, (state, action: PayloadAction<Role>) => {
        state.loading = false;
        state.roles.push(action.payload);
      })
      .addCase(addRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateRole.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRole.fulfilled, (state, action: PayloadAction<Role>) => {
        state.loading = false;
        const index = state.roles.findIndex(role => role.id === action.payload.id);
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
        if (state.selectedRole?.id === action.payload.id) {
          state.selectedRole = action.payload;
        }
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearSelectedRole } = roleSlice.actions;
export const roleReducer = roleSlice.reducer;