import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { 
  addOpcion, 
  updateOpcion,
  deleteOpcion,
  getOpcionesByCampo
} from '../../slices/formularioSlice';
import {
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider
} from '@mui/material';
import {
  MdAdd,
  MdEdit,
  MdDelete
} from 'react-icons/md';

interface OpcionFormProps {
  campoId: string;
  onClose: () => void;
}

const OpcionForm: React.FC<OpcionFormProps> = ({
  campoId,
  onClose
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { secciones, loading } = useSelector((state: RootState) => state.formulario);
  
  // Buscar el campo en todas las secciones
  const campo = secciones.flatMap(s => s.campos || []).find(c => c.id === campoId);
  
  // Estados del formulario
  const [etiqueta, setEtiqueta] = useState('');
  const [valor, setValor] = useState('');
  const [orden, setOrden] = useState(0);
  
  // Estado para edición
  const [editId, setEditId] = useState<string | null>(null);
  
  const [formErrors, setFormErrors] = useState({
    etiqueta: '',
    valor: '',
    orden: ''
  });

  useEffect(() => {
    // Cargar opciones existentes
    if (campoId) {
      dispatch(getOpcionesByCampo(campoId));
    }
    
    // Inicializar orden
    if (campo?.opciones) {
      const maxOrden = campo.opciones.reduce((max, opcion) => 
        opcion.orden > max ? opcion.orden : max, 0);
      setOrden(maxOrden + 1);
    } else {
      setOrden(1);
    }
  }, [dispatch, campoId, campo]);

  const validateForm = () => {
    const errors = {
      etiqueta: '',
      valor: '',
      orden: ''
    };
    let isValid = true;

    if (!etiqueta.trim()) {
      errors.etiqueta = 'La etiqueta es requerida';
      isValid = false;
    }

    if (!valor.trim()) {
      errors.valor = 'El valor es requerido';
      isValid = false;
    }

    if (orden <= 0) {
      errors.orden = 'El orden debe ser mayor a 0';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    if (editId) {
      dispatch(updateOpcion({
        updateOpcionId: editId,
        etiqueta,
        valor,
        orden
      }));
    } else {
      dispatch(addOpcion({
        campoId,
        etiqueta,
        valor,
        orden
      }));
    }
    
    // Limpiar el formulario
    resetForm();
    
    // Recargar opciones
    dispatch(getOpcionesByCampo(campoId));
  };

  const handleEdit = (opcionId: string) => {
    if (campo?.opciones) {
      const opcion = campo.opciones.find(o => o.id === opcionId);
      if (opcion) {
        setEtiqueta(opcion.etiqueta);
        setValor(opcion.valor);
        setOrden(opcion.orden);
        setEditId(opcionId);
      }
    }
  };

  const handleDelete = (opcionId: string) => {
    if (window.confirm('¿Está seguro que desea eliminar esta opción?')) {
      dispatch(deleteOpcion(opcionId));
      // Recargar opciones
      dispatch(getOpcionesByCampo(campoId));
    }
  };

  const resetForm = () => {
    setEtiqueta('');
    setValor('');
    // Calcular nuevo orden
    if (campo?.opciones) {
      const maxOrden = campo.opciones.reduce((max, opcion) => 
        opcion.orden > max ? opcion.orden : max, 0);
      setOrden(maxOrden + 1);
    } else {
      setOrden(1);
    }
    setEditId(null);
  };

  if (loading) return <div>Cargando...</div>;

  return (
    <>
      <DialogTitle>
        Opciones de Campo 
        {campo && <Typography variant="subtitle2" color="text.secondary">
          {campo.etiqueta} ({campo.tipo})
        </Typography>}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Etiqueta"
                value={etiqueta}
                onChange={(e) => setEtiqueta(e.target.value)}
                fullWidth
                margin="normal"
                error={!!formErrors.etiqueta}
                helperText={formErrors.etiqueta || 'Texto que verá el usuario'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Valor"
                value={valor}
                onChange={(e) => setValor(e.target.value)}
                fullWidth
                margin="normal"
                error={!!formErrors.valor}
                helperText={formErrors.valor || 'Valor que se guardará en el formulario'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Orden"
                type="number"
                value={orden}
                onChange={(e) => setOrden(parseInt(e.target.value))}
                fullWidth
                margin="normal"
                error={!!formErrors.orden}
                helperText={formErrors.orden}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                startIcon={editId ? <MdEdit /> : <MdAdd />}
              >
                {editId ? 'Actualizar Opción' : 'Añadir Opción'}
              </Button>
              {editId && (
                <Button 
                  color="inherit" 
                  onClick={resetForm}
                  sx={{ ml: 1 }}
                >
                  Cancelar
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
          
        <Divider sx={{ my: 3 }} />
        
        <Typography variant="subtitle1" gutterBottom>
          Opciones existentes
        </Typography>
        
        {campo?.opciones && campo.opciones.length > 0 ? (
          <List>
            {campo.opciones.map((opcion) => (
              <ListItem key={opcion.id} disablePadding sx={{ py: 1 }}>
                <ListItemText 
                  primary={opcion.etiqueta} 
                  secondary={`Valor: ${opcion.valor} | Orden: ${opcion.orden}`} 
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleEdit(opcion.id)}>
                    <MdEdit />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleDelete(opcion.id)}>
                    <MdDelete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No hay opciones definidas para este campo.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cerrar
        </Button>
      </DialogActions>
    </>
  );
};

export default OpcionForm; 