import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listRecursosComposicionApuService,
  addRecursoComposicionApuService,
  updateRecursoComposicionApuService,
  deleteRecursoComposicionApuService,
  getRecursoComposicionApuService
} from '../services/recursoComposicionApuService';
import { Unidad } from './unidadSlice';
import { Recurso } from './recursoSlice';

export interface RecursoComposicionApu {
  id_rec_comp_apu: string;
  recurso_id: string;
  unidad_id: string;
  nombre: string;
  especificaciones: string;
  descripcion: string;
  fecha_creacion: string;
  recurso: Recurso;
  unidad: Unidad;
}

interface RecursoComposicionApuState {
  recursosComposicionApu: RecursoComposicionApu[];
  selectedRecursoComposicionApu: RecursoComposicionApu | null;
  loading: boolean;
  error: string | null;
}

const initialState: RecursoComposicionApuState = {
  recursosComposicionApu: [],
  selectedRecursoComposicionApu: null,
  loading: false,
  error: null,
};

// Función auxiliar para manejar errores
const handleError = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

// Thunks
export const fetchRecursosComposicionApu = createAsyncThunk(
  'recursoComposicionApu/fetchRecursos',
  async (_, { rejectWithValue }) => {
    try {
      return await listRecursosComposicionApuService();
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const getRecursoComposicionApu = createAsyncThunk(
  'recursoComposicionApu/getRecurso',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getRecursoComposicionApuService(id);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const addRecursoComposicionApu = createAsyncThunk(
  'recursoComposicionApu/addRecurso',
  async (data: {
    recursoId: string;
    unidadId: string;
    nombre: string;
    especificaciones?: string;
    descripcion?: string;
  }, { rejectWithValue }) => {
    try {
      return await addRecursoComposicionApuService(data);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const updateRecursoComposicionApu = createAsyncThunk(
  'recursoComposicionApu/updateRecurso',
  async (data: {
    idRecCompApu: string;
    recursoId?: string;
    unidadId?: string;
    nombre?: string;
    especificaciones?: string;
    descripcion?: string;
  }, { rejectWithValue }) => {
    try {
      return await updateRecursoComposicionApuService(data);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const deleteRecursoComposicionApu = createAsyncThunk(
  'recursoComposicionApu/deleteRecurso',
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteRecursoComposicionApuService(id);
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

// Slice
const recursoComposicionApuSlice = createSlice({
  name: 'recursoComposicionApu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecursosComposicionApu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRecursosComposicionApu.fulfilled, (state, action: PayloadAction<RecursoComposicionApu[]>) => {
        state.loading = false;
        state.recursosComposicionApu = action.payload;
      })
      .addCase(fetchRecursosComposicionApu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getRecursoComposicionApu.fulfilled, (state, action: PayloadAction<RecursoComposicionApu>) => {
        state.selectedRecursoComposicionApu = action.payload;
      })
      .addCase(addRecursoComposicionApu.fulfilled, (state, action: PayloadAction<RecursoComposicionApu>) => {
        state.recursosComposicionApu.push(action.payload);
      })
      .addCase(updateRecursoComposicionApu.fulfilled, (state, action: PayloadAction<RecursoComposicionApu>) => {
        const index = state.recursosComposicionApu.findIndex(
          recurso => recurso.id_rec_comp_apu === action.payload.id_rec_comp_apu
        );
        if (index !== -1) {
          state.recursosComposicionApu[index] = action.payload;
        }
      })
      .addCase(deleteRecursoComposicionApu.fulfilled, (state, action: PayloadAction<{ id_rec_comp_apu: string }>) => {
        state.recursosComposicionApu = state.recursosComposicionApu.filter(
          recurso => recurso.id_rec_comp_apu !== action.payload.id_rec_comp_apu
        );
      });
  },
});

export const recursoComposicionApuReducer = recursoComposicionApuSlice.reducer;
