import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchOrdenCompraRecursosByOrdenId } from '../../slices/ordenCompraRecursosSlice';
import Modal from '../../components/Modal/Modal';
import TableComponent from '../../components/Table/TableComponent';
import LoaderPage from '../../components/Loader/LoaderPage';

interface RecursosOrdenCompraModalProps {
  isOpen: boolean;
  onClose: () => void;
  ordenCompraId: string;
  onTotalUpdate?: (total: number) => void; // Agregar esta prop
}

const RecursosOrdenCompraModal: React.FC<RecursosOrdenCompraModalProps> = ({
  isOpen,
  onClose,
  ordenCompraId,
  onTotalUpdate
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { ordenCompraRecursosByOrdenId, loading, error } = useSelector(
    (state: RootState) => state.ordenCompraRecursos
  );

  useEffect(() => {
    if (isOpen && ordenCompraId) {
      dispatch(fetchOrdenCompraRecursosByOrdenId(ordenCompraId));
    }
  }, [dispatch, ordenCompraId, isOpen]);

  // Calcular la suma total del costo real
  const totalCostoReal = useMemo(() => {
    return ordenCompraRecursosByOrdenId.reduce((sum, recurso) => 
      sum + (recurso.costo_real || 0), 0
    );
  }, [ordenCompraRecursosByOrdenId]);

  // Actualizar useEffect para enviar el total cuando cambie
  useEffect(() => {
    const total = ordenCompraRecursosByOrdenId.reduce((sum, recurso) => 
      sum + (recurso.costo_real || 0), 0
    );
    onTotalUpdate?.(total);
  }, [ordenCompraRecursosByOrdenId, onTotalUpdate]);

  if (loading) return <LoaderPage />;
  if (error) return <div>Error: {error}</div>;

  const tableData = {
    filter: [true, true, true, true, true],
    headers: [
      "Recurso",
      "Código",
      "Cantidad",
      "Costo Aproximado",
      "Costo Real"
    ],
    rows: ordenCompraRecursosByOrdenId.map(recurso => ({
      "Recurso": recurso.id_recurso.nombre,
      "Código": recurso.id_recurso.codigo,
      "Cantidad": recurso.cantidad,
      "Costo Aproximado": recurso.costo_aproximado,
      "Costo Real": recurso.costo_real
    }))
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Recursos de la Orden de Compra"
    >
      <div className="w-full max-h-[calc(100vh-10rem)] overflow-auto">
        {ordenCompraRecursosByOrdenId.length === 0 ? (
          <div className="flex justify-center items-center p-8">
            <p className="text-gray-500 text-lg">No hay recursos disponibles</p>
          </div>
        ) : (
          <div>
            <TableComponent tableData={tableData} />
            <div id="total" className="mt-4 space-y-3 p-4 bg-gray-50 rounded-lg">
              {/* Total Costo Real */}
              <div className="flex justify-end items-center gap-2">
                <span className="font-medium text-gray-700">Total :</span>
                <label className="px-3  w-40 text-right font-semibold text-gray-700">
                  S/ {totalCostoReal.toFixed(2)}
                </label>
              </div>
              {/* IGV (18%) */}
              <div className="flex justify-end items-center gap-2">
                <span className="font-medium text-gray-700">IGV (18%):</span>
                <label className="px-3 w-40 text-right font-semibold text-blue-600">
                  S/ {(totalCostoReal * 0.18).toFixed(2)}
                </label>
              </div>
              {/* Total con IGV */}
              <div className="flex justify-end items-center gap-2">
                <span className="font-medium text-gray-700">Total con IGV:</span>
                <label className="px-3  w-40 text-right font-semibold text-green-600">
                  S/ {(totalCostoReal * 1.18).toFixed(2)}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
//todo happy hacking

export default RecursosOrdenCompraModal;
