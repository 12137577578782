import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store/store';
import ListaProyectos from './ListaProyectos';
// import { clearActiveTitulo, clearActivePresupuesto, clearActiveProyecto } from '../../../slices/activeDataSlice';
import PresupuestoTableAPU from './PresupuestoTableAPU';
import APU from './APU';
import LoaderOverlay from '../../../components/Loader/LoaderOverlay';
import { getComposicionesApuByTitulo } from '../../../slices/composicionApuSlice';
import TitulosJerarquia from '../1HojaPresupuesto/TitulosJerarquia/TitulosJerarquia';

interface GestionLayoutProps {
  children?: React.ReactNode;
}

const GestionLayoutAPU: React.FC<GestionLayoutProps> = () => {
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const { activeProyecto, activePresupuesto, activeTitulo, isEditMode } = useSelector((state: RootState) => state.activeData);
  const loading = useSelector((state: RootState) => state.titulo.loading);
  const composiciones = useSelector((state: RootState) => state.composicionApu.composicionesApu);
  const composicionesLoading = useSelector((state: RootState) => state.composicionApu.loading);

  useEffect(() => {
    if (activeTitulo?.id_titulo && activeProyecto?.id_proyecto && activeTitulo.tipo === "PARTIDA") {
      dispatch(getComposicionesApuByTitulo({
        id_titulo: activeTitulo.id_titulo,
        id_proyecto: activeProyecto.id_proyecto
      }));
    }
  }, [activeTitulo, dispatch]);

  useEffect(() => {
    if (window.innerWidth < 1024 && activePresupuesto) {
      setShowLeftPanel(false);
    }
  }, [activePresupuesto]);

  return (
    <div className="h-[calc(100vh-6rem)] flex flex-col lg:flex-row bg-gray-900/50 overflow-hidden relative">
      {/* Panel izquierdo - Lista de Presupuestos */}
      <div className={`
        absolute lg:relative w-full lg:w-1/4 h-full lg:h-full 
        lg:min-w-[300px] lg:max-w-md border-r border-gray-700 
        overflow-y-auto transition-all duration-300 ease-in-out
        transform lg:transform-none bg-gray-900/95
        ${showLeftPanel ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
        z-20
      `}>
        <div className="flex justify-between items-center p-2 border-b border-gray-700">
          <h2 className="text-white font-semibold">Presupuestos</h2>
          <button
            onClick={() => setShowLeftPanel(false)}
            className="lg:hidden p-2 text-white hover:bg-gray-700 rounded"
          >
            <span className="text-xl">X</span>
          </button>
        </div>
        <ListaProyectos />
      </div>

      {/* Panel derecho - Eliminar la condición !showLeftPanel que estaba causando el problema */}
      <div className="flex-1 flex flex-col h-full">
        {/* Botón para mostrar panel en móvil */}
        <div className="lg:hidden flex items-center p-2 border-b border-gray-700">
          <button
            onClick={() => setShowLeftPanel(true)}
            className="p-2 text-white hover:bg-gray-700 rounded"
          >
            ☰
          </button>
          <span className="ml-2 text-white">
            {activePresupuesto ? activePresupuesto.nombre_presupuesto : 'Seleccione un presupuesto'}
          </span>
        </div>

        {activeProyecto && (
          <div className="flex-1 flex flex-col">
            {isEditMode ? (
              <TitulosJerarquia />
            ) : (
              <>
                {activePresupuesto && (
                  <div className="h-1/2 min-h-[200px] max-h-[calc(50vh-3rem)] border-b border-gray-700 overflow-y-auto">
                    <PresupuestoTableAPU />
                  </div>
                )}
                {activeTitulo && (
                  <div className="h-1/2 min-h-[200px] max-h-[calc(50vh-3rem)] border-b border-gray-700 overflow-y-auto">
                    {loading || composicionesLoading ? (
                      <LoaderOverlay message="Cargando Recursos del APU." />
                    ) : (
                      <APU composiciones={composiciones} />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {/* Overlay para cerrar panel en móvil */}
      {showLeftPanel && (
        <div
          className="lg:hidden fixed inset-0 bg-black/50 z-300"
          onClick={() => setShowLeftPanel(false)}
        />
      )}
    </div>
  );
};

export default GestionLayoutAPU;