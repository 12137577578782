import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArchivoPago } from '../../../services/archivoPagoService';
import Modal from '../../../components/Modal/Modal';
import Card from '../../../components/Card/Card';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchPagosByOrdenPago, PagoOrdenPago } from '../../../slices/pagosOrdenPagoSlice';
import { addVerificacionAsync, fetchVerificacionesByArchivo, VerificacionArchivoPago } from '../../../slices/verificacionArchivoPagoSlice';
import Toast from '../../../components/Toast/Toast';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'; // Añadir este import
import { FaCheck, FaTimes } from 'react-icons/fa'; // Agregar este import

interface ComprobantesModalProps {
  isOpen: boolean;
  onClose: () => void;
  archivos: ArchivoPago[];
}

const formatearFecha = (fecha: string): string => {
  const date = new Date(fecha);
  const dia = date.getDate().toString().padStart(2, '0');
  const mes = (date.getMonth() + 1).toString().padStart(2, '0');
  const año = date.getFullYear();
  const hora = date.getHours().toString().padStart(2, '0');
  const minutos = date.getMinutes().toString().padStart(2, '0');
  const segundos = date.getSeconds().toString().padStart(2, '0');

  return `${dia}/${mes}/${año} ${hora}:${minutos}:${segundos}`;
};

const ComprobantesModal: React.FC<ComprobantesModalProps> = ({ isOpen, onClose, archivos }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector((state: RootState) => state.user.id);
  const [pagos, setPagos] = useState<PagoOrdenPago[]>([]);
  const [toastConfig, setToastConfig] = useState<{
    show: boolean;
    message: string;
    variant: 'success' | 'danger' | 'warning' | 'info';
    index: number;
  }>({
    show: false,
    message: '',
    variant: 'success',
    index: 0
  });
  const [loadingVerificacion, setLoadingVerificacion] = useState<string | null>(null);
  const [verificaciones, setVerificaciones] = useState<{ [key: string]: VerificacionArchivoPago[] }>({});

  const showToast = (message: string, variant: 'success' | 'danger' | 'warning' | 'info') => {
    setToastConfig({
      show: true,
      message,
      variant,
      index: toastConfig.index + 1
    });

    // Auto hide after duration
    setTimeout(() => {
      setToastConfig(prev => ({ ...prev, show: false }));
    }, 3000);
  };

  useEffect(() => {
    const cargarPagos = async () => {
      if (archivos.length > 0) {
        try {
          // Solo necesitamos cargar los pagos una vez usando el primer archivo
          const result = await dispatch(fetchPagosByOrdenPago(archivos[0].orden_pago_id.id)).unwrap();
          setPagos(result);
        } catch (error) {
          console.error('Error al cargar pagos:', error);
          setPagos([]);
        }
      }
    };

    cargarPagos();
  }, [dispatch, archivos]);

  useEffect(() => {
    const cargarVerificaciones = async (archivoId: string) => {
      try {
        const result = await dispatch(fetchVerificacionesByArchivo(archivoId)).unwrap();
        setVerificaciones(prev => ({
          ...prev,
          [archivoId]: result
        }));
      } catch (error) {
        console.error('Error al cargar verificaciones:', error);
      }
    };

    if (isOpen) {
      archivos.forEach(archivo => {
        cargarVerificaciones(archivo.id);
      });
    }
  }, [dispatch, archivos, isOpen]);

  // Agregar este nuevo useEffect para depuración
  useEffect(() => {
    console.log('Archivos recibidos:', archivos);
  }, [archivos]);

  const handleVerificar = async (archivoId: string, estado: 'APROBADO' | 'DESAPROBADO') => {
    if (!userId) {
      showToast('Usuario no identificado', 'danger');
      return;
    }

    setLoadingVerificacion(archivoId);
    try {
      const result = await dispatch(addVerificacionAsync({
        archivo_pago_id: archivoId,
        usuario_id: userId,
        estado: estado
      })).unwrap();

      if (result) {
        showToast(`Archivo ${estado.toLowerCase()} exitosamente`, 'success');
        // Recargar verificaciones después de la acción
        const nuevasVerificaciones = await dispatch(fetchVerificacionesByArchivo(archivoId)).unwrap();
        setVerificaciones(prev => ({
          ...prev,
          [archivoId]: nuevasVerificaciones
        }));
      }
    } catch (error) {
      console.error('Error al crear verificación:', error);
      showToast('Error al verificar el archivo', 'danger');
    } finally {
      setLoadingVerificacion(null);
    }
  };

  // Agregar esta función auxiliar
  const tieneVerificacion = (archivoId: string): boolean => {
    return verificaciones[archivoId]?.length > 0;
  };

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        title="Comprobantes de Pago"
      >
        <div className="flex flex-col gap-6 p-4">
          {/* Sección de Comprobantes */}
          <div className="w-full">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Comprobantes</h3>
            {archivos.length === 0 ? (
              <p className="text-gray-500 text-center py-8">No hay comprobantes disponibles</p>
            ) : (
              <div className="flex overflow-x-auto pb-4 custom-scrollbar">
                <div className="flex gap-4 min-w-min">
                  {archivos.map((archivo, index) => (
                    <div key={archivo.id} className="relative">
                      {/* Verificaciones Overlay */}
                      {verificaciones[archivo.id]?.length > 0 && (
                        <div className="absolute -top-2 right-0 z-10 bg-white rounded-lg shadow-lg p-2 text-sm">
                          {verificaciones[archivo.id].map((v, i) => (
                            <div 
                              key={i} 
                              className={`flex items-center gap-2 ${
                                v.estado === 'APROBADO' ? 'text-green-600' : 'text-red-600'
                              }`}
                            >
                              <span className={`w-2 h-2 rounded-full ${
                                v.estado === 'APROBADO' ? 'bg-green-500' : 'bg-red-500'
                              }`}></span>
                              <span> {v.estado}</span>
                            </div>
                          ))}
                        </div>
                      )}
                      
                      {/* Card existente */}
                      <Card className="relative group overflow-hidden bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex-shrink-0">
                        <a 
                          href={archivo.file} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="block"
                        >
                          <div className="relative overflow-hidden rounded-t-lg w-[300px]">
                            <img
                              src={archivo.file}
                              alt={`Comprobante ${index + 1}`}
                              className="w-full h-64 object-contain bg-gray-100"
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                          </div>
                        </a>
                        <div className="p-3 flex flex-col gap-2">
                          <div className="flex gap-2 justify-center">
                            <button
                              onClick={() => handleVerificar(archivo.id, 'APROBADO')}
                              disabled={loadingVerificacion === archivo.id || tieneVerificacion(archivo.id)}
                              className={`
                                w-12 h-12 flex items-center justify-center
                                rounded-full transition-colors
                                ${loadingVerificacion === archivo.id || tieneVerificacion(archivo.id)
                                  ? 'bg-gray-400 cursor-not-allowed opacity-50' 
                                  : 'bg-green-500 hover:bg-green-600 hover:scale-110 transform duration-200'}
                                text-white
                              `}
                              title={tieneVerificacion(archivo.id) ? "Ya está verificado" : "Aprobar archivo"}
                            >
                              {loadingVerificacion === archivo.id ? (
                                <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                              ) : (
                                <FaCheck className="h-5 w-5" />
                              )}
                            </button>
                            <button
                              onClick={() => handleVerificar(archivo.id, 'DESAPROBADO')}
                              disabled={loadingVerificacion === archivo.id || tieneVerificacion(archivo.id)}
                              className={`
                                w-12 h-12 flex items-center justify-center
                                rounded-full transition-colors
                                ${loadingVerificacion === archivo.id || tieneVerificacion(archivo.id)
                                  ? 'bg-gray-400 cursor-not-allowed opacity-50' 
                                  : 'bg-red-500 hover:bg-red-600 hover:scale-110 transform duration-200'}
                                text-white
                              `}
                              title={tieneVerificacion(archivo.id) ? "Ya está verificado" : "Desaprobar archivo"}
                            >
                              {loadingVerificacion === archivo.id ? (
                                <AiOutlineLoading3Quarters className="animate-spin h-5 w-5" />
                              ) : (
                                <FaTimes className="h-5 w-5" />
                              )}
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Sección de Pagos Asociados */}
          <div className="w-full border-t pt-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Pagos Asociados</h3>
            <div className="space-y-4">
              {!archivos.length || !archivos[0]?.orden_pago_id?.id ? (
                <p className="text-gray-500 text-sm text-center py-3 bg-gray-50 rounded-lg">
                  No hay pagos asociados
                </p>
              ) : pagos.length > 0 ? (
                <div className="grid grid-cols-1 gap-4 max-h-[400px] overflow-y-auto custom-scrollbar">
                  {pagos.map((pago) => (
                    <div key={pago.id} className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors">
                      <div className="space-y-2 text-sm">
                        <p className="text-gray-600">
                          <span className="font-medium text-gray-700">Fecha:</span>
                          <br />
                          {formatearFecha(pago.fecha_pago)}
                        </p>
                        <p className="text-gray-600">
                          <span className="font-medium text-gray-700">Monto:</span>
                          <br />
                          <span className="text-blue-600">
                            {pago.tipo_monedas === 'SOLES' ? 'S/.' : '$'} {pago.monto.toFixed(2)}
                          </span>
                        </p>
                        <p className="text-gray-600">
                          <span className="font-medium text-gray-700">Usuario:</span>{' '}
                          {pago.usuario_id.nombres}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 text-sm text-center py-3 bg-gray-50 rounded-lg">
                  No hay pagos registrados
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
      
      {toastConfig.show && (
        <Toast
          message={toastConfig.message}
          variant={toastConfig.variant}
          duration={3000}
          onClose={() => setToastConfig(prev => ({ ...prev, show: false }))}
          index={toastConfig.index}
          position="top-right"
          showCloseButton={true}
        />
      )}
    </>
  );
};

export default ComprobantesModal;