import React, { useState } from 'react';
import Button from '../../components/Buttons/Button';

interface CargoFormData {
  nombre: string;
  descripcion: string;
  gerarquia: number;
}

interface FormErrors {
  nombre?: string;
  descripcion?: string;
  gerarquia?: string;
}

interface FormComponentProps {
  initialValues?: CargoFormData;
  onSubmit: (data: CargoFormData) => void;
}

const CargoFormComponent: React.FC<FormComponentProps> = ({ initialValues, onSubmit }) => {
  const [formData, setFormData] = useState<CargoFormData>(
    initialValues || { nombre: '', descripcion: '', gerarquia: 1 }
  );
  const [errors, setErrors] = useState<FormErrors>({});

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    
    if (!formData.nombre.trim()) {
      newErrors.nombre = 'El nombre es requerido';
    }
    
    if (!formData.descripcion.trim()) {
      newErrors.descripcion = 'La descripción es requerida';
    }
    
    if (formData.gerarquia < 1 || formData.gerarquia > 4) {
      newErrors.gerarquia = 'La jerarquía debe estar entre 1 y 4';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'gerarquia' ? (value ? parseInt(value, 10) : 1) : value
    }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto"
    >
      <div className="mb-4">
        <label htmlFor="nombre" className="block text-gray-700 text-sm font-bold mb-2">
          Nombre:
        </label>
        <input
          id="nombre"
          name="nombre"
          type="text"
          placeholder="Nombre del cargo"
          value={formData.nombre}
          onChange={handleChange}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.nombre ? 'border-red-500' : ''
          }`}
        />
        {errors.nombre && (
          <p className="text-red-500 text-xs italic mt-1">{errors.nombre}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="descripcion" className="block text-gray-700 text-sm font-bold mb-2">
          Descripción:
        </label>
        <textarea
          id="descripcion"
          name="descripcion"
          placeholder="Descripción del cargo"
          value={formData.descripcion}
          onChange={handleChange}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-24 ${
            errors.descripcion ? 'border-red-500' : ''
          }`}
        />
        {errors.descripcion && (
          <p className="text-red-500 text-xs italic mt-1">{errors.descripcion}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="gerarquia" className="block text-gray-700 text-sm font-bold mb-2">
          Jerarquía:
        </label>
        <input
          id="gerarquia"
          name="gerarquia"
          type="number"
          min="1"
          max="4"
          placeholder="Jerarquía del cargo"
          value={formData.gerarquia}
          onChange={handleChange}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            errors.gerarquia ? 'border-red-500' : ''
          }`}
        />
        {errors.gerarquia && (
          <p className="text-red-500 text-xs italic mt-1">{errors.gerarquia}</p>
        )}
      </div>

      <div className="flex items-center justify-center mt-6">
        <Button
          text={initialValues ? 'Actualizar Cargo' : 'Crear Cargo'}
          color="verde"
          className="w-auto px-6 py-2 text-sm font-medium"
        />
      </div>
    </form>
  );
};

export default CargoFormComponent;