import React, { useState, useEffect } from 'react';

interface UnidadFormData {
  nombre: string;
  descripcion: string;
}

interface FormComponentProps {
  initialValues?: UnidadFormData;
  onSubmit: (data: UnidadFormData) => void;
}

const UnidadFormComponent: React.FC<FormComponentProps> = ({ initialValues, onSubmit }) => {
  const [formData, setFormData] = useState<UnidadFormData>({
    nombre: '',
    descripcion: ''
  });
  const [errors, setErrors] = useState({
    nombre: '',
    descripcion: ''
  });

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);

  const validateForm = (): boolean => {
    const newErrors = {
      nombre: '',
      descripcion: ''
    };

    if (!formData.nombre.trim()) {
      newErrors.nombre = 'El nombre es requerido';
    }
    if (!formData.descripcion.trim()) {
      newErrors.descripcion = 'La descripción es requerida';
    }

    setErrors(newErrors);
    return !newErrors.nombre && !newErrors.descripcion;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Limpiar error al escribir
    if (errors[name as keyof typeof errors]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto"
    >
      <div className="mb-4">
        <label htmlFor="nombre" className="block text-gray-700 text-sm font-bold mb-2">
          Nombre:
        </label>
        <input
          id="nombre"
          name="nombre"
          type="text"
          placeholder="Nombre de la unidad"
          value={formData.nombre}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        {errors.nombre && (
          <p className="text-red-500 text-xs italic mt-1">{errors.nombre}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="descripcion" className="block text-gray-700 text-sm font-bold mb-2">
          Descripción:
        </label>
        <textarea
          id="descripcion"
          name="descripcion"
          placeholder="Descripción de la unidad"
          value={formData.descripcion}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows={3}
        />
        {errors.descripcion && (
          <p className="text-red-500 text-xs italic mt-1">{errors.descripcion}</p>
        )}
      </div>

      <div className="flex items-center justify-center mt-6">
        <button
          type="submit"
          className="w-auto px-6 py-2 text-sm font-medium bg-green-500 text-white rounded-lg focus:outline-none"
          >
          {initialValues ? 'Actualizar Unidad' : 'Crear Unidad'}
        </button>
      </div>
    </form>
  );
};

export default UnidadFormComponent;