import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { FiRefreshCcw } from 'react-icons/fi';

// Importaciones de componentes
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Buttons/Button';
import RequerimientoRecursos from '../RequerimientosPage/CrearRequerimientoYRecursos/RequerimientoRecursos';
import KanbanColumn from './KanbanColumn';
import KanbanCardAprobacion from './KanbanCardAprobacion';
import KanbanCardLogistica from './KanbanCardLogistica';
import KanbanCardAlmacen from './KanbanCardAlmacen';
import KanbanCardOrdenCompra from './KanbanCardOrdenCompra';
import KanbanCardOrdenServicio from './KanbanCardOrdenServicio';
import KanbanColumnCotizacion from './KanbanColumnCotizacion';
import KanbanColumnOrdenServicio from './KanbanColumnOrdenServicio';

// Importaciones de tipos y servicios
import { Requerimiento } from './types/kanban';
import { RootState, AppDispatch } from '../../store/store';
import { fetchRequerimientos } from '../../slices/requerimientoSlice';
import { fetchCotizaciones } from '../../slices/cotizacionSlice';
import { fetchOrdenCompras } from '../../slices/ordenCompraSlice';

/**
 * Componente principal del Tablero Kanban
 * Maneja el flujo de trabajo de requerimientos y cotizaciones
 */
const KanbanBoard = () => {
  // Estados locales
  const [searchTerm, setSearchTerm] = useState('');
  const [modalNuevoRequerimiento, setModalNuevoRequerimiento] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  
  // Referencias y hooks
  const dispatch = useDispatch<AppDispatch>();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  
  // Selectores de Redux
  const user = useSelector((state: RootState) => state.user);
  const requerimientos: Requerimiento[] = useSelector((state: RootState) => state.requerimiento.requerimientos);
  const cotizaciones = useSelector((state: RootState) => 
    state.cotizacion.cotizaciones.map(cot => ({
      ...cot,
      fecha_solicitud: new Date().toISOString(),
      title: `Cotización ${cot.codigo_cotizacion}`,
      color: '#F7AA01'
    }))
  );
  const ordenCompras = useSelector((state: RootState) => state.ordenCompra.ordenCompras);

  // Función para cargar datos
  const cargarDatos = () => {
    dispatch(fetchCotizaciones());
    dispatch(fetchRequerimientos());
    dispatch(fetchOrdenCompras());
  };

  // Efectos
  useEffect(() => {
    cargarDatos();
  }, [dispatch]);

  useEffect(() => {
    // Manejar la visibilidad de los botones de scroll
    const checkOverflow = () => {
      if (scrollContainerRef.current) {
        const hasOverflow = scrollContainerRef.current.scrollWidth > scrollContainerRef.current.clientWidth;
        setShowButtons(hasOverflow);
      }
    };

    checkOverflow();
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (scrollContainerRef.current) {
      resizeObserver.observe(scrollContainerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  // Funciones auxiliares
  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const currentScroll = scrollContainerRef.current.scrollLeft;
      const targetScroll = direction === 'left'
        ? currentScroll - scrollAmount
        : currentScroll + scrollAmount;

      scrollContainerRef.current.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  const handleCloseModal = () => setModalNuevoRequerimiento(false);

  const handleRefresh = () => {
    cargarDatos();
  };

  // Filtrado y preparación de datos
  const filteredRequerimientos = requerimientos.filter(requerimiento =>
    requerimiento.codigo.toLowerCase().includes(searchTerm.toLowerCase()) ||
    requerimiento.sustento.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const cotizacionesAdjudicadas = cotizaciones.filter(cot => cot.estado === 'adjudicada');
  const ordenesCompraPendientes = ordenCompras.filter(oc => oc.estado === 'proceso');

  // Configuración de columnas
  const columnas = {
    aprobacionSupervisor: {
      id: 'aprobacion_supervisor',
      title: 'Aprobación del Supervisor',
      color: "#d86827",
      requerimiento: filteredRequerimientos
        .filter(req => req.estado_atencion === "pendiente")
        .sort((a, b) => {
          const aHasUser = a.aprobacion?.some(aprob => aprob.id_usuario === user.id) || false;
          const bHasUser = b.aprobacion?.some(aprob => aprob.id_usuario === user.id) || false;
          return aHasUser === bHasUser ? 0 : aHasUser ? -1 : 1;
        })
    },
    aprobacionGerencia: {
      id: 'aprobacion_gerencia',
      title: 'Aprobación de Gerencia',
      color: "#e2524a",
      requerimiento: filteredRequerimientos
        .filter(req => req.estado_atencion === "aprobado_supervisor")
        .sort((a, b) => {
          const aHasUser = a.aprobacion?.some(aprob => aprob.id_usuario === user.id) || false;
          const bHasUser = b.aprobacion?.some(aprob => aprob.id_usuario === user.id) || false;
          return aHasUser === bHasUser ? 0 : aHasUser ? -1 : 1;
        })
    },
    gestionLogistica: {
      id: 'gestion_logistica',
      title: 'Logística a Almacén',
      color: "#C84630",
      requerimiento: filteredRequerimientos.filter(req => req.estado_atencion === "aprobado_gerencia")
    },
    gestionAlmacen: {
      id: 'gestion_almacen',
      title: 'Almacén y traslados',
      color: "#a2122f",
      requerimiento: filteredRequerimientos.filter(req => req.estado_atencion === "aprobado_logistica")
    },
    gestionOrdenCompra: {
      id: 'gestion_orden_compra',
      title: 'Aprobar Orden de Compra',
      color: "#F7AA01",
      cotizacion: cotizacionesAdjudicadas,
    },
    gestionOrdenServicio: {
      id: 'gestion_orden_servicio',
      title: 'Validar Orden de Servicio',
      color: "#4A90E2",
      ordenservicio: ordenesCompraPendientes,
    }
  };

  return (
    <div className="p-4 relative">
      {/* Barra de herramientas superior */}
      <div className="mb-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Buscar tareas..."
          className="p-2 border rounded-lg w-64"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button 
          text='Actualizar' 
          color='blanco' 
          onClick={handleRefresh} 
          className="rounded w-auto" 
          icon={<FiRefreshCcw className="text-green-500 text-center h-3 w-3" />} 
        />
      </div>

      {/* Contenedor principal del Kanban */}
      <div className="relative">
        {/* Botones de navegación */}
        {showButtons && (
          <>
            <button
              onClick={() => scroll('left')}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-black/20 hover:bg-black/30 text-white p-3 rounded-r-lg backdrop-blur-sm transition-all"
            >
              ◀
            </button>
            <button
              onClick={() => scroll('right')}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-black/20 hover:bg-black/30 text-white p-3 rounded-l-lg backdrop-blur-sm transition-all"
            >
              ▶
            </button>
          </>
        )}

        {/* Contenedor scrolleable de columnas */}
        <div
          ref={scrollContainerRef}
          className="flex overflow-x-auto space-x-4 max-h-[calc(100vh-14rem)] h-full scroll-smooth snap-x snap-mandatory"
        >
          {/* Columnas del Kanban */}
          <div className="snap-center min-w-[240px] min-h-[calc(100vh-15rem)]">
            <KanbanColumn column={columnas.aprobacionSupervisor} CardComponent={KanbanCardAprobacion} />
          </div>
          <div className="snap-center min-w-[240px] min-h-[calc(100vh-15rem)]">
            <KanbanColumn column={columnas.aprobacionGerencia} CardComponent={KanbanCardAprobacion} />
          </div>
          <div className="snap-center min-w-[240px] min-h-[calc(100vh-15rem)]">
            <KanbanColumn column={columnas.gestionLogistica} CardComponent={KanbanCardLogistica} />
          </div>
          <div className="snap-center min-w-[240px] min-h-[calc(100vh-15rem)]">
            <KanbanColumn column={columnas.gestionAlmacen} CardComponent={KanbanCardAlmacen} />
          </div>
          <div className="snap-center min-w-[240px] min-h-[calc(100vh-15rem)]">
            <KanbanColumnCotizacion columna={columnas.gestionOrdenCompra} CardComponentC={KanbanCardOrdenCompra} />
          </div>
          <div className="snap-center min-w-[240px] min-h-[calc(100vh-15rem)]">
            <KanbanColumnOrdenServicio columna={columnas.gestionOrdenServicio} CardComponentS={KanbanCardOrdenServicio} />
          </div>
        </div>
      </div>

      {/* Modal para nuevo requerimiento */}
      {modalNuevoRequerimiento && (
        <Modal
          title='Crear Requerimiento'
          isOpen={modalNuevoRequerimiento}
          onClose={handleCloseModal}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
          >
            <RequerimientoRecursos
              initialValues={{
                id: '',
                usuario_id: '',
                usuario: '',
                presupuesto_id: '',
                fecha_solicitud: '',
                estado_atencion: '',
                sustento: '',
                obra_id: '',
                fecha_final: '',
                codigo: ''
              }}
              onClose={handleCloseModal}
            />
          </motion.div>
        </Modal>
      )}
    </div>
  );
};

export default KanbanBoard;
