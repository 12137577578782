import { gql } from '@apollo/client';
import client from '../apolloClient';

const GET_COMPOSICIONES_APU_BY_TITULO_QUERY = gql`
  query GetComposicionesApuByTitulo($idTitulo: String!, $idProyecto: String!) {
    getComposicionesApuByTitulo(id_titulo: $idTitulo, id_proyecto: $idProyecto) {
      id_composicion_apu
      id_titulo
      id_rec_comp_apu
      rec_comp_apu {
        id_rec_comp_apu
        recurso_id
        unidad_id
        nombre
        especificaciones
        descripcion
        fecha_creacion
        recurso {
          id
          recurso_id
          codigo
          nombre
          descripcion
          fecha
          cantidad
          unidad_id
          precio_actual
          vigente
          tipo_recurso_id
          tipo_costo_recurso_id
          clasificacion_recurso_id
          imagenes {
            id
            file
          }
        }
        unidad {
          id
          unidad_id
          nombre
          descripcion
        }
      }
      cuadrilla
      cantidad
      fecha_creacion
      precio {
        id_prp
        id_rec_comp_apu
        precio
        fecha_creacion
        id_proyecto
      }
    }
  }
`;

const ADD_COMPOSICION_APU_MUTATION = gql`
  mutation AddComposicionApu($idTitulo: String!, $idRecCompApu: String!, $cuadrilla: Float!, $cantidad: Float!, $idProyecto: String!) {
    addComposicionApu(id_titulo: $idTitulo, id_rec_comp_apu: $idRecCompApu, cuadrilla: $cuadrilla, cantidad: $cantidad, id_proyecto: $idProyecto) {
      id_composicion_apu
      id_titulo
      id_rec_comp_apu
      rec_comp_apu {
        id_rec_comp_apu
        recurso_id
        unidad_id
        nombre
        especificaciones
        descripcion
        fecha_creacion
        recurso {
          id
          recurso_id
          codigo
          nombre
          descripcion
          fecha
          cantidad
          unidad_id
          precio_actual
          vigente
          tipo_recurso_id
          tipo_costo_recurso_id
          clasificacion_recurso_id
          imagenes {
            id
            file
          }
        }
        unidad {
          id
          unidad_id
          nombre
          descripcion
        }
      }
      cuadrilla
      cantidad
      fecha_creacion
      precio {
        id_prp
        id_rec_comp_apu
        precio
        fecha_creacion
        id_proyecto
      }
    }
  }
`;

const UPDATE_COMPOSICION_APU_MUTATION = gql`
  mutation UpdateComposicionApu($idComposicionApu: String!, $cuadrilla: Float, $cantidad: Float) {
    updateComposicionApu(id_composicion_apu: $idComposicionApu, cuadrilla: $cuadrilla, cantidad: $cantidad) {
      _id
      id_composicion_apu
      id_titulo
      id_rec_comp_apu
      rec_comp_apu {
        id_rec_comp_apu
        recurso_id
        unidad_id
        nombre
        especificaciones
        descripcion
        fecha_creacion
      }
      cuadrilla
      cantidad
      fecha_creacion
      precio {
        id_prp
        id_proyecto
        id_rec_comp_apu
        precio
        fecha_creacion
      }
    }
  }
`;

const DELETE_COMPOSICION_APU_MUTATION = gql`
  mutation DeleteComposicionApu($id_composicion_apu: String!) {
    deleteComposicionApu(id_composicion_apu: $id_composicion_apu) {
      id_composicion_apu
    }
  }
`;

export const getComposicionesApuByTituloService = async (id_titulo: string, id_proyecto: string) => {
  const { data } = await client.query({
    query: GET_COMPOSICIONES_APU_BY_TITULO_QUERY,
    variables: { idTitulo: id_titulo, idProyecto: id_proyecto },
    fetchPolicy: 'network-only' // Forzar a buscar desde el servidor
  });
  return data.getComposicionesApuByTitulo;
};

export const addComposicionApuService = async (data: {
  id_titulo: string;
  id_rec_comp_apu: string;
  cuadrilla: number;
  cantidad: number;
  id_proyecto: string; // Nuevo campo añadido
}) => {
  const response = await client.mutate({
    mutation: ADD_COMPOSICION_APU_MUTATION,
    variables: {
      idTitulo: data.id_titulo,
      idRecCompApu: data.id_rec_comp_apu,
      cuadrilla: data.cuadrilla,
      cantidad: data.cantidad,
      idProyecto: data.id_proyecto // Nueva variable añadida
    },
    errorPolicy: 'all',
  });

  if (!response.data?.addComposicionApu) {
    throw new Error('No se recibieron datos de la mutación');
  }

  return response.data.addComposicionApu;
};

export const updateComposicionApuService = async (data: {
  id_composicion_apu: string;
  cuadrilla?: number;
  cantidad?: number;
}) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_COMPOSICION_APU_MUTATION,
      variables: {
        idComposicionApu: data.id_composicion_apu,
        cuadrilla: data.cuadrilla,
        cantidad: data.cantidad
      },
    });
    return response.data.updateComposicionApu;
  } catch (error) {
    throw new Error(`Error updating composición APU: ${error}`);
  }
};

export const deleteComposicionApuService = async (id_composicion_apu: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_COMPOSICION_APU_MUTATION,
      variables: { id_composicion_apu },
    });
    return response.data.deleteComposicionApu;
  } catch (error) {
    throw new Error(`Error deleting composición APU: ${error}`);
  }
};
