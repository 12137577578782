import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store/store';
import { fetchRequerimientoRecursos, deleteRequerimientoRecurso, updateRequerimientoRecurso, RequerimientoRecurso} from '../../../slices/requerimientoRecursoSlice';
import LoaderPage from '../../../components/Loader/LoaderPage';
import { FiCheckSquare, FiEdit2, FiTrash2, FiX, FiEye } from 'react-icons/fi';
import { formatCurrency } from '../../../components/Utils/priceFormatUtils';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

// Actualizar la interfaz
interface SelectedProductsProps {
  requerimiento_id: string;
  fecha_final: Date;
  onSelectPartida: (partidaId: string) => void;
  onRecursosChange: (hasZeroPrices: boolean, hasZeroQuantities: boolean, isEmpty: boolean) => void; // Actualización de prop
}

export const SelectedProducts: React.FC<SelectedProductsProps> = ({ 
  requerimiento_id, 
  fecha_final,
  onSelectPartida,
  onRecursosChange 
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { requerimientoRecursos, loading } = useSelector((state: RootState) => state.requerimientoRecurso);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editValues, setEditValues] = useState({ cantidad: '', notas: '', precio: '', id_partida: '', precio_partida: '' });
  const partidas = useSelector((state: RootState) => state.titulo.titulos);

  const handleDeleteRecurso = async (recursoId: string) => {
    try {
      await dispatch(deleteRequerimientoRecurso(recursoId)).unwrap();
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleEdit = (recurso: RequerimientoRecurso) => {
    setEditingId(recurso.id);
    setEditValues({
      cantidad: recurso.cantidad.toString(),
      notas: recurso.notas || '',
      precio: (recurso.precio || 0).toString(),
      id_partida: recurso.id_partida || '',
      precio_partida: (recurso.precio_partida || 0).toString()
    });
  };

  const handleUpdate = async (recursoId: string) => {
    try {
      await dispatch(updateRequerimientoRecurso({
        id: recursoId,
        cantidad_aprobada: Number(editValues.cantidad),
        cantidad: Number(editValues.cantidad),
        notas: editValues.notas,
        precio: Number(editValues.precio),
        fecha_limit: fecha_final,
        id_partida: editValues.id_partida,
        precio_partida: Number(editValues.precio_partida)
      })).unwrap();
      setEditingId(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditValues({ cantidad: '', notas: '', precio: '', id_partida: '', precio_partida: '' });
  };

  // Modificar la función handleViewPartidaDetails
  const handleViewPartidaDetails = (partidaId: string) => {
    onSelectPartida(partidaId);
  };

  const calculateTotalReferencial = () => {
    return requerimientoRecursos.reduce((total, recurso) => {
      return total + ((recurso.costo_ref || 0) * recurso.cantidad);
    }, 0).toFixed(2);
  };
  const calculateTotalPartida = () => {
    return requerimientoRecursos.reduce((total, recurso) => {
      return total + ((recurso.precio_partida || 0) * recurso.cantidad);
    }, 0).toFixed(2);
  };
  const calculateTotalPrecio = () => {
    return requerimientoRecursos.reduce((total, recurso) => {
      return total + ((recurso.precio || 0) * recurso.cantidad);
    }, 0).toFixed(2);
  };

  useEffect(() => {
    if (requerimiento_id) dispatch(fetchRequerimientoRecursos(requerimiento_id));
  }, [dispatch, requerimiento_id]);
  
  // Nuevo useEffect para verificar recursos
  useEffect(() => {
    // Verificar si hay al menos un recurso con precio 0
    const hasZeroPrices = requerimientoRecursos.some(recurso => (recurso.precio === 0 || recurso.precio === null));
    
    // Verificar si hay al menos un recurso con cantidad 0 o menor
    const hasZeroQuantities = requerimientoRecursos.some(recurso => (recurso.cantidad <= 0));
    
    // Verificar si no hay recursos
    const isEmpty = requerimientoRecursos.length === 0;
    
    // Informar al componente padre
    onRecursosChange(hasZeroPrices, hasZeroQuantities, isEmpty);
  }, [requerimientoRecursos, onRecursosChange]);

  if (loading) {
    return <LoaderPage />;    
  }
  
  return (
    <div className="bg-gray-100 shadow-lg rounded-lg w-full sticky flex flex-col h-full border border-gray-200 p-6">
      <h2 className="text-lg font-semibold mb-4 text-[#1a73e8] border-b pb-2">
        Productos Seleccionados
      </h2>
      <div className="flex-1 overflow-y-auto min-h-0 w-full">
        {requerimientoRecursos.length === 0 ? (
          <div className="text-center py-8 text-gray-500 text-sm bg-gray-50 rounded-lg">
            No hay productos seleccionados
          </div>
        ) : (
          <div className="overflow-x-auto rounded-lg w-full">
            <table className="w-full text-sm bg-white border border-gray-200 rounded-lg">
              <thead>
                <tr className="bg-gray-50">
                  <th className="p-2 text-xs text-left text-gray-700 font-semibold w-24">Código</th>
                  <th className="p-2 text-xs text-left text-gray-700 font-semibold w-1/4">Nombre</th>
                  <th className="p-2 text-xs text-left text-gray-700 font-semibold w-20">Unidad</th>
                  <th className="p-2 text-xs text-center text-gray-700 font-semibold w-12">Cant.</th>
                  <th className="p-2 text-xs text-center text-gray-700 font-semibold w-32">Precio Ref.</th>
                  <th className="p-2 text-xs text-center text-gray-700 font-semibold w-32">Precio Part.</th>
                  <th className="p-2 text-xs text-center text-gray-700 font-semibold w-32">Precio</th>
                  <th className="p-2 text-xs text-left text-gray-700 font-semibold">Notas</th>
                  <th className="p-2 text-xs text-left text-gray-700 font-semibold w-32">Partida</th>
                  <th className="p-2 text-xs text-center text-gray-700 font-semibold w-24">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {requerimientoRecursos.map(recurso => (
                  <tr key={recurso.id} className="border-t hover:bg-blue-50 transition-colors duration-150">
                    <td className="p-2 text-xs">{recurso.codigo}</td>
                    <td className="p-2 text-xs">{recurso.nombre}</td>
                    <td className="p-2 text-xs text-center">{recurso.unidad}</td>
                    <td className="p-2 text-xs text-center">
                      {editingId === recurso.id ? (
                        <input
                          type="number"
                          min={0}
                          step={0.5}
                          value={editValues.cantidad}
                          onChange={(e) => setEditValues({...editValues, cantidad: e.target.value})}
                          onFocus={(e) => e.target.value === '0' && setEditValues({...editValues, cantidad: ''})}
                          className="w-16 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-500"
                        />
                      ) : recurso.cantidad}
                    </td>
                    <td className="p-2 text-xs text-center">
                      {formatCurrency(recurso.costo_ref || 0)}
                    </td>
                    <td className="p-2 text-xs text-center">
                      {formatCurrency(recurso.precio_partida) || "-"}
                    </td>
                    <td className="p-2 text-xs text-center">
                      {editingId === recurso.id ? (
                        <input
                          type="number"
                          min={1}
                          value={editValues.precio}
                          onChange={(e) => setEditValues({...editValues, precio: e.target.value})}
                          className="w-16 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-500"
                        />
                      ) : <span data-tooltip-id="tooltip"
                      data-tooltip-content="Debes de ingresar un Precio" className={`${recurso.precio === 0 && "text-red-500"}`}>{formatCurrency(recurso.precio || 0)}</span>}
                    </td>
                    <td className="p-2 text-xs text-left">
                      {editingId === recurso.id ? (
                        <input
                          type="text"
                          value={editValues.notas}
                          onChange={(e) => setEditValues({...editValues, notas: e.target.value})}
                          className="w-16 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-500"
                        />
                      ) : recurso.notas}
                    </td>
                    <td className="p-2 text-xs">
                      {editingId === recurso.id ? (
                        <select
                          value={editValues.id_partida}
                          onChange={(e) => setEditValues({...editValues, id_partida: e.target.value})}
                          className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-500 text-xs"
                        >
                          <option value="">Seleccione una partida</option>
                          {partidas.map((partida) => (
                            <option key={partida.id_titulo} value={partida.id_titulo}>
                              {partida.id_titulo} - {partida.descripcion} - {partida.item}
                            </option>
                          ))}
                        </select>
                      ) : (
                        partidas.find(p => p.id_titulo === recurso.id_partida)?.id_titulo || '-'
                      )}
                    </td>
                    <td className="p-2 text-xs">
                      <div className="flex gap-2 justify-center items-center">
                        {editingId === recurso.id ? (
                          <>
                            <button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Guardar"
                              onClick={() => handleUpdate(recurso.id)}
                              className="p-1.5 text-green-600 hover:bg-green-100 rounded-full transition-colors"
                            >
                              <FiCheckSquare size={16} />
                            </button>
                            <button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Cancelar"
                              onClick={handleCancelEdit}
                              className="p-1.5 text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
                            >
                              <FiX size={16} />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Editar"
                              onClick={() => handleEdit(recurso)}
                              className="py-1.5 text-blue-600 hover:bg-blue-100 rounded-full transition-colors"
                            >
                              <FiEdit2 size={16} />
                            </button>
                            <button
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Eliminar"
                              onClick={() => handleDeleteRecurso(recurso.id)}
                              className="py-1.5 text-red-600 hover:bg-red-100 rounded-full transition-colors"
                            >
                              <FiTrash2 size={16} />
                            </button>
                            { (
                              <button
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Ver detalles de partida"
                                disabled={!recurso.id_partida}
                                onClick={() => handleViewPartidaDetails(recurso.id_partida)}
                                className="py-1.5 disabled:text-gray-300 text-emerald-600 hover:bg-emerald-100 rounded-full transition-colors"
                              >
                                <FiEye size={16} />
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="sticky bottom-0 bg-gray-50 font-semibold border-t">
                <tr>
                  <td colSpan={4} className="p-2 text-xs text-right">Total:</td>
                  <td colSpan={1} className="p-2 text-xs text-right font-bold text-blue-600">
                    S/.{formatCurrency( Number(calculateTotalReferencial()))}
                  </td>
                  <td colSpan={1} className="p-2 text-xs text-right font-bold text-blue-600">
                    S/.{formatCurrency( Number(calculateTotalPartida()))}
                  </td>
                  <td colSpan={1} className="p-2 text-xs text-right font-bold text-blue-600">
                    S/.{formatCurrency( Number(calculateTotalPrecio()))}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
      </div>
      <Tooltip id="tooltip" />
    </div>
  );
};