import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { RootState, AppDispatch } from '../../../store/store';
import { useAPUCalculations, calcularTotal } from './hooks/useAPUCalculations';
import { CompositionProps, IComposicionApu, ITitulo } from './types/apu.types';
import { PendingChangesMap, PendingChange, UpdatePrecioInput, UpdateComposicionInput } from './types/apu.actions';
import { deleteComposicionApu, getComposicionesApuByTitulo, updateComposicionApu } from '../../../slices/composicionApuSlice';
import { actualizarPrecioRecursoProyecto } from '../../../slices/precioRecursoProyectoSlice';
import { updateDetallePartida } from '../../../slices/detallePartidaSlice';
import APUHeader from './components/APUHeader';
import APUTable from './components/APUTable';
import APUFooter from './components/APUFooter';
import Modal from '../../../components/Modal/Modal';
import CatalogoRecursos from './CatalogoRecursos';
import ModalAlert from '../../../components/Modal/ModalAlert';
import LoaderOverlay from '../../../components/Loader/LoaderOverlay';
import { getTitulosByPresupuesto } from '../../../slices/tituloSlice';
import useAPUCalculator, { CalculationValues } from './hooks/useAPUCalculator';
import { updateActiveTitulo } from '../../../slices/activeDataSlice';
import { ApiResponse } from './types/updateResults.types';
import APUHeaderNoPartida from './components/APUHeaderNoPartida';

const isValueDifferent = (newValue: number | undefined, originalValue: number | undefined): boolean => {
  if (newValue === undefined || originalValue === undefined) return false;
  // Comparar con una pequeña tolerancia para números decimales
  return Math.abs(newValue - originalValue) > 0.0001;
};

// Reemplazar la función calculateMOPrice con esta nueva versión
const calculateMOPrice = (composiciones: IComposicionApu[], pendingChanges: PendingChangesMap): number => {
  // Filtramos solo recursos de mano de obra (hh)
  const moSubtotal = composiciones
    .filter(comp => {
      const tipoMO = comp.rec_comp_apu?.recurso?.tipo_costo_recurso_id;
      const tiposMO = "679d17a98ac9bee6519f5d4e";
      console.log("tipoMO",tipoMO, comp.rec_comp_apu?.recurso?.nombre )
      return  tipoMO === tiposMO;
    })
    .reduce((total, comp) => {
      const changes = pendingChanges.get(comp.id_composicion_apu) || {};
      const cantidad = changes.cantidad !== undefined ? changes.cantidad : comp.cantidad || 0;
      const precio = changes.precio !== undefined ? changes.precio : comp.precio?.precio || 0;
      return total + (cantidad * precio);
    }, 0);
    
  return moSubtotal / 100;
};

const APU: React.FC<CompositionProps> = ({ className, composiciones }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [pendingChanges, setPendingChanges] = useState<PendingChangesMap>(new Map());
  const [detallePartidaChanges, setDetallePartidaChanges] = useState<{
    jornada?: number;
    rendimiento?: number;
  }>({});
  const [isBadCalcAlertOpen, setIsBadCalcAlertOpen] = useState(false);
  const [badCalcResources, setBadCalcResources] = useState<string[]>([]);
  // Nuevo estado para el modal de error de validación
  const [validationError, setValidationError] = useState<{isOpen: boolean, message: string}>({
    isOpen: false,
    message: ""
  });

  const activeTitulo = useSelector((state: RootState) => state.activeData.activeTitulo) as ITitulo | null;
  const titulos = useSelector((state: RootState) => state.titulo.titulos);
  const { activeProyecto, activePresupuesto } = useSelector((state: RootState) => state.activeData);
  const { calcularSubtotalPorTipo } = useAPUCalculations(composiciones, activeTitulo);
  const dispatch = useDispatch<AppDispatch>();
  const { updateValues, verifyCalculation } = useAPUCalculator();

  const handleAddResource = () => {
    if (!activeTitulo?.detallePartida?.id_detalle_partida) {
      setIsAlertOpen(true);
      return;
    }
    setIsModalOpen(true);
  };

  const handleDelete = async (composicion: IComposicionApu) => {
    try {
      setIsLoading(true);
      await dispatch(deleteComposicionApu(composicion.id_composicion_apu));
    } catch (error) {
      console.error('❌ Error al eliminar la composición:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const recalcAllCompositions = (updatedJornada?: number, updatedRendimiento?: number) => {
    const newChanges = new Map(pendingChanges);
    
    // Calcular el nuevo precio MO basado en el subtotal actual
    const moPrice = calculateMOPrice(composiciones, newChanges);
    
    // Solo actualizar recursos %mo con el nuevo precio
    composiciones.forEach((comp) => {
      const prevChanges = newChanges.get(comp.id_composicion_apu) || {};
      
      if (comp.rec_comp_apu?.unidad?.nombre === "%mo") {
        if (isValueDifferent(moPrice, comp.precio?.precio)) {
          newChanges.set(comp.id_composicion_apu, {
            ...prevChanges,
            precio: moPrice
          });
        }
      }
      
      // Valores base
      const currentValues = {
        jornada: updatedJornada ?? activeTitulo?.detallePartida?.jornada ?? 0,
        cuadrilla: comp.cuadrilla ?? 0,
        rendimiento: updatedRendimiento ?? activeTitulo?.detallePartida?.rendimiento ?? 0,
        cantidad: comp.cantidad ?? 0
      };
  
      // Si se cambió Jornada o Rendimiento, recalcular
      if (updatedJornada !== undefined || updatedRendimiento !== undefined) {
        const result = updateValues(currentValues, 'jornada', currentValues.jornada);
        
        // Solo guardar cambios si son diferentes de los valores originales
        const changes: PendingChange = {};
        
        if (isValueDifferent(result.cuadrilla, comp.cuadrilla)) {
          changes.cuadrilla = result.cuadrilla;
        }
        
        if (isValueDifferent(result.cantidad, comp.cantidad)) {
          changes.cantidad = result.cantidad;
        }
        
        // Mantener el precio si ya existía
        if (prevChanges.precio !== undefined) {
          changes.precio = prevChanges.precio;
        }
  
        // Solo agregar al mapa si hay cambios reales
        if (Object.keys(changes).length > 0) {
          newChanges.set(comp.id_composicion_apu, changes);
        }
      }
    });
  
    setPendingChanges(newChanges);
  };

  const updateMOPrices = (currentPendingChanges: PendingChangesMap): PendingChangesMap => {
    const newChanges = new Map(currentPendingChanges);
    const moPrice = calculateMOPrice(composiciones, currentPendingChanges);
  
    composiciones.forEach((comp) => {
      if (comp.rec_comp_apu?.unidad?.nombre === "%mo") {
        const prevChanges = newChanges.get(comp.id_composicion_apu) || {};
        if (isValueDifferent(moPrice, comp.precio?.precio)) {
          newChanges.set(comp.id_composicion_apu, {
            ...prevChanges,
            precio: moPrice
          });
        }
      }
    });
  
    return newChanges;
  };
  
  const handleEdit = (composicion: IComposicionApu, field: string, value: number) => {
    // Validar valores negativos
    if (value < 0) {
      // Para precio, cantidad y cuadrilla no permitimos valores negativos
      if (field === 'precio' || field === 'cantidad' || field === 'cuadrilla') {
        value = 0;
      }
    }
    
    console.log('🛠 Editando composición:', {
      nombre: composicion.rec_comp_apu?.nombre,
      tipo: composicion.rec_comp_apu?.recurso?.tipo_costo_recurso_id,
      field,
      value,
      unidad: composicion.rec_comp_apu?.unidad?.nombre
    });
    
    if (field === 'cuadrilla') {
      const unidad = composicion.rec_comp_apu?.unidad?.nombre?.toLowerCase();
      if (unidad !== 'hh' && unidad !== 'hm') {
        console.log('❌ No se permite editar cuadrilla para este tipo de unidad:', unidad);
        return;
      }
    }
  
    const prevChanges = pendingChanges.get(composicion.id_composicion_apu) || {};
    const currentValues = {
      jornada: detallePartidaChanges.jornada ?? activeTitulo?.detallePartida?.jornada ?? 0,
      cuadrilla: prevChanges.cuadrilla ?? composicion.cuadrilla ?? 0,
      rendimiento: detallePartidaChanges.rendimiento ?? activeTitulo?.detallePartida?.rendimiento ?? 0,
      cantidad: prevChanges.cantidad ?? composicion.cantidad ?? 0
    };
  
    if (field === 'precio') {
      setPendingChanges(prev => {
        const newMap = new Map(prev);
        const currentChanges = { ...newMap.get(composicion.id_composicion_apu) };
  
        if (isValueDifferent(value, composicion.precio?.precio)) {
          currentChanges.precio = value;
          newMap.set(composicion.id_composicion_apu, currentChanges);
        } else {
          delete currentChanges.precio;
          if (Object.keys(currentChanges).length === 0) {
            newMap.delete(composicion.id_composicion_apu);
          } else {
            newMap.set(composicion.id_composicion_apu, currentChanges);
          }
        }
        
        // Actualizar precios %mo después de cualquier cambio de precio
        return updateMOPrices(newMap);
      });
      return;
    }
  
    const result = updateValues(currentValues, field as keyof CalculationValues, value);
  
    setPendingChanges(prev => {
      const newMap = new Map(prev);
      const changes: PendingChange = {};
  
      if (isValueDifferent(result.cuadrilla, composicion.cuadrilla)) {
        changes.cuadrilla = result.cuadrilla;
      }
      if (isValueDifferent(result.cantidad, composicion.cantidad)) {
        changes.cantidad = result.cantidad;
      }
      if (prevChanges.precio !== undefined) {
        changes.precio = prevChanges.precio;
      }
  
      if (Object.keys(changes).length > 0) {
        newMap.set(composicion.id_composicion_apu, changes);
      } else {
        newMap.delete(composicion.id_composicion_apu);
      }
  
      // Actualizar precios %mo después de cualquier cambio
      return updateMOPrices(newMap);
    });
  };

  const handleEditDetallePartida = (field: 'jornada' | 'rendimiento', value: number) => {    
    // Asegurar que no se establezcan valores negativos
    if (value < 0) {
      value = 0;
    }
    
    setDetallePartidaChanges(prev => ({
      ...prev,
      [field]: value
    }));
    
    recalcAllCompositions(
      field === 'jornada' ? value : detallePartidaChanges.jornada ?? activeTitulo?.detallePartida?.jornada,
      field === 'rendimiento' ? value : detallePartidaChanges.rendimiento ?? activeTitulo?.detallePartida?.rendimiento
    );
  };

  const isSuccessfulResponse = (result: ApiResponse): boolean => {
    if (!result) return false;
    
    if ('success' in result) {
      return result.success;
    }
    if ('id_detalle_partida' in result) {
      return true;
    }
    if ('_id' in result) {
      return true;
    }
    return false;
  };

  // Función para validar que todos los valores sean positivos
  const validatePositiveValues = (): {isValid: boolean, message: string} => {
    // Validar rendimiento
    const rendimiento = detallePartidaChanges.rendimiento ?? activeTitulo?.detallePartida?.rendimiento;
    if (!rendimiento || rendimiento <= 0) {
      return {
        isValid: false,
        message: "El rendimiento no puede ser cero o negativo"
      };
    }

    // Validar jornada
    const jornada = detallePartidaChanges.jornada ?? activeTitulo?.detallePartida?.jornada;
    if (!jornada || jornada <= 0) {
      return {
        isValid: false, 
        message: "La jornada no puede ser cero o negativa"
      };
    }

    // Validar cantidades en composiciones
    const invalidCompositions: string[] = [];
    composiciones.forEach(comp => {
      const changes = pendingChanges.get(comp.id_composicion_apu) || {};
      const cantidad = changes.cantidad !== undefined ? changes.cantidad : comp.cantidad;
      const precio = changes.precio !== undefined ? changes.precio : comp?.precio?.precio;
      const cuadrilla = changes.cuadrilla !== undefined ? changes.cuadrilla : comp.cuadrilla;
      
      if (cantidad !== undefined && cantidad <= 0) {
        invalidCompositions.push(`${comp.rec_comp_apu?.recurso?.nombre || 'Recurso'} (cantidad)`);
      }
      if (precio !== undefined && precio < 0) {
        invalidCompositions.push(`${comp.rec_comp_apu?.recurso?.nombre || 'Recurso'} (precio)`);
      }
      if (cuadrilla !== undefined && cuadrilla < 0) {
        invalidCompositions.push(`${comp.rec_comp_apu?.recurso?.nombre || 'Recurso'} (cuadrilla)`);
      }
    });

    if (invalidCompositions.length > 0) {
      return {
        isValid: false,
        message: `Los siguientes recursos tienen valores negativos o cero: ${invalidCompositions.join(', ')}`
      };
    }

    return { isValid: true, message: "" };
  };

  const handleSaveChanges = async () => {
    console.log("💡 Iniciando guardado de cambios");
    
    // Validar valores antes de guardar
    const validation = validatePositiveValues();
    if (!validation.isValid) {
      setValidationError({
        isOpen: true,
        message: validation.message
      });
      return;
    }
    
    try {
      setIsLoading(true);
      // Eliminamos esta línea para que no se oculte el encabezado
      // setIsHeaderReady(false); 

      const updates = {
        precios: [] as UpdatePrecioInput[],
        composiciones: [] as UpdateComposicionInput[]
      };

      // Separar cambios por tipo
      pendingChanges.forEach((changes, id) => {
        const composicion = composiciones.find(c => c.id_composicion_apu === id);
        if (!composicion || Object.keys(changes).length === 0) return;

        const { precio, ...otherChanges } = changes;
        const isPorcentajeMO = composicion.rec_comp_apu?.unidad?.nombre === "%mo";

        if (precio !== undefined && composicion.precio) {
          // Para recursos %mo
          if (isPorcentajeMO) {
            // Solo agregar actualización si el precio original no era cero
            if (composicion.precio.precio !== 0.01) {
              updates.precios.push({
                idPrp: composicion.precio.id_prp,
                precio: 0.01 // Forzar precio a cero para %mo
              });
            }
          } else {
            // Para recursos que no son %mo, proceder normalmente
            if (isValueDifferent(precio, composicion.precio.precio)) {
              updates.precios.push({
                idPrp: composicion.precio.id_prp,
                precio
              });
            }
          }
        }

        if (Object.keys(otherChanges).length > 0) {
          updates.composiciones.push({
            ...composicion,
            ...otherChanges
          });
        }
      });

      console.log("🎯 updates:", updates);

      // Calcular el nuevo total usando un mapeo tipado
      const composicionesConCambios = composiciones.map(comp => {
        const changes = pendingChanges.get(comp.id_composicion_apu);
        if (!changes) return comp;

        return {
          ...comp,
          cantidad: changes.cantidad ?? comp.cantidad,
          cuadrilla: changes.cuadrilla ?? comp.cuadrilla,
          precio: changes.precio ? {
            ...comp.precio,
            precio: changes.precio
          } : comp.precio
        };
      });

      console.log("🎯 composicionesConCambios:", composicionesConCambios);
      const nuevoTotal = calcularTotal(composicionesConCambios);
      console.log("🛠 Nuevo total calculado:", nuevoTotal);

      // Verificar cálculos
      const badCalcs: string[] = [];
      composicionesConCambios.forEach((comp) => {
        const checkVals = {
          jornada: detallePartidaChanges.jornada ?? activeTitulo?.detallePartida?.jornada ?? 0,
          cuadrilla: comp.cuadrilla ?? 0,
          rendimiento: detallePartidaChanges.rendimiento ?? activeTitulo?.detallePartida?.rendimiento ?? 0,
          cantidad: comp.cantidad ?? 0
        };
        console.log("🔎 Verificando cálculos:", comp.id_composicion_apu, checkVals);
        if (!verifyCalculation(checkVals)) {
          console.log("❌ Cálculo inválido para:", comp.id_composicion_apu);
          badCalcs.push(String(comp.id_composicion_apu));
        }
      });

      if (badCalcs.length > 0) {
        setBadCalcResources(badCalcs);
        setIsBadCalcAlertOpen(true);
        setIsLoading(false);
        return;
      }

      // Preparar actualización del detalle partida con todos los cambios
      const detallePartidaUpdate = activeTitulo?.detallePartida && {
        ...activeTitulo.detallePartida,
        ...detallePartidaChanges,
        precio: nuevoTotal // Incluir el nuevo total
      };

      // Ejecutar actualizaciones en paralelo
      const updateResults = await Promise.all([
        // Actualizar DetallePartida con todos los cambios
        detallePartidaUpdate
          ? dispatch(updateDetallePartida(detallePartidaUpdate)).unwrap()
          : Promise.resolve({ success: true }),

        // Actualizar precios
        ...updates.precios.map(update =>
          dispatch(actualizarPrecioRecursoProyecto(update)).unwrap()
        ),

        // Actualizar composiciones
        ...updates.composiciones.map(update =>
          dispatch(updateComposicionApu(update)).unwrap()
        )
      ]);

      console.log('Resultados de actualizaciones:', updateResults);

      // Verificar resultados con la nueva función
      const allSuccess = updateResults.every(isSuccessfulResponse);

      if (allSuccess) {
        // Actualizar el estado local de Redux con las composiciones modificadas
        dispatch(getComposicionesApuByTitulo({
          id_titulo: activeTitulo?.id_titulo ?? '',
          id_proyecto: activeProyecto?.id_proyecto ?? ''
        }));

        const titulosResponse = await dispatch(getTitulosByPresupuesto(activePresupuesto?.id_presupuesto ?? ''))
        if (titulosResponse.payload) {
          console.log("desde APU", titulos)
          console.log("🎉 Actualizando título activo:", titulos.find(t => t.id_titulo === activeTitulo?.id_titulo));
          dispatch(updateActiveTitulo((titulosResponse.payload).find((t: { id_titulo: string | undefined; }) => t.id_titulo === activeTitulo?.id_titulo) || null));
          // Eliminamos esta línea que ya no es necesaria
          // setIsHeaderReady(true);
        }
        console.log("titulosResponse  ", titulosResponse)

        setPendingChanges(new Map());
        setDetallePartidaChanges({});
        setIsEditMode(false);
      } else {
        console.error('❌ Algunas actualizaciones fallaron:',
          updateResults.filter(result => !isSuccessfulResponse(result))
        );
      }
    } catch (error) {
      console.error('❌ Error al guardar los cambios:', error);
      // Eliminamos esta línea que ya no es necesaria
      // setIsHeaderReady(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnableEditMode = () => {
    // Asegurar que los precios %mo estén correctamente calculados al entrar en modo edición
    recalcAllCompositions();
    
    // Si el rendimiento es 0, establecerlo a 100
    if (!activeTitulo?.detallePartida?.rendimiento || activeTitulo?.detallePartida?.rendimiento <= 0) {
      setDetallePartidaChanges(prev => ({
        ...prev,
        rendimiento: 0
      }));
    }
    
    setIsEditMode(true);
  };

  const getPseudoTotal = useCallback(() => {
    console.log('🔄 Calculando pseudo-total:');
    // Unir composiciones con pendingChanges
    const composicionesTemporal = composiciones.map(comp => {
      const changes = pendingChanges.get(comp.id_composicion_apu) || {};
      const cantidad = changes.cantidad !== undefined ? changes.cantidad : comp.cantidad;
      const precio = changes.precio !== undefined ? changes.precio : comp.precio?.precio;
      
      console.log(`- ${comp.rec_comp_apu?.nombre}:`, {
        original: { cantidad: comp.cantidad, precio: comp.precio?.precio },
        changes: changes,
        final: { cantidad, precio }
      });
      
      return {
        ...comp,
        cantidad: changes.cantidad !== undefined ? changes.cantidad : comp.cantidad,
        cuadrilla: changes.cuadrilla !== undefined ? changes.cuadrilla : comp.cuadrilla,
        precio: changes.precio !== undefined
          ? { ...comp.precio, precio: changes.precio }
          : comp.precio
      };
    });
    const total = calcularTotal(composicionesTemporal);
    console.log('Total calculado:', total);
    return total;
  }, [composiciones, pendingChanges]);

  // Configuración de animaciones
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  return (

    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className={`bg-white rounded-xl shadow-sm border border-gray-200 h-full ${className} relative flex flex-col`} // Añadimos relative y flex flex-col
    >
      {/* Solo mostrar el encabezado si el título es tipo "PARTIDA" */}
      {activeTitulo?.tipo !== "PARTIDA" && (
        <APUHeaderNoPartida
          onAddResource={handleAddResource}
          calcularSubtotalPorTipo={calcularSubtotalPorTipo}
          isEditMode={isEditMode}
          onEditDetallePartida={handleEditDetallePartida}
          detallePartida={{
            ...activeTitulo?.detallePartida,
            ...detallePartidaChanges
          }}
          pendingChanges={pendingChanges}
          composiciones={composiciones}
        />
      )}
      {activeTitulo?.tipo === "PARTIDA" && (
        <APUHeader
          onAddResource={handleAddResource}
          calcularSubtotalPorTipo={calcularSubtotalPorTipo}
          isEditMode={isEditMode}
          onEditDetallePartida={handleEditDetallePartida}
          detallePartida={{
            ...activeTitulo?.detallePartida,
            ...detallePartidaChanges
          }}
          pendingChanges={pendingChanges}
          composiciones={composiciones}
        />
      )}
      {(activeTitulo?.tipo === "PARTIDA") &&
        <>
          <div className="flex-1 overflow-auto"> {/* Añadimos un contenedor con flex-1 */}
            <APUTable
              composiciones={composiciones}
              onDelete={handleDelete}
              onEdit={handleEdit}
              isEditMode={isEditMode}
              pendingChanges={pendingChanges}
            />
          </div>
          <div className={`rounded-b-lg border-t border-black w-full`}>
            <APUFooter
              isEditMode={isEditMode}
              onSaveChanges={handleSaveChanges}
              onCancelChanges={() => {
                setPendingChanges(new Map());
                setDetallePartidaChanges({});
                setIsEditMode(false);
              }}
              onEnableEditMode={handleEnableEditMode}
              pendingChangesCount={pendingChanges.size + Object.keys(detallePartidaChanges).length}
              pseudoTotal={isEditMode ? getPseudoTotal() : undefined}
              pendingChanges={pendingChanges}
              composiciones={composiciones}
              detallePartidaChanges={detallePartidaChanges}
            />
          </div>

          {isLoading && <LoaderOverlay message="Actualizando precios..." />}

          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              title="Nueva Composición"
            >
              <CatalogoRecursos onClose={() => setIsModalOpen(false)} />
            </Modal>
          )}

          <ModalAlert
            isOpen={isAlertOpen}
            title="Atención"
            message="Debe seleccionar una Unidad para la partida antes de añadir recursos."
            onConfirm={() => setIsAlertOpen(false)}
            onCancel={() => setIsAlertOpen(false)}
            variant="yellow"
          />
          {isBadCalcAlertOpen && (
            <ModalAlert
              isOpen={isBadCalcAlertOpen}
              title="Cálculos inválidos"
              message={`Los siguientes recursos tienen cálculos inválidos: ${badCalcResources.join(', ')}`}
              onConfirm={() => setIsBadCalcAlertOpen(false)}
              onCancel={() => setIsBadCalcAlertOpen(false)}
              variant="red"
            />
          )}
          {/* Agregar modal de error de validación */}
          {validationError.isOpen && (
            <ModalAlert
              isOpen={validationError.isOpen}
              title="Error de validación"
              message={validationError.message}
              onConfirm={() => setValidationError({isOpen: false, message: ""})}
              onCancel={() => setValidationError({isOpen: false, message: ""})}
              variant="red"
            />
          )}
        </>
      }
    </motion.div>

  );
};

export default APU;