import React, { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';

import { FormData, Requerimiento, UsuarioCargo } from './types';
import { formatDateForInput } from '../../../../components/Utils/dateUtils';
import AssignApproversModal from './AssignApproversModal';
import Modal from '../../../../components/Modal/Modal';
import ModalAlert from '../../../../components/Modal/ModalAlert';
import { Obra } from '../../../../slices/obrasSlice';
import { Tooltip } from 'react-tooltip';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import { deleteFile, fetchFilesByReferencia } from '../../../../slices/archivoSustentoSlice';
import { FiFile, FiImage, FiUpload, FiTrash2 } from 'react-icons/fi';

interface ReadOnlyViewProps {
  formData: FormData;
  requerimiento: Requerimiento;
  obras: { obras: Obra[] };
  onEdit: () => void;
  onSubmit: () => void;
  onSave: () => void;
  usuariosCargo: UsuarioCargo[];
  hasZeroPriceRecursos?: boolean;
  hasZeroQuantityRecursos?: boolean;
  isEmptyRecursos?: boolean;
  isEditingRequerimiento?: boolean;
}

export const ReadOnlyView: React.FC<ReadOnlyViewProps> = memo(({
  formData,
  requerimiento,
  obras,
  onEdit,
  onSubmit,
  usuariosCargo,
  hasZeroPriceRecursos = false,
  hasZeroQuantityRecursos = false,
  isEmptyRecursos = true,
  isEditingRequerimiento
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [archivos, setArchivos] = useState<any[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [archivoToDelete, setArchivoToDelete] = useState<string | null>(null);

  useEffect(() => {
    const loadArchivos = async () => {
      try {
        const result = await dispatch(fetchFilesByReferencia({
          referencia_id: requerimiento.id,
          tipo: 'requerimiento'
        })).unwrap();
        setArchivos(result);
      } catch (error) {
        console.error('Error al cargar los archivos:', error);
      }
    };

    if (requerimiento.id) {
      loadArchivos();
    }
  }, [dispatch, requerimiento.id]);

  const isSubmitDisabled = hasZeroPriceRecursos || hasZeroQuantityRecursos || isEmptyRecursos;

  const getTooltipMessage = () => {
    if (isEmptyRecursos) return "No se puede enviar: Debe agregar al menos un recurso.";
    if (hasZeroPriceRecursos) return "No se puede enviar: Existen recursos con precio cero. El precio es un campo obligatorio.";
    if (hasZeroQuantityRecursos) return "No se puede enviar: Existen recursos con cantidad cero o negativa.";
    return "";
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenFileUploadModal = () => {
    setIsFileUploadModalOpen(true);
  };

  const handleCloseFileUploadModal = () => {
    setIsFileUploadModalOpen(false);
  };

  const handleFilePreview = (url: string) => {
    if (url.match(/\.(jpg|jpeg|png|gif|webp)$/i)) {
      window.open(url, '_blank');
    } else if (url.match(/\.pdf$/i)) {
      window.open(url, '_blank');
    } else {
      window.open(url, '_blank');
    }
  };

  const handleDeleteFile = async (archivoId: string) => {
    setArchivoToDelete(archivoId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteFile = async () => {
    if (!archivoToDelete) return;

    try {
      await dispatch(deleteFile(archivoToDelete)).unwrap();
      setArchivos(archivos.filter(archivo => archivo.id !== archivoToDelete));
      setShowDeleteConfirmation(false);
      setArchivoToDelete(null);
    } catch (error) {
      console.error('Error al eliminar el archivo:', error);
    }
  };

  const cancelDeleteFile = () => {
    setShowDeleteConfirmation(false);
    setArchivoToDelete(null);
  };

  return (
    <div className="grid grid-cols-4 gap-1 bg-gray-900/80 py-2.5 px-3 rounded-lg">
      <div>
        <span className="block font-bold text-xs text-zinc-400">Código:</span>
        <p className="text-base text-zinc-100 p-1">{formData.codigo || '-'}</p>
      </div>

      <div>
        <span className="block font-bold text-xs text-zinc-400">Usuario:</span>
        <p className="text-base text-zinc-100 p-1">{requerimiento.usuario || '-'}</p>
      </div>

      <div>
        <span className="block text-xs font-bold text-zinc-400">Obra:</span>
        <p className="text-sm text-zinc-100 p-1">
          <span title={obras.obras.find(obra => obra.id === formData.obra_id)?.nombre || '-'}>
            {(obras.obras.find(obra => obra.id === formData.obra_id)?.nombre || '-').substring(0, 35)}
          </span>
        </p>
      </div>

      <div>
        <span className="block text-xs font-bold text-zinc-400">Proyecto:</span>
        <p className="text-sm text-zinc-100 p-1">
          <span title={obras.obras.find(obra => obra.id === formData.obra_id)?.nombre || '-'}>
            {(obras.obras.find(obra => obra.id === formData.obra_id)?.proyecto?.nombre_proyecto || '-').substring(0, 35)}
          </span>
        </p>
      </div>

      <div>
        <span className="block text-xs font-bold text-zinc-400">Fecha Final:</span>
        <p className="text-base text-zinc-100 p-1">
          {formatDateForInput(formData.fecha_final).split("-").reverse().join("-") || '-'}
        </p>
      </div>

      <div className="col-span-1">
        <span className="block text-xs font-bold text-zinc-400">Sustento:</span>
        <p className="text-base text-zinc-100 p-1">{formData.sustento || '-'}</p>
      </div>
      <div className="mt-2">
        <div className="flex items-center gap-2">
          {archivos.length === 0 ? (
            <button
              type="button"
              onClick={handleOpenFileUploadModal}
              className="flex items-center gap-2 bg-gradient-to-r from-yellow-500 via-pink-500 to-purple-500 text-white rounded text-base p-2 hover:from-purple-500 hover:via-blue-500 hover:to-yellow-500 transition-all duration-1000 bg-[length:200%_auto] hover:bg-[position:right_center]"
            >
              <FiUpload className="w-5 h-5" />
              Subir Archivos de Sustento
            </button>
          ) : (
            <div className="flex flex-wrap gap-2">
              {archivos.map((archivo) => (
                <div key={archivo.id} className="flex items-center gap-2 bg-gray-200/90 text-white rounded text-base p-2">
                  <button
                    onClick={() => handleFilePreview(archivo.url)}
                    className="flex items-center gap-2 text-blue-500 hover:text-blue-700 transition-colors"
                  >
                    {archivo.type === 'image' ? (
                      <FiImage className="w-5 h-5" />
                    ) : (
                      <FiFile className="w-5 h-5" />
                    )}
                  </button>
                  <button
                    onClick={() => handleDeleteFile(archivo.id)}
                    className="hover:text-red-400 transition-colors"
                  >
                    <FiTrash2 className="w-5 h-5 text-red-500" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-end gap-2">
        {isEditingRequerimiento ? <button
          type="button"
          onClick={onEdit}
          className="w-full bg-blue-500 text-white rounded text-base p-2"
        >
          Editar
        </button> : null}
        <button
          type="button"
          onClick={handleOpenModal}
          className={`w-full rounded text-base p-2 ${isSubmitDisabled
            ? 'bg-yellow-300 text-gray-600 cursor-not-allowed opacity-70'
            : 'bg-yellow-500 text-white'}`}
          disabled={isSubmitDisabled}
          data-tooltip-id="submit-tooltip"
          data-tooltip-content={getTooltipMessage()}
        >
          Enviar
        </button>
        <Tooltip id="submit-tooltip" />
      </div>


      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <AssignApproversModal
            requerimientoId={requerimiento.id}
            usuariosCargo={usuariosCargo}
            onClose={handleCloseModal}
            onSubmit={onSubmit}
          />
        </Modal>
      )}

      {isFileUploadModalOpen && (
        <Modal isOpen={isFileUploadModalOpen} onClose={handleCloseFileUploadModal} title="Subir Archivos">
          <FileUpload
            tipo="requerimiento"
            referencia_id={requerimiento.id}
            maxFileSize={10}
            accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.gif"
            multiple={true}
            onFilesChange={(files) => {
              setArchivos(files);
              if (files.length > 0) {
                handleCloseFileUploadModal();
              }
            }}
          />
        </Modal>
      )}

      <ModalAlert
        isOpen={showDeleteConfirmation}
        title="Confirmar Eliminación"
        message="¿Está seguro que desea eliminar este archivo?"
        onConfirm={confirmDeleteFile}
        onCancel={cancelDeleteFile}
        variant="red"
      />
    </div>
  );
});