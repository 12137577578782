import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../store/store";
import {
  fetchTransferenciaDetalles,
  fetchObraOrigenYDestino,
  addTransferenciaDetalle,
} from "../../../slices/transferenciaDetalleSlice";
import {
  fetchTransferenciaRecursosById,
  addTransferenciaRecurso,
  Recurso,
} from "../../../slices/transferenciaRecursoSlice";
import {
  updateObraBodegaRecurso,
  addObraBodegaRecurso,
} from "../../../slices/obraBodegaRecursoSlice";
import { } from "../../../slices/transferenciaRecursoSlice";
import LoaderPage from "../../../components/Loader/LoaderPage";
import { formatDate } from "../../../components/Utils/dateUtils";
import { ModalProps, ObraInfo, TransferenciaRecurso, Unidades2 } from "./type";
import { consultaRecursosPorBodegaByObraIdAndRecursoIdService } from "../../../services/consultaRecursosPorBodegaByObraIdAndRecursoIdService";

interface BodegaInfo {
  obra_bodega_id: string;
  nombre: string;
  cantidad: number;
  costo: number;
  obra_bodega_recursos_id: string;
  existencia: string;
}

interface RecursoConBodegas extends TransferenciaRecurso {
  cantidadModificada?: number;
  bodegasDistribucion: {
    [bodegaId: string]: number;
  };
}

const getUnidadNombre = (
  recurso: TransferenciaRecurso,
  unidades: Unidades2[]
): string => {
  if (!recurso?.recurso_id?.unidad_id || !unidades) return "-";
  const unidad = unidades.find((u) => u.id === recurso.recurso_id.unidad_id);
  return unidad?.nombre || "-";
};

const NewRecepcionTransferencia: React.FC<ModalProps> = ({ onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTransferencia, setSelectedTransferencia] = useState<
    string | null
  >(null);
  const [selectedRecursos, setSelectedRecursos] = useState<RecursoConBodegas[]>(
    []
  );
  const [isLoadingDetalles, setIsLoadingDetalles] = useState(true);
  const [isLoadingRecursos, setIsLoadingRecursos] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recepcionCompleta, setRecepcionCompleta] = useState(false);
  const userId = useSelector((state: RootState) => state.user?.id);
  const unidades = useSelector((state: RootState) => state.unidad.unidades);
  const [obrasInfo, setObrasInfo] = useState<ObraInfo | null>(null);
  const [bodegasInfo, setBodegasInfo] = useState<{
    [key: string]: BodegaInfo[];
  }>({});

  const { transferenciaDetalles } = useSelector(
    (state: RootState) => state.transferenciaDetalle
  );
  const { transferenciaRecursos } = useSelector(
    (state: RootState) => state.transferenciaRecurso
  );

  const [selectedBodegas, setSelectedBodegas] = useState<{ [key: string]: string }>({});

  const handleBodegaChange = (recursoId: string, bodegaId: string) => {
    setSelectedBodegas(prev => ({
      ...prev,
      [recursoId]: bodegaId
    }));
  };

  const handleCantidadRecibidaChange = (recursoId: string, cantidad: number) => {
    const bodegaId = selectedBodegas[recursoId];
    if (!bodegaId) {
      setError("Por favor seleccione una bodega primero");
      return;
    }
    handleCantidadChange(recursoId, bodegaId, cantidad);
  };

  // Filtrar solo las transferencias de tipo SALIDA-ALMACEN y ordenarlas por fecha descendente
  const filteredTransferencias = transferenciaDetalles
    .filter((detalle) => detalle.tipo === "SALIDA-ALMACEN")
    .sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());

  // Combinar los efectos de carga inicial
  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoadingDetalles(true);
      try {
        await dispatch(fetchTransferenciaDetalles());
      } finally {
        setIsLoadingDetalles(false);
      }
    };
    loadInitialData();
  }, [dispatch]);

  // Combinar la carga de recursos y obras
  useEffect(() => {
    const loadTransferenciaData = async () => {
      if (!selectedTransferencia) return;

      try {
        setIsLoadingRecursos(true);

        // Cargar información de obras y recursos en paralelo
        const [recursosResponse, obrasResponse] = await Promise.all([
          dispatch(fetchTransferenciaRecursosById(selectedTransferencia)),
          dispatch(fetchObraOrigenYDestino(
            transferenciaDetalles.find(t => t.id === selectedTransferencia)?.transferencia_id.id || ''
          ))
        ]);

        if (!recursosResponse.payload) {
          throw new Error("No se recibieron datos de recursos");
        }

        // Inicializar recursos
        const recursosConCantidad = recursosResponse.payload.map(
          (recurso: Recurso) => ({
            ...recurso,
            cantidadModificada: 0,
            bodegasDistribucion: {},
          })
        );
        setSelectedRecursos(recursosConCantidad);

        // Actualizar información de obras
        if (obrasResponse.payload?.length > 0) {
          const obraInfo = obrasResponse.payload[0];
          setObrasInfo(obraInfo);

          // Cargar información de bodegas solo si es necesario
          if (obraInfo?.referencia_id?.obra_destino_id?._id) {
            const bodegasPromises = recursosResponse.payload.map(
              (recurso: TransferenciaRecurso) =>
                consultaRecursosPorBodegaByObraIdAndRecursoIdService({
                  obraId: obraInfo.referencia_id.obra_destino_id._id,
                  recursoId: recurso.recurso_id.id,
                })
            );

            const bodegasResults = await Promise.all(bodegasPromises);
            const bodegasData = bodegasResults.reduce((acc, curr, index) => ({
              ...acc,
              [recursosResponse.payload[index].recurso_id.id]: curr,
            }), {});

            setBodegasInfo(bodegasData);
          }
        }
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setError(error instanceof Error ? error.message : "Error al cargar datos");
      } finally {
        setIsLoadingRecursos(false);
      }
    };

    loadTransferenciaData();
  }, [selectedTransferencia, dispatch]);

  // Optimizar handleCantidadChange para reducir actualizaciones de estado
  const handleCantidadChange = React.useCallback((
    recursoId: string,
    bodegaId: string,
    valor: number
  ) => {
    setSelectedRecursos(prev => {
      const recurso = transferenciaRecursos.find(r => r._id === recursoId);
      if (!recurso) return prev;

      const cantidadValidada = Math.max(0, valor);
      const index = prev.findIndex(r => r._id === recursoId);
      const recursoActual = prev[index] || {
        ...recurso,
        bodegasDistribucion: {},
        cantidadModificada: 0,
      };

      const nuevaDistribucion = {
        ...recursoActual.bodegasDistribucion,
        [bodegaId]: cantidadValidada,
      };

      const totalDistribuido = Object.values(nuevaDistribucion)
        .reduce((sum, val) => sum + val, 0);

      if (totalDistribuido > recurso.cantidad) return prev;

      const recursoActualizado = {
        ...recursoActual,
        bodegasDistribucion: nuevaDistribucion,
        cantidadModificada: totalDistribuido,
      };

      if (index === -1) {
        return [...prev, recursoActualizado];
      }

      const newArray = [...prev];
      newArray[index] = recursoActualizado;
      return newArray;
    });
  }, [transferenciaRecursos]);

  // Añadir useEffect para verificar si la recepción está completa
  useEffect(() => {
    if (transferenciaRecursos.length > 0) {
      const todasDiferenciasCero = transferenciaRecursos.every(recurso => recurso.diferencia === 0);
      setRecepcionCompleta(todasDiferenciasCero);
    } else {
      setRecepcionCompleta(false);
    }
  }, [transferenciaRecursos]);

  // Optimizar handleSave para manejar errores y transacciones
  const handleSave = async () => {
    if (!userId || !selectedTransferencia) {
      setError("Datos de sesión inválidos");
      return;
    }
  
    try {
      setIsProcessing(true);
      setError(null);
  
      const recursosValidos = selectedRecursos.filter(
        recurso => (recurso.cantidadModificada || 0) > 0
      );
  
      if (recursosValidos.length === 0) {
        throw new Error("No hay recursos seleccionados para transferir");
      }
  
      // Obtener la transferencia actual
      const transferenciaActual = transferenciaDetalles.find(t => t.id === selectedTransferencia);
      if (!transferenciaActual) {
        throw new Error("No se encontró la transferencia seleccionada");
      }
  
      // Crear el detalle de transferencia con el transferencia_id correcto
      const detalleTransferencia = await dispatch(addTransferenciaDetalle({
        transferencia_id: transferenciaActual.transferencia_id.id, // Usar el ID de la transferencia padre
        referencia_id: selectedTransferencia,
        fecha: new Date(),
        tipo: "RECEPCION_TRANSFERENCIA",
        referencia: `Recepción de transferencia - ${transferenciaActual.referencia}`,
      })).unwrap();
  
      // Procesar todos los recursos en paralelo
      await Promise.all(
        recursosValidos.map(async (recurso) => {
          const distribucionBodegas = recurso.bodegasDistribucion || {};
          const totalDistribuido = Object.values(distribucionBodegas)
            .reduce((sum, val) => sum + val, 0);
  
          // Crear transferencia de recurso
          await dispatch(addTransferenciaRecurso({
            transferencia_detalle_id: detalleTransferencia.id,
            recurso_id: recurso.recurso_id.id,
            cantidad: totalDistribuido,
            costo: recurso.costo,
            requerimiento_recurso_id: recurso.requerimiento_recurso_id || undefined,
          }));
  
          // Actualizar bodegas
          const bodegasPromises = Object.entries(distribucionBodegas)
            .filter(([_, cantidad]) => cantidad > 0)
            .map(([bodegaId, cantidad]) => {
              const bodega = bodegasInfo[recurso.recurso_id.id]
                ?.find(b => b.obra_bodega_id === bodegaId);
  
              if (!bodega) return Promise.resolve();
  
              return bodega.existencia === "si"
                ? dispatch(updateObraBodegaRecurso({
                  updateObraBodegaRecursoId: bodega.obra_bodega_recursos_id,
                  cantidad: cantidad + bodega.cantidad,
                }))
                : dispatch(addObraBodegaRecurso({
                  obraBodegaId: bodega.obra_bodega_id,
                  recursoId: recurso.recurso_id.id,
                  cantidad,
                  costo: bodega.costo,
                  estado: "Nuevo",
                }));
            });
  
          await Promise.all(bodegasPromises);
        })
      );
  
      // Actualizar vista
      await dispatch(fetchTransferenciaDetalles());
      onClose();
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : "Error al procesar la recepción"
      );
    } finally {
      setIsProcessing(false);
    }
  };
  

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl shadow-lg w-[2000px] max-w-full min-w-full max-h-[90vh] overflow-hidden border border-indigo-100">
      {error && (
        <div className="p-3 text-red-500 bg-red-50 border-b border-red-100">
          {error}
        </div>
      )}

      <div className="flex h-[calc(90vh-12rem)]">
        {/* Panel izquierdo - Lista de Transferencias */}
        <div className="w-1/3 min-w-40 border-r border-indigo-100 p-4 overflow-y-auto bg-white">
          {isLoadingDetalles ? (
            <div className="flex justify-center items-center h-full">
              <LoaderPage />
            </div>
          ) : (
            filteredTransferencias.map((transferencia) => (
              <div
                key={transferencia.id}
                onClick={() => setSelectedTransferencia(transferencia.id)}
                className={`p-4 mb-3 rounded-lg cursor-pointer transition-all duration-200 ${selectedTransferencia === transferencia.id
                  ? "bg-indigo-50 border-indigo-200 shadow"
                  : "bg-white border border-gray-100 hover:shadow-md hover:border-indigo-100"
                  }`}
              >
                <div className="text-sm font-medium text-indigo-700">
                  {transferencia.referencia}
                </div>
                <div className="text-xs text-gray-600 mt-1">
                  Usuario: {transferencia.transferencia_id.usuario_id.nombres}
                </div>
                <div className="text-xs text-gray-500 mt-1">
                  Fecha: {new Date(transferencia.fecha).toLocaleDateString()}
                </div>
                {selectedTransferencia === transferencia.id && obrasInfo && (
                  <>
                    <div className="text-xs text-gray-600 mt-1">
                      Desde:{" "}
                      {obrasInfo.referencia_id.obra_origen_id
                        ? obrasInfo.referencia_id.obra_origen_id.nombre
                        : "Sin origen"}
                    </div>
                    <div className="text-xs text-gray-600 mt-1">
                      Para:{" "}
                      {obrasInfo.referencia_id.obra_destino_id
                        ? obrasInfo.referencia_id.obra_destino_id.nombre
                        : "Sin destino"}
                    </div>
                  </>
                )}
              </div>
            ))
          )}
        </div>

        {/* Panel derecho - Recursos */}
        <div className="flex-1 flex flex-col bg-white">
          {selectedTransferencia ? (
            isLoadingRecursos ? (
              <div className="flex justify-center items-center h-full">
                <LoaderPage />
              </div>
            ) : (
              <div className="overflow-auto p-4">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="text-center">
                      <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase">
                        Fecha
                      </th>
                      <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase">
                        Código
                      </th>
                      <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase">
                        Nombre
                      </th>
                      <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase">
                        Unidad
                      </th>
                      <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase">
                        Cantidad
                      </th>
                      <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase">
                        Pendiente
                      </th>
                      <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase">
                        Bodega
                      </th>
                      <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase">
                        Cantidad a Recibir
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-100">
                    {transferenciaRecursos.map((recurso) => {
                      const bodegasDelRecurso =
                        bodegasInfo[recurso.recurso_id.id] || [];
                      const selectedRecurso = selectedRecursos.find(
                        (r) => r._id === recurso._id
                      );
                      const totalDistribuido = selectedRecurso
                        ? Object.values(
                          selectedRecurso.bodegasDistribucion || {}
                        ).reduce((sum, val) => sum + val, 0)
                        : 0;

                      return (
                        <tr key={recurso._id} className="hover:bg-gray-50">
                          <td className="px-2 text-center py-3 text-sm text-gray-600">
                            {formatDate(
                              recurso.transferencia_detalle_id.fecha,
                              "dd/mm/yyyy"
                            )}
                          </td>
                          <td className="px-2 text-center py-3 text-sm text-gray-600">
                            {recurso.recurso_id?.codigo || "-"}
                          </td>
                          <td className="px-2 text-left py-3 text-sm text-gray-600">
                            {recurso.recurso_id?.nombre || "-"}
                          </td>
                          <td className="px-2 text-center py-3 text-sm text-gray-600">
                            {getUnidadNombre(recurso, unidades)}
                          </td>
                          <td className="px-2 text-center py-3 text-gray-600">
                            {recurso.cantidad}
                          </td>
                          <td className="px-2 text-center py-3 text-gray-600">
                            {recurso.diferencia}
                          </td>
                          <td className="px-2 py-2">
                            <select
                              value={selectedBodegas[recurso._id] || ''}
                              onChange={(e) => handleBodegaChange(recurso._id, e.target.value)}
                              className="w-full p-1 text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            >
                              <option value="">Seleccione bodega</option>
                              {bodegasDelRecurso.map((bodega) => (
                                <option 
                                  key={bodega.obra_bodega_id} 
                                  value={bodega.obra_bodega_id}
                                >
                                  {bodega.nombre} ({bodega.cantidad})
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="px-2 text-center  py-3 text-gray-600">
                            <div className="flex flex-col pt-5 items-center justify-center gap-2">
                              <input
                                type="number"
                                min="0"
                                max={recurso.diferencia - totalDistribuido}
                                onChange={(e) => handleCantidadRecibidaChange(
                                  recurso._id,
                                  parseInt(e.target.value) || 0
                                )}
                                className="w-20 px-3 h-6  text-xs border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-center"
                              />
                              <div className="text-xs text-indigo-600">
                                {recurso.diferencia === 0 ? (
                                  <span className="text-green-600">Recepción completa</span>
                                ) : (
                                  `Total: ${totalDistribuido} / ${recurso.diferencia}`
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )
          ) : (
            <div className="flex h-full items-center justify-center">
              <div className="text-center text-gray-400">
                <p className="mt-2 text-sm">
                  Selecciona una transferencia para ver sus recursos
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Footer */}
      <div className="p-4 border-t border-indigo-100 flex justify-end space-x-3 bg-white">
        {recepcionCompleta && (
          <div className="flex-1 text-green-600 text-sm font-medium">
            Recepción completa - Todos los recursos han sido recibidos
          </div>
        )}
        <button
          onClick={onClose}
          className="px-4 py-2 text-sm text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
          disabled={isProcessing}
        >
          Cancelar
        </button>
        <button
          onClick={handleSave}
          className="px-4 py-2 text-sm text-white bg-indigo-500 rounded-md hover:bg-indigo-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={
            !selectedTransferencia ||
            isProcessing ||
            selectedRecursos.length === 0 ||
            recepcionCompleta
          }
        >
          {isProcessing ? "Procesando..." : recepcionCompleta ? "Recepción Completa" : "Guardar"}
        </button>
      </div>
    </div>
  );
};

export default React.memo(NewRecepcionTransferencia);
