import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listFormulariosService,
  getFormularioService,
  addFormularioService,
  updateFormularioService,
  deleteFormularioService,
  getSeccionesByFormularioService,
  addSeccionService,
  updateSeccionService,
  deleteSeccionService,
  getCamposBySeccionService,
  addCampoService,
  updateCampoService,
  deleteCampoService,
  getOpcionesByCampoService,
  addOpcionService,
  updateOpcionService,
  deleteOpcionService,
  getRespuestasByFormularioService,
  getRespuestaService,
  addRespuestaService,
  updateRespuestaService,
  deleteRespuestaService
} from '../services/formularioService';

interface OpcionCampo {
  id: string;
  campoId: string;
  etiqueta: string;
  valor: string;
  orden: number;
}

export interface Campo {
  id: string;
  seccionId: string;
  tipo: string;
  etiqueta: string;
  nombre: string;
  valorPredeterminado: string;
  placeholder: string;
  requerido: boolean;
  orden: number;
  ancho: number;
  condicionalMostrar: string;
  propiedadesAdicionales: any;
  opciones: OpcionCampo[];
}

interface Seccion {
  id: string;
  formularioId: string;
  titulo: string;
  descripcion: string;
  orden: number;
  condicionalMostrar: string;
  campos: Campo[];
}

export interface Formulario {
  id: string;
  codigo: string;
  version: string;
  fechaAprobacion: string;
  titulo: string;
  descripcion: string;
  activo: boolean;
  fechaCreacion: string;
  fechaModificacion: string;
  secciones: Seccion[];
}

export interface RespuestaFormulario {
  id: string;
  formularioId: string;
  fechaCreacion: string;
  fechaModificacion: string;
  datosJson: string;
  usuarioId: string;
}

interface FormularioState {
  formularios: Formulario[];
  selectedFormulario: Formulario | null;
  secciones: Seccion[];
  respuestas: RespuestaFormulario[];
  loading: boolean;
  error: string | null;
}

const initialState: FormularioState = {
  formularios: [],
  selectedFormulario: null,
  secciones: [],
  respuestas: [],
  loading: false,
  error: null,
};

export const fetchFormularios = createAsyncThunk(
  'formulario/fetchFormularios',
  async (_, { rejectWithValue }) => {
    try {
      return await listFormulariosService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getFormulario = createAsyncThunk(
  'formulario/getFormulario',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getFormularioService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchSeccionesByFormulario = createAsyncThunk(
  'formulario/fetchSeccionesByFormulario',
  async (formularioId: string, { rejectWithValue }) => {
    try {
      return await getSeccionesByFormularioService(formularioId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchRespuestasByFormulario = createAsyncThunk(
  'formulario/fetchRespuestasByFormulario',
  async (formularioId: string, { rejectWithValue }) => {
    try {
      return await getRespuestasByFormularioService(formularioId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getOpcionesByCampo = createAsyncThunk(
  'formulario/getOpcionesByCampo',
  async (campoId: string, { rejectWithValue }) => {
    try {
      return await getOpcionesByCampoService(campoId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addFormulario = createAsyncThunk(
  'formulario/addFormulario',
  async (data: {
    codigo: string;
    version: string;
    fechaAprobacion: string;
    titulo: string;
    descripcion?: string;
    activo?: boolean;
  }, { rejectWithValue }) => {
    try {
      return await addFormularioService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateFormulario = createAsyncThunk(
  'formulario/updateFormulario',
  async (data: {
    updateFormularioId: string;
    codigo?: string;
    version?: string;
    fechaAprobacion?: string;
    titulo?: string;
    descripcion?: string;
    activo?: boolean;
  }, { rejectWithValue }) => {
    try {
      return await updateFormularioService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteFormulario = createAsyncThunk(
  'formulario/deleteFormulario',
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteFormularioService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addSeccion = createAsyncThunk(
  'formulario/addSeccion',
  async (data: {
    formularioId: string;
    titulo: string;
    orden: number;
    descripcion?: string;
    condicionalMostrar?: string;
  }, { rejectWithValue }) => {
    try {
      return await addSeccionService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateSeccion = createAsyncThunk(
  'formulario/updateSeccion',
  async (data: {
    updateSeccionId: string;
    titulo?: string;
    descripcion?: string;
    orden?: number;
    condicionalMostrar?: string;
  }, { rejectWithValue }) => {
    try {
      return await updateSeccionService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteSeccion = createAsyncThunk(
  'formulario/deleteSeccion',
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteSeccionService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getCamposBySeccion = createAsyncThunk(
  'formulario/getCamposBySeccion',
  async (seccionId: string, { rejectWithValue }) => {
    try {
      return await getCamposBySeccionService(seccionId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addCampo = createAsyncThunk(
  'formulario/addCampo',
  async (data: {
    seccionId: string;
    tipo: string;
    etiqueta: string;
    nombre: string;
    orden: number;
    ancho: number;
    valorPredeterminado?: string;
    placeholder?: string;
    requerido?: boolean;
    condicionalMostrar?: string;
    propiedadesAdicionales?: string;
  }, { rejectWithValue }) => {
    try {
      return await addCampoService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateCampo = createAsyncThunk(
  'formulario/updateCampo',
  async (data: {
    updateCampoId: string;
    tipo?: string;
    etiqueta?: string;
    nombre?: string;
    valorPredeterminado?: string;
    placeholder?: string;
    requerido?: boolean;
    orden?: number;
    ancho?: number;
    condicionalMostrar?: string;
    propiedadesAdicionales?: string;
  }, { rejectWithValue }) => {
    try {
      return await updateCampoService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteCampo = createAsyncThunk(
  'formulario/deleteCampo',
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteCampoService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addOpcion = createAsyncThunk(
  'formulario/addOpcion',
  async (data: {
    campoId: string;
    etiqueta: string;
    valor: string;
    orden: number;
  }, { rejectWithValue }) => {
    try {
      return await addOpcionService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateOpcion = createAsyncThunk(
  'formulario/updateOpcion',
  async (data: {
    updateOpcionId: string;
    etiqueta?: string;
    valor?: string;
    orden?: number;
  }, { rejectWithValue }) => {
    try {
      return await updateOpcionService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteOpcion = createAsyncThunk(
  'formulario/deleteOpcion',
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteOpcionService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getRespuesta = createAsyncThunk(
  'formulario/getRespuesta',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getRespuestaService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addRespuesta = createAsyncThunk(
  'formulario/addRespuesta',
  async (data: {
    formularioId: string;
    datosJson: string;
    usuarioId?: string;
  }, { rejectWithValue }) => {
    try {
      return await addRespuestaService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateRespuesta = createAsyncThunk(
  'formulario/updateRespuesta',
  async (data: {
    updateRespuestaId: string;
    datosJson?: string;
  }, { rejectWithValue }) => {
    try {
      return await updateRespuestaService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteRespuesta = createAsyncThunk(
  'formulario/deleteRespuesta',
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteRespuestaService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const formularioSlice = createSlice({
  name: 'formulario',
  initialState,
  reducers: {
    setFormularios: (state, action: PayloadAction<Formulario[]>) => {
      state.formularios = action.payload;
      state.loading = false;
      state.error = null;
    },
    setSecciones: (state, action: PayloadAction<Seccion[]>) => {
      state.secciones = action.payload;
      state.loading = false;
      state.error = null;
    },
    setRespuestas: (state, action: PayloadAction<RespuestaFormulario[]>) => {
      state.respuestas = action.payload;
      state.loading = false;
      state.error = null;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Formularios
      .addCase(fetchFormularios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFormularios.fulfilled, (state, action: PayloadAction<Formulario[]>) => {
        state.loading = false;
        state.formularios = action.payload;
      })
      .addCase(fetchFormularios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getFormulario.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFormulario.fulfilled, (state, action: PayloadAction<Formulario>) => {
        state.loading = false;
        state.selectedFormulario = action.payload;
      })
      .addCase(getFormulario.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addFormulario.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addFormulario.fulfilled, (state, action: PayloadAction<Formulario>) => {
        state.loading = false;
        state.formularios.push(action.payload);
      })
      .addCase(addFormulario.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateFormulario.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFormulario.fulfilled, (state, action: PayloadAction<Formulario>) => {
        state.loading = false;
        const index = state.formularios.findIndex(form => form.id === action.payload.id);
        if (index !== -1) {
          state.formularios[index] = action.payload;
        }
      })
      .addCase(updateFormulario.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteFormulario.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFormulario.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.loading = false;
        state.formularios = state.formularios.filter(form => form.id !== action.payload.id);
      })
      .addCase(deleteFormulario.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      
      // Secciones
      .addCase(fetchSeccionesByFormulario.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSeccionesByFormulario.fulfilled, (state, action: PayloadAction<Seccion[]>) => {
        state.loading = false;
        state.secciones = action.payload;
      })
      .addCase(fetchSeccionesByFormulario.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addSeccion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSeccion.fulfilled, (state, action: PayloadAction<Seccion>) => {
        state.loading = false;
        state.secciones.push(action.payload);
        
        // Si tenemos el formulario seleccionado, actualizamos sus secciones también
        if (state.selectedFormulario && state.selectedFormulario.id === action.payload.formularioId) {
          state.selectedFormulario.secciones.push(action.payload);
        }
      })
      .addCase(addSeccion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateSeccion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSeccion.fulfilled, (state, action: PayloadAction<Seccion>) => {
        state.loading = false;
        const index = state.secciones.findIndex(sec => sec.id === action.payload.id);
        if (index !== -1) {
          state.secciones[index] = action.payload;
        }
        
        // Si tenemos el formulario seleccionado, actualizamos sus secciones también
        if (state.selectedFormulario) {
          const secIndex = state.selectedFormulario.secciones.findIndex(sec => sec.id === action.payload.id);
          if (secIndex !== -1) {
            state.selectedFormulario.secciones[secIndex] = action.payload;
          }
        }
      })
      .addCase(updateSeccion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteSeccion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSeccion.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.loading = false;
        state.secciones = state.secciones.filter(sec => sec.id !== action.payload.id);
        
        // Si tenemos el formulario seleccionado, actualizamos sus secciones también
        if (state.selectedFormulario) {
          state.selectedFormulario.secciones = state.selectedFormulario.secciones.filter(
            sec => sec.id !== action.payload.id
          );
        }
      })
      .addCase(deleteSeccion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      
      // Campos
      .addCase(getCamposBySeccion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCamposBySeccion.fulfilled, (state, action: PayloadAction<Campo[]>) => {
        state.loading = false;
        
        // Actualizar campos si la sección existe en el estado
        if (state.selectedFormulario) {
          const seccionIndex = state.selectedFormulario.secciones.findIndex(
            sec => sec.id === action.payload[0]?.seccionId
          );
          
          if (seccionIndex !== -1) {
            state.selectedFormulario.secciones[seccionIndex].campos = action.payload;
          }
        }
        
        // También actualizar en el array de secciones si existe
        const seccionIndex = state.secciones.findIndex(
          sec => sec.id === action.payload[0]?.seccionId
        );
        
        if (seccionIndex !== -1) {
          state.secciones[seccionIndex].campos = action.payload;
        }
      })
      .addCase(getCamposBySeccion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addCampo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCampo.fulfilled, (state, action: PayloadAction<Campo>) => {
        state.loading = false;
        
        // Actualizar en el formulario seleccionado si existe
        if (state.selectedFormulario) {
          const seccionIndex = state.selectedFormulario.secciones.findIndex(
            sec => sec.id === action.payload.seccionId
          );
          
          if (seccionIndex !== -1) {
            if (!state.selectedFormulario.secciones[seccionIndex].campos) {
              state.selectedFormulario.secciones[seccionIndex].campos = [];
            }
            state.selectedFormulario.secciones[seccionIndex].campos.push(action.payload);
          }
        }
        
        // También actualizar en el array de secciones si existe
        const seccionIndex = state.secciones.findIndex(
          sec => sec.id === action.payload.seccionId
        );
        
        if (seccionIndex !== -1) {
          if (!state.secciones[seccionIndex].campos) {
            state.secciones[seccionIndex].campos = [];
          }
          state.secciones[seccionIndex].campos.push(action.payload);
        }
      })
      .addCase(addCampo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateCampo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCampo.fulfilled, (state, action: PayloadAction<Campo>) => {
        state.loading = false;
        
        // Actualizar en el formulario seleccionado si existe
        if (state.selectedFormulario) {
          const seccionIndex = state.selectedFormulario.secciones.findIndex(
            sec => sec.id === action.payload.seccionId
          );
          
          if (seccionIndex !== -1) {
            const campoIndex = state.selectedFormulario.secciones[seccionIndex].campos.findIndex(
              campo => campo.id === action.payload.id
            );
            
            if (campoIndex !== -1) {
              state.selectedFormulario.secciones[seccionIndex].campos[campoIndex] = action.payload;
            }
          }
        }
        
        // También actualizar en el array de secciones si existe
        const seccionIndex = state.secciones.findIndex(
          sec => sec.id === action.payload.seccionId
        );
        
        if (seccionIndex !== -1) {
          const campoIndex = state.secciones[seccionIndex].campos.findIndex(
            campo => campo.id === action.payload.id
          );
          
          if (campoIndex !== -1) {
            state.secciones[seccionIndex].campos[campoIndex] = action.payload;
          }
        }
      })
      .addCase(updateCampo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteCampo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCampo.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.loading = false;
        
        // Eliminar del formulario seleccionado si existe
        if (state.selectedFormulario) {
          state.selectedFormulario.secciones.forEach(seccion => {
            seccion.campos = seccion.campos.filter(campo => campo.id !== action.payload.id);
          });
        }
        
        // También eliminar del array de secciones si existe
        state.secciones.forEach(seccion => {
          seccion.campos = seccion.campos.filter(campo => campo.id !== action.payload.id);
        });
      })
      .addCase(deleteCampo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      
      // Respuestas
      .addCase(fetchRespuestasByFormulario.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRespuestasByFormulario.fulfilled, (state, action: PayloadAction<RespuestaFormulario[]>) => {
        state.loading = false;
        state.respuestas = action.payload;
      })
      .addCase(fetchRespuestasByFormulario.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      
      // Opciones
      .addCase(getOpcionesByCampo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOpcionesByCampo.fulfilled, (state, action: PayloadAction<OpcionCampo[]>) => {
        state.loading = false;
        
        // Buscar el campo en todas las secciones y actualizar sus opciones
        if (state.selectedFormulario && action.payload.length > 0) {
          const campoId = action.payload[0].campoId;
          
          state.selectedFormulario.secciones.forEach(seccion => {
            const campoIndex = seccion.campos.findIndex(campo => campo.id === campoId);
            if (campoIndex !== -1) {
              seccion.campos[campoIndex].opciones = action.payload;
            }
          });
        }
        
        // También actualizar en el array de secciones si existe
        if (action.payload.length > 0) {
          const campoId = action.payload[0].campoId;
          
          state.secciones.forEach(seccion => {
            const campoIndex = seccion.campos.findIndex(campo => campo.id === campoId);
            if (campoIndex !== -1) {
              seccion.campos[campoIndex].opciones = action.payload;
            }
          });
        }
      })
      .addCase(getOpcionesByCampo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addOpcion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addOpcion.fulfilled, (state, action: PayloadAction<OpcionCampo>) => {
        state.loading = false;
        
        // Actualizar en el formulario seleccionado si existe
        if (state.selectedFormulario) {
          state.selectedFormulario.secciones.forEach(seccion => {
            const campoIndex = seccion.campos.findIndex(campo => campo.id === action.payload.campoId);
            if (campoIndex !== -1) {
              if (!seccion.campos[campoIndex].opciones) {
                seccion.campos[campoIndex].opciones = [];
              }
              seccion.campos[campoIndex].opciones.push(action.payload);
            }
          });
        }
        
        // También actualizar en el array de secciones si existe
        state.secciones.forEach(seccion => {
          const campoIndex = seccion.campos.findIndex(campo => campo.id === action.payload.campoId);
          if (campoIndex !== -1) {
            if (!seccion.campos[campoIndex].opciones) {
              seccion.campos[campoIndex].opciones = [];
            }
            seccion.campos[campoIndex].opciones.push(action.payload);
          }
        });
      })
      .addCase(addOpcion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateOpcion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOpcion.fulfilled, (state, action: PayloadAction<OpcionCampo>) => {
        state.loading = false;
        
        // Actualizar en el formulario seleccionado si existe
        if (state.selectedFormulario) {
          state.selectedFormulario.secciones.forEach(seccion => {
            const campoIndex = seccion.campos.findIndex(campo => campo.id === action.payload.campoId);
            if (campoIndex !== -1) {
              const opcionIndex = seccion.campos[campoIndex].opciones.findIndex(
                opcion => opcion.id === action.payload.id
              );
              
              if (opcionIndex !== -1) {
                seccion.campos[campoIndex].opciones[opcionIndex] = action.payload;
              }
            }
          });
        }
        
        // También actualizar en el array de secciones si existe
        state.secciones.forEach(seccion => {
          const campoIndex = seccion.campos.findIndex(campo => campo.id === action.payload.campoId);
          if (campoIndex !== -1) {
            const opcionIndex = seccion.campos[campoIndex].opciones.findIndex(
              opcion => opcion.id === action.payload.id
            );
            
            if (opcionIndex !== -1) {
              seccion.campos[campoIndex].opciones[opcionIndex] = action.payload;
            }
          }
        });
      })
      .addCase(updateOpcion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteOpcion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOpcion.fulfilled, (state, action: PayloadAction<OpcionCampo>) => {
        state.loading = false;
        
        // Eliminar del formulario seleccionado si existe
        if (state.selectedFormulario) {
          state.selectedFormulario.secciones.forEach(seccion => {
            seccion.campos.forEach(campo => {
              campo.opciones = campo.opciones.filter(opcion => opcion.id !== action.payload.id);
            });
          });
        }
        
        // También eliminar del array de secciones si existe
        state.secciones.forEach(seccion => {
          seccion.campos.forEach(campo => {
            campo.opciones = campo.opciones.filter(opcion => opcion.id !== action.payload.id);
          });
        });
      })
      .addCase(deleteOpcion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      
      // Respuestas individuales
      .addCase(getRespuesta.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRespuesta.fulfilled, (state, action: PayloadAction<RespuestaFormulario>) => {
        state.loading = false;
        const index = state.respuestas.findIndex(resp => resp.id === action.payload.id);
        if (index === -1) {
          state.respuestas.push(action.payload);
        }
      })
      .addCase(getRespuesta.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addRespuesta.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addRespuesta.fulfilled, (state, action: PayloadAction<RespuestaFormulario>) => {
        state.loading = false;
        state.respuestas.push(action.payload);
      })
      .addCase(addRespuesta.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateRespuesta.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRespuesta.fulfilled, (state, action: PayloadAction<RespuestaFormulario>) => {
        state.loading = false;
        const index = state.respuestas.findIndex(resp => resp.id === action.payload.id);
        if (index !== -1) {
          state.respuestas[index] = action.payload;
        }
      })
      .addCase(updateRespuesta.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteRespuesta.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteRespuesta.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.loading = false;
        state.respuestas = state.respuestas.filter(resp => resp.id !== action.payload.id);
      })
      .addCase(deleteRespuesta.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setFormularios, setSecciones, setRespuestas, clearErrors } = formularioSlice.actions;
export const formularioReducer = formularioSlice.reducer; 