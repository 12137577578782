import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

// Creamos un link de error
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

// Creamos un link HTTP
const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URI,
});

// Creamos un link para añadir el token de autenticación
const authLink = setContext((_, { headers }) => {
  // Obtenemos el token del localStorage
  const userStr = localStorage.getItem('user');
  const user = userStr ? JSON.parse(userStr) : null;
  const token = user?.token;
  
  // Retornamos los headers con el token si existe
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

// Creamos el cliente Apollo
const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]), // Combinamos los links incluyendo authLink
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only', // Cambiado a network-only para asegurar datos frescos - se cambio de cache-first
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default client;