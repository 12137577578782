import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../../components/Buttons/Button';
import Modal from '../../components/Modal/Modal';
import TableComponent from '../../components/Table/TableComponent';
import FormComponent from './UnidadFormComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnidades, addUnidad, updateUnidad, deleteUnidad } from '../../slices/unidadSlice';
import { RootState, AppDispatch } from '../../store/store';
import LoaderPage from '../../components/Loader/LoaderPage';
import ModalAlert from '../../components/Modal/ModalAlert';
import { FiEdit2 } from 'react-icons/fi';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
};

const UnidadPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUnidad, setEditingUnidad] = useState<{ id: string; nombre: string; descripcion: string } | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [unidadToDelete, setUnidadToDelete] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { unidades, loading, error } = useSelector((state: RootState) => state.unidad);

  useEffect(() => {
    dispatch(fetchUnidades());
  }, [dispatch]);

  const handleSubmit = (data: { nombre: string; descripcion: string }) => {
    if (editingUnidad) {
      dispatch(updateUnidad({ id: editingUnidad.id, ...data }));
    } else {
      dispatch(addUnidad(data));
    }
    setIsModalOpen(false);
    setEditingUnidad(null);
  };

  const handleEdit = (unidad: { id: string; nombre: string; descripcion: string }) => {
    setEditingUnidad(unidad);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (unidadToDelete) {
      dispatch(deleteUnidad(unidadToDelete));
    }
    setDeleteModalOpen(false);
    setUnidadToDelete(null);
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setUnidadToDelete(null);
  };

  if (loading) return <LoaderPage />;
  if (error) return <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>Error: {error}</motion.div>;

  const handleButtonClick = () => {
    setEditingUnidad(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingUnidad(null);
  };

  const tableData = {
    filter: [true, true, true],
    headers: ["id","nombre", "descripcion", "opciones"],
    rows: unidades.map(unidad => ({
      id: unidad.unidad_id,
      nombre: unidad.nombre,
      descripcion: unidad.descripcion,
      opciones: (
        <div className="flex gap-2">
          <Button 
            icon={<FiEdit2 />}
            color='transp' 
            className='text-black' 
            onClick={() => handleEdit(unidad)}
          />          
        </div>
      )
    }))
  };

  return (
    <motion.div 
      className="flex flex-col h-full"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <motion.div 
        className="x text-white pb-4 px-4 flex items-center justify-between"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <h1 className="text-2xl font-bold">Unidades ☺</h1>
            <div className="flex items-center space-x-2">
              <Button text='Nueva Unidad' color='verde' onClick={handleButtonClick} className="rounded w-full" />
            </div>
      </motion.div>

      <motion.div 
        className="flex flex-1 overflow-hidden rounded-xl"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4 }}
      >
        <main className="w-full flex flex-col flex-grow p-4 bg-white/80 overflow-hidden">
          <motion.div 
            className="flex-grow border rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
          >
            <div className="h-full overflow-auto">
              <TableComponent tableData={tableData} />
            </div>
          </motion.div>
        </main>
      </motion.div>

      <AnimatePresence>
        {isModalOpen && (
          <Modal title={editingUnidad ? 'Actualizar Unidad' : 'Crear Unidad'} isOpen={isModalOpen} onClose={handleCloseModal}>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
            >
              <FormComponent
                initialValues={editingUnidad || undefined}
                onSubmit={handleSubmit}
              />
            </motion.div>
          </Modal>
        )}

        {deleteModalOpen && (
          <ModalAlert
            isOpen={deleteModalOpen}
            title="Confirmar Eliminación"
            message="¿Está seguro que desea eliminar esta unidad?"
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
            variant="red"
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default UnidadPage;