import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiDownload, FiColumns, FiFilter, FiRefreshCcw } from 'react-icons/fi';
import { Table } from '@tanstack/react-table';

interface TableCustomOptionsProps {
  table: Table<any>;
  onExportToExcel: () => void;
  isOpen: boolean;
  onClose: () => void;
  columns: string[];
  visibleColumns: string[];
  onToggleColumn: (columnId: string) => void;
  onResetColumns: () => void;
  onResetFilters: () => void;
  onResetAll: () => void;
}

const menuVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};

const buttonBaseClass = "flex items-center space-x-2 text-sm transition-colors";

const TableCustomOptions: React.FC<TableCustomOptionsProps> = ({
  onExportToExcel,
  isOpen,
  onClose,
  columns,
  visibleColumns,
  onToggleColumn,
  onResetColumns,
  onResetFilters,
  onResetAll,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        menuRef.current && 
        !menuRef.current.contains(event.target as Node) &&
        !(event.target as Element).closest('[data-settings-button]')
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onClose]);

  const OptionSection = ({ title, children }: { title: string, children: React.ReactNode }) => (
    <div>
      <h3 className="text-sm font-semibold text-gray-700 mb-2">{title}</h3>
      {children}
    </div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={menuRef}
          variants={menuVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl p-4 z-50"
        >
          <div className="space-y-4">
            <OptionSection title="Exportar">
              <button
                onClick={onExportToExcel}
                className={`${buttonBaseClass} text-gray-600 hover:text-green-600`}
              >
                <FiDownload />
                <span>Exportar a Excel</span>
              </button>
            </OptionSection>

            <OptionSection title="Columnas Visibles">
              <div className="max-h-40 overflow-y-auto space-y-2">
                {columns.map((column) => (
                  <label
                    key={column}
                    className="flex items-center space-x-2 text-sm cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={visibleColumns.includes(column)}
                      onChange={() => onToggleColumn(column)}
                      className="rounded text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-gray-600">{column}</span>
                  </label>
                ))}
              </div>
            </OptionSection>

            <OptionSection title="Acciones">
              <div className="space-y-2">
                <button
                  onClick={onResetColumns}
                  className={`${buttonBaseClass} text-gray-600 hover:text-blue-600`}
                >
                  <FiColumns />
                  <span>Restablecer Columnas</span>
                </button>
                <button
                  onClick={onResetFilters}
                  className={`${buttonBaseClass} text-gray-600 hover:text-blue-600`}
                >
                  <FiFilter />
                  <span>Limpiar Filtros</span>
                </button>
                <button
                  onClick={onResetAll}
                  className={`${buttonBaseClass} text-gray-600 hover:text-red-600`}
                >
                  <FiRefreshCcw />
                  <span>Restablecer Todo</span>
                </button>
              </div>
            </OptionSection>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TableCustomOptions;
