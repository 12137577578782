import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ArchivoSustento,
  UploadResponse,
  UploadFileInput,
  getFilesByReferenciaService,
  uploadFileService,
  deleteFileService
} from '../services/archivoSustentoService';

interface ArchivoSustentoState {
  archivos: ArchivoSustento[];
  loading: boolean;
  uploading: boolean;
  deleting: boolean;
  error: string | null;
  success: string | null;
}

const initialState: ArchivoSustentoState = {
  archivos: [],
  loading: false,
  uploading: false,
  deleting: false,
  error: null,
  success: null
};

export const fetchFilesByReferencia = createAsyncThunk(
  'archivoSustento/fetchFilesByReferencia',
  async ({ referencia_id, tipo }: { referencia_id: string, tipo: string }, { rejectWithValue }) => {
    try {
      return await getFilesByReferenciaService(referencia_id, tipo);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'archivoSustento/uploadFile',
  async (input: UploadFileInput, { rejectWithValue }) => {
    try {
      const response = await uploadFileService(input);
      // Añadimos el nombre del archivo para mejor tracking
      return {
        ...response,
        fileName: input.file.name
      };
    } catch (error) {
      console.error("Error en uploadFile thunk:", error);
      return rejectWithValue({
        message: (error as Error).message,
        fileName: input.file.name
      });
    }
  }
);

export const deleteFile = createAsyncThunk(
  'archivoSustento/deleteFile',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteFileService(id);
      if (response.success) {
        return { id, message: response.message };
      }
      return rejectWithValue(response.message);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const archivoSustentoSlice = createSlice({
  name: 'archivoSustento',
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.error = null;
      state.success = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch files
      .addCase(fetchFilesByReferencia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFilesByReferencia.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        
        // La respuesta viene directamente en action.payload como array
        const newFiles = Array.isArray(action.payload) ? action.payload : [];
        
        if (newFiles.length > 0) {
          // Eliminar archivos existentes con los mismos IDs para evitar duplicados
          const existingIds = new Set(newFiles.map((file: ArchivoSustento) => file.id));
          state.archivos = state.archivos.filter(file => !existingIds.has(file.id));
          
          // Agregar los nuevos archivos
          state.archivos = [...state.archivos, ...newFiles];
        }
      })
      .addCase(fetchFilesByReferencia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      
      // Upload file
      .addCase(uploadFile.pending, (state) => {
        state.uploading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(uploadFile.fulfilled, (state, action: PayloadAction<UploadResponse>) => {
        state.uploading = false;
        state.success = action.payload.message;
        // Si tenemos el ID y la URL, añadimos el archivo a la lista
        if (action.payload.archivo_id && action.payload.url) {
          const newArchivo: ArchivoSustento = {
            id: action.payload.archivo_id,
            url: action.payload.url,
            tipo: state.archivos.length > 0 ? state.archivos[0].tipo : '', // Mantener el mismo tipo
            referencia_id: state.archivos.length > 0 ? state.archivos[0].referencia_id : '' // Mantener la misma referencia
          };
          state.archivos.push(newArchivo);
        }
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.uploading = false;
        state.error = action.payload as string;
      })
      
      // Delete file
      .addCase(deleteFile.pending, (state) => {
        state.deleting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(deleteFile.fulfilled, (state, action: PayloadAction<{ id: string, message: string }>) => {
        state.deleting = false;
        state.success = action.payload.message;
        state.archivos = state.archivos.filter(archivo => archivo.id !== action.payload.id);
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.payload as string;
      });
  }
});

export const { clearMessages } = archivoSustentoSlice.actions;
export const archivoSustentoReducer = archivoSustentoSlice.reducer;
