import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listTitulosService,
  getTitulosByPresupuestoService,
  getPartidasByProyectoService,
  getTituloService,
  addTituloService,
  updateTituloService,
  deleteTituloService
} from '../services/tituloService';
import { DetallePartida } from './detallePartidaSlice';

export interface Titulo {
  id_titulo: string;
  id_presupuesto: string;
  id_titulo_padre: string | null;
  id_titulo_plantilla: string | null;
  item: string;
  descripcion: string;
  parcial: number;
  fecha_creacion: string;
  id_especialidad: string;
  nivel: number;
  orden: number;
  tipo: 'TITULO' | 'PARTIDA' | 'SUBPARTIDA';
  detallePartida?: DetallePartida;
}


interface TituloState {
  titulos: Titulo[];
  selectedTitulo: Titulo| null;
  loading: boolean;
  error: string | null;
  titulosPorPresupuesto: { [key: string]: Titulo[] };
}

const initialState: TituloState = {
  titulos: [],
  selectedTitulo: null,
  loading: false,
  error: null,
  titulosPorPresupuesto: {},
};

export const fetchTitulos = createAsyncThunk(
  'titulo/fetchTitulos',
  async (_, { rejectWithValue }) => {
    try {
      return await listTitulosService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getTitulosByPresupuesto = createAsyncThunk(
  'titulo/getTitulosByPresupuesto',
  async (id_presupuesto: string, { rejectWithValue }) => {
    try {
      return await getTitulosByPresupuestoService(id_presupuesto);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getTitulo = createAsyncThunk(
  'titulo/getTitulo',
  async (id_titulo: string, { rejectWithValue }) => {
    try {
      return await getTituloService(id_titulo);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addTitulo = createAsyncThunk(
  'titulo/addTitulo',
  async (data: Titulo, { rejectWithValue }) => {
    try {
      console.log('Adding titulo with data:', data);
      const response = await addTituloService(data);
      console.log('Response from addTituloService:', response);
      return { ...response, id_presupuesto: data.id_presupuesto }; // Aseguramos que id_presupuesto esté presente
    } catch (error) {
      console.error('Error in addTitulo:', error);
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateTitulo = createAsyncThunk(
  'titulo/updateTitulo',
  async (data: Titulo, { rejectWithValue }) => {
    try {
      return await updateTituloService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteTitulo = createAsyncThunk(
  'titulo/deleteTitulo',
  async (id_titulo: string, { rejectWithValue }) => {
    try {
      return await deleteTituloService(id_titulo);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getPartidasByProyecto = createAsyncThunk(
  'titulo/getPartidasByProyecto',
  async (id_proyecto: string, { rejectWithValue }) => {
    try {
      return await getPartidasByProyectoService(id_proyecto);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const tituloSlice = createSlice({
  name: 'titulo',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    getTitulosFromCache: (state, action: PayloadAction<string>) => {
      state.loading = true;  // Agregar loading incluso al obtener del cache
      const titulosCache = state.titulosPorPresupuesto[action.payload];
      if (titulosCache) {
        state.titulos = titulosCache;
      }
      state.loading = false;  // Finalizar loading
    },
    // Nuevo reducer para actualizar el detalle de partida en un título
    updateTituloDetallePartida: (state, action: PayloadAction<{
      id_presupuesto: string;
      id_titulo: string;
      detallePartida: DetallePartida;
    }>) => {      
      console.log('🔄 Iniciando updateTituloDetallePartida con:', action.payload);
      
      // Actualizar en la lista principal de títulos
      const titulo = state.titulos.find(t => t.id_titulo === action.payload.id_titulo);
      if (titulo) {
        console.log('📝 Actualizando título en lista principal:', {
          antes: titulo.detallePartida,
          despues: action.payload.detallePartida
        });
        titulo.detallePartida = action.payload.detallePartida;
      }
    
      // Actualizar en el cache de títulos por presupuesto
      if (state.titulosPorPresupuesto[action.payload.id_presupuesto]) {
        const tituloEnCache = state.titulosPorPresupuesto[action.payload.id_presupuesto]
          .find(t => t.id_titulo === action.payload.id_titulo);
        if (tituloEnCache) {
          console.log('📝 Actualizando título en cache:', {
            antes: tituloEnCache.detallePartida,
            despues: action.payload.detallePartida
          });
          tituloEnCache.detallePartida = action.payload.detallePartida;
        }
      }
      
      console.log('✅ Estado final después de updateTituloDetallePartida:', {
        titulosActualizados: state.titulos.filter(t => t.id_titulo === action.payload.id_titulo),
        cacheActualizado: state.titulosPorPresupuesto[action.payload.id_presupuesto]?.filter(t => t.id_titulo === action.payload.id_titulo)
      });
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch Titulos
      .addCase(fetchTitulos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTitulos.fulfilled, (state, action: PayloadAction<Titulo[]>) => {
        state.loading = false;
        state.titulos = action.payload;
      })
      .addCase(fetchTitulos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Get Titulos By Presupuesto
      .addCase(getTitulosByPresupuesto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTitulosByPresupuesto.fulfilled, (state, action: PayloadAction<Titulo[], string, { arg: string }>) => {
        state.loading = false;
        state.titulos = action.payload;
        // Guardar en cache
        if (action.meta.arg) { // action.meta.arg contiene el id_presupuesto
          state.titulosPorPresupuesto[action.meta.arg] = action.payload;
        }
      })
      .addCase(getTitulosByPresupuesto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Get Single Titulo
      .addCase(getTitulo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTitulo.fulfilled, (state, action: PayloadAction<Titulo>) => {
        state.loading = false;
        state.selectedTitulo = action.payload;
      })
      .addCase(getTitulo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Add Titulo
      .addCase(addTitulo.pending, (state) => {
        console.log('addTitulo.pending - Current state:', state);
        state.loading = true;
      })
      .addCase(addTitulo.fulfilled, (state, action: PayloadAction<Titulo>) => {
        console.log('addTitulo.fulfilled - Received payload:', action.payload);
        state.loading = false;
        state.selectedTitulo = action.payload;
        
        // Agregar el nuevo título tanto a titulos como a titulosPorPresupuesto
        state.titulos.push(action.payload);
        
        // Si existe el presupuesto en el cache, agregar el título allí también
        if (action.payload.id_presupuesto) {
          console.log('Updating titulosPorPresupuesto for:', action.payload.id_presupuesto);
          if (!state.titulosPorPresupuesto[action.payload.id_presupuesto]) {
            state.titulosPorPresupuesto[action.payload.id_presupuesto] = [];
          }
          state.titulosPorPresupuesto[action.payload.id_presupuesto].push(action.payload);
        }
        
        console.log('Updated state:', {
          titulos: state.titulos.length,
          titulosPorPresupuesto: Object.keys(state.titulosPorPresupuesto).map(key => ({
            presupuesto: key,
            count: state.titulosPorPresupuesto[key].length
          }))
        });
      })
      .addCase(addTitulo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Update Titulo
      .addCase(updateTitulo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTitulo.fulfilled, (state, action: PayloadAction<Titulo>) => {
        state.loading = false;
        const index = state.titulos.findIndex(t => t.id_titulo === action.payload.id_titulo);
        if (index !== -1) {
          state.titulos[index] = action.payload;
          // Actualizar el cache si existe para este presupuesto
          if (action.payload.id_presupuesto && state.titulosPorPresupuesto[action.payload.id_presupuesto]) {
            const cacheIndex = state.titulosPorPresupuesto[action.payload.id_presupuesto]
              .findIndex(t => t.id_titulo === action.payload.id_titulo);
            if (cacheIndex !== -1) {
              state.titulosPorPresupuesto[action.payload.id_presupuesto][cacheIndex] = action.payload;
            }
          }
        }
      })
      .addCase(updateTitulo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Delete Titulo
      .addCase(deleteTitulo.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTitulo.fulfilled, (state, action: PayloadAction<{ id_titulo: string; id_presupuesto: string }>) => {
        state.loading = false;
        state.titulos = state.titulos.filter(t => t.id_titulo !== action.payload.id_titulo);
        // Actualizar el cache si existe para este presupuesto
        if (action.payload.id_presupuesto && state.titulosPorPresupuesto[action.payload.id_presupuesto]) {
          state.titulosPorPresupuesto[action.payload.id_presupuesto] = 
            state.titulosPorPresupuesto[action.payload.id_presupuesto]
              .filter(t => t.id_titulo !== action.payload.id_titulo);
        }
      })
      .addCase(deleteTitulo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Get Partidas By Proyecto
      .addCase(getPartidasByProyecto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPartidasByProyecto.fulfilled, (state, action: PayloadAction<Titulo[]>) => {
        state.loading = false;
        state.titulos = action.payload;
      })
      .addCase(getPartidasByProyecto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearErrors, getTitulosFromCache, updateTituloDetallePartida } = tituloSlice.actions;
export const tituloReducer = tituloSlice.reducer;
