import React, { useState } from 'react';

interface FiltroFechasProps {
  onChange: (startDate: Date | null, endDate: Date | null) => void;
}

const FiltroFechas: React.FC<FiltroFechasProps> = ({ onChange }) => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [daysRange, setDaysRange] = useState<number | null>(null);

  const handleApplyFilter = () => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    onChange(start, end);
  };

  const handleDaysRangeChange = (days: number) => {
    const end = new Date();
    const start = new Date();
    if (days === 0) {
 
      setStartDate(end.toISOString().split('T')[0]);
      setEndDate(end.toISOString().split('T')[0]);
    } else if (days === 7) {

      start.setDate(end.getDate() - 7);
      setStartDate(start.toISOString().split('T')[0]);
      setEndDate(end.toISOString().split('T')[0]);
    } else {
      start.setDate(end.getDate() - days);
      setStartDate(start.toISOString().split('T')[0]);
      setEndDate(end.toISOString().split('T')[0]);
    }
    setDaysRange(days);
  };

  const handleClearFilters = () => {
    setStartDate('');
    setEndDate('');
    setDaysRange(null);
    onChange(null, null);
  };

  return (
    <div className="mb-4 flex justify-end space-x-4">
      <select
        value={daysRange || ''}
        onChange={(e) => handleDaysRangeChange(Number(e.target.value))}
        className="p-2 border rounded text-sm"
      >
        <option value="">Rango de días</option>
        <option value="0">Hoy</option>
        <option value="7">Última semana</option>
        <option value="15">Últimos 15 Días</option>
        <option value="30">Últimos 30 Días</option>
        <option value="90">Últimos 90 Días</option>
      </select>
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="border rounded p-2"
        placeholder="dd/mm/aaaa"
      />
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        className="border rounded p-2"
        placeholder="dd/mm/aaaa"
      />
      <button onClick={handleApplyFilter} className="bg-blue-900 text-white rounded px-5 py-1">
        Buscar
      </button>
      <button onClick={handleClearFilters} className="bg-blue-500 text-white rounded px-5 py-2">
        Limpiar
      </button>
    </div>
  );
};

export default FiltroFechas;
