import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_TRANSFERENCIA_RECURSOS = gql`
  query ListTransferenciaRecursos {
    listTransferenciaRecursos {
      _id
      transferencia_detalle_id {
        id
        referencia_id
        fecha
        tipo
        referencia
      }
      recurso_id {
        id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
      }
      cantidad
      costo
      requerimiento_recurso_id
    }
  }
`;

const LIST_TRANSFERENCIA_RECURSOS_BY_ID = gql`
  query ListTransferenciaRecursosById($id: ID!) {
    listTransferenciaRecursosById(id: $id) {
      _id
      transferencia_detalle_id {
        id
        referencia_id
        fecha
        tipo
        referencia
      }
      recurso_id {
        id
        codigo
        nombre
        descripcion
        unidad_id
        imagenes {
          file
        }
      }
      diferencia
      cantidad
      costo
      requerimiento_recurso_id
    }
  }
`;

const ADD_TRANSFERENCIA_RECURSO = gql`
  mutation AddTransferenciaRecurso(
    $transferenciaDetalleId: ID!, 
    $recursoId: ID!, 
    $cantidad: Float!, 
    $costo: Float,
    $requerimientoRecursoId: String
  ) {
    addTransferenciaRecurso(
      transferencia_detalle_id: $transferenciaDetalleId,
      recurso_id: $recursoId,
      cantidad: $cantidad,
      costo: $costo,
      requerimiento_recurso_id: $requerimientoRecursoId
    ) {
      _id
      transferencia_detalle_id {
        id
        referencia_id
        fecha
        tipo
        referencia
      }
      recurso_id {
        id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
      }
      cantidad
      costo
      requerimiento_recurso_id
    }
  }
`;

const UPDATE_TRANSFERENCIA_RECURSO = gql`
  mutation UpdateTransferenciaRecurso(
    $updateTransferenciaRecursoId: ID!, 
    $cantidad: Float, 
    $costo: Float,
    $requerimientoRecursoId: String
  ) {
    updateTransferenciaRecurso(
      id: $updateTransferenciaRecursoId, 
      cantidad: $cantidad, 
      costo: $costo,
      requerimiento_recurso_id: $requerimientoRecursoId
    ) {
      _id
      transferencia_detalle_id {
        id
        referencia_id
        fecha
        tipo
        referencia
      }
      recurso_id {
        id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
      }
      cantidad
      costo
      requerimiento_recurso_id
    }
  }
`;

const DELETE_TRANSFERENCIA_RECURSO = gql`
  mutation DeleteTransferenciaRecurso($deleteTransferenciaRecursoId: ID!) {
    deleteTransferenciaRecurso(id: $deleteTransferenciaRecursoId) {
      _id
    }
  }
`;

export const listTransferenciaRecursosService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_TRANSFERENCIA_RECURSOS,
    });
    return data.listTransferenciaRecursos;
  } catch (error) {
    throw new Error(`Error fetching transferencia recursos: ${error}`);
  }
};

export const listTransferenciaRecursosByIdService = async (id: string) => {
  try {
    const response = await client.query({
      query: LIST_TRANSFERENCIA_RECURSOS_BY_ID,
      variables: { id },
      fetchPolicy: 'network-only'
    });
    console.log('Response from service:', response.data);
    return response.data.listTransferenciaRecursosById;
  } catch (error) {
    console.error('Error in service:', error);
    throw error;
  }
};

export const addTransferenciaRecursoService = async (data: {
  transferencia_detalle_id: string;
  recurso_id: string;
  cantidad: number;
  costo: number;
  requerimiento_recurso_id?: string;
}) => {
  try {
    const { data: responseData } = await client.mutate({
      mutation: ADD_TRANSFERENCIA_RECURSO,
      variables: {
        transferenciaDetalleId: data.transferencia_detalle_id,
        recursoId: data.recurso_id,
        cantidad: data.cantidad,
        costo: data.costo,
        requerimientoRecursoId: data.requerimiento_recurso_id,
      },
    });
    return responseData.addTransferenciaRecurso;
  } catch (error) {
    throw new Error(`Error adding transferencia recurso: ${error}`);
  }
};

export const updateTransferenciaRecursoService = async (data: {
  id: string;
  cantidad: number;
  costo: number;
  requerimiento_recurso_id?: string;
}) => {
  try {
    const { data: responseData } = await client.mutate({
      mutation: UPDATE_TRANSFERENCIA_RECURSO,
      variables: {
        updateTransferenciaRecursoId: data.id,
        cantidad: data.cantidad,
        costo: data.costo,
        requerimientoRecursoId: data.requerimiento_recurso_id,
      },
    });
    return responseData.updateTransferenciaRecurso;
  } catch (error) {
    throw new Error(`Error updating transferencia recurso: ${error}`);
  }
};

export const deleteTransferenciaRecursoService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_TRANSFERENCIA_RECURSO,
      variables: { deleteTransferenciaRecursoId: id },
    });
    return data.deleteTransferenciaRecurso;
  } catch (error) {
    throw new Error(`Error deleting transferencia recurso: ${error}`);
  }
};
