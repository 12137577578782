import React from 'react';
import { IngresoAlmacen } from '../../../services/ordenPagoService';

interface PDFIngresoAlmacenGeneratorProps {
  ingresoAlmacen: IngresoAlmacen;
}

const PDFIngresoAlmacenGenerator: React.FC<PDFIngresoAlmacenGeneratorProps> = ({ ingresoAlmacen }) => {
  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  return (
    <div className="bg-white p-8 shadow-lg max-w-4xl mx-auto">
      {/* Cabecera */}
      <div className="border-b-2 border-gray-300 pb-4 mb-6">
        <h1 className="text-2xl font-bold text-center mb-4 text-green-700">ORDEN DE INGRESO A ALMACÉN</h1>
        <div className="grid grid-cols-2 gap-4 text-green-700">
          <div>
            <p><span className="font-semibold">N° Transferencia:</span> {ingresoAlmacen.transferencia_id}</p>
            <p><span className="font-semibold">Tipo:</span> {ingresoAlmacen.tipo}</p>
            <p><span className="font-semibold">Referencia:</span> {ingresoAlmacen.referencia}</p>
          </div>
          <div>
            <p><span className="font-semibold">Fecha Orden:</span> {formatDate(ingresoAlmacen.fecha_orden)}</p>
            <p><span className="font-semibold">Fecha Ingreso:</span> {formatDate(ingresoAlmacen.fecha)}</p>
          </div>
        </div>
      </div>

      {/* Tabla de Recursos */}
      <div className="mb-6">
        <h2 className="text-lg font-semibold mb-4 text-green-700">Recursos</h2>
        <table className="min-w-full border">
          <thead className="bg-gray-100">
            <tr>
              <th className="border px-4 py-2 text-left">Código</th>
              <th className="border px-4 py-2 text-left">Nombre</th>
              <th className="border px-4 py-2 text-center">Unidad</th>
              <th className="border px-4 py-2 text-right">Cantidad</th>
              <th className="border px-4 py-2 text-right">Costo</th>
              <th className="border px-4 py-2 text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {ingresoAlmacen.recursos.map((recurso) => (
              <tr key={recurso._id}>
                <td className="border px-4 py-2">{recurso.codigo}</td>
                <td className="border px-4 py-2">{recurso.nombre}</td>
                <td className="border px-4 py-2 text-center">{recurso.unidad}</td>
                <td className="border px-4 py-2 text-right">{recurso.cantidad}</td>
                <td className="border px-4 py-2 text-right">S/ {recurso.costo.toFixed(2)}</td>
                <td className="border px-4 py-2 text-right">S/ {(recurso.cantidad * recurso.costo).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot className="bg-gray-50">
            <tr>
              <td colSpan={3} className="border px-4 py-2 font-semibold">Totales</td>
              <td className="border px-4 py-2 text-right font-semibold">{ingresoAlmacen.cantidad_total}</td>
              <td className="border px-4 py-2"></td>
              <td className="border px-4 py-2 text-right font-semibold">S/ {ingresoAlmacen.monto_total.toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      {/* Firmas */}
      <div className="grid grid-cols-3 gap-8 mt-16">
        <div className="text-center">
          <div className="border-t border-green-700 pt-2">
            <p className="font-semibold text-green-700">Entregado por</p>
          </div>
        </div>
        <div className="text-center">
          <div className="border-t border-green-700 pt-2">
            <p className="font-semibold text-green-700">Revisado por</p>
          </div>
        </div>
        <div className="text-center">
          <div className="border-t border-green-700 pt-2">
            <p className="font-semibold text-green-700">Recibido por</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFIngresoAlmacenGenerator;
