import { gql } from '@apollo/client';
import client from '../apolloClient';

export interface ComprobanteArchivos {
  id: string;
  tipo: string;
  comprobante_id: {
    id: string;
  };
  file: string;
  fecha: string;
}

export interface ComprobanteArchivosInput {
  tipo: string;
  comprobante_id: string;
  file: File;
}

const LIST_COMPROBANTE_ARCHIVOS = gql`
  query ListComprobanteArchivos {
    listComprobanteArchivos {
      id
      tipo
      comprobante_id {
        id
      }
      file
      fecha
    }
  }
`;

const GET_COMPROBANTE_ARCHIVOS_BY_COMPROBANTE = gql`
  query GetComprobanteArchivosByComprobante($comprobanteId: ID!) {
    getComprobanteArchivosByComprobante(comprobanteId: $comprobanteId) {
      id
      tipo
      comprobante_id {
        id
      }
      file
      fecha
    }
  }
`;

const DELETE_COMPROBANTE_ARCHIVO = gql`
  mutation DeleteComprobanteArchivo($id: ID!) {
    deleteComprobanteArchivo(id: $id) {
      id
    }
  }
`;

export const listComprobanteArchivosService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_COMPROBANTE_ARCHIVOS,
      fetchPolicy: 'network-only',
    });
    return data.listComprobanteArchivos;
  } catch (error) {
    throw new Error(`Error fetching comprobante archivos: ${error}`);
  }
};

export const getComprobanteArchivosByComprobanteService = async (comprobanteId: string) => {
  try {
    const { data } = await client.query({
      query: GET_COMPROBANTE_ARCHIVOS_BY_COMPROBANTE,
      variables: { comprobanteId },
      fetchPolicy: 'network-only',
    });
    return data.getComprobanteArchivosByComprobante;
  } catch (error) {
    throw new Error(`Error fetching comprobante archivos by comprobante: ${error}`);
  }
};

export const uploadComprobanteArchivoService = async (input: ComprobanteArchivosInput) => {
  try {
    const GRAPHQL_URI = import.meta.env.VITE_GRAPHQL_URI;
    const formData = new FormData();

    formData.append('operations', JSON.stringify({
      query: `
        mutation UploadComprobanteArchivo($tipo: String!, $comprobante_id: ID!, $file: Upload!) {
          uploadComprobanteArchivo(tipo: $tipo, comprobante_id: $comprobante_id, file: $file) {
            id
            tipo
            comprobante_id {
              id
            }
            file
            fecha
          }
        }
      `,
      variables: {
        tipo: input.tipo,
        comprobante_id: input.comprobante_id,
        file: null
      }
    }));

    formData.append('map', JSON.stringify({
      "0": ["variables.file"]
    }));

    formData.append('0', input.file);

    const response = await fetch(GRAPHQL_URI, {
      method: 'POST',
      body: formData,
    });

    const result = await response.json();

    if (result.errors) {
      throw new Error(result.errors[0]?.message || 'Error al subir el archivo');
    }

    return result.data.uploadComprobanteArchivo;
  } catch (error) {
    console.error('Error en uploadComprobanteArchivoService:', error);
    throw error;
  }
};

export const deleteComprobanteArchivoService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_COMPROBANTE_ARCHIVO,
      variables: { id },
    });
    return data.deleteComprobanteArchivo;
  } catch (error) {
    throw new Error(`Error deleting comprobante archivo: ${error}`);
  }
};
