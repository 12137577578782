import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { 
  getFormulario, 
  fetchFormularios,
  fetchSeccionesByFormulario,
  getCamposBySeccion,
  getOpcionesByCampo,
  deleteFormulario,
  fetchRespuestasByFormulario,
  deleteRespuesta,
  Formulario
} from '../../slices/formularioSlice';
import SeccionForm from './SeccionForm';
import CampoForm from './CampoForm';
import OpcionForm from './OpcionForm';
import FormularioForm from './FormularioForm';
import Modal from '../../components/Modal/Modal';
import { 
  MdExpandMore,
  MdAdd,
  MdEdit,
  MdDelete,
  MdViewList,
  MdDescription,
  MdChevronRight,
  MdRefresh
} from 'react-icons/md';

// Interface para TabPanel
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="p-3">
          {children}
        </div>
      )}
    </div>
  );
};

const FormularioDetail: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formularios, selectedFormulario, secciones, loading, error, respuestas } = useSelector((state: RootState) => state.formulario);
  
  const [tabValue, setTabValue] = useState(0);
  const [expandedSeccion, setExpandedSeccion] = useState<string | false>(false);
  const [expandedCampo, setExpandedCampo] = useState<string | false>(false);
  
  // Estados para modales
  const [openSeccionForm, setOpenSeccionForm] = useState(false);
  const [openCampoForm, setOpenCampoForm] = useState(false);
  const [openOpcionForm, setOpenOpcionForm] = useState(false);
  const [selectedSeccionId, setSelectedSeccionId] = useState<string | null>(null);
  const [selectedCampoId, setSelectedCampoId] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  
  // Estado para manejar el formulario seleccionado
  const [activeFormularioId, setActiveFormularioId] = useState<string | null>(null);
  const usuarios = useSelector((state: RootState) => state.usuario.usuarios);
  
  // Estados para el modal de formulario
  const [openFormularioForm, setOpenFormularioForm] = useState(false);
  const [selectedFormularioForEdit, setSelectedFormularioForEdit] = useState<Formulario | null>(null);
  
  // Cargar lista de formularios al inicio
  useEffect(() => {
    dispatch(fetchFormularios());
  }, [dispatch]);
  
  // Cargar formulario cuando se selecciona uno
  useEffect(() => {
    if (activeFormularioId) {
      dispatch(getFormulario(activeFormularioId));
      dispatch(fetchSeccionesByFormulario(activeFormularioId));
      dispatch(fetchRespuestasByFormulario(activeFormularioId));
    }
  }, [dispatch, activeFormularioId]);

  // Cargar campos cuando se expande una sección
  const handleExpandSeccion = (seccionId: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedSeccion(isExpanded ? seccionId : false);
    if (isExpanded) {
      dispatch(getCamposBySeccion(seccionId));
    }
  };

  // Cargar opciones cuando se expande un campo
  const handleExpandCampo = (campoId: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedCampo(isExpanded ? campoId : false);
    if (isExpanded) {
      dispatch(getOpcionesByCampo(campoId));
    }
  };

  // Manejadores para abrir formularios
  const handleOpenSeccionForm = (seccionId?: string) => {
    if (seccionId) {
      setSelectedSeccionId(seccionId);
      setIsEditMode(true);
    } else {
      setSelectedSeccionId(null);
      setIsEditMode(false);
    }
    setOpenSeccionForm(true);
  };

  const handleOpenCampoForm = (seccionId: string, campoId?: string) => {
    setSelectedSeccionId(seccionId);
    if (campoId) {
      setSelectedCampoId(campoId);
      setIsEditMode(true);
    } else {
      setSelectedCampoId(null);
      setIsEditMode(false);
    }
    setOpenCampoForm(true);
  };

  const handleOpenOpcionForm = (campoId: string) => {
    setSelectedCampoId(campoId);
    setOpenOpcionForm(true);
  };

  // Manejadores para cerrar formularios
  const handleCloseSeccionForm = () => {
    setOpenSeccionForm(false);
    setSelectedSeccionId(null);
    setIsEditMode(false);
  };

  const handleCloseCampoForm = () => {
    setOpenCampoForm(false);
    setSelectedSeccionId(null);
    setSelectedCampoId(null);
    setIsEditMode(false);
  };

  const handleCloseOpcionForm = () => {
    setOpenOpcionForm(false);
    setSelectedCampoId(null);
  };

  // Cambiar de tab
  const handleChangeTab = (newValue: number) => {
    setTabValue(newValue);
  };
  
  // Manejar la selección de un formulario
  const handleSelectFormulario = (formularioId: string) => {
    setActiveFormularioId(formularioId);
    // Resetear estados
    setTabValue(0);
    setExpandedSeccion(false);
    setExpandedCampo(false);
  };
  
  // Refrescar la lista de formularios
  const handleRefreshFormularios = () => {
    dispatch(fetchFormularios());
  };
  
  // Eliminar un formulario
  const handleDeleteFormulario = (formularioId: string) => {
    if (window.confirm('¿Está seguro de eliminar este formulario? Esta acción no se puede deshacer.')) {
      dispatch(deleteFormulario(formularioId));
      if (activeFormularioId === formularioId) {
        setActiveFormularioId(null);
      }
    }
  };

  // Manejadores para el formulario
  const handleOpenFormularioForm = (formulario?: Formulario) => {
    if (formulario) {
      setSelectedFormularioForEdit(formulario);
    } else {
      setSelectedFormularioForEdit(null);
    }
    setOpenFormularioForm(true);
  };

  const handleCloseFormularioForm = () => {
    setOpenFormularioForm(false);
    setSelectedFormularioForEdit(null);
  };

  const renderFormularioPanel = () => {
    if (!activeFormularioId || !selectedFormulario) {
      return (
        <div className="flex items-center justify-center h-full">
          <div className="text-center p-8 bg-gray-50 rounded-lg border border-gray-200 max-w-md">
            <MdDescription className="mx-auto text-gray-400 text-5xl mb-4" />
            <h3 className="text-xl font-medium text-gray-700 mb-2">Ningún formulario seleccionado</h3>
            <p className="text-gray-500 mb-4">Seleccione un formulario de la lista para ver sus detalles o crear uno nuevo.</p>
          </div>
        </div>
      );
    }

    return (
      <div className="h-[100%] overflow-y-auto">
        <div className="border-b border-gray-200 bg-white sticky top-0 z-10 shadow-sm">
          <div className="flex">
            <button 
              className={`py-3 px-4 font-medium transition-colors ${tabValue === 0 ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'}`}
              onClick={() => handleChangeTab(0)}
            >
              Estructura
            </button>
            <button 
              className={`py-3 px-4 font-medium transition-colors ${tabValue === 1 ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'}`}
              onClick={() => handleChangeTab(1)}
            >
              Visualización
            </button>
            <button 
              className={`py-3 px-4 font-medium transition-colors ${tabValue === 2 ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'}`}
              onClick={() => handleChangeTab(2)}
            >
              Respuestas
            </button>
          </div>
        </div>

        {/* Tab de Estructura */}
        <TabPanel value={tabValue} index={0}>
          <div className="mb-4 flex justify-between items-center">
            <h3 className="text-lg font-semibold text-gray-800">Secciones</h3>
            <button 
              className="bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded-md text-sm flex items-center transition-colors shadow-sm"
              onClick={() => handleOpenSeccionForm()}
            >
              <MdAdd className="mr-1" /> Añadir Sección
            </button>
          </div>

          {secciones.length === 0 ? (
            <div className="bg-gray-50 border border-gray-200 rounded-lg p-6 text-center">
              <p className="text-gray-500">
                Este formulario no tiene secciones aún. Añada una sección para comenzar.
              </p>
            </div>
          ) : (
            secciones.map((seccion) => (
              <div 
                key={seccion.id} 
                className="mb-4 border border-gray-200 rounded-lg shadow-sm bg-white overflow-hidden transition-all hover:shadow-md"
              >
                <div 
                  className="p-4 flex items-center justify-between cursor-pointer border-b bg-gray-50"
                  onClick={(e) => handleExpandSeccion(seccion.id)(e, expandedSeccion !== seccion.id)}
                >
                  <div className="flex items-center">
                    <div className="relative mr-3 bg-white p-2 rounded-full shadow-sm">
                      <MdViewList className="text-indigo-500" />
                      <span className="absolute -top-1 -right-1 bg-indigo-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                        {seccion.campos?.length || 0}
                      </span>
                    </div>
                    <span className="font-medium text-gray-800">
                      {seccion.titulo} 
                      <span className="ml-2 text-xs text-gray-500 font-normal">
                        (Orden: {seccion.orden})
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center">
                    <button 
                      className="p-2 text-gray-500 hover:text-indigo-500 rounded-full hover:bg-gray-100 transition-colors"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenSeccionForm(seccion.id);
                      }}
                      title="Editar Sección"
                    >
                      <MdEdit />
                    </button>
                    <MdExpandMore className={`ml-1 text-gray-500 transform transition-transform ${expandedSeccion === seccion.id ? 'rotate-180' : ''}`} />
                  </div>
                </div>
                {expandedSeccion === seccion.id && (
                  <div className="p-4">
                    <div className="mb-4">
                      {seccion.descripcion && (
                        <p className="text-sm text-gray-600 mb-3 bg-gray-50 p-3 rounded-md">
                          {seccion.descripcion}
                        </p>
                      )}
                      {seccion.condicionalMostrar && (
                        <p className="text-sm bg-yellow-50 text-yellow-700 p-3 rounded-md border border-yellow-200">
                          <strong>Condición:</strong> {seccion.condicionalMostrar}
                        </p>
                      )}
                    </div>

                    <div className="mb-4 flex justify-between items-center">
                      <h4 className="text-base font-semibold text-gray-700">Campos</h4>
                      <button 
                        className="border border-indigo-500 text-indigo-500 hover:bg-indigo-50 py-1 px-3 rounded-md text-sm flex items-center transition-colors"
                        onClick={() => handleOpenCampoForm(seccion.id)}
                      >
                        <MdAdd className="mr-1" /> Añadir Campo
                      </button>
                    </div>

                    {!seccion.campos || seccion.campos.length === 0 ? (
                      <div className="bg-gray-50 border border-gray-200 rounded-md p-4 text-center">
                        <p className="text-sm text-gray-500">
                          Esta sección no tiene campos aún. Añada un campo para comenzar.
                        </p>
                      </div>
                    ) : (
                      <div className="grid gap-4">
                        {seccion.campos.map((campo) => (
                          <div className="col-span-12" key={campo.id}>
                            <div className="border border-gray-200 rounded-lg shadow-sm bg-white overflow-hidden hover:shadow-md transition-all">
                              <div 
                                className="p-3 flex items-center justify-between cursor-pointer border-b bg-gray-50"
                                onClick={(e) => handleExpandCampo(campo.id)(e, expandedCampo !== campo.id)}
                              >
                                <div className="flex items-center">
                                  <span className="font-medium text-gray-700">
                                    {campo.etiqueta} 
                                    <span className="ml-1 text-xs text-gray-500 font-normal">
                                      ({campo.tipo})
                                    </span>
                                    <span className="ml-1 text-xs text-indigo-600 font-medium">
                                      {campo.requerido ? '* Requerido' : 'Opcional'}
                                    </span>
                                  </span>
                                </div>
                                <div className="flex items-center">
                                  <button 
                                    className="p-1 text-gray-500 hover:text-indigo-500 rounded-full hover:bg-gray-100 transition-colors"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleOpenCampoForm(seccion.id, campo.id);
                                    }}
                                    title="Editar Campo"
                                  >
                                    <MdEdit />
                                  </button>
                                  <MdExpandMore className={`ml-2 transform transition-transform text-gray-500 ${expandedCampo === campo.id ? 'rotate-180' : ''}`} />
                                </div>
                              </div>
                              {expandedCampo === campo.id && (
                                <div className="p-4">
                                  <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-12 sm:col-span-6">
                                      <p className="text-sm mb-2">
                                        <span className="font-semibold text-gray-600">Nombre:</span> {campo.nombre}
                                      </p>
                                      <p className="text-sm mb-2">
                                        <span className="font-semibold text-gray-600">Tipo:</span> {campo.tipo}
                                      </p>
                                      <p className="text-sm mb-2">
                                        <span className="font-semibold text-gray-600">Orden:</span> {campo.orden}
                                      </p>
                                      <p className="text-sm">
                                        <span className="font-semibold text-gray-600">Ancho:</span> {campo.ancho}
                                      </p>
                                    </div>
                                    <div className="col-span-12 sm:col-span-6">
                                      {campo.valorPredeterminado && (
                                        <p className="text-sm mb-2">
                                          <span className="font-semibold text-gray-600">Valor predeterminado:</span> {campo.valorPredeterminado}
                                        </p>
                                      )}
                                      {campo.placeholder && (
                                        <p className="text-sm mb-2">
                                          <span className="font-semibold text-gray-600">Placeholder:</span> {campo.placeholder}
                                        </p>
                                      )}
                                      {campo.condicionalMostrar && (
                                        <p className="text-sm mb-2 bg-yellow-50 p-2 rounded-md border border-yellow-200">
                                          <span className="font-semibold text-yellow-700">Condición:</span> {campo.condicionalMostrar}
                                        </p>
                                      )}
                                    </div>
                                    
                                    {['select', 'radio', 'checkbox'].includes(campo.tipo) && (
                                      <div className="col-span-12">
                                        <div className="mt-4 mb-3 flex justify-between items-center">
                                          <h5 className="text-sm font-semibold text-gray-700">Opciones</h5>
                                          <button 
                                            className="border border-indigo-500 text-indigo-500 hover:bg-indigo-50 py-1 px-2 rounded-md text-xs flex items-center transition-colors"
                                            onClick={() => handleOpenOpcionForm(campo.id)}
                                          >
                                            <MdAdd className="mr-1" /> Añadir Opción
                                          </button>
                                        </div>
                                        
                                        {!campo.opciones || campo.opciones.length === 0 ? (
                                          <div className="bg-gray-50 border border-gray-200 rounded-md p-3 text-center">
                                            <p className="text-sm text-gray-500">
                                              Este campo no tiene opciones aún. Añada opciones para que los usuarios puedan seleccionar.
                                            </p>
                                          </div>
                                        ) : (
                                          <div className="bg-white rounded-md border border-gray-200">
                                            {campo.opciones.map((opcion, index) => (
                                              <div 
                                                key={opcion.id} 
                                                className={`flex justify-between items-center p-2 ${index !== campo.opciones.length - 1 ? 'border-b border-gray-100' : ''}`}
                                              >
                                                <p className="text-sm">
                                                  <span className="font-medium">{opcion.etiqueta}</span> = {opcion.valor} 
                                                  <span className="ml-1 text-xs text-gray-500">(Orden: {opcion.orden})</span>
                                                </p>
                                                <div>
                                                  <button className="p-1 text-gray-500 hover:text-indigo-500 rounded-full hover:bg-gray-100 transition-colors">
                                                    <MdEdit className="text-sm" />
                                                  </button>
                                                  <button className="p-1 text-gray-500 hover:text-red-500 rounded-full hover:bg-gray-100 transition-colors">
                                                    <MdDelete className="text-sm" />
                                                  </button>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          )}
        </TabPanel>

        {/* Tab de Visualización */}
        <TabPanel value={tabValue} index={1}>
          <div className="bg-white shadow-md rounded-lg p-6 border border-gray-200">
            <h2 className="text-xl font-semibold mb-2 text-gray-800">
              {selectedFormulario.titulo}
            </h2>
            {selectedFormulario.descripcion && (
              <p className="mb-4 text-gray-600">
                {selectedFormulario.descripcion}
              </p>
            )}
            
            {secciones.map((seccion) => (
              <div key={seccion.id} className="mb-6 border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                <div className="border-b p-4 bg-gray-50">
                  <h3 className="text-lg font-medium text-gray-800">{seccion.titulo}</h3>
                  {seccion.descripcion && <p className="text-sm text-gray-600 mt-1">{seccion.descripcion}</p>}
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-12 gap-4">
                    {seccion.campos?.map((campo) => (
                      <div className={`col-span-12 ${campo.ancho === 12 ? '' : campo.ancho === 6 ? 'md:col-span-6' : 'md:col-span-4'}`} key={campo.id}>
                        <div className="mb-4">
                          <label className="block text-sm font-medium mb-1 text-gray-700">
                            {campo.etiqueta} {campo.requerido && <span className="text-red-500">*</span>}
                          </label>
                          
                          {/* Renderizar diferentes controles según el tipo */}
                          {campo.tipo === 'text' && (
                            <input 
                              type="text"
                              className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                              placeholder={campo.placeholder} 
                              disabled
                            />
                          )}
                          {campo.tipo === 'textarea' && (
                            <textarea 
                              className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                              rows={3}
                              placeholder={campo.placeholder} 
                              disabled
                            ></textarea>
                          )}
                          {campo.tipo === 'select' && (
                            <select 
                              className="w-full border border-gray-300 rounded-md py-2 px-3 text-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                              disabled
                            >
                              <option value="">Seleccione...</option>
                              {campo.opciones?.map((opcion) => (
                                <option key={opcion.id} value={opcion.valor}>
                                  {opcion.etiqueta}
                                </option>
                              ))}
                            </select>
                          )}
                          {/* Otros tipos podrían añadirse aquí */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>

        {/* Tab de Respuestas */}
        <TabPanel value={tabValue} index={2}>
          <div className="bg-white shadow-md rounded-lg p-6 border border-gray-200">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-800">Respuestas del Formulario</h2>
              <button 
                className="bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded-md text-sm flex items-center transition-colors shadow-sm"
                onClick={() => activeFormularioId && dispatch(fetchRespuestasByFormulario(activeFormularioId))}
              >
                <MdRefresh className="mr-1" /> Actualizar
              </button>
            </div>

            {respuestas.length === 0 ? (
              <div className="text-center p-8 bg-gray-50 rounded-lg border border-gray-200">
                <MdDescription className="mx-auto text-gray-400 text-4xl mb-4" />
                <p className="text-gray-600">
                  No hay respuestas registradas para este formulario.
                </p>
              </div>
            ) : (
              <div className="space-y-4">
                {respuestas.map((respuesta) => {
                  let datos;
                  try {
                    datos = JSON.parse(respuesta.datosJson);
                  } catch (error) {
                    console.error('Error al parsear datos JSON:', error);
                    datos = {};
                  }

                  return (
                    <div key={respuesta.id} className="border border-gray-200 rounded-lg overflow-hidden">
                      <div className="bg-gray-50 p-4 border-b border-gray-200">
                        <div className="flex justify-between items-center">
                          <div>
                            <span className="text-sm font-medium text-gray-700">
                              Respuesta #{usuarios.find(u => u.id === respuesta.usuarioId)?.nombres + " " + usuarios.find(u => u.id === respuesta.usuarioId)?.apellidos}
                            </span>
                            <span className="ml-2 text-xs text-gray-500">
                              Fecha: {new Date(respuesta.fechaCreacion).toLocaleString()}
                            </span>
                          </div>
                          <div className="flex space-x-2">
                            <button 
                              className="p-1 text-gray-500 hover:text-indigo-500 rounded-full hover:bg-gray-100 transition-colors"
                              onClick={() => window.open(`/formularios/responder/${activeFormularioId}?respuestaId=${respuesta.id}`, '_blank')}
                              title="Ver respuesta completa"
                            >
                              <MdViewList />
                            </button>
                            <button 
                              className="p-1 text-gray-500 hover:text-red-500 rounded-full hover:bg-gray-100 transition-colors"
                              onClick={() => {
                                if (window.confirm('¿Está seguro de eliminar esta respuesta?')) {
                                  dispatch(deleteRespuesta(respuesta.id));
                                }
                              }}
                              title="Eliminar respuesta"
                            >
                              <MdDelete />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="p-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {Object.entries(datos).map(([nombre, valor]) => {
                            const campo = secciones && secciones.length > 0
                              ? secciones
                                  .filter(s => s && s.campos)
                                  .flatMap(s => s.campos || [])
                                  .find(c => c && c.nombre === nombre)
                              : null;

                            return (
                              <div key={nombre} className="bg-gray-50 p-3 rounded-md">
                                <div className="text-sm font-medium text-gray-700 mb-1">
                                  {campo?.etiqueta || nombre}
                                </div>
                                <div className="text-sm text-gray-600">
                                  {Array.isArray(valor) ? valor.join(', ') : String(valor)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </TabPanel>
      </div>
    );
  };

  if (loading && !formularios.length) {
    return <div className="p-4 text-center">Cargando...</div>;
  }

  if (error && !formularios.length) {
    return <div className="p-4 text-center text-red-500">Error: {error}</div>;
  }

  return (
    <div className="flex h-[calc(100vh-6.1rem)] bg-gray-100">
      {/* Panel izquierdo - Lista de formularios */}
      <div className="w-80 border-r border-gray-200 bg-white shadow-md flex flex-col h-full">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center bg-indigo-50">
          <h2 className="text-xl font-semibold text-indigo-700">Formularios</h2>
          <button 
            onClick={handleRefreshFormularios}
            className="p-2 text-indigo-600 hover:text-indigo-800 hover:bg-indigo-100 rounded-full transition-colors"
            title="Refrescar lista"
          >
            <MdRefresh />
          </button>
        </div>
        
        <div className="overflow-y-auto flex-grow">
          {formularios.length === 0 ? (
            <div className="p-4 text-center text-gray-500">
              No hay formularios disponibles.
            </div>
          ) : (
            <div className="divide-y divide-gray-200">
              {formularios.map((formulario) => (
                <div 
                  key={formulario.id}
                  className={`p-3 hover:bg-gray-50 cursor-pointer transition-colors ${activeFormularioId === formulario.id ? 'bg-indigo-50 border-l-4 border-indigo-500' : ''}`}
                  onClick={() => handleSelectFormulario(formulario.id)}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex-grow">
                      <div className="font-medium text-gray-800">{formulario.titulo}</div>
                      <div className="text-xs text-gray-500 mt-1">
                        Código: {formulario.codigo} | Versión: {formulario.version}
                      </div>
                      <div className="text-xs text-gray-500">
                        Creado: {new Date(formulario.fechaCreacion).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="flex">
                      <button 
                        className="p-1 text-gray-400 hover:text-indigo-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenFormularioForm(formulario);
                        }}
                        title="Editar formulario"
                      >
                        <MdEdit />
                      </button>
                      <button 
                        className="p-1 text-gray-400 hover:text-red-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteFormulario(formulario.id);
                        }}
                        title="Eliminar formulario"
                      >
                        <MdDelete />
                      </button>
                      <MdChevronRight className={`ml-1 transition-colors ${activeFormularioId === formulario.id ? 'text-indigo-500' : 'text-gray-400'}`} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div className="p-3 border-t border-gray-200 bg-gray-50">
          <button 
            className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md font-medium flex items-center justify-center transition-colors shadow-sm"
            onClick={() => handleOpenFormularioForm()}
          >
            <MdAdd className="mr-2" /> Nuevo Formulario
          </button>
        </div>
      </div>
      
      {/* Panel derecho - Detalle del formulario seleccionado */}
      <div className="flex-grow overflow-hidden flex flex-col h-full">
        {renderFormularioPanel()}
      </div>

      {/* Modales para crear/editar elementos */}
      {openFormularioForm && (
        <Modal
          isOpen={openFormularioForm}
          onClose={handleCloseFormularioForm}
          title={selectedFormularioForEdit ? 'Editar Formulario' : 'Nuevo Formulario'}
        >
          <FormularioForm 
            formulario={selectedFormularioForEdit}
            isEditMode={!!selectedFormularioForEdit}
            onClose={handleCloseFormularioForm}
          />
        </Modal>
      )}

      {openSeccionForm && activeFormularioId && (
        <Modal
          isOpen={openSeccionForm}
          onClose={handleCloseSeccionForm}
          title={isEditMode ? 'Editar Sección' : 'Nueva Sección'}
        >
          <SeccionForm 
            formularioId={activeFormularioId}
            seccionId={selectedSeccionId}
            isEditMode={isEditMode}
            onClose={handleCloseSeccionForm}
          />
        </Modal>
      )}

      {openCampoForm && selectedSeccionId && (
        <Modal
          isOpen={openCampoForm}
          onClose={handleCloseCampoForm}
          title={isEditMode ? 'Editar Campo' : 'Nuevo Campo'}
        >
          <CampoForm 
            seccionId={selectedSeccionId}
            campoId={selectedCampoId}
            isEditMode={isEditMode}
            onClose={handleCloseCampoForm}
          />
        </Modal>
      )}

      {openOpcionForm && selectedCampoId && (
        <Modal
          isOpen={openOpcionForm}
          onClose={handleCloseOpcionForm}
          title="Gestionar Opciones"
        >
          <OpcionForm 
            campoId={selectedCampoId}
            onClose={handleCloseOpcionForm}
          />
        </Modal>
      )}
    </div>
  );
};

export default FormularioDetail; 