import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { OrdenCompra } from '../../types/ordenCompra';
import { fetchOrdenCompraRecursosByOrdenId } from '../../slices/ordenCompraRecursosSlice';
import { fetchProveedorByOrdenCompraId, updateOrdenCompra } from '../../slices/ordenCompraSlice';
import { fetchOrdenServicioFechasByOrdenId } from '../../slices/ordenServicioFechaSlice';
import { OrdenServicioFecha } from '../../types/ordenServicioFecha';
import { AppDispatch, RootState } from '../../store/store';
import LoaderPage from '../../components/Loader/LoaderPage';
import { updateCotizacion } from '../../slices/cotizacionSlice';
import { fetchAprobacionGeneralById } from '../../slices/aprobacionGeneralSlice';
import { fetchUsuarioById } from '../../slices/usuarioSlice';

interface CotizacionId {
  id: string;
}

interface ValidarServicioProps {
  onClose: () => void;
  ordenServicio: OrdenCompra;
}

const ValidarServicio: React.FC<ValidarServicioProps> = ({ onClose, ordenServicio }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showRechazo, setShowRechazo] = useState(false);
  const [showValidar, setShowValidar] = useState(true);
  const [motivoRechazo, setMotivoRechazo] = useState('');
  const [userData, setUserData] = useState<any>(null);
  const { ordenCompraRecursosByOrdenId, loading: loadingRecursos, error: errorRecursos } = useSelector(
    (state: RootState) => state.ordenCompraRecursos
  );
  const { proveedorOrdenCompra, loading: loadingProveedor, error: errorProveedor } = useSelector(
    (state: RootState) => state.ordenCompra
  );
  const { ordenServicioFechas, loading: loadingFechas, error: errorFechas } = useSelector(
    (state: RootState) => state.ordenServicioFecha
  );

  const { selectedAprobacion, loading: loadingAprobacion, error: errorAprobacion } = useSelector(
    (state: RootState) => state.aprobacionGeneral
  );

  useEffect(() => {
    if (ordenServicio.id) {
      dispatch(fetchOrdenCompraRecursosByOrdenId(ordenServicio.id));
      dispatch(fetchProveedorByOrdenCompraId(ordenServicio.id));
      dispatch(fetchAprobacionGeneralById(ordenServicio.id));
    }
  }, [dispatch, ordenServicio.id]);

  useEffect(() => {
    if (selectedAprobacion && Array.isArray(selectedAprobacion) && selectedAprobacion[0]?.usuario_id) {
      dispatch(fetchUsuarioById(selectedAprobacion[0].usuario_id))
        .unwrap()
        .then((user) => {
          setUserData(user);
        })
        .catch((error) => {
          console.error('Error fetching user:', error);
        });
    }
  }, [dispatch, selectedAprobacion]);


  useEffect(() => {
    if (ordenCompraRecursosByOrdenId.length > 0) {
      ordenCompraRecursosByOrdenId.forEach(recurso => {
        dispatch(fetchOrdenServicioFechasByOrdenId(recurso.id));
      });
    }
  }, [dispatch, ordenCompraRecursosByOrdenId]);



  const handleConfirmar = async () => {
    try {
      const cotizacionId = typeof ordenServicio.cotizacion_id === 'object'
        ? (ordenServicio.cotizacion_id as CotizacionId).id
        : ordenServicio.cotizacion_id;

      await dispatch(updateOrdenCompra({
        id: ordenServicio.id,
        estado: 'completada'
      })).unwrap();

      await dispatch(updateCotizacion({
        id: cotizacionId,
        estado: 'OSCompletada'
      })).unwrap();

      onClose();
    } catch (error) {
      console.error('Error al actualizar el estado de la cotización:', error);
    }
  };

  const handleRechazar = async () => {
    if (!showRechazo) {
      setShowRechazo(true);
      setShowValidar(false);
      return;
    }
    if (!motivoRechazo.trim()) {
      alert('Por favor, ingrese el motivo del rechazo');
      return;
    }

    try {
      const cotizacionId = typeof ordenServicio.cotizacion_id === 'object'
        ? (ordenServicio.cotizacion_id as CotizacionId).id
        : ordenServicio.cotizacion_id;

      await dispatch(updateOrdenCompra({
        id: ordenServicio.id,
        estado: 'rechazado',
        descripcion: motivoRechazo
      })).unwrap();

      await dispatch(updateCotizacion({
        id: cotizacionId,
        estado: 'OSRechazada'
      })).unwrap();

      setShowValidar(false);
      onClose();
    } catch (error) {
      console.error('Error al rechazar la orden de compra:', error);
    }
  };

  if (loadingRecursos || loadingProveedor || loadingFechas || loadingAprobacion) return <LoaderPage />;
  if (errorRecursos || errorProveedor || errorFechas || errorAprobacion) 
    return <div className="text-red-500">Error: {errorRecursos || errorProveedor || errorFechas || errorAprobacion}</div>;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.3 }}
      className="w-full bg-gray-900 p-6 rounded-xl shadow-lg"
    >
      <div className="p-4">
        <div className="mb-4">
          <h3 className="text-lg font-semibold text-white mb-2">Detalles de la Orden de Servicio</h3>
          <div className="bg-gray-800 p-4 rounded-lg">
            <div className="grid grid-cols-3 gap-4">
              <div>
                <p className="text-white text-sm"><span className="font-semibold">Código de Orden :</span> {ordenServicio.codigo_orden}</p>
                <p className="text-white text-sm capitalize"><span className="font-semibold">Tipo de Orden :</span> {ordenServicio.tipo}</p>
                <p className="text-white text-sm capitalize"><span className="font-semibold">Estado :</span> <span className="bg-green-500 text-white px-1 py-0 rounded-md">{ordenServicio.estado}</span></p>
                <p className="text-white text-sm"><span className="font-semibold">Fecha de Inicio :</span> {new Date(ordenServicio.fecha_ini).toLocaleDateString()}</p>
                <p className="text-white text-sm"><span className="font-semibold">Fecha de Fin :</span> {new Date(ordenServicio.fecha_fin).toLocaleDateString()}</p>
              </div>
              <div>
                {proveedorOrdenCompra?.proveedor ? (
                  <>
                    <p className="text-white text-sm"><span className="font-semibold">Proveedor :</span> {proveedorOrdenCompra.proveedor.nombre_comercial}</p>
                    <p className="text-white text-sm"><span className="font-semibold">RUC :</span> {proveedorOrdenCompra.proveedor.ruc}</p>
                    <p className="text-white text-sm"><span className="font-semibold">Dirección :</span> {proveedorOrdenCompra.proveedor.direccion}</p>
                  </>
                ) : (
                  <p className="text-white text-sm">No hay información del proveedor disponible</p>
                )}
              </div>
              <div>
                {userData ? (
                  <>
                    <p className="text-white text-sm">
                      <span className="font-semibold">Responsable de aprobación: </span> 
                      <span className='bg-blue-500 rounded px-1 uppercase'>{userData.nombres} {userData.apellidos}</span>
                    </p>
                  </>
                ) : (
                  <p className="text-white text-sm">No hay información del responsable disponible</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold text-white mb-2">Recursos Asociados</h3>
          <div className="bg-gray-800 p-4 rounded-lg">
            {ordenCompraRecursosByOrdenId.length === 0 ? (
              <p className="text-white text-sm">No hay recursos asociados</p>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full border-collapse border border-gray-700">
                  <thead>
                    <tr className="bg-gray-700">
                      <th className="border border-gray-600 px-4 py-2 text-white text-sm">Recurso</th>
                      <th className="border border-gray-600 px-4 py-2 text-white text-sm">Código</th>
                      <th className="border border-gray-600 px-4 py-2 text-white text-sm">Cantidad</th>
                      <th className="border border-gray-600 px-4 py-2 text-white text-sm">Costo Real</th>
                      <th className="border border-gray-600 px-4 py-2 text-white text-sm">Costo Aproximado</th>
                      <th className="border border-gray-600 px-4 py-2 text-white text-sm">Fecha de Inicio</th>
                      <th className="border border-gray-600 px-4 py-2 text-white text-sm">Fecha de Fin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordenCompraRecursosByOrdenId.map((recurso) => {
                      const fechasRecurso = ordenServicioFechas.find(
                        (fecha: OrdenServicioFecha) => fecha.orden_servicio_recurso_id === recurso.id
                      );
                      return (
                        <tr key={recurso.id} className="hover:bg-gray-700">
                          <td className="border border-gray-600 px-4 py-2 text-white text-sm">{recurso.id_recurso.nombre}</td>
                          <td className="border border-gray-600 px-4 py-2 text-white text-sm">{recurso.id_recurso.codigo}</td>
                          <td className="border border-gray-600 px-4 py-2 text-white text-sm text-center">{recurso.cantidad}</td>
                          <td className="border border-gray-600 px-4 py-2 text-white text-sm text-center">S/ {recurso.costo_real.toFixed(2)}</td>
                          <td className="border border-gray-600 px-4 py-2 text-white text-sm text-center">S/ {recurso.costo_aproximado.toFixed(2)}</td>
                          <td className="border border-gray-600 px-4 py-2 text-white text-sm text-center">
                            {fechasRecurso ? new Date(fechasRecurso.fecha_inicio).toLocaleDateString() : new Date(ordenServicio.fecha_ini).toLocaleDateString()}
                          </td>
                          <td className="border border-gray-600 px-4 py-2 text-white text-sm text-center">
                            {fechasRecurso ? new Date(fechasRecurso.fecha_fin).toLocaleDateString() : new Date(ordenServicio.fecha_fin).toLocaleDateString()}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="bg-gray-700 font-semibold">
                      <td colSpan={3} className="border border-gray-600 px-4 py-2 text-white text-sm text-right">Total General:</td>
                      <td className="border border-gray-600 px-4 py-2 text-white text-sm text-center">
                        S/ {ordenCompraRecursosByOrdenId.reduce((sum, recurso) => sum + recurso.costo_real, 0).toFixed(2)}
                      </td>
                      <td className="border border-gray-600 px-4 py-2 text-white text-sm text-center">
                        S/ {ordenCompraRecursosByOrdenId.reduce((sum, recurso) => sum + recurso.costo_aproximado, 0).toFixed(2)}
                      </td>
                      <td colSpan={2} className="border border-gray-600 px-4 py-2 text-white text-sm text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        <p className="text-lg text-white text-center mb-4">¿Está seguro que desea validar el servicio?</p>
        <div className="flex flex-col gap-4">
          {showRechazo && (
            <div className="mt-4">
              <label htmlFor="motivoRechazo" className="block text-white text-sm mb-2">
                Motivo del Rechazo:
              </label>
              <textarea
                id="motivoRechazo"
                value={motivoRechazo}
                onChange={(e) => setMotivoRechazo(e.target.value)}
                className="w-full bg-gray-800 text-white p-2 rounded-md border border-gray-700 focus:border-blue-500 focus:outline-none"
                rows={4}
                placeholder="Ingrese el motivo del rechazo..."
              />
            </div>
          )}
          <div className="flex justify-center gap-2">
            <button className="bg-gray-300 text-gray-900 text-sm px-4 py-1 rounded-md" onClick={onClose}>Cancelar</button>
            {showValidar && (
              <button className="bg-green-500 text-white text-sm px-4 py-1 rounded-md" onClick={handleConfirmar}>Validar Servicio</button>
            )}
            <button
              className="bg-red-500 text-white text-sm px-4 py-1 rounded-md"
              onClick={handleRechazar}
            >
              {showRechazo ? 'Confirmar Rechazo' : 'Rechazar Servicio'}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ValidarServicio;