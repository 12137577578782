import React, { useState, useCallback, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../store/store';
import { updateRequerimiento } from '../../../../slices/requerimientoSlice';
import { getComentariosByReferenciaId } from '../../../../slices/comentarioSlice';
import LoaderPage from '../../../../components/Loader/LoaderPage';
import Toast from '../../../../components/Toast/Toast';
import { ReadOnlyView } from './ReadOnlyView';
import { EditView } from './EditView';
import { formatDateForInput } from '../../../../components/Utils/dateUtils';
import { Requerimiento, FormData } from './types';
import { Obra } from '../../../../slices/obrasSlice';
import { FiAlertTriangle } from 'react-icons/fi';
interface DetalleRequerimientoProps {
  requerimiento: Requerimiento;
  obras: {
    obras: Obra[];
  };
  onClose: () => void;
  hasZeroPriceRecursos?: boolean; // Nueva prop opcional
  hasZeroQuantityRecursos?: boolean; // Nueva prop
  isEmptyRecursos?: boolean; // Nueva prop
  isEditingRequerimiento?: boolean; // Nueva prop
}


const RequerimientoHeader: React.FC<DetalleRequerimientoProps> = memo(({ 
  requerimiento, 
  obras, 
  onClose, 
  hasZeroPriceRecursos = false, // Default a false
  hasZeroQuantityRecursos = false, // Default a false
  isEmptyRecursos = true, // Default a true
  isEditingRequerimiento // Default a false
}) => {
  console.log('RequerimientoHeader.tsx', isEditingRequerimiento); // Log a la consola
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { usuariosCargo } = useSelector((state: RootState) => state.usuario);
  const comentarios = useSelector((state: RootState) => state.comentario.comentariosByReferencia);

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>(() => ({
    usuario_id: requerimiento.usuario_id || '',
    obra_id: requerimiento.obra_id || '',
    fecha_final: formatDateForInput(requerimiento.fecha_final || ''),
    sustento: requerimiento.sustento || '',
    codigo: requerimiento.codigo || ''
  }));

  const [initialFormData, setInitialFormData] = useState<FormData>(() => ({
    usuario_id: requerimiento.usuario_id || '',
    obra_id: requerimiento.obra_id || '',
    fecha_final: formatDateForInput(requerimiento.fecha_final || ''),
    sustento: requerimiento.sustento || '',
    codigo: requerimiento.codigo || ''
  }));

  useEffect(() => {
    if (requerimiento.id) {
      dispatch(getComentariosByReferenciaId(requerimiento.id));
    }
  }, [dispatch, requerimiento.id]);

  const handleCancelEdit = useCallback((): void => {
    setFormData(initialFormData);
    setIsEditing(false);
  }, [initialFormData]);

  // Agregar estados para el Toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastTitle, setToastTitle] = useState('');

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Mostrar Toast según el campo modificado
    if (name === 'fecha_final') {
      setToastTitle('Fecha Final');
      setToastMessage(`Se ha seleccionado la fecha: ${value}`);
      setShowToast(true);
    } else if (name === 'sustento') {
      setToastTitle('Sustento');
      setToastMessage(`Modificando sustento - ID: ${requerimiento.id}`);
      setShowToast(true);
    }
  }, [requerimiento.id]);

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await dispatch(updateRequerimiento({
        id: requerimiento.id,
        ...formData,
        fecha_final: new Date(formData.fecha_final),
        estado_atencion: requerimiento.estado_atencion || 'pendiente'
      })).unwrap();
      setFormData(prev => ({ ...prev, codigo: response.codigo }));
      setInitialFormData({ ...formData });
      setIsEditing(false);
    } catch (error) {
      console.error('Error al actualizar:', error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, formData, requerimiento.id, requerimiento.estado_atencion]);

  const handleSubmitRequerimiento = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      await dispatch(updateRequerimiento({
        id: requerimiento.id,
        ...formData,
        fecha_final: new Date(formData.fecha_final),
        estado_atencion: 'pendiente'
      })).unwrap();

      setLoading(false);
      onClose();
      navigate('/dashboard/requerimiento');

    } catch (error) {
      console.error('Error al enviar:', error);
      setLoading(false);
    }
  }, [dispatch, formData, requerimiento.id]);

  const handleSubmitSave = useCallback((): void => {
    navigate('/dashboard/requerimiento');
    onClose();
  }, [navigate]);

  const [showRejectionToast, setShowRejectionToast] = useState(true);

  useEffect(() => {
    if (showRejectionToast && comentarios.length > 0) {
      const timer = setTimeout(() => {
        setShowRejectionToast(false);
      }, 60000); // 1 minuto en milisegundos

      return () => clearTimeout(timer);
    }
  }, [showRejectionToast, comentarios.length]);

  if (loading) {
    return <LoaderPage />;
  }

  return (
    <div className="w-full">
      <Toast
        index={0}
        title={toastTitle}
        message={toastMessage}
        variant="danger"
        position="bottom-right"
        duration={3000}
        isVisible={showToast}
        onClose={() => setShowToast(false)}
        icon={<i className="bi bi-info-circle"></i>}
      />

      {comentarios.length > 0 && showRejectionToast && (
        <Toast
          index={1}
          title="Motivo de rechazo:"
          message={
            <div className="max-h-48 overflow-y-auto">
              <ul className="list-disc pl-5">
                {comentarios.map(comentario => (
                  <li key={comentario.id} className="text-white flex flex-col gap-2">
                    <span className="font-medium p-2 bg-red-500 rounded-md">{comentario.usuario_id.nombres} {comentario.usuario_id.apellidos}:</span>
                    <span className="font-medium ml-5 pl-5 p-1 bg-white/75 rounded-md text-black">{comentario.comentario}</span> 
                  </li>
                ))}
              </ul>
            </div>
          }
          variant="danger"
          position="bottom-right"
          duration={120000}
          isVisible={showRejectionToast}
          onClose={() => setShowRejectionToast(false)}
          icon={<FiAlertTriangle />}
        />
      )}

      {isEditing ? (
        <EditView
          formData={formData}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          setIsEditing={setIsEditing}
          requerimiento={requerimiento}
          obras={obras}
          onCancel={handleCancelEdit}
        />
      ) : (
        <ReadOnlyView
          formData={formData}
          requerimiento={requerimiento}
          obras={obras}
          onEdit={() => setIsEditing(true)}
          onSubmit={handleSubmitRequerimiento}
          onSave={handleSubmitSave}
          usuariosCargo={usuariosCargo}
          hasZeroPriceRecursos={hasZeroPriceRecursos}
          hasZeroQuantityRecursos={hasZeroQuantityRecursos}
          isEmptyRecursos={isEmptyRecursos}
          isEditingRequerimiento={isEditingRequerimiento}
        />
      )}
    </div>
  );
});

export default memo(RequerimientoHeader);