import React, { useState } from 'react';
import Button from '../../../components/Buttons/Button';

interface ContactoData {
  nombres: string;
  apellidos: string;
  cargo: string;
  telefono: string;
}

interface ContactoFormProps {
  onSubmit: (data: ContactoData) => void;
  onCancel: () => void;
  initialData?: ContactoData;
}

const ContactoForm: React.FC<ContactoFormProps> = ({ onSubmit, onCancel, initialData }) => {
  const [formData, setFormData] = useState({
    nombres: initialData?.nombres || '',
    apellidos: initialData?.apellidos || '',
    cargo: initialData?.cargo || '',
    telefono: initialData?.telefono || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-2xl mx-auto space-y-4 md:space-y-6 p-3 md:p-8 bg-white/90 backdrop-blur-sm rounded-xl shadow-2xl">
      <div className="grid grid-cols-1 gap-4 md:gap-6">
        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
            Nombres
          </label>
          <input
            type="text"
            name="nombres"
            value={formData.nombres}
            onChange={handleChange}
            className="block w-full rounded-lg border-gray-300 shadow-lg px-2 py-1 md:py-2
                     focus:ring-4 focus:ring-blue-500/50 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/75 backdrop-blur-sm"
            required
          />
        </div>
        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
            Apellidos
          </label>
          <input
            type="text"
            name="apellidos"
            value={formData.apellidos}
            onChange={handleChange}
            className="block w-full rounded-lg border-gray-300 shadow-lg px-2 py-1 md:py-2
                     focus:ring-4 focus:ring-blue-500/50 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/75 backdrop-blur-sm"
            required
          />
        </div>
        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
            Cargo
          </label>
          <input
            type="text"
            name="cargo"
            value={formData.cargo}
            onChange={handleChange}
            className="block w-full rounded-lg border-gray-300 shadow-lg px-2 py-1 md:py-2
                     focus:ring-4 focus:ring-blue-500/50 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/75 backdrop-blur-sm"
            required
          />
        </div>
        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
            Teléfono
          </label>
          <input
            type="text"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            className="block w-full rounded-lg border-gray-300 shadow-lg px-2 py-1 md:py-2
                     focus:ring-4 focus:ring-blue-500/50 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/75 backdrop-blur-sm"
            required
          />
        </div>
      </div>
      
      <div className="flex justify-end gap-2 md:gap-4 pt-4 md:pt-8">
        <Button 
          text="Cancelar" 
          color="rojo" 
          onClick={onCancel}
          className="flex-1 md:flex-none md:w-32 px-4 md:px-8 py-2 md:py-3 rounded-lg shadow-lg"
        />
        <Button 
          text="Guardar" 
          color="verde"
          className="flex-1 md:flex-none md:w-32 px-4 md:px-8 py-2 md:py-3 rounded-lg shadow-lg" 
        />
      </div>
    </form>
  );
};

export default ContactoForm;