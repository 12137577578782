export interface ComentarioInput {
  usuario_id: string;        // Aseguramos que es string
  referencia_id: string;     // Aseguramos que es string
  tabla: string;
  comentario: string;
}

import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_COMENTARIOS = gql`
  query ListComentarios {
    listComentarios {
      id
      usuario_id {
        id
        nombres
        apellidos
        usuario
      }
      referencia_id
      tabla
      comentario
    }
  }
`;

const GET_COMENTARIOS_BY_REFERENCIA_ID = gql`
  query GetComentariosByReferenciaId($referenciaId: ID!) {
    getComentariosByReferenciaId(referencia_id: $referenciaId) {
      id
      usuario_id {
        nombres
        apellidos
        id
      }
      referencia_id
      tabla
      comentario
    }
  }
`;

const ADD_COMENTARIO = gql`
  mutation AddComentario(
    $usuario_id: ID!
    $referencia_id: ID!
    $tabla: String!
    $comentario: String!
  ) {
    addComentario(
      usuario_id: $usuario_id
      referencia_id: $referencia_id
      tabla: $tabla
      comentario: $comentario
    ) {
      id
      usuario_id {
        id
        nombres
        apellidos
        usuario
      }
      referencia_id
      tabla
      comentario
    }
  }
`;

const UPDATE_COMENTARIO = gql`
  mutation UpdateComentario(
    $id: ID!
    $usuario_id: ID
    $referencia_id: ID
    $tabla: String
    $comentario: String
  ) {
    updateComentario(
      id: $id
      usuario_id: $usuario_id
      referencia_id: $referencia_id
      tabla: $tabla
      comentario: $comentario
    ) {
      id
      usuario_id {
        id
        nombres
        apellidos
        usuario
      }
      referencia_id
      tabla
      comentario
    }
  }
`;

const DELETE_COMENTARIO = gql`
  mutation DeleteComentario($id: ID!) {
    deleteComentario(id: $id) {
      id
    }
  }
`;

export interface Usuario {
  id: string;
  nombres: string;
  apellidos: string;
  usuario: string;
}

export interface Comentario {
  id: string;
  usuario_id: Usuario;
  referencia_id: string;
  tabla: string;
  comentario: string;
}

export const listComentariosService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_COMENTARIOS,
      fetchPolicy: 'network-only',
    });
    return data.listComentarios;
  } catch (error) {
    throw new Error(`Error fetching comentarios: ${error}`);
  }
};

export const addComentarioService = async (input: ComentarioInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_COMENTARIO,
      variables: input,
    });
    return data.addComentario;
  } catch (error) {
    throw new Error(`Error adding comentario: ${error}`);
  }
};

export const updateComentarioService = async (id: string, input: Partial<ComentarioInput>) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_COMENTARIO,
      variables: { id, ...input },
    });
    return data.updateComentario;
  } catch (error) {
    throw new Error(`Error updating comentario: ${error}`);
  }
};

export const deleteComentarioService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_COMENTARIO,
      variables: { id },
    });
    return data.deleteComentario;
  } catch (error) {
    throw new Error(`Error deleting comentario: ${error}`);
  }
};

export const getComentariosByReferenciaIdService = async (referenciaId: string) => {
  try {
    const { data } = await client.query({
      query: GET_COMENTARIOS_BY_REFERENCIA_ID,
      variables: { referenciaId },
      fetchPolicy: 'network-only',
    });
    return data.getComentariosByReferenciaId;
  } catch (error) {
    throw new Error(`Error fetching comentarios by referencia_id: ${error}`);
  }
};
