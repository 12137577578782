import { FiEye } from "react-icons/fi";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store/store';
import { fetchTransferenciaDetalles } from '../../slices/transferenciaDetalleSlice';
import { TransferFilter } from "./TransferFilter";
import { DetalleTransferencia } from "./DetalleTransferencia";
import { TipoFiltro, SortState, TransferenciaDetalle } from "./types";
import { TableHeader } from "./components/TableHeader";

const Skeleton = () => (
  <div className="animate-pulse">
    <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
  </div>
);

export function TransferTable() {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);
  const [tipoFiltro, setTipoFiltro] = useState<TipoFiltro>('TODOS');
  const [selectedTransferencia, setSelectedTransferencia] = useState<TransferenciaDetalle | null>(null);
  const [sortState, setSortState] = useState<SortState>({ field: 'fecha', direction: 'desc' }); // Set default sort
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const detalles = useSelector((state: RootState) => state.transferenciaDetalle.transferenciaDetalles);
  const error = useSelector((state: RootState) => state.transferencia.error);

  // console.log(detalles);
  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(fetchTransferenciaDetalles()).unwrap();
      return response;
    } catch (err) {
      console.error('Error al cargar los datos:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [dispatch]);

  const transferenciasCompletas: TransferenciaDetalle[] = detalles
    .filter(detalle => {
      if (tipoFiltro === 'TODOS') return true;
      switch (tipoFiltro) {
        case 'COMPRAS':
          return detalle.transferencia_id.movimiento_id.tipo === 'entrada' &&
            detalle.transferencia_id.movimiento_id.nombre.toLowerCase().includes('compra');
        case 'RECEPCIONES':
          return detalle.transferencia_id.movimiento_id.tipo === 'entrada' &&
            detalle.transferencia_id.movimiento_id.nombre.toLowerCase().includes('transferencia');
        case 'PRESTAMOS':
          return detalle.transferencia_id.movimiento_id.tipo === 'salida' &&
            detalle.transferencia_id.movimiento_id.nombre.toLowerCase().includes('prestamo');
        case 'SALIDA':
          return detalle.transferencia_id.movimiento_id.tipo === 'salida' &&
            detalle.transferencia_id.movimiento_id.nombre.toLowerCase().includes('salida');
        default:
          return true;
      }
    })
    .filter(transferencia => {
      const fecha = new Date(transferencia.fecha);
      if (startDate && endDate) {
        return fecha >= startDate && fecha <= endDate;
      }
      return true;
    })
    .map(transferencia => {
      const detallesTransferencia = detalles.filter(d =>
        d.transferencia_id && d.transferencia_id.id === transferencia.id
      );

      return {
        ...transferencia,
        detalles: detallesTransferencia,
      };
    });

  const handleSort = (field: keyof TransferenciaDetalle | 'transferencia_id.usuario_id' | 'transferencia_id.movimiento_id' | 'transferencia_id.movilidad_id') => {
    setSortState(prevState => ({
      field,
      direction: prevState.field === field && prevState.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedTransferencias = [...transferenciasCompletas].sort((a, b) => {
    if (!sortState.field) return 0;

    let fieldA: any, fieldB: any;

    switch (sortState.field) {
      case 'fecha':
        fieldA = new Date(a.fecha).getTime();
        fieldB = new Date(b.fecha).getTime();
        break;
      case 'transferencia_id.usuario_id':
        fieldA = `${a.transferencia_id.usuario_id.nombres} ${a.transferencia_id.usuario_id.apellidos}`;
        fieldB = `${b.transferencia_id.usuario_id.nombres} ${b.transferencia_id.usuario_id.apellidos}`;
        break;
      case 'transferencia_id.movimiento_id':
        fieldA = a.transferencia_id.movimiento_id.nombre;
        fieldB = b.transferencia_id.movimiento_id.nombre;
        break;
      case 'transferencia_id.movilidad_id':
        fieldA = a.transferencia_id.movilidad_id?.denominacion;
        fieldB = b.transferencia_id.movilidad_id?.denominacion;
        break;
      default:
        fieldA = (a as any)[sortState.field];
        fieldB = (b as any)[sortState.field];
    }

    if (fieldA < fieldB) return sortState.direction === 'asc' ? -1 : 1;
    if (fieldA > fieldB) return sortState.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const handleDateFilterChange = (tipo: 'TODOS' | 'COMPRAS' | 'RECEPCIONES' | 'PRESTAMOS' | 'SALIDA', start: Date | null, end: Date | null) => {
    setTipoFiltro(tipo);
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="w-full h-[calc(100vh-20rem)] overflow-hidden">
  
      <TransferFilter tipoFiltro={tipoFiltro} onChange={handleDateFilterChange} />
      <div className="overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="grid grid-cols-6  bg-gray-100">
              <TableHeader
                field="transferencia_id.usuario_id"
                label="Usuario"
                onSort={handleSort}
                currentSort={sortState}
                sortable={false}
              />
              <TableHeader
                field="fecha"
                label="Fecha"
                onSort={handleSort}
                currentSort={sortState}
                sortable={true}
              />
              <TableHeader
                field="transferencia_id.movimiento_id"
                label="Movimiento"
                onSort={handleSort}
                currentSort={sortState}
                sortable={false}
              />
              <TableHeader
                field="transferencia_id.movilidad_id"
                label="Transporte"
                onSort={handleSort}
                currentSort={sortState}
                sortable={false}
              />
              <TableHeader
                field="tipo"
                label="Tipo"
                onSort={handleSort}
                currentSort={sortState}
                sortable={false}
              />
              <TableHeader
                field="tipo"
                label="Acciones"
                onSort={handleSort}
                currentSort={sortState}
                sortable={false}
              />

            </tr>
          </thead>
        </table>

        <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 25rem)' }}>
          <table className="w-full">
            <tbody>
              {isLoading ? (
                [...Array(3)].map((_, index) => (
                  <tr key={index} className="grid grid-cols-6 gap-2 p-2">
                    <td className="p-2"><Skeleton /></td>
                    <td className="p-2"><Skeleton /></td>
                    <td className="p-2"><Skeleton /></td>
                    <td className="p-2"><Skeleton /></td>
                    <td className="p-2"><Skeleton /></td>
                    <td className="p-2"><Skeleton /></td>
                  </tr>
                ))
              ) : error ? (
                <tr className="grid grid-cols-1 gap-4 p-2 text-center text-red-500">
                  <td className="p-4">Error al cargar las transferencias: {error}</td>
                </tr>
              ) : sortedTransferencias.length === 0 ? (
                <tr className="grid grid-cols-1 gap-4 p-2 text-center text-gray-500">
                  <td className="p-4">No hay transferencias disponibles</td>
                </tr>
              ) : (
                sortedTransferencias.map((transferencia: TransferenciaDetalle) => (
                  <tr key={transferencia.id} className="grid grid-cols-6 gap-5 hover:bg-gray-50">
                    <td className="p-2">
                      {transferencia.transferencia_id.usuario_id
                        ? `${transferencia.transferencia_id.usuario_id.nombres} ${transferencia.transferencia_id.usuario_id.apellidos}`
                        : 'Usuario desconocido'}
                    </td>
                    <td className="p-2">{new Date(transferencia.fecha).toLocaleDateString()}</td>
                    <td className="p-2">
                      {transferencia.transferencia_id.movimiento_id
                        ? transferencia.transferencia_id.movimiento_id.nombre
                        : 'Movimiento desconocido'}
                    </td>
                    <td className="p-2">{transferencia.transferencia_id.movilidad_id?.denominacion || '-'}</td>
                    <td className="p-2">
                      <span className={`px-2 py-1 rounded-full text-xs ${transferencia.transferencia_id.movimiento_id?.tipo === 'entrada'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-blue-100 text-blue-800'
                        }`}>
                        {transferencia.transferencia_id.movimiento_id?.tipo || 'Tipo desconocido'}
                      </span>
                    </td>
                    <td className="p-2">
                      <button
                        className="bg-transparent border-none text-blue-800 hover:text-blue-600 transition-colors"
                        title="Ver detalles"
                        onClick={() => setSelectedTransferencia(transferencia)}
                      >
                        <FiEye className="gap-10 w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {selectedTransferencia && (
        <DetalleTransferencia
          transferencia_detalle={selectedTransferencia}
          onClose={() => setSelectedTransferencia(null)}
        />
      )}
    </div>
  );
}
