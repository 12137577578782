import { RecursoExtendidoType } from '../../types/ordenCompra.types';
import { DataPDFOrdenCompra } from '../../services/ordenCompraService';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
// import logo from '../../assets/logoInacons.png';
import { formatDate } from '../../components/Utils/dateUtils';
import { Contacto } from '../../slices/proveedorSlice';
import { useState, useEffect } from 'react';

// Helper function to truncate text
const truncateText = (text: string, maxLength: number) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

// Helper function to format contact information
const formatContacts = (contacts: Contacto[]) => {
  if (!contacts || !contacts.length) return '';
  return contacts
    .map(contact => `${contact.nombres}: ${contact.telefono}`)
    .join('\n');
};


interface PDFOCGeneratorProps {
  ordenCompra: DataPDFOrdenCompra;
  recursos: RecursoExtendidoType[];
  nombreSolicita: string;
  documentFileName?: string;
}

const PDFOCGenerator: React.FC<PDFOCGeneratorProps> = ({ 
  ordenCompra, 
  recursos, 
  nombreSolicita,
  documentFileName = `OC-${ordenCompra.codigo_orden}.pdf` 
}) => {
  const [imageError, setImageError] = useState<string | null>(null);
  const [convertedImageUrl, setConvertedImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const convertWebpToPng = async () => {
      try {
        const webpUrl = ordenCompra.cotizacion_id.solicitud_compra_id.requerimiento.obra.empresa_id.imagenes;
        if (!webpUrl) {
          setImageError('No hay imagen disponible');
          return;
        }

        const response = await fetch(webpUrl);
        const blob = await response.blob();
        
        // Crear un elemento img HTML
        const imgElement = document.createElement('img');
        imgElement.src = URL.createObjectURL(blob);
        
        imgElement.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = imgElement.naturalWidth;
          canvas.height = imgElement.naturalHeight;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(imgElement, 0, 0);
            const pngUrl = canvas.toDataURL('image/png');
            setConvertedImageUrl(pngUrl);
          } else {
            setImageError('Error al crear el contexto del canvas');
          }
        };

        imgElement.onerror = () => {
          setImageError('Error al cargar la imagen');
        };
      } catch (error) {
        console.error('Error al convertir la imagen:', error);
        setImageError('Error al procesar la imagen');
      }
    };

    convertWebpToPng();
  }, [ordenCompra.cotizacion_id.solicitud_compra_id.requerimiento.obra.empresa_id.imagenes]);

// Original color
const empresaColor = ordenCompra.cotizacion_id.solicitud_compra_id.requerimiento.obra.empresa_id.color || '#2c5282';

// Helper function to adjust color brightness
const adjustBrightness = (color: string, percent: number) => {
  const num = parseInt(color.replace('#',''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = (num >> 8 & 0x00FF) + amt;
  const B = (num & 0x0000FF) + amt;
  return `#${(1 << 24 | (R < 255 ? R < 0 ? 0 : R : 255) << 16 | (G < 255 ? G < 0 ? 0 : G : 255) << 8 | (B < 255 ? B < 0 ? 0 : B : 255)).toString(16).slice(1)}`;
};

// Create lighter and darker versions
const empresaColorUltraLighter = adjustBrightness(empresaColor, 75); // 20% lighter
const empresaColorLighter = adjustBrightness(empresaColor, 20); // 20% lighter
const empresaColorDarker = adjustBrightness(empresaColor, -20); // 20% darker
const empresaColorMoreDarker = adjustBrightness(empresaColor, -30); // 20% darker

  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontSize: 10,
      backgroundColor: '#ffffff',
    },
    header: {
      flexDirection: 'row',
      marginBottom: 20,
      alignItems: 'center',
    },
    headerLeft: {
      width: '30%',
      alignItems: 'center',
    },
    headerCompanyName: {
      fontSize: 40,
      marginTop: 5,
      width: '300%',
      flexWrap: 'wrap',
      color: empresaColorMoreDarker , // Usa la variable y proporciona un color por defecto
    },
    headerRight: {
      width: '70%',
      alignItems: 'flex-end',
    },
    logo: {
      width: 'auto',
      height: 40,
      marginBottom: 10,
    },
    logoError: {
      color: 'red',
      fontSize: 8,
      marginBottom: 10,
    },
    title: {
      fontSize: 16,
      marginBottom: 5,
      color: empresaColorDarker,
      fontFamily: 'Helvetica-Bold',
    },
    obraText: {
      fontSize: 12,
      marginBottom: 5,
      color: empresaColor,
    },
    infoContainer: {
      flexDirection: 'row',
      marginTop: 20,
      borderWidth: 1,
      borderColor: empresaColorLighter,
      borderRadius: 8,
      padding: 15,
      backgroundColor: empresaColorUltraLighter,
    },
    infoLeft: {
      width: '50%',
    },
    infoRight: {
      width: '50%',
    },
    label: {
      fontFamily: 'Helvetica-Bold',
      marginRight: 5,
      color: empresaColorDarker,
      width: '100%', // Fixed width for labels
    },
    labelTotal1: {
      fontFamily: 'Helvetica-Bold',
      marginRight: 0,
      fontSize: 11,
      color: empresaColorDarker,
      width: '100%', // Fixed width for labels
    },
    labelTotal2: {
      textAlign: 'right',
      fontFamily: 'Helvetica-Bold',
      marginRight: 0,
      fontSize: 11,
      color: empresaColorDarker,
      width: '100%',
  
    },
    value: {
      width: '75%', // Fixed width for values
      flexWrap: 'wrap',
      fontSize: 9,
    },
    labelResponse: {
      fontSize: 9,
      flexWrap: 'wrap',
      flex: 1,
    },
    row: {
      flexDirection: 'row',
      marginBottom: 5,
      flexWrap: 'wrap',
    },
    table: {
      marginTop: 20,
      borderRadius: 8,
      overflow: 'hidden',
    },
    tableHeader: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: empresaColorLighter,
      backgroundColor: empresaColorMoreDarker,
      padding: 8,
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: empresaColorDarker,
      padding: 8,
      backgroundColor: '#ffffff',
      minHeight: 30,
    },
    colCodigo: { width: '8%', fontSize: 8, color: '#ffffff' },
    colDescripcion: { 
      width: '30%', 
      fontSize: 8, 
      color: '#ffffff',
      flexWrap: 'wrap',
    },
    colNotas: { 
      width: '24%', 
      fontSize: 8, 
      color: '#ffffff',
      flexWrap: 'wrap',
    },
    colUnid: { width: '6%', fontSize: 8, color: '#ffffff' },
    colCantidad: { width: '6%', textAlign: 'center', fontSize: 8, color: '#ffffff' },
    colPrecio: { width: '10%', textAlign: 'center', fontSize: 8, color: '#ffffff' },
    colDes: { width: '6%', textAlign: 'center', fontSize: 8, color: '#ffffff' },
    colSubTot: { width: '10%', textAlign: 'right', fontSize: 8, color: '#ffffff' },
    cellData: {
      color: '#2d3748',
      fontSize: 8,
      flexWrap: 'wrap',
    },
    cellDataWrapped: {
      color: '#2d3748',
      fontSize: 8,
      flexWrap: 'wrap',
      padding: 2,
    },
    totalesContainer: {
      marginTop: 20,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    totalesBox: {
      width: '40%',
      backgroundColor: empresaColorUltraLighter,
      padding: 10,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: empresaColor,
    },
    totalesRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 5,
      padding: 3,
    },
    facturacionDespacho: {
      marginTop: 20,
      flexDirection: 'row',
      gap: 10,
    },
    facturacionBox: {
      flex: 1,
      padding: 15,
      borderWidth: 1,
      borderColor: empresaColor,
      borderRadius: 8,
      backgroundColor: empresaColorUltraLighter,
    },
    boxTitle: {
      fontSize: 12,
      fontFamily: 'Helvetica-Bold',
      marginBottom: 10,
      color: empresaColorMoreDarker,
      borderBottomWidth: 1,
      borderBottomColor: empresaColor,
      paddingBottom: 5,
    },
    multiLineText: {
      flexWrap: 'wrap',
      width: '100%',
    },
  });
  
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const simbolo = ordenCompra.cotizacion_proveedor.divisa.simbolo;
  const PDFContent = (
    <Document title={documentFileName}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            {imageError ? (
              <Text style={styles.logoError}>Error al cargar el logo: {imageError}</Text>
            ) : convertedImageUrl ? (
              <Image
                style={styles.logo}
                src={convertedImageUrl}
              />
            ) : (
              <Text style={styles.logoError}>Cargando imagen...</Text>
            )}
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.title}>Orden de Compra</Text>
            <Text style={styles.obraText}>
              N° {ordenCompra.codigo_orden}    Obra {truncateText(ordenCompra.cotizacion_id.solicitud_compra_id.requerimiento.obra.nombre, 30)}
            </Text>
          </View>
        </View>

        <View style={styles.infoContainer}>
          <View style={styles.infoLeft}>
            <View style={styles.row}>
              <Text style={styles.label}>Ruc:</Text>
              <Text style={styles.value}>{ordenCompra.cotizacion_proveedor.proveedor.ruc}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Razon Social:</Text>
              <Text style={styles.value}>
                {truncateText(ordenCompra.cotizacion_proveedor.proveedor.razon_social, 60)}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Forma de Pago:</Text>
              <Text style={styles.value}>
                {ordenCompra?.cotizacion_proveedor?.proveedor.medios_pago.map(medio => 
                  [medio.entidad, medio.nro_cuenta, medio.detalles].filter(Boolean).join(', ')
                ).join('\n')}
              </Text>
            </View>
          </View>
          <View style={styles.infoRight}>
            <View style={styles.row}>
              <Text style={styles.label}>F.Emisión:</Text>
              <Text style={styles.value}>{formatDate(ordenCompra.fecha_ini, "dd/mm/yyyy")}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Entrega:</Text>
              <Text style={styles.value}>{formatDate(ordenCompra.fecha_fin, "dd/mm/yyyy")}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Solicita:</Text>
              <Text style={styles.value}>{nombreSolicita}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Moneda de Pago:</Text>
              <Text style={styles.value}>
                {ordenCompra.cotizacion_proveedor.divisa.nombre} - 
                {ordenCompra.cotizacion_proveedor.divisa.abreviatura} - 
                {simbolo}
                </Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.colCodigo}>Código</Text>
            <Text style={styles.colDescripcion}>Descripción</Text>
            <Text style={styles.colNotas}>Notas</Text>
            <Text style={styles.colUnid}>Unid.</Text>
            <Text style={styles.colCantidad}>Cant.</Text>
            <Text style={styles.colPrecio}>Precio</Text>
            <Text style={styles.colDes}>%Des</Text>
            <Text style={styles.colSubTot}>Sub Tot</Text>
          </View>
          {recursos.map((recurso, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.colCodigo, styles.cellData]}>{recurso.id_recurso.codigo}</Text>
              <Text style={[styles.colDescripcion, styles.cellDataWrapped]}>
                {truncateText(recurso.id_recurso.nombre, 100)}
              </Text>
              <Text style={[styles.colNotas, styles.cellDataWrapped]}>
                {truncateText(recurso.notas || '', 80)}
              </Text>
              <Text style={[styles.colUnid, styles.cellData]}>UND</Text>
              <Text style={[styles.colCantidad, styles.cellData]}>{recurso.cantidad.toFixed(2)}</Text>
              <Text style={[styles.colPrecio, styles.cellData]}>{simbolo} {recurso.precio.toFixed(2)}</Text>
              <Text style={[styles.colDes, styles.cellData]}>{recurso.descuento.toFixed(2)}</Text>
              <Text style={[styles.colSubTot, styles.cellData]}>{simbolo} {recurso.subTotal.toFixed(2)}</Text>
            </View>
          ))}
        </View>

        <View style={styles.totalesContainer}>
          <View style={styles.totalesBox}>
            <View style={styles.totalesRow}>
              <Text>Neto:</Text>
              <Text>{simbolo} {recursos.reduce((sum, r) => sum + r.subTotal, 0).toFixed(2)}</Text>
            </View>
            <View style={styles.totalesRow}>
              <Text>IGV:</Text>
              <Text>{simbolo} {(recursos.reduce((sum, r) => sum + r.subTotal, 0) * 0.18).toFixed(2)}</Text>
            </View>
            <View style={styles.totalesRow}>
              <Text style={styles.labelTotal1}>Total:</Text>
              <Text style={styles.labelTotal2}>
              {simbolo} {(recursos.reduce((sum, r) => sum + r.subTotal, 0) * 1.18).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.facturacionDespacho}>
          <View style={styles.facturacionBox}>
            <Text style={styles.boxTitle}>Datos Adicionales de {truncateText(ordenCompra.cotizacion_proveedor.proveedor.nombre_comercial, 80)}</Text>
            <View style={styles.row}>
              <Text style={styles.label}>Dirección:</Text>
              <Text style={styles.labelResponse}>
                {truncateText(ordenCompra.cotizacion_proveedor.proveedor.direccion, 100)}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Contactos:</Text>
              <Text style={styles.labelResponse}>
                {formatContacts(ordenCompra.cotizacion_proveedor?.proveedor.contactos)}
              </Text>
            </View>
          </View>
          <View style={styles.facturacionBox}>
            <Text style={styles.boxTitle}>Datos para Despachar</Text>
            <View style={styles.row}>
              <Text style={styles.label}>Obra:</Text>
              <Text style={styles.labelResponse}>
                {truncateText(ordenCompra.cotizacion_id.solicitud_compra_id.requerimiento.obra.nombre, 80)}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Despacho:</Text>
              <Text style={styles.labelResponse}>
                {truncateText(ordenCompra.cotizacion_id.solicitud_compra_id.requerimiento.obra.ubicacion, 80)}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Dirección:</Text>
              <Text style={styles.labelResponse}>{ordenCompra.cotizacion_id.solicitud_compra_id.requerimiento.obra.direccion}              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return isMobile ? (
    PDFContent
  ) : (
    <PDFViewer style={{ width: '100%', height: '800px' }}>
      {PDFContent}
    </PDFViewer>
  );
};

export default PDFOCGenerator;