import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { FaMoneyBillWave } from 'react-icons/fa';

const VentanaSUNAT = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full min-w-[800px] mx-auto mt-2">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-4 py-3 bg-[#337AB7] rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200"
      >
        <div className="flex items-center space-x-2">
          <FaMoneyBillWave className="text-white text-xl" />
          <span className="text-gray-50 font-medium">Tipo de Cambio SUNAT</span>
        </div>
        {isOpen ? (
          <MdKeyboardArrowUp className="text-2xl text-gray-50" />
        ) : (
          <MdKeyboardArrowDown className="text-2xl text-gray-50" />
        )}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <iframe
              src="https://e-consulta.sunat.gob.pe/cl-at-ittipcam/tcS01Alias"
              className="min-w-[800px] w-full min-h-80 h-full mt-4 border rounded-lg shadow-md"
              title="SUNAT Tipo de Cambio"
            />
            {/* <iframe
              src="https://e-consultaruc.sunat.gob.pe/cl-ti-itmrconsruc/FrameCriterioBusquedaWeb.jsp"
              className="min-w-[800px] w-full min-h-80 h-full mt-4 border rounded-lg shadow-md"
              title="SUNAT Tipo de Cambio"
            /> */}
            
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VentanaSUNAT;
