import React, { useState, useEffect } from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { OrdenTransferenciaProps } from '../interfaces';
//import { RecursoSeleccionado } from '../types';
import { addTransferencia } from '../../../slices/transferenciaSlice';
import { addTransferenciaRecurso } from '../../../slices/transferenciaRecursoSlice';
import { addTransferenciaDetalle } from '../../../slices/transferenciaDetalleSlice';
import { updateObraBodegaRecurso } from '../../../slices/obraBodegaRecursoSlice';
import { addComentario } from '../../../slices/comentarioSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransferenciaDetalles } from '../../../slices/transferenciaDetalleSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import { fetchMovimientos } from '../../../slices/movimientoSlice';
import { fetchMovilidades } from '../../../slices/movilidadSlice';

const OrdenTransferencia: React.FC<OrdenTransferenciaProps> = ({ 
  onClose, 
  recursos,
  recursosPorBodega,
  solicitudData 
}) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [descripcion, setDescripcion] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [movilidadId, setMovilidadId] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [savingMessage, setSavingMessage] = useState<string | null>(null);

  const movimientos = useSelector((state: RootState) => state.movimiento.movimientos);
  const movilidades = useSelector((state: RootState) => state.movilidad.movilidades) || [];

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await Promise.all([
          dispatch(fetchMovimientos()),
          dispatch(fetchMovilidades())
        ]);
      } catch (error) {
        console.error('Error al cargar datos:', error);
        setErrorMessage('Error al cargar los datos necesarios');
      }
    };
    cargarDatos();
  }, [dispatch]);

  const getMovimientoSalidaId = () => {
    const movimientoTransferencia = movimientos.find(m => 
      m.nombre.toLowerCase().includes('almacen')
    );
    return movimientoTransferencia?.id || null;
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setSavingMessage('Enviando solicitud...');
      setErrorMessage(null);
      setSuccessMessage(null);

      if (!movilidadId) {
        setErrorMessage('Debe seleccionar un tipo de transporte');
        setIsSaving(false);
        setSavingMessage(null);
        return;
      }

      const movimientoId = getMovimientoSalidaId();
      if (!movimientoId) {
        setErrorMessage('No se encontró el tipo de movimiento');
        setIsSaving(false);
        setSavingMessage(null);
        return;
      }

      if (!solicitudData.usuario.id || !movimientoId || !movilidadId) {
        setErrorMessage('Error en los datos de la transferencia');
        setIsSaving(false);
        setSavingMessage(null);
        return;
      }

      const fecha = new Date();
      const codigoTransferencia = `TRA-${fecha.getFullYear()}${(fecha.getMonth() + 1).toString().padStart(2, '0')}${fecha.getDate().toString().padStart(2, '0')}-${Math.floor(Math.random() * 1000).toString().padStart(3, '0')}`;

      const transferencia = {
        usuario_id: solicitudData.usuario.id.toString(),
        movimiento_id: movimientoId.toString(),
        movilidad_id: movilidadId.toString(),
        fecha: fecha,
        descripcion,
        observaciones,
        almacen_origen_id: solicitudData.almacenOrigen.id,
        almacen_destino_id: solicitudData.almacenDestino?.id,
        codigo: codigoTransferencia
      };

      setSavingMessage('Procesando transferencia...');
      const transferenciaResult = await dispatch(addTransferencia(transferencia)).unwrap();

      if (!transferenciaResult || !transferenciaResult.id) {
        throw new Error('Error al crear la transferencia');
      }

      setSavingMessage('Creando detalle de transferencia...');
      const detalleTransferencia = {
        transferencia_id: transferenciaResult.id,
        referencia_id: solicitudData.id,
        tipo: 'SALIDA-ALMACEN',
        referencia: `Salida de Almacen -  ${codigoTransferencia}`,
        fecha: new Date()
      };
      
      const detalleResult = await dispatch(addTransferenciaDetalle(detalleTransferencia)).unwrap();

      if (!detalleResult || !detalleResult.id) {
        throw new Error('Error al crear el detalle de la transferencia');
      }

      setSavingMessage('Guardando comentarios...');
      if (observaciones && observaciones.trim() !== '') {
        await dispatch(addComentario({
          usuario_id: solicitudData.usuario.id,
          referencia_id: detalleResult.id,
          tabla: 'TRANSFERENCIA_DETALLE',
          comentario: observaciones.trim()
        })).unwrap();
      }

      setSavingMessage('Actualizando recursos...');
      await Promise.all(recursos.map(async recurso => {
        const recursoTransferencia = {
          transferencia_detalle_id: detalleResult.id,
          recurso_id: recurso.recurso_id.id,
          cantidad: recurso.cantidadSeleccionada,
          costo: recurso.recurso_id.precio_actual,
          requerimiento_recurso_id: recurso.requerimiento_recurso_id || '',
          obra_bodega_recurso_id: recurso.bodega_id || ''
        };
        await dispatch(addTransferenciaRecurso(recursoTransferencia)).unwrap();

        if (recurso.bodega_id) {
          const bodegaRecurso = recursosPorBodega[recurso.recurso_id.id]?.find(
            b => b.obra_bodega_id === recurso.bodega_id
          );
          
          if (bodegaRecurso) {
            const nuevaCantidad = bodegaRecurso.cantidad - recurso.cantidadSeleccionada;
            await dispatch(updateObraBodegaRecurso({
              updateObraBodegaRecursoId: bodegaRecurso.obra_bodega_recursos_id,
              cantidad: nuevaCantidad
            })).unwrap();
          }
        }
      }));
      
      setSuccessMessage('Transferencia guardada con éxito');
      await dispatch(fetchTransferenciaDetalles()).unwrap();
      
      setTimeout(() => {
        onClose();
      }, 1500);
   
    } catch (error) {
      console.error('Error al guardar la transferencia:', error);
      setErrorMessage(error instanceof Error ? error.message : 'Error al guardar la transferencia');
    } finally {
      setIsSaving(false);
      setSavingMessage(null);
    }
  };

  return (
    <div className="bg-white rounded-lg w-full p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-[1.5rem] font-bold text-[#003876]">Orden de Transferencia</h2>
        <button onClick={onClose} className="text-red-500 hover:text-red-700">
          <IoMdCloseCircle size={24} />
        </button>
      </div>

      {/* Mensajes */}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {successMessage}
        </div>
      )}
      {savingMessage && (
        <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded mb-4 flex items-center">
          <AiOutlineLoading3Quarters className="animate-spin mr-2" />
          {savingMessage}
        </div>
      )}

      {/* Formulario */}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Almacén de Salida</label>
            <input
              type="text"
              value={solicitudData.almacenOrigen?.nombre || ''}
              readOnly
              className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Almacén de Destino</label>
            <input
              type="text"
              value={solicitudData.almacenDestino?.nombre || ''}
              readOnly
              className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
            />
          </div>
        </div>
        <div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Tipo de Transporte</label>
            <select
              value={movilidadId}
              onChange={(e) => setMovilidadId(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="">Seleccione un tipo de transporte</option>
              {movilidades.map((movilidad) => (
                <option key={movilidad.id} value={movilidad.id}>
                  {movilidad.denominacion} - {movilidad.descripcion}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Descripción</label>
            <input
              type="text"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Ingrese una descripción"
            />
          </div>
        </div>
      </div>

      {/* Tabla */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Código
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Nombre
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cantidad Solicitada
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cantidad a Transferir
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Pendiente
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Bodega
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Precio
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Subtotal
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {recursos.map((recurso) => {
              const bodegaSeleccionada = recursosPorBodega[recurso.recurso_id.id]?.find(
                b => b.obra_bodega_id === recurso.bodega_id
              );
              const subtotal = recurso.cantidadSeleccionada * recurso.recurso_id.precio_actual;
              const pendiente = (recurso.pendiente || 0) - recurso.cantidadSeleccionada;

              return (
                <tr key={recurso.recurso_id.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {recurso.recurso_id.codigo}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {recurso.recurso_id.nombre}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {recurso.cantidad}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {recurso.cantidadSeleccionada}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {pendiente}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {bodegaSeleccionada?.nombre || recurso.bodega || 'No seleccionada'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    S/ {recurso.recurso_id.precio_actual}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    S/ {subtotal.toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Observaciones y botones */}
      <div className="mt-6">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Observaciones</label>
          <textarea
            value={observaciones}
            onChange={(e) => setObservaciones(e.target.value)}
            rows={3}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Ingrese observaciones adicionales"
          />
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            disabled={isSaving}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Cancelar
          </button>
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
          >
            {isSaving ? (
              <>
                <AiOutlineLoading3Quarters className="animate-spin mr-2" />
                Guardando...
              </>
            ) : (
              'Guardar'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrdenTransferencia;
