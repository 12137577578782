import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { RequerimientoForm } from './RequerimientoForm';
import { ProductList } from './ProductList';
import { SelectedProducts } from './SelectedProducts';
import RequerimientoHeader from './RequerimientoHeader/RequerimientoHeader';
import LoaderPage from '../../../components/Loader/LoaderPage';
import PartidasList from './PartidasList';
import { getPartidasByProyecto } from '../../../slices/tituloSlice';

interface InitialValues {
  codigo: string;
  estado_atencion: string;
  fecha_final: string;
  fecha_solicitud: string;
  id: string;
  obra_id: string;
  presupuesto_id: string | null;
  sustento: string;
  usuario: string;
  usuario_id: string;
}

interface RequerimientoRecursosProps {
  initialValues?: InitialValues;
  onClose: () => void;
  isEditingRequerimiento?: boolean;
}

const RequerimientoRecursos: React.FC<RequerimientoRecursosProps> = ({ initialValues, onClose, isEditingRequerimiento }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [requerimiento_id, setRequerimiento_id] = useState<string | null>(null);
  const [requerimientoData, setRequerimientoData] = useState<InitialValues | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeView, setActiveView] = useState<'partidas' | 'productos'>('productos');
  const [selectedPartidaId, setSelectedPartidaId] = useState<string | null>(null);
  
  // Estado actualizado para validaciones
  const [hasZeroPriceRecursos, setHasZeroPriceRecursos] = useState(false);
  const [hasZeroQuantityRecursos, setHasZeroQuantityRecursos] = useState(false);
  const [isEmptyRecursos, setIsEmptyRecursos] = useState(true);

  const memoizedInitialId = useMemo(() => initialValues?.id, [initialValues]);
  const obras = useSelector((state: RootState) => state.obra);
  const proyectoId = obras.obras.find(obra => obra.id === requerimientoData?.obra_id)?.proyecto?.id_proyecto;

  useEffect(() => {
    if (proyectoId) {
      dispatch(getPartidasByProyecto(proyectoId || ""));
    }
  }, [dispatch, proyectoId]);

  useEffect(() => {
    setIsLoading(true);

    if (memoizedInitialId && !requerimiento_id && initialValues) {
      setRequerimiento_id(memoizedInitialId);
      setRequerimientoData(initialValues);
    }

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [memoizedInitialId, requerimiento_id, initialValues]);

  const handleSelectPartida = (partidaId: string) => {
    setSelectedPartidaId(partidaId);
    setActiveView('partidas');
  };

  // Actualización del manejador para recibir más información
  const handleRecursosChange = (hasZeroPrices: boolean, hasZeroQuantities: boolean, isEmpty: boolean) => {
    setHasZeroPriceRecursos(hasZeroPrices);
    setHasZeroQuantityRecursos(hasZeroQuantities);
    setIsEmptyRecursos(isEmpty);
  };

  if (isLoading) {
    return <LoaderPage />;
  }

  const handleRequerimientoCreated = (id: string, data: InitialValues) => {
    setRequerimiento_id(id);
    setRequerimientoData(data);
  };

  const renderContent = () => {
    if (!requerimiento_id) {
      return <RequerimientoForm onRequerimientoCreated={handleRequerimientoCreated} />;
    }

    return (
      <div className="flex flex-col gap-4 min-h-[calc(100vh-10rem)] min-w-[80vw] p-4">
        <div className='w-full h-28'>
          <RequerimientoHeader
            key={requerimiento_id}
            requerimiento={requerimientoData!}
            obras={obras}
            onClose={onClose}
            hasZeroPriceRecursos={hasZeroPriceRecursos}
            hasZeroQuantityRecursos={hasZeroQuantityRecursos}
            isEmptyRecursos={isEmptyRecursos}
            isEditingRequerimiento={isEditingRequerimiento}
          />
        </div>
        <div className="grid grid-cols-3 gap-1 h-[calc(100vh-20rem)]">
          <div className="col-span-1 overflow-auto rounded-lg shadow-md bg-white/50">
            {activeView === 'partidas' ? (
              <PartidasList
                requerimiento_id={requerimiento_id}
                id_proyecto={proyectoId || ""}
                onToggleView={() => setActiveView('productos')}
                selectedPartidaId={selectedPartidaId}
                onClearSelectedPartida={() => setSelectedPartidaId(null)}
                onSelectPartida={(partidaId) => setSelectedPartidaId(partidaId)}
              />
            ) : (
              <ProductList
                requerimiento_id={requerimiento_id}
                id_proyecto={proyectoId || ""}
                fecha_final={requerimientoData ? new Date(requerimientoData.fecha_final) : new Date()}
                onToggleView={() => setActiveView('partidas')}
              />
            )}
          </div>
          <div className="col-span-2 overflow-auto rounded-lg shadow-md bg-white/50">
            <SelectedProducts
              requerimiento_id={requerimiento_id}
              fecha_final={requerimientoData ? new Date(requerimientoData.fecha_final) : new Date()}
              onSelectPartida={handleSelectPartida}
              onRecursosChange={handleRecursosChange}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto">
      {renderContent()}
    </div>
  );
};

export default RequerimientoRecursos;