import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  AprobacionConstancia,
  AprobacionConstanciaInput,
  listAprobacionesConstanciasService,
  getAprobacionesByConstanciaService,
  addAprobacionConstanciaService,
  updateAprobacionConstanciaService,
  deleteAprobacionConstanciaService
} from '../services/aprobacionConstanciasComprobanteService';

interface AprobacionConstanciaState {
  aprobacionesConstancia: AprobacionConstancia[];
  aprobacionesByConstancia: AprobacionConstancia[];
  loading: boolean;
  error: string | null;
  loadingByConstancia: boolean;
  errorByConstancia: string | null;
}

const initialState: AprobacionConstanciaState = {
  aprobacionesConstancia: [],
  aprobacionesByConstancia: [],
  loading: false,
  error: null,
  loadingByConstancia: false,
  errorByConstancia: null
};

export const fetchAprobacionesConstancia = createAsyncThunk(
  'aprobacionConstancia/fetchAprobaciones',
  async (_, { rejectWithValue }) => {
    try {
      return await listAprobacionesConstanciasService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchAprobacionesByConstancia = createAsyncThunk(
  'aprobacionConstancia/fetchAprobacionesByConstancia',
  async (constanciaId: string, { rejectWithValue }) => {
    try {
      return await getAprobacionesByConstanciaService(constanciaId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addAprobacionConstancia = createAsyncThunk(
  'aprobacionConstancia/addAprobacion',
  async (aprobacion: AprobacionConstanciaInput, { rejectWithValue }) => {
    try {
      return await addAprobacionConstanciaService(aprobacion);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateAprobacionConstancia = createAsyncThunk(
  'aprobacionConstancia/updateAprobacion',
  async ({ id, ...data }: Partial<AprobacionConstanciaInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateAprobacionConstanciaService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteAprobacionConstancia = createAsyncThunk(
  'aprobacionConstancia/deleteAprobacion',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteAprobacionConstanciaService(id);
      return { id };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const aprobacionConstanciaSlice = createSlice({
  name: 'aprobacionConstancia',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchAprobacionesConstancia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAprobacionesConstancia.fulfilled, (state, action: PayloadAction<AprobacionConstancia[]>) => {
        state.loading = false;
        state.aprobacionesConstancia = action.payload;
      })
      .addCase(fetchAprobacionesConstancia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch by constancia
      .addCase(fetchAprobacionesByConstancia.pending, (state) => {
        state.loadingByConstancia = true;
        state.errorByConstancia = null;
      })
      .addCase(fetchAprobacionesByConstancia.fulfilled, (state, action: PayloadAction<AprobacionConstancia[]>) => {
        state.loadingByConstancia = false;
        state.aprobacionesByConstancia = action.payload;
      })
      .addCase(fetchAprobacionesByConstancia.rejected, (state, action) => {
        state.loadingByConstancia = false;
        state.errorByConstancia = action.payload as string;
      })
      // Add
      .addCase(addAprobacionConstancia.fulfilled, (state, action: PayloadAction<AprobacionConstancia>) => {
        state.aprobacionesConstancia.push(action.payload);
      })
      // Update
      .addCase(updateAprobacionConstancia.fulfilled, (state, action: PayloadAction<AprobacionConstancia>) => {
        const index = state.aprobacionesConstancia.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.aprobacionesConstancia[index] = action.payload;
        }
      })
      // Delete
      .addCase(deleteAprobacionConstancia.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.aprobacionesConstancia = state.aprobacionesConstancia.filter(item => item.id !== action.payload.id);
      });
  },
});

export const aprobacionConstanciaReducer = aprobacionConstanciaSlice.reducer;
