import React, { useMemo } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';
import { ValoracionProveedor } from '../../slices/valoracionProveedorSlice';
import { formatDate } from '../../components/Utils/dateUtils';

// Tipo para el producto/servicio después de convertirlo de string a array
type ProductoServicio = [string, boolean];

interface FichaPDFProveedorGeneratorProps {
  valoracionData?: ValoracionProveedor;
}

const FichaPDFProveedorGenerator: React.FC<FichaPDFProveedorGeneratorProps> = ({
  valoracionData
}) => {
  console.log('FichaPDFProveedorGenerator - Datos de valoración:', valoracionData);
  // Función para convertir el string p_s_brindado a un array (copiada de ValoracionForm)
  const stringToArray = (psBrindadoString: string): ProductoServicio[] => {
    if (!psBrindadoString || psBrindadoString === '') return [['', false]];

    try {
      // El string ya viene como un array JSON, simplemente lo parseamos
      return JSON.parse(psBrindadoString);
    } catch (error) {
      console.error("Error al convertir p_s_brindado a array:", error);
      return [['', false]];
    }
  };

  // Procesar productos/servicios
  const productosServicios = useMemo(() => {
    if (!valoracionData?.p_s_brindado) return [];
    return stringToArray(valoracionData.p_s_brindado);
  }, [valoracionData?.p_s_brindado]);

  // Criterios estructurados para el PDF
  const criteriosSeleccion = useMemo(() => {
    if (!valoracionData) return [];

    return [
      {
        id: 1,
        nombre: 'Experiencia en el Mercado',
        descripcion: 'Más de 3 años = 15 Puntos, 2 años = 7.5 Puntos, Menos de 1 año = 2 Puntos',
        puntaje_maximo: 15,
        puntaje_obtenido: valoracionData.experiencia || 0
      },
      {
        id: 2,
        nombre: 'Condiciones de Pago',
        descripcion: 'Credito a más de 30 días = 15 Puntos, Contado = 7.5 Puntos',
        puntaje_maximo: 15,
        puntaje_obtenido: valoracionData.condiciones_pago || 0
      },
      {
        id: 3,
        nombre: 'Garantía del producto/servicio',
        descripcion: 'Si ofrece = 10 Puntos, No ofrece = 5 Puntos',
        puntaje_maximo: 10,
        puntaje_obtenido: valoracionData.garantia || 0
      },
      {
        id: 4,
        nombre: 'Controles en Materia de Calidad, MA y SST',
        descripcion: 'Si = 10 Puntos, En Proceso = 5 Puntos, No = 1.5 Puntos',
        puntaje_maximo: 10,
        puntaje_obtenido: valoracionData.control || 0
      },
      {
        id: 5,
        nombre: 'Infraestructura y capacidad',
        descripcion: 'Si = 15 Puntos, A veces = 7.5 Puntos, No = 1.5 Puntos',
        puntaje_maximo: 15,
        puntaje_obtenido: valoracionData.infraestructura || 0
      },
      {
        id: 6,
        nombre: 'Cuestionario socio de negocio',
        descripcion: 'Si = 10 Puntos, Solo 01 = 5 Puntos, No = 2 Puntos',
        puntaje_maximo: 10,
        puntaje_obtenido: valoracionData.cuestionario || 0
      }
    ];
  }, [valoracionData]);

  const criteriosEvaluacion = useMemo(() => {
    if (!valoracionData) return [];

    return [
      {
        id: 7,
        nombre: 'Disponibilidad del producto o servicio',
        descripcion: 'Siempre tiene disponibilidad = 15 Puntos, Pocas veces no cuentan = 7.5 Puntos, No tiene disponibilidad = 1.5 Puntos',
        puntaje_maximo: 15,
        puntaje_obtenido: valoracionData.disponibilidad || 0
      },
      {
        id: 8,
        nombre: 'Asesoramiento personalizado',
        descripcion: 'Cuenta con asesoramiento = 10 Puntos, Demora en atender = 5 Puntos, No cuenta con asesoramiento = 1 punto',
        puntaje_maximo: 10,
        puntaje_obtenido: valoracionData.asesoramiento || 0
      },
      {
        id: 9,
        nombre: 'Cumple especificaciones de OC',
        descripcion: 'Siempre cumple = 20 Puntos, A veces = 10 Puntos, No cumple = 1.5 Puntos',
        puntaje_maximo: 20,
        puntaje_obtenido: valoracionData.especificaciones || 0
      },
      {
        id: 10,
        nombre: 'Cumple tiempos establecidos',
        descripcion: 'Siempre cumple = 20 Puntos, A veces = 10 Puntos, No cumple = 1.5 Puntos',
        puntaje_maximo: 20,
        puntaje_obtenido: valoracionData.tiempos || 0
      },
      {
        id: 11,
        nombre: 'Entrega de certificados/documentación',
        descripcion: 'Siempre cumple = 15 Puntos, Parcialmente = 7.5 Puntos, No cumple = 1.5 Puntos',
        puntaje_maximo: 15,
        puntaje_obtenido: valoracionData.certificados || 0
      },
      {
        id: 12,
        nombre: 'Precio',
        descripcion: 'Mejor precio del mercado = 25 Puntos, Precio promedio = 15 Puntos, Precio por encima del mercado = 5 Puntos',
        puntaje_maximo: 25,
        puntaje_obtenido: valoracionData.precio || 0
      },
      {
        id: 13,
        nombre: 'Cumplimiento Ambiental',
        descripcion: 'Cumple completamente = 10 Puntos, Cumple parcialmente = 5 Puntos, No cumple = 0 puntos',
        puntaje_maximo: 10,
        puntaje_obtenido: valoracionData.ambiental || 0,
        notas: valoracionData.ambiental === undefined ? 'NA= No aplica' : undefined
      },
      {
        id: 14,
        nombre: 'Cumplimiento de Seguridad y Salud en el Trabajo',
        descripcion: 'Cumple completamente = 10 Puntos, Cumple parcialmente = 5 Puntos, No cumple = 0 puntos',
        puntaje_maximo: 10,
        puntaje_obtenido: valoracionData.sst || 0,
        notas: valoracionData.sst === undefined ? 'NA= No aplica' : undefined
      }
    ];
  }, [valoracionData]);

  // Calcular puntajes totales
  const puntajeTotalSeleccion = useMemo(() => {
    return criteriosSeleccion.reduce((total, criterio) => total + criterio.puntaje_obtenido, 0);
  }, [criteriosSeleccion]);

  const puntajeTotalEvaluacion = useMemo(() => {
    return criteriosEvaluacion.reduce((total, criterio) => total + criterio.puntaje_obtenido, 0);
  }, [criteriosEvaluacion]);

  // Añade estos cálculos después de calcular puntajeTotalSeleccion y puntajeTotalEvaluacion
  const puntajeTotal = useMemo(() => {
    return puntajeTotalSeleccion + puntajeTotalEvaluacion;
  }, [puntajeTotalSeleccion, puntajeTotalEvaluacion]);

  // Reemplaza la función de clasificación existente con esta nueva lógica
  const clasificacionProveedor = useMemo(() => {
    // Si no hay evaluación, clasificar según criterios de selección
    if (puntajeTotalEvaluacion === 0) {
      if (puntajeTotalSeleccion >= 72.5) return { texto: 'BUEN PROVEEDOR', color: '#276749' }; // Verde
      if (puntajeTotalSeleccion >= 42.5) return { texto: 'REGULAR', color: '#c05621' }; // Naranja
      return { texto: 'DESAPROBADO', color: '#e53e3e' }; // Rojo
    }
    // Si hay evaluación, clasificar según criterios de evaluación
    else {
      if (puntajeTotalEvaluacion > 170) return { texto: 'BUEN PROVEEDOR', color: '#276749' }; // Verde
      if (puntajeTotalEvaluacion >= 135) return { texto: 'REGULAR', color: '#c05621' }; // Naranja
      return { texto: 'DESAPROBADO', color: '#e53e3e' }; // Rojo
    }
  }, [puntajeTotalSeleccion, puntajeTotalEvaluacion]);

  // También actualiza el contenido de la tarjeta de clasificación para mostrar qué criterios se están usando
  const clasificacionCriterio = useMemo(() => {
    return puntajeTotalEvaluacion === 0 ? "Según Criterios de Selección" : "Según Criterios de Evaluación";
  }, [puntajeTotalEvaluacion]);

  // Determinar si alguno de los productos/servicios es crítico
  const esCritico = useMemo(() => {
    return productosServicios.some(ps => ps[1]);
  }, [productosServicios]);

  // Colores empresariales
  const empresaColor = '#2c5282'; // Azul corporativo

  // Helper function to adjust color brightness
  const adjustBrightness = (color: string, percent: number) => {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = (num >> 8 & 0x00FF) + amt;
    const B = (num & 0x0000FF) + amt;
    return `#${(1 << 24 | (R < 255 ? R < 0 ? 0 : R : 255) << 16 | (G < 255 ? G < 0 ? 0 : G : 255) << 8 | (B < 255 ? B < 0 ? 0 : B : 255)).toString(16).slice(1)}`;
  };

  // Crear variaciones de color
  const empresaColorUltraLighter = adjustBrightness(empresaColor, 75);
  const empresaColorLighter = adjustBrightness(empresaColor, 20);
  const empresaColorDarker = adjustBrightness(empresaColor, -20);
  const empresaColorMoreDarker = adjustBrightness(empresaColor, -30);

  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontSize: 9,
      backgroundColor: '#ffffff',
      fontFamily: 'Helvetica',
    },
    header: {
      flexDirection: 'row',
      marginBottom: 20,
      borderBottomWidth: 1,
      borderBottomColor: empresaColorLighter,
      paddingBottom: 10,
    },
    headerLeft: {
      width: '50%',
    },
    headerRight: {
      width: '50%',
      alignItems: 'flex-end',
    },
    title: {
      fontSize: 16,
      fontFamily: 'Helvetica-Bold',
      marginBottom: 5,
      color: empresaColorDarker,
    },
    codigo: {
      fontSize: 12,
      marginBottom: 5,
      color: empresaColorMoreDarker,
    },
    infoBasica: {
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: empresaColorLighter,
      borderRadius: 5,
      padding: 10,
      marginBottom: 15,
      backgroundColor: empresaColorUltraLighter,
    },
    infoBasicaLeft: {
      width: '50%',
    },
    infoBasicaRight: {
      width: '50%',
    },
    row: {
      flexDirection: 'row',
      marginBottom: 5,
    },
    label: {
      fontFamily: 'Helvetica-Bold',
      marginRight: 5,
      color: empresaColorDarker,
      width: 100,
    },
    value: {
      flex: 1,
      flexWrap: 'wrap',
    },
    tableTitles: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
      marginBottom: 5,
    },
    subtitle: {
      fontSize: 12,
      fontFamily: 'Helvetica-Bold',
      color: empresaColorDarker,
      marginTop: 5,
      marginBottom: 5,
      paddingBottom: 3,
      borderBottomWidth: 1,
      borderBottomColor: empresaColorLighter,
    },
    criteriosTable: {
      marginTop: 5,
      marginBottom: 15,
      borderWidth: 1,
      borderColor: empresaColorLighter,
      borderRadius: 5,
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: empresaColorMoreDarker,
      padding: 8,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    tableHeaderText: {
      color: '#ffffff',
      fontFamily: 'Helvetica-Bold',
      fontSize: 8,
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: empresaColorLighter,
      padding: 6,
      minHeight: 25,
    },
    tableRowAlt: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: empresaColorLighter,
      padding: 6,
      minHeight: 25,
      backgroundColor: empresaColorUltraLighter,
    },
    tableCell: {
      fontSize: 8,
      flexWrap: 'wrap',
    },
    tableCellNum: {
      fontSize: 8,
      textAlign: 'center',
    },
    colItem: { width: '5%' },
    colCriterio: { width: '45%' },
    colPuntajeMax: { width: '15%', textAlign: 'center' },
    colPuntajeObt: { width: '15%', textAlign: 'center' },
    colParametros: { width: '20%' },
    totalContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      padding: 8,
      backgroundColor: empresaColorLighter,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
    totalLabel: {
      fontFamily: 'Helvetica-Bold',
      marginRight: 10,
      fontSize: 9,
      color: '#ffffff',
    },
    totalValue: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 9,
      color: '#ffffff',
      width: '15%',
      textAlign: 'center',
    },
    resultadosContainer: {
      marginTop: 15,
      borderWidth: 1,
      borderColor: empresaColorLighter,
      borderRadius: 5,
      padding: 10,
      backgroundColor: empresaColorUltraLighter,
    },
    resultadosRow: {
      flexDirection: 'row',
      marginBottom: 10,
      justifyContent: 'space-between',
    },
    resultadosColumn: {
      width: '32%',
      borderWidth: 1,
      borderColor: empresaColorLighter,
      borderRadius: 4,
      backgroundColor: '#ffffff',
    },
    resultadosTitle: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 10,
      color: '#ffffff',
      marginBottom: 5,
      textAlign: 'center',
      padding: 4,
      backgroundColor: empresaColorDarker,
    },
    criterioRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: 8,
      marginBottom: 0,
      paddingVertical: 4,
      paddingHorizontal: 6,
      borderBottomWidth: 1,
      borderBottomColor: empresaColorUltraLighter,
    },
    criterioLastRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: 8,
      paddingVertical: 4,
      paddingHorizontal: 6,
    },
    criterioRowAlt: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: 8,
      marginBottom: 0,
      paddingVertical: 4,
      paddingHorizontal: 6,
      backgroundColor: empresaColorUltraLighter,
      borderBottomWidth: 1,
      borderBottomColor: empresaColorUltraLighter,
    },
    criterioLabel: {
      width: '50%',
      fontFamily: 'Helvetica',
    },
    criterioValue: {
      width: '50%',
      textAlign: 'right',
      fontFamily: 'Helvetica-Bold',
    },
    footer: {
      marginTop: 20,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    footerItem: {
      width: '30%',
    },
    footerTitle: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 9,
      color: empresaColorDarker,
      marginBottom: 5,
      textAlign: 'center',
      borderBottomWidth: 1,
      borderBottomColor: empresaColorLighter,
      paddingBottom: 3,
    },
    footerValue: {
      fontSize: 8,
      textAlign: 'center',
      padding: 5,
    },
    observacionesContainer: {
      marginTop: 15,
      borderWidth: 1,
      borderColor: empresaColorLighter,
      borderRadius: 5,
      padding: 10,
    },
    observacionesTitle: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 10,
      color: empresaColorDarker,
      marginBottom: 5,
    },
    observacionesText: {
      fontSize: 8,
      minHeight: 40,
    },
    noDataMessage: {
      fontSize: 14,
      fontFamily: 'Helvetica-Bold',
      color: '#ff0000',
      textAlign: 'center',
      marginTop: 100,
    },
    productosServiciosContainer: {
      marginTop: 15,
      borderWidth: 1,
      borderColor: empresaColorLighter,
      borderRadius: 5,
      padding: 10,
      backgroundColor: '#ffffff',
    },
    productosServiciosTitle: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 10,
      color: empresaColorDarker,
      marginBottom: 8,
      borderBottomWidth: 1,
      borderBottomColor: empresaColorLighter,
      paddingBottom: 3,
    },
    productoItemContainer: {
      flexDirection: 'row',
      marginBottom: 5,
      paddingVertical: 3,
      borderBottomWidth: 1,
      borderBottomStyle: 'dotted',
      borderBottomColor: empresaColorUltraLighter,
    },
    productoNombre: {
      flex: 3,
      fontSize: 9,
    },
    productoCriticoContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    productoCriticoLabel: {
      fontSize: 9,
      fontFamily: 'Helvetica-Bold',
      marginRight: 6,
      color: empresaColorDarker,
    },
    productoCriticoValue: {
      fontSize: 9,
      color: '#e53e3e', // Color rojo para resaltar cuando es crítico
      fontFamily: 'Helvetica-Bold',
    },
    noCriticoValue: {
      fontSize: 9,
      color: '#276749', // Color verde para cuando no es crítico
    },
    resumenCritico: {
      marginTop: 10,
      flexDirection: 'row',
      alignItems: 'center',
    },
    resumenCriticoLabel: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 9,
      color: empresaColorDarker,
      marginRight: 10,
    },
    resumenCriticoValue: {
      fontFamily: 'Helvetica-Bold',
      fontSize: 9,
      color: '#e53e3e', // Rojo para resaltar cuando algún producto es crítico
    },
    noProductosMessage: {
      fontSize: 9,
      fontStyle: 'italic',
      color: '#718096',
      textAlign: 'center',
      padding: 5,
    },
    resumenPuntajeContainer: {
      marginBottom: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    puntajeTotalCard: {
      width: '48%',
      borderWidth: 3,
      borderColor: empresaColorDarker,
      borderRadius: 8,
      padding: 10,
      backgroundColor: '#ffffff',
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    puntajeTotalHeader: {
      fontSize: 14,
      fontFamily: 'Helvetica-Bold',
      color: empresaColorDarker,
      textAlign: 'center',
      marginBottom: 5,
      paddingBottom: 5,
      borderBottomWidth: 1,
      borderBottomColor: empresaColorLighter,
    },
    puntajeTotalValor: {
      fontSize: 28,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'center',
      color: empresaColorMoreDarker,
    },
    puntajeMaximoNota: {
      fontSize: 8,
      fontFamily: 'Helvetica',
      textAlign: 'center',
      color: '#718096',
      marginTop: 5,
    },
    clasificacionCard: {
      width: '48%',
      borderWidth: 3,
      borderRadius: 8,
      padding: 10,
      backgroundColor: '#ffffff',
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    clasificacionHeader: {
      fontSize: 14,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'center',
      marginBottom: 5,
      paddingBottom: 5,
      borderBottomWidth: 1,
    },
    clasificacionValor: {
      fontSize: 22,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'center',
      marginTop: 2,
      padding: 4,
    },
    puntajesDesglose: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 5,
      paddingTop: 5,
      borderTopWidth: 1,
      borderTopStyle: 'dashed',
      borderTopColor: '#CBD5E0',
    },
    puntajeItem: {
      alignItems: 'center',
      marginHorizontal: 10,
    },
    puntajeItemLabel: {
      fontSize: 8,
      color: '#4A5568',
    },
    puntajeItemValor: {
      fontSize: 12,
      fontFamily: 'Helvetica-Bold',
      color: empresaColorDarker,
    },
  });

  const isMobile = typeof window !== 'undefined' ? /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) : false;

  // Si no hay datos, mostrar un mensaje
  if (!valoracionData) {
    return (
      <PDFViewer style={{ width: '100%', height: '800px' }}>
        <Document title="Sin datos de evaluación">
          <Page size="A4" style={styles.page}>
            <Text style={styles.noDataMessage}>No hay datos disponibles para generar el PDF</Text>
          </Page>
        </Document>
      </PDFViewer>
    );
  }

  const PDFContent = (
    <Document title={`Ficha_Proveedor_${valoracionData.proveedor_id?.razon_social || ''}.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Text style={styles.title}>FICHA DE PROVEEDOR</Text>
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.codigo}>FO-LOC-004</Text>
            <Text>Versión: 3</Text>
            <Text>Fecha de Aprobación: {formatDate(valoracionData.fecha_inicio, "dd/mm/yyyy")}</Text>
          </View>
        </View>

        <View style={styles.resumenPuntajeContainer}>
          <View style={styles.puntajeTotalCard}>
            <Text style={styles.puntajeTotalHeader}>PUNTAJE TOTAL</Text>
            <Text style={styles.puntajeTotalValor}>{puntajeTotal}/245</Text>
            <Text style={styles.puntajeMaximoNota}>Puntaje máximo posible: 245 puntos</Text>

            <View style={styles.puntajesDesglose}>
              <View style={styles.puntajeItem}>
                <Text style={styles.puntajeItemLabel}>SELECCIÓN</Text>
                <Text style={styles.puntajeItemValor}>{puntajeTotalSeleccion}</Text>
              </View>
              <View style={styles.puntajeItem}>
                <Text style={styles.puntajeItemLabel}>EVALUACIÓN</Text>
                <Text style={styles.puntajeItemValor}>{puntajeTotalEvaluacion}</Text>
              </View>
            </View>
          </View>

          <View style={[styles.clasificacionCard, { borderColor: clasificacionProveedor.color }]}>
            <Text style={[styles.clasificacionHeader, { color: clasificacionProveedor.color, borderBottomColor: clasificacionProveedor.color }]}>
              CLASIFICACIÓN
            </Text>
            <Text style={[styles.clasificacionValor, { color: clasificacionProveedor.color }]}>
              {clasificacionProveedor.texto}
            </Text>
            <Text style={{
              fontSize: 8,
              textAlign: 'center',
              color: '#718096',
              marginTop: 2,
            }}>
              {clasificacionCriterio}
            </Text>
          </View>
        </View>

        <View style={styles.infoBasica}>
          <View style={styles.infoBasicaLeft}>
            <View style={styles.row}>
              <Text style={styles.label}>Razón Social:</Text>
              <Text style={styles.value}>{valoracionData.proveedor_id?.razon_social || 'No especificado'}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Dirección:</Text>
              <Text style={styles.value}>{valoracionData.proveedor_id?.direccion || 'No especificada'}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Fecha Evaluación:</Text>
              <Text style={styles.value}>{formatDate(valoracionData.fecha_ingreso, "dd/mm/yyyy") || 'No especificada'}</Text>
            </View>
          </View>
          <View style={styles.infoBasicaRight}>
            <View style={styles.row}>
              <Text style={styles.label}>N° de RUC:</Text>
              <Text style={styles.value}>{valoracionData.proveedor_id?.ruc || 'No especificado'}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Horario de Atención:</Text>
              <Text style={styles.value}>{valoracionData.proveedor_id?.horario || 'No especificado'}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Siguiente Evaluación:</Text>
              <Text style={styles.value}>{formatDate(valoracionData.fecha_fin, "dd/mm/yyyy") || 'No especificado'}</Text>
            </View>
          </View>
        </View>

        {/* Evaluación de Selección */}
        <Text style={styles.subtitle}>EVALUACIÓN DE SELECCIÓN</Text>
        <View style={styles.criteriosTable}>
          <View style={styles.tableHeader}>
            <Text style={[styles.tableHeaderText, styles.colItem]}>Ítem</Text>
            <Text style={[styles.tableHeaderText, styles.colCriterio]}>CRITERIOS</Text>
            <Text style={[styles.tableHeaderText, styles.colPuntajeMax]}>Puntaje Max.</Text>
            <Text style={[styles.tableHeaderText, styles.colPuntajeObt]}>Puntaje Obtenido</Text>
            <Text style={[styles.tableHeaderText, styles.colParametros]}>PARAMETROS</Text>
          </View>

          {criteriosSeleccion.map((criterio, index) => (
            <View
              key={`seleccion-${criterio.id}`}
              style={index % 2 === 0 ? styles.tableRow : styles.tableRowAlt}
            >
              <Text style={[styles.tableCellNum, styles.colItem]}>{criterio.id}</Text>
              <Text style={[styles.tableCell, styles.colCriterio]}>{criterio.nombre}</Text>
              <Text style={[styles.tableCellNum, styles.colPuntajeMax]}>{criterio.puntaje_maximo}</Text>
              <Text style={[styles.tableCellNum, styles.colPuntajeObt]}>{criterio.puntaje_obtenido}</Text>
              <Text style={[styles.tableCell, styles.colParametros]}>{criterio.descripcion}</Text>
            </View>
          ))}

          <View style={styles.totalContainer}>
            <Text style={styles.totalLabel}>PUNTAJE TOTAL</Text>
            <Text style={styles.totalValue}>{puntajeTotalSeleccion}</Text>
          </View>
        </View>

        {/* Evaluación del Servicio/Producto */}
        <Text style={styles.subtitle}>EVALUACIÓN DEL SERVICIO/PRODUCTO</Text>
        <View style={styles.criteriosTable}>
          <View style={styles.tableHeader}>
            <Text style={[styles.tableHeaderText, styles.colItem]}>Ítem</Text>
            <Text style={[styles.tableHeaderText, styles.colCriterio]}>CRITERIOS</Text>
            <Text style={[styles.tableHeaderText, styles.colPuntajeMax]}>Puntaje Max.</Text>
            <Text style={[styles.tableHeaderText, styles.colPuntajeObt]}>Puntaje Obtenido</Text>
            <Text style={[styles.tableHeaderText, styles.colParametros]}>PARAMETROS</Text>
          </View>

          {criteriosEvaluacion.map((criterio, index) => (
            <View
              key={`evaluacion-${criterio.id}`}
              style={index % 2 === 0 ? styles.tableRow : styles.tableRowAlt}
            >
              <Text style={[styles.tableCellNum, styles.colItem]}>{criterio.id}</Text>
              <Text style={[styles.tableCell, styles.colCriterio]}>
                {criterio.nombre}
                {criterio.notas ? `\n${criterio.notas}` : ''}
              </Text>
              <Text style={[styles.tableCellNum, styles.colPuntajeMax]}>{criterio.puntaje_maximo}</Text>
              <Text style={[styles.tableCellNum, styles.colPuntajeObt]}>
                {criterio.notas === 'NA= No aplica' ? 'NA' : criterio.puntaje_obtenido}
              </Text>
              <Text style={[styles.tableCell, styles.colParametros]}>{criterio.descripcion}</Text>
            </View>
          ))}

          <View style={styles.totalContainer}>
            <Text style={styles.totalLabel}>PUNTAJE TOTAL</Text>
            <Text style={styles.totalValue}>{puntajeTotalEvaluacion}</Text>
          </View>
        </View>

        {/* Resultados y criterios */}
        <View style={styles.resultadosContainer}>
          <View style={styles.resultadosRow}>
            <View style={styles.resultadosColumn}>
              <Text style={styles.resultadosTitle}>PUNTAJE CRITERIOS SELECCIÓN</Text>
              <View style={styles.criterioRow}>
                <Text style={styles.criterioLabel}>{'<42.5'}</Text>
                <Text style={styles.criterioValue}>Desaprobado</Text>
              </View>
              <View style={styles.criterioRowAlt}>
                <Text style={styles.criterioLabel}>42.5 - 72.5</Text>
                <Text style={styles.criterioValue}>Regular</Text>
              </View>
              <View style={styles.criterioLastRow}>
                <Text style={styles.criterioLabel}>≥ 72.5</Text>
                <Text style={styles.criterioValue}>Buen Proveedor</Text>
              </View>
            </View>
            <View style={styles.resultadosColumn}>
              <Text style={styles.resultadosTitle}>PROXIMA EVALUACIÓN</Text>
              <View style={styles.criterioRow}>
                <Text style={styles.criterioLabel}>Si es Desaprobado</Text>
                <Text style={styles.criterioValue}>A los 6 Meses</Text>
              </View>
              <View style={styles.criterioRowAlt}>
                <Text style={styles.criterioLabel}>Si es Regular</Text>
                <Text style={styles.criterioValue}>A los 12 meses</Text>
              </View>
              <View style={styles.criterioLastRow}>
                <Text style={styles.criterioLabel}>Si es Buen Proveedor</Text>
                <Text style={styles.criterioValue}>A los 18 meses</Text>
              </View>
            </View>
            <View style={styles.resultadosColumn}>
              <Text style={styles.resultadosTitle}>PUNTAJE CRITERIOS EVALUACIÓN</Text>
              <View style={styles.criterioRow}>
                <Text style={styles.criterioLabel}>{'<135'}</Text>
                <Text style={styles.criterioValue}>Desaprobado</Text>
              </View>
              <View style={styles.criterioRowAlt}>
                <Text style={styles.criterioLabel}>135 - 170</Text>
                <Text style={styles.criterioValue}>Regular</Text>
              </View>
              <View style={styles.criterioLastRow}>
                <Text style={styles.criterioLabel}>{'>170'}</Text>
                <Text style={styles.criterioValue}>Buen Proveedor</Text>
              </View>
            </View>
          </View>
        </View>

        {/* Observaciones */}
        <View style={styles.observacionesContainer}>
          <Text style={styles.observacionesTitle}>OBSERVACIONES</Text>
          <Text style={styles.observacionesText}>{valoracionData.notas || 'Sin observaciones'}</Text>
        </View>

        {/* Información crítico */}
        <View style={styles.productosServiciosContainer}>
          <Text style={styles.productosServiciosTitle}>PRODUCTOS O SERVICIOS BRINDADOS</Text>

          {productosServicios.length > 0 && productosServicios[0][0] !== '' ? (
            <>
              {productosServicios.map((ps, index) => (
                <View key={`producto-${index}`} style={styles.productoItemContainer}>
                  <Text style={styles.productoNombre}>{ps[0]}</Text>
                  <View style={styles.productoCriticoContainer}>
                    <Text style={styles.productoCriticoLabel}>¿Crítico?:</Text>
                    <Text style={ps[1] ? styles.productoCriticoValue : styles.noCriticoValue}>
                      {ps[1] ? 'SI' : 'NO'}
                    </Text>
                  </View>
                </View>
              ))}

              <View style={styles.resumenCritico}>
                <Text style={styles.resumenCriticoLabel}>Proveedor de Producto/Servicio Crítico:</Text>
                <Text style={styles.resumenCriticoValue}>{esCritico ? 'SÍ' : 'NO'}</Text>
              </View>
            </>
          ) : (
            <Text style={styles.noProductosMessage}>No hay productos o servicios especificados</Text>
          )}
        </View>
      </Page>
    </Document>
  );

  return isMobile ? (
    PDFContent
  ) : (
    <PDFViewer style={{ width: '100%', height: '800px' }}>
      {PDFContent}
    </PDFViewer>
  );
};

export default FichaPDFProveedorGenerator;