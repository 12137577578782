import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Localidad } from '../../../../slices/localidadSlice';

interface LocalidadFormProps {
  onSubmit: (data: Omit<Localidad, 'id_localidad'>) => void;
}

const LocalidadForm: React.FC<LocalidadFormProps> = ({ onSubmit }) => {
  const { distritos } = useSelector((state: RootState) => state.distrito);
  const [formData, setFormData] = useState({
    nombre_localidad: '',
    id_distrito: distritos[0]?.id_distrito || ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6 max-w-full mx-auto">
      <h2 className="text-xl font-semibold mb-6 text-gray-800 dark:text-white">Nueva Localidad</h2>
      
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Nombre de la Localidad
          </label>
          <input
            type="text"
            value={formData.nombre_localidad}
            onChange={(e) => setFormData({...formData, nombre_localidad: e.target.value})}
            className="w-full px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 
                     focus:ring-2 focus:ring-cyan-500 focus:border-transparent
                     bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100
                     transition duration-150 ease-in-out"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Distrito
          </label>
          <select
            value={formData.id_distrito}
            onChange={(e) => setFormData({...formData, id_distrito: e.target.value})}
            className="w-full px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 
                     focus:ring-2 focus:ring-cyan-500 focus:border-transparent
                     bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100
                     transition duration-150 ease-in-out"
            required
          >
            {distritos.map(dist => (
              <option key={dist.id_distrito} value={dist.id_distrito}>
                {dist.nombre_distrito}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-8">
        <button
          type="submit"
          className="w-full px-4 py-2.5 bg-gradient-to-r from-cyan-500 to-cyan-600 
                   text-white font-medium rounded-md hover:from-cyan-600 hover:to-cyan-700
                   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500
                   transform transition duration-150 ease-in-out hover:scale-[1.02]
                   shadow-md"
        >
          Crear Localidad
        </button>
      </div>
    </form>
  );
};

export default LocalidadForm;
