import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_APROBACIONES = gql`
  query ListAprobacionesComprobanteOrdenes {
    listAprobacionesComprobanteOrdenes {
      id
      comprobante_ordenes_compra_id {
        id
        orden_compra_id
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const GET_APROBACIONES_BY_COMPROBANTE = gql`
  query GetAprobacionesByComprobanteOrdenId($comprobanteOrdenId: ID!) {
    getAprobacionesByComprobanteOrdenId(comprobanteOrdenId: $comprobanteOrdenId) {
      id
      comprobante_ordenes_compra_id {
        id
        orden_compra_id
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const ADD_APROBACION = gql`
  mutation AddAprobacionComprobanteOrden(
    $comprobante_ordenes_compra_id: ID!
    $usuario_id: ID!
    $estado: String!
    $fecha: DateTime
  ) {
    addAprobacionComprobanteOrden(
      comprobante_ordenes_compra_id: $comprobante_ordenes_compra_id
      usuario_id: $usuario_id
      estado: $estado
      fecha: $fecha
    ) {
      id
      estado
      fecha
    }
  }
`;

export interface AprobacionComprobanteOrdenInput {
  comprobante_ordenes_compra_id: string;
  usuario_id: string;
  estado: string;
  fecha?: Date;
}

export interface AprobacionComprobanteOrden {
  id: string;
  comprobante_ordenes_compra_id: {
    id: string;
    orden_compra_id: string;
  };
  usuario_id: {
    id: string;
    usuario: string;
  };
  estado: string;
  fecha: string;
}

// Services
export const listAprobacionesService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_APROBACIONES,
      fetchPolicy: 'network-only',
    });
    return data.listAprobacionesComprobanteOrdenes;
  } catch (error) {
    throw new Error(`Error fetching aprobaciones: ${error}`);
  }
};

export const getAprobacionesByComprobanteOrdenService = async (comprobanteOrdenId: string) => {
  try {
    const { data } = await client.query({
      query: GET_APROBACIONES_BY_COMPROBANTE,
      variables: { comprobanteOrdenId },
      fetchPolicy: 'network-only',
    });
    return data.getAprobacionesByComprobanteOrdenId;
  } catch (error) {
    throw new Error(`Error fetching aprobaciones by comprobante orden: ${error}`);
  }
};

export const addAprobacionService = async (input: AprobacionComprobanteOrdenInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_APROBACION,
      variables: input,
    });
    return data.addAprobacionComprobanteOrden;
  } catch (error) {
    throw new Error(`Error adding aprobacion: ${error}`);
  }
};
