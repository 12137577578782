import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchFormularios } from '../../slices/formularioSlice';
import { Card, CardContent, Typography, Grid, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TodosLosFormularios: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { formularios, loading, error } = useSelector((state: RootState) => state.formulario);

  useEffect(() => {
    dispatch(fetchFormularios());
  }, [dispatch]);

  if (loading) {
    return <Typography>Cargando formularios...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Formularios Disponibles
      </Typography>
      <Grid container spacing={3}>
        {formularios.map((formulario) => (
          <Grid item xs={12} md={6} lg={4} key={formulario.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {formulario.titulo}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Código: {formulario.codigo}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Versión: {formulario.version}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formulario.descripcion}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => navigate(`/dashboard/responder-formulario/${formulario.id}`)}
                  >
                    Responder Formulario
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TodosLosFormularios;
