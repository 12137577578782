import React from 'react';

interface SimpleLoaderProps {
  text?: string;
  size?: 'sm' | 'md' | 'lg';
  textColor?: string;
  loaderColor?: {
    border1?: string;
    border2?: string;
    border3?: string;
    gradient?: string;
    dot?: string;
  };
}

const SimpleLoader: React.FC<SimpleLoaderProps> = ({
  text = 'Cargando...',
  size = 'md',
  textColor = 'bg-gradient-to-r from-blue-500 to-purple-600',
  loaderColor = {
    border1: 'border-blue-900',
    border2: 'border-blue-700',
    border3: 'border-blue-500',
    gradient: 'from-blue-900 via-blue-700 to-blue-500',
    dot: 'bg-blue-600'
  }
}) => {
  // Tamaños predefinidos
  const sizeClasses = {
    sm: {
      container: 'w-16 h-16',
      text: 'text-base',
      dot: 'w-2 h-2'
    },
    md: {
      container: 'w-24 h-24',
      text: 'text-xl',
      dot: 'w-3 h-3'
    },
    lg: {
      container: 'w-32 h-32',
      text: 'text-2xl',
      dot: 'w-4 h-4'
    }
  };

  const currentSize = sizeClasses[size];

  return (
    <div className="flex justify-center items-center">
      <div className={`relative ${currentSize.container}`}>
        <div className={`absolute inset-0 rounded-full border-t-4 ${loaderColor.border1} animate-spin`}></div>
        <div className={`absolute inset-0 rounded-full border-r-4 ${loaderColor.border2} animate-ping opacity-75`}></div>
        <div className={`absolute inset-0 rounded-full border-b-4 ${loaderColor.border3} animate-pulse`}></div>
        <div className={`absolute inset-2 rounded-full bg-gradient-to-r ${loaderColor.gradient} animate-pulse`}></div>
        <div className="absolute inset-4 rounded-full bg-white flex items-center justify-center">
          <div className={`${currentSize.dot} ${loaderColor.dot} rounded-full animate-bounce`}></div>
        </div>
      </div>
      {text && (
        <div className={`ml-4 ${currentSize.text} font-bold bg-clip-text text-transparent ${textColor} animate-pulse`}>
          {text}
        </div>
      )}
    </div>
  );
};

export default SimpleLoader;
