import React, { useState } from 'react';
import { FiEye, FiFileText, FiPlus, FiLoader } from 'react-icons/fi';
import TableComponent from '../../../components/Table/TableComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import Modal from '../../../components/Modal/Modal';
import PDFOCGenerator from '../../OrdenCompraPage/PDFOCGenerator';

interface ListOrdenesCompraProps {
  onAddDocument: (ordenCompra: any) => void;
}

const ListOrdenesCompra: React.FC<ListOrdenesCompraProps> = ({
  onAddDocument,
}) => {
  const [selectedOrdenCompra, setSelectedOrdenCompra] = useState<any>(null);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const { ordenCompras, loading } = useSelector((state: RootState) => state.ordenCompra);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const handleViewPDF = (ordenCompra: any) => {
    setSelectedOrdenCompra(ordenCompra);
    setIsPDFModalOpen(true);
  };

  const tableData = {
    filter: [true, true, true, true, true],
    headers: [
      "Código",
      "Fecha Inicio",
      "Fecha Fin",
      "Estado",
      "Acción"
    ],
    rows: ordenCompras.map(oc => ({
      id: oc.id,
      Código: oc.codigo_orden,
      "Fecha Inicio": formatDate(oc.fecha_ini),
      "Fecha Fin": formatDate(oc.fecha_fin),
      Estado: (
        <span className={`font-medium px-2 py-1 rounded-full ${
          oc.estado ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
          {oc.estado ? 'Activo' : 'Inactivo'}
        </span>
      ),
      Acción: (
        <div className="flex items-center space-x-2 justify-center">
          <button
            onClick={() => handleViewPDF(oc)}
            className="text-blue-500 hover:text-blue-700 transition-colors"
            title="Ver detalle"
          >
            <FiEye size={18} />
          </button>
          <button
            onClick={() => onAddDocument(oc)}
            className="text-green-500 hover:text-green-700 transition-colors"
            title="Subir documento"
          >
            <div className="relative">
              <FiFileText size={18} />
              <FiPlus className="w-2 h-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
            </div>
          </button>
        </div>
      )
    }))
  };

  return (
    <>
      <div className="min-h-[400px] max-h-[600px] overflow-auto">
        {loading ? (
          <div className="flex flex-col items-center justify-center h-full space-y-4">
            <FiLoader className="w-[80px] h-[80px] text-blue-500 animate-spin" />
            <p className="text-xl text-gray-600">Cargando...</p>
          </div>
        ) : ordenCompras.length === 0 ? (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-500">No hay órdenes de compra disponibles</p>
          </div>
        ) : (
  
          <TableComponent tableData={tableData} />
        )}
      </div>

      {/* Modal para el PDF */}
      {selectedOrdenCompra && (
        <Modal
          isOpen={isPDFModalOpen}
          onClose={() => setIsPDFModalOpen(false)}
          title={`Orden de Compra: ${selectedOrdenCompra.codigo_orden}`}
        >
          <div className="min-h-[400px] max-h-[80vh] overflow-auto">
            <PDFOCGenerator
              ordenCompra={{
                ...selectedOrdenCompra,
                obra: "CU PLAN3",
                descripcion: selectedOrdenCompra.descripcion || "",
                fechaEmision: selectedOrdenCompra.fecha_ini,
                fechaEntrega: selectedOrdenCompra.fecha_fin,
                moneda: "Sol",
                cambio: 1.00,
                solicitante: "Usuario Sistema",
                proveedor: {
                  ruc: "20297346353",
                  razonSocial: "PROVEEDOR EJEMPLO",
                  direccion: "Dirección ejemplo",
                  ciudad: "Lima",
                  fonos: "01-2345678",
                  contacto: "Contacto ejemplo",
                  email: "ejemplo@email.com",
                  formaPago: "Transferencia"
                },
                datosBancarios: [
                  {
                    banco: "BCP",
                    nroCta: "193-1234567-0-12",
                    tipoMoneda: "Soles"
                  }
                ],
                datosFacturacion: {
                  rSocial: "INACONS S.R.L.",
                  ruc: "20564567767",
                  giro: "CONSTRUCTORA",
                  direccion: "MZA. D1 LOTE: 3 URB. VILLA SANTA MARIA",
                  ciudad: "CARABAYLLO",
                  departamento: "LIMA",
                  fono: "064791255",
                  email: "ejemplo@inacons.com.pe"
                },
                datosDespacho: {
                  obra: "PROYECTO EJEMPLO",
                  despacho: "DESPACHO EJEMPLO",
                  direccion: "Av. Ejemplo 123",
                  ciudad: "LIMA",
                  departamento: "LIMA",
                  fono: "01-5678901",
                  email: "despacho@ejemplo.com"
                }
              }}
              recursos={[]}
              nombreSolicita="Usuario Sistema" // Add this line
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ListOrdenesCompra;
