import React from 'react';
import { ConsumoPDFWrapperProps } from './consumoPDF.types';
import ConsumoPDFGenerator from './ConsumoPDFGenerator';

const ConsumoPDFWrapper: React.FC<ConsumoPDFWrapperProps> = ({
    recursosNoRetornables,
    empleadoData,
    almaceneroData,
    supervisorData,
    obraData,
}) => {
    const consumoDocument = {
        codigo: `CONSUMO-${obraData.nombre}-${new Date().getTime()}`,
        fecha: new Date(),
        obra: obraData,
        almacenero: almaceneroData,
        supervisor: supervisorData,
        empleado: empleadoData,
        recursos: recursosNoRetornables.map(item => ({
            recurso_id: {
                id: item.recurso.recurso_id.id,
                nombre: item.recurso.recurso_id.nombre,
                codigo: item.recurso.recurso_id.codigo,
            },
            cantidad: item.cantidad,
            costo: item.recurso.costo,
            observaciones: item.observacion
        }))
    };

    return <ConsumoPDFGenerator consumo={consumoDocument} />;
};

export default ConsumoPDFWrapper;
