import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import TableComponent from '../../components/Table/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentosContables, fetchDocumentosContablesRegistrados } from '../../slices/registroSlice';
import { RootState, AppDispatch } from '../../store/store';
import LoaderPage from '../../components/Loader/LoaderPage';
import { FiEdit, FiEye, FiSearch, FiMenu } from 'react-icons/fi';  // Cambiamos FiTrash2 por FiEye
import Button from '../../components/Buttons/Button';
import Modal from '../../components/Modal/Modal';
import NuevoRegistro from './modals/NuevoRegistro';
import EditarRegistro from './modals/EditarRegistro';
import ListConsolidado from './ListConsolidado';
import ListRegistrados from './ListRegistrados';

const Comprobantes: React.FC = () => {
    const [activeTab, setActiveTab] = useState('home');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { documentosContables, loadingDocumentos, errorDocumentos } = useSelector(
        (state: RootState) => state.registro
    );
    const { documentosContablesRegistrados, loadingDocumentosRegistrados } = useSelector(
        (state: RootState) => state.registro
    );
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedObra, setSelectedObra] = useState('');
    const [selectedRange, setSelectedRange] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState<Array<any>>([]);
    const [filteredDataRegistrados, setFilteredDataRegistrados] = useState<Array<any>>([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedComprobante, setSelectedComprobante] = useState<{id: string, estado: string, codigo: string, registrado?: string} | null>(null);

    useEffect(() => {
        dispatch(fetchDocumentosContables());
    }, [dispatch]);

    useEffect(() => {
        if (activeTab === 'profile') {
            dispatch(fetchDocumentosContablesRegistrados());
        }
    }, [dispatch, activeTab]);

    useEffect(() => {
        if (documentosContables.length > 0) {
            handleSearch(); // Aplicar filtros actuales cuando cambian los datos
        }
    }, [documentosContables]); // Solo depende de documentosContables

    useEffect(() => {
        if (documentosContablesRegistrados.length > 0) {
            setFilteredDataRegistrados(documentosContablesRegistrados);
        }
    }, [documentosContablesRegistrados]);

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'UTC'
        });
    };

    const renderOptions = (doc: any) => (
        <div className='flex flex-row gap-2'>
            <button 
                className={`${
                    doc.registro_estado === 'REGISTRADO' 
                        ? 'text-blue-500 hover:text-blue-700' 
                        : 'text-red-500 cursor-not-allowed'
                }`}
                onClick={() => doc.registro_estado === 'REGISTRADO' && handleViewDocument(doc.comprobante_id)}
                disabled={doc.registro_estado !== 'REGISTRADO'}
            >
                <FiEye size={17} /> 
            </button>
            <button 
                className={`${
                    doc.registro_estado === 'PENDIENTE' 
                        ? 'text-blue-500 hover:text-blue-700' 
                        : 'text-red-500 cursor-not-allowed'
                }`}
                onClick={() => doc.registro_estado === 'PENDIENTE' && handleEditDocument(doc.comprobante_id)}
                disabled={doc.registro_estado !== 'PENDIENTE'}
            >
                <FiEdit size={17} /> 
            </button>
        </div>
    );

    const tableData = {
        filterSelect: [false, true, false, true, false, true, false],
        filter: [true, true, true, true, true, true, false],
        headers: [
            "N° Registro",
            "Orden de Compra",
            "Tipo Recurso",
            "Monto",
            "Tipo Documento",
            "Fecha", // Cambiado de "Estado" a "Fecha"
            "Acciones"
        ],
        rows: filteredData.map(doc => ({
            "N° Registro": doc.registro_codigo || 'N/A',
            "Orden de Compra": doc.orden_compra_codigo || 'N/A',
            "Tipo Recurso": doc.tipo_recurso_nombre || 'N/A',
            "Monto": doc.comprobante_monto?.toFixed(2) || '0.00',
            "Tipo Documento": doc.tipo_comprobante_nombre || 'N/A',
            "Fecha": formatDate(doc.registro_fecha_creacion), // Cambiado de registro_estado a registro_fecha_creacion
            "Acciones": renderOptions(doc) // Pasar el documento completo
        })),
        headerClassName: 'text-center',
        rowClassName: 'text-center'
    };

    const handleCreate = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveSuccess = () => {
        dispatch(fetchDocumentosContables()); // Actualizar para recargar documentos contables
    };

    const handleSearch = () => {
        let filtered = [...documentosContables];

        try {
            // Filtrar por fecha si ambas fechas están establecidas
            if (startDate && endDate) {
                const start = new Date(startDate);
                start.setHours(0, 0, 0, 0); // Inicio del día
                
                const end = new Date(endDate);
                end.setHours(23, 59, 59, 999); // Fin del día

                filtered = filtered.filter(doc => {
                    const docDate = new Date(doc.registro_fecha_creacion);
                    return docDate >= start && docDate <= end;
                });
            }

            // Filtrar por rango predefinido si está seleccionado y no hay fechas específicas
            if (selectedRange && !startDate && !endDate) {
                const today = new Date();
                today.setHours(23, 59, 59, 999);
                const days = parseInt(selectedRange);
                const rangeStart = new Date();
                rangeStart.setDate(today.getDate() - days);
                rangeStart.setHours(0, 0, 0, 0);

                filtered = filtered.filter(doc => {
                    const docDate = new Date(doc.registro_fecha_creacion);
                    return docDate >= rangeStart && docDate <= today;
                });
            }

            // Filtrar por texto de búsqueda
            if (searchQuery.trim()) {
                const query = searchQuery.toLowerCase().trim();
                filtered = filtered.filter(doc => 
                    doc.registro_codigo?.toLowerCase().includes(query) ||
                    doc.orden_compra_codigo?.toLowerCase().includes(query) ||
                    doc.tipo_recurso_nombre?.toLowerCase().includes(query)
                );
            }

            setFilteredData(filtered);
            
        } catch (error) {
            console.error('Error al filtrar:', error);
            // Opcional: Mostrar un mensaje de error al usuario
        }
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedObra('');
        setSelectedRange('');
        setStartDate('');
        setEndDate('');
        setFilteredData(documentosContables); // Resetear a datos originales
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, isStart: boolean) => {
        const value = e.target.value;
        if (isStart) {
            setStartDate(value);
            if (endDate && new Date(value) > new Date(endDate)) {
                setEndDate(value); // Actualizar fecha final si es necesario
            }
        } else {
            if (startDate && new Date(value) < new Date(startDate)) {
                setStartDate(value); // Actualizar fecha inicial si es necesario
            }
            setEndDate(value);
        }
    };

    const handleViewDocument = (comprobanteId: string, registrado?: string) => {
        setSelectedComprobante({ 
            id: comprobanteId, 
            estado: 'REGISTRADO',
            codigo: getComprobanteCode(comprobanteId),
            registrado: registrado || 'PENDIENTE' // Añadir el nuevo parámetro
        });
        setIsEditModalOpen(true);
    };

    const handleEditDocument = (comprobanteId: string) => {
        setSelectedComprobante({ 
            id: comprobanteId, 
            estado: 'PENDIENTE',
            codigo: getComprobanteCode(comprobanteId) // Obtener el código del documento actual
        });
        setIsEditModalOpen(true);
    };

    // Función helper para obtener el código del documento
    const getComprobanteCode = (comprobanteId: string) => {
        const documento = filteredData.find(doc => doc.comprobante_id === comprobanteId);
        return documento?.registro_codigo || '';
    };

    if (loadingDocumentos) return <LoaderPage />;
    if (errorDocumentos) return <div>Error: {errorDocumentos}</div>;

    return (
        <div className="p-12">
            {/* Título */}
            <h1 className="font-['Istok_Web'] font-bold text-[24px] leading-[34.55px] text-white mb-4">
                Documentos contables
            </h1>
            
            {/* Container para los tabs */}
            <div className="flex justify-end mb-1">
                    <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                        <li className="mr-2">
                            <button
                                className={`inline-block p-4 rounded-t-lg transition-colors px-8 ${
                                    activeTab === 'home'
                                        ? 'bg-[#0D3588] text-white'
                                        : 'bg-white text-gray-500 hover:bg-[#4C4C4C52]'
                                }`}
                                onClick={() => setActiveTab('home')}
                                role="tab"
                            >
                                Documentos
                            </button>
                        </li>
                        <li className="mr-2">
                            <button
                                className={`inline-block p-4 rounded-t-lg transition-colors px-8 ${
                                    activeTab === 'profile'
                                        ? 'bg-[#0D3588] text-white'
                                        : 'bg-white text-gray-500 hover:bg-[#4C4C4C52]'
                                }`}
                                onClick={() => setActiveTab('profile')}
                                role="tab"
                            >
                                Registros
                            </button>
                        </li>
                        <li className="mr-2">
                            <button
                                className={`inline-block p-4 rounded-t-lg transition-colors  px-8 ${
                                    activeTab === 'contact'
                                        ? 'bg-[#0D3588] text-white'
                                        : 'bg-white text-gray-500 hover:bg-[#4C4C4C52]'
                                }`}
                                onClick={() => setActiveTab('contact')}
                                role="tab"
                            >
                                Reportes
                            </button>
                        </li>
                    </ul>
            </div>

            {/* Tabs Content */}
            <div className="bg-white p-4 rounded-lg">
                <div className={`${activeTab === 'home' ? 'block' : 'hidden'}`} role="tabpanel">
                    <div id="comprobantes" className="overflow-x-auto">
                        <motion.div
                            className="flex-grow"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.8 }}
                        >
                            <div id="crear" className="flex justify-end mb-4">
                                <Button 
                                    text="+ Nuevo Registro" 
                                    color="navy"
                                    onClick={handleCreate}
                                    className="rounded w-auto"
                                />
                            </div>  
                            <div className="flex justify-center mb-6">
                                <div className="flex flex-wrap gap-2 items-center">
                                    {/* Barra de búsqueda */}
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className="w-60 border border-gray-300 rounded-l p-2"
                                            placeholder="Buscar..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <button 
                                            className="absolute right-0 top-0 h-full bg-[#192E58] text-white px-3 rounded-r"
                                            onClick={handleSearch}
                                        >
                                            <FiSearch className="h-5 w-5" />
                                        </button>
                                    </div>

                                    {/* Selectores */}
                                    <select
                                        className="border border-gray-300 rounded p-2"
                                        value={selectedObra}
                                        onChange={(e) => setSelectedObra(e.target.value)}
                                    >
                                        <option value="">Todas las Obras</option>
                                        {/* Agregar más opciones según necesites */}
                                    </select>

                                    <select
                                        className="border border-gray-300 rounded p-2"
                                        value={selectedRange}
                                        onChange={(e) => setSelectedRange(e.target.value)}
                                    >
                                            <option value="">Rango de días</option>
                                            <option value="0">Hoy</option>
                                            <option value="7">Última semana</option>
                                            <option value="15">Últimos 15 Días</option>
                                            <option value="30">Últimos 30 Días</option>
                                    </select>

                                    {/* Inputs de fecha */}
                                    <div className="relative">
                                        <input
                                            type="date"
                                            className="border border-gray-300 rounded p-2 pr-8 cursor-pointer hover:border-blue-500"
                                            value={startDate}
                                            onChange={(e) => handleDateChange(e, true)}
                                            max={endDate || undefined}
                                            style={{ 
                                                colorScheme: 'light',
                                                WebkitAppearance: 'none', // Para Safari
                                                MozAppearance: 'none' // Para Firefox
                                            }}
                                        />
                                     
                                    </div>

                                    <div className="relative">
                                        <input
                                            type="date"
                                            className="border border-gray-300 rounded p-2 pr-8 cursor-pointer hover:border-blue-500"
                                            value={endDate}
                                            onChange={(e) => handleDateChange(e, false)}
                                            min={startDate || undefined}
                                            style={{ 
                                                colorScheme: 'light',
                                                WebkitAppearance: 'none',
                                                MozAppearance: 'none'
                                            }}
                                        />
                                    
                                    </div>

                                    {/* Botones */}
                                    <button
                                        className="bg-[#192E58] text-white px-6 py-2 rounded hover:bg-[#192E58]/90"
                                        onClick={handleSearch}
                                    >
                                        Buscar
                                    </button>
                                    
                                    <button
                                        className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
                                        onClick={handleClear}
                                    >
                                        Limpiar
                                    </button>

                                    <button className="bg-[#192E58] p-2 rounded hover:bg-[#192E58]/90">
                                        <FiMenu className="h-6 w-6 text-white" />
                                    </button>
                                </div>
                            </div>  
                            <TableComponent tableData={tableData} />
                        </motion.div>
                    </div>
                </div>
                <div id="registrados" className={`${activeTab === 'profile' ? 'block' : 'hidden'}`} role="tabpanel">
                    <ListRegistrados 
                        data={filteredDataRegistrados}
                        loading={loadingDocumentosRegistrados}
                        onViewDocument={handleViewDocument}
                    />
                </div>
                <div id="consolidado" className={`${activeTab === 'contact' ? 'block' : 'hidden'}`} role="tabpanel">
                    <ListConsolidado />
                </div>
            </div>

            {/* Modal de Nuevo Registro */}
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                title="Nuevo Registro"
                headerBgColor="bg-[#192E58]"
            >
                <NuevoRegistro 
                    onClose={handleCloseModal}
                    onSaveSuccess={handleSaveSuccess}
                />
            </Modal>

            {/* Modal de Edición */}
            {selectedComprobante && (
                <Modal
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    title={`${selectedComprobante.estado === 'REGISTRADO' ? 'Ver' : 'Editar'} Registro`}
                    headerBgColor="bg-[#192E58]"
                >
                    <EditarRegistro 
                        onClose={() => setIsEditModalOpen(false)}
                        onSaveSuccess={handleSaveSuccess}
                        comprobante={selectedComprobante}
                    />
                </Modal>
            )}
        </div>
    );
};

export default Comprobantes;
