import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiEdit, FiTrash2, FiPlus, FiEye, FiUser, FiDollarSign, FiStar, FiFileText, FiImage } from 'react-icons/fi';
import Button from '../../components/Buttons/Button';
import TableComponent from '../../components/Table/TableComponent';
import {
  addContactoProveedor,
  fetchContactosByProveedor,
  updateContactoProveedor,
  deleteContactoProveedor
} from '../../slices/contactoProveedorSlice';
import {
  addMediosPagoProveedor,
  fetchMediosPagoByProveedor,
  deleteMediosPagoProveedor,
  updateMediosPagoProveedor,
  MediosPagoProveedor
} from '../../slices/mediosPagoProveedorSlice';
import { addProveedor, updateProveedor } from '../../slices/proveedorSlice';
import {  fetchValoracionesByProveedor } from '../../slices/valoracionProveedorSlice';
import { fetchFilesByReferencia } from '../../slices/archivoSustentoSlice';
import type { AppDispatch, RootState } from '../../store/store';
import ContactoForm from './Forms/ContactoForm';
import MediosPagoForm from './Forms/MediosPagoForm';
import ProveedorForm from './Forms/ProveedorForm';
import ValoracionForm from './Forms/ValoracionForm';
import { formatDate } from '../../components/Utils/dateUtils';
import FichaPDFProveedorGenerator from './FichaPDFProveedorGenerator';
import Modal from '../../components/Modal/Modal';

interface ProveedorFormData {
  ruc: string;
  razon_social: string;
  direccion?: string;
  nombre_comercial?: string;
  rubro?: string;
  estado?: string;
  tipo?: string;
  actividad?: string;
  correo?: string;
  id?: string;
}

interface FormComponentProps {
  initialValues?: Partial<ProveedorFormData>;
  onSuccess?: () => void;
}

interface ContactoData {
  nombres: string;
  apellidos: string;
  cargo: string;
  telefono: string;
}

interface ContactoProveedor {
  id: string;
  nombres: string;
  apellidos: string;
  cargo: string;
  telefono: string;
}

interface ValoracionProveedorMini {
  id: string;
  puntuacion: number;
  fecha_inicio: string;
  fecha_fin: string;
  notas?: string;
  usuario_id: {
    id: string;
    nombres: string;
    apellidos: string;
  };
}

const ProveedorFormComponent: React.FC<FormComponentProps> = ({
  initialValues,
  onSuccess
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState('datos');
  const [showContactForm, setShowContactForm] = useState(false);
  const [mostrarPDF, setMostrarPDF] = useState(false);
  const [showMediosPagoForm, setShowMediosPagoForm] = useState(false);
  const [showValoracionForm, setShowValoracionForm] = useState(false);
  const [editContactoId, setEditContactoId] = useState<string | null>(null);
  const [editContactoData, setEditContactoData] = useState<ContactoData>({
    nombres: '',
    apellidos: '',
    cargo: '',
    telefono: ''
  });
  const [editMediosPagoId, setEditMediosPagoId] = useState<string | null>(null);
  const [editMediosPagoData, setEditMediosPagoData] = useState<Partial<MediosPagoProveedor>>({
    id: '',
    entidad: '',
    nro_cuenta: '',
    detalles: '',
    titular: '',
    validado: false
  });

  const [editValoracionId, setEditValoracionId] = useState<string | null>(null);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const contactos = useSelector((state: RootState) => state.contactoProveedor.contactos);
  const mediosPago = useSelector((state: RootState) => state.mediosPagoProveedor.mediosPago);
  const valoraciones = useSelector((state: RootState) => state.valoracionProveedor.valoraciones);
  const archivos = useSelector((state: RootState) => state.archivoSustento.archivos);

  useEffect(() => {
    const fetchData = async () => {
      if (initialValues?.id) {
        dispatch(fetchContactosByProveedor(initialValues.id));
        dispatch(fetchMediosPagoByProveedor(initialValues.id));
        dispatch(fetchValoracionesByProveedor(initialValues.id));
      }
    };
    fetchData();
  }, [dispatch, initialValues]);

  useEffect(() => {
    const fetchFiles = async () => {
      if (mediosPago && mediosPago.length > 0) {
        for (const medioPago of mediosPago) {
          try {
            await dispatch(fetchFilesByReferencia({
              referencia_id: medioPago.id,
              tipo: 'medio_pago'
            })).unwrap();
          } catch (error) {
            console.error('Error al obtener archivos:', error);
          }
        }
      }
    };

    fetchFiles();
  }, [dispatch, mediosPago]);

  const handleSubmit = async (formData: ProveedorFormData) => {
    try {
      if (initialValues?.id) {
        await dispatch(updateProveedor({
          id: initialValues.id,
          ...formData
        })).unwrap();
      } else {
        await dispatch(addProveedor(formData)).unwrap();
      }

      onSuccess?.();
    } catch (error) {
      console.error('Error al guardar el proveedor:', error);
    }
  };

  const handleContactoSubmit = (contactoData: ContactoData) => {
    if (initialValues?.id) {
      if (editContactoId) {
        // Editar contacto existente
        dispatch(updateContactoProveedor({
          id: editContactoId,
          proveedor_id: initialValues.id,
          ...contactoData
        })).then(() => {
          setEditContactoId(null);
          setEditContactoData({
            nombres: '',
            apellidos: '',
            cargo: '',
            telefono: ''
          });
          setShowContactForm(false);
        });
      } else {
        // Crear nuevo contacto
        dispatch(addContactoProveedor({
          proveedor_id: initialValues.id,
          ...contactoData
        })).then(() => {
          setShowContactForm(false);
        });
      }
    }
  };

  const handleMediosPagoSubmit = (mediosPagoData: Partial<MediosPagoProveedor>) => {
    if (initialValues?.id) {
      if (editMediosPagoId) {
        // Editar medio de pago existente
        dispatch(updateMediosPagoProveedor({
          id: editMediosPagoId,
          proveedor_id: initialValues.id,
          ...mediosPagoData
        })).then(() => {
          setEditMediosPagoId(null);
          setEditMediosPagoData({
            id: '',
            entidad: '',
            nro_cuenta: '',
            detalles: '',
            titular: '',
            validado: false
          });
          setShowMediosPagoForm(false);
        });
      } else {
        // Crear nuevo medio de pago
        dispatch(addMediosPagoProveedor({
          proveedor_id: initialValues.id,
          ...mediosPagoData
        })).then(() => {
          setShowMediosPagoForm(false);
        });
      }
    }
  };

  const handleEditContacto = (contacto: ContactoProveedor) => {
    setEditContactoId(contacto.id);
    setEditContactoData({
      nombres: contacto.nombres,
      apellidos: contacto.apellidos,
      cargo: contacto.cargo,
      telefono: contacto.telefono
    });
    setShowContactForm(true);
  };

  const handleEditMedioPago = (medio: MediosPagoProveedor) => {
    setEditMediosPagoId(medio.id);
    setEditMediosPagoData({
      id: medio.id,
      entidad: medio.entidad,
      nro_cuenta: medio.nro_cuenta,
      detalles: medio.detalles,
      titular: medio.titular,
      validado: medio.validado
    });
    setShowMediosPagoForm(true);
  };

  const handleEditValoracion = (valoracion: ValoracionProveedorMini) => {
    setEditValoracionId(valoracion.id);
    setShowValoracionForm(true);
  };

  // Agregar nueva función para manejar la visualización del PDF
  const handleViewValoracion = (valoracion: ValoracionProveedorMini) => {
    setEditValoracionId(valoracion.id);
    setMostrarPDF(true);
  };

  const handleDeleteContacto = (contactoId: string) => {
    if (window.confirm('¿Está seguro de eliminar este contacto?')) {
      dispatch(deleteContactoProveedor(contactoId));
    }
  };

  const handleDeleteMedioPago = (medioId: string) => {
    if (window.confirm('¿Está seguro de eliminar este medio de pago?')) {
      dispatch(deleteMediosPagoProveedor(medioId));
    }
  };

  const contactosTableData = {
    headers: ['Nombres', 'Apellidos', 'Cargo', 'Telefono', 'Acciones'],
    filter: [true, true, true, true, false],
    rows: contactos.map((contacto: ContactoProveedor) => ({
      Nombres: contacto.nombres,
      Apellidos: contacto.apellidos,
      Cargo: contacto.cargo,
      Telefono: contacto.telefono,
      Acciones: (
        <div className="flex gap-2">
          <FiEdit
            className="cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={() => handleEditContacto(contacto)}
          />
          <FiTrash2
            className="cursor-pointer text-red-500 hover:text-red-700"
            onClick={() => handleDeleteContacto(contacto.id)}
          />
        </div>
      )
    }))
  };

  const mediosPagoTableData = {
    headers: ['Entidad', 'NroCuenta', 'Detalles', 'Titular', 'Validado', 'Archivos', 'Acciones'],
    filter: [true, true, true, true, true, false, false],
    rows: mediosPago.map((medio: MediosPagoProveedor) => {
      const archivosMedio = archivos.filter(archivo => archivo.referencia_id === medio.id);
      return {
        'Entidad': medio.entidad || '-',
        'NroCuenta': medio.nro_cuenta || '-',
        'Detalles': medio.detalles || '-',
        'Titular': medio.titular || '-',
        'Validado': medio.validado ? 'Sí' : 'No',
        'Archivos': archivosMedio.length > 0 ? (
          <div className="flex gap-2">
            {archivosMedio.map((archivo) => {
              const isImage = archivo.url.match(/\.(jpg|jpeg|png|gif)$/i);
              return (
                <div key={archivo.id} className="relative group">
                  <a
                    href={archivo.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:text-blue-700"
                    onClick={(e) => {
                      if (isImage) {
                        e.preventDefault();
                        setSelectedImage(archivo.url);
                        setShowImagePreview(true);
                      }
                    }}
                  >
                    {isImage ? (
                      <FiImage className="w-5 h-5" />
                    ) : (
                      <FiFileText className="w-5 h-5" />
                    )}
                  </a>
                  <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                    {isImage ? 'Ver imagen' : 'Ver documento'}
                  </div>
                </div>
              );
            })}
          </div>
        ) : '-',
        Acciones: (
          <div className="flex gap-2">
            <FiEdit
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={() => handleEditMedioPago(medio)}
            />
            <FiTrash2
              className="cursor-pointer text-red-500 hover:text-red-700"
              onClick={() => handleDeleteMedioPago(medio.id)}
            />
          </div>
        )
      };
    })
  };

  const valoracionesTableData = {
    filterSelect: [true, false, true, true, true, false],
    filter: [true, false, true, true, true, false],
    headers: ['Fecha Evaluacion', 'Proxima Evaluacion', 'Puntuación', 'Notas', 'Usuario', 'Acciones'],
    rows: valoraciones.map((val: ValoracionProveedorMini) => {
      // Calculate days remaining until next evaluation
      const today = new Date();
      const nextEval = new Date(val.fecha_fin);
      const daysRemaining = Math.ceil((nextEval.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
      
      // Determine color based on days remaining
      let statusColor = 'text-green-600'; // More than 20 days: green
      if (daysRemaining <= 5 && daysRemaining > 0) {
        statusColor = 'text-red-600'; // 5 days or less: red
      } else if (daysRemaining <= 10 && daysRemaining > 5) {
        statusColor = 'text-amber-600'; // Between 5-10 days: orange-red
      } else if (daysRemaining <= 20 && daysRemaining > 10) {
        statusColor = 'text-orange-500'; // Between 10-20 days: orange
      } else if (daysRemaining <= 0) {
        statusColor = 'text-purple-700'; // Overdue: purple-red
      }

      return {
        Puntuación: val.puntuacion,
        'Fecha Evaluacion': formatDate(val.fecha_inicio, 'dd/mm/yyyy'),
        'Proxima Evaluacion': (
          <span className={statusColor + " font-medium"}>
            {formatDate(val.fecha_fin, 'dd/mm/yyyy')}
          </span>
        ),
        Notas: val.notas || '-',
        Usuario: `${val.usuario_id.nombres} ${val.usuario_id.apellidos}`,
        Acciones: (
          <div className="flex gap-2">
            <FiEdit
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={() => handleEditValoracion(val)}
            />
            {/* <FiTrash2 
              className="cursor-pointer text-red-500 hover:text-red-700"
              onClick={() => dispatch(deleteValoracionProveedor(val.id))}
            /> */}
            <FiEye
              className="cursor-pointer text-green-500 hover:text-green-700"
              onClick={() => handleViewValoracion(val)}
            />
          </div>
        )
      };
    })
  };

  const tabs = [
    { id: 'datos', label: 'Datos Generales', icon: <FiFileText /> },
    { id: 'contactos', label: 'Contactos', icon: <FiUser /> },
    { id: 'pagos', label: 'Medios de Pago', icon: <FiDollarSign /> },
    { id: 'valoraciones', label: 'Valoraciones', icon: <FiStar /> },
  ];

  return (
    <div className="min-w-[calc(100vw-10rem)] md:min-w-[70vw] min-h-[calc(100vh-14rem)] bg-gradient-to-b from-gray-50/50 to-white/50 rounded-xl">
      {/* Header con pestañas */}
      <div className="bg-white shadow-lg border-b border-gray-200 rounded-t-xl overflow-x-auto">
        <div className="max-w-7xl mx-auto">
          <nav className="flex space-x-1 min-w-max" aria-label="Tabs">
            {tabs.map(tab => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  flex-1 group inline-flex items-center justify-center px-3 md:px-6 py-2 md:py-4 text-xs md:text-sm font-medium
                  transition-all duration-200 ease-in-out border-b-2 whitespace-nowrap
                  hover:bg-gray-50
                  ${activeTab === tab.id
                    ? 'border-blue-600 text-blue-600 bg-blue-200/50 rounded-xl'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }
                `}
              >
                <span className="mr-1 md:mr-2">{tab.icon}</span>
                {tab.label}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Contenido principal */}
      <div className="w-full max-w-7xl mx-auto py-4 md:py-8 px-2 md:px-8">
        {/* Datos Generales */}
        {activeTab === 'datos' && (

          <div className="p-2 md:p-6">
            <ProveedorForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
            />
          </div>
        )}

        {/* Contactos */}
        {activeTab === 'contactos' && (
          <div className="bg-white/20 shadow-xl rounded-xl overflow-hidden">
            <div className="px-3 md:px-6 py-3 md:py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex flex-row justify-between items-start md:items-center gap-2">
              <h2 className="text-lg md:text-xl font-semibold text-white">Gestión de Contactos</h2>
              <Button
                text="Contacto"
                color="azul"
                className="bg-white/10 hover:bg-white/20 text-white px-3 py-1 md:px-4 md:py-2 rounded-lg transition-all duration-200 w-full md:w-40"
                icon={<FiPlus className="mr-2" />}
                onClick={() => setShowContactForm(true)}
              />
            </div>
            <div className="p-2 md:p-6">
              {showContactForm ? (
                <div className="bg-gray-50 p-6 rounded-xl border border-gray-200">
                  <ContactoForm
                    onSubmit={handleContactoSubmit}
                    onCancel={() => setShowContactForm(false)}
                    initialData={editContactoData}
                  />
                </div>
              ) : (
                <div className="overflow-hidden rounded-lg border border-gray-200">
                  <TableComponent tableData={contactosTableData} />
                </div>
              )}
            </div>
          </div>
        )}

        {/* Medios de Pago */}
        {activeTab === 'pagos' && (
          <div className="bg-white/20 shadow-xl rounded-xl overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex justify-between items-center">
              <h2 className="text-xl font-semibold text-white">Medios de Pago</h2>
              <Button
                text="MedioDePago"
                color="azul"
                className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-all duration-200 w-40"
                icon={<FiPlus className="mr-2" />}
                onClick={() => {
                  setEditMediosPagoId(null);
                  setEditMediosPagoData({
                    id: '',
                    entidad: '',
                    nro_cuenta: '',
                    detalles: '',
                    titular: '',
                    validado: false
                  });
                  setShowMediosPagoForm(true);
                }}
              />
            </div>
            <div className="p-0 md:p-6">
              {showMediosPagoForm ? (
                <div className="bg-gray-50 p-6 rounded-xl border border-gray-200">
                  <MediosPagoForm
                    onSubmit={handleMediosPagoSubmit}
                    onCancel={() => setShowMediosPagoForm(false)}
                    initialData={editMediosPagoData}
                  />
                </div>
              ) : (
                <div className="overflow-hidden rounded-lg border border-gray-200">
                  <TableComponent tableData={mediosPagoTableData} />
                </div>
              )}
            </div>
          </div>
        )}

        {/* Valoraciones */}
        {activeTab === 'valoraciones' && (
          <div className="bg-white/20 shadow-xl rounded-xl overflow-hidden">
            <div className="px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex justify-between items-center">
              <h2 className="text-xl font-semibold text-white">Valoraciones</h2>
              <Button
                text="Valoración"
                color="azul"
                className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-all duration-200 w-40"
                icon={<FiPlus className="mr-2" />}
                onClick={() => setShowValoracionForm(true)}
              />
            </div>
            <div className="p-0 md:p-6">
              {showValoracionForm ? (
                <div className="bg-gray-50 p-6 rounded-xl border border-gray-200">
                  <ValoracionForm
                    onCancel={() => setShowValoracionForm(false)}
                    valoracionData={valoraciones.length > 0 ? valoraciones.find(val => val.id === editValoracionId) : undefined}
                    proveedorId={initialValues?.id || ''}
                    productoServicio={initialValues?.rubro || ''}
                  />
                </div>
              ) : (
                <div className="overflow-hidden rounded-lg border border-gray-200">
                  <TableComponent tableData={valoracionesTableData} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Modal para PDF */}
      {mostrarPDF && (
        <Modal
          title="Ficha de Proveedor"
          isOpen={mostrarPDF}
          onClose={() => {
            setMostrarPDF(false);
            setEditValoracionId(null); // Resetear el ID al cerrar el modal
          }}
        >
          <FichaPDFProveedorGenerator
            valoracionData={valoraciones.length > 0 ? valoraciones.find(val => val.id === editValoracionId) : undefined}
          />
        </Modal>
      )}

      {/* Modal para previsualización de imágenes */}
      {showImagePreview && selectedImage && (
        <Modal
          title="Previsualización de Imagen"
          isOpen={showImagePreview}
          onClose={() => {
            setShowImagePreview(false);
            setSelectedImage(null);
          }}
        >
          <div className="flex justify-center items-center p-4">
            <img
              src={selectedImage}
              alt="Previsualización"
              className="max-w-full max-h-[80vh] rounded-lg shadow-lg"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ProveedorFormComponent;