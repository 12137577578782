import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './store/store';
import { clearUser } from './slices/userSlice';
import Login from './pages/IniciarSesionPage/Login';
import Dashboard from './layouts/dashboard/Dashboard';
import HomePage from './pages/HomePage/HomePage';
import CargosComponent from './pages/CargosPage/CargosComponent';

import RecursosPage from './pages/RecursosPage/RecursosPage';
import UnidadPage from './pages/UnidadPage/UnidadPage';
import TipoRecursoComponent from './pages/TipoRecursoPage/TipoRecursoPage';
import TipoCostoRecursoComponent from './pages/TipoCostoRecursoPage/TipoCostoRecursoComponent';
import ClasificacionRecursoComponent from './pages/ClasificacionRecursoPage/ClasificacionRecursoComponent';

import UsuariosPage from './pages/UsuariosPage/UsuariosPage';
import RolesPage from './pages/RolePage/RolesPage';
import KanbanBoard from './pages/KanBanBoard/KanbanBoard';
import ProveedorComponent from './pages/ProveedorPage/ProveedorComponent';
import RequerimientosComponent from './pages/RequerimientosPage/RequerimientosComponent';
import AlmacenBoardPage from './pages/AlmacenesPage/AlmacenBoard/AlmacenBoardPage';
import TipoAlmacenPage from './pages/TipoAlmacenPage/TipoAlmacenPage';
import ObrasComponent from './pages/ObrasPage/ObrasPage';
import ComprasBoard from './pages/ComprasPage/ComprasBoard';
import OrdenCompraPage from './pages/OrdenCompraPage/OrdenCompraPage';
import OrdenServicioPage from './pages/OrdenServicioPage/OrdenServicioPage';
import OrdenPagoPage from './pages/OrdenPagoPage/OrdenPagoPage';
import ListOrdenPage from './pages/OrdenPagoPage/ListOrdenPage';
import HomologacionFormPage from './pages/ProveedorPage/Forms/HomologacionForm';
import EmpleadosPage from './pages/EmpleadosPage/EmpleadosPage';
import SorteoPage from './pages/HomePage/Sorteo';
import BodegasComponent from './pages/BodegasPage/BodegasComponent';
import GestionLayoutAPU from './pages/PresupuestosNuevos/2APU/0GestionLayoutAPU';
import EmpresaPage from './pages/EmpresaPage/EmpresaPage';
import GestionLayoutDatosGenerales from './pages/PresupuestosNuevos/0DatosGenerales/GestionLayoutDatosGenerales';
import PrefijoPage from './pages/PrefijoPage/PrefijoPage';
import Comprobantes from './pages/ComprobantesPage/Comprobantes';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import SolicitudCompras from './pages/ComprasPage/SolicitudCompras';
import TransfersPage from './pages/Tranferencias/TransfersPage';
import FormularioDetail from './pages/FormularioPage/FormularioDetail';
import TodosLosFormularios from './pages/FormularioPage/TodosLosFormularios';
import ResponderFormulario from './pages/FormularioPage/ResponderFormulario';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    // Escuchar eventos de storage para sincronizar el logout entre pestañas
    const syncLogout = (e: StorageEvent) => {
      if (e.key === 'logout' || (e.key === 'user' && e.newValue === null)) {
        dispatch(clearUser());
      }
    };

    window.addEventListener('storage', syncLogout);

    return () => {
      window.removeEventListener('storage', syncLogout);
    };
  }, [dispatch]);

  // Verificación básica de autenticación
  const AuthRoute = ({ children }: { children: React.ReactNode }) => {
    if (!user.token) {
      return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
  };

  return (
    <Routes>
      <Route path="/login" element={
        user.token ? <Navigate to="/dashboard" replace /> : <Login />
      } />
      <Route path="/proveedor-registro" element={<HomologacionFormPage />
      } />
      <Route
        path="/dashboard"
        element={
          <AuthRoute>
            <Dashboard />
          </AuthRoute>
        }
      >
        <Route index element={<HomePage />
        } />

        {/* Rutas administrativas */}
        <Route path="cargo" element={
          <ProtectedRoute requiredSlug="cargo">
            <CargosComponent />
          </ProtectedRoute>
        } />
        <Route path="usuario" element={
          <ProtectedRoute requiredSlug="usuario">
            <UsuariosPage />
          </ProtectedRoute>
        } />
        <Route path="roles" element={
          <ProtectedRoute requiredSlug="roles">
            <RolesPage />
          </ProtectedRoute>
        } />
        <Route path="empresa" element={
          <ProtectedRoute requiredSlug="empresa">
            <EmpresaPage />
          </ProtectedRoute>
        } />
        <Route path="obras" element={
          <ProtectedRoute requiredSlug="obras">
            <ObrasComponent />
          </ProtectedRoute>
        } />
        <Route path="prefijos" element={
          <ProtectedRoute requiredSlug="prefijos">
            <PrefijoPage />
          </ProtectedRoute>
        } />

        {/* Rutas de recursos */}
        <Route path="recurso" element={
          <ProtectedRoute requiredSlug="recurso">
            <RecursosPage />
          </ProtectedRoute>
        } />
        <Route path="unidad" element={
          <ProtectedRoute requiredSlug="unidad">
            <UnidadPage />
          </ProtectedRoute>
        } />
        <Route path="clasificacionRecurso" element={
          <ProtectedRoute requiredSlug="clasificacionRecurso">
            <ClasificacionRecursoComponent />
          </ProtectedRoute>
        } />
        <Route path="tipoCostoRecurso" element={
          <ProtectedRoute requiredSlug="tipoCostoRecurso">
            <TipoCostoRecursoComponent />
          </ProtectedRoute>
        } />
        <Route path="tipoRecurso" element={
          <ProtectedRoute requiredSlug="tipoRecurso">
            <TipoRecursoComponent />
          </ProtectedRoute>
        } />

        {/* Rutas de requerimientos */}
        <Route path="requerimiento" element={
          <ProtectedRoute requiredSlug="requerimiento">
            <RequerimientosComponent />
          </ProtectedRoute>
        } />

        {/* Rutas de compras */}
        <Route path="solicitudCompra" element={
          <ProtectedRoute requiredSlug="solicitudCompra">
            <SolicitudCompras />
          </ProtectedRoute>
        } />
        <Route path="compra" element={
          <ProtectedRoute requiredSlug="compra">
            <ComprasBoard />
          </ProtectedRoute>
        } />
        <Route path="proveedor" element={
          <ProtectedRoute requiredSlug="proveedor">
            <ProveedorComponent />
          </ProtectedRoute>
        } />
        <Route path="ordenCompra" element={
          <ProtectedRoute requiredSlug="ordenCompra">
            <OrdenCompraPage />
          </ProtectedRoute>
        } />
        <Route path="ordenPago" element={
          <ProtectedRoute requiredSlug="ordenPago">
            <OrdenPagoPage />
          </ProtectedRoute>
        } />
        <Route path="listaOrdenPago" element={
          <ProtectedRoute requiredSlug="listaOrdenPago">
            <ListOrdenPage />
          </ProtectedRoute>
        } />

        {/* Rutas de almacén */}
        <Route path="bodegas" element={
          <ProtectedRoute requiredSlug="bodegas">
            <BodegasComponent />
          </ProtectedRoute>
        } />
        <Route path="almacen" element={
          <ProtectedRoute requiredSlug="almacen">
            <AlmacenBoardPage />
          </ProtectedRoute>
        } />
        <Route path="transferencia" element={
          <ProtectedRoute requiredSlug="transferencia">
            <TransfersPage />
          </ProtectedRoute>
        } />
        <Route path="tipoAlmacen" element={
          <ProtectedRoute requiredSlug="tipoAlmacen">
            <TipoAlmacenPage />
          </ProtectedRoute>
        } />
        <Route path="empleados" element={
          <ProtectedRoute requiredSlug="empleados">
            <EmpleadosPage />
          </ProtectedRoute>
        } />


        {/* Ruta de formularios */}
        <Route path="formulariosToDo" element={
          <ProtectedRoute requiredSlug="formularios">
            <TodosLosFormularios />
          </ProtectedRoute>
        } />
        {/* Ruta de formularios */}
        <Route path="formularioDetalle" element={
          <ProtectedRoute requiredSlug="formularios">
            <FormularioDetail />
          </ProtectedRoute>
        } />
        {/* Ruta para responder formularios */}
        <Route path="responder-formulario/:id" element={
          <ProtectedRoute requiredSlug="formularios">
            <ResponderFormulario />
          </ProtectedRoute>
        } />

        {/* Otras rutas */}
        <Route path="kanban" element={
          <ProtectedRoute requiredSlug="kanban">
            <KanbanBoard />
          </ProtectedRoute>
        } />
        <Route path="sorteo" element={
            <SorteoPage />
        } />
        <Route path="presupuestos" element={
          <ProtectedRoute requiredSlug="presupuestos">
            <GestionLayoutDatosGenerales />
          </ProtectedRoute>
        } />
        <Route path="apu" element={
          <ProtectedRoute requiredSlug="apu">
            <GestionLayoutAPU />
          </ProtectedRoute>
        } />
        <Route path="ordenServicio" element={
          <ProtectedRoute requiredSlug="ordenServicio">
            <OrdenServicioPage />
          </ProtectedRoute>
        } />
        <Route path="comprobantes" element={
          <ProtectedRoute requiredSlug="comprobantes">
            <Comprobantes />
          </ProtectedRoute>
        } />
      </Route>
      <Route path="/" element={
        user.token ?
          <Navigate to="/dashboard" replace /> :
          <Navigate to="/login" replace />
      } />
    </Routes>
  );
};

const AppWrapper: React.FC = () => (
  <Router
    future={{//salia error, este codigo prepara la app para la version 7 de react-router
      v7_startTransition: true, v7_relativeSplatPath: true
    }}>
    <App />
  </Router>
);

export default AppWrapper;
