import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  getComposicionesApuByTituloService,
  addComposicionApuService,
  updateComposicionApuService,
  deleteComposicionApuService
} from '../services/composicionApuService';
import { updatePrecioRecursoProyecto, addPrecioRecursoProyecto } from '../slices/precioRecursoProyectoSlice';

// Interface para el listado con relaciones
interface PrecioRecursoProyecto {
  id_prp: string;
  id_rec_comp_apu: string;
  precio: number;
  fecha_creacion: string;
  id_proyecto: string;
}

export interface ITipo {
  id_tipo: string;
  descripcion: string;
  codigo: string;
}

interface Imagen {
  id: string;
  file: string;
}

interface Recurso {
  id: string;
  recurso_id: string;
  codigo: string;
  nombre: string;
  descripcion: string;
  fecha: string;
  cantidad: number;
  unidad_id: string;
  precio_actual: number;
  vigente: boolean;
  tipo_recurso_id: string;
  tipo_costo_recurso_id: string;
  clasificacion_recurso_id: string;
  imagenes: Imagen[];
}

interface Unidad {
  id: string;
  unidad_id: string;
  nombre: string;
  descripcion: string;
}

interface RecursoComposicionApu {
  id_rec_comp_apu: string;
  recurso_id: string;
  unidad_id: string;
  nombre: string;
  especificaciones: string;
  descripcion: string;
  fecha_creacion: string;
  recurso: Recurso;
  unidad: Unidad;
}

export interface ComposicionApuWithRelations {
  id_composicion_apu: string;
  id_titulo: string;
  id_rec_comp_apu: string;
  rec_comp_apu: RecursoComposicionApu;
  cuadrilla: number;
  cantidad: number;
  fecha_creacion: string;
  precio: PrecioRecursoProyecto;
}

interface ComposicionApuState {
  composicionesApu: ComposicionApuWithRelations[];
  selectedComposicionApu: ComposicionApuWithRelations | null;
  loading: boolean;
  error: string | null;
}

const initialState: ComposicionApuState = {
  composicionesApu: [],
  selectedComposicionApu: null,
  loading: false,
  error: null,
};

export const getComposicionesApuByTitulo = createAsyncThunk(
  'composicionApu/getComposicionesApuByTitulo',
  async ({id_titulo, id_proyecto}: {id_titulo: string, id_proyecto: string}, { rejectWithValue }) => {
    try {
      const result = await getComposicionesApuByTituloService(id_titulo, id_proyecto);
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addComposicionApu = createAsyncThunk(
  'composicionApu/addComposicionApu',
  async (data: {
    id_titulo: string;
    id_rec_comp_apu: string;
    cuadrilla: number;
    cantidad: number;
    id_proyecto: string; // Nuevo campo añadido
  }, { rejectWithValue }) => {
    try {
      const result = await addComposicionApuService(data);
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateComposicionApu = createAsyncThunk(
  'composicionApu/updateComposicionApu',
  async (data: {
    id_composicion_apu: string;
    id_rec_comp_apu?: string;
    cuadrilla?: number;
    cantidad?: number;
  }, { rejectWithValue }) => {
    try {
      const result = await updateComposicionApuService(data);
      // Después de actualizar la composición, disparar una acción para actualizar los títulos
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteComposicionApu = createAsyncThunk(
  'composicionApu/deleteComposicionApu',
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteComposicionApuService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const composicionApuSlice = createSlice({
  name: 'composicionApu',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComposicionesApuByTitulo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getComposicionesApuByTitulo.fulfilled, (state, action: PayloadAction<ComposicionApuWithRelations[]>) => {
        state.loading = false;
        state.composicionesApu = action.payload;
      })
      .addCase(getComposicionesApuByTitulo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addComposicionApu.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addComposicionApu.fulfilled, (state, action: PayloadAction<ComposicionApuWithRelations>) => {
        state.loading = false;
        const newComposicion = action.payload;
        
        // Asegurarse de que todos los campos necesarios estén presentes
        if (newComposicion.rec_comp_apu) {
          // Mantener la referencia a precio_recurso_proyecto si existe
          if (newComposicion.precio) {
            newComposicion.precio = {
              ...newComposicion.precio
            };
          }
          
          // Mantener la referencia a unidad_presupuesto si existe
          if (newComposicion.rec_comp_apu.unidad) {
            newComposicion.rec_comp_apu.unidad = {
              ...newComposicion.rec_comp_apu.unidad
            };
          }
          
          // Mantener la referencia a recurso_presupuesto si existe
          if (newComposicion.rec_comp_apu.recurso) {
            newComposicion.rec_comp_apu.recurso = {
              ...newComposicion.rec_comp_apu.recurso
            };
          }
        }

        state.composicionesApu.push(newComposicion);
      })
      .addCase(addComposicionApu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateComposicionApu.fulfilled, (state, action: PayloadAction<ComposicionApuWithRelations>) => {
        state.loading = false;
        const updatedItem = action.payload;
        const index = state.composicionesApu.findIndex(
          (comp) => comp.id_composicion_apu === updatedItem.id_composicion_apu
        );
        if (index !== -1) {
          const existing = state.composicionesApu[index];
          // Mezcla la data nueva con la existente
          state.composicionesApu[index] = {
            ...existing,
            ...updatedItem,
            rec_comp_apu: {
              ...existing.rec_comp_apu,
              ...updatedItem.rec_comp_apu,
              recurso: {
                ...existing.rec_comp_apu.recurso,
                ...updatedItem.rec_comp_apu?.recurso,
              },
              unidad: {
                ...existing.rec_comp_apu.unidad,
                ...updatedItem.rec_comp_apu?.unidad,
              },
            },
            precio: {
              ...existing.precio,
              ...updatedItem.precio,
            },
          };
        }
      })
      .addCase(deleteComposicionApu.fulfilled, (state, action: PayloadAction<{ id_composicion_apu: string }>) => {
        state.loading = false;
        state.composicionesApu = state.composicionesApu.filter(
          comp => comp.id_composicion_apu !== action.payload.id_composicion_apu
        );
      })
      .addCase(updatePrecioRecursoProyecto.fulfilled, (state, action: PayloadAction<PrecioRecursoProyecto>) => {
        const updatedPrice = action.payload;
        const compIndex = state.composicionesApu.findIndex(
          comp => comp.rec_comp_apu.id_rec_comp_apu === updatedPrice.id_rec_comp_apu
        );
        if (compIndex !== -1) {
          state.composicionesApu[compIndex].precio = {
            ...state.composicionesApu[compIndex].precio,
            ...updatedPrice
          };
        }
      })
      .addCase(addPrecioRecursoProyecto.fulfilled, (state, action) => {
        const newPrecio = action.payload;
        state.composicionesApu = state.composicionesApu.map(comp => {
          if (comp.rec_comp_apu.id_rec_comp_apu === newPrecio.id_rec_comp_apu) {
            return {
              ...comp,
              rec_comp_apu: {
                ...comp.rec_comp_apu,
                precio_recurso_proyecto: newPrecio
              }
            };
          }
          return comp;
        });
      });
  },
});

import { RootState } from '../store/store';

// Selectores
export const selectComposicionesApuWithRecursos = (state: RootState) => {
  return state.composicionApu.composicionesApu.map(composicion => ({
    ...composicion,
    rec_comp_apu: {
      ...composicion.rec_comp_apu,
      precio: composicion.precio
    }
  }));
};

export const { clearErrors } = composicionApuSlice.actions;
export const composicionApuReducer = composicionApuSlice.reducer;
