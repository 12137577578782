import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_COTIZACIONES_QUERY = gql`
  query ListCotizaciones {
    listCotizaciones {
      id
      codigo_cotizacion
      aprobacion
      estado
      fecha
      solicitudes_compra {
        id
        fecha
        estado
      #   requerimiento_id {
      #     id
      #     codigo
      #     sustento
      #   }
      }
      usuario_id {
        id
        nombres
        apellidos
      }
    }
  }
`;

const GET_COTIZACION_QUERY = gql`
  query GetCotizacion($getCotizacionId: ID!) {
    getCotizacion(id: $getCotizacionId) {
      id
      codigo_cotizacion
      usuario_id {
        id
        nombres
        apellidos
      }
      solicitudes_compra {
        id
        fecha
        estado
        # requerimiento_id {
        #   id
        #   codigo
        #   sustento
        #   usuario
        # }
      }
      aprobacion
      estado
      fecha
    }
  }
`;

const ADD_COTIZACION_MUTATION = gql`
mutation AddCotizacion(
  $usuario_id: ID!
  $solicitudes_compra_ids: [ID!]
  $estado: String
) {
  addCotizacion(
    usuario_id: $usuario_id
    solicitudes_compra_ids: $solicitudes_compra_ids
    estado: $estado
  ) {
    id
    codigo_cotizacion
    usuario_id {
      id
      nombres
      apellidos
    }
    solicitudes_compra {
      id
      fecha
      estado
    }
    aprobacion
    estado
    fecha
  }
}
`;

const UPDATE_COTIZACION_MUTATION = gql`
  mutation UpdateCotizacion(
    $id: ID!,
    $aprobacion: Boolean,
    $estado: String,
    $fecha: DateTime,
    $usuario_id: ID,
    $codigo_cotizacion: String,
    $solicitudes_compra_ids: [ID!]
  ) {
    updateCotizacion(
      id: $id,
      aprobacion: $aprobacion,
      estado: $estado,
      fecha: $fecha,
      usuario_id: $usuario_id,
      codigo_cotizacion: $codigo_cotizacion,
      solicitudes_compra_ids: $solicitudes_compra_ids
    ) {
      id
      codigo_cotizacion
      usuario_id {
        id
        nombres
        apellidos
        rol_id
      }
      solicitudes_compra {
        id
        fecha
        estado
        # requerimiento_id {
        #   id
        #   codigo
        #   sustento
        # }
      }
      aprobacion
      estado
      fecha
    }
  }
`;

const DELETE_COTIZACION_MUTATION = gql`
  mutation DeleteCotizacion($deleteCotizacionId: ID!) {
    deleteCotizacion(id: $deleteCotizacionId) {
      id
    }
  }
`;

const CREATE_MASIVE_RECURSOS_MUTATION = gql`
  mutation CreateMasiveRecursos(
    $usuarioId: ID!
    $solicitudCompras: [ID!]!
    $solicitudesCompraRecursos: [SolicitudCompraRecursoInput!]!
    $solicitudesCompraEstado: [SolicitudCompraEstadoInput!]!
  ) {
    createMasiveRecursos(
      usuarioId: $usuarioId
      solicitudCompras: $solicitudCompras
      solicitudesCompraRecursos: $solicitudesCompraRecursos
      solicitudesCompraEstado: $solicitudesCompraEstado
    ) {
      recursos_creados {
        id
        cantidad
        atencion
        costo
        total
      }
      solicitudes_actualizadas {
        id
        fecha
        estado
      }
    }
  }
`;

export const listCotizacionesService = async () => {
  try {
    const response = await client.query({
      query: LIST_COTIZACIONES_QUERY,
    });
    return response.data.listCotizaciones;
  } catch (error) {
    throw new Error(`Error fetching cotizaciones: ${error}`);
  }
};

export const getCotizacionService = async (id: string) => {
  try {
    const response = await client.query({
      query: GET_COTIZACION_QUERY,
      variables: { getCotizacionId: id },
    });
    return response.data.getCotizacion;
  } catch (error) {
    throw new Error(`Error fetching cotización: ${error}`);
  }
};

export const addCotizacionService = async (data: {
  usuario_id: string;
  estado: string;
  solicitudes_compra_ids?: string[];
}) => {
  try {
    const response = await client.mutate({
      mutation: ADD_COTIZACION_MUTATION,
      variables: data,
    });
    return response.data.addCotizacion;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error adding cotización: ${error.message}`);
    }
    throw error;
  }
};

export const updateCotizacionService = async (data: {
  id: string;
  aprobacion?: boolean;
  estado?: string;
  fecha?: Date;
  usuario_id?: string;
  codigo_cotizacion?: string;
  solicitudes_compra_ids?: string[];
}) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_COTIZACION_MUTATION,
      variables: data,
    });
    return response.data.updateCotizacion;
  } catch (error) {
    throw new Error(`Error updating cotización: ${error}`);
  }
};

export const deleteCotizacionService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_COTIZACION_MUTATION,
      variables: { deleteCotizacionId: id },
    });
    return response.data.deleteCotizacion;
  } catch (error) {
    throw new Error(`Error deleting cotización: ${error}`);
  }
};

export const createCotizacionGeneralService = async (data: {
  usuarioId: string;
  solicitudCompras: string[];
  solicitudesCompraRecursos: [string, number, number, number, number, string][];
  solicitudesCompraEstado: [string, string | null][];
}) => {
  try {
    // Transformar los arrays a objetos con los nombres de campo correctos que espera GraphQL
    const solicitudesCompraRecursosInput = data.solicitudesCompraRecursos.map(
      ([recursoId, cantidad, costo, precio, precio_partida, requerimiento_recurso_id]) => ({
        solicitud_compra_recurso_id: recursoId,
        cantidad,
        costo,
        precio,
        precio_partida,
        requerimiento_recurso_id
      })
    );
    
    const solicitudesCompraEstadoInput = data.solicitudesCompraEstado.map(([solicitudId, estado]) => ({
      solicitud_id: solicitudId,
      estado
    }));

    const response = await client.mutate({
      mutation: CREATE_MASIVE_RECURSOS_MUTATION,
      variables: {
        usuarioId: data.usuarioId,
        solicitudCompras: data.solicitudCompras,
        solicitudesCompraRecursos: solicitudesCompraRecursosInput,
        solicitudesCompraEstado: solicitudesCompraEstadoInput
      },
    });
    
    return response.data.createMasiveRecursos;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Error creating recursos masivos: ${error.message}`);
    }
    throw error;
  }
};
