import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchPrefijos, addPrefijo, updatePrefijo, deletePrefijo, Prefijo, PrefijoFormData } from '../../slices/prefijoSlice';
import PrefijoForm from './PrefijoForm';
import { FiEdit2 } from 'react-icons/fi';
import Button from '../../components/Buttons/Button';
import Modal from '../../components/Modal/Modal';
import TableComponent from '../../components/Table/TableComponent';
import LoaderPage from '../../components/Loader/LoaderPage';
import ModalAlert from '../../components/Modal/ModalAlert';

const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };
  
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

const PrefijoPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { prefijos, loading, error } = useSelector((state: RootState) => state.prefijo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPrefijo, setEditingPrefijo] = useState<Prefijo | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [prefijoToDelete, setPrefijoToDelete] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchPrefijos());
  }, [dispatch]);

  const handleSubmit = async (formData: PrefijoFormData) => {
    if (editingPrefijo) {
      await dispatch(updatePrefijo({ id: editingPrefijo.id, usa_este_numero: editingPrefijo.usa_este_numero }));
    } else {
      await dispatch(addPrefijo(formData));
    }
    setIsModalOpen(false);
    setEditingPrefijo(null);
  };

  const handleEdit = (prefijo: Prefijo) => {
    setEditingPrefijo(prefijo);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (prefijoToDelete) {
      dispatch(deletePrefijo(prefijoToDelete));
    }
    setDeleteModalOpen(false);
    setPrefijoToDelete(null);
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setPrefijoToDelete(null);
  };

  if (loading) return <LoaderPage />;
  if (error) return <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>Error: {error}</motion.div>;

  const tableData = {
    filter: [true, true, true, true],
    headers: ["Tabla", "Prefijo", "Caracteres", "Número Actual", "Opciones"],
    rows: prefijos.map(prefijo => ({
      "Tabla": prefijo.nombre_tabla,
      "Prefijo": prefijo.prefijo,
      "Caracteres": prefijo.cantidad_caracteres,
      "Número Actual": prefijo.usa_este_numero,
      "Opciones": (
        <div className="flex gap-2">
          <Button 
            icon={<FiEdit2 />}
            color='transp' 
            className='text-black' 
            onClick={() => handleEdit(prefijo)}
          />
        </div>
      )
    }))
  };

  return (
    <motion.div 
      className="flex flex-col h-full"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <motion.div 
        className="x text-white pb-4 px-4 flex items-center justify-between"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <h1 className="text-2xl font-bold">Prefijos</h1>
        <div className="flex items-center space-x-2">
          <Button text='Nuevo Prefijo' color='verde' onClick={() => setIsModalOpen(true)} className="rounded w-full" />
        </div>
      </motion.div>

      <motion.div 
        className="flex flex-1 overflow-hidden rounded-xl"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4 }}
      >
        <main className="w-full flex flex-col flex-grow p-4 bg-white/80 overflow-hidden">
          <motion.div 
            className="flex-grow border rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
          >
            <div className="h-full overflow-auto">
              <TableComponent tableData={tableData} />
            </div>
          </motion.div>
        </main>
      </motion.div>

      <AnimatePresence>
        {isModalOpen && (
          <Modal title={editingPrefijo ? 'Actualizar Prefijo' : 'Crear Prefijo'} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
            >
              <PrefijoForm
                initialValues={editingPrefijo || undefined}
                onSubmit={handleSubmit}
              />
            </motion.div>
          </Modal>
        )}

        {deleteModalOpen && (
          <ModalAlert
            isOpen={deleteModalOpen}
            title="Confirmar Eliminación"
            message="¿Está seguro que desea eliminar este prefijo?"
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
            variant="red"
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default PrefijoPage;
