import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { formatCurrency } from '../../../../components/Utils/priceFormatUtils';
import { PendingChangesMap } from '../types/apu.actions';
import { IComposicionApu } from '../types/apu.types';

interface APUFooterProps {
  isEditMode: boolean;
  onSaveChanges: () => void;
  onCancelChanges: () => void;
  onEnableEditMode: () => void;
  pendingChangesCount: number;
  pseudoTotal?: number;
  pendingChanges?: PendingChangesMap; // Tipado correcto
  composiciones?: IComposicionApu[]; // Tipado correcto
  detallePartidaChanges?: { // Añadir esta prop
    jornada?: number;
    rendimiento?: number;
  };
}

const APUFooter: React.FC<APUFooterProps> = ({
  isEditMode,
  onSaveChanges,
  onCancelChanges,
  onEnableEditMode,
  pendingChangesCount,
  pseudoTotal,
  pendingChanges = new Map(),
  composiciones = [],
  detallePartidaChanges = {}
}) => {
  const totalFromRedux = useSelector((state: RootState) => state.apuTotal.total);

  const renderChangesTooltip = () => {
    const changes: string[] = [];

    // Añadir cambios de detalle partida
    if (detallePartidaChanges.jornada) {
      changes.push(`Jornada: ${detallePartidaChanges.jornada}h`);
    }
    if (detallePartidaChanges.rendimiento) {
      changes.push(`Rendimiento: ${detallePartidaChanges.rendimiento}`);
    }

    // Añadir cambios de composiciones
    pendingChanges.forEach((change, id) => {
      const composicion = composiciones.find(c => c.id_composicion_apu === id);
      if (composicion) {
        const nombre = composicion.rec_comp_apu?.nombre || 'Recurso';
        if (change.cantidad !== undefined) {
          changes.push(`${nombre} - Cantidad: ${change.cantidad}`);
        }
        if (change.cuadrilla !== undefined) {
          changes.push(`${nombre} - Cuadrilla: ${change.cuadrilla}`);
        }
        if (change.precio !== undefined) {
          changes.push(`${nombre} - Precio: S/. ${formatCurrency(change.precio)}`);
        }
      }
    });

    return changes.length > 0 ? changes.join('\n') : 'No hay cambios pendientes';
  };

  return (
    <div className={`${isEditMode ? "bg-yellow-600" : "bg-gray-50"}  p-2 rounded-b-lg border-t border-gray-200`}>
      <div className="flex justify-between items-center px-10">
        <div>
          {isEditMode && (
            <div className="relative group">
              <span className="text-xs text-gray-50 cursor-help">
                {pendingChangesCount} cambios pendientes
              </span>
              {/* Tooltip */}
              <div className="absolute bottom-full left-0 mb-2 hidden group-hover:block">
                <div className="bg-gray-900 text-white text-xs rounded p-2 min-w-[200px] max-w-[300px] shadow-lg">
                  <div className="whitespace-pre-line">
                    {renderChangesTooltip()}
                  </div>
                  {/* Flecha del tooltip */}
                  <div className="absolute bottom-[-6px] left-4 w-3 h-3 bg-gray-900 transform rotate-45"></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center">
          {isEditMode ? (
            <>
              <button
                onClick={onSaveChanges}
                className="px-3 py-1 text-xs font-medium text-white bg-green-600 rounded hover:bg-green-700"
              >
                Guardar Cambios
              </button>
              <button
                onClick={onCancelChanges}
                className="px-3 py-1 text-xs font-medium text-gray-600 bg-white rounded border border-gray-300 hover:bg-gray-50"
              >
                Cancelar
              </button>
            </>
          ) : (
            <button
              onClick={onEnableEditMode}
              className="px-3 py-1 text-xs font-medium text-blue-600 bg-white rounded border border-blue-300 hover:bg-blue-50"
            >
              Editar Cantidades
            </button>
          )}
          <span className={`text-xs font-medium ${isEditMode ? 'text-gray-50': 'text-gray-600'} ml-4`}>
            Total: S/. {formatCurrency(isEditMode && pseudoTotal !== undefined ? pseudoTotal : totalFromRedux)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default APUFooter;
