import { gql } from '@apollo/client';
import client from '../apolloClient';
import { Comprobante } from './comprobanteService';
import { OrdenCompra } from './ordenCompraService';

const LIST_COMPROBANTE_ORDEN_COMPRA = gql`
  query ListComprobanteOrdenesCompra {
    listComprobanteOrdenesCompra {
      id
      comprobante_id {
        id
        fecha_creacion
        fecha_emision
        comprobante_tipo_id {
          id
          nombre
        }
        tipo_moneda_id
        serie
        monto
      }
      orden_compra_id {
        id
        codigo_orden
      }
    }
  }
`;

const GET_BY_COMPROBANTE_ID = gql`
  query GetByComprobanteId($comprobante_id: ID!) {
    getByComprobanteId(comprobante_id: $comprobante_id) {
      id
      comprobante_id {
        id
        fecha_creacion
        fecha_emision
        comprobante_tipo_id {
          id
          nombre
        }
        tipo_moneda_id
        serie
        monto
      }
      orden_compra_id {
        id
        codigo_orden
      }
    }
  }
`;

const GET_BY_ORDEN_COMPRA_ID = gql`
  query GetByOrdenCompraId($orden_compra_id: ID!) {
    getByOrdenCompraId(orden_compra_id: $orden_compra_id) {
      id
      comprobante_id {
        id
        fecha_creacion
        fecha_emision
        comprobante_tipo_id {
          id
          nombre
        }
        tipo_moneda_id
        serie
        monto
      }
      orden_compra_id {
        id
        codigo_orden
      }
    }
  }
`;

const ADD_COMPROBANTE_ORDEN_COMPRA = gql`
  mutation AddComprobanteOrdenCompra($comprobante_id: ID!, $orden_compra_id: ID!) {
    addComprobanteOrdenCompra(
      comprobante_id: $comprobante_id
      orden_compra_id: $orden_compra_id
    ) {
      id
      comprobante_id {
        id
      }
      orden_compra_id {
        id
      }
    }
  }
`;

const UPDATE_COMPROBANTE_ORDEN_COMPRA = gql`
  mutation UpdateComprobanteOrdenCompra(
    $id: ID!
    $comprobante_id: ID
    $orden_compra_id: ID
  ) {
    updateComprobanteOrdenCompra(
      id: $id
      comprobante_id: $comprobante_id
      orden_compra_id: $orden_compra_id
    ) {
      id
      comprobante_id {
        id
      }
      orden_compra_id {
        id
      }
    }
  }
`;

const DELETE_COMPROBANTE_ORDEN_COMPRA = gql`
  mutation DeleteComprobanteOrdenCompra($id: ID!) {
    deleteComprobanteOrdenCompra(id: $id) {
      id
    }
  }
`;

export interface ComprobanteOrdenCompra {
  id: string;
  comprobante_id: Comprobante;
  orden_compra_id: OrdenCompra;
}

export interface ComprobanteOrdenCompraInput {
  comprobante_id: string;
  orden_compra_id: string;
}

export const listComprobanteOrdenCompraService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_COMPROBANTE_ORDEN_COMPRA,
      fetchPolicy: 'network-only',
    });
    return data.listComprobanteOrdenesCompra;
  } catch (error) {
    throw new Error(`Error fetching comprobante orden compra: ${error}`);
  }
};

export const getByComprobanteIdService = async (comprobante_id: string) => {
  try {
    const { data } = await client.query({
      query: GET_BY_COMPROBANTE_ID,
      variables: { comprobante_id },
      fetchPolicy: 'network-only',
    });
    return data.getByComprobanteId;
  } catch (error) {
    throw new Error(`Error fetching by comprobante id: ${error}`);
  }
};

export const getByOrdenCompraIdService = async (orden_compra_id: string) => {
  try {
    const { data } = await client.query({
      query: GET_BY_ORDEN_COMPRA_ID,
      variables: { orden_compra_id },
      fetchPolicy: 'network-only',
    });
    return data.getByOrdenCompraId;
  } catch (error) {
    throw new Error(`Error fetching by orden compra id: ${error}`);
  }
};

export const addComprobanteOrdenCompraService = async (input: ComprobanteOrdenCompraInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_COMPROBANTE_ORDEN_COMPRA,
      variables: input,
    });
    return data.addComprobanteOrdenCompra;
  } catch (error) {
    throw new Error(`Error adding comprobante orden compra: ${error}`);
  }
};

export const updateComprobanteOrdenCompraService = async (
  id: string,
  input: Partial<ComprobanteOrdenCompraInput>
) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_COMPROBANTE_ORDEN_COMPRA,
      variables: { id, ...input },
    });
    return data.updateComprobanteOrdenCompra;
  } catch (error) {
    throw new Error(`Error updating comprobante orden compra: ${error}`);
  }
};

export const deleteComprobanteOrdenCompraService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_COMPROBANTE_ORDEN_COMPRA,
      variables: { id },
    });
    return data.deleteComprobanteOrdenCompra;
  } catch (error) {
    throw new Error(`Error deleting comprobante orden compra: ${error}`);
  }
};
