import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_INGRESO_ALMACEN_COMPROBANTES = gql`
  query ListIngresoAlmacenComprobantes {
    listIngresoAlmacenComprobantes {
      id
      comprobante_id {
        id
        serie
        monto
      }
      transferencia_detalle_id {
        id
        monto
        estado
      }
    }
  }
`;

const GET_BY_COMPROBANTE = gql`
  query GetIngresoAlmacenComprobanteByComprobante($comprobanteId: ID!) {
    getIngresoAlmacenComprobanteByComprobante(comprobanteId: $comprobanteId) {
      id
      comprobante_id {
        id
        serie
        monto
      }
      transferencia_detalle_id {
        id
        monto
        estado
      }
    }
  }
`;

const ADD_INGRESO_ALMACEN_COMPROBANTE = gql`
  mutation AddIngresoAlmacenComprobante(
    $comprobante_id: ID!
    $transferencia_detalle_id: ID!
  ) {
    addIngresoAlmacenComprobante(
      comprobante_id: $comprobante_id
      transferencia_detalle_id: $transferencia_detalle_id
    ) {
      id
      comprobante_id {
        id
        serie
      }
      transferencia_detalle_id {
        id
      }
    }
  }
`;

const UPDATE_INGRESO_ALMACEN_COMPROBANTE = gql`
  mutation UpdateIngresoAlmacenComprobante(
    $id: ID!
    $comprobante_id: ID
    $transferencia_detalle_id: ID
  ) {
    updateIngresoAlmacenComprobante(
      id: $id
      comprobante_id: $comprobante_id
      transferencia_detalle_id: $transferencia_detalle_id
    ) {
      id
      comprobante_id {
        id
        serie
      }
      transferencia_detalle_id {
        id
        monto
      }
    }
  }
`;

const DELETE_INGRESO_ALMACEN_COMPROBANTE = gql`
  mutation DeleteIngresoAlmacenComprobante($id: ID!) {
    deleteIngresoAlmacenComprobante(id: $id) {
      id
    }
  }
`;

export interface IngresoAlmacenComprobanteInput {
  comprobante_id: string;
  transferencia_detalle_id: string;
}

export interface IngresoAlmacenComprobante {
  id: string;
  comprobante_id: {
    id: string;
    serie: string;
    monto: number;
  };
  transferencia_detalle_id: {
    id: string;
    monto: number;
    estado: string;
  };
}

// Servicios
export const listIngresoAlmacenComprobantesService = async (): Promise<IngresoAlmacenComprobante[]> => {
  try {
    const { data } = await client.query({
      query: LIST_INGRESO_ALMACEN_COMPROBANTES,
      fetchPolicy: 'network-only',
    });
    return data.listIngresoAlmacenComprobantes;
  } catch (error) {
    throw new Error(`Error fetching ingreso almacen comprobantes: ${error}`);
  }
};

export const getByComprobanteIdService = async (comprobanteId: string): Promise<IngresoAlmacenComprobante[]> => {
  try {
    const { data } = await client.query({
      query: GET_BY_COMPROBANTE,
      variables: { comprobanteId },
      fetchPolicy: 'network-only',
    });
    return data.getIngresoAlmacenComprobanteByComprobante;
  } catch (error) {
    throw new Error(`Error fetching ingreso almacen comprobante by comprobante: ${error}`);
  }
};

export const addIngresoAlmacenComprobanteService = async (input: IngresoAlmacenComprobanteInput): Promise<IngresoAlmacenComprobante> => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_INGRESO_ALMACEN_COMPROBANTE,
      variables: input,
    });
    return data.addIngresoAlmacenComprobante;
  } catch (error) {
    throw new Error(`Error adding ingreso almacen comprobante: ${error}`);
  }
};

export const updateIngresoAlmacenComprobanteService = async (
  id: string,
  input: Partial<IngresoAlmacenComprobanteInput>
): Promise<IngresoAlmacenComprobante> => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_INGRESO_ALMACEN_COMPROBANTE,
      variables: { id, ...input },
    });
    return data.updateIngresoAlmacenComprobante;
  } catch (error) {
    throw new Error(`Error updating ingreso almacen comprobante: ${error}`);
  }
};

export const deleteIngresoAlmacenComprobanteService = async (id: string): Promise<{ id: string }> => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_INGRESO_ALMACEN_COMPROBANTE,
      variables: { id },
    });
    return data.deleteIngresoAlmacenComprobante;
  } catch (error) {
    throw new Error(`Error deleting ingreso almacen comprobante: ${error}`);
  }
};
