import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listRegistrosService,
  addRegistroService,
  updateRegistroService,
  deleteRegistroService,
  listDocumentosContablesService,
  listDocumentosContablesRegistradosService,
  totalContableService,
  Registro,
  RegistroInput,
  DocumentoContable,
  DocumentoContableRegistrado,
  TotalContable
} from '../services/registroService';

interface RegistroState {
  registros: Registro[];
  loading: boolean;
  error: string | null;
  documentosContables: DocumentoContable[];
  loadingDocumentos: boolean;
  errorDocumentos: string | null;
  documentosContablesRegistrados: DocumentoContableRegistrado[];
  loadingDocumentosRegistrados: boolean;
  errorDocumentosRegistrados: string | null;
  totalContable: TotalContable[];
  loadingTotalContable: boolean;
  errorTotalContable: string | null;
}

const initialState: RegistroState = {
  registros: [],
  loading: false,
  error: null,
  documentosContables: [],
  loadingDocumentos: false,
  errorDocumentos: null,
  documentosContablesRegistrados: [],
  loadingDocumentosRegistrados: false,
  errorDocumentosRegistrados: null,
  totalContable: [],
  loadingTotalContable: false,
  errorTotalContable: null
};

// Thunks
export const fetchRegistros = createAsyncThunk(
  'registro/fetchRegistros',
  async (_, { rejectWithValue }) => {
    try {
      return await listRegistrosService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addRegistro = createAsyncThunk(
  'registro/addRegistro',
  async (registro: RegistroInput, { rejectWithValue }) => {
    try {
      return await addRegistroService(registro);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateRegistro = createAsyncThunk(
  'registro/updateRegistro',
  async ({ id, ...data }: Partial<RegistroInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateRegistroService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteRegistro = createAsyncThunk(
  'registro/deleteRegistro',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteRegistroService(id);
      return { id };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchDocumentosContables = createAsyncThunk(
  'registro/fetchDocumentosContables',
  async (_, { rejectWithValue }) => {
    try {
      return await listDocumentosContablesService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchDocumentosContablesRegistrados = createAsyncThunk(
  'registro/fetchDocumentosContablesRegistrados',
  async (_, { rejectWithValue }) => {
    try {
      return await listDocumentosContablesRegistradosService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchTotalContable = createAsyncThunk(
  'registro/fetchTotalContable',
  async (_, { rejectWithValue }) => {
    try {
      return await totalContableService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const registroSlice = createSlice({
  name: 'registro',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch registros
      .addCase(fetchRegistros.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRegistros.fulfilled, (state, action: PayloadAction<Registro[]>) => {
        state.loading = false;
        state.registros = action.payload;
      })
      .addCase(fetchRegistros.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Add registro
      .addCase(addRegistro.fulfilled, (state, action: PayloadAction<Registro>) => {
        state.registros.push(action.payload);
      })
      // Update registro
      .addCase(updateRegistro.fulfilled, (state, action: PayloadAction<Registro>) => {
        const index = state.registros.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.registros[index] = action.payload;
        }
      })
      // Delete registro
      .addCase(deleteRegistro.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.registros = state.registros.filter(item => item.id !== action.payload.id);
      })
      // Documentos Contables
      .addCase(fetchDocumentosContables.pending, (state) => {
        state.loadingDocumentos = true;
        state.errorDocumentos = null;
      })
      .addCase(fetchDocumentosContables.fulfilled, (state, action: PayloadAction<DocumentoContable[]>) => {
        state.loadingDocumentos = false;
        state.documentosContables = action.payload;
      })
      .addCase(fetchDocumentosContables.rejected, (state, action) => {
        state.loadingDocumentos = false;
        state.errorDocumentos = action.payload as string;
      })
      // Documentos Contables Registrados
      .addCase(fetchDocumentosContablesRegistrados.pending, (state) => {
        state.loadingDocumentosRegistrados = true;
        state.errorDocumentosRegistrados = null;
      })
      .addCase(fetchDocumentosContablesRegistrados.fulfilled, (state, action) => {
        state.loadingDocumentosRegistrados = false;
        state.documentosContablesRegistrados = action.payload;
      })
      .addCase(fetchDocumentosContablesRegistrados.rejected, (state, action) => {
        state.loadingDocumentosRegistrados = false;
        state.errorDocumentosRegistrados = action.payload as string;
      })
      // Total Contable
      .addCase(fetchTotalContable.pending, (state) => {
        state.loadingTotalContable = true;
        state.errorTotalContable = null;
      })
      .addCase(fetchTotalContable.fulfilled, (state, action) => {
        state.loadingTotalContable = false;
        state.totalContable = action.payload;
      })
      .addCase(fetchTotalContable.rejected, (state, action) => {
        state.loadingTotalContable = false;
        state.errorTotalContable = action.payload as string;
      });
  },
});

export const registroReducer = registroSlice.reducer;
