import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import TableComponent from '../../../components/Table/TableComponent';
import LoaderPage from '../../../components/Loader/LoaderPage';
import { fetchRecursosBodegaByObra } from '../../../slices/obraBodegaRecursoSlice';
import { fetchObras } from '../../../slices/obrasSlice';
import SalidasConsumosPrestamos from '../../Tranferencias/SalidasConsumosPrestamos/SalidasConsumosPrestamos';
import Modal from '../../../components/Modal/Modal';
import TransferirRecurso from '../../../components/Transfers/TransferirRecurso';
import { FiRepeat } from 'react-icons/fi';

const AlmacenBodega: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [selectedObraId, setSelectedObraId] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSalidas, setShowSalidas] = useState<boolean>(false);
    const [showTransferir, setShowTransferir] = useState<boolean>(false);
    const [selectedRecurso, setSelectedRecurso] = useState<any>(null);

    // Selectors
    const obras = useSelector((state: RootState) => state.obra.obras);
    const obraBodegaRecursosMap = useSelector((state: RootState) => state.obraBodegaRecurso.obraBodegaRecursosMap);
    const unidades = useSelector((state: RootState) => state.unidad.unidades);

    const fetchRecursosForObra = async (obraId: string) => {
        if (obraId && !obraBodegaRecursosMap[obraId]?.length) {
            setIsLoading(true);
            await dispatch(fetchRecursosBodegaByObra(obraId));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (obras.length === 0) {
            dispatch(fetchObras());
        }
    }, [dispatch, obras.length]);

    // cargar el inventario de la primera obra por defecto
    useEffect(() => {
        if (obras.length > 0 && !selectedObraId) {
            const firstObraId = obras[0].id;
            setSelectedObraId(firstObraId);
            fetchRecursosForObra(firstObraId);
        }
    }, [obras]);

    const handleObraChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const obraId = e.target.value;
        setSelectedObraId(obraId);
        if (obraId) {
            await fetchRecursosForObra(obraId);
        }
    };

    const handleTransferirClick = (recursoId: string) => {
        const recurso = obraBodegaRecursosMap[selectedObraId].find(r => r.id === recursoId);
        if (recurso) {
            setSelectedRecurso(recurso);
            setShowTransferir(true);
        }
    };

    // Preparar datos para la tabla
    const obraBodegaRecursos = obraBodegaRecursosMap[selectedObraId] || [];
    const tableData = {
        noMostrar: [false, false, false, false, false, false, false, false, false, true],
        headers: ['Código', 'Nombre', 'Cantidad', 'Costo', 'Unidad', 'Bodega', 'Estado', 'Opc', 'Imagen', 'ID'],
        filterSelect: [false, false, false, false, true, true, true, false, false, false],
        filter: [true, true, true, true, true, true, true, false, false, true],
        rows: obraBodegaRecursos
            .map(recurso => {
                const unidad = recurso.recurso_id ? unidades.find(u => u.id === recurso.recurso_id.unidad_id) : null;
                return {
                    ID: recurso.id,
                    Código: recurso.recurso_id?.codigo || "No hay código",
                    Nombre: recurso.recurso_id?.nombre || 'Sin nombre',
                    Cantidad: recurso.cantidad,
                    Costo: recurso.costo.toFixed(2),
                    Bodega: recurso.obra_bodega_id?.nombre || 'Sin bodega',
                    Unidad: unidad?.nombre || 'Sin unidad',
                    Estado: recurso.estado,
                    "Opc": (
                        <button
                            onClick={() => handleTransferirClick(recurso.id)}
                            className="p-1 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center justify-center"
                            title="Transferir recurso"
                        >
                            <FiRepeat size={16} />
                        </button>
                    ),
                    Imagen: recurso.recurso_id?.imagenes?.[0]?.recurso_id || 'Sin imagen' //modificar esto cuando haya imagenes con "FILE"
                };
            })
    };

    return (
        <div className="w-full p-4 flex flex-col flex-grow bg-white/60 overflow-hidden rounded-xl">
            <div className="flex justify-between gap-4 items-end mb-6">
                <div className="text-white pb-4">
                    <h1 className="text-2xl text-gray-900 font-bold">Inventarios</h1>
                </div>
            </div>
            <div className="mb-6 flex justify-between items-end">
                <div className="flex-1">
                    <label htmlFor="obra" className="block text-sm font-medium text-white mb-2">
                        Seleccionar Obra
                    </label>
                    <select
                        id="obra"
                        value={selectedObraId}
                        onChange={handleObraChange}
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        <option value="">Seleccione una obra</option>
                        {obras.map(obra => (
                            <option key={obra.id} value={obra.id}>
                                {obra.titulo} - {obra.nombre}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedObraId && !isLoading && obraBodegaRecursos.length > 0 && (
                    <button
                        onClick={() => setShowSalidas(true)}
                        className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                        Registrar Salida
                    </button>
                )}
            </div>

            {selectedObraId && !showSalidas && (
                <div className="flex flex-1 overflow-hidden rounded-xl">
                    <main className="w-full flex flex-col flex-grow p-4 bg-white/80 overflow-hidden">
                        <div className="flex-grow border rounded-lg overflow-hidden">
                            <div className="h-full overflow-auto">
                                {isLoading ? (
                                    <LoaderPage />
                                ) : (
                                    obraBodegaRecursos.length > 0 && <TableComponent tableData={tableData} />
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            )}

            {showSalidas && (
                <Modal
                    isOpen={showSalidas}
                    onClose={() => setShowSalidas(false)}
                    title="Salidas de Consumos y Préstamos"
                >
                    <SalidasConsumosPrestamos
                        obraId={selectedObraId}
                        recursos={obraBodegaRecursos}
                        onClose={() => setShowSalidas(false)}
                        loading={isLoading} // Pasamos la prop
                    />
                </Modal>
            )}

            {showTransferir && selectedRecurso && (
                <Modal
                    isOpen={showTransferir}
                    onClose={() => setShowTransferir(false)}
                    title="Transferencia de Recursos entre Bodegas"
                    headerBgColor="bg-blue-700"
                >
                    <TransferirRecurso
                        recurso={selectedRecurso}
                        onClose={() => setShowTransferir(false)}
                        obraId={selectedObraId}
                    />
                </Modal>
            )}
        </div>
    );
};

export default AlmacenBodega;