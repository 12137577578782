import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listComentariosService,
  addComentarioService,
  updateComentarioService,
  deleteComentarioService,
  getComentariosByReferenciaIdService,
  Comentario,
  ComentarioInput
} from '../services/comentarioService';

interface ComentarioState {
  comentarios: Comentario[];
  comentariosByReferencia: Comentario[];
  loading: boolean;
  error: string | null;
}

const initialState: ComentarioState = {
  comentarios: [],
  comentariosByReferencia: [],
  loading: false,
  error: null,
};

export const fetchComentarios = createAsyncThunk(
  'comentario/fetchComentarios',
  async (_, { rejectWithValue }) => {
    try {
      return await listComentariosService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addComentario = createAsyncThunk(
  'comentario/addComentario',
  async (comentario: ComentarioInput, { rejectWithValue }) => {
    try {
      return await addComentarioService(comentario);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateComentario = createAsyncThunk(
  'comentario/updateComentario',
  async ({ id, ...data }: Partial<ComentarioInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateComentarioService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteComentario = createAsyncThunk(
  'comentario/deleteComentario',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteComentarioService(id);
      return id;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getComentariosByReferenciaId = createAsyncThunk(
  'comentario/getComentariosByReferenciaId',
  async (referenciaId: string, { rejectWithValue }) => {
    try {
      return await getComentariosByReferenciaIdService(referenciaId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const comentarioSlice = createSlice({
  name: 'comentario',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchComentarios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchComentarios.fulfilled, (state, action: PayloadAction<Comentario[]>) => {
        state.loading = false;
        state.comentarios = action.payload;
      })
      .addCase(fetchComentarios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addComentario.fulfilled, (state, action: PayloadAction<Comentario>) => {
        state.comentarios.push(action.payload);
      })
      .addCase(updateComentario.fulfilled, (state, action: PayloadAction<Comentario>) => {
        const index = state.comentarios.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.comentarios[index] = action.payload;
        }
      })
      .addCase(deleteComentario.fulfilled, (state, action: PayloadAction<string>) => {
        state.comentarios = state.comentarios.filter(item => item.id !== action.payload);
      })
      .addCase(getComentariosByReferenciaId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getComentariosByReferenciaId.fulfilled, (state, action: PayloadAction<Comentario[]>) => {
        state.loading = false;
        state.comentariosByReferencia = action.payload;
      })
      .addCase(getComentariosByReferenciaId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const comentarioReducer = comentarioSlice.reducer;
