import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { FiX } from 'react-icons/fi';

// Types
import { Unidad } from '../../types/ordenCompra.types';
import { OrdenCompra } from '../../types/ordenCompra';
import type { UsuarioCargo } from '../../slices/usuarioSlice';
// Store
import { AppDispatch, RootState } from '../../store/store';
import { fetchOrdenCompraRecursosByOrdenId } from '../../slices/ordenCompraRecursosSlice';
import { createOrdenServicioFecha } from '../../slices/ordenServicioFechaSlice';
import { fetchUsuariosAndCargos } from '../../slices/usuarioSlice';
import { updateOrdenCompra } from '../../slices/ordenCompraSlice';
// import { updateCotizacion } from '../../slices/cotizacionSlice';
import { createAprobacionGeneral } from '../../slices/aprobacionGeneralSlice';

// Components
import LoaderOverlay from '../../components/Loader/LoaderOverlay';
import ModalAlert from '../../components/Modal/ModalAlert';
import Toast from '../../components/Toast/Toast';

// Utils
import { formatDate } from '../../components/Utils/dateUtils';
import { uploadFileService } from '../../services/archivoSustentoService';

interface AprobacionAsignada {
  usuario: UsuarioCargo;
  aprobacion_id: string;
}

interface OrdenServicioDetalleProps {
  ordenCompra: OrdenCompra;
  onSuccess?: () => void;
  onClose?: () => void;
}

// Interfaces
interface UploadResult {
  success: boolean;
  archivo_id?: string;
  url?: string;
  fileName?: string;
  isDevelopmentMode?: boolean;
  message?: string;
}

const OrdenServicioDetalle: React.FC<OrdenServicioDetalleProps> = ({ ordenCompra, onSuccess, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { ordenCompraRecursosByOrdenId, loading } = useSelector((state: RootState) => state.ordenCompraRecursos);
  const { usuariosCargo } = useSelector((state: RootState) => state.usuario);
  const unidades = useSelector((state: RootState) => state.unidad.unidades) as Unidad[];
  const [archivos, setArchivos] = useState<{ [key: string]: File[] }>({});
  const [isParcial, setIsParcial] = useState(ordenCompra?.estado === 'parcial');
  const [fechasRecursos, setFechasRecursos] = useState<{ [key: string]: { inicio: string; fin: string } }>({});
  const [gerentesAsignados] = useState<AprobacionAsignada[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResponsable, setSelectedResponsable] = useState<string>('');
  // const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
  const [toast, setToast] = useState<{
    show: boolean;
    message: string;
    variant: 'success' | 'danger' | 'warning' | 'info';
  }>({
    show: false,
    message: '',
    variant: 'info'
  });

  const gerentes = useMemo(() =>
    usuariosCargo.filter(usuario => usuario.cargo_id.gerarquia === 4),
    [usuariosCargo]
  );

  const formatCurrency = (value: number) => `S/ ${value.toFixed(2)}`;

  const handleFileChange = (index: number, files: FileList) => {
    setArchivos(prev => ({
      ...prev,
      [index]: [...(prev[index] || []), ...Array.from(files)]
    }));
  };

  const handleRemoveFile = (index: number, fileIndex: number) => {
    setArchivos(prev => ({
      ...prev,
      [index]: prev[index].filter((_, idx) => idx !== fileIndex)
    }));
  };

  const handleFechaChange = (recursoId: string, tipo: 'inicio' | 'fin', value: string) => {
    setFechasRecursos(prev => ({
      ...prev,
      [recursoId]: {
        ...prev[recursoId],
        [tipo]: value
      }
    }));
  };

  const gerentesDisponibles = gerentes.filter(
    gerente => !gerentesAsignados.some(
      asignado => asignado.usuario.id === gerente.id
    )
  );

  const handleParcialChange = async (isChecked: boolean) => {
    if (!isChecked || !ordenCompra?.id) {
      setIsParcial(false);
      return;
    }

    setIsModalOpen(true);
  };

  const handleConfirmParcial = async () => {
    try {
      if (ordenCompra?.id) {
        await dispatch(updateOrdenCompra({
          id: ordenCompra.id,
          estado: 'parcial' //pendiente, parcial, proceso, completado
        }));
        setIsParcial(true);
      }
    } catch (error) {
      console.error('Error al cambiar a estado parcial:', error);
      setIsParcial(false);
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleCancelParcial = () => {
    setIsModalOpen(false);
    setIsParcial(false);
  };

  const handleResponsableChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedResponsable(e.target.value);
  };

  const showToast = (message: string, variant: 'success' | 'danger' | 'warning' | 'info') => {
    setToast({ show: true, message, variant });
  };

  const handleSubmit = async () => {
    try {
      if (!selectedResponsable) {
        alert('Por favor seleccione un responsable de aprobación');
        return;
      }

      // setUploadingFiles(true);

      // Definir tipos de archivos
      const tiposArchivo: Record<string, string> = {
        '1': 'servicio/informe',
        '2': 'servicio/valorizacion',
        '3': 'servicio/otros',
        '4': 'servicio/imagenes'
      };

      // Subir todos los archivos
      const uploadPromises = Object.entries(archivos).flatMap(([index, files]) =>
        files.map(file =>
          uploadFileService({
            file,
            referencia_id: ordenCompra.id,
            tipo: tiposArchivo[index]
          })
            .then(response => {
              console.log("Respuesta exitosa del servidor:", response);

              const result: UploadResult = {
                success: true,
                archivo_id: response.archivo_id,
                url: response.url,
                fileName: file.name,
                isDevelopmentMode: response.isDevelopmentMode
              };

              return result;
            })
            .catch(error => {
              console.error("Error en la subida:", error);

              if (error.message?.includes("Failed to fetch")) {
                console.warn("Error CORS detectado, usando modo desarrollo");
                return {
                  success: true,
                  archivo_id: `temp-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`,
                  url: URL.createObjectURL(file),
                  fileName: file.name,
                  isDevelopmentMode: true
                };
              }

              return {
                success: false,
                message: `Error: ${error.message || 'Error desconocido'}`,
                fileName: file.name
              };
            })
        )
      );

      const results = await Promise.all(uploadPromises);

      // Analizar resultados
      const successfulUploads = results.filter(r => r.success);
      const failedUploads = results.filter(r => !r.success);
      const developmentModeUploads = results.filter(r => r.success && r.isDevelopmentMode);

      // Mostrar mensaje según resultados
      if (successfulUploads.length > 0 && failedUploads.length === 0) {
        if (developmentModeUploads.length > 0) {
          showToast(`${successfulUploads.length} archivo(s) subido(s) en modo desarrollo`, 'warning');
        } else {
          showToast(`${successfulUploads.length} archivo(s) subido(s) correctamente`, 'success');
        }
      } else if (successfulUploads.length > 0 && failedUploads.length > 0) {
        showToast(`${successfulUploads.length} archivo(s) subido(s), ${failedUploads.length} fallidos`, 'warning');
      } else if (failedUploads.length > 0) {
        showToast('Error: No se pudo subir ningún archivo', 'danger');
        return;
      }

      // Continuar con el resto del proceso
      if (ordenCompra?.id) {
        await dispatch(updateOrdenCompra({
          id: ordenCompra.id,
          estado: 'proceso'
        }));
      }

      if (isParcial && ordenCompra?.id) {
        const fechasPromises = Object.entries(fechasRecursos).map(([recursoId, fechas]) => {
          return dispatch(createOrdenServicioFecha({
            ordenServicioRecursoId: recursoId,
            fechaInicio: new Date(fechas.inicio),
            fechaFin: new Date(fechas.fin)
          }));
        });

        await Promise.all(fechasPromises);
      }

      if (ordenCompra?.id) {
        await dispatch(createAprobacionGeneral({
          referencia_id: ordenCompra.id,
          referencia: "servicio",
          usuario_id: selectedResponsable,
          fecha: new Date()
        }));
      }

      showToast('Proceso completado exitosamente', 'success');
      onSuccess?.();
      onClose?.();
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
      showToast('Error al procesar la solicitud', 'danger');
    } finally {
      // setUploadingFiles(false);
    }
  };

  useEffect(() => {
    if (ordenCompra?.id) {
      dispatch(fetchOrdenCompraRecursosByOrdenId(ordenCompra.id));
      setIsParcial(ordenCompra.estado === 'parcial');
      dispatch(fetchUsuariosAndCargos());
    }
  }, [ordenCompra?.id, ordenCompra?.estado, dispatch]);

  if (!ordenCompra) return <LoaderOverlay message="Cargando Información" />;

  const totales = ordenCompraRecursosByOrdenId.reduce((acc, item) => ({
    real: acc.real + (item.costo_real * item.cantidad),
    aprox: acc.aprox + (item.costo_aproximado * item.cantidad)
  }), { real: 0, aprox: 0 });

  const renderInformacionGeneral = () => (
    <div className="bg-white p-6 rounded-lg border border-gray-200">
      <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Información General</h3>
      <div className="text-xs grid grid-cols-2 gap-4">

        <div className='flex flex-col gap-1'>

          <div className="flex justify-between">
            <span className="text-gray-600">Código:</span>
            <span className="font-medium">{ordenCompra.codigo_orden}</span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Emisión:</span>
            <span className="font-medium">{formatDate(ordenCompra?.fecha_ini || "", 'dd/mm/yyyy')}</span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Entrega Deseable:</span>
            <span className="font-medium">{formatDate(ordenCompra?.fecha_fin || "", 'dd/mm/yyyy')}</span>
          </div>

          {/* <div className="flex justify-between">
            <span className="text-gray-600">Obra:</span>
            <span className="font-medium">
              -
            </span>
          </div> */}

        </div>

        <div className='flex flex-col gap-1'>

          <div className="flex justify-between">
            <span className="text-gray-600">Moneda:</span>
            <span className="font-medium">
              S/
            </span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Monto total:</span>
            <span className="font-medium">{formatCurrency(totales.real)}</span>
          </div>

          {/* <div className="flex justify-between">
            <span className="text-gray-600">Usuario:</span>
            <span className="font-medium"></span>
          </div> */}

        </div>

      </div>
    </div>
  );

  const renderTablaRecursos = () => (
    <div className="bg-white p-6 rounded-lg border border-gray-200">
      <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Servicios</h3>

      <div className='flex items-center gap-2 mb-3'>
        <input
          type="checkbox"
          checked={isParcial}
          onChange={(e) => handleParcialChange(e.target.checked)}
        />
        <label className='text-sm font-semibold text-red-600'>Parcial</label>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Código</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Nombre</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Unidad</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Cantidad</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Costo Real</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Costo Aprox.</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Estado</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Fecha Inicio</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Fecha Fin</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {ordenCompraRecursosByOrdenId.map((recurso) => (
              <tr key={recurso.id} className="hover:bg-gray-50 text-xs">
                <td className="px-4 py-2 text-center whitespace-nowrap font-medium text-gray-900">{recurso.id_recurso.codigo}</td>
                <td className="px-4 py-2 text-gray-700">{recurso.id_recurso.nombre}</td>
                <td className="px-4 py-2 text-center text-gray-600">
                  {unidades.find(u => u.id === recurso.id_recurso.unidad_id)?.nombre || 'N/A'}
                </td>
                <td className="px-4 py-2 text-center text-gray-700">{recurso.cantidad}</td>
                <td className="px-4 py-2 text-center font-medium text-gray-900">{formatCurrency(recurso.costo_real)}</td>
                <td className="px-4 py-2 text-center text-gray-600">{formatCurrency(recurso.costo_aproximado)}</td>
                <td className="px-4 py-2 text-center">
                  <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                    ${recurso.estado === 'pendiente' ? 'bg-yellow-100 text-yellow-800' :
                      recurso.estado === 'completado' ? 'bg-green-100 text-green-800' :
                        'bg-gray-100 text-gray-800'}`}>
                    {recurso.estado}
                  </span>
                </td>
                <td className="px-4 py-2 text-center">
                  <input
                    type="date"
                    className='w-full border border-gray-300 rounded-md p-1'
                    value={fechasRecursos[recurso.id]?.inicio || formatDate(ordenCompra?.fecha_ini, 'yyyy-mm-dd')}
                    disabled={!isParcial}
                    onChange={(e) => handleFechaChange(recurso.id, 'inicio', e.target.value)}
                  />
                </td>
                <td className="px-4 py-2 text-center">
                  <input
                    type="date"
                    className='w-full border border-gray-300 rounded-md p-1'
                    value={fechasRecursos[recurso.id]?.fin || formatDate(ordenCompra?.fecha_fin, 'yyyy-mm-dd')}
                    disabled={!isParcial}
                    onChange={(e) => handleFechaChange(recurso.id, 'fin', e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="bg-gray-50 font-medium text-xs">
              <td colSpan={4} className="px-4 py-3 text-right text-gray-700">Totales:</td>
              <td className="px-4 py-3 text-center text-gray-900">{formatCurrency(totales.real)}</td>
              <td className="px-4 py-3 text-center text-gray-600">{formatCurrency(totales.aprox)}</td>
              <td colSpan={3}></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );

  const renderPagos = () => (
    <div className="bg-white p-6 rounded-lg border border-gray-200">
      {/* <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Pagos</h3> */}

      {/* <div className="grid grid-cols-3 gap-4 mb-3">
        <div className="flex justify-between">
          <span className="text-xs font-semibold text-gray-600">Tipo comprobante:</span>
          <span className="font-medium"></span>
        </div>
        <div className="flex justify-between">
          <span className="text-xs font-semibold text-gray-600">Monto:</span>
          <span className="font-medium"></span>
        </div>
        <div className="flex justify-between">
          <span className="text-xs font-semibold text-gray-600">Estado:</span>
          <span className="font-medium"></span>
        </div>
      </div>

      <div className='grid grid-cols-1 gap-2 mb-3'>
        <label className='text-xs font-semibold text-gray-600'>Detalles:</label>
        <textarea className='w-full border border-gray-300 rounded-md p-1' />
      </div> */}

      <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Subir sustento</h3>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-3'>
        {[
          { title: 'Informe', index: 1 },
          { title: 'Valorización', index: 2 },
          { title: 'Otros Archivos', index: 3 },
          { title: 'Imágenes', index: 4 }
        ].map(({ title, index }) => (
          <div key={index} className='w-full'>
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center w-full">
                <label htmlFor={`dropzone-file-${index}`} className={`flex flex-col items-center justify-center w-full  border-2 ${archivos[index]?.length ? 'border-blue-300' : 'border-gray-300'} border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100`}>
                  <div className="flex flex-col items-center justify-center p-1">
                    {/* <FiUpload className='w-8 h-8 mb-4 text-gray-300' /> */}
                    <p className="text-xs text-gray-500">
                      <span className="font-semibold">Click para subir</span>
                    </p>
                    <p className="text-sm text-cyan-500">
                      <span className="font-semibold">{title}</span>
                    </p>
                    {archivos[index]?.length > 0 && (
                      <p className="text-xs text-gray-600 mt-2">
                        {archivos[index].length} archivo(s)
                      </p>
                    )}
                  </div>
                  <input
                    id={`dropzone-file-${index}`}
                    type="file"
                    className="hidden"
                    onChange={(e) => e.target.files && handleFileChange(index, e.target.files)}
                    multiple
                    accept={index === 4 ? "image/*" : undefined}
                  />
                </label>
              </div>

              {archivos[index]?.length > 0 && (
                <div className="max-h-32 overflow-y-auto">
                  <ul className="space-y-1">
                    {archivos[index].map((file, fileIndex) => (
                      <li key={fileIndex} className="flex items-center justify-between px-2 py-1 bg-gray-50 rounded text-xs">
                        <span className="truncate flex-1">{file.name}</span>
                        <button
                          onClick={() => handleRemoveFile(index, fileIndex)}
                          className="ml-2 text-red-500 hover:text-red-700"
                        >
                          <FiX className="w-4 h-4" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='flex flex-col gap-2 mt-5 mb-3'>
        <label className='text-sm font-semibold text-gray-600'>Selecione responsable de aprobación:</label>
        <select
          name=""
          id=""
          className='w-full border border-gray-300 rounded-md p-1'
          value={selectedResponsable}
          onChange={handleResponsableChange}
        >
          <option value="">Seleccione un responsable</option>
          {gerentesDisponibles.map((usuario) => (
            <option key={usuario.id} value={usuario.id}>
              {usuario.nombres} {usuario.apellidos}
            </option>
          ))}
        </select>
      </div>

    </div>
  );


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="max-w-7xl mx-auto bg-gray-50 p-6"
    >
      <ModalAlert
        isOpen={isModalOpen}
        title="Confirmar cambio a estado parcial"
        message="Esta acción actualizará el estado de la orden de servicio a parcial"
        onConfirm={handleConfirmParcial}
        onCancel={handleCancelParcial}
        variant="blue"
      />
      {toast.show && (
        <Toast
          message={toast.message}
          variant={toast.variant}
          duration={3000}
          index={0}
          onClose={() => setToast(prev => ({ ...prev, show: false }))}
        />
      )}
      {loading ? (
        <LoaderOverlay message="Cargando Detalles" />
      ) : (
        <div className='flex flex-col gap-4'>
          {renderInformacionGeneral()}
          {renderTablaRecursos()}
          {renderPagos()}

          {/* header */}
          <div className='flex justify-end gap-1'>
            <button
              className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600'
              onClick={() => window.history.back()}
            >
              Cancelar
            </button>
            <button
              className='bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600'
              onClick={handleSubmit}
            >
              Enviar
            </button>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default OrdenServicioDetalle;