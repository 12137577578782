import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store/store';
import { getTitulosByPresupuesto, addTitulo, updateTitulo, deleteTitulo, Titulo, getTitulosFromCache } from '../../../../slices/tituloSlice';
import Modal from '../../../../components/Modal/Modal';
import TituloForm from './TituloForm';
import { FiTrash2 } from 'react-icons/fi';
import ModalAlert from '../../../../components/Modal/ModalAlert';
import LoaderPage from '../../../../components/Loader/LoaderPage';
import LoaderOverlay from '../../../../components/Loader/LoaderOverlay';
import { setEditMode } from '../../../../slices/activeDataSlice';

const TitulosJerarquia: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    
    const activePresupuesto = useSelector((state: RootState) => state.activeData.activePresupuesto);
    const titulos = useSelector((state: RootState) => state.titulo.titulos);
    const titulosPorPresupuesto = useSelector((state: RootState) => state.titulo.titulosPorPresupuesto);
    const [showTituloForm, setShowTituloForm] = useState(false);
    const [editingTitulo, setEditingTitulo] = useState<Titulo | null>(null);
    const [titulosEnEdicion, setTitulosEnEdicion] = useState<Titulo[]>([]);
    const [modoEdicion, setModoEdicion] = useState(false);
    const [primerAccionGrupo, setPrimerAccionGrupo] = useState<null | 'PRIMER_GRUPO' | 'SEGUNDO_GRUPO'>(null);
    const [tituloMoviendose, setTituloMoviendose] = useState<string | null>(null);
    const [titulosColapsados, setTitulosColapsados] = useState<Set<string>>(new Set());
    const [parentId, setParentId] = useState<string | undefined>();
    const [ordenCreate, setOrdenCreate] = useState<number | undefined>();
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const [tipoTitulo, setTipoTitulo] = useState<'TITULO' | 'PARTIDA'>('TITULO');
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [tituloToDelete, setTituloToDelete] = useState<string | null>(null);
    const [isCalculating, setIsCalculating] = useState(false);
    const loading = useSelector((state: RootState) => state.titulo.loading);

    useEffect(() => {
        const fetchTitulos = async () => {
            if (activePresupuesto) {
                const presupuestoId = activePresupuesto.id_presupuesto;
                
                try {
                    if (titulosPorPresupuesto[presupuestoId]) {
                        await dispatch(getTitulosFromCache(presupuestoId));
                    } else {
                        await dispatch(getTitulosByPresupuesto(presupuestoId));
                    }
                } catch (error) {
                    // Manejo de error silencioso
                }
            }
        };

        fetchTitulos();
    }, [dispatch, activePresupuesto]);

    useEffect(() => {
        try {
            const titulosOrdenados = [...titulos].sort((a, b) => a.orden - b.orden);
            setTitulosEnEdicion(titulosOrdenados);
        } catch (error) {
            // Manejo de error silencioso
        }
    }, [titulos, modoEdicion]);

    useEffect(() => {
        if (!modoEdicion) {
            setPrimerAccionGrupo(null);
        }
    }, [modoEdicion]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (activeMenu) {
                const menuElement = document.querySelector('.menu-dropdown');
                const buttonElement = document.querySelector(`[data-menu-button="${activeMenu}"]`);
                
                if (menuElement && 
                    !menuElement.contains(event.target as Node) && 
                    !buttonElement?.contains(event.target as Node)) {
                    setActiveMenu(null);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [activeMenu]);

    const handleEdit = (titulo: Titulo) => {
        if (!primerAccionGrupo) setPrimerAccionGrupo('SEGUNDO_GRUPO');
        setEditingTitulo(titulo);
        setShowTituloForm(true);
    };

    const handleCreate = (parentId?: string, ordenTitulo?: number, tipo: 'TITULO' | 'PARTIDA' = 'TITULO') => {
        if (!primerAccionGrupo) setPrimerAccionGrupo('SEGUNDO_GRUPO');
        setEditingTitulo(null);
        setParentId(parentId);
        setOrdenCreate(ordenTitulo);
        setTipoTitulo(tipo);
        setShowTituloForm(true);
    };

    const handleSubmit = async (tituloData: Titulo) => {
        if (editingTitulo) {
            await dispatch(updateTitulo(tituloData));
        } else {
            await dispatch(addTitulo(tituloData));
        }
        setShowTituloForm(false);
        setEditingTitulo(null);
    };

    const handleNivelChange = (tituloId: string, incrementar: boolean) => {
        if (!primerAccionGrupo) setPrimerAccionGrupo('PRIMER_GRUPO');
        setTitulosEnEdicion(prevTitulos =>
            prevTitulos.map(t => {
                if (t.id_titulo === tituloId) {
                    return {
                        ...t,
                        nivel: incrementar ? t.nivel + 1 : Math.max(1, t.nivel - 1)
                    };
                }
                return t;
            })
        );
    };

    const handleMoverOrden = (tituloId: string, direccion: 'arriba' | 'abajo') => {
        if (!primerAccionGrupo) setPrimerAccionGrupo('PRIMER_GRUPO');
        setTituloMoviendose(tituloId);
        setTitulosEnEdicion(prevTitulos => {
            const titulos = prevTitulos.map(t => ({ ...t }));
            const index = titulos.findIndex(t => t.id_titulo === tituloId);

            if (direccion === 'arriba' && index > 0) {
                const ordenActual = titulos[index].orden;
                const ordenAnterior = titulos[index - 1].orden;

                titulos[index] = { ...titulos[index], orden: ordenAnterior };
                titulos[index - 1] = { ...titulos[index - 1], orden: ordenActual };
            } else if (direccion === 'abajo' && index < titulos.length - 1) {
                const ordenActual = titulos[index].orden;
                const ordenSiguiente = titulos[index + 1].orden;

                titulos[index] = { ...titulos[index], orden: ordenSiguiente };
                titulos[index + 1] = { ...titulos[index + 1], orden: ordenActual };
            }

            return titulos.sort((a, b) => a.orden - b.orden);
        });
        setTimeout(() => setTituloMoviendose(null), 300);
    };

    const calcularEstructura = async () => {
        setIsCalculating(true);
        try {
            const titulosOriginales = new Map(titulos.map(t => [t.id_titulo, { ...t }]));
            let titulosOrdenados = await calcularEstructuraInterna([...titulosEnEdicion]);
            titulosOrdenados = await calcularEstructuraInterna(titulosOrdenados);
            const titulosModificados = titulosOrdenados.filter(titulo => {
                const original = titulosOriginales.get(titulo.id_titulo);
                if (!original) return true;

                const hayCambios =
                    titulo.nivel !== original.nivel ||
                    titulo.orden !== original.orden ||
                    titulo.item !== original.item ||
                    titulo.id_titulo_padre !== original.id_titulo_padre;
                return hayCambios;
            });

            for (const titulo of titulosModificados) {
                await dispatch(updateTitulo(titulo));
            }

            setModoEdicion(false);
        } catch (error) {
            // Manejo de error silencioso
        } finally {
            setIsCalculating(false);
        }
    };

    const calcularEstructuraInterna = async (titulos: Titulo[]): Promise<Titulo[]> => {
        let titulosOrdenados = [...titulos].sort((a, b) => a.orden - b.orden);
        titulosOrdenados = titulosOrdenados.map((titulo, index) => ({
            ...titulo,
            orden: index + 1
        }));

        for (let i = 0; i < titulosOrdenados.length; i++) {
            const titulo = titulosOrdenados[i];

            if (titulo.tipo === 'PARTIDA' && titulo.nivel === 1) {
                titulo.nivel = 2;
            }

            if (titulo.tipo === 'PARTIDA') {
                const tieneHijos = titulosOrdenados.some(t => t.id_titulo_padre === titulo.id_titulo);
                if (tieneHijos) {
                    const padreId = titulo.id_titulo_padre;
                    titulosOrdenados = titulosOrdenados.map(t => {
                        if (t.id_titulo_padre === titulo.id_titulo) {
                            return { ...t, id_titulo_padre: padreId };
                        }
                        return t;
                    });
                }
            }

            if (titulo.tipo === 'PARTIDA' && titulo.id_titulo_padre) {
                const padre = titulosOrdenados.find(t => t.id_titulo === titulo.id_titulo_padre);
                if (padre?.tipo === 'PARTIDA') {
                    const ancestroTitulo = titulosOrdenados.find(t =>
                        t.id_titulo === padre.id_titulo_padre && t.tipo === 'TITULO'
                    );
                    titulo.id_titulo_padre = ancestroTitulo?.id_titulo || null;
                }
            }

            if (titulo.tipo === 'TITULO') {
                const padreInmediato = titulosOrdenados.find(t => t.id_titulo === titulo.id_titulo_padre);
                if (padreInmediato?.tipo === 'PARTIDA') {
                    titulo.nivel = padreInmediato.nivel;
                    titulo.id_titulo_padre = padreInmediato.id_titulo_padre;
                }
            }

            if (i === 0) {
                titulo.nivel = titulo.tipo === 'PARTIDA' ? 2 : 1;
                continue;
            }

            const nivelAnterior = titulosOrdenados[i - 1].nivel;
            if (titulo.nivel > nivelAnterior + 1) {
                titulo.nivel = nivelAnterior + 1;
            }
        }

        return titulosOrdenados.map((titulo, index) => {
            const item = calcularItem(titulo, titulosOrdenados.slice(0, index));
            const padre = encontrarPadreApropiado(titulo, titulosOrdenados.slice(0, index));

            return {
                ...titulo,
                item,
                id_titulo_padre: padre?.id_titulo || null
            };
        });
    };

    const calcularItem = (titulo: Titulo, titulosAnteriores: Titulo[]): string => {
        if (titulo.nivel === 1) {
            const titulosNivel1Anteriores = titulosAnteriores.filter(t => t.nivel === 1);
            const numeroSecuencial = titulosNivel1Anteriores.length + 1;
            return numeroSecuencial.toString().padStart(2, '0');
        } else {
            const padre = encontrarPadreApropiado(titulo, titulosAnteriores);
            if (!padre) return '01';

            const hermanos = titulosAnteriores.filter(t =>
                t.nivel === titulo.nivel &&
                t.id_titulo_padre === padre.id_titulo
            );

            const siguienteNumero = hermanos.length + 1;
            return `${padre.item}.${siguienteNumero.toString().padStart(2, '0')}`;
        }
    };

    const encontrarPadreApropiado = (titulo: Titulo, titulosAnteriores: Titulo[]): Titulo | null => {
        for (const posiblePadre of [...titulosAnteriores].reverse()) {
            if (posiblePadre.nivel === titulo.nivel - 1) {
                return posiblePadre;
            }
        }
        return null;
    };

    const getTitleColor = (nivel: number, tipo: string) => {
        if (tipo === 'PARTIDA') return 'text-gray-900';
        switch (nivel) {
            case 1: return 'text-blue-600';
            case 2: return 'text-orange-500';
            case 3: return 'text-red-800';
            case 4: return 'text-pink-500';
            case 5: return 'text-cyan-500';
            default: return 'text-gray-900';
        }
    };

    const tieneHijos = (tituloId: string, titulos: Titulo[]): boolean => {
        return titulos.some(t => t.id_titulo_padre === tituloId);
    };

    const esHijoDeTituloColapsado = (titulo: Titulo, titulos: Titulo[]): boolean => {
        try {
            let currentTitulo = titulo;
            let depth = 0;
            const maxDepth = 10;

            while (currentTitulo.id_titulo_padre && depth < maxDepth) {
                if (titulosColapsados.has(currentTitulo.id_titulo_padre)) {
                    return true;
                }
                const padre = titulos.find(t => t.id_titulo === currentTitulo.id_titulo_padre);
                if (!padre) break;
                currentTitulo = padre;
                depth++;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    const handleToggleColapso = (tituloId: string) => {
        setTitulosColapsados(prev => {
            const newSet = new Set(prev);
            if (newSet.has(tituloId)) {
                newSet.delete(tituloId);
            } else {
                newSet.add(tituloId);
            }
            return newSet;
        });
    };

    const handleDelete = async (tituloId: string) => {
        if (!primerAccionGrupo) setPrimerAccionGrupo('SEGUNDO_GRUPO');
        setTituloToDelete(tituloId);
        setShowDeleteAlert(true);
    };

    const confirmDelete = async () => {
        if (tituloToDelete) {
            await dispatch(deleteTitulo(tituloToDelete));
            setShowDeleteAlert(false);
            setTituloToDelete(null);
        }
    };

    const sortedTitulos = [...titulos].sort((a, b) => {
        return (a.orden || 0) - (b.orden || 0);
    });
    
    const titulosFiltrados = (modoEdicion ? titulosEnEdicion : sortedTitulos).filter(titulo => {
        return modoEdicion || !esHijoDeTituloColapsado(titulo, sortedTitulos);
    });

    if (loading) {
        return <LoaderOverlay message="Cargando títulos..." />;
    }

    if (isCalculating) {
        return <LoaderPage />;
    }

    return (
        <div className="mx-auto w-full h-[calc(100vh-7rem)] bg-white/50 rounded-xl shadow-sm border border-gray-200 flex flex-col p-4">
            <div className="bg-gray-100 p-2 rounded-t-xl border-b px-4 border-gray-200 flex flex-col gap-1 sticky top-0 z-20">
                <h2 className="font-semibold text-sm text-gray-800 ">
                    {activePresupuesto?.nombre_presupuesto.toUpperCase()}
                </h2>
                <div className="flex gap-1">

                    {!modoEdicion ? (
                        <>
                            <button
                                onClick={() => setModoEdicion(true)}
                                className="px-2 py-0.5 bg-yellow-600 text-white rounded text-xs hover:bg-yellow-700 flex items-center gap-1"
                            >
                                Editar Estructura
                            </button>
                            <button
                                onClick={() => dispatch(setEditMode(false))}
                                className="px-2 py-0.5 bg-blue-600 text-white rounded text-xs hover:bg-blue-700 flex items-center gap-1"
                            >
                                Volver a Presupuesto
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={calcularEstructura}
                                className="px-2 py-0.5 bg-green-600 text-white rounded text-xs hover:bg-green-700 flex items-center gap-1"
                            >
                                Guardar Estructura
                            </button>
                            <button
                                onClick={() => {
                                    setModoEdicion(false);
                                    setTitulosEnEdicion([...titulos]);
                                }}
                                className="px-2 py-0.5 bg-red-600 text-white rounded text-xs hover:bg-red-700 flex items-center gap-1"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={() => handleCreate(undefined, undefined, 'TITULO')}
                                className="px-2 py-0.5 bg-blue-600 text-white rounded text-xs hover:bg-blue-700 flex items-center gap-1"
                            >
                                Nuevo Título al final
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className="overflow-x-auto rounded-b-xl flex-1 relative bg-gray-200">
                <div className=" min-w-full divide-y bg-yellow-50/50 divide-neutral-200 text-[11px] p-4 h-[calc(100vh-13rem)] overflow-y-auto">
                    {titulosFiltrados.map((titulo, index, array) => (
                        <div
                            key={titulo.id_titulo}
                            className={`flex justify-between items-center px-3 py-0 rounded-md shadow-md border-b border-gray-200
                                ${index % 2 === 1 ? 'bg-gray-200' : 'bg-white'}
                                ${modoEdicion ? 'hover:bg-gray-50' : ''} 
                                ${tituloMoviendose === titulo.id_titulo ? 'ring-2 ring-blue-200 bg-blue-50' : ''}`}
                            style={{ paddingLeft: `${(titulo.nivel + 1) * 20}px` }}
                        >
                            <div className="flex items-center gap-x-2 py-2 ">
                                {!modoEdicion && tieneHijos(titulo.id_titulo, sortedTitulos) && (
                                    <button
                                        onClick={() => handleToggleColapso(titulo.id_titulo)}
                                        className="w-4 h-4 flex items-center justify-center text-gray-500 hover:text-gray-700"
                                    >
                                        {titulosColapsados.has(titulo.id_titulo) ? '►' : '▼'}
                                    </button>
                                )}
                                <span className="text-gray-500 w-14">{titulo.item}</span>
                                <span className={`${titulo.tipo === 'PARTIDA' ? 'text-gray-900' : getTitleColor(titulo.nivel, titulo.tipo)}`}>
                                    {titulo.descripcion}
                                </span>
                            </div>

                            <div className="flex items-center gap-1">
                                {modoEdicion && (
                                    <>
                                        {(!primerAccionGrupo || primerAccionGrupo === 'SEGUNDO_GRUPO') && titulo.tipo === 'TITULO' && (
                                            <>
                                                <button
                                                    onClick={() => handleCreate(titulo.id_titulo, titulo.orden, 'TITULO')}
                                                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-white bg-green-600 border border-transparent rounded hover:bg-green-700"
                                                    title="Agregar Título"
                                                >
                                                    + T
                                                </button>
                                                <button
                                                    onClick={() => handleCreate(titulo.id_titulo, titulo.orden, 'PARTIDA')}
                                                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-white bg-blue-600 border border-transparent rounded hover:bg-blue-700"
                                                    title="Agregar Partida"
                                                >
                                                    + P
                                                </button>
                                            </>
                                        )}
                                        {(!primerAccionGrupo || primerAccionGrupo === 'SEGUNDO_GRUPO') && (
                                            <>
                                                <button
                                                    onClick={() => handleDelete(titulo.id_titulo)}
                                                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-white bg-red-600 border border-transparent rounded hover:bg-red-700"
                                                >
                                                    <FiTrash2 />
                                                </button>
                                                <button
                                                    onClick={() => handleEdit(titulo)}
                                                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50"
                                                >
                                                    Editar
                                                </button>
                                            </>
                                        )}
                                        {(!primerAccionGrupo || primerAccionGrupo === 'PRIMER_GRUPO') && (
                                            <>
                                                <button
                                                    onClick={() => handleNivelChange(titulo.id_titulo, false)}
                                                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 bg-yellow-300 border border-gray-300 rounded hover:bg-yellow-500"
                                                >
                                                    {'<'}
                                                </button>
                                                <button
                                                    onClick={() => handleNivelChange(titulo.id_titulo, true)}
                                                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-700 bg-yellow-300 border border-gray-300 rounded hover:bg-yellow-500"
                                                >
                                                    {'>'}
                                                </button>
                                                <button
                                                    onClick={() => handleMoverOrden(titulo.id_titulo, 'arriba')}
                                                    disabled={index === 0}
                                                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-100 bg-sky-600 border border-gray-300 rounded hover:bg-sky-500 disabled:opacity-50"
                                                >
                                                    ↑
                                                </button>
                                                <button
                                                    onClick={() => handleMoverOrden(titulo.id_titulo, 'abajo')}
                                                    disabled={index === array.length - 1}
                                                    className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-100 bg-sky-600 border border-gray-300 rounded hover:bg-sky-500 disabled:opacity-50"
                                                >
                                                    ↓
                                                </button>
                                            </>
                                        )}
                                        
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal
                isOpen={showTituloForm}
                onClose={() => {
                    setShowTituloForm(false);
                    setEditingTitulo(null);
                    setParentId(undefined);
                    setOrdenCreate(undefined);
                }}
                title={editingTitulo ? "Editar Título" : "Nuevo Título"}
                headerBgColor="bg-blue-900"
            >
                <TituloForm
                    titulo={editingTitulo}
                    titulos={titulos}
                    tituloParentId={parentId}
                    ordenCreate={ordenCreate}
                    tipo={tipoTitulo}
                    onSubmit={handleSubmit}
                    onCancel={() => {
                        setShowTituloForm(false);
                        setEditingTitulo(null);
                        setParentId(undefined);
                        setOrdenCreate(undefined);
                    }}
                />
            </Modal>

            <ModalAlert
                isOpen={showDeleteAlert}
                title="Eliminar Título"
                message="¿Está seguro que desea eliminar este título? Esta acción no se puede deshacer."
                onConfirm={confirmDelete}
                onCancel={() => {
                    setShowDeleteAlert(false);
                    setTituloToDelete(null);
                }}
                variant="red"
            />
        </div>
    );
};

export default TitulosJerarquia;
