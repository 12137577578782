import React, { useState, useEffect } from 'react';
import { FiEye, FiFileText, FiPlus, FiTrash2, FiLoader, FiCheck, FiX, FiClock, FiCheckCircle, FiXCircle } from 'react-icons/fi';
import Modal from '../../../components/Modal/Modal';
//import TableComponent from '../../../components/Table/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchOrdenCompras } from '../../../slices/ordenCompraSlice';
import PDFIngresoAlmacenGenerator from './PDFIngresoAlmacenGenerator';
import { fetchOrdenCompraRecursosByOrdenId } from '../../../slices/ordenCompraRecursosSlice';
import ListConstancias from './ListConstancias';
import { fetchConstanciasForOrden, fetchIngresosAlmacenForOrden } from '../../../slices/ordenPagoSlice';
import SubirComprobante from './SubirComprobante';
import ListOrdenIngreso from './ListOrdenIngreso';
import ListOrdenesCompra from './ListOrdenesCompra';
import { addRegistro } from '../../../slices/registroSlice';
import { addConstanciaComprobante } from '../../../slices/constanciaComprobanteSlice';
import { addIngresoAlmacenComprobante } from '../../../slices/ingresoAlmacenComprobanteSlice';
import { addComprobanteOrdenCompra } from '../../../slices/comprobanteOrdenCompraSlice';
// Agregar import de Toast
import Toast from '../../../components/Toast/Toast';
import { fetchDetalleComprobanteOrdenCompra, fetchDetalleConstancia, fetchDetalleIngresoAlmacen, findAllByIdComprobante } from '../../../slices/comprobanteSlice';
// 1. Importar el nuevo componente
import PDFOCGenerator from '../../OrdenCompraPage/PDFOCGenerator';
import { fetchAprobacionesByComprobante, addAprobacion } from '../../../slices/aprobacionComprobanteSlice';
import { addAprobacionOrdenCompra } from '../../../slices/aprobacionComprobanteOrdenesCompraSlice';
import { addAprobacionConstancia } from '../../../slices/aprobacionConstanciasComprobanteSlice';
import { addAprobacionIngresoAlmacen } from '../../../slices/aprobacionIngresoAlmacenComprobanteSlice';

interface ConstanciaEntry {
    id: string;
    codigo: string;
    monto: number;
    archivo_pago_id: string | null; // Asegurarnos de que este campo esté definido
    pago_orden_id: string | null;
    estado?: string; // Agregar estado
    fecha_aprobacion?: string; // Agregar fecha de aprobación
}

// Agregar nueva interfaz junto a ConstanciaEntry
interface IngresoAlmacenEntry {
    id: string;
    transferencia_id: string;
    monto: number;
    detalle_id: string; // Agregar este campo
    estado?: string; // Agregar estado
    fecha_aprobacion?: string; // Agregar fecha de aprobación
}

// Agregar nueva interfaz para las órdenes de compra
interface OrdenCompraEntry {
    id: string;
    codigo: string;
    monto: number;
    cod?: string; // Hacerlo opcional agregando ?
    estado?: string; // Agregar estado
}

interface EditarRegistroProps {
    onClose: () => void;
    onSaveSuccess: () => void;
    comprobante: {
        id: string;
        estado: string;
        codigo: string;
        registrado?: string; // Añadir el nuevo campo
    };
}

const EditarRegistro: React.FC<EditarRegistroProps> = ({ onClose, onSaveSuccess, comprobante }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedOC, setSelectedOC] = useState<any>(null);
    const [isConstanciaModalOpen, setIsConstanciaModalOpen] = useState(false);
    const [isSubirComprobanteOpen, setIsSubirComprobanteOpen] = useState(false);
    const [isIngresoAlmacenModalOpen, setIsIngresoAlmacenModalOpen] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { ordenCompras } = useSelector((state: RootState) => state.ordenCompra);
    const [ordenCompraInput] = useState('');
    const [constanciaEntries, setConstanciaEntries] = useState<ConstanciaEntry[]>([]);
    const [documentoInput, setDocumentoInput] = useState('');
    const [documentoMonto, setDocumentoMonto] = useState('');
    const [ingresoAlmacenEntries, setIngresoAlmacenEntries] = useState<IngresoAlmacenEntry[]>([]);
    const [ordenCompraEntries, setOrdenCompraEntries] = useState<OrdenCompraEntry[]>([]);
    const [documentoId, setDocumentoId] = useState<string>('');
    const [documentoInfo, setDocumentoInfo] = useState({
        ruc: '',
        razonSocial: '',
        tipoComprobante: '',
        fechaEmision: '',
        fechaVencimiento: '',
        montoSinIgv: '',
        serie: '',
        monto: '',
        condicionPago: '',
        agenteRetencion: '',
        descripcion: ''
      });

    const userId = useSelector((state: RootState) => state.user.id);
    const nombreUsuario = useSelector((state: RootState) => state.user.usuario);
    const { ordenCompraRecursosByOrdenId } = useSelector((state: RootState) => state.ordenCompraRecursos);
    const { constancias, loadingConstancias } = useSelector((state: RootState) => state.ordenPago);

    // Agregar estado para Toast
    const [toast, setToast] = useState<{
        show: boolean;
        message: string;
        variant: 'success' | 'danger' | 'warning' | 'info';
      }>({
        show: false,
        message: '',
        variant: 'info'
      });
    
      // Agregar función helper para mostrar Toast
      const showToast = (message: string, variant: 'success' | 'danger' | 'warning' | 'info') => {
        setToast({ show: true, message, variant });
      };

    // Añadir selector para el detalle de orden compra
    const { detalleOrdenCompra } = useSelector((state: RootState) => state.comprobante);

    // Añadir selector para el detalle de constancias
    const { detalleConstancia } = useSelector((state: RootState) => state.comprobante);

    // Añadir selector para el detalle de ingreso almacén
    const { detalleIngresoAlmacen } = useSelector((state: RootState) => state.comprobante);

    // Añadir selector para datos del comprobante
    const { comprobantes: comprobanteData } = useSelector((state: RootState) => state.comprobante);

    // Cambiar el selector
    const { aprobacionesComprobante } = useSelector((state: RootState) => state.aprobacionComprobante);

    useEffect(() => {
        // Cargar el detalle cuando se monta el componente
        if (comprobante?.id) {
            dispatch(fetchDetalleComprobanteOrdenCompra(comprobante.id));
            // Cargar constancias
            dispatch(fetchDetalleConstancia(comprobante.id));
            // Agregar carga de detalles de ingreso almacén
            dispatch(fetchDetalleIngresoAlmacen(comprobante.id));
            // Cargar datos del comprobante
            dispatch(findAllByIdComprobante(comprobante.id));
            dispatch(fetchAprobacionesByComprobante(comprobante.id));
        }
    }, [dispatch, comprobante?.id]);

    // Helper function para verificar el estado
    const getAprobacionEstado = () => {
        if (aprobacionesComprobante && aprobacionesComprobante.length > 0) {
            return aprobacionesComprobante[0].estado;
        }
        return null;
    };

    // Añadir efecto para actualizar ordenCompraEntries cuando se carga el detalle
    useEffect(() => {
        if (detalleOrdenCompra.length > 0) {
            const entries = detalleOrdenCompra.map(detalle => ({
                cod: Math.random().toString(36).substr(2, 9), // Generar ID único
                id: detalle.comprobante_ordenes_compra_id,
                codigo: detalle.codigo,
                monto: detalle.monto,
                estado: detalle.aprobacion_comprobante_ordenes_compra?.estado // Agregar estado
            }));
            setOrdenCompraEntries(entries);
        }
    }, [detalleOrdenCompra]);

    // Añadir efecto para actualizar constanciaEntries cuando se carga el detalle
    useEffect(() => {
        if (detalleConstancia.length > 0) {
            const entries = detalleConstancia.map(detalle => ({
                id: Math.random().toString(36).substr(2, 9), // Generar ID único
                codigo: detalle.codigo,
                monto: detalle.pago_orden_id?.monto || 0,
                archivo_pago_id: detalle.archivo_pago_id?.file || null,
                pago_orden_id: detalle.pago_id || null,
                estado: detalle.aprobacion_constancias_comprobante?.estado, // Agregar estado
                fecha_aprobacion: detalle.aprobacion_constancias_comprobante?.fecha // Agregar fecha
            }));
            setConstanciaEntries(entries);
        }
    }, [detalleConstancia]);

    // Añadir efecto para actualizar ingresoAlmacenEntries cuando se carga el detalle
    useEffect(() => {
        if (detalleIngresoAlmacen.length > 0) {
            const entries = detalleIngresoAlmacen.map(detalle => ({
                id: Math.random().toString(36).substr(2, 9),
                transferencia_id: detalle.transferencia_id,
                monto: detalle.monto,
                detalle_id: detalle.transferencia_detalle_id,
                estado: detalle.aprobacion_ingreso_almacen?.estado, // Agregar estado
                fecha_aprobacion: detalle.aprobacion_ingreso_almacen?.fecha // Agregar fecha
            }));
            setIngresoAlmacenEntries(entries);
        }
    }, [detalleIngresoAlmacen]);

    // Añadir efecto para actualizar documentoInput y documentoMonto cuando se carga el comprobante
    useEffect(() => {
        if (comprobanteData && comprobanteData.length > 0) {
            const data = comprobanteData[0]; // Tomamos el primer comprobante
            setDocumentoInput(data.serie || '');
            setDocumentoMonto(data.monto?.toString() || '0');
            
            // También actualizamos documentoInfo si es necesario
            setDocumentoInfo(prev => ({
                ...prev,
                serie: data.serie || '',
                monto: data.monto?.toString() || '0',
                tipoComprobante: data.comprobante_tipo_id?.id || '',
                fechaEmision: data.fecha_vencimiento || '',
                // ...otros campos que necesites actualizar
            }));
        }
    }, [comprobanteData]);

    useEffect(() => {
        if (isModalOpen) {
            dispatch(fetchOrdenCompras());
        }
    }, [dispatch, isModalOpen]);


    const handleAddDocument = (oc: any) => {
        setSelectedOC(oc);
        setIsConfirmModalOpen(true);
    };

    // Modificar la función de cálculo
    const calcularMontoTotal = async (ordenCompraId: string) => {
        // Primero obtener los recursos de la orden de compra
        await dispatch(fetchOrdenCompraRecursosByOrdenId(ordenCompraId));
        
        // Calcular el total sumando los montos reales
        const total = ordenCompraRecursosByOrdenId.reduce((sum, recurso) => {
            const montoReal = Number(recurso.costo_real) * Number(recurso.cantidad) || 0;
            return sum + montoReal;
        }, 0);
        return total.toFixed(2);
    };

    // Modificar handleConfirmAdd para incluir cod
    const handleConfirmAdd = async () => {
        if (selectedOC) {
            const montoCalculado = await calcularMontoTotal(selectedOC.id);
            const newEntry: OrdenCompraEntry = {
                id: selectedOC.id,
                codigo: selectedOC.codigo_orden,
                monto: Number(montoCalculado),
                cod: Math.random().toString(36).substr(2, 9) // Agregar cod al crear nueva entrada
            };
            setOrdenCompraEntries([...ordenCompraEntries, newEntry]);
        }
        setIsConfirmModalOpen(false);
        setSelectedOC(null);
        setIsModalOpen(false);
    };

    // Agregar función para eliminar orden de compra
    const handleRemoveOrdenCompra = (id: string) => {
        setOrdenCompraEntries(entries => entries.filter(entry => entry.id !== id));
    };

    // Calcular total de órdenes de compra
    const totalOrdenesCompra = ordenCompraEntries.reduce((sum, entry) => sum + entry.monto, 0);

    const handleConstanciaClick = () => {
        // Verificamos si hay órdenes de compra agregadas
        if (ordenCompraEntries.length === 0) {
            showToast('Por favor agregue al menos una orden de compra primero', 'warning');
            return;
        }

        // Usamos la última orden de compra agregada para obtener las constancias
        const ultimaOrden = ordenCompras.find(oc => 
            oc.codigo_orden === ordenCompraEntries[ordenCompraEntries.length - 1].codigo
        );

        if (!ultimaOrden) {
            showToast('No se encontró la orden de compra', 'danger');
            return;
        }

        // Establecemos la orden seleccionada y despachamos la acción
        setSelectedOC(ultimaOrden);
        dispatch(fetchConstanciasForOrden(ultimaOrden.id));
        setIsConstanciaModalOpen(true);
    };

    const handleConstanciaSelect = (constancia: any) => {
        console.log("Constancia recibida:", constancia);
        
        const newEntry = {
            id: Math.random().toString(36).substr(2, 9),
            codigo: constancia.codigo,
            monto: constancia.monto_pago || 0,
            archivo_pago_id: constancia.archivo_pago_id,
            pago_orden_id: constancia.pago_orden_id
        };
        
        console.log("Nueva entrada creada:", newEntry);
        setConstanciaEntries([...constanciaEntries, newEntry]);
        setIsConstanciaModalOpen(false);
    };

    const handleRemoveConstancia = (id: string) => {
        setConstanciaEntries(entries => entries.filter(entry => entry.id !== id));
    };

    // Agregar un cálculo del total
    const totalConstancias = constanciaEntries.reduce((sum, entry) => sum + entry.monto, 0);
    const montoRestanteConstancias = totalOrdenesCompra - totalConstancias;

    const handleDocumentoClick = () => {
        // Verificamos si hay órdenes de compra agregadas
        if (ordenCompraEntries.length === 0) {
            showToast('Por favor agregue al menos una orden de compra primero', 'warning');
            return;
        }

        // Usamos la última orden de compra agregada
        const ultimaOrden = ordenCompras.find(oc => 
            oc.codigo_orden === ordenCompraEntries[ordenCompraEntries.length - 1].codigo
        );

        if (!ultimaOrden) {
            showToast('No se encontró la orden de compra', 'danger');
            return;
        }
        setIsSubirComprobanteOpen(true);
    };

    const handleIngresoAlmacenClick = () => {
        // Verificamos si hay órdenes de compra agregadas
        if (ordenCompraEntries.length === 0) {
            showToast('Por favor agregue al menos una orden de compra primero', 'warning');
            return;
        }

        // Usamos la última orden de compra agregada
        const ultimaOrden = ordenCompras.find(oc => 
            oc.codigo_orden === ordenCompraEntries[ordenCompraEntries.length - 1].codigo
        );

        if (!ultimaOrden) {
            showToast('No se encontró la orden de compra', 'danger');
            return;
        }
        dispatch(fetchIngresosAlmacenForOrden(ultimaOrden.id));
        setIsIngresoAlmacenModalOpen(true);
    };

    // Modificar la función handleIngresoSelect
    const handleIngresoSelect = (ingreso: any) => {
        const newEntry = {
            id: Math.random().toString(36).substr(2, 9),
            transferencia_id: ingreso.transferencia_id,
            monto: ingreso.monto_total,
            detalle_id: ingreso._id // Agregar el _id del ingreso
        };
        console.log("Nuevo ingreso agregado:", newEntry); // Para debugging
        setIngresoAlmacenEntries([...ingresoAlmacenEntries, newEntry]);
        setIsIngresoAlmacenModalOpen(false);
    };

    // Agregar función para eliminar ingreso
    const handleRemoveIngreso = (id: string) => {
        setIngresoAlmacenEntries(entries => entries.filter(entry => entry.id !== id));
    };

    // Agregar cálculo del total de ingresos
    const totalIngresos = ingresoAlmacenEntries.reduce((sum, entry) => sum + entry.monto, 0);
    const montoRestanteIngresos = totalOrdenesCompra - totalIngresos;

    const montoPendienteDocumento = totalOrdenesCompra - Number(documentoMonto);

    // Modificar el handleComprobanteCreated para manejar el ID
    const handleComprobanteCreated = (comprobanteId: string, serie: string, monto: number, documentoData: any) => {
        setDocumentoId(comprobanteId);
        setDocumentoInput(serie);
        setDocumentoMonto(monto.toString());
        setDocumentoInfo(documentoData);
      };

    const validateFields = (): boolean => {
        if (ordenCompraEntries.length === 0) {
          showToast('Debe agregar al menos una orden de compra', 'warning');
          return false;
        }
    
        if (!documentoId) {
          showToast('Debe subir un comprobante', 'warning');
          return false;
        }
    
        // Validar que el monto total de constancias no exceda el monto total de órdenes
        // if (totalConstancias > totalOrdenesCompra) {
        //   showToast('El monto total de constancias no puede exceder el monto total de órdenes de compra', 'warning');
        //   return false;
        // }
    
        // Validar que el monto total de ingresos no exceda el monto total de órdenes
        if (totalIngresos > totalOrdenesCompra) {
          showToast('El monto total de ingresos no puede exceder el monto total de órdenes de compra', 'warning');
          return false;
        }
    
        return true;
      };
    
      const [isSaving, setIsSaving] = useState(false);

      const handleSave = async (estado: 'PENDIENTE' | 'REGISTRADO') => {
        if (!validateFields()) return;
    
        // Validar que el usuario exista
        if (!userId) {
          showToast('No se ha encontrado un usuario válido', 'warning');
          return;
        }
    
        setIsSaving(true); // Activar loading

        try {
          // 1. Guardar el registro principal con el userId validado
          const registroData = {
            user_id: userId, // Ahora estamos seguros que no es null
            comprobante_id: documentoId,
            estado: estado,
            monto_total: totalOrdenesCompra,
            fecha_registro: new Date().toISOString()
          };
    
         await dispatch(addRegistro(registroData)).unwrap(); //Guardar el registro principal
          
          // 2. Crear las relaciones con las órdenes de compra
          const ordenCompraPromises = ordenCompraEntries.map(orden => 
            dispatch(addComprobanteOrdenCompra({
              comprobante_id: documentoId,
              orden_compra_id: orden.id
            })).unwrap()
          );
    
          // 3. Guardar las constancias
          const constanciaPromises = constanciaEntries.map(constancia => 
            dispatch(addConstanciaComprobante({
              comprobante_id: documentoId,
              archivos_pago_id: constancia.archivo_pago_id || '',
              pagos_orden_pago_id: constancia.pago_orden_id || ''
    
            })).unwrap()
          );
    
          // 4. Guardar los ingresos de almacén con el detalle_id
          const ingresoPromises = ingresoAlmacenEntries.map(ingreso => 
            dispatch(addIngresoAlmacenComprobante({
              comprobante_id: documentoId,
              transferencia_detalle_id: ingreso.detalle_id, // Usar el detalle_id guardado
            })).unwrap()
          );
    
          // Esperar a que todas las promesas se resuelvan
          await Promise.all([
            ...ordenCompraPromises,
            ...constanciaPromises, 
            ...ingresoPromises
          ]);
    
          showToast(
            estado === 'PENDIENTE' ? 'Registro guardado con éxito' : 'Registro completado con éxito',
            'success'
          );
          
          // Cerrar modal y actualizar datos después de 1 segundo
          setTimeout(() => {
            onSaveSuccess(); // Actualizar datos
            onClose(); // Cerrar modal
          }, 1000);
          
        } catch (error) {
          console.error('Error detallado:', error); // Mejorar el logging de errores
          showToast('Error al guardar el registro: ' + (error as Error).message, 'danger');
        } finally {
          setIsSaving(false); // Desactivar loading
        }
      };

    // Modificar la función formatComprobanteDataForEdit
    const formatComprobanteDataForEdit = () => {
        if (comprobanteData && comprobanteData.length > 0) {
          const data = comprobanteData[0];
          // Formatear las fechas a YYYY-MM-DD
          const formatDate = (dateString: string) => {
            if (!dateString) return '';
            return new Date(dateString).toISOString().split('T')[0];
          };
      
          return {
           // ruc: data.ruc || '',
          //  razonSocial: data.razon_social || '',
            tipoComprobante: data.comprobante_tipo_id?.id || '',
            fechaEmision: formatDate(data.fecha_vencimiento),
            fechaVencimiento: formatDate(data.fecha_creacion),
           // montoSinIgv: data.monto_sin_igv?.toString() || '0',
            serie: data.serie || '',
            monto: data.monto?.toString() || '0',
           // descripcion: data.descripcion || '',
            id: documentoId,
            estado: comprobante.estado,
            comprobanteData: data
          };
        }
        return documentoInfo;
      };

    // Añadir estos estados al inicio del componente (junto a los otros estados)
    const [selectedVoucher, setSelectedVoucher] = useState<string | null>(null);
    const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false);

    // Añadir esta función después de las otras funciones handle
    const handleViewVoucher = (archivo_pago_id: string | null) => {
        if (archivo_pago_id) {
            setSelectedVoucher(archivo_pago_id);
            setIsVoucherModalOpen(true);
        }
    };

    // Agregar estos estados al inicio del componente junto a los demás estados
    const [selectedIngresoAlmacen, setSelectedIngresoAlmacen] = useState<any>(null);
    const [isIngresoAlmacenPDFModalOpen, setIsIngresoAlmacenPDFModalOpen] = useState(false);

    // Agregar esta función para manejar la vista del ingreso almacén
    const handleViewIngresoAlmacen = async (detalleId: string) => {
        try {
            // Buscar el detalle en el estado actual
            const ingreso = detalleIngresoAlmacen.find(
                item => item.transferencia_detalle_id === detalleId
            );
            
            if (ingreso) {
                setSelectedIngresoAlmacen(ingreso);
                setIsIngresoAlmacenPDFModalOpen(true);
            }
        } catch (error) {
            console.error('Error al obtener detalle de ingreso:', error);
        }
    };

    // 2. Agregar estos estados junto a los otros estados
    const [selectedOrdenCompra, setSelectedOrdenCompra] = useState<any>(null);
    const [isOrdenCompraPDFModalOpen, setIsOrdenCompraPDFModalOpen] = useState(false);

    // 3. Agregar esta función junto a los otros handlers
    const handleViewOrdenCompra = async (ordenId: string) => {
        try {
            const ordenDetalle = detalleOrdenCompra.find(
                item => item.comprobante_ordenes_compra_id === ordenId
            );
            
            if (ordenDetalle) {
                setSelectedOrdenCompra(ordenDetalle);
                setIsOrdenCompraPDFModalOpen(true);
            }
        } catch (error) {
            console.error('Error al obtener detalle de orden:', error);
        }
    };

    // Dentro del componente, añadir esta constante
    const isRegistradoMode = comprobante.registrado === 'REGISTRADO';

    // Añadir manejadores para aprobar/rechazar
    const handleApproveOrden = async (ordenId: string) => {
        try {
            const orden = detalleOrdenCompra.find(
                item => item.comprobante_ordenes_compra_id === ordenId
            );

            if (orden && userId) {
                await dispatch(addAprobacionOrdenCompra({
                    comprobante_ordenes_compra_id: orden.comprobante_ordenes_compra_id,
                    usuario_id: userId,
                    estado: 'APROBADO',
                    fecha: new Date()
                })).unwrap();

                // Actualizar el estado local
                setOrdenCompraEntries(entries =>
                    entries.map(entry =>
                        entry.id === ordenId 
                            ? { ...entry, estado: 'APROBADO' }
                            : entry
                    )
                );

                showToast('Orden aprobada exitosamente', 'success');
            }
        } catch (error) {
            console.error('Error al aprobar:', error);
            showToast('Error al aprobar la orden', 'danger');
        }
    };

    const handleRejectOrden = async (ordenId: string) => {
        try {
            const orden = detalleOrdenCompra.find(
                item => item.comprobante_ordenes_compra_id === ordenId
            );

            if (orden && userId) {
                await dispatch(addAprobacionOrdenCompra({
                    comprobante_ordenes_compra_id: orden.comprobante_ordenes_compra_id,
                    usuario_id: userId,
                    estado: 'RECHAZADO',
                    fecha: new Date()
                })).unwrap();

                // Actualizar el estado local
                setOrdenCompraEntries(entries =>
                    entries.map(entry =>
                        entry.id === ordenId 
                            ? { ...entry, estado: 'RECHAZADO' }
                            : entry
                    )
                );

                showToast('Orden rechazada', 'warning');
            }
        } catch (error) {
            console.error('Error al rechazar:', error);
            showToast('Error al rechazar la orden', 'danger');
        }
    };

    // Añadir manejadores para aprobar/rechazar constancias
    const handleApproveConstancia = async (constanciaId: string) => {
        try {
            const constancia = detalleConstancia.find(
                item => item.comprobante_id === constanciaId
            );

            if (constancia && userId) {
                await dispatch(addAprobacionConstancia({
                    constancias_comprobante_id: constancia.comprobante_id,
                    usuario_id: userId,
                    estado: 'APROBADO',
                    fecha: new Date()
                })).unwrap();

                // Actualizar el estado local
                setConstanciaEntries(entries =>
                    entries.map(entry =>
                        entry.id === constanciaId 
                            ? { ...entry, estado: 'APROBADO' }
                            : entry
                    )
                );

                showToast('Constancia aprobada exitosamente', 'success');
            }
        } catch (error) {
            console.error('Error al aprobar:', error);
            showToast('Error al aprobar la constancia', 'danger');
        }
    };

    const handleRejectConstancia = async (constanciaId: string) => {
        try {
            const constancia = detalleConstancia.find(
                item => item.comprobante_id === constanciaId
            );

            if (constancia && userId) {
                await dispatch(addAprobacionConstancia({
                    constancias_comprobante_id: constancia.comprobante_id,
                    usuario_id: userId,
                    estado: 'RECHAZADO',
                    fecha: new Date()
                })).unwrap();

                // Actualizar el estado local
                setConstanciaEntries(entries =>
                    entries.map(entry =>
                        entry.id === constanciaId 
                            ? { ...entry, estado: 'RECHAZADO' }
                            : entry
                    )
                );

                showToast('Constancia rechazada', 'warning');
            }
        } catch (error) {
            console.error('Error al rechazar:', error);
            showToast('Error al rechazar la constancia', 'danger');
        }
    };

    // Añadir manejadores para aprobar/rechazar comprobante
    const handleApproveComprobante = async () => {
        try {
            if (documentoId && userId) {
                await dispatch(addAprobacion({
                    comprobante_id: documentoId,
                    usuario_id: userId,
                    estado: 'APROBADO',
                    fecha: new Date()
                })).unwrap();

                // Refrescar los datos del comprobante
                await dispatch(fetchAprobacionesByComprobante(documentoId));
                showToast('Comprobante aprobado exitosamente', 'success');
            }
        } catch (error) {
            console.error('Error al aprobar:', error);
            showToast('Error al aprobar el comprobante', 'danger');
        }
    };

    const handleRejectComprobante = async () => {
        try {
            if (documentoId && userId) {
                await dispatch(addAprobacion({
                    comprobante_id: documentoId,
                    usuario_id: userId,
                    estado: 'RECHAZADO',
                    fecha: new Date()
                })).unwrap();

                // Refrescar los datos del comprobante
                await dispatch(fetchAprobacionesByComprobante(documentoId));
                showToast('Comprobante rechazado', 'warning');
            }
        } catch (error) {
            console.error('Error al rechazar:', error);
            showToast('Error al rechazar el comprobante', 'danger');
        }
    };

    // Añadir manejadores para aprobar/rechazar ingresos de almacén
    const handleApproveIngreso = async (ingresoId: string) => {
        try {
            const ingreso = detalleIngresoAlmacen.find(
                item => item.transferencia_detalle_id === ingresoId
            );

            if (ingreso && userId) {
                await dispatch(addAprobacionIngresoAlmacen({
                    ingreso_almacen_comprobante_id: ingreso.transferencia_detalle_id,
                    usuario_id: userId,
                    estado: 'APROBADO',
                    fecha: new Date()
                })).unwrap();

                // Actualizar el estado local
                setIngresoAlmacenEntries(entries =>
                    entries.map(entry =>
                        entry.detalle_id === ingresoId 
                            ? { ...entry, estado: 'APROBADO' }
                            : entry
                    )
                );

                showToast('Ingreso aprobado exitosamente', 'success');
            }
        } catch (error) {
            console.error('Error al aprobar:', error);
            showToast('Error al aprobar el ingreso', 'danger');
        }
    };

    const handleRejectIngreso = async (ingresoId: string) => {
        try {
            const ingreso = detalleIngresoAlmacen.find(
                item => item.transferencia_detalle_id === ingresoId
            );

            if (ingreso && userId) {
                await dispatch(addAprobacionIngresoAlmacen({
                    ingreso_almacen_comprobante_id: ingreso.transferencia_detalle_id,
                    usuario_id: userId,
                    estado: 'RECHAZADO',
                    fecha: new Date()
                })).unwrap();

                // Actualizar el estado local
                setIngresoAlmacenEntries(entries =>
                    entries.map(entry =>
                        entry.detalle_id === ingresoId 
                            ? { ...entry, estado: 'RECHAZADO' }
                            : entry
                    )
                );

                showToast('Ingreso rechazado', 'warning');
            }
        } catch (error) {
            console.error('Error al rechazar:', error);
            showToast('Error al rechazar el ingreso', 'danger');
        }
    };

    return (
        <div className="w-full max-w-4xl mx-auto">
            {/* Agregar componente Toast */}
            {toast.show && (
                <Toast
                message={toast.message}
                variant={toast.variant}
                duration={3000}
                index={0}
                onClose={() => setToast(prev => ({ ...prev, show: false }))}
                />
            )}
            <div className="bg-white/80 p-6 rounded-lg">
                <div className="max-w-3xl mx-auto">
                    <div className="mb-6 border-2 border-[#192E58] p-2 rounded-lg">
                        <div className="text-gray-700 mb-5">N° Registro: {comprobante.codigo || '00'}</div>
                         {/* Comentar visualización del objeto comprobante */}
                         {/* <div>
                            {JSON.stringify(comprobante)}
                         </div> */}
                        <div className="flex justify-between text-sm text-gray-500 ">
                            <div>F.Creacion: {new Date().toLocaleDateString()}</div>
                            <div>Usuario: {nombreUsuario}</div>
                        </div>
                    </div>

                    <div className="space-y-4">
                        {/* Orden de Compra */}
                        <div className="flex items-center gap-4 mt-4">
                            <div className="flex-1 w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Orden de Compra
                                </label>           
                            </div>
                            <div className="text-center w-32">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Monto
                                </label>
                            </div>
                            <div className="w-32"></div>
                            <div className="w-10"></div>
                        </div>
                         {/* Comentar visualización de ordenCompraEntries */}
                          {JSON.stringify(ordenCompraEntries)} 
                        {ordenCompraEntries.map((entry) => (
                            <div key={entry.cod} className="flex items-center gap-4 py-1"> {/* Cambiado de entry.id a entry.cod */}
                                <div className="flex-1">
                                    <input 
                                        type="text" 
                                        value={entry.codigo}
                                        readOnly
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <input 
                                        type="text" 
                                        value={entry.monto.toFixed(2)}
                                        readOnly
                                        className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div className="flex items-center justify-end space-x-2">
                                    {!isRegistradoMode && (
                                        <button 
                                            onClick={() => handleRemoveOrdenCompra(entry.id)}
                                            className="text-red-500 hover:text-red-700"
                                            title="Eliminar"
                                        >
                                            <FiTrash2 size={20} />
                                        </button>
                                    )}
                                    <button 
                                        onClick={() => handleViewOrdenCompra(entry.id)}
                                        className="text-blue-600 hover:text-blue-800"
                                        title="Ver detalle"
                                    >
                                        <FiEye size={20} />
                                    </button>
                                    {/* Estado movido al final */}
                                    <div className="flex items-center ml-2">
                                        {entry.estado === 'APROBADO' ? (
                                            <div className="flex items-center text-green-600">
                                                <FiCheck className="mr-1" />
                                                <span className="text-sm">Aprobado</span>
                                            </div>
                                        ) : entry.estado === 'RECHAZADO' ? (
                                            <div className="flex items-center text-red-600">
                                                <FiX className="mr-1" />
                                                <span className="text-sm">Rechazado</span>
                                            </div>
                                        ) : (
                                            <div className="flex items-center gap-2">
                                                <div className="flex items-center text-gray-400">
                                                    <FiClock className="mr-1" />
                                                    <span className="text-sm">Pendiente</span>
                                                </div>
                                                {isRegistradoMode && (
                                                    <div className="flex items-center gap-1">
                                                        <button 
                                                            className="p-1 text-green-500 hover:text-green-600 transition-colors"
                                                            title="Aprobar"
                                                            onClick={() => handleApproveOrden(entry.id)}
                                                        >
                                                            <FiCheckCircle size={18} />
                                                        </button>
                                                        <button 
                                                            className="p-1 text-red-500 hover:text-red-600 transition-colors"
                                                            title="Rechazar"
                                                            onClick={() => handleRejectOrden(entry.id)}
                                                        >
                                                            <FiXCircle size={18} />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="flex items-center gap-4 mt-1">
                            <div className="flex-1 w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Total Órdenes de Compra
                                </label>           
                            </div>
                            <div className="text-center">
                                <input 
                                    type="text" 
                                    value={totalOrdenesCompra.toFixed(2)}
                                    readOnly
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="w-32"></div>
                            <div className="w-10"></div>
                        </div>

                        {/* Botón para agregar nueva orden de compra */}
                        {!isRegistradoMode && (
                            <div className="flex justify-end">
                                <button 
                                    onClick={() => setIsModalOpen(true)}
                                    className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                                >
                                    <FiPlus size={18} />
                                    Agregar Orden de Compra
                                </button>
                            </div>
                        )}

                        {/* Constancia de Pago */}

                        <div className="flex items-center gap-4 mt-4">
                            <div className="flex-1 w-full">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Constancia de Pago
                                    </label>           
                            </div>
                          
                            <div className="text-center w-32">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Monto
                                    </label>
                            </div>
                          
                            <div className="text-center w-32">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Pendiente
                                    </label>
                            </div>
                          
                            <div className="w-5 w-5 w-3">    
                            </div>
                        </div>
                            {/* Comentar visualización de constanciaEntries */}
                            {/* {JSON.stringify(constanciaEntries)} */}
                            {constanciaEntries.map((entry) => (
                                <div key={entry.id} className="flex items-center gap-4 py-1">
                                    <div className="flex-1">
                                        <input 
                                            type="text" 
                                            value={entry.codigo}
                                            readOnly
                                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div>
                                        
                                        <input 
                                            type="text" 
                                            value={entry.monto}
                                            readOnly
                                            className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>
                                    <div className="flex items-center justify-end space-x-2">
                                        {!isRegistradoMode && (
                                            <button 
                                                onClick={() => handleRemoveConstancia(entry.id)}
                                                className="text-red-500 hover:text-red-700"
                                                title="Eliminar"
                                            >
                                                <FiTrash2 size={20} />
                                            </button>
                                        )}
                                        <button 
                                            onClick={() => handleViewVoucher(entry.archivo_pago_id)}
                                            className="text-blue-600 hover:text-blue-800"
                                            title="Ver voucher"
                                        >
                                            <FiEye size={20} />
                                        </button>
                                        {/* Estado movido al final */}
                                        <div className="flex items-center ml-2">
                                            {entry.estado === 'APROBADO' ? (
                                                <div className="flex items-center text-green-600">
                                                    <FiCheck className="mr-1" />
                                                    <span className="text-sm">Aprobado</span>
                                                </div>
                                            ) : entry.estado === 'RECHAZADO' ? (
                                                <div className="flex items-center text-red-600">
                                                    <FiX className="mr-1" />
                                                    <span className="text-sm">Rechazado</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center gap-2">
                                                    <div className="flex items-center text-gray-400">
                                                        <FiClock className="mr-1" />
                                                        <span className="text-sm">Pendiente</span>
                                                    </div>
                                                    {isRegistradoMode && (
                                                        <div className="flex items-center gap-1">
                                                            <button 
                                                                className="p-1 text-green-500 hover:text-green-600 transition-colors"
                                                                title="Aprobar"
                                                                onClick={() => handleApproveConstancia(entry.id)}
                                                            >
                                                                <FiCheckCircle size={18} />
                                                            </button>
                                                            <button 
                                                                className="p-1 text-red-500 hover:text-red-600 transition-colors"
                                                                title="Rechazar"
                                                                onClick={() => handleRejectConstancia(entry.id)}
                                                            >
                                                                <FiXCircle size={18} />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}

                         <div className="flex items-center gap-4 mt-1">
                            <div className="flex-1 w-full">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                       Total Constancia de Pago
                                    </label>           
                            </div>
                          
                            <div className="text-center">
                            <input 
                                            type="text" 
                                            value={totalConstancias.toFixed(2)}
                                            readOnly
                                            className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                            </div>
                          
                            <div className="text-center">
                            <input 
                                            type="text" 
                                            value={montoRestanteConstancias.toFixed(2)}
                                            readOnly
                                            className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                            </div>
                          
                            <div className="w-10">    
                            </div>
                        </div>



                            {/* Botón para agregar nueva constancia */}
                            {!isRegistradoMode && (
                                <div className="flex justify-end">
                                    <button 
                                        onClick={handleConstanciaClick}
                                        className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                                    >
                                        <FiPlus size={18} />
                                        Agregar Constancia
                                    </button>
                                </div>
                            )}
                       

                        {/* Documento */}
                        <div className="flex items-center gap-4">
                            <div className="flex-1">
                                <div className="text-left">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Documento 
                                    </label>
                                </div>
                                <input 
                                    type="text" 
                                    value={documentoInput}
                                    onChange={(e) => setDocumentoInput(e.target.value)}
                                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <div className="text-center">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Monto
                                    </label>
                                </div>
                                <input 
                                    type="text" 
                                    value={documentoMonto}
                                    onChange={(e) => setDocumentoMonto(e.target.value)}
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div>
                                <div className="text-center">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Pendiente
                                    </label>
                                </div>
                                <input 
                                    type="text" 
                                    value={montoPendienteDocumento.toFixed(2)}
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    readOnly
                                />
                            </div>
                            <div className="flex flex-col items-center space-y-2">
                                {/* Estado de aprobación */}
                                {JSON.stringify(getAprobacionEstado())}
                                <div id="aprobacion-documento" className="w-32 flex items-center justify-center mb-2">  
                                    {getAprobacionEstado() === 'APROBADO' ? (
                                        <div className="flex items-center text-green-600">
                                            <FiCheck className="mr-1" />
                                            <span className="text-sm">Aprobado</span>
                                        </div>
                                    ) : getAprobacionEstado() === 'RECHAZADO' ? (
                                        <div className="flex items-center text-red-600">
                                            <FiX className="mr-1" />
                                            <span className="text-sm">Rechazado</span>
                                        </div>
                                    ) : (
                                        <div className="flex items-center gap-2">
                                            <div className="flex items-center text-gray-400">
                                                <FiClock className="mr-1" />
                                                <span className="text-sm">Pendiente</span>
                                            </div>
                                            {isRegistradoMode && (
                                                <div className="flex items-center gap-1">
                                                    <button 
                                                        className="p-1 text-green-500 hover:text-green-600 transition-colors"
                                                        title="Aprobar"
                                                        onClick={handleApproveComprobante}
                                                    >
                                                        <FiCheckCircle size={18} />
                                                    </button>
                                                    <button 
                                                        className="p-1 text-red-500 hover:text-red-600 transition-colors"
                                                        title="Rechazar"
                                                        onClick={handleRejectComprobante}
                                                    >
                                                        <FiXCircle size={18} />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                
                                {/* Botones de acción */}
                                <div className="flex flex-row space-x-2">
                                    <button 
                                        id="documento" 
                                        className="text-green-600 hover:text-green-800 p-2" 
                                        title="Subir Comprobante"
                                        onClick={handleDocumentoClick}
                                    >
                                        <div className="relative">
                                            <FiFileText className="w-5 h-5" />
                                            <FiPlus className="w-3 h-3 absolute -top-1 -right-1" />
                                        </div>
                                    </button>
                                    <button 
                                        className="text-blue-600 hover:text-blue-800 p-2"
                                        title="Ver documento"
                                    >
                                        <FiEye className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Ingreso Almacen */}
                        <div className="flex items-center gap-4 mt-4">
                            <div className="flex-1 w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Ingreso Almacén
                                </label>           
                            </div>
                            <div className="text-center w-32">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Monto
                                </label>
                            </div>
                            <div className="text-center w-32">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Pendiente
                                </label>
                            </div>
                            <div className="w-10"></div>
                        </div>
                         {/* Comentar visualización de ingresoAlmacenEntries */}
                         {/* {JSON.stringify(ingresoAlmacenEntries)} */}
                        {ingresoAlmacenEntries.map((entry) => (
                            <div key={entry.id} className="flex items-center gap-4 py-1">
                                <div className="flex-1">
                                    <input 
                                        type="text" 
                                        value={entry.transferencia_id}
                                        readOnly
                                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div>
                                    <input 
                                        type="text" 
                                        value={entry.monto.toFixed(2)}
                                        readOnly
                                        className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>
                                <div className="flex items-center justify-end space-x-2">
                                    {!isRegistradoMode && (
                                        <button 
                                            onClick={() => handleRemoveIngreso(entry.id)}
                                            className="text-red-500 hover:text-red-700"
                                            title="Eliminar"
                                        >
                                            <FiTrash2 size={20} />
                                        </button>
                                    )}
                                    <button 
                                        onClick={() => handleViewIngresoAlmacen(entry.detalle_id)}
                                        className="text-blue-600 hover:text-blue-800"
                                        title="Ver detalle"
                                    >
                                        <FiEye size={20} />
                                    </button>
                                    {/* Estado movido al final */}
                                    <div className="flex items-center ml-2">
                                        {entry.estado === 'APROBADO' ? (
                                            <div className="flex items-center text-green-600">
                                                <FiCheck className="mr-1" />
                                                <span className="text-sm">Aprobado</span>
                                            </div>
                                        ) : entry.estado === 'RECHAZADO' ? (
                                            <div className="flex items-center text-red-600">
                                                <FiX className="mr-1" />
                                                <span className="text-sm">Rechazado</span>
                                            </div>
                                        ) : (
                                            <div className="flex items-center gap-2">
                                                <div className="flex items-center text-gray-400">
                                                    <FiClock className="mr-1" />
                                                    <span className="text-sm">Pendiente</span>
                                                </div>
                                                {isRegistradoMode && (
                                                    <div className="flex items-center gap-1">
                                                        <button 
                                                            className="p-1 text-green-500 hover:text-green-600 transition-colors"
                                                            title="Aprobar"
                                                            onClick={() => handleApproveIngreso(entry.detalle_id)}
                                                        >
                                                            <FiCheckCircle size={18} />
                                                        </button>
                                                        <button 
                                                            className="p-1 text-red-500 hover:text-red-600 transition-colors"
                                                            title="Rechazar"
                                                            onClick={() => handleRejectIngreso(entry.detalle_id)}
                                                        >
                                                            <FiXCircle size={18} />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="flex items-center gap-4 mt-1">
                            <div className="flex-1 w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Total Ingreso Almacén
                                </label>           
                            </div>
                            <div className="text-center">
                                <input 
                                    type="text" 
                                    value={totalIngresos.toFixed(2)}
                                    readOnly
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="text-center">
                                <input 
                                    type="text" 
                                    value={montoRestanteIngresos.toFixed(2)}
                                    readOnly
                                    className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="w-10"></div>
                        </div>

                        {/* Botón para agregar nuevo ingreso */}
                        {!isRegistradoMode && (
                            <div className="flex justify-end">
                                <button 
                                    onClick={handleIngresoAlmacenClick}
                                    className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                                >
                                    <FiPlus size={18} />
                                    Agregar Ingreso
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="flex items-center gap-4 mt-8">
                        {isRegistradoMode ? (
                            // Botones para modo REGISTRADO
                            <div className="flex justify-end w-full gap-4">
                                <button 
                                    className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </button>
                                <button 
                                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                                    onClick={onClose}
                                >
                                    Confirmar
                                </button>
                            </div>
                        ) : (
                            // Botones originales para modo edición
                            <>
                                <button 
                                    className="flex-1 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors disabled:opacity-50"
                                    disabled={isSaving}
                                >
                                    Eliminar
                                </button>
                                <button 
                                    className="flex-1 bg-[#0D3588] text-white px-4 py-2 rounded-lg hover:bg-[#0D3588]/90 transition-colors disabled:opacity-50 flex items-center justify-center"
                                    onClick={() => handleSave('PENDIENTE')}
                                    disabled={isSaving}
                                >
                                    {isSaving ? (
                                        <FiLoader className="animate-spin mr-2" />
                                    ) : null}
                                    Guardar
                                </button>
                                <button 
                                    className="flex-1 bg-[#192E58] text-white px-4 py-2 rounded-lg hover:bg-[#192E58]/90 transition-colors disabled:opacity-50 flex items-center justify-center"
                                    onClick={() => handleSave('REGISTRADO')}
                                    disabled={isSaving}
                                >
                                    {isSaving ? (
                                        <FiLoader className="animate-spin mr-2" />
                                    ) : null}
                                    Registrar
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Modal para la tabla de órdenes */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Órdenes de Compra"
            >
                <ListOrdenesCompra
                    onAddDocument={handleAddDocument}
                />
            </Modal>

           

            {/* Modal de confirmación */}
            <Modal
                isOpen={isConfirmModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
                headerBgColor="bg-[#EAF1FF]"
            >
                <div className="p-6">
                    <p className="text-gray-700 text-lg text-center mb-6">
                        ¿Estás seguro de que deseas agregar el Constancia de Pago #{selectedOC?.codigo_orden}?
                    </p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setIsConfirmModalOpen(false)}
                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handleConfirmAdd}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Modal para Constancia de Pago */}
            <Modal
                isOpen={isConstanciaModalOpen}
                onClose={() => setIsConstanciaModalOpen(false)}
                title="Constancias de Pago"
                headerBgColor="bg-blue-900"
            >
                <div className="p-6">
                    <ListConstancias 
                        constancias={constancias.map(c => ({
                            ...c,
                            fecha_archivo: new Date(c.fecha_archivo).toISOString(),
                            estado_verificacion: c.estado_verificacion,
                            fecha_pago: new Date(c.fecha_pago).toISOString(),
                            archivo_pago_id: c.archivo_pago_id || null,  
                            pago_orden_id: c.pago_orden_id || null      
                        }))} 
                        loading={loadingConstancias}
                        onConstanciaSelect={handleConstanciaSelect}
                    />
                </div>
            </Modal>

            {/*  modal para subir comprobante */}
            <Modal
                isOpen={isSubirComprobanteOpen}
                onClose={() => setIsSubirComprobanteOpen(false)}
                title="Editar Comprobante"
            >
                <SubirComprobante 
                    mode="edit" // Agregar modo edición
                    ordenCompraId={ordenCompras.find(oc => oc.codigo_orden === ordenCompraInput)?.id}
                    onClose={() => setIsSubirComprobanteOpen(false)}
                    setDocumentoInput={setDocumentoInput}
                    setDocumentoMonto={setDocumentoMonto}
                    ordenCompraEntries={ordenCompraEntries} // Pasar las entradas de órdenes de compra
                    onComprobanteCreated={handleComprobanteCreated} // Pasar la función de creación
                    documentoInfo={formatComprobanteDataForEdit()}
                    initialData={{
                        id: documentoId,
                        fecha_creacion: comprobanteData?.[0]?.fecha_creacion 
                          ? new Date(comprobanteData[0].fecha_creacion).toISOString().split('T')[0]
                          : '',
                        fecha_emision: comprobanteData?.[0]?.fecha_vencimiento 
                          ? new Date(comprobanteData[0].fecha_vencimiento).toISOString().split('T')[0]
                          : '',
                        comprobante_tipo_id: comprobanteData?.[0]?.comprobante_tipo_id,
                        tipo_moneda_id: comprobanteData?.[0]?.tipo_moneda_id,
                        serie: comprobanteData?.[0]?.serie,
                        monto: comprobanteData?.[0]?.monto,
                        fecha_vencimiento: comprobanteData?.[0]?.fecha_creacion
                          ? new Date(comprobanteData[0].fecha_creacion).toISOString().split('T')[0]
                          : ''
                    }}
                />
            </Modal>
      
         
            {/*  modal para subir las ordenes de ingreso  */}

            <Modal
                isOpen={isIngresoAlmacenModalOpen}
                onClose={() => setIsIngresoAlmacenModalOpen(false)}
                title="Órdenes de Ingreso"
                headerBgColor="bg-blue-900"
            >
                <div className="p-6">
                    <ListOrdenIngreso onIngresoSelect={handleIngresoSelect} />
                </div>
            </Modal>

            {/* Modal para ver el voucher */}
            <Modal
                isOpen={isVoucherModalOpen}
                onClose={() => setIsVoucherModalOpen(false)}
                title="Voucher de Pago"
            >
                <div className="flex justify-center items-center p-4">
                    {selectedVoucher ? (
                        <img 
                            src={selectedVoucher} 
                            alt="Voucher de pago"
                            className="max-w-full max-h-[70vh] object-contain"
                        />
                    ) : (
                        <p className="text-gray-500">No hay imagen disponible</p>
                    )}
                </div>
            </Modal>

            {/* Modal para ver el PDF del Ingreso Almacén */}
            <Modal
                isOpen={isIngresoAlmacenPDFModalOpen}
                onClose={() => setIsIngresoAlmacenPDFModalOpen(false)}
                title="Detalle de Ingreso Almacén"
                headerBgColor="bg-green-700"
            >
                <div className="min-h-[400px] max-h-[80vh] overflow-auto p-4">
                    {selectedIngresoAlmacen && (
                        <PDFIngresoAlmacenGenerator 
                            ingresoAlmacen={{
                                _id: selectedIngresoAlmacen.transferencia_detalle_id || Math.random().toString(),
                                transferencia_id: selectedIngresoAlmacen.transferencia_id,
                                tipo: selectedIngresoAlmacen.tipo,
                                referencia: selectedIngresoAlmacen.referencia,
                                fecha_orden: selectedIngresoAlmacen.fecha_orden,
                                fecha: selectedIngresoAlmacen.fecha_ingreso,
                                recursos: selectedIngresoAlmacen.recursos_transferencia.map((recurso: any) => ({
                                    _id: Math.random().toString(), // ID temporal
                                    nombre: recurso.nombre,
                                    cantidad: recurso.cantidad,
                                    costo: recurso.costo,
                                    unidad: 'UND' // Valor por defecto si no existe
                                })),
                                cantidad_total: selectedIngresoAlmacen.recursos_transferencia.reduce(
                                    (sum: number, recurso: any) => sum + recurso.cantidad, 0
                                ),
                                monto_total: selectedIngresoAlmacen.monto
                            }} 
                        />
                    )}
                </div>
            </Modal>

            {/* Modal para ver el PDF de la Orden de Compra */}
            <Modal
                isOpen={isOrdenCompraPDFModalOpen}
                onClose={() => setIsOrdenCompraPDFModalOpen(false)}
                title="Detalle de Orden de Compra"
                headerBgColor="bg-blue-700"
            >
                <div className="min-h-[400px] max-h-[80vh] overflow-auto p-4">
                    {selectedOrdenCompra && (
                        <PDFOCGenerator 
                            ordenCompra={{
                                ...selectedOrdenCompra,
                                codigo: selectedOrdenCompra.codigo,
                                recursos: selectedOrdenCompra.orden_compra_recursos.map((recurso: any) => ({
                                    ...recurso,
                                    precio: recurso.precio_real,
                                })),
                                fecha_creacion: selectedOrdenCompra.aprobacion_comprobante_ordenes_compra.fecha,
                                estado: selectedOrdenCompra.aprobacion_comprobante_ordenes_compra.estado,
                            }}
                            recursos={selectedOrdenCompra.orden_compra_recursos || []}
                            nombreSolicita={selectedOrdenCompra.nombre_solicita || 'No especificado'}
                        />
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default EditarRegistro;
