import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_MEDIOS_PAGO_QUERY = gql`
  query ListMediosPagoProveedores {
    listMediosPagoProveedores {
      id
      entidad
      nro_cuenta
      detalles
      titular
      validado
    }
  }
`;

const GET_MEDIOS_PAGO_BY_PROVEEDOR_QUERY = gql`
  query ListMediosPagoProveedorByProveedor($proveedorId: ID!) {
    listMediosPagoProveedorByProveedor(proveedor_id: $proveedorId) {
      id
      entidad
      nro_cuenta
      detalles
      titular
      validado
    }
  }
`;

const ADD_MEDIOS_PAGO_MUTATION = gql`
  mutation AddMediosPagoProveedor(
    $proveedorId: ID!
    $entidad: String
    $nroCuenta: String
    $detalles: String
    $titular: String
    $validado: Boolean
  ) {
    addMediosPagoProveedor(
      proveedor_id: $proveedorId
      entidad: $entidad
      nro_cuenta: $nroCuenta
      detalles: $detalles
      titular: $titular
      validado: $validado
    ) {
      id
      entidad
      nro_cuenta
      detalles
      titular
      validado
    }
  }
`;

const UPDATE_MEDIOS_PAGO_MUTATION = gql`
  mutation UpdateMediosPagoProveedor(
    $updateMediosPagoProveedorId: ID!
    $validado: Boolean
    $titular: String
    $detalles: String
    $nroCuenta: String
    $entidad: String
    $proveedorId: ID
  ) {
    updateMediosPagoProveedor(
      id: $updateMediosPagoProveedorId
      validado: $validado
      titular: $titular
      detalles: $detalles
      nro_cuenta: $nroCuenta
      entidad: $entidad
      proveedor_id: $proveedorId
    ) {
      id
      entidad
      nro_cuenta
      detalles
      titular
      validado
    }
  }
`;

const DELETE_MEDIOS_PAGO_MUTATION = gql`
  mutation DeleteMediosPagoProveedor($deleteMediosPagoProveedorId: ID!) {
    deleteMediosPagoProveedor(id: $deleteMediosPagoProveedorId) {
      id
    }
  }
`;

export const listMediosPagoProveedoresService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_MEDIOS_PAGO_QUERY,
    });
    return data.listMediosPagoProveedores;
  } catch (error) {
    throw new Error(`Error fetching medios de pago: ${error}`);
  }
};

export const getMediosPagoByProveedorService = async (proveedor_id: string) => {
  try {
    const { data } = await client.query({
      query: GET_MEDIOS_PAGO_BY_PROVEEDOR_QUERY,
      variables: { proveedorId: proveedor_id },
    });
    return data.listMediosPagoProveedorByProveedor;
  } catch (error) {
    throw new Error(`Error fetching medios de pago by proveedor: ${error}`);
  }
};

export const addMediosPagoProveedorService = async (mediosPagoData: {
  proveedor_id: string;
  entidad?: string;
  nro_cuenta?: string;
  detalles?: string;
  titular?: string;
  validado?: boolean;
}) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_MEDIOS_PAGO_MUTATION,
      variables: {
        proveedorId: mediosPagoData.proveedor_id,
        entidad: mediosPagoData.entidad,
        nroCuenta: mediosPagoData.nro_cuenta,
        detalles: mediosPagoData.detalles,
        titular: mediosPagoData.titular,
        validado: mediosPagoData.validado,
      },
    });
    return data.addMediosPagoProveedor;
  } catch (error) {
    throw new Error(`Error adding medios de pago: ${error}`);
  }
};

export const updateMediosPagoProveedorService = async (mediosPagoData: {
  id: string;
  proveedor_id?: string;
  entidad?: string;
  nro_cuenta?: string;
  detalles?: string;
  titular?: string;
  validado?: boolean;
}) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_MEDIOS_PAGO_MUTATION,
      variables: {
        updateMediosPagoProveedorId: mediosPagoData.id,
        proveedorId: mediosPagoData.proveedor_id,
        entidad: mediosPagoData.entidad,
        nroCuenta: mediosPagoData.nro_cuenta,
        detalles: mediosPagoData.detalles,
        titular: mediosPagoData.titular,
        validado: mediosPagoData.validado,
      },
    });
    return data.updateMediosPagoProveedor;
  } catch (error) {
    throw new Error(`Error updating medios de pago: ${error}`);
  }
};

export const deleteMediosPagoProveedorService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_MEDIOS_PAGO_MUTATION,
      variables: { deleteMediosPagoProveedorId: id },
    });
    return data.deleteMediosPagoProveedor;
  } catch (error) {
    throw new Error(`Error deleting medios de pago: ${error}`);
  }
};