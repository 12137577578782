import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { 
  listOrdenComprasService, 
  listOrdenComprasWithObraService, 
  addOrdenCompraService, 
  updateOrdenCompraService, 
  deleteOrdenCompraService,
  getProveedorByOrdenCompraIdService,
  getDataPDFByOrdenCompraIdService,
  OrdenCompraUpdate,
  ProveedorOrdenCompraResponse,
  DataPDFOrdenCompra
} from '../services/ordenServicioService';
import { OrdenCompraWithObra } from '../types/ordenCompra';

export interface OrdenCompra {
  id: string;
  codigo_orden: string;
  cotizacion_id: string;
  estado: boolean;
  descripcion: string;
  usuario: string;
  fecha_ini: string;
  fecha_fin: string;
  tipo: string;
}

interface OrdenCompraState {
  ordenCompras: OrdenCompra[];
  proveedorOrdenCompra: ProveedorOrdenCompraResponse | null;
  dataPDF: DataPDFOrdenCompra | null;
  loading: boolean;
  error: string | null;
  ordenComprasWithObra: OrdenCompraWithObra[];
}

const initialState: OrdenCompraState = {
  ordenCompras: [],
  proveedorOrdenCompra: null,
  dataPDF: null,
  loading: false,
  error: null,
  ordenComprasWithObra: [],
};

export const fetchOrdenCompras = createAsyncThunk(
  'ordenCompra/fetchOrdenCompras',
  async () => {
    return await listOrdenComprasService();
  }
);

export const fetchOrdenComprasWithObra = createAsyncThunk(
  'ordenCompra/fetchOrdenComprasWithObra',
  async () => {
    return await listOrdenComprasWithObraService();
  }
);

export const addOrdenCompra = createAsyncThunk(
  'ordenCompra/addOrdenCompra',
  async (ordenCompra: Omit<OrdenCompra, 'id'>) => {
    return await addOrdenCompraService(ordenCompra);
  }
);

export const updateOrdenCompra = createAsyncThunk(
  'ordenCompra/updateOrdenCompra',
  async (ordenCompra: Omit<OrdenCompraUpdate, 'usuario'>) => {
    return await updateOrdenCompraService(ordenCompra);
  }
);

export const deleteOrdenCompra = createAsyncThunk(
  'ordenCompra/deleteOrdenCompra',
  async (id: string) => {
    await deleteOrdenCompraService(id);
    return id;
  }
);

export const fetchProveedorByOrdenCompraId = createAsyncThunk(
  'ordenCompra/fetchProveedorByOrdenCompraId',
  async (ordenCompraId: string) => {
    return await getProveedorByOrdenCompraIdService(ordenCompraId);
  }
);

export const fetchDataPDFByOrdenCompraId = createAsyncThunk(
  'ordenCompra/fetchDataPDFByOrdenCompraId',
  async (ordenCompraId: string) => {
    return await getDataPDFByOrdenCompraIdService(ordenCompraId);
  }
);

const ordenServicioSlice = createSlice({
  name: 'ordenCompra',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdenCompras.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrdenCompras.fulfilled, (state, action: PayloadAction<OrdenCompra[]>) => {
        state.loading = false;
        state.ordenCompras = action.payload;
      })
      .addCase(fetchOrdenCompras.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al cargar órdenes de compra';
      })

      .addCase(fetchOrdenComprasWithObra.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrdenComprasWithObra.fulfilled, (state, action: PayloadAction<OrdenCompraWithObra[]>) => {
        state.loading = false;
        state.ordenComprasWithObra = action.payload;
      })
      .addCase(fetchOrdenComprasWithObra.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al cargar órdenes de compra con obra';
      })

      .addCase(addOrdenCompra.fulfilled, (state, action: PayloadAction<OrdenCompra>) => {
        state.ordenCompras.push(action.payload);
      })
      .addCase(updateOrdenCompra.fulfilled, (state, action: PayloadAction<OrdenCompraWithObra>) => {
        const index = state.ordenComprasWithObra.findIndex(oc => oc.id === action.payload.id);
        if (index !== -1) {
          state.ordenComprasWithObra[index] = action.payload;
        }
      })
      .addCase(deleteOrdenCompra.fulfilled, (state, action: PayloadAction<string>) => {
        state.ordenCompras = state.ordenCompras.filter(oc => oc.id !== action.payload);
      })
      .addCase(fetchProveedorByOrdenCompraId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProveedorByOrdenCompraId.fulfilled, (state, action: PayloadAction<ProveedorOrdenCompraResponse>) => {
        state.loading = false;
        state.proveedorOrdenCompra = action.payload;
      })
      .addCase(fetchProveedorByOrdenCompraId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al cargar el proveedor de la orden de compra';
      })
      .addCase(fetchDataPDFByOrdenCompraId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataPDFByOrdenCompraId.fulfilled, (state, action: PayloadAction<DataPDFOrdenCompra>) => {
        state.loading = false;
        state.dataPDF = action.payload;
      })
      .addCase(fetchDataPDFByOrdenCompraId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al cargar datos para PDF de la orden de compra';
      });
  },
});

export const ordenServicioReducer = ordenServicioSlice.reducer;
