import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { removeRecursoNoServicio, removeRecursoServicio, ESTADOS_FINALES } from '../../../slices/compraSelectorSlice';
import { formatCurrency } from '../../../components/Utils/priceFormatUtils';
import { FiTrash2, FiChevronDown, FiChevronRight } from 'react-icons/fi';
import ModalAlert from '../../../components/Modal/ModalAlert';


const RecursosAcumuladosList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    recursosNoServiciosAgrupados,
    recursosNoServicios,
    recursosServiciosAgrupados,
    recursosServicios,
    solicitudColors,
    obraFilter,
    selectedSolicitudes
  } = useSelector((state: RootState) => state.compraSelector);
  const obras = useSelector((state: RootState) => state.obra.obras);
  const { solicitudes } = useSelector((state: RootState) => state.solicitudCompra);

  // Verificar si hay solicitudes con estados finales seleccionadas
  const haySolicitudesConEstadosFinales = selectedSolicitudes.some(solicitudId => {
    const solicitud = solicitudes.find(s => s.id === solicitudId);
    return solicitud && ESTADOS_FINALES.includes(solicitud.estado || '');
  });

  // Estados para las pestañas
  const [activeTab, setActiveTab] = useState<'productos' | 'servicios'>('productos');
  
  // Estado para controlar qué grupos están expandidos
  const [expandedGroups, setExpandedGroups] = useState<{[key: string]: boolean}>({});

  // Estado para el modal de confirmación
  const [modalOpen, setModalOpen] = useState(false);
  const [grupoAEliminar, setGrupoAEliminar] = useState<{
    grupo: RecursoGrupo;
    esServicio: boolean;
  } | null>(null);

  // Función para obtener el color correspondiente a una solicitud
  const getSolicitudColor = (solicitudId: string) => {
    const colorAssignment = solicitudColors.find(sc => sc.solicitudId === solicitudId);
    return colorAssignment?.colorClass || '';
  };

  // Función para alternar la expansión de un grupo
  const toggleGroupExpansion = (recursoId: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [recursoId]: !prev[recursoId]
    }));
  };

  // Obtener nombre de la obra filtrada
  const obraSeleccionada = obras.find(obra => obra.id === obraFilter)?.nombre || 'Todas las obras';

  // Convertir objetos a arrays para facilitar renderización
  const productosAgrupados = Object.values(recursosNoServiciosAgrupados);
  const serviciosAgrupados = Object.values(recursosServiciosAgrupados);

  // Calcular totales
  const totalProductos = productosAgrupados.reduce((total, grupo) => {
    return total + (grupo.precioUnitario * grupo.cantidadTotal);
  }, 0);

  const totalServicios = serviciosAgrupados.reduce((total, grupo) => {
    return total + (grupo.precioUnitario * grupo.cantidadTotal);
  }, 0);

  // Manejar eliminación de un recurso específico
  const handleRemoveItem = (id: string, esServicio: boolean) => {
    if (esServicio) {
      dispatch(removeRecursoServicio(id));
    } else {
      dispatch(removeRecursoNoServicio(id));
    }
  };
  
  // Definir tipos para los grupos
  type RecursoGrupo = {
    recursoId: string;
    nombre: string;
    codigo?: string;
    cantidadTotal: number;
    precioUnitario: number;
    items: {
      id: string;
      solicitud_compra_id: string;
      cantidadSeleccionada: number;
    }[];
  };

  // Nueva función para abrir el modal de confirmación
  const confirmarEliminarGrupo = (grupo: RecursoGrupo, esServicio: boolean) => {
    setGrupoAEliminar({ grupo, esServicio });
    setModalOpen(true);
  };

  // Función para eliminar el grupo confirmado
  const eliminarGrupoConfirmado = () => {
    if (!grupoAEliminar) return;
    
    const { grupo, esServicio } = grupoAEliminar;
    
    // Eliminar cada recurso del grupo
    grupo.items.forEach((item: {
      id: string;
      solicitud_compra_id: string;
      cantidadSeleccionada: number;
    }) => {
      if (esServicio) {
      dispatch(removeRecursoServicio(item.id));
      } else {
      dispatch(removeRecursoNoServicio(item.id));
      }
    });

    // Cerrar el modal y limpiar el estado
    setModalOpen(false);
    setGrupoAEliminar(null);
  };

  // Función para cancelar la eliminación
  const cancelarEliminacion = () => {
    setModalOpen(false);
    setGrupoAEliminar(null);
  };

  return (
    <div className="h-[calc(100%)] px-3 rounded-xl bg-white/15 backdrop-blur-md border border-white/20 shadow-[0_8px_32px_rgba(0,0,30,0.3)] overflow-y-auto transition-all duration-300 text-white">
      {/* Modal de confirmación */}
      <ModalAlert 
        isOpen={modalOpen}
        title="Confirmar eliminación"
        message={`¿Está seguro que desea eliminar todos los recursos de ${grupoAEliminar?.grupo.nombre || ''}?`}
        onConfirm={eliminarGrupoConfirmado}
        onCancel={cancelarEliminacion}
        variant="yellow"
      />
      
      <div className="flex justify-between items-center my-0.5 pb-0.5 border-b border-sky-300/30">
        <h2 className="text-sm font-bold text-sky-100"><span className='pr-2'>Recursos Seleccionados para la Obra</span>
          <span className="text-xs bg-sky-500/40 backdrop-blur-sm text-white py-0.5 px-3 rounded-full shadow-inner border border-sky-400/30">
            {obraSeleccionada}
          </span>
        </h2>
        {/* Pestañas estilizadas */}
        <div className="flex w-64 rounded-lg overflow-hidden shadow-md border border-sky-400/20 bg-sky-900/30 backdrop-blur-sm">
          <button
            className={`flex-1 py-1.5 text-sm font-medium transition-all duration-300 ${activeTab === 'productos'
              ? 'bg-gradient-to-r from-sky-500 to-blue-600 text-white shadow-lg'
              : 'bg-transparent text-sky-200 hover:bg-sky-800/30'}`}
            onClick={() => setActiveTab('productos')}
          >
            Productos <span className="text-xs bg-sky-500/40 backdrop-blur-sm text-white py-0.5 px-2 rounded-full shadow-inner border border-sky-400/30">{productosAgrupados.length}</span>
          </button>
          <button
            className={`flex-1 py-1.5 text-sm font-medium transition-all duration-300 ${activeTab === 'servicios'
              ? 'bg-gradient-to-r from-sky-500 to-blue-600 text-white shadow-lg'
              : 'bg-transparent text-sky-200 hover:bg-sky-800/30'}`}
            onClick={() => setActiveTab('servicios')}
          >
            Servicios <span className="text-xs bg-sky-500/40 backdrop-blur-sm text-white py-0.5 px-2 rounded-full shadow-inner border border-sky-400/30">{serviciosAgrupados.length}</span>
          </button>
        </div>
      </div>

      {activeTab === 'productos' ? (
        productosAgrupados.length === 0 ? (
          <div className="flex flex-col justify-center items-center min-h-[calc(100%-12rem)] text-sky-200/70 text-sm">
            {haySolicitudesConEstadosFinales ? (
              <>
                <div className="text-yellow-300 font-medium mb-2">No se pueden añadir recursos</div>
                <div className="text-center text-xs text-yellow-200/70">
                  Has seleccionado solicitudes con estados finales (Cotización completa, Fin atención parcial, Fin completo o Abortada).
                  <br />No es posible añadir recursos de solicitudes en estos estados.
                </div>
              </>
            ) : (
              "No hay productos seleccionados"
            )}
          </div>
        ) : (
          <div className="space-y-0 overflow-y-auto h-[calc(100%-5rem)]">
            {productosAgrupados.map(grupo => (
              <div
                key={grupo.recursoId}
                className="border border-sky-400/20 rounded-lg px-2 hover:bg-sky-800/30 transition-all duration-200 shadow-lg backdrop-blur-sm bg-sky-900/40 mb-1"
              >
                <div 
                  className="flex items-center justify-between py-0 cursor-pointer"
                  onClick={() => toggleGroupExpansion(grupo.recursoId)}
                >
                  <div className="grid grid-cols-12 gap-2 w-full items-center text-[10px]">
                    <div className='col-span-1 items-center flex'>
                      {expandedGroups[grupo.recursoId] ? 
                        <FiChevronDown className="mr-1 text-sky-300" /> : 
                        <FiChevronRight className="mr-1 text-sky-300" />
                      }
                      <span className="text-sky-200/80 truncate">{grupo.codigo || 'Sin código'}</span>
                    </div>
                    <div className='col-span-7'>
                      <h4 className="font-medium text-white truncate">{grupo.nombre}</h4>
                    </div>
                    <div className='col-span-1'>
                      <span className="text-sky-200 text-center">{grupo.cantidadTotal}</span>
                    </div>
                    <div className='col-span-1'>
                      <span className="text-sky-200 text-right">{formatCurrency(grupo.precioUnitario)}</span>
                    </div>
                    <div className='col-span-1'>
                      <span className="font-medium text-white text-right">{formatCurrency(grupo.cantidadTotal * grupo.precioUnitario)}</span>
                    </div>
                    <div className='col-span-1'>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          // Reemplazar la llamada directa por la apertura del modal
                          confirmarEliminarGrupo(grupo, false);
                        }}
                        className="text-red-300 hover:bg-red-900/30 hover:text-red-200 rounded-full p-1 transition-all duration-300 border border-transparent hover:border-red-500/30"
                        title="Eliminar grupo completo"
                      >
                        <FiTrash2 className='h-3 w-3' />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Elementos individuales del grupo, mostrados cuando el grupo está expandido */}
                {expandedGroups[grupo.recursoId] && (
                  <div className="pl-4 py-1 border-t border-sky-400/20 mt-1 bg-sky-900/20 rounded-b-md">
                    {grupo.items.map(item => {
                      const recurso = recursosNoServicios.find(r => r.id === item.id);
                      const colorClass = getSolicitudColor(item.solicitud_compra_id);
                      return (
                        <div key={item.id} className="flex items-center justify-between py-0.5 text-[10px]">
                          <div className="flex items-center">
                            <div 
                              className={`w-2 h-2 rounded-full mr-1.5 ${colorClass} opacity-90 shadow-sm border border-white/20`} 
                              title={`Solicitud: ${item.solicitud_compra_id}`}
                            ></div>
                            <span className="text-sky-200/80 truncate w-16">{recurso?.recurso_id.codigo || 'Sin código'}</span>
                          </div>
                          <span className="font-medium text-white truncate flex-1">{recurso?.recurso_id.nombre}</span>
                          <span className="text-sky-200 whitespace-nowrap">{item.cantidadSeleccionada}</span>
                          <span className="text-sky-200 whitespace-nowrap px-2">{formatCurrency(recurso?.recurso_id.precio_actual || 0)}</span>
                          <span className="font-medium text-white whitespace-nowrap">{formatCurrency(item.cantidadSeleccionada * (recurso?.recurso_id.precio_actual || 0))}</span>
                          <button
                            onClick={() => handleRemoveItem(item.id, false)}
                            className="text-red-300 hover:bg-red-900/30 hover:text-red-200 rounded-full p-1 transition-all duration-300 border border-transparent hover:border-red-500/30 ml-1"
                            title="Eliminar"
                          >
                            <FiTrash2 className='h-3 w-3' />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        )
      ) : (
        serviciosAgrupados.length === 0 ? (
          <div className="flex flex-col justify-center items-center min-h-[calc(100%-12rem)] text-sky-200/70 text-sm">
            {haySolicitudesConEstadosFinales ? (
              <>
                <div className="text-yellow-300 font-medium mb-2">No se pueden añadir recursos</div>
                <div className="text-center text-xs text-yellow-200/70">
                  Has seleccionado solicitudes con estados finales (Cotización completa, Fin atención parcial, Fin completo o Abortada).
                  <br />No es posible añadir recursos de solicitudes en estos estados.
                </div>
              </>
            ) : (
              "No hay servicios seleccionados"
            )}
          </div>
        ) : (
          <div className="space-y-2 overflow-y-auto h-[calc(100%-5rem)]">
            {serviciosAgrupados.map(grupo => (
              <div
                key={grupo.recursoId}
                className="border border-sky-400/20 rounded-lg px-2 hover:bg-sky-800/30 transition-all duration-200 shadow-lg backdrop-blur-sm bg-sky-900/40 mb-1"
              >
                <div 
                  className="flex items-center justify-between py-1 cursor-pointer"
                  onClick={() => toggleGroupExpansion(grupo.recursoId)}
                >
                  <div className="grid grid-cols-12 gap-2 w-full items-center text-[10px]">
                    <div className='col-span-1 items-center flex'>
                      {expandedGroups[grupo.recursoId] ? 
                        <FiChevronDown className="mr-1 text-sky-300" /> : 
                        <FiChevronRight className="mr-1 text-sky-300" />
                      }
                      <span className="text-sky-200/80 truncate">{grupo.codigo || 'Sin código'}</span>
                    </div>
                    <div className='col-span-7'>
                      <h4 className="font-medium text-white truncate">{grupo.nombre}</h4>
                    </div>
                    <div className='col-span-1'>
                      <span className="text-sky-200 text-center">{grupo.cantidadTotal}</span>
                    </div>
                    <div className='col-span-1'>
                      <span className="text-sky-200 text-right">{formatCurrency(grupo.precioUnitario)}</span>
                    </div>
                    <div className='col-span-1'>
                      <span className="font-medium text-white text-right">{formatCurrency(grupo.cantidadTotal * grupo.precioUnitario)}</span>
                    </div>
                    <div className='col-span-1'>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          // Reemplazar la llamada directa por la apertura del modal
                          confirmarEliminarGrupo(grupo, true);
                        }}
                        className="text-red-300 hover:bg-red-900/30 hover:text-red-200 rounded-full p-1 transition-all duration-300 border border-transparent hover:border-red-500/30"
                        title="Eliminar grupo completo"
                      >
                        <FiTrash2 className='h-3 w-3' />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Elementos individuales del grupo de servicios, mostrados cuando el grupo está expandido */}
                {expandedGroups[grupo.recursoId] && (
                  <div className="pl-4 py-1 border-t border-sky-400/20 mt-1 bg-sky-900/20 rounded-b-md">
                    {grupo.items.map(item => {
                      const recurso = recursosServicios.find(r => r.id === item.id);
                      const colorClass = getSolicitudColor(item.solicitud_compra_id);
                      return (
                        <div key={item.id} className="flex items-center justify-between py-0.5 text-[10px]">
                          <div className="flex items-center">
                            <div 
                              className={`w-2 h-2 rounded-full mr-1.5 ${colorClass} opacity-90 shadow-sm border border-white/20`} 
                              title={`Solicitud: ${item.solicitud_compra_id}`}
                            ></div>
                            <span className="text-sky-200/80 truncate w-16">{recurso?.recurso_id.codigo || 'Sin código'}</span>
                          </div>
                          <span className="font-medium text-white truncate flex-1">{recurso?.recurso_id.nombre}</span>
                          <span className="text-sky-200 whitespace-nowrap">{item.cantidadSeleccionada}</span>
                          <span className="text-sky-200 whitespace-nowrap px-2">{formatCurrency(recurso?.recurso_id.precio_actual || 0)}</span>
                          <span className="font-medium text-white whitespace-nowrap">{formatCurrency(item.cantidadSeleccionada * (recurso?.recurso_id.precio_actual || 0))}</span>
                          <button
                            onClick={() => handleRemoveItem(item.id, true)}
                            className="text-red-300 hover:bg-red-900/30 hover:text-red-200 rounded-full p-1 transition-all duration-300 border border-transparent hover:border-red-500/30 ml-1"
                            title="Eliminar"
                          >
                            <FiTrash2 className='h-3 w-3' />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        )
      )}

      {/* Total con efecto elegante */}
      <div className="mt-0 py-1 border-t border-sky-400/30">
        <div className="flex justify-between items-center bg-gradient-to-r from-blue-900/50 to-sky-700/50 rounded-lg py-0.5 px-3 shadow-inner backdrop-blur-sm border border-sky-400/20">
          <span className="text-sm font-medium text-sky-100">Total estimado:</span>
          <span className="text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-sky-200 to-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]">
            {formatCurrency(activeTab === 'productos' ? totalProductos : totalServicios)}
          </span>
        </div>     
      </div>
    </div>
  );
};

export default RecursosAcumuladosList;
