import React, { useState } from 'react';
import Button from '../../../components/Buttons/Button';
import { MediosPagoProveedor } from '../../../slices/mediosPagoProveedorSlice';
import FileUpload from '../../../components/FileUpload/FileUpload';

interface MediosPagoFormProps {
  onSubmit: (data: Partial<MediosPagoProveedor>) => void;
  onCancel: () => void;
  initialData?: Partial<MediosPagoProveedor>;
}

const MediosPagoForm: React.FC<MediosPagoFormProps> = ({ onSubmit, onCancel, initialData }) => {
  const [formData, setFormData] = useState<Partial<MediosPagoProveedor>>({
    entidad: initialData?.entidad || '',
    nro_cuenta: initialData?.nro_cuenta || '',
    detalles: initialData?.detalles || '',
    titular: initialData?.titular || '',
    validado: initialData?.validado || false,
    id: initialData?.id || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-2xl mx-auto space-y-4 md:space-y-6 p-3 md:p-8 bg-white/90 backdrop-blur-sm rounded-xl shadow-2xl">
      <div className="grid grid-cols-1 gap-4 md:gap-6">
        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
            Entidad Bancaria
          </label>
          <input
            type="text"
            name="entidad"
            value={formData.entidad}
            onChange={handleChange}
            className="block w-full rounded-lg border-gray-300 shadow-lg px-2 py-1 md:py-2
                     focus:ring-4 focus:ring-blue-500/50 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/75 backdrop-blur-sm"
          />
        </div>
        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
            Número de Cuenta
          </label>
          <input
            type="text"
            name="nro_cuenta"
            value={formData.nro_cuenta}
            onChange={handleChange}
            className="block w-full rounded-lg border-gray-300 shadow-lg px-2 py-1 md:py-2
                     focus:ring-4 focus:ring-blue-500/50 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/75 backdrop-blur-sm"
          />
        </div>
        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
            Detalles <span className='text-slate-400 font-thin italic'>(CCI, soles, dólares, etc.)</span>
          </label>
          <input
            type="text"
            name="detalles"
            value={formData.detalles}
            onChange={handleChange}
            className="block w-full rounded-lg border-gray-300 shadow-lg px-2 py-1 md:py-2
                     focus:ring-4 focus:ring-blue-500/50 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/75 backdrop-blur-sm"
          />
        </div>
        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
            Titular
          </label>
          <input
            type="text"
            name="titular"
            value={formData.titular}
            onChange={handleChange}
            className="block w-full rounded-lg border-gray-300 shadow-lg px-2 py-1 md:py-2
                     focus:ring-4 focus:ring-blue-500/50 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/75 backdrop-blur-sm"
          />
        </div>
        <div className="group flex items-center">
          <input
            type="checkbox"
            name="validado"
            checked={formData.validado}
            onChange={handleChange}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label className="ml-2 block text-sm text-gray-800">
            Validado
          </label>
        </div>
        
        {/* Área de carga de archivos - Solo visible en modo edición */}
        {initialData?.entidad && (
          <div className="group">
            <label className="block text-sm font-medium text-gray-800 mb-1 md:mb-2">
              Documentos de Sustento
            </label>
            <FileUpload
              tipo="medio_pago"
              referencia_id={formData.id || ''}
              maxFileSize={10}
              accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.gif"
              multiple={true}
            />
          </div>
        )}
      </div>
      
      <div className="flex justify-end gap-2 md:gap-4 pt-4 md:pt-8">
        <Button 
          text="Cancelar" 
          color="rojo" 
          onClick={onCancel}
          className="flex-1 md:flex-none md:w-32 px-4 md:px-8 py-2 md:py-3 rounded-lg shadow-lg"
        />
        <Button 
          text="Guardar" 
          color="verde"
          className="flex-1 md:flex-none md:w-32 px-4 md:px-8 py-2 md:py-3 rounded-lg shadow-lg" 
        />
      </div>
    </form>
  );
};

export default MediosPagoForm;