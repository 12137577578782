import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { IComposicionApu, ITitulo } from '../types/apu.types';
import { updateTotal } from '../../../../slices/apuTotalSlice';
import { PendingChangesMap } from '../types/apu.actions';

// Función auxiliar para calcular el subtotal de mano de obra
const calcularSubtotalMO = (composiciones: IComposicionApu[], pendingChanges?: PendingChangesMap): number => {
  const TIPO_MANO_OBRA = "679d17a98ac9bee6519f5d4e"; // ID del tipo MANO DE OBRA
  
  return composiciones
    .filter(comp => comp.rec_comp_apu?.recurso?.tipo_costo_recurso_id === TIPO_MANO_OBRA)
    .reduce((sum, comp) => {
      const changes = pendingChanges?.get(comp.id_composicion_apu) || {};
      const cantidad = changes.cantidad !== undefined ? changes.cantidad : (comp.cantidad || 0);
      const precio = changes.precio !== undefined ? changes.precio : (comp.precio?.precio || 0);
      return sum + (cantidad * precio);
    }, 0);
};

export const calcularTotal = (composiciones: IComposicionApu[], pendingChanges?: PendingChangesMap): number => {
  const subtotalMO = calcularSubtotalMO(composiciones, pendingChanges);
  console.log('🔍 Calculando total APU:');
  console.log('- Subtotal MO:', subtotalMO);

  return composiciones.reduce((sum, comp) => {
    const changes = pendingChanges?.get(comp.id_composicion_apu) || {};
    const cantidad = changes.cantidad !== undefined ? changes.cantidad : (comp.cantidad || 0);
    const precio = changes.precio !== undefined ? changes.precio : (comp.precio?.precio || 0);
    const tipoRecurso = comp.rec_comp_apu?.recurso?.tipo_costo_recurso_id;
    const nombreRecurso = comp.rec_comp_apu?.nombre;

    console.log(`- Procesando ${nombreRecurso}:`, {
      tipo: tipoRecurso,
      cantidad,
      precio,
      parcial: cantidad * precio
    });

    // Si es un recurso %mo, su parcial es (cantidad * subtotalMO)
    if (comp.rec_comp_apu?.unidad?.nombre === '%mo') {
      const parcial = cantidad * subtotalMO / 100;
      console.log(`  -> Es %MO, parcial calculado:`, parcial);
      return sum + parcial;
    }

    const parcial = cantidad * precio;
    console.log(`  -> Parcial normal:`, parcial);
    return sum + parcial;
  }, 0);
};

export const useAPUCalculations = (composiciones: IComposicionApu[], activeTitulo: ITitulo | null) => {
  const dispatch = useDispatch<AppDispatch>();
  const prevTotalRef = useRef<number>(NaN);

  const calcularSubtotalPorTipo = (id_tipo: string, pendingChanges?: PendingChangesMap): number => {
    const composicionesFiltradas = composiciones.filter(
      comp => comp.rec_comp_apu?.recurso?.tipo_costo_recurso_id === id_tipo
    );

    const subtotalMO = calcularSubtotalMO(composiciones, pendingChanges);

    return composicionesFiltradas.reduce((sum, comp) => {
      const changes = pendingChanges?.get(comp.id_composicion_apu) || {};
      const cantidad = changes.cantidad !== undefined ? changes.cantidad : (comp.cantidad || 0);
      const precio = changes.precio !== undefined ? changes.precio : (comp.precio?.precio || 0);

      if (comp.rec_comp_apu?.unidad?.nombre === '%mo') {
        return sum + (cantidad * subtotalMO / 100);
      }

      return sum + (cantidad * precio);
    }, 0);
  };

  useEffect(() => {
    if (activeTitulo?.id_titulo) {
      const nuevoTotal = calcularTotal(composiciones);
      
      if (!Number.isNaN(nuevoTotal) && nuevoTotal !== prevTotalRef.current) {
        dispatch(updateTotal(nuevoTotal));
        prevTotalRef.current = nuevoTotal;
      }
    }
  }, [composiciones, activeTitulo, dispatch]);

  return {
    calcularTotal,
    calcularSubtotalPorTipo
  };
};
