import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store/store';
import { fetchSolicitudAlmacenes, updateSolicitudAlmacen } from '../../../slices/solicitudAlmacenSlice';
import { getOrdenSolicitudRecursoById } from '../../../slices/solicitudRecursoAlmacenSlice';
import { fetchRequerimientoRecurso } from '../../../slices/requerimientoRecursoSlice';
import { addSolicitudCompra } from '../../../slices/solicitudCompraSlice';
import { addSolicitudCompraRecurso } from '../../../slices/solicitudCompraRecursoSlice';
import { addComentario } from '../../../slices/comentarioSlice';
import { fetchObraBodegasByObraId } from '../../../slices/obraBodegaSlice';
import { consultaRecursosPorBodegaByObraIdAndRecursoIdService, RecursoBodega } from '../../../services/consultaRecursosPorBodegaByObraIdAndRecursoIdService';
import OrdenTransferencia from './OrdenTransferencia';
import { 
  FormularioSolicitudProps, 
  SolicitudAlmacen 
} from '../types';
import { GiFactory } from 'react-icons/gi';

interface RecursoSeleccionado {
  recurso_id: {
    id: string;
    codigo: string;
    nombre: string;
    unidad_id: string;
    precio_actual: number;
  };
  cantidad: number;
  cantidadSeleccionada: number;
  isChecked: boolean;
  bodega_id?: string;
  obra_bodega_recurso_id?: string;
  requerimiento_recurso_id: string;
  pendiente?: number;
}

const Skeleton: React.FC<{ className?: string }> = ({ className }) => (
  <div className={`animate-pulse bg-gray-200 rounded ${className}`}></div>
);

const FormularioSolicitud: React.FC<FormularioSolicitudProps> = ({ onClose, transferenciasId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showOrdenTransferencia, setShowOrdenTransferencia] = useState(false);
  const [showRechazoModal, setShowRechazoModal] = useState(false);
  const [selectedSolicitud, setSelectedSolicitud] = useState<SolicitudAlmacen | null>(null);
  const [selectedRecursos, setSelectedRecursos] = useState<RecursoSeleccionado[]>([]);
  const [almacenId, setAlmacenId] = useState<string>('');
  const [isLoadingSolicitudes, setIsLoadingSolicitudes] = useState(true);
  const [isLoadingRecursos, setIsLoadingRecursos] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [motivoRechazo, setMotivoRechazo] = useState('');
  const [recursosPorBodega, setRecursosPorBodega] = useState<{ [key: string]: RecursoBodega[] }>({});

  const solicitudes = useSelector((state: RootState) => state.solicitudAlmacen.solicitudes);
  const obras = useSelector((state: RootState) => state.obra.obras);
  const unidades = useSelector((state: RootState) => state.unidad.unidades);
  
  const userId = useSelector((state: RootState) => state.user.id);

  const cargarRecursosPorBodega = async (recursoId: string) => {
    if (!selectedSolicitud) return;
    
    try {
      const recursos = await consultaRecursosPorBodegaByObraIdAndRecursoIdService({
        obraId: selectedSolicitud.obra_origen_id.id,
        recursoId: recursoId
      });
      setRecursosPorBodega(prev => ({ ...prev, [recursoId]: recursos }));
    } catch (error) {
      console.error('Error al cargar recursos por bodega:', error);
      setErrorMessage('Error al cargar los recursos por bodega');
    }
  };

  useEffect(() => {
    if (selectedRecursos.length > 0) {
      selectedRecursos.forEach(recurso => {
        if (!recursosPorBodega[recurso.recurso_id.id]) {
          cargarRecursosPorBodega(recurso.recurso_id.id);
        }
      });
    }
  }, [selectedRecursos, selectedSolicitud]);

  useEffect(() => {
    const loadSolicitudes = async () => {
      setIsLoadingSolicitudes(true);
      await dispatch(fetchSolicitudAlmacenes());
      setIsLoadingSolicitudes(false);
    };
    loadSolicitudes();
  }, [dispatch]);

  useEffect(() => {
    const loadRecursos = async () => {
      if (selectedSolicitud) {
        setIsLoadingRecursos(true);
        const recursosData = await dispatch(getOrdenSolicitudRecursoById(selectedSolicitud.id)).unwrap();
        setSelectedRecursos(recursosData);
        setIsLoadingRecursos(false);
      }
    };
    loadRecursos();
  }, [selectedSolicitud, dispatch]);

  useEffect(() => {
    const loadBodegas = async () => {
      if (selectedSolicitud) {
        try {
          const result = await dispatch(fetchObraBodegasByObraId(selectedSolicitud.obra_origen_id.id)).unwrap();
          setRecursosPorBodega(prev => ({ ...prev, [selectedSolicitud.obra_origen_id.id]: result }));
        } catch (error) {
          console.error('Error al cargar las bodegas:', error);
          setErrorMessage('Error al cargar las bodegas de la obra');
        }
      }
    };
    loadBodegas();
  }, [selectedSolicitud, dispatch]);

  const handleCantidadChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedRecursos = [...selectedRecursos];
    const nuevaCantidad = parseInt(e.target.value);
    const recurso = updatedRecursos[index];
    
    // Obtener la bodega seleccionada
    const bodegaSeleccionada = recursosPorBodega[recurso.recurso_id.id]?.find(
      b => b.obra_bodega_id === recurso.bodega_id
    );

    if (bodegaSeleccionada && nuevaCantidad > bodegaSeleccionada.cantidad) {
      setErrorMessage(`No hay suficientes recursos en la bodega ${bodegaSeleccionada.nombre}. Cantidad disponible: ${bodegaSeleccionada.cantidad}`);
      setTimeout(() => {
        setErrorMessage(null);
      }, 8000);
      return;
    }

    updatedRecursos[index].cantidadSeleccionada = nuevaCantidad;
    setSelectedRecursos(updatedRecursos);
  };

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedRecursos = selectedRecursos.map(recurso => ({
      ...recurso,
      isChecked: e.target.checked,
      cantidadSeleccionada: e.target.checked ? (recurso.pendiente || 0) : 0,
    }));
    setSelectedRecursos(updatedRecursos);
  };

  const handleSave = async () => {
    if (!selectedSolicitud) return;

    // Validar que todos los recursos seleccionados tengan una bodega asignada
    const recursosSinBodega = selectedRecursos.filter(
      recurso => recurso.isChecked && !recurso.bodega_id
    );

    if (recursosSinBodega.length > 0) {
      setErrorMessage('Debe seleccionar una bodega para todos los recursos seleccionados');
      setTimeout(() => {
        setErrorMessage(null);
      }, 8000);
      return;
    }

    // Mostramos el formulario de orden de transferencia sin cerrar el modal
    setShowOrdenTransferencia(true);
  };

  const handleCloseOrdenTransferencia = () => {
    setShowOrdenTransferencia(false);
    // Refrescar los recursos de la solicitud seleccionada
    if (selectedSolicitud) {
      const loadRecursos = async () => {
        setIsLoadingRecursos(true);
        try {
          // Cargar recursos de la solicitud
          const recursosData = await dispatch(getOrdenSolicitudRecursoById(selectedSolicitud.id)).unwrap();
          setSelectedRecursos(recursosData);

          // Cargar recursos por bodega para cada recurso
          const recursosPorBodegaPromises = recursosData.map(async (recurso: RecursoSeleccionado) => {
            const recursos = await consultaRecursosPorBodegaByObraIdAndRecursoIdService({
              obraId: selectedSolicitud.obra_origen_id.id,
              recursoId: recurso.recurso_id.id
            });
            return { [recurso.recurso_id.id]: recursos };
          });

          const resultados = await Promise.all(recursosPorBodegaPromises);
          const nuevosRecursosPorBodega = resultados.reduce((acc, curr) => ({ ...acc, ...curr }), {});
          setRecursosPorBodega(nuevosRecursosPorBodega);
        } catch (error) {
          console.error('Error al refrescar los recursos:', error);
          setErrorMessage('Error al refrescar los datos de recursos');
        } finally {
          setIsLoadingRecursos(false);
        }
      };
      loadRecursos();
    }
  };
  
  const totalGeneral = React.useMemo(() => {
    return selectedRecursos
      .filter(recurso => recurso.isChecked)
      .reduce((total, recurso) => 
        total + (recurso.cantidadSeleccionada || 0) * recurso.recurso_id.precio_actual, 0);
  }, [selectedRecursos]);
  
  const handleRechazarSolicitud = async () => {
    if (!selectedSolicitud) return;
    
    try {
      setErrorMessage(null);
      setSuccessMessage(null);

      // Validar que el motivo de rechazo no esté vacío
      if (!motivoRechazo.trim()) {
        setErrorMessage('Debe ingresar un motivo de rechazo');
        setTimeout(() => {
          setErrorMessage(null);
        }, 8000); 
        return;
      }

      // Actualizar el estado de la solicitud a RECHAZADO
      await dispatch(updateSolicitudAlmacen({
        updateSolicitudAlmacenId: selectedSolicitud.id,
        usuario_id: selectedSolicitud.usuario_id.id,
        requerimiento_id: selectedSolicitud.requerimiento_id.id,
        obra_origen_id: selectedSolicitud.obra_origen_id.id,
        obra_destino_id: selectedSolicitud.obra_destino_id?.id || '',
        estado: 'RECHAZADO'
      })).unwrap();

      // Crear el comentario de rechazo
      await dispatch(addComentario({
        usuario_id: userId,
        referencia_id: selectedSolicitud.id,
        tabla: 'solicitud_almacen',
        comentario: motivoRechazo.trim()
      })).unwrap();

      // Obtener el requerimiento recurso para cada recurso seleccionado
      const recursosProcesados = await Promise.all(
        selectedRecursos.map(async (recurso) => {
          const requerimientoRecurso = await dispatch(fetchRequerimientoRecurso(recurso.requerimiento_recurso_id)).unwrap();
          return {
            ...recurso,
            requerimientoRecurso
          };
        })
      );

      // Crear la solicitud de compra
      const solicitudCompraData = {
        requerimientoId: recursosProcesados[0].requerimientoRecurso.requerimiento_id,
        usuarioId: userId
      };

      const nuevaSolicitudCompra = await dispatch(addSolicitudCompra(solicitudCompraData)).unwrap();

      // Crear los recursos de la solicitud de compra
      await Promise.all(
        recursosProcesados.map(async (recurso) => {
          const recursoData = {
            solicitud_compra_id: nuevaSolicitudCompra.id,
            recurso_id: recurso.recurso_id.id,
            requerimiento_recurso_id: recurso.requerimiento_recurso_id,
            cantidad: recurso.cantidad,
            costo: recurso.recurso_id.precio_actual,
            precio_partida: recurso.requerimientoRecurso.precio_partida,
            precio: recurso.requerimientoRecurso.precio
          };
          await dispatch(addSolicitudCompraRecurso(recursoData)).unwrap();
        })
      );

      setSuccessMessage('Solicitud de compra creada exitosamente y solicitud de almacén rechazada');
      setTimeout(() => {
        setShowRechazoModal(false);
        onClose();
      }, 1500);

    } catch (error) {
      console.error('Error al procesar el rechazo:', error);
      setErrorMessage(error instanceof Error ? error.message : 'Error al procesar el rechazo de la solicitud');
    }
  };

  const handleBodegaChange = (index: number, bodegaId: string) => {
    const updatedRecursos = [...selectedRecursos];
    const bodegaSeleccionada = recursosPorBodega[updatedRecursos[index].recurso_id.id]?.find(
      b => b.obra_bodega_id === bodegaId
    );
    
    updatedRecursos[index] = {
      ...updatedRecursos[index],
      bodega_id: bodegaId,
      obra_bodega_recurso_id: bodegaSeleccionada?.obra_bodega_recursos_id || ''
    };
    setSelectedRecursos(updatedRecursos);
  };

  const hayRecursosSuficientes = React.useMemo(() => {
    return selectedRecursos.every(recurso => {
      if (!recurso.isChecked || !recurso.bodega_id) return true;
      
      const bodegaSeleccionada = recursosPorBodega[recurso.recurso_id.id]?.find(
        b => b.obra_bodega_id === recurso.bodega_id
      );
      
      return bodegaSeleccionada && recurso.cantidadSeleccionada <= bodegaSeleccionada.cantidad;
    });
  }, [selectedRecursos, recursosPorBodega]);

  return (
    <>
      {showOrdenTransferencia && selectedSolicitud ? (
        <OrdenTransferencia 
          onClose={handleCloseOrdenTransferencia}
          transferenciasId={transferenciasId}
          recursos={selectedRecursos.filter(recurso => recurso.isChecked)}
          recursosPorBodega={recursosPorBodega}
          solicitudData={{
            id: selectedSolicitud.id,
            almacenOrigen: selectedSolicitud.obra_origen_id,
            almacenDestino: selectedSolicitud.obra_destino_id,
            usuario: selectedSolicitud.usuario_id
          }}
        />
      ) : (
        <div className="bg-gradient-to-br from-white to-gray-50 rounded-xl shadow-lg w-[1200px] max-w-full min-w-full max-h-[90vh] flex flex-col overflow-hidden border border-gray-100">
          {/* Mensajes de error/éxito */}
          {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
          
          {/* Header */}
          <div className="border-b border-gray-100 bg-white">
            
            <div className="px-3 pb-3">
              <select
                value={almacenId}
                onChange={(e) => setAlmacenId(e.target.value)}
                className="w-64 px-2 py-1.5 text-sm text-gray-600 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent shadow-sm transition-all duration-200"
              >
                <option value="">Todas las obras</option>
                {obras.map((obra) => (
                  <option key={obra.id} value={obra.id}>{obra.nombre}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex h-[calc(80vh-16rem)]">
            
            {/* Left Panel - Solicitudes */}
            <div className="w-1/4 border-r border-gray-100 p-4 overflow-y-auto bg-gray-100">
              {isLoadingSolicitudes ? (
                [...Array(5)].map((_, index) => (
                  <div key={index} className="p-3 mb-2 rounded-md border border-white">
                    <Skeleton className="h-4 w-3/4 mb-2" />
                    <Skeleton className="h-3 w-1/2 mb-1" />
                    <Skeleton className="h-3 w-1/3" />
                  </div>
                ))
              ) : (
                solicitudes
                  .filter(solicitud => almacenId === '' || solicitud.requerimiento_id.obra_id === almacenId)
                  .sort((a, b) => {
                    // Primero ordenamos por prioridad de estado
                    const priorityA = ['aprobado', 'parcial'].includes(a.estado) ? 0 : 1;
                    const priorityB = ['aprobado', 'parcial'].includes(b.estado) ? 0 : 1;
                    
                    if (priorityA !== priorityB) return priorityA - priorityB;
                    
                    // Luego ordenamos por fecha, más reciente primero
                    return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
                  })
                  .map((solicitud) => (
                    <div
                      key={solicitud.id}
                      onClick={() => {
                        if (selectedSolicitud?.id === solicitud.id) {
                          setSelectedSolicitud(null); 
                          setTimeout(() => setSelectedSolicitud(solicitud), 0); 
                        } else {
                          setSelectedSolicitud(solicitud);
                        }
                        setSelectedRecursos([]);
                      }}
                      className={`p-4 mb-2 rounded-lg cursor-pointer border transition-all duration-300 hover:shadow-md ${
                        selectedSolicitud?.id === solicitud.id
                          ? 'bg-blue-50 border-blue-400 shadow-md ring-2 ring-blue-200 ring-opacity-50'
                          : 'border-gray-100 bg-white hover:bg-gray-50'
                      }`}
                    >
                    <div className="flex justify-between items-start">
                      <div className="text-sm font-medium text-gray-800">{solicitud.requerimiento_id.codigo}</div>
                      <div className={`text-xs px-2 py-1 rounded-full font-medium ${
                        solicitud.estado === 'aprobado' ? 'bg-green-100 text-green-700' :
                        solicitud.estado === 'parcial' ? 'bg-yellow-100 text-yellow-700' :
                        solicitud.estado === 'completado' ? 'bg-blue-100 text-blue-700' :
                        solicitud.estado === 'finalParcial' ? 'bg-purple-100 text-purple-700' :
                        solicitud.estado === 'RECHAZADO' ? 'bg-red-100 text-red-700' :
                        'bg-gray-100 text-gray-600'
                      }`}>
                        {solicitud.estado}
                      </div>
                    </div>
                    <div className="text-xs text-gray-600 mt-2 flex items-center">
                      <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                      {solicitud.usuario_id.nombres} {solicitud.usuario_id.apellidos}
                    </div>
                    <div className="text-xs text-gray-600 mt-2 flex items-center">
                      <GiFactory className="w-3 h-3 mr-1" />
                      De: {solicitud.obra_origen_id.nombre}
                    </div>
                    <div className="text-xs text-gray-600 mt-2 flex items-center">
                      <GiFactory className="w-3 h-3 mr-1" />
                      Para: {solicitud.obra_destino_id.nombre}
                    </div>
                    <div className="text-xs text-gray-500 mt-1.5 flex items-center">
                      <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                      {new Date(solicitud.fecha).toLocaleDateString()}
                    </div>
                  </div>
                ))
              )}
            </div>

            {/* Right Panel - Recursos */}
            <div className="flex-1 p-4 w-full flex flex-col h-full">
              {selectedSolicitud ? (
                <>
                  <div className="p-3 bg-white border-b border-gray-100">
                    <h3 className="text-sm font-medium text-gray-700">Recursos de la Solicitud</h3>
                  </div>

                  <div className="flex-1 overflow-auto overflow-x-auto p-3 w-full">
                    <table className="w-full border-collapse">
                      <thead className="bg-gray-50 sticky -top-3">
                        <tr>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            <input
                              type="checkbox"
                              className="rounded border-gray-300 text-blue-500 focus:ring-blue-400"
                              onChange={handleSelectAllChange}
                            />
                          </th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Código</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unidad</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cant.Solicitada</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 upercase tracking-wider">PENDIENTE </th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Precio</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bodega</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cant. Transferida</th>
                          <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-100">
                        {isLoadingRecursos ? (
                          [...Array(5)].map((_, index) => (
                            <tr key={index}>
                              <td className="px-3 py-2"><Skeleton className="h-4 w-4" /></td>
                              <td className="px-3 py-2"><Skeleton className="h-4 w-20" /></td>
                              <td className="px-3 py-2"><Skeleton className="h-4 w-32" /></td>
                              <td className="px-3 py-2"><Skeleton className="h-4 w-16" /></td>
                              <td className="px-3 py-2"><Skeleton className="h-4 w-16" /></td>
                              <td className="px-3 py-2"><Skeleton className="h-4 w-20" /></td>
                              <td className="px-3 py-2"><Skeleton className="h-8 w-20" /></td>
                              <td className="px-3 py-2"><Skeleton className="h-4 w-20" /></td>
                            </tr>
                          ))
                        ) : (
                          selectedRecursos.map((recurso, index) => {
                            const subtotal = (recurso.cantidadSeleccionada || 0) * recurso.recurso_id.precio_actual;
                            const isRechazado = selectedSolicitud?.estado === 'RECHAZADO';
                            return (
                              <React.Fragment key={recurso.recurso_id.id}>
                                <tr 
                                  className={`hover:bg-gray-50 transition-colors duration-150 ${
                                    isRechazado ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                                  }`}
                                  onClick={() => {
                                    if (recurso.pendiente === 0 || isRechazado) return;
                                    const updatedRecursos = [...selectedRecursos];
                                    updatedRecursos[index] = {
                                      ...updatedRecursos[index],
                                      isChecked: !recurso.isChecked,
                                      cantidadSeleccionada: !recurso.isChecked ? (recurso.pendiente || 0) : 0
                                    };
                                    setSelectedRecursos(updatedRecursos);
                                  }}
                                >
                                  <td className="px-3 py-2" onClick={(e) => e.stopPropagation()}>
                                    <input
                                      type="checkbox"
                                      checked={recurso.isChecked || false}
                                      onChange={(e) => {
                                        if (recurso.pendiente === 0 || isRechazado) return;
                                        const updatedRecursos = [...selectedRecursos];
                                        updatedRecursos[index] = {
                                          ...updatedRecursos[index],
                                          isChecked: e.target.checked,
                                          cantidadSeleccionada: e.target.checked ? (recurso.pendiente || 0) : 0
                                        };
                                        setSelectedRecursos(updatedRecursos);
                                      }}
                                      disabled={recurso.pendiente === 0 || isRechazado}
                                      className={`rounded border-gray-300 text-blue-500 focus:ring-blue-400 ${
                                        (recurso.pendiente === 0 || isRechazado) ? 'opacity-50 cursor-not-allowed' : ''
                                      }`}
                                    />
                                  </td>
                                  <td className="px-3 py-2 text-xs text-gray-600">{recurso.recurso_id.codigo}</td>
                                  <td className="px-3 py-2 text-xs text-gray-600">{recurso.recurso_id.nombre}</td>
                                  <td className="px-3 py-2 text-xs text-gray-600">{unidades.find(u => u.id === recurso.recurso_id.unidad_id)?.nombre || 'N/A'}</td>
                                  <td className="px-3 py-2 text-xs text-gray-600">{recurso.cantidad}</td>
                                  <td className="px-3 py-2 text-xs text-gray-600">{recurso.pendiente}</td>
                                  <td className="px-3 py-2 text-xs text-gray-600">S/ {recurso.recurso_id.precio_actual}</td>
                                  <td className="px-3 py-2" onClick={(e) => e.stopPropagation()}>
                                    <select
                                      value={recurso.bodega_id || ''}
                                      onChange={(e) => handleBodegaChange(index, e.target.value)}
                                      className={`w-32 px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent ${
                                        (recurso.pendiente === 0 || isRechazado) ? 'opacity-50 cursor-not-allowed' : ''
                                      }`}
                                      disabled={!recurso.isChecked || !recursosPorBodega[recurso.recurso_id.id]?.length || recurso.pendiente === 0 || isRechazado}
                                    >
                                      <option value="">Seleccionar Bodega</option>
                                      {recursosPorBodega[recurso.recurso_id.id]?.map((bodega) => (
                                        <option key={bodega.obra_bodega_id} value={bodega.obra_bodega_id}>
                                          {bodega.nombre} ({bodega.cantidad} disponibles)
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td className="px-3 py-2" onClick={(e) => e.stopPropagation()}>
                                    <input
                                      type="number"
                                      min="1"
                                      max={recurso.pendiente}
                                      value={recurso.cantidadSeleccionada || recurso.pendiente || 0}
                                      onChange={(e) => handleCantidadChange(index, e)}
                                      className={`w-20 px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent ${
                                        (recurso.pendiente === 0 || isRechazado) ? 'opacity-50 cursor-not-allowed' : ''
                                      }`}
                                      disabled={!recurso.isChecked || recurso.pendiente === 0 || isRechazado}
                                    />
                                  </td>
                                  <td className="px-3 py-2 text-xs text-gray-600">S/ {subtotal.toFixed(2)}</td>
                                </tr>
                                {recurso.pendiente === 0 && (
                                  <tr>
                                    <td colSpan={10} className="px-3 py-2 bg-green-50">
                                      <div className="text-xs text-green-600 flex items-center">
                                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        Transferencia completa
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {isRechazado && (
                                  <tr>
                                    <td colSpan={10} className="px-3 py-2 bg-red-50">
                                      <div className="text-xs text-red-600 flex items-center">
                                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                        Solicitud rechazada
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="p-3 bg-white border-t border-gray-100">
                    <div className="flex justify-end">
                      <div className="text-sm font-medium text-gray-700 pr-10">
                        Total: <span className="text-blue-600">S/ {(totalGeneral || 0).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex h-full items-center justify-center">
                  <div className="text-center text-gray-400">
                    <h3 className="mt-2 text-sm font-medium text-gray-600">No hay solicitud seleccionada</h3>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="p-5 border-t border-gray-100 flex justify-end space-x-2 bg-white">
            <button
              onClick={onClose}
              className="px-3 py-1.5 text-gray-600 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors duration-200"
            >
              Cancelar
            </button>
            <button
              onClick={() => setShowRechazoModal(true)}
              className="px-3 py-1.5 text-white bg-red-500 rounded-md hover:bg-red-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!selectedSolicitud || !selectedRecursos.some(recurso => recurso.isChecked)}
            >
              Rechazar Solicitud
            </button>
            <button
              onClick={handleSave}
              className="px-3 py-1.5 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!selectedSolicitud || !selectedRecursos.some(recurso => recurso.isChecked) || !hayRecursosSuficientes}
            >
              Guardar Solicitud
            </button>
          </div>
        </div>
      )}

      {/* Modal de Confirmación de Rechazo */}
      {showRechazoModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirmar Rechazo</h3>
            <p className="text-sm text-gray-600 mb-4">
              ¿Está seguro que desea rechazar esta solicitud? Esta acción no se puede deshacer.
            </p>
            <div className="mb-4">
              <label htmlFor="motivoRechazo" className="block text-sm font-medium text-gray-700 mb-1">
                Motivo del Rechazo
              </label>
              <textarea
                id="motivoRechazo"
                value={motivoRechazo}
                onChange={(e) => setMotivoRechazo(e.target.value)}
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                rows={4}
                placeholder="Ingrese el motivo del rechazo..."
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowRechazoModal(false)}
                className="px-4 py-2 text-sm text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
              >
                Cancelar
              </button>
              <button
                onClick={handleRechazarSolicitud}
                className="px-4 py-2 text-sm text-white bg-red-500 rounded-md hover:bg-red-600 transition-colors duration-200"
              >
                Confirmar Rechazo
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormularioSolicitud;