import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listVerificacionesArchivoPagoService,
  addVerificacionArchivoPagoService,
  updateVerificacionArchivoPagoService,
  deleteVerificacionArchivoPagoService,
  VerificacionArchivoPagoInput,
  getVerificacionesByArchivoService
} from '../services/verificacionArchivoPagoService';

export interface VerificacionArchivoPago {
  id: string;
  archivo_pago_id: string;
  usuario_id: string; 
  fecha: string;
  estado: string;
}

interface VerificacionArchivoPagoState {
  verificaciones: VerificacionArchivoPago[];
  loading: boolean;
  error: string | null;
}

const initialState: VerificacionArchivoPagoState = {
  verificaciones: [],
  loading: false,
  error: null,
};

// Thunks
export const fetchVerificaciones = createAsyncThunk(
  'verificacionArchivoPago/fetchVerificaciones',
  async (_, { rejectWithValue }) => {
    try {
      return await listVerificacionesArchivoPagoService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addVerificacionAsync = createAsyncThunk(
  'verificacionArchivoPago/addVerificacion',
  async (verificacion: VerificacionArchivoPagoInput, { rejectWithValue }) => {
    try {
      return await addVerificacionArchivoPagoService(verificacion);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateVerificacionAsync = createAsyncThunk(
  'verificacionArchivoPago/updateVerificacion',
  async ({ id, ...data }: VerificacionArchivoPagoInput & { id: string }, { rejectWithValue }) => {
    try {
      return await updateVerificacionArchivoPagoService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteVerificacionAsync = createAsyncThunk(
  'verificacionArchivoPago/deleteVerificacion',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteVerificacionArchivoPagoService(id);
      return { id };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchVerificacionesByArchivo = createAsyncThunk(
  'verificacionArchivoPago/fetchVerificacionesByArchivo',
  async (archivoPagoId: string, { rejectWithValue }) => {
    try {
      return await getVerificacionesByArchivoService(archivoPagoId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const verificacionArchivoPagoSlice = createSlice({
  name: 'verificacionArchivoPago',
  initialState,
  reducers: {
    setVerificaciones: (state, action: PayloadAction<VerificacionArchivoPago[]>) => {
      state.verificaciones = action.payload;
    },
    addVerificacion: (state, action: PayloadAction<VerificacionArchivoPago>) => {
      state.verificaciones.push(action.payload);
    },
    updateVerificacion: (state, action: PayloadAction<VerificacionArchivoPago>) => {
      const index = state.verificaciones.findIndex(v => v.id === action.payload.id);
      if (index !== -1) {
        state.verificaciones[index] = action.payload;
      }
    },
    deleteVerificacion: (state, action: PayloadAction<string>) => {
      state.verificaciones = state.verificaciones.filter(v => v.id !== action.payload);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch verificaciones
      .addCase(fetchVerificaciones.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVerificaciones.fulfilled, (state, action: PayloadAction<VerificacionArchivoPago[]>) => {
        state.loading = false;
        state.verificaciones = action.payload;
      })
      .addCase(fetchVerificaciones.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Add verificacion
      .addCase(addVerificacionAsync.fulfilled, (state, action: PayloadAction<VerificacionArchivoPago>) => {
        state.verificaciones.push(action.payload);
      })
      // Update verificacion
      .addCase(updateVerificacionAsync.fulfilled, (state, action: PayloadAction<VerificacionArchivoPago>) => {
        const index = state.verificaciones.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.verificaciones[index] = action.payload;
        }
      })
      // Delete verificacion
      .addCase(deleteVerificacionAsync.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.verificaciones = state.verificaciones.filter(item => item.id !== action.payload.id);
      })
      // Fetch verificaciones by archivo
      .addCase(fetchVerificacionesByArchivo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVerificacionesByArchivo.fulfilled, (state, action: PayloadAction<VerificacionArchivoPago[]>) => {
        state.loading = false;
        state.verificaciones = action.payload;
      })
      .addCase(fetchVerificacionesByArchivo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default verificacionArchivoPagoSlice.reducer;
