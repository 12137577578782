import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { BsPersonStanding, BsTools } from 'react-icons/bs';
import { FaToolbox, FaFileContract, FaPlus } from 'react-icons/fa';
import { MdSmartButton } from 'react-icons/md';
import { formatCurrency } from '../../../../components/Utils/priceFormatUtils';
import { IComposicionApu } from '../types/apu.types';
import { PendingChangesMap } from '../types/apu.actions';

interface APUHeaderProps {
  onAddResource: () => void;
  calcularSubtotalPorTipo: (id_tipo: string) => number;
  isEditMode: boolean;
  onEditDetallePartida: (field: 'jornada' | 'rendimiento', value: number) => void;
  detallePartida?: {
    jornada?: number;
    rendimiento?: number;
    unidad_id?: string;
  };
  pendingChanges: PendingChangesMap;
  composiciones: IComposicionApu[];
}

const APUHeaderNoPartida: React.FC<APUHeaderProps> = ({ 
  onAddResource, 
  calcularSubtotalPorTipo,
  isEditMode,
  onEditDetallePartida,
  detallePartida,
  pendingChanges,
  composiciones
}) => {
  const activeTitulo = useSelector((state: RootState) => state.activeData.activeTitulo);
  const tipos = useSelector((state: RootState) => state.tipoCostoRecurso.tipoCostoRecursos);
  const unidades = useSelector((state: RootState) => state.unidad.unidades);
  console.log("Detalle Partida",detallePartida);  

  const calcularSubtotalPorTipoConPendientes = (id_tipo: string): number => {
    return composiciones
      .filter(comp => comp.rec_comp_apu?.recurso?.tipo_costo_recurso_id === id_tipo)
      .reduce((total, comp) => {
        const changes = pendingChanges.get(comp.id_composicion_apu) || {};
        const cantidad = changes.cantidad !== undefined ? changes.cantidad : comp.cantidad || 0;
        const precio = changes.precio !== undefined ? changes.precio : comp.precio?.precio || 0;
        return total + (cantidad * precio);
      }, 0);
  };

  return (
    <div className="bg-gradient-to-r from-gray-50 to-gray-100 p-0.5 rounded-t-lg border-b border-gray-200">
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          {/* Columna izquierda */}
          <div className="flex w-full flex-col gap-x-1 pl-2">
            <div className="flex justify-between gap-1 items-center">
              <span className="text-sm font-semibold text-gray-800 flex flex-row gap-4">
                {activeTitulo?.item} 
                <p className='font-normal ml-4'>{activeTitulo?.descripcion}</p>
                <p className='font-normal text-xs text-gray-400 ml-4'>{activeTitulo?.id_titulo}</p>
              </span>
              {activeTitulo?.tipo === "PARTIDA" && (
                <div className="flex items-center px-3 py-0 bg-white rounded-md shadow-sm border border-gray-200">
                  <span className="text-xs font-medium text-gray-500">Nivel:</span>
                  <span className="text-xs text-gray-700">{activeTitulo?.nivel}</span>
                </div>
              )}
            </div>
            
            {activeTitulo?.tipo === "PARTIDA" && (
                <>
                <div className='flex gap-3 flex-row items-center mt-2'>
                  <div className="flex items-center px-3 py-1 bg-white rounded-md shadow-sm border border-gray-200 min-w-[140px]">
                  <span className="text-xs font-medium text-gray-600">
                    Jornada:
                    {isEditMode ? (
                    <input
                      type="number"
                      className="w-16 ml-2 text-xs border rounded px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      value={detallePartida?.jornada || 8}
                      onChange={(e) => onEditDetallePartida('jornada', Number(e.target.value))}
                    />
                    ) : (
                    <span className="ml-2 text-gray-800">{detallePartida?.jornada || 8}h</span>
                    )}
                  </span>
                  </div>
                  <div className="flex items-center px-3 py-1 bg-white rounded-md shadow-sm border border-gray-200 min-w-[160px]">
                  <span className="text-xs font-medium text-gray-600">
                    Rendimiento:
                    {isEditMode ? (
                    <input
                      type="number"
                      step="0.01"
                      className="w-20 ml-2 text-xs border rounded px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      value={detallePartida?.rendimiento || ''}
                      onChange={(e) => onEditDetallePartida('rendimiento', Number(e.target.value))}
                    />
                    ) : (
                    <span className="ml-2 text-gray-800">{detallePartida?.rendimiento}</span>
                    )} {unidades.find(unid => unid.id === detallePartida?.unidad_id)?.nombre || ''}
                  </span>
                  </div>
                </div>
                {!isEditMode && 
                <button
                  onClick={onAddResource}
                  className="flex items-center gap-2 px-4 py-1.5 mt-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  <FaPlus size={12} />
                  Añadir Recursos
                </button>
                }
                </>
            )}
          </div>

          {/* Columna derecha - Subtotales */}
          {/* Columna derecha */}
                        {activeTitulo?.tipo === "PARTIDA" &&
                          <div className="flex flex-col gap-0 scale-75 -mx-5 -my-3">
                            {tipos.filter(tipo => tipo.nombre !== "OTROS").map(tipo => (
                              <div key={tipo.id} className="flex items-center justify-between bg-white px-2 py-0.5 rounded-md shadow-sm border border-gray-200 text-xs">
                                <div className="flex items-center gap-1 w-32">
                                  {tipo.nombre === 'MANO DE OBRA' && <BsPersonStanding className="text-green-600" />}
                                  {tipo.nombre === 'MATERIALES' && <FaToolbox className="text-red-600" />}
                                  {tipo.nombre === 'EQUIPO' && <BsTools className="text-blue-600" />}
                                  {tipo.nombre === 'SUB-CONTRATOS' && <FaFileContract className="text-lime-500" />}
                                  {tipo.nombre === 'SUB-PARTIDAS' && <MdSmartButton className="text-pink-500" />}
                                  <span className="font-medium text-gray-600">{tipo.nombre}:</span>
                                </div>
                                <span className="text-gray-700 ml-2 w-24 text-right">S/. {formatCurrency(
                                  isEditMode 
                                    ? calcularSubtotalPorTipoConPendientes(tipo.id)
                                    : calcularSubtotalPorTipo(tipo.id)
                                )}</span>
                              </div>
                            ))}
                          </div>
                        }          
        </div>
      </div>
    </div>
  );
};

export default APUHeaderNoPartida;
