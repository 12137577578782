import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import TableComponent from '../../../components/Table/TableComponent';
import { IngresoAlmacen } from '../../../services/ordenPagoService';
import { FiEye, FiFileText, FiPlus } from 'react-icons/fi';
import PDFIngresoAlmacenGenerator from './PDFIngresoAlmacenGenerator';
import Modal from '../../../components/Modal/Modal';

interface ListOrdenIngresoProps {
  onIngresoSelect: (ingreso: IngresoAlmacen) => void;
}

const ListOrdenIngreso: React.FC<ListOrdenIngresoProps> = ({ onIngresoSelect }) => {
  const [selectedIngreso, setSelectedIngreso] = useState<IngresoAlmacen | null>(null);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedIngresoTemp, setSelectedIngresoTemp] = useState<IngresoAlmacen | null>(null);
  const { ingresosAlmacen, loadingIngresosAlmacen } = useSelector(
    (state: RootState) => state.ordenPago
  );

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const handleViewClick = (ingreso: IngresoAlmacen) => {
    setSelectedIngreso(ingreso);
    setIsPDFModalOpen(true);
  };

  const handleConfirmSelect = (ingreso: IngresoAlmacen) => {
    setSelectedIngresoTemp(ingreso);
    setIsConfirmModalOpen(true);
  };

  const handleConfirm = () => {
    if (selectedIngresoTemp) {
        // Asegurarse de que se pase el _id junto con los otros datos
        onIngresoSelect({
            ...selectedIngresoTemp,
            _id: selectedIngresoTemp._id // Asegurarse de que el _id se incluya
        });
    }
    setIsConfirmModalOpen(false);
    setSelectedIngresoTemp(null);
  };

  const tableData = {
    headers: ["N° Transferencia", "Fecha Orden", "Fecha Ingreso", "Tipo", "Acciones"],
    filter: [true, true, true, true, false],
    rows: ingresosAlmacen.map(ingreso => ({
      id: ingreso._id,
      "N° Transferencia": ingreso.transferencia_id,
      "Fecha Orden": formatDate(ingreso.fecha_orden),
      "Fecha Ingreso": formatDate(ingreso.fecha),
      "Tipo": ingreso.tipo,
      "Acciones": (
        <div className="flex items-center space-x-2 justify-center">
          <button
            onClick={() => handleViewClick(ingreso)}
            className="text-blue-500 hover:text-blue-700 transition-colors"
            title="Ver detalle"
          >
            <FiEye size={18} />
          </button>
          <button
            onClick={() => handleConfirmSelect(ingreso)}
            className="text-green-500 hover:text-green-700 transition-colors"
            title="Seleccionar ingreso"
          >
            <div className="relative">
              <FiFileText size={18} />
              <FiPlus className="w-2 h-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
            </div>
          </button>
        </div>
      )
    }))
  };

  if (loadingIngresosAlmacen) {
    return <div className="p-4 text-center">Cargando...</div>;
  }

  return (
    <>
      <div className="overflow-x-auto">
        <TableComponent tableData={tableData} />
      </div>

      {/* Modal de confirmación */}
      <Modal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <div className="p-6">
          <p className="text-gray-700 text-lg text-center mb-6">
            ¿Estás seguro que deseas agregar la orden de ingreso #{selectedIngresoTemp?.transferencia_id}?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setIsConfirmModalOpen(false)}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
            >
              Cancelar
            </button>
            <button
              onClick={handleConfirm}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Agregar
            </button>
          </div>
        </div>
      </Modal>

      {/* Modal para el PDF */}
      {selectedIngreso && (
        <Modal
          isOpen={isPDFModalOpen}
          onClose={() => setIsPDFModalOpen(false)}
          title={`Ingreso Almacén: ${selectedIngreso.transferencia_id}`}
          headerBgColor="bg-green-700" // Cambiado de bg-blue-900 a bg-green-700
        >
          <div className="min-h-[400px] max-h-[80vh] overflow-auto">
            <PDFIngresoAlmacenGenerator ingresoAlmacen={selectedIngreso} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ListOrdenIngreso;
