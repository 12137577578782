import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store/store';
import { addRequerimientoRecurso } from '../../../../slices/requerimientoRecursoSlice';
import defaultImage from '../../../../assets/NoImage.webp';
import { Titulo } from '../../../../slices/tituloSlice';
import { formatCurrency } from '../../../../components/Utils/priceFormatUtils';

interface RecursosDetailProps {
    partidaInfo: Titulo;
    requerimiento_id: string;
    onBack: () => void;
}

const RecursosPartidaDetail: React.FC<RecursosDetailProps> = ({ partidaInfo, requerimiento_id }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { composicionesApu, loading } = useSelector((state: RootState) => state.composicionApu);
    const [cantidades, setCantidades] = useState<{ [key: string]: number }>({});
    const [notas, setNotas] = useState<{ [key: string]: string }>({});

    const handleQuantityChange = (id: string, value: number) => {
        setCantidades(prev => ({
            ...prev,
            [id]: value >= 0 ? value : 0
        }));
    };

    const handleNotasChange = (id: string, value: string) => {
        setNotas(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const handleAddToCart = (recurso_id: string, composicionId: string) => {
        if (!cantidades[composicionId] || cantidades[composicionId] <= 0) {
            return;
        }

        // Encontrar el recurso correspondiente para obtener su precio
        const recurso = composicionesApu.find(r => r.id_composicion_apu === composicionId);
        const precioPartida = recurso ? recurso.precio.precio : 0;

        dispatch(addRequerimientoRecurso({
            requerimiento_id,
            recurso_id,
            cantidad: cantidades[composicionId],
            notas: notas[composicionId] || '',
            cantidad_aprobada: 0,
            fecha_limit: new Date(new Date().setDate(new Date().getDate() + 4)),
            precio: 0,
            precio_partida: precioPartida,
            id_partida: partidaInfo.id_titulo
        }));

        // Limpiar solo los campos del recurso agregado
        setCantidades(prev => {
            const newCantidades = { ...prev };
            delete newCantidades[composicionId];
            return newCantidades;
        });

        setNotas(prev => {
            const newNotas = { ...prev };
            delete newNotas[composicionId];
            return newNotas;
        });
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="bg-white rounded-lg shadow-lg p-6 h-full">
            <div className="flex items-center mb-6">
                <div>
                    <h2 className="text-xl font-semibold text-gray-800">{partidaInfo.descripcion}</h2>
                    <div className='flex flex-row justify-start items-center gap-4'>
                        <p className="text-sm text-gray-500">Item: {partidaInfo.item}</p>
                        <p className="text-sm text-gray-500">Código: {partidaInfo.id_titulo}</p>
                    </div>
                </div>
            </div>

            <div className="space-y-4">
                {composicionesApu.map((recurso) => (
                    <div key={recurso.id_composicion_apu} className="bg-white/85 shadow-2xl rounded-lg border-[1px] border-gray-400">
                        <div className="flex p-2">
                            <div className="w-20 h-20 flex-shrink-0">
                                <img
                                    src={recurso.rec_comp_apu.recurso?.imagenes?.[0].file || defaultImage}
                                    alt={recurso.rec_comp_apu.nombre}
                                    className="w-full h-full object-cover rounded-md"
                                    onError={(e) => {
                                        e.currentTarget.src = defaultImage;
                                    }}
                                />
                            </div>
                            <div className="flex-1 ml-2">
                                <h3 className="text-sm font-medium">{recurso.rec_comp_apu.nombre}</h3>
                                <p className="text-xs text-gray-500">
                                    Unidad: {recurso.rec_comp_apu.unidad.nombre} -
                                    Precio: S/.{formatCurrency(recurso.precio.precio)}
                                </p>

                                <div className="flex flex-col w-full gap-2 mt-1">
                                    <div className='flex flex-row justify-between items-center'>
                                        <div className="flex items-center gap-2">
                                            <label className="text-sm" htmlFor={`cantidad-${recurso.id_composicion_apu}`}>
                                                Cantidad:
                                            </label>
                                            <input
                                                id={`cantidad-${recurso.id_composicion_apu}`}
                                                type="number"
                                                min="0"
                                                value={cantidades[recurso.id_composicion_apu] || ''}
                                                onChange={e => handleQuantityChange(recurso.id_composicion_apu, parseInt(e.target.value))}
                                                className="w-20 pl-2 border rounded-md text-sm bg-slate-700 text-white"
                                            />
                                        </div>
                                        <button
                                            onClick={() => handleAddToCart(recurso.rec_comp_apu.recurso.id, recurso.id_composicion_apu)}
                                            className={`px-4 py-1 rounded-md text-xs text-white ${cantidades[recurso.id_composicion_apu] > 0
                                                    ? 'bg-blue-500 hover:bg-blue-700'
                                                    : 'bg-gray-400 cursor-not-allowed'
                                                }`}
                                            disabled={!cantidades[recurso.id_composicion_apu] || cantidades[recurso.id_composicion_apu] <= 0}
                                        >
                                            Agregar
                                        </button>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Notas"
                                        value={notas[recurso.id_composicion_apu] || ''}
                                        onChange={e => handleNotasChange(recurso.id_composicion_apu, e.target.value)}
                                        className="w-full border pl-2 h-8 rounded-md text-xs bg-slate-800 text-white"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {composicionesApu.length === 0 && (
                    <div className="text-center text-gray-500"> No hay recursos disponibles </div>
                )}
            </div>
        </div>
    );
};

export default RecursosPartidaDetail;
