import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_COMPROBANTES = gql`
  query ListComprobantes {
    listComprobantes {
      id
      fecha_creacion
      fecha_vencimiento
      comprobante_tipo_id {
        id
        nombre
      }
      tipo_moneda_id
      serie
      monto
      ruc
      razon_social
    }
  }
`;

const GET_COMPROBANTES_BY_ORDEN_COMPRA = gql`
  query GetComprobantesByOrderCompra($orderCompraId: ID!) {
    getComprobantesByOrderCompra(orderCompraId: $orderCompraId) {
      id
      fecha_creacion
      fecha_vencimiento
      comprobante_tipo_id {
        id
        nombre
      }
      tipo_moneda_id
      serie
      monto
      ruc
      razon_social
    }
  }
`;

const ADD_COMPROBANTE = gql`
  mutation AddComprobante(
    $fecha_creacion: DateTime
    $fecha_vencimiento: DateTime!
    $comprobante_tipo_id: ID!
    $tipo_moneda_id: Int!
    $serie: String!
    $monto: Float!
    $ruc: Float!
    $razon_social: String!
  ) {
    addComprobante(
      fecha_creacion: $fecha_creacion
      fecha_vencimiento: $fecha_vencimiento
      comprobante_tipo_id: $comprobante_tipo_id
      tipo_moneda_id: $tipo_moneda_id
      serie: $serie
      monto: $monto
      ruc: $ruc
      razon_social: $razon_social
    ) {
      id
      fecha_creacion
      fecha_vencimiento
      comprobante_tipo_id {
        id
        nombre
      }
      tipo_moneda_id
      serie
      monto
      ruc
      razon_social
    }
  }
`;

const UPDATE_COMPROBANTE = gql`
  mutation UpdateComprobante(
    $id: ID!
    $fecha_creacion: DateTime
    $fecha_vencimiento: DateTime
    $comprobante_tipo_id: ID
    $tipo_moneda_id: Int
    $serie: String
    $monto: Float
    $ruc: Float
    $razon_social: String
  ) {
    updateComprobante(
      id: $id
      fecha_creacion: $fecha_creacion
      fecha_vencimiento: $fecha_vencimiento
      comprobante_tipo_id: $comprobante_tipo_id
      tipo_moneda_id: $tipo_moneda_id
      serie: $serie
      monto: $monto
      ruc: $ruc
      razon_social: $razon_social
    ) {
      id
      fecha_creacion
      fecha_vencimiento
      comprobante_tipo_id {
        id
        nombre
      }
      tipo_moneda_id
      serie
      monto
      ruc
      razon_social
    }
  }
`;

const DELETE_COMPROBANTE = gql`
  mutation DeleteComprobante($id: ID!) {
    deleteComprobante(id: $id) {
      id
    }
  }
`;

const GET_DETALLE_COMPROBANTE_ORDEN_COMPRA = gql`
  query GetDetalleComprobanteOrdenCompra($comprobanteId: ID!) {
    getDetalleComprobanteOrdenCompra(comprobanteId: $comprobanteId) {
      id
      codigo
      monto
      orden_compra_recursos {
        nombre
        cantidad
        precio_real
      }
      comprobante_ordenes_compra_id
      aprobacion_comprobante_ordenes_compra {
        id
        estado
        fecha
      }
      comprobante_id
    }
  }
`;

const GET_DETALLE_INGRESO_ALMACEN = gql`
  query GetDetalleIngresoAlmacenComprobante($comprobanteId: ID!) {
    getDetalleIngresoAlmacenComprobante(comprobanteId: $comprobanteId) {
      transferencia_id
      monto
      transferencia_detalle_id
      tipo
      referencia
      fecha_orden
      fecha_ingreso
      recursos_transferencia {
        nombre
        cantidad
        costo
      }
      comprobante_id
      aprobacion_ingreso_almacen {
        id
        estado
        fecha
        usuario_id
      }
    }
  }
`;

const GET_DETALLE_CONSTANCIA = gql`
  query GetDetalleConstanciaComprobante($comprobanteId: ID!) {
    getDetalleConstanciaComprobante(comprobanteId: $comprobanteId) {
      codigo
      pago_orden_id {
        monto
      }
      archivo_pago_id {
        file
      }
      aprobacion_constancias_comprobante {
        id
        estado
        fecha
        usuario_id
      }
      comprobante_id
      pago_id
    }
  }
`;

const FIND_BY_ID_COMPROBANTE = gql`
  query FindAllByIdComprobante($comprobanteId: ID!) {
    findAllByIdComprobante(comprobanteId: $comprobanteId) {
      id
      fecha_creacion
      fecha_vencimiento
      comprobante_tipo_id {
        id
        nombre
      }
      tipo_moneda_id
      serie
      monto
      ruc
      razon_social
    }
  }
`;

export interface ComprobanteInput {
  fecha_creacion?: Date;
  fecha_vencimiento: Date;
  comprobante_tipo_id: string;
  tipo_moneda_id: number;
  serie: string;
  monto: number;
  ruc: number;
  razon_social: string;
}

export interface Comprobante {
  id: string;
  fecha_creacion: string;
  fecha_vencimiento: string;
  comprobante_tipo_id: {
    id: string;
    nombre: string;
  };
  tipo_moneda_id: number;
  serie: string;
  monto: number;
  ruc: number;
  razon_social: string;
}

export interface OrdenCompraRecurso {
  nombre: string;
  cantidad: number;
  precio_real: number;
}

export interface AprobacionComprobanteOrdenCompra {
  id: string;
  estado: string;
  fecha: string;
}

export interface DetalleComprobanteOrdenCompra {
  id: string;
  codigo: string;
  monto: number;
  orden_compra_recursos: OrdenCompraRecurso[];
  comprobante_ordenes_compra_id: string;
  aprobacion_comprobante_ordenes_compra: AprobacionComprobanteOrdenCompra;
  comprobante_id: string;
}

export interface RecursoTransferenciaDetalle {
  nombre: string;
  cantidad: number;
  costo: number;
}

export interface AprobacionIngresoAlmacen {
  id: string;
  estado: string;
  fecha: string;
  usuario_id: string;
}

export interface DetalleIngresoAlmacenComprobante {
  transferencia_id: string;
  monto: number;
  transferencia_detalle_id: string;
  tipo: string;
  referencia: string;
  fecha_orden: string;
  fecha_ingreso: string;
  recursos_transferencia: RecursoTransferenciaDetalle[];
  comprobante_id: string;
  aprobacion_ingreso_almacen: AprobacionIngresoAlmacen;
}

export interface DetalleConstanciaComprobante {
  codigo: string;
  pago_orden_id: { monto: number };
  archivo_pago_id: { file: string };
  aprobacion_constancias_comprobante: {
    id: string;
    estado: string;
    fecha: string;
    usuario_id: string;
  };
  comprobante_id: string;
  pago_id: string;
}

export const listComprobantesService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_COMPROBANTES,
      fetchPolicy: 'network-only',
    });
    return data.listComprobantes;
  } catch (error) {
    throw new Error(`Error fetching comprobantes: ${error}`);
  }
};

export const getComprobantesByOrderCompraService = async (orderCompraId: string) => {
  try {
    const { data } = await client.query({
      query: GET_COMPROBANTES_BY_ORDEN_COMPRA,
      variables: { orderCompraId },
      fetchPolicy: 'network-only',
    });
    return data.getComprobantesByOrderCompra;
  } catch (error) {
    throw new Error(`Error fetching comprobantes by orden compra: ${error}`);
  }
};

export const addComprobanteService = async (input: ComprobanteInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_COMPROBANTE,
      variables: input,
    });
    return data.addComprobante;
  } catch (error) {
    throw new Error(`Error adding comprobante: ${error}`);
  }
};

export const updateComprobanteService = async (id: string, input: Partial<ComprobanteInput>) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_COMPROBANTE,
      variables: { id, ...input },
    });
    return data.updateComprobante;
  } catch (error) {
    throw new Error(`Error updating comprobante: ${error}`);
  }
};

export const deleteComprobanteService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_COMPROBANTE,
      variables: { id },
    });
    return data.deleteComprobante;
  } catch (error) {
    throw new Error(`Error deleting comprobante: ${error}`);
  }
};

export const getDetalleComprobanteOrdenCompraService = async (comprobanteId: string) => {
  try {
    const { data } = await client.query({
      query: GET_DETALLE_COMPROBANTE_ORDEN_COMPRA,
      variables: { comprobanteId },
      fetchPolicy: 'network-only',
    });
    return data.getDetalleComprobanteOrdenCompra;
  } catch (error) {
    throw new Error(`Error fetching detalle comprobante orden compra: ${error}`);
  }
};

export const getDetalleIngresoAlmacenService = async (comprobanteId: string) => {
  try {
    const { data } = await client.query({
      query: GET_DETALLE_INGRESO_ALMACEN,
      variables: { comprobanteId },
      fetchPolicy: 'network-only',
    });
    return data.getDetalleIngresoAlmacenComprobante;
  } catch (error) {
    throw new Error(`Error fetching detalle ingreso almacen: ${error}`);
  }
};

export const getDetalleConstanciaService = async (comprobanteId: string) => {
  try {
    const { data } = await client.query({
      query: GET_DETALLE_CONSTANCIA,
      variables: { comprobanteId },
      fetchPolicy: 'network-only',
    });
    return data.getDetalleConstanciaComprobante;
  } catch (error) {
    throw new Error(`Error fetching detalle constancia: ${error}`);
  }
};

export const findAllByIdComprobanteService = async (comprobanteId: string) => {
  try {
    const { data } = await client.query({
      query: FIND_BY_ID_COMPROBANTE,
      variables: { comprobanteId },
      fetchPolicy: 'network-only',
    });
    return data.findAllByIdComprobante;
  } catch (error) {
    throw new Error(`Error fetching comprobante by id: ${error}`);
  }
};
