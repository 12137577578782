import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listAprobacionesService,
  getAprobacionesByComprobanteService,
  addAprobacionService,
  updateAprobacionService,
  deleteAprobacionService,
  AprobacionComprobante,
  AprobacionComprobanteInput
} from '../services/aprobacionComprobanteService';

interface AprobacionComprobanteState {
  aprobaciones: AprobacionComprobante[];
  aprobacionesComprobante: any[];
  loading: boolean;
  error: string | null;
  loadingByComprobante: boolean;
  errorByComprobante: string | null;
}

const initialState: AprobacionComprobanteState = {
  aprobaciones: [],
  aprobacionesComprobante: [],
  loading: false,
  error: null,
  loadingByComprobante: false,
  errorByComprobante: null
};

export const fetchAprobaciones = createAsyncThunk(
  'aprobacionComprobante/fetchAprobaciones',
  async (_, { rejectWithValue }) => {
    try {
      return await listAprobacionesService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchAprobacionesByComprobante = createAsyncThunk(
  'aprobacionComprobante/fetchAprobacionesByComprobante',
  async (comprobanteId: string, { rejectWithValue }) => {
    try {
      return await getAprobacionesByComprobanteService(comprobanteId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addAprobacion = createAsyncThunk(
  'aprobacionComprobante/addAprobacion',
  async (aprobacion: AprobacionComprobanteInput, { rejectWithValue }) => {
    try {
      return await addAprobacionService(aprobacion);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateAprobacion = createAsyncThunk(
  'aprobacionComprobante/updateAprobacion',
  async ({ id, ...data }: Partial<AprobacionComprobanteInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateAprobacionService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteAprobacion = createAsyncThunk(
  'aprobacionComprobante/deleteAprobacion',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteAprobacionService(id);
      return { id };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const aprobacionComprobanteSlice = createSlice({
  name: 'aprobacionComprobante',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all aprobaciones
      .addCase(fetchAprobaciones.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAprobaciones.fulfilled, (state, action: PayloadAction<AprobacionComprobante[]>) => {
        state.loading = false;
        state.aprobaciones = action.payload;
      })
      .addCase(fetchAprobaciones.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch aprobaciones by comprobante
      .addCase(fetchAprobacionesByComprobante.pending, (state) => {
        state.loadingByComprobante = true;
        state.errorByComprobante = null;
      })
      .addCase(fetchAprobacionesByComprobante.fulfilled, (state, action: PayloadAction<AprobacionComprobante[]>) => {
        state.loadingByComprobante = false;
        state.aprobacionesComprobante = action.payload;
      })
      .addCase(fetchAprobacionesByComprobante.rejected, (state, action) => {
        state.loadingByComprobante = false;
        state.errorByComprobante = action.payload as string;
      })
      // Add aprobacion
      .addCase(addAprobacion.fulfilled, (state, action: PayloadAction<AprobacionComprobante>) => {
        state.aprobaciones.push(action.payload);
      })
      // Update aprobacion
      .addCase(updateAprobacion.fulfilled, (state, action: PayloadAction<AprobacionComprobante>) => {
        const index = state.aprobaciones.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.aprobaciones[index] = action.payload;
        }
      })
      // Delete aprobacion
      .addCase(deleteAprobacion.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.aprobaciones = state.aprobaciones.filter(item => item.id !== action.payload.id);
      });
  },
});

export const aprobacionComprobanteReducer = aprobacionComprobanteSlice.reducer;
