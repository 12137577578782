import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getCountRecursosInAllTablesService } from '../services/recursosAllTablesService';

interface RecursoCotizacion {
  cantidad: number;
}

interface Cotizacion {
  id: string;
  estado: string;
  recursos_cotizacion: RecursoCotizacion;
}

interface RecursoProveedor {
  cantidad: number;
}

interface Proveedor {
  id: string;
  estado: string;
  recursos_proveedor: RecursoProveedor[];
}

interface RecursoOrden {
  cantidad: number;
}

interface OrdenCompra {
  id: string;
  cotizacion_id: string;
  recursos_orden: RecursoOrden[];
}

interface RecursoTransferencia {
  cantidad: number;
}

interface Transferencia {
  id: string;
  referencia_id: string;
  recursos_transferencia: RecursoTransferencia[];
}

export interface RecursosAllTables {
  recurso_id: string;
  cantidad_solicitud: number | null;
  cotizaciones: Cotizacion[];
  tabla_proveedores: Proveedor[];
  tabla_ordenes_compra: OrdenCompra[];
  tabla_transferencias: Transferencia[];
}

interface RecursosAllTablesState {
  recursosData: RecursosAllTables[];
  loading: boolean;
  error: string | null;
}

const initialState: RecursosAllTablesState = {
  recursosData: [],
  loading: false,
  error: null,
};

const handleError = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const getCountRecursosInAllTables = createAsyncThunk<
  RecursosAllTables[],
  string,
  { rejectValue: string }
>('recursosAllTables/getCount', async (solicitudCompraId, { rejectWithValue }) => {
  try {
    const data = await getCountRecursosInAllTablesService(solicitudCompraId);
    return data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

const recursosAllTablesSlice = createSlice({
  name: 'recursosAllTables',
  initialState,
  reducers: {
    clearRecursosData: (state) => {
      state.recursosData = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountRecursosInAllTables.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCountRecursosInAllTables.fulfilled, (state, action: PayloadAction<RecursosAllTables[]>) => {
        state.loading = false;
        state.recursosData = action.payload;
      })
      .addCase(getCountRecursosInAllTables.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Error desconocido';
        state.recursosData = [];
      });
  },
});

export const { clearRecursosData } = recursosAllTablesSlice.actions;
export const recursosAllTablesReducer = recursosAllTablesSlice.reducer;
