import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from '../../../store/store';
import { createCotizacionGeneralService } from '../../../services/cotizacionService';
import { removeAllRecursos, clearSolicitudEstadosCotizacion, clearSeleccion, clearSelectedSolicitudes, setObraFilter } from '../../../slices/compraSelectorSlice';
import ModalAlert from '../../../components/Modal/ModalAlert';
import LoaderOverlay from '../../../components/Loader/LoaderOverlay';
import { clearAccumulatedResources } from '../../../slices/solicitudCompraRecursoSlice';

const GuardarSolicitudCompras: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const userId = useSelector((state: RootState) => state.user.id);
    const {
        recursosNoServicios,
        recursosServicios,
        // recursosNoServiciosAgrupados,
        // recursosServiciosAgrupados,
        solicitudEstadosCotizacion,
        isSelectionLocked
    } = useSelector((state: RootState) => state.compraSelector);

    const hangleGuardarSolicitud = async () => {
        try {
            setLoading(true);
            setError(null);
            console.group('Payloads para enviar');

            // Verificar si hay recursos no servicios antes de procesar
            if (recursosNoServicios.length > 0) {
                // Extraer los IDs únicos de solicitud_compra_id para no servicios
                const idsNoServicios = Array.from(
                    new Set(recursosNoServicios.map(item => item.solicitud_compra_id))
                );

                // Crear arrays de pares [id, cantidad] para recursos no servicios
                const recursosNoServiciosPares = recursosNoServicios.map(item =>
                    [item.recurso_id.id, item.cantidadSeleccionada || item.cantidad, item.costo || 0, item.precio || 0, item.precio_partida || 0, item.requerimiento_recurso_id || ""] as [string, number, number, number, number, string]
                );

                // Crear arrays de pares [solicitud_id, estado] para recursos no servicios
                const estadosNoServicios = idsNoServicios.map(id => {
                    const estadoInfo = solicitudEstadosCotizacion.find(item => item.solicitudId === id);
                    return [id, estadoInfo ? estadoInfo.estado : null] as [string, string | null];
                });

                // Crear el payload para no servicios
                const payloadNoServicios = {
                    usuarioId: userId,
                    solicitudCompras: idsNoServicios,
                    solicitudesCompraRecursos: recursosNoServiciosPares,
                    solicitudesCompraEstado: estadosNoServicios
                };

                console.log('No Servicios:', payloadNoServicios);
                // Enviar la solicitud para recursos no servicios
                const resultadoNoServicios = await createCotizacionGeneralService(payloadNoServicios);
                console.log('Respuesta No Servicios:', resultadoNoServicios);
            } else {
                console.log('No Servicios: No hay recursos para enviar');
            }

            // Verificar si hay recursos servicios antes de procesar
            if (recursosServicios.length > 0) {
                // Extraer los IDs únicos de solicitud_compra_id para servicios
                const idsServicios = Array.from(
                    new Set(recursosServicios.map(item => item.solicitud_compra_id))
                );

                // Crear arrays de pares [id, cantidad] para recursos servicios
                const recursosServiciosPares = recursosServicios.map(item =>
                    [item.recurso_id.id, item.cantidadSeleccionada || item.cantidad, item.costo || 0, item.precio || 0, item.precio_partida || 0, item.requerimiento_recurso_id || ""] as [string, number, number, number, number, string]
                );

                // Crear arrays de pares [solicitud_id, estado] para recursos servicios
                const estadosServicios = idsServicios.map(id => {
                    const estadoInfo = solicitudEstadosCotizacion.find(item => item.solicitudId === id);
                    return [id, estadoInfo ? estadoInfo.estado : null] as [string, string | null];
                });

                // Crear el payload para servicios
                const payloadServicios = {
                    usuarioId: userId,
                    solicitudCompras: idsServicios,
                    solicitudesCompraRecursos: recursosServiciosPares,
                    solicitudesCompraEstado: estadosServicios
                };

                console.log('Servicios:', payloadServicios);
                // Enviar la solicitud para recursos servicios
                const resultadoServicios = await createCotizacionGeneralService(payloadServicios);
                console.log('Respuesta Servicios:', resultadoServicios);
            } else {
                console.log('Servicios: No hay recursos para enviar');
            }
            console.groupEnd();
        } catch (error) {
            console.error("Error al guardar solicitud:", error);
            setError(error instanceof Error ? error.message : 'Error desconocido');
        } finally {
            setLoading(false);
            setShowModal(true); // Mostrar el modal después de completar la operación            
        }
    };

    const handleNavigateToCotizaciones = () => {
        setShowModal(false);
        dispatch(removeAllRecursos());
        dispatch(clearSolicitudEstadosCotizacion());
        dispatch(clearSeleccion());
        dispatch(clearSelectedSolicitudes());
        dispatch(setObraFilter(''));
        dispatch(clearAccumulatedResources());
        navigate('/dashboard/compra');
    };

    const handleStayInCurrentPage = () => {
        dispatch(removeAllRecursos());
        dispatch(clearSolicitudEstadosCotizacion());
        dispatch(clearSeleccion());
        dispatch(clearSelectedSolicitudes());
        dispatch(setObraFilter(''));
        dispatch(clearAccumulatedResources());
        setShowModal(false);
    };

    return (
        <>
            {loading && <LoaderOverlay message="Guardando Recursos en la Cotizacion..." />}

            {
                isSelectionLocked === false ? null : <div className="flex items-center justify-between py-4">

                    <h2 className="text-lg font-semibold text-blue-900">Acciones</h2>
                    <div className="flex flex-col space-y-2">
                        <div className="flex space-x-3">
                            {error && (
                                <div className="text-red-500 text-sm mt-2">{error}</div>
                            )}
                            <button
                                onClick={hangleGuardarSolicitud}
                                disabled={loading}
                                className={`px-4 py-2 ${loading ? 'bg-gray-400' : 'bg-blue-700 hover:bg-blue-800'} text-white rounded-md transition-colors shadow-md flex items-center justify-center`}>
                                {loading ? 'Guardando...' : 'Generar Cotizacion'}
                            </button>
                        </div>
                    </div>
                    {showModal && (
                        <ModalAlert
                            isOpen={showModal}
                            title="Solicitud guardada correctamente"
                            message={`"Confirmar" para ir a la lista de COTIZACIONES o "Cancelar" para crear una nueva cotización.`}
                            onConfirm={handleNavigateToCotizaciones}
                            onCancel={handleStayInCurrentPage}
                        />
                    )}
                </div>
            }
        </>

    );
};

export default GuardarSolicitudCompras;