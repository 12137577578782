import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_VALORACION_PROVEEDORES = gql`
  query ListValoracionProveedores {
    listValoracionProveedores {
      id    
      puntuacion
      fecha_inicio
      fecha_fin    
      fecha_ingreso
      notas
      p_s_brindado
      condiciones_pago
      precio
      control
      garantia
      experiencia
      infraestructura
      cuestionario
      disponibilidad
      asesoramiento
      especificaciones
      tiempos
      certificados
      ambiental
      sst
      proveedor_id {
        id
        razon_social
        direccion
        nombre_comercial
        ruc
        rubro
        estado
        tipo
        actividad
        correo
        horario
        contactos {
          nombres
          apellidos
          cargo
          telefono
          correo
        }
      }
      usuario_id {
        id
        nombres
        apellidos
      }
    }
  }
`;

const LIST_VALORACION_BY_PROVEEDOR = gql`
  query ListValoracionProveedoresByProveedor($proveedor_id: ID!) {
    listValoracionProveedoresByProveedor(proveedor_id: $proveedor_id) {
      id    
      puntuacion
      fecha_inicio
      fecha_fin    
      fecha_ingreso
      notas
      p_s_brindado
      condiciones_pago
      precio
      control
      garantia
      experiencia
      infraestructura
      cuestionario
      disponibilidad
      asesoramiento
      especificaciones
      tiempos
      certificados
      ambiental
      sst
      proveedor_id {
        id
        razon_social
        direccion
        nombre_comercial
        ruc
        rubro
        estado
        tipo
        actividad
        correo
        horario
        contactos {
          nombres
          apellidos
          cargo
          telefono
          correo
        }
      }
      usuario_id {
        id
        nombres
        apellidos
      }
    }
  }
`;

const ADD_VALORACION_PROVEEDOR = gql`
  mutation AddValoracionProveedor(
    $proveedor_id: ID!, 
    $puntuacion: Float!, 
    $fecha_inicio: Date!, 
    $fecha_fin: Date!, 
    $usuario_id: ID!, 
    $fecha_ingreso: Date!, 
    $p_s_brindado: String!,
    $notas: String,
    $condiciones_pago: Float,
    $precio: Float,
    $control: Float,
    $garantia: Float,
    $experiencia: Float,
    $infraestructura: Float,
    $cuestionario: Float,
    $disponibilidad: Float,
    $asesoramiento: Float,
    $especificaciones: Float,
    $tiempos: Float,
    $certificados: Float,
    $ambiental: Float,
    $sst: Float
  ) {
    addValoracionProveedor(
      proveedor_id: $proveedor_id, 
      puntuacion: $puntuacion, 
      fecha_inicio: $fecha_inicio, 
      fecha_fin: $fecha_fin, 
      usuario_id: $usuario_id, 
      fecha_ingreso: $fecha_ingreso, 
      p_s_brindado: $p_s_brindado,
      notas: $notas,
      condiciones_pago: $condiciones_pago,
      precio: $precio,
      control: $control,
      garantia: $garantia,
      experiencia: $experiencia,
      infraestructura: $infraestructura,
      cuestionario: $cuestionario,
      disponibilidad: $disponibilidad,
      asesoramiento: $asesoramiento,
      especificaciones: $especificaciones,
      tiempos: $tiempos,
      certificados: $certificados,
      ambiental: $ambiental,
      sst: $sst
    ) {
      id    
      puntuacion
      fecha_inicio
      fecha_fin    
      fecha_ingreso
      notas
      p_s_brindado
      condiciones_pago
      precio
      control
      garantia
      experiencia
      infraestructura
      cuestionario
      disponibilidad
      asesoramiento
      especificaciones
      tiempos
      certificados
      ambiental
      sst
      proveedor_id {
        id
        razon_social
        direccion
        nombre_comercial
        ruc
        rubro
        estado
        tipo
        actividad
        correo
        horario
        contactos {
          nombres
          apellidos
          cargo
          telefono
          correo
        }
      }
      usuario_id {
        id
        nombres
        apellidos
      }
    }
  }
`;

const UPDATE_VALORACION_PROVEEDOR = gql`
  mutation UpdateValoracionProveedor(
    $updateValoracionproveedor_id: ID!,
    $puntuacion: Float,
    $fecha_inicio: Date,
    $fecha_fin: Date,
    $p_s_brindado: String,
    $notas: String,
    $condiciones_pago: Float,
    $precio: Float,
    $control: Float,
    $garantia: Float,
    $experiencia: Float,
    $infraestructura: Float,
    $cuestionario: Float,
    $disponibilidad: Float,
    $asesoramiento: Float,
    $especificaciones: Float,
    $tiempos: Float,
    $certificados: Float,
    $ambiental: Float,
    $sst: Float
  ) {
    updateValoracionProveedor(
      id: $updateValoracionproveedor_id,
      puntuacion: $puntuacion,
      fecha_inicio: $fecha_inicio,
      fecha_fin: $fecha_fin,
      p_s_brindado: $p_s_brindado,
      notas: $notas,
      condiciones_pago: $condiciones_pago,
      precio: $precio,
      control: $control,
      garantia: $garantia,
      experiencia: $experiencia,
      infraestructura: $infraestructura,
      cuestionario: $cuestionario,
      disponibilidad: $disponibilidad,
      asesoramiento: $asesoramiento,
      especificaciones: $especificaciones,
      tiempos: $tiempos,
      certificados: $certificados,
      ambiental: $ambiental,
      sst: $sst
    ) {
      id    
      puntuacion
      fecha_inicio
      fecha_fin    
      fecha_ingreso
      notas
      p_s_brindado
      condiciones_pago
      precio
      control
      garantia
      experiencia
      infraestructura
      cuestionario
      disponibilidad
      asesoramiento
      especificaciones
      tiempos
      certificados
      ambiental
      sst
      proveedor_id {
        id
        razon_social
        direccion
        nombre_comercial
        ruc
        rubro
        estado
        tipo
        actividad
        correo
        horario
        contactos {
          nombres
          apellidos
          cargo
          telefono
          correo
        }
      }
      usuario_id {
        id
        nombres
        apellidos
      }
    }
  }
`;

const DELETE_VALORACION_PROVEEDOR = gql`
  mutation DeleteValoracionProveedor($deleteValoracionproveedor_id: ID!) {
    deleteValoracionProveedor(id: $deleteValoracionproveedor_id) {
      id
    }
  }
`;

export const listValoracionProveedoresService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_VALORACION_PROVEEDORES,
    });
    return data.listValoracionProveedores;
  } catch (error) {
    throw new Error(`Error fetching valoraciones: ${error}`);
  }
};

export const listValoracionByProveedorService = async (proveedor_id: string) => {
  try {
    const { data } = await client.query({
      query: LIST_VALORACION_BY_PROVEEDOR,
      variables: { proveedor_id: proveedor_id },
    });
    return data.listValoracionProveedoresByProveedor;
  } catch (error) {
    throw new Error(`Error fetching valoraciones by proveedor: ${error}`);
  }
};

export const addValoracionProveedorService = async (valoracionData: {
  proveedor_id: string;
  puntuacion: number;
  fecha_inicio: string;
  fecha_fin: string;
  usuario_id: string;
  fecha_ingreso: string;
  p_s_brindado: string;
  notas?: string;
  condiciones_pago?: number;
  precio?: number;
  control?: number;
  garantia?: number;
  experiencia?: number;
  infraestructura?: number;
  cuestionario?: number;
  disponibilidad?: number;
  asesoramiento?: number;
  especificaciones?: number;
  tiempos?: number;
  certificados?: number;
  ambiental?: number;
  sst?: number;
}) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_VALORACION_PROVEEDOR,
      variables: {
        ...valoracionData,
        puntuacion: +valoracionData.puntuacion,
        condiciones_pago: valoracionData.condiciones_pago ? +valoracionData.condiciones_pago : null,
        precio: valoracionData.precio ? +valoracionData.precio : null,
        control: valoracionData.control ? +valoracionData.control : null,
        garantia: valoracionData.garantia ? +valoracionData.garantia : null,
        experiencia: valoracionData.experiencia ? +valoracionData.experiencia : null,
        infraestructura: valoracionData.infraestructura ? +valoracionData.infraestructura : null,
        cuestionario: valoracionData.cuestionario ? +valoracionData.cuestionario : null,
        disponibilidad: valoracionData.disponibilidad ? +valoracionData.disponibilidad : null,
        asesoramiento: valoracionData.asesoramiento ? +valoracionData.asesoramiento : null,
        especificaciones: valoracionData.especificaciones ? +valoracionData.especificaciones : null,
        tiempos: valoracionData.tiempos ? +valoracionData.tiempos : null,
        certificados: valoracionData.certificados ? +valoracionData.certificados : null,
        ambiental: valoracionData.ambiental ? +valoracionData.ambiental : null,
        sst: valoracionData.sst ? +valoracionData.sst : null,
      },
    });
    return data.addValoracionProveedor;
  } catch (error) {
    throw new Error(`Error adding valoracion: ${error}`);
  }
};

export const updateValoracionProveedorService = async (valoracionData: {
  id: string;
  puntuacion?: number;
  fecha_inicio?: string;
  fecha_fin?: string;
  p_s_brindado?: string;
  notas?: string;
  condiciones_pago?: number;
  precio?: number;
  control?: number;
  garantia?: number;
  experiencia?: number;
  infraestructura?: number;
  cuestionario?: number;
  disponibilidad?: number;
  asesoramiento?: number;
  especificaciones?: number;
  tiempos?: number;
  certificados?: number;
  ambiental?: number;
  sst?: number;
}) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_VALORACION_PROVEEDOR,
      variables: {
        updateValoracionproveedor_id: valoracionData.id,
        ...valoracionData,
        puntuacion: valoracionData.puntuacion ? +valoracionData.puntuacion : undefined,
        condiciones_pago: valoracionData.condiciones_pago ? +valoracionData.condiciones_pago : undefined,
        precio: valoracionData.precio ? +valoracionData.precio : undefined,
        control: valoracionData.control ? +valoracionData.control : undefined,
        garantia: valoracionData.garantia ? +valoracionData.garantia : undefined,
        experiencia: valoracionData.experiencia ? +valoracionData.experiencia : undefined,
        infraestructura: valoracionData.infraestructura ? +valoracionData.infraestructura : undefined,
        cuestionario: valoracionData.cuestionario ? +valoracionData.cuestionario : undefined,
        disponibilidad: valoracionData.disponibilidad ? +valoracionData.disponibilidad : undefined,
        asesoramiento: valoracionData.asesoramiento ? +valoracionData.asesoramiento : undefined,
        especificaciones: valoracionData.especificaciones ? +valoracionData.especificaciones : undefined,
        tiempos: valoracionData.tiempos ? +valoracionData.tiempos : undefined,
        certificados: valoracionData.certificados ? +valoracionData.certificados : undefined,
        ambiental: valoracionData.ambiental ? +valoracionData.ambiental : undefined,
        sst: valoracionData.sst ? +valoracionData.sst : undefined,
      },
    });
    return data.updateValoracionProveedor;
  } catch (error) {
    throw new Error(`Error updating valoracion: ${error}`);
  }
};

export const deleteValoracionProveedorService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_VALORACION_PROVEEDOR,
      variables: { deleteValoracionproveedor_id: id },
    });
    return data.deleteValoracionProveedor;
  } catch (error) {
    throw new Error(`Error deleting valoracion: ${error}`);
  }
};