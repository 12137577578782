import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { 
  addFormulario, 
  updateFormulario,
  Formulario
} from '../../slices/formularioSlice';

interface FormularioFormProps {
  formulario: Formulario | null;
  isEditMode: boolean;
  onClose: () => void;
}

const FormularioForm: React.FC<FormularioFormProps> = ({ 
  formulario, 
  isEditMode, 
  onClose 
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [codigo, setCodigo] = useState('');
  const [version, setVersion] = useState('');
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [fechaAprobacion, setFechaAprobacion] = useState<string>(new Date().toISOString().split('T')[0]);
  const [activo, setActivo] = useState(true);
  
  const [formErrors, setFormErrors] = useState({
    codigo: '',
    version: '',
    titulo: '',
    fechaAprobacion: ''
  });

  useEffect(() => {
    if (formulario && isEditMode) {
      setCodigo(formulario.codigo);
      setVersion(formulario.version);
      setTitulo(formulario.titulo);
      setDescripcion(formulario.descripcion || '');
      // Convertir fecha a formato YYYY-MM-DD para input type="date"
      setFechaAprobacion(new Date(formulario.fechaAprobacion).toISOString().split('T')[0]);
      setActivo(formulario.activo);
    } else {
      // Valores por defecto para un nuevo formulario
      setCodigo('');
      setVersion('1.0');
      setTitulo('');
      setDescripcion('');
      setFechaAprobacion(new Date().toISOString().split('T')[0]);
      setActivo(true);
    }
  }, [formulario, isEditMode]);

  const validateForm = () => {
    const errors = {
      codigo: '',
      version: '',
      titulo: '',
      fechaAprobacion: ''
    };
    let isValid = true;

    if (!codigo.trim()) {
      errors.codigo = 'El código es requerido';
      isValid = false;
    }

    if (!version.trim()) {
      errors.version = 'La versión es requerida';
      isValid = false;
    }

    if (!titulo.trim()) {
      errors.titulo = 'El título es requerido';
      isValid = false;
    }

    if (!fechaAprobacion) {
      errors.fechaAprobacion = 'La fecha de aprobación es requerida';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    if (isEditMode && formulario) {
      dispatch(updateFormulario({
        updateFormularioId: formulario.id,
        codigo,
        version,
        titulo,
        descripcion,
        fechaAprobacion: new Date(fechaAprobacion).toISOString(),
        activo
      }));
    } else {
      dispatch(addFormulario({
        codigo,
        version,
        titulo,
        descripcion,
        fechaAprobacion: new Date(fechaAprobacion).toISOString(),
        activo
      }));
    }
    
    onClose();
  };

  return (
    <div className="w-full">
      <div className="text-xl font-bold mb-4 p-4 border-b">
        {isEditMode ? 'Editar Formulario' : 'Nuevo Formulario'}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Código
              </label>
              <input
                type="text"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
                className={`w-full p-2 border rounded-md ${formErrors.codigo ? 'border-red-500' : 'border-gray-300'}`}
              />
              {formErrors.codigo && (
                <p className="mt-1 text-sm text-red-500">{formErrors.codigo}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Versión
              </label>
              <input
                type="text"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
                className={`w-full p-2 border rounded-md ${formErrors.version ? 'border-red-500' : 'border-gray-300'}`}
              />
              {formErrors.version && (
                <p className="mt-1 text-sm text-red-500">{formErrors.version}</p>
              )}
            </div>
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Título
              </label>
              <input
                type="text"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                className={`w-full p-2 border rounded-md ${formErrors.titulo ? 'border-red-500' : 'border-gray-300'}`}
              />
              {formErrors.titulo && (
                <p className="mt-1 text-sm text-red-500">{formErrors.titulo}</p>
              )}
            </div>
            <div className="sm:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Descripción
              </label>
              <textarea
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                rows={3}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fecha de Aprobación
              </label>
              <input
                type="date"
                value={fechaAprobacion}
                onChange={(e) => setFechaAprobacion(e.target.value)}
                className={`w-full p-2 border rounded-md ${formErrors.fechaAprobacion ? 'border-red-500' : 'border-gray-300'}`}
              />
              {formErrors.fechaAprobacion && (
                <p className="mt-1 text-sm text-red-500">{formErrors.fechaAprobacion}</p>
              )}
            </div>
            <div className="flex items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={activo}
                  onChange={(e) => setActivo(e.target.checked)}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-700">Activo</span>
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2 p-4 border-t">
          <button 
            type="button" 
            onClick={onClose} 
            className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
          >
            Cancelar
          </button>
          <button 
            type="submit" 
            className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            {isEditMode ? 'Actualizar' : 'Crear'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormularioForm; 