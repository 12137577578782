import React, { useState } from 'react';
import { FiEye, FiSearch, FiMenu, FiClock, FiX } from 'react-icons/fi';
import TableComponent from '../../components/Table/TableComponent';

interface ListRegistradosProps {
    data: Array<any>;
    loading: boolean;
    onViewDocument: (comprobanteId: string, registrado?: string) => void; // Modificar para aceptar segundo parámetro
}

const ListRegistrados: React.FC<ListRegistradosProps> = ({ data, loading, onViewDocument }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedObra, setSelectedObra] = useState('');
    const [selectedRange, setSelectedRange] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    const handleSearch = () => {
        let filtered = [...data];

        try {
            // Filtrar por fecha si ambas fechas están establecidas
            if (startDate && endDate) {
                const start = new Date(startDate);
                start.setHours(0, 0, 0, 0);
                
                const end = new Date(endDate);
                end.setHours(23, 59, 59, 999);

                filtered = filtered.filter(doc => {
                    const docDate = new Date(doc.registro_fecha_creacion);
                    return docDate >= start && docDate <= end;
                });
            }

            // Filtrar por rango predefinido
            if (selectedRange && !startDate && !endDate) {
                const today = new Date();
                today.setHours(23, 59, 59, 999);
                const days = parseInt(selectedRange);
                const rangeStart = new Date();
                rangeStart.setDate(today.getDate() - days);
                rangeStart.setHours(0, 0, 0, 0);

                filtered = filtered.filter(doc => {
                    const docDate = new Date(doc.registro_fecha_creacion);
                    return docDate >= rangeStart && docDate <= today;
                });
            }

            // Filtrar por texto de búsqueda
            if (searchQuery.trim()) {
                const query = searchQuery.toLowerCase().trim();
                filtered = filtered.filter(doc => 
                    doc.registro_codigo?.toLowerCase().includes(query) ||
                    doc.orden_compra_codigo?.toLowerCase().includes(query)
                );
            }

            setFilteredData(filtered);
        } catch (error) {
            console.error('Error al filtrar:', error);
        }
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedObra('');
        setSelectedRange('');
        setStartDate('');
        setEndDate('');
        setFilteredData(data);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, isStart: boolean) => {
        const value = e.target.value;
        if (isStart) {
            setStartDate(value);
            if (endDate && new Date(value) > new Date(endDate)) {
                setEndDate(value);
            }
        } else {
            if (startDate && new Date(value) < new Date(startDate)) {
                setStartDate(value);
            }
            setEndDate(value);
        }
    };

    const getEstadoColor = (estado: string | null) => {
        switch (estado) {
            case 'APROBADO':
                return 'bg-green-100 text-green-800';
            case 'RECHAZADO':
                return 'bg-red-100 text-red-800';
            case null:
            case undefined:
            case 'PENDIENTE':
                return 'bg-gray-100 text-gray-600';
            default:
                return 'bg-yellow-100 text-yellow-800';
        }
    };

    const getEstadoIcon = (estado: string | null) => {
        switch (estado) {
            case 'RECHAZADO':
                return <FiX className="mr-1" />;
            case null:
            case undefined:
            case 'PENDIENTE':
                return <FiClock className="mr-1" />;
            default:
                return null;
        }
    };

    const tableDataRegistrados = {
        filterSelect: [false, true, false, true, false, true, false],
        filter: [true, true, true, true, true, true, false],
        headers: [
            "N° Registro",
            "Orden de Compra",
            "Serie",
            "RUC",
            "Monto",
            "Estado",
            "Acciones"
        ],
        rows: filteredData.map(doc => ({
            "N° Registro": doc.registro_codigo || 'N/A',
            "Orden de Compra": doc.orden_compra_codigo || 'N/A',
            "Serie": doc.comprobante_serie || 'N/A',
            "RUC": doc.comprobante_ruc || 'N/A',
            "Monto": doc.comprobante_monto?.toFixed(2) || '0.00',
            "Estado": (
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getEstadoColor(doc.estado_consolidado)}`}>
                    {getEstadoIcon(doc.estado_consolidado)}
                    {doc.estado_consolidado || 'PENDIENTE'}
                </span>
            ),
            "Acciones": (
                <div className='flex flex-row gap-2 justify-center'>
                    <button 
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => onViewDocument(doc.comprobante_id, "REGISTRADO")} // Pasar "REGISTRADO" como segundo argumento
                    >
                        <FiEye size={17} /> 
                    </button>
                </div>
            )
        })),
        headerClassName: 'text-center',
        rowClassName: 'text-center'
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-900"></div>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            {/* Formulario de búsqueda */}
            <div className="flex justify-center mb-6">
                <div className="flex flex-wrap gap-2 items-center">
                    <div className="relative">
                        <input
                            type="text"
                            className="w-60 border border-gray-300 rounded-l p-2"
                            placeholder="Buscar..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button 
                            className="absolute right-0 top-0 h-full bg-[#192E58] text-white px-3 rounded-r"
                            onClick={handleSearch}
                        >
                            <FiSearch className="h-5 w-5" />
                        </button>
                    </div>

                    <select
                        className="border border-gray-300 rounded p-2"
                        value={selectedObra}
                        onChange={(e) => setSelectedObra(e.target.value)}
                    >
                        <option value="">Todas las Obras</option>
                    </select>

                    <select
                        className="border border-gray-300 rounded p-2"
                        value={selectedRange}
                        onChange={(e) => setSelectedRange(e.target.value)}
                    >
                        <option value="">Rango de días</option>
                        <option value="0">Hoy</option>
                        <option value="7">Última semana</option>
                        <option value="15">Últimos 15 Días</option>
                        <option value="30">Últimos 30 Días</option>
                    </select>

                    <div className="relative">
                        <input
                            type="date"
                            className="border border-gray-300 rounded p-2 pr-8 cursor-pointer hover:border-blue-500"
                            value={startDate}
                            onChange={(e) => handleDateChange(e, true)}
                            max={endDate || undefined}
                            style={{ 
                                colorScheme: 'light',
                                WebkitAppearance: 'none',
                                MozAppearance: 'none'
                            }}
                        />
                    </div>

                    <div className="relative">
                        <input
                            type="date"
                            className="border border-gray-300 rounded p-2 pr-8 cursor-pointer hover:border-blue-500"
                            value={endDate}
                            onChange={(e) => handleDateChange(e, false)}
                            min={startDate || undefined}
                            style={{ 
                                colorScheme: 'light',
                                WebkitAppearance: 'none',
                                MozAppearance: 'none'
                            }}
                        />
                    </div>

                    <button
                        className="bg-[#192E58] text-white px-6 py-2 rounded hover:bg-[#192E58]/90"
                        onClick={handleSearch}
                    >
                        Buscar
                    </button>
                    
                    <button
                        className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700"
                        onClick={handleClear}
                    >
                        Limpiar
                    </button>

                    <button className="bg-[#192E58] p-2 rounded hover:bg-[#192E58]/90">
                        <FiMenu className="h-6 w-6 text-white" />
                    </button>
                </div>
            </div>

            {/* Tabla */}
            <div className="overflow-x-auto">
                <TableComponent
                    tableData={tableDataRegistrados}
                />
            </div>
        </div>
    );
};

export default ListRegistrados;
