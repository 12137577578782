import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_APROBACIONES = gql`
  query ListAprobacionesIngresoAlmacen {
    listAprobacionesIngresoAlmacen {
      id
      ingreso_almacen_comprobante_id {
        id
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const GET_APROBACIONES_BY_INGRESO = gql`
  query GetAprobacionesByIngresoAlmacen($ingresoId: ID!) {
    getAprobacionesByIngresoAlmacen(ingresoId: $ingresoId) {
      id
      ingreso_almacen_comprobante_id {
        id
      }
      usuario_id {
        id
        usuario
      }
      estado
      fecha
    }
  }
`;

const ADD_APROBACION = gql`
  mutation AddAprobacionIngresoAlmacen(
    $ingreso_almacen_comprobante_id: ID!
    $usuario_id: ID!
    $estado: String!
    $fecha: DateTime
  ) {
    addAprobacionIngresoAlmacen(
      ingreso_almacen_comprobante_id: $ingreso_almacen_comprobante_id
      usuario_id: $usuario_id
      estado: $estado
      fecha: $fecha
    ) {
      id
      estado
      fecha
    }
  }
`;

const UPDATE_APROBACION = gql`
  mutation UpdateAprobacionIngresoAlmacen(
    $id: ID!
    $ingreso_almacen_comprobante_id: ID
    $usuario_id: ID
    $estado: String
    $fecha: DateTime
  ) {
    updateAprobacionIngresoAlmacen(
      id: $id
      ingreso_almacen_comprobante_id: $ingreso_almacen_comprobante_id
      usuario_id: $usuario_id
      estado: $estado
      fecha: $fecha
    ) {
      id
      estado
      fecha
    }
  }
`;

const DELETE_APROBACION = gql`
  mutation DeleteAprobacionIngresoAlmacen($id: ID!) {
    deleteAprobacionIngresoAlmacen(id: $id) {
      id
    }
  }
`;

export interface AprobacionIngresoAlmacenInput {
  ingreso_almacen_comprobante_id: string;
  usuario_id: string;
  estado: string;
  fecha?: Date;
}

export interface AprobacionIngresoAlmacen {
  id: string;
  ingreso_almacen_comprobante_id: {
    id: string;
  };
  usuario_id: {
    id: string;
    usuario: string;
  };
  estado: string;
  fecha: string;
}

export const listAprobacionesIngresoAlmacenService = async () => {
  try {
    const { data } = await client.query({
      query: LIST_APROBACIONES,
      fetchPolicy: 'network-only',
    });
    return data.listAprobacionesIngresoAlmacen;
  } catch (error) {
    throw new Error(`Error fetching aprobaciones: ${error}`);
  }
};

export const getAprobacionesByIngresoAlmacenService = async (ingresoId: string) => {
  try {
    const { data } = await client.query({
      query: GET_APROBACIONES_BY_INGRESO,
      variables: { ingresoId },
      fetchPolicy: 'network-only',
    });
    return data.getAprobacionesByIngresoAlmacen;
  } catch (error) {
    throw new Error(`Error fetching aprobaciones by ingreso: ${error}`);
  }
};

export const addAprobacionIngresoAlmacenService = async (input: AprobacionIngresoAlmacenInput) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_APROBACION,
      variables: input,
    });
    return data.addAprobacionIngresoAlmacen;
  } catch (error) {
    throw new Error(`Error adding aprobacion: ${error}`);
  }
};

export const updateAprobacionIngresoAlmacenService = async (id: string, input: Partial<AprobacionIngresoAlmacenInput>) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_APROBACION,
      variables: { id, ...input },
    });
    return data.updateAprobacionIngresoAlmacen;
  } catch (error) {
    throw new Error(`Error updating aprobacion: ${error}`);
  }
};

export const deleteAprobacionIngresoAlmacenService = async (id: string) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_APROBACION,
      variables: { id },
    });
    return data.deleteAprobacionIngresoAlmacen;
  } catch (error) {
    throw new Error(`Error deleting aprobacion: ${error}`);
  }
};
