import { useCallback } from 'react';

export interface CalculationValues {
    jornada: number;
    cuadrilla: number;
    rendimiento: number;
    cantidad: number;
}

const useAPUCalculator = () => {
    const truncateToFour = (num: number): number => {
        return Math.round(num * 10000) / 10000;
    };

    // Modificamos la fórmula para calcular cantidad
    const calculateCantidad = useCallback((jornada: number, cuadrilla: number, rendimiento: number): number => {
        if (rendimiento <= 0) return 0;
        // La cantidad debe ser igual a la fórmula: (jornada * cuadrilla) / rendimiento
        return truncateToFour((jornada * cuadrilla) / rendimiento);
    }, []);

    const calculateCuadrilla = useCallback((cantidad: number, jornada: number, rendimiento: number): number => {
        if (jornada <= 0) return 0;
        // La cuadrilla debe despejarse de la fórmula anterior: cuadrilla = (cantidad * rendimiento) / jornada
        return truncateToFour((cantidad * rendimiento) / jornada);
    }, []);

    const updateValues = useCallback((
        currentValues: CalculationValues,
        field: keyof CalculationValues,
        newValue: number
    ): Partial<CalculationValues> => {
        const updates: Partial<CalculationValues> = {};

        switch (field) {
            case 'jornada':
            case 'rendimiento':
                // Recalcular cuadrilla usando la cantidad fija
                updates.cuadrilla = calculateCuadrilla(
                    currentValues.cantidad,
                    field === 'jornada' ? newValue : currentValues.jornada,
                    field === 'rendimiento' ? newValue : currentValues.rendimiento
                );
                updates[field] = truncateToFour(newValue);
                break;

            case 'cuadrilla':
                // Recalcular cantidad
                updates.cantidad = calculateCantidad(
                    currentValues.jornada,
                    newValue,
                    currentValues.rendimiento
                );
                updates.cuadrilla = truncateToFour(newValue);
                break;

            case 'cantidad':
                // Recalcular cuadrilla
                updates.cuadrilla = calculateCuadrilla(
                    newValue,
                    currentValues.jornada,
                    currentValues.rendimiento
                );
                updates.cantidad = truncateToFour(newValue);
                break;
        }

        return updates;
    }, [calculateCantidad, calculateCuadrilla]);

    const initializeValues = useCallback((
        jornada: number,
        cuadrilla: number,
        rendimiento: number
    ): CalculationValues => {
        const cantidad = calculateCantidad(jornada, cuadrilla, rendimiento);
        return {
            jornada: truncateToFour(jornada),
            cuadrilla: truncateToFour(cuadrilla),
            rendimiento: truncateToFour(rendimiento),
            cantidad
        };
    }, [calculateCantidad]);

    const verifyCalculation = useCallback((values: CalculationValues): boolean => {
        const expectedCantidad = truncateToFour((values.jornada * values.cuadrilla) / values.rendimiento);
        const isValid = values.cantidad === expectedCantidad;        
        return isValid;
    }, []);

    return {
        calculateCantidad,
        calculateCuadrilla,
        updateValues,
        initializeValues,
        verifyCalculation
    };
};

export default useAPUCalculator;
