import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RequerimientoRecursoAllResponse, listRequerimientoRecursoAllService } from '../services/requerimientoRecursoAllService';

// Definición de interfaces auxiliares
export interface ItemPadreEstado {
  padreEstado: string;
  cantidad: number;
}

// Extendemos la interfaz para garantizar que TypeScript conozca estas propiedades
export interface RequerimientoRecursoAllResponseTyped {
  requerimiento_recurso_id: string;
  requerimiento: { cantidad: number };
  solicitudCompra?: ItemPadreEstado[];
  solicitudAlmacen?: ItemPadreEstado[];
  cotizacion?: ItemPadreEstado[];
  cotizacion_proveedor?: ItemPadreEstado[];
  orden_compra?: ItemPadreEstado[];
  transferencia?: ItemPadreEstado[];
  [key: string]: unknown;
}

interface RequerimientoRecursoAllState {
  requerimientosRecursoIds: string[];
  data: Record<string, RequerimientoRecursoAllResponseTyped>;
  dataBruta: Record<string, RequerimientoRecursoAllResponse>; // Datos originales sin procesar
  loading: boolean;
  error: string | null;
}

const initialState: RequerimientoRecursoAllState = {
  requerimientosRecursoIds: [],
  data: {},
  dataBruta: {}, // Inicializar la propiedad para datos sin procesar
  loading: false,
  error: null
};

// Función auxiliar para agrupar cantidades por padreEstado
const agruparPorPadreEstado = (data: Record<string, RequerimientoRecursoAllResponse>): Record<string, RequerimientoRecursoAllResponseTyped> => {
  const resultado: Record<string, RequerimientoRecursoAllResponseTyped> = {};
  
  Object.entries(data).forEach(([key, originalValue]) => {
    // Creamos un nuevo objeto con la estructura que necesitamos
    const value = originalValue as unknown as { 
      requerimiento_recurso_id: string;
      requerimiento: { cantidad: number };
      solicitudCompra?: Array<{ padreEstado: string, cantidad: number }>;
      solicitudAlmacen?: Array<{ padreEstado: string, cantidad: number }>;
      cotizacion?: Array<{ padreEstado: string, cantidad: number }>;
      cotizacion_proveedor?: Array<{ padreEstado: string, cantidad: number }>;
      orden_compra?: Array<{ padreEstado: string, cantidad: number }>;
      transferencia?: Array<{ padreEstado: string, cantidad: number }>;
      [key: string]: unknown;
    };
    
    // Inicializamos el resultado para este key
    resultado[key] = {
      ...value
    };
    
    // Campos a agrupar
    const camposArray = ['solicitudCompra', 'solicitudAlmacen', 'cotizacion', 'cotizacion_proveedor', 'orden_compra', 'transferencia'];
    
    // Procesar cada campo que es un array
    camposArray.forEach(campo => {
      const items = value[campo];
      if (items && Array.isArray(items)) {
        // Objeto para acumular cantidades por padreEstado
        const agrupado: {[padreEstado: string]: number} = {};
        
        // Sumar cantidades para cada padreEstado
        items.forEach((item: { padreEstado: string, cantidad: number }) => {
          const { padreEstado, cantidad } = item;
          if (!agrupado[padreEstado]) {
            agrupado[padreEstado] = 0;
          }
          agrupado[padreEstado] += cantidad;
        });
        
        // Convertir de nuevo a array con formato { padreEstado, cantidad }
        resultado[key][campo] = Object.entries(agrupado).map(([padreEstado, cantidad]) => ({
          padreEstado,
          cantidad
        }));
      }
    });
  });
  
  return resultado;
};

// Thunk para consultar múltiples requerimientoRecursoId en paralelo
export const fetchRequerimientosRecursoAll = createAsyncThunk(
  'requerimientoRecursoAll/fetchAll',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { requerimientoRecursoAll } = getState() as { requerimientoRecursoAll: RequerimientoRecursoAllState };
      const { requerimientosRecursoIds } = requerimientoRecursoAll;
      
      if (requerimientosRecursoIds.length === 0) {
        return {};
      }
      
      // Usar Promise.all para consultar en paralelo todos los requerimientos
      const responses = await Promise.all(
        requerimientosRecursoIds.map(id => listRequerimientoRecursoAllService(id))
      );
      
      // Construir un objeto donde cada clave es el requerimientoRecursoId
      const result: Record<string, RequerimientoRecursoAllResponse> = {};
      responses.forEach((response, index) => {
        result[requerimientosRecursoIds[index]] = response;
      });
      
      return result;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('Error desconocido al consultar requerimientos');
    }
  }
);

export const requerimientoRecursoAllSlice = createSlice({
  name: 'requerimientoRecursoAll',
  initialState,
  reducers: {
    // Función para establecer el grupo de IDs a consultar
    setRequerimientosRecursoIds: (state, action: PayloadAction<string[]>) => {
      state.requerimientosRecursoIds = action.payload;
    },
    
    // Función para añadir un ID al grupo
    addRequerimientoRecursoId: (state, action: PayloadAction<string>) => {
      if (!state.requerimientosRecursoIds.includes(action.payload)) {
        state.requerimientosRecursoIds.push(action.payload);
      }
    },
    
    // Función para limpiar los datos almacenados
    clearRequerimientoRecursoData: (state) => {
      state.data = {};
      state.dataBruta = {}; // También limpiar los datos brutos
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequerimientosRecursoAll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRequerimientosRecursoAll.fulfilled, (state, action: PayloadAction<Record<string, RequerimientoRecursoAllResponse>>) => {
        state.loading = false;
        // Guardar los datos originales sin procesar
        state.dataBruta = action.payload;
        // Aplicar la función de agrupación antes de guardar los datos
        state.data = agruparPorPadreEstado(action.payload);
      })
      .addCase(fetchRequerimientosRecursoAll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export const { 
  setRequerimientosRecursoIds, 
  addRequerimientoRecursoId,
  clearRequerimientoRecursoData 
} = requerimientoRecursoAllSlice.actions;

export const requerimientoRecursoAllReducer = requerimientoRecursoAllSlice.reducer;
