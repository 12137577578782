import React from 'react';
import { FaLock, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const AccessDenied: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="h-[calc(100vh-6rem)] flex flex-col items-center justify-center bg-gradient-to-br from-sky-100/50  to-indigo-200/50 p-4">
      <motion.div 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-8 rounded-2xl shadow-lg max-w-md w-full text-center"
      >
        <motion.div 
          initial={{ scale: 0 }}
          animate={{ scale: 1, rotate: [0, 10, -10, 0] }}
          transition={{ delay: 0.2, duration: 0.7 }}
          className="mx-auto bg-red-100 w-24 h-24 rounded-full flex items-center justify-center mb-6"
        >
          <FaLock className="text-red-500 text-4xl" />
        </motion.div>
        
        <motion.h1 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="text-2xl font-bold text-gray-800 mb-4"
        >
          Acceso Denegado
        </motion.h1>
        
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
          className="text-gray-600 mb-6"
        >
          Lo sentimos, no tienes permisos para acceder a esta página. Por favor contacta a un administrador si crees que esto es un error.
        </motion.p>
        
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/dashboard')}
          className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg flex items-center justify-center mx-auto transition-colors"
        >
          <FaArrowLeft className="mr-2" /> Volver al inicio
        </motion.button>
      </motion.div>
    </div>
  );
};

export default AccessDenied;
