import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { OrdenServicioFecha } from '../types/ordenServicioFecha';
import {
  listOrdenServicioFechasService,
  getOrdenServicioFechaService,
  addOrdenServicioFechaService,
  updateOrdenServicioFechaService,
  getOrdenServicioFechasByOrdenIdService,
} from '../services/ordenServicioFechaService';

interface OrdenServicioFechaState {
  ordenServicioFechas: OrdenServicioFecha[];
  selectedOrdenServicioFecha: OrdenServicioFecha | null;
  loading: boolean;
  error: string | null;
}

const initialState: OrdenServicioFechaState = {
  ordenServicioFechas: [],
  selectedOrdenServicioFecha: null,
  loading: false,
  error: null,
};

// Thunks
export const fetchOrdenServicioFechas = createAsyncThunk(
  'ordenServicioFecha/fetchAll',
  async () => {
    const response = await listOrdenServicioFechasService();
    return response;
  }
);

export const fetchOrdenServicioFechaById = createAsyncThunk(
  'ordenServicioFecha/fetchById',
  async (id: string) => {
    const response = await getOrdenServicioFechaService(id);
    return response;
  }
);

export const createOrdenServicioFecha = createAsyncThunk(
  'ordenServicioFecha/create',
  async (data: {
    ordenServicioRecursoId: string;
    fechaInicio: Date;
    fechaFin: Date;
  }) => {
    const response = await addOrdenServicioFechaService(data);
    return response;
  }
);

export const updateOrdenServicioFecha = createAsyncThunk(
  'ordenServicioFecha/update',
  async (data: { id: string; fechaInicio?: Date; fechaFin?: Date }) => {
    const response = await updateOrdenServicioFechaService(data);
    return response;
  }
);

export const fetchOrdenServicioFechasByOrdenId = createAsyncThunk(
  'ordenServicioFecha/fetchByOrdenId',
  async (ordenServicioRecursoId: string) => {
    const response = await getOrdenServicioFechasByOrdenIdService(ordenServicioRecursoId);
    return response;
  }
);

const ordenServicioFechaSlice = createSlice({
  name: 'ordenServicioFecha',
  initialState,
  reducers: {
    clearSelectedOrdenServicioFecha: (state) => {
      state.selectedOrdenServicioFecha = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch All
      .addCase(fetchOrdenServicioFechas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrdenServicioFechas.fulfilled, (state, action) => {
        state.loading = false;
        state.ordenServicioFechas = action.payload;
      })
      .addCase(fetchOrdenServicioFechas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al obtener las fechas';
      })
      // Fetch By Id
      .addCase(fetchOrdenServicioFechaById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrdenServicioFechaById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedOrdenServicioFecha = action.payload;
      })
      .addCase(fetchOrdenServicioFechaById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al obtener la fecha';
      })
      // Create
      .addCase(createOrdenServicioFecha.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrdenServicioFecha.fulfilled, (state, action) => {
        state.loading = false;
        state.ordenServicioFechas.push(action.payload);
      })
      .addCase(createOrdenServicioFecha.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al crear la fecha';
      })
      // Update
      .addCase(updateOrdenServicioFecha.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOrdenServicioFecha.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.ordenServicioFechas.findIndex(
          (fecha) => fecha.orden_servicio_recurso_id === action.payload.orden_servicio_recurso_id
        );
        if (index !== -1) {
          state.ordenServicioFechas[index] = action.payload;
        }
        if (state.selectedOrdenServicioFecha?.orden_servicio_recurso_id === action.payload.orden_servicio_recurso_id) {
          state.selectedOrdenServicioFecha = action.payload;
        }
      })
      .addCase(updateOrdenServicioFecha.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al actualizar la fecha';
      })
      // Fetch By Orden Id
      .addCase(fetchOrdenServicioFechasByOrdenId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrdenServicioFechasByOrdenId.fulfilled, (state, action) => {
        state.loading = false;
        state.ordenServicioFechas = action.payload;
      })
      .addCase(fetchOrdenServicioFechasByOrdenId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Error al obtener las fechas por ID de orden';
      });
  },
});

export const { clearSelectedOrdenServicioFecha, clearError } = ordenServicioFechaSlice.actions;
export default ordenServicioFechaSlice.reducer; 