import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listRecursosPresupuestoService,
  getRecursoPresupuestoService,
  createRecursoPresupuestoService,
  updateRecursoPresupuestoService,
  deleteRecursoPresupuestoService
} from '../services/recursoPresupuestoService';

// Interfaces
export interface RecursoPresupuesto {
  id: string;
  id_recurso_presupuesto: string;
  recurso_id: string;
  nombre: string;
  recurso: {
    id: string;
    recurso_id: string;
    codigo: string;
    nombre: string;
    descripcion: string;
    fecha: string;
    cantidad: number;
    unidad_id: string;
    precio_actual: number;
    vigente: boolean;
    tipo_recurso_id: string;
    tipo_costo_recurso_id: string;
    clasificacion_recurso_id: string;
    imagenes: { id: string; file: string }[];
  };
}

interface RecursoPresupuestoState {
  recursosPresupuesto: RecursoPresupuesto[];
  selectedRecursoPresupuesto: RecursoPresupuesto | null;
  loading: boolean;
  error: string | null;
}

const initialState: RecursoPresupuestoState = {
  recursosPresupuesto: [],
  selectedRecursoPresupuesto: null,
  loading: false,
  error: null,
};

// Thunks
export const fetchRecursosPresupuesto = createAsyncThunk(
  'recursoPresupuesto/fetchRecursosPresupuesto',
  async (_, { rejectWithValue }) => {
    try {
      return await listRecursosPresupuestoService();
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Error desconocido');
    }
  }
);

export const getRecursoPresupuesto = createAsyncThunk(
  'recursoPresupuesto/getRecursoPresupuesto',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getRecursoPresupuestoService(id);
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Error desconocido');
    }
  }
);

export const createRecursoPresupuesto = createAsyncThunk(
  'recursoPresupuesto/createRecursoPresupuesto',
  async (recursoId: string, { rejectWithValue }) => {
    try {
      return await createRecursoPresupuestoService(recursoId);
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Error desconocido');
    }
  }
);

export const updateRecursoPresupuesto = createAsyncThunk(
  'recursoPresupuesto/updateRecursoPresupuesto',
  async ({ id, nombre }: { id: string; nombre: string }, { rejectWithValue }) => {
    try {
      return await updateRecursoPresupuestoService(id, nombre);
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Error desconocido');
    }
  }
);

export const deleteRecursoPresupuesto = createAsyncThunk(
  'recursoPresupuesto/deleteRecursoPresupuesto',
  async (id: string, { rejectWithValue }) => {
    try {
      return await deleteRecursoPresupuestoService(id);
    } catch (error) {
      return rejectWithValue(error instanceof Error ? error.message : 'Error desconocido');
    }
  }
);

// Slice
const recursoPresupuestoSlice = createSlice({
  name: 'recursoPresupuesto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecursosPresupuesto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRecursosPresupuesto.fulfilled, (state, action: PayloadAction<RecursoPresupuesto[]>) => {
        state.loading = false;
        state.recursosPresupuesto = action.payload;
      })
      .addCase(fetchRecursosPresupuesto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getRecursoPresupuesto.fulfilled, (state, action: PayloadAction<RecursoPresupuesto>) => {
        state.selectedRecursoPresupuesto = action.payload;
      })
      .addCase(createRecursoPresupuesto.fulfilled, (state, action: PayloadAction<RecursoPresupuesto>) => {
        state.recursosPresupuesto.push(action.payload);
      })
      .addCase(updateRecursoPresupuesto.fulfilled, (state, action: PayloadAction<RecursoPresupuesto>) => {
        const index = state.recursosPresupuesto.findIndex(r => r.id === action.payload.id);
        if (index !== -1) {
          state.recursosPresupuesto[index] = action.payload;
        }
      })
      .addCase(deleteRecursoPresupuesto.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.recursosPresupuesto = state.recursosPresupuesto.filter(
          recurso => recurso.id !== action.payload.id
        );
      });
  },
});

export const recursoPresupuestoReducer = recursoPresupuestoSlice.reducer;
