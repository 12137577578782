import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface APUTotalState {
  total: number;
}

const initialState: APUTotalState = {
  total: 0
};

const apuTotalSlice = createSlice({
  name: 'apuTotal',
  initialState,
  reducers: {
    updateTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    }
  }
});

export const { updateTotal } = apuTotalSlice.actions;
export default apuTotalSlice.reducer;
