import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import { ConsumoPDFGeneratorProps } from './consumoPDF.types';
import logo from '../../../../assets/logoInacons.png';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    backgroundColor: '#ffffff',
  },
  header: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
  },
  headerLeft: {
    width: '30%',
  },
  headerRight: {
    width: '70%',
    alignItems: 'flex-end',
  },
  logo: {
    width: 120,
    height: 40,
  },
  title: {
    fontSize: 14,
    marginBottom: 5,
    color: '#1a365d',
    fontFamily: 'Helvetica-Bold',
  },
  infoContainer: {
    marginTop: 15,
    borderWidth: 1,
    borderColor: '#e2e8f0',
    borderRadius: 4,
    padding: 12,
  },
  infoRow: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  label: {
    width: '25%',
    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
  },
  value: {
    width: '75%',
    fontSize: 9,
  },
  table: {
    marginTop: 20,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#2c5282',
    padding: 8,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e2e8f0',
    padding: 8,
  },
  colCodigo: { width: '15%', color: '#ffffff', fontSize: 9 },
  colDescripcion: { width: '45%', color: '#ffffff', fontSize: 9 },
  colCantidad: { width: '20%', color: '#ffffff', fontSize: 9 },
  colCosto: { width: '20%', color: '#ffffff', fontSize: 9 },
  cell: {
    fontSize: 9,
    color: '#000000',
  },
  observacionesText: {
    fontSize: 8,
    color: '#666666',
    marginLeft: 15,
    fontStyle: 'italic',
  },
  termsContainer: {
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: '#e2e8f0',
    borderRadius: 4,
  },
  termTitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 5,
  },
  signatures: {
    marginTop: 100, // Aumentado de 30 a 50
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signatureBox: {
    width: '30%',
    alignItems: 'center',
  },
  signatureLine: {
    borderTopWidth: 1,
    borderTopColor: '#000000',
    width: '100%',
    marginBottom: 5,
  },
});

const ConsumoPDFGenerator: React.FC<ConsumoPDFGeneratorProps> = ({ consumo }) => {
  const formatDate = (date: Date): string => {
    return date.toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const documentFileName = `ACTA-CONSUMO-${consumo.codigo}.pdf`;
  
  const isMobileOrTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile|tablet|android|ipad/i.test(userAgent);
  };

  const PDFContent = (
    <Document title={documentFileName}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Image style={styles.logo} src={logo} />
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.title}>ACTA DE CONSUMO DE RECURSOS</Text>
            <Text>{consumo.codigo}</Text>
          </View>
        </View>

        <View style={styles.infoContainer}>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Obra/Lugar:</Text>
            <Text style={styles.value}>{consumo.obra.nombre} - {consumo.obra.ubicacion}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Almacenero:</Text>
            <Text style={styles.value}>
              {`${consumo.almacenero.nombres} ${consumo.almacenero.apellidos} - DNI: ${consumo.almacenero.dni}`}
            </Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Supervisor:</Text>
            <Text style={styles.value}>
              {`${consumo.supervisor.nombres} ${consumo.supervisor.apellidos}`}
            </Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Empleado:</Text>
            <Text style={styles.value}>
              {`${consumo.empleado.nombres} ${consumo.empleado.apellidos} - DNI: ${consumo.empleado.dni}`}
            </Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.label}>Fecha:</Text>
            <Text style={styles.value}>{formatDate(consumo.fecha)}</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.colCodigo}>Código</Text>
            <Text style={styles.colDescripcion}>Descripción</Text>
            <Text style={styles.colCantidad}>Cantidad</Text>
            <Text style={styles.colCosto}>Costo Unit.</Text>
          </View>
          {consumo.recursos.map((recurso, index) => (
            <React.Fragment key={index}>
              <View style={styles.tableRow}>
                <Text style={[styles.colCodigo, styles.cell]}>{recurso.recurso_id.codigo}</Text>
                <Text style={[styles.colDescripcion, styles.cell]}>{recurso.recurso_id.nombre}</Text>
                <Text style={[styles.colCantidad, styles.cell]}>{recurso.cantidad}</Text>
                <Text style={[styles.colCosto, styles.cell]}>S/. {recurso.costo.toFixed(2)}</Text>
              </View>
              {recurso.observaciones && (
                <View style={styles.tableRow}>
                  <Text style={styles.observacionesText}>
                    Observaciones: {recurso.observaciones}
                  </Text>
                </View>
              )}
            </React.Fragment>
          ))}
        </View>

        <View style={styles.signatures}>
          <View style={styles.signatureBox}>
            <View style={styles.signatureLine} />
            <Text>Almacenero</Text>
            <Text>{consumo.almacenero.nombres}</Text>
          </View>
          <View style={styles.signatureBox}>
            <View style={styles.signatureLine} />
            <Text>Supervisor</Text>
            <Text>{consumo.supervisor.nombres}</Text>
          </View>
          <View style={styles.signatureBox}>
            <View style={styles.signatureLine} />
            <Text>Empleado</Text>
            <Text>{consumo.empleado.nombres}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return isMobileOrTablet() ? PDFContent : (
    <PDFViewer style={{ width: '100%', height: '800px' }}>
      {PDFContent}
    </PDFViewer>
  );
};

export default ConsumoPDFGenerator;
