import { gql } from '@apollo/client';
import client from '../apolloClient';

const LIST_CONSTANCIAS_COMPROBANTE = gql`
  query ListConstanciasComprobante {
    listConstanciasComprobante {
      id
      comprobante_id {
        id
        serie
        monto
      }
      pagos_orden_pago_id {
        id
        monto_pago
      }
      archivos_pago_id {
        id
        url
      }
    }
  }
`;

const GET_BY_COMPROBANTE = gql`
  query GetConstanciaComprobanteByComprobante($comprobanteId: ID!) {
    getConstanciaComprobanteByComprobante(comprobanteId: $comprobanteId) {
      id
      comprobante_id {
        id
        serie
        monto
      }
      pagos_orden_pago_id {
        id
        monto_pago
      }
      archivos_pago_id {
        id
        url
      }
    }
  }
`;

const ADD_CONSTANCIA_COMPROBANTE = gql`
  mutation AddConstanciaComprobante(
    $comprobante_id: ID!
    $pagos_orden_pago_id: ID!
    $archivos_pago_id: ID!
  ) {
    addConstanciaComprobante(
      comprobante_id: $comprobante_id
      pagos_orden_pago_id: $pagos_orden_pago_id
      archivos_pago_id: $archivos_pago_id
    ) {
      id
      comprobante_id {
        id
        serie
      }
      archivos_pago_id {
        id
      }
    }
  }
`;

const UPDATE_CONSTANCIA_COMPROBANTE = gql`
  mutation UpdateConstanciaComprobante(
    $id: ID!
    $comprobante_id: ID
    $pagos_orden_pago_id: ID
    $archivos_pago_id: ID
  ) {
    updateConstanciaComprobante(
      id: $id
      comprobante_id: $comprobante_id
      pagos_orden_pago_id: $pagos_orden_pago_id
      archivos_pago_id: $archivos_pago_id
    ) {
      id
      comprobante_id {
        id
        serie
      }
      pagos_orden_pago_id {
        id
        monto_pago
      }
      archivos_pago_id {
        id
        url
      }
    }
  }
`;

const DELETE_CONSTANCIA_COMPROBANTE = gql`
  mutation DeleteConstanciaComprobante($id: ID!) {
    deleteConstanciaComprobante(id: $id) {
      id
    }
  }
`;

export interface ConstanciaComprobanteInput {
  comprobante_id: string;
  pagos_orden_pago_id: string;
  archivos_pago_id: string;
}

export interface ConstanciaComprobante {
  id: string;
  comprobante_id: {
    id: string;
    serie: string;
    monto: number;
  };
  pagos_orden_pago_id: {
    id: string;
    monto_pago: number;
  };
  archivos_pago_id: {
    id: string;
    url: string;
  };
}

// Servicios
export const listConstanciasComprobanteService = async (): Promise<ConstanciaComprobante[]> => {
  try {
    const { data } = await client.query({
      query: LIST_CONSTANCIAS_COMPROBANTE,
      fetchPolicy: 'network-only',
    });
    return data.listConstanciasComprobante;
  } catch (error) {
    throw new Error(`Error fetching constancias comprobante: ${error}`);
  }
};

export const getByComprobanteIdService = async (comprobanteId: string): Promise<ConstanciaComprobante[]> => {
  try {
    const { data } = await client.query({
      query: GET_BY_COMPROBANTE,
      variables: { comprobanteId },
      fetchPolicy: 'network-only',
    });
    return data.getConstanciaComprobanteByComprobante;
  } catch (error) {
    throw new Error(`Error fetching constancia comprobante by comprobante: ${error}`);
  }
};

export const addConstanciaComprobanteService = async (input: ConstanciaComprobanteInput): Promise<ConstanciaComprobante> => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_CONSTANCIA_COMPROBANTE,
      variables: input,
    });
    return data.addConstanciaComprobante;
  } catch (error) {
    throw new Error(`Error adding constancia comprobante: ${error}`);
  }
};

export const updateConstanciaComprobanteService = async (
  id: string, 
  input: Partial<ConstanciaComprobanteInput>
): Promise<ConstanciaComprobante> => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_CONSTANCIA_COMPROBANTE,
      variables: { id, ...input },
    });
    return data.updateConstanciaComprobante;
  } catch (error) {
    throw new Error(`Error updating constancia comprobante: ${error}`);
  }
};

export const deleteConstanciaComprobanteService = async (id: string): Promise<{ id: string }> => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_CONSTANCIA_COMPROBANTE,
      variables: { id },
    });
    return data.deleteConstanciaComprobante;
  } catch (error) {
    throw new Error(`Error deleting constancia comprobante: ${error}`);
  }
};
