import { gql } from '@apollo/client';
import client from '../apolloClient';

const GET_FILES_BY_REFERENCIA = gql`
  query GetFilesByReferencia($referencia_id: ID!, $tipo: String!) {
    getFilesByReferencia(referencia_id: $referencia_id, tipo: $tipo) {
      id
      url
      tipo
      referencia_id
    }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id) {
      success
      message
    }
  }
`;

export interface ArchivoSustento {
  id: string;
  url: string;
  tipo: string;
  referencia_id: string;
}

export interface UploadResponse {
  success: boolean;
  message: string;
  url?: string;
  archivo_id?: string;
  isDevelopmentMode?: boolean;
}

export interface DeleteResponse {
  success: boolean;
  message: string;
}

export interface UploadFileInput {
  file: File;
  referencia_id: string;
  tipo: string;
}

export const getFilesByReferenciaService = async (referencia_id: string, tipo: string): Promise<ArchivoSustento[]> => {
  try {
    const { data } = await client.query({
      query: GET_FILES_BY_REFERENCIA,
      variables: { referencia_id, tipo },
      fetchPolicy: 'network-only' // Para asegurar datos actualizados
    });
    return data.getFilesByReferencia;
  } catch (error) {
    console.error('Error fetching files by referencia:', error);
    throw new Error(`Error al obtener archivos: ${error}`);
  }
};

export const uploadFileService = async ({ file, referencia_id, tipo }: UploadFileInput): Promise<UploadResponse> => {
  try {
    const GRAPHQL_URI = import.meta.env.VITE_GRAPHQL_URI;
    console.log('Intentando subir archivo a:', GRAPHQL_URI);
    
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: `
        mutation UploadFile($input: UploadFileInput!) {
          uploadFile(input: $input) {
            success
            message
            url
            archivo_id
          }
        }
      `,
      variables: {
        input: {
          file: null,
          referencia_id,
          tipo
        }
      }
    }));

    formData.append('map', JSON.stringify({
      "0": ["variables.input.file"]
    }));

    formData.append('0', file);

    const response = await fetch(GRAPHQL_URI, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json',
      },
      mode: 'cors'
    });

    if (!response.ok) {
      console.error('Error en la respuesta:', response.status, response.statusText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('Respuesta del servidor:', result);

    if (result.errors) {
      throw new Error(result.errors[0]?.message || 'Error al subir el archivo');
    }

    return result.data.uploadFile;
  } catch (error) {
    console.error('Error detallado en uploadFileService:', error);
    
    // Si es un error CORS, devolvemos una respuesta simulada para desarrollo
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      console.warn('Error CORS detectado, usando modo desarrollo');
      return {
        success: true,
        message: 'Archivo subido exitosamente (modo desarrollo)',
        url: URL.createObjectURL(file),
        archivo_id: `temp-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`,
        isDevelopmentMode: true
      };
    }
    throw error;
  }
};

export const deleteFileService = async (id: string): Promise<DeleteResponse> => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_FILE,
      variables: { id }
    });
    return data.deleteFile;
  } catch (error) {
    console.error('Error deleting file:', error);
    throw new Error(`Error al eliminar archivo: ${error}`);
  }
};
