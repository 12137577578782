import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  listTipoComprobantesService,
  getTipoComprobanteByIdService,
  addTipoComprobanteService,
  updateTipoComprobanteService,
  deleteTipoComprobanteService,
  TipoComprobante,
  TipoComprobanteInput
} from '../services/tipoComprobanteService';

interface TipoComprobanteState {
  tipoComprobantes: TipoComprobante[];
  selectedTipoComprobante: TipoComprobante | null;
  loading: boolean;
  error: string | null;
}

const initialState: TipoComprobanteState = {
  tipoComprobantes: [],
  selectedTipoComprobante: null,
  loading: false,
  error: null,
};

export const fetchTipoComprobantes = createAsyncThunk(
  'tipoComprobante/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await listTipoComprobantesService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchTipoComprobanteById = createAsyncThunk(
  'tipoComprobante/fetchById',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getTipoComprobanteByIdService(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addTipoComprobante = createAsyncThunk(
  'tipoComprobante/add',
  async (tipoComprobante: TipoComprobanteInput, { rejectWithValue }) => {
    try {
      return await addTipoComprobanteService(tipoComprobante);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateTipoComprobante = createAsyncThunk(
  'tipoComprobante/update',
  async ({ id, ...data }: Partial<TipoComprobanteInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateTipoComprobanteService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteTipoComprobante = createAsyncThunk(
  'tipoComprobante/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteTipoComprobanteService(id);
      return id;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const tipoComprobanteSlice = createSlice({
  name: 'tipoComprobante',
  initialState,
  reducers: {
    clearSelectedTipoComprobante: (state) => {
      state.selectedTipoComprobante = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchTipoComprobantes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTipoComprobantes.fulfilled, (state, action: PayloadAction<TipoComprobante[]>) => {
        state.loading = false;
        state.tipoComprobantes = action.payload;
      })
      .addCase(fetchTipoComprobantes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch by ID
      .addCase(fetchTipoComprobanteById.fulfilled, (state, action: PayloadAction<TipoComprobante>) => {
        state.selectedTipoComprobante = action.payload;
      })
      // Add
      .addCase(addTipoComprobante.fulfilled, (state, action: PayloadAction<TipoComprobante>) => {
        state.tipoComprobantes.push(action.payload);
      })
      // Update
      .addCase(updateTipoComprobante.fulfilled, (state, action: PayloadAction<TipoComprobante>) => {
        const index = state.tipoComprobantes.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.tipoComprobantes[index] = action.payload;
        }
      })
      // Delete
      .addCase(deleteTipoComprobante.fulfilled, (state, action: PayloadAction<string>) => {
        state.tipoComprobantes = state.tipoComprobantes.filter(item => item.id !== action.payload);
      });
  },
});

export const { clearSelectedTipoComprobante, clearError } = tipoComprobanteSlice.actions;
export const tipoComprobanteReducer = tipoComprobanteSlice.reducer;
