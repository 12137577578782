import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ComprobanteOrdenCompra,
  ComprobanteOrdenCompraInput,
  listComprobanteOrdenCompraService,
  getByComprobanteIdService,
  getByOrdenCompraIdService,
  addComprobanteOrdenCompraService,
  updateComprobanteOrdenCompraService,
  deleteComprobanteOrdenCompraService,
} from '../services/comprobanteOrdenCompraService';

interface ComprobanteOrdenCompraState {
  items: ComprobanteOrdenCompra[];
  byComprobante: ComprobanteOrdenCompra[];
  byOrdenCompra: ComprobanteOrdenCompra[];
  loading: boolean;
  error: string | null;
  loadingByComprobante: boolean;
  errorByComprobante: string | null;
  loadingByOrdenCompra: boolean;
  errorByOrdenCompra: string | null;
}

const initialState: ComprobanteOrdenCompraState = {
  items: [],
  byComprobante: [],
  byOrdenCompra: [],
  loading: false,
  error: null,
  loadingByComprobante: false,
  errorByComprobante: null,
  loadingByOrdenCompra: false,
  errorByOrdenCompra: null,
};

export const fetchComprobanteOrdenCompras = createAsyncThunk(
  'comprobanteOrdenCompra/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await listComprobanteOrdenCompraService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchByComprobanteId = createAsyncThunk(
  'comprobanteOrdenCompra/fetchByComprobanteId',
  async (comprobante_id: string, { rejectWithValue }) => {
    try {
      return await getByComprobanteIdService(comprobante_id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchByOrdenCompraId = createAsyncThunk(
  'comprobanteOrdenCompra/fetchByOrdenCompraId',
  async (orden_compra_id: string, { rejectWithValue }) => {
    try {
      return await getByOrdenCompraIdService(orden_compra_id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const addComprobanteOrdenCompra = createAsyncThunk(
  'comprobanteOrdenCompra/add',
  async (data: ComprobanteOrdenCompraInput, { rejectWithValue }) => {
    try {
      return await addComprobanteOrdenCompraService(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateComprobanteOrdenCompra = createAsyncThunk(
  'comprobanteOrdenCompra/update',
  async ({ id, ...data }: Partial<ComprobanteOrdenCompraInput> & { id: string }, { rejectWithValue }) => {
    try {
      return await updateComprobanteOrdenCompraService(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteComprobanteOrdenCompra = createAsyncThunk(
  'comprobanteOrdenCompra/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteComprobanteOrdenCompraService(id);
      return { id };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const comprobanteOrdenCompraSlice = createSlice({
  name: 'comprobanteOrdenCompra',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchComprobanteOrdenCompras.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchComprobanteOrdenCompras.fulfilled, (state, action: PayloadAction<ComprobanteOrdenCompra[]>) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchComprobanteOrdenCompras.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch by comprobante
      .addCase(fetchByComprobanteId.pending, (state) => {
        state.loadingByComprobante = true;
        state.errorByComprobante = null;
      })
      .addCase(fetchByComprobanteId.fulfilled, (state, action: PayloadAction<ComprobanteOrdenCompra[]>) => {
        state.loadingByComprobante = false;
        state.byComprobante = action.payload;
      })
      .addCase(fetchByComprobanteId.rejected, (state, action) => {
        state.loadingByComprobante = false;
        state.errorByComprobante = action.payload as string;
      })
      // Fetch by orden compra
      .addCase(fetchByOrdenCompraId.pending, (state) => {
        state.loadingByOrdenCompra = true;
        state.errorByOrdenCompra = null;
      })
      .addCase(fetchByOrdenCompraId.fulfilled, (state, action: PayloadAction<ComprobanteOrdenCompra[]>) => {
        state.loadingByOrdenCompra = false;
        state.byOrdenCompra = action.payload;
      })
      .addCase(fetchByOrdenCompraId.rejected, (state, action) => {
        state.loadingByOrdenCompra = false;
        state.errorByOrdenCompra = action.payload as string;
      })
      // Add
      .addCase(addComprobanteOrdenCompra.fulfilled, (state, action: PayloadAction<ComprobanteOrdenCompra>) => {
        state.items.push(action.payload);
      })
      // Update
      .addCase(updateComprobanteOrdenCompra.fulfilled, (state, action: PayloadAction<ComprobanteOrdenCompra>) => {
        const index = state.items.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      // Delete
      .addCase(deleteComprobanteOrdenCompra.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.items = state.items.filter(item => item.id !== action.payload.id);
      });
  },
});

export const comprobanteOrdenCompraReducer = comprobanteOrdenCompraSlice.reducer;
