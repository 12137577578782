import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiRefreshCw, FiEdit, FiEye } from 'react-icons/fi';
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Buttons/Button';
import Modal from '../../components/Modal/Modal';
import TableCustom from '../../components/Table/TableCustom';
import LoaderPage from '../../components/Loader/LoaderPage';
import OrdenServicioDetalle from './OrdenServicioDetalle';
import OrdenServicioDetalleReadOnly from './OrdenServicioDetalleReadOnly';

import { fetchOrdenCompras } from '../../slices/ordenServicioSlice';
import { fetchOrdenCompraRecursosByOrdenId } from '../../slices/ordenCompraRecursosSlice';
import { RootState, AppDispatch } from '../../store/store';

import { OrdenCompra } from '../../types/ordenCompra';

const formatDate = (date: string): string => {
    return new Date(date).toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};

const OrdenServicioPage: React.FC = (): JSX.Element => {
    const dispatch = useDispatch<AppDispatch>();
    const { ordenCompras, loading, error } = useSelector((state: RootState) => state.ordenCompra);

    const [editingOrdenCompra, setEditingOrdenCompra] = useState<OrdenCompra | null>(null);
    const [activeFilter, setActiveFilter] = useState('todos');
    const [showDetalleModal, setShowDetalleModal] = useState(false);

    useEffect(() => {
        dispatch(fetchOrdenCompras());
    }, [dispatch]);

    const handleView = useCallback((ordenCompra: OrdenCompra) => {
        setEditingOrdenCompra(ordenCompra);
        setShowDetalleModal(true);
        dispatch(fetchOrdenCompraRecursosByOrdenId(ordenCompra.id))
            .unwrap()
            .catch(error => console.error('Error al cargar los detalles:', error));
    }, [dispatch]);

    const handleRefresh = useCallback(() => {
        dispatch(fetchOrdenCompras());
    }, [dispatch]);

    const handleCloseDetalleModal = useCallback(() => {
        setShowDetalleModal(false);
        setEditingOrdenCompra(null);
    }, []);

    const navigate = useNavigate();
    const handleNavigateToPayments = (ordenCompra: OrdenCompra) => {
        navigate(`/dashboard/ordenPago`, {
            state: {
                ordenCompraId: ordenCompra.id,
                codigoOrden: ordenCompra.codigo_orden
            }
        });
    };

    const filteredOrdenCompras = useMemo(() => {
        const serviciosOnly = ordenCompras.filter(oc => oc.tipo === "servicio");
        switch (activeFilter) {
            case 'activas': return serviciosOnly.filter(oc => oc.estado);
            case 'inactivas': return serviciosOnly.filter(oc => !oc.estado);
            default: return serviciosOnly;
        }
    }, [ordenCompras, activeFilter]);

    const tableData = useMemo(() => ({
        filter: [true, true, true, true, true,],
        headers: ["codigo", "descripción", "fecha inicio", "fecha fin", "estado", "pagos", "opciones"],
        rows: filteredOrdenCompras.map(oc => ({
            codigo: oc.codigo_orden,
            descripción: oc.tipo,
            "fecha inicio": formatDate(oc.fecha_ini),
            "fecha fin": formatDate(oc.fecha_fin),
            estado: (
                <span className={`capitalize font-bold ${oc.estado === 'proceso' ? 'text-green-500' : oc.estado === 'pendiente' ? 'text-yellow-500' : oc.estado === 'parcial' ? 'text-orange-500' : oc.estado === 'completada' ? 'text-blue-500' : 'text-red-500'}`}>{oc.estado}</span>
            ),
            pagos: (
                <button
                    className="text-blue-500 hover:text-blue-700 transition-colors"
                    onClick={() => handleNavigateToPayments(oc)}
                    title="Ver pagos"
                >
                    <LiaMoneyBillWaveAltSolid size={22} className="text-green-500" />
                </button>
            ),
            opciones: (
                <div className='flex flex-row gap-2'>
                    <button 
                        className='text-blue-500' 
                        onClick={() => handleView(oc as OrdenCompra)}
                        title={oc.estado === 'pendiente' || oc.estado === 'parcial' ? 'Editar' : 'Ver detalles'}
                    >
                        {oc.estado === 'pendiente' || oc.estado === 'parcial' ? (
                            <FiEdit className='size-4' />
                        ) : (
                            <FiEye className='size-4' />
                        )}
                    </button>
                </div>
            )
        }))
    }), [filteredOrdenCompras, handleView]);

    if (loading) return <LoaderPage />;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="flex flex-col h-full transition-opacity duration-500 ease-in-out">
            <div className="flex flex-1 overflow-hidden rounded-xl">
                <main className="w-full flex flex-col flex-grow p-4 bg-white/80 overflow-hidden">
                    <div className="text-white pb-4 flex items-center justify-between">
                        <div className="flex items-center gap-4">
                            <h1 className="text-2xl font-bold text-gray-900">Órdenes de Servicio</h1>
                        </div>
                        <Button
                            text='Actualizar'
                            color='blanco'
                            onClick={handleRefresh}
                            className="rounded w-auto"
                            icon={<FiRefreshCw className="text-green-500 text-center h-3 w-3" />}
                        />
                    </div>

                    <div className="mb-4 space-x-2">
                        <button
                            className={`px-3 py-1 ${activeFilter === 'activas' ? 'bg-green-600' : 'bg-green-500'} text-white rounded-md text-xs`}
                            onClick={() => setActiveFilter('activas')}
                        >
                            Activas
                        </button>
                        <button
                            className={`px-3 py-1 ${activeFilter === 'inactivas' ? 'bg-red-600' : 'bg-red-500'} text-white rounded-md text-xs`}
                            onClick={() => setActiveFilter('inactivas')}
                        >
                            Inactivas
                        </button>
                        <button
                            className={`px-3 py-1 ${activeFilter === 'todos' ? 'bg-blue-600' : 'bg-blue-500'} text-white rounded-md text-xs`}
                            onClick={() => setActiveFilter('todos')}
                        >
                            Todas
                        </button>
                    </div>
                    <div className="flex-grow border rounded-lg overflow-hidden">
                        <div className="h-full overflow-auto">
                            <TableCustom tableData={tableData} />
                        </div>
                    </div>
                </main>
            </div>

            {showDetalleModal && editingOrdenCompra && (
                <Modal
                    title="Detalle de Orden de Servicio"
                    isOpen={showDetalleModal}
                    onClose={handleCloseDetalleModal}
                    className='w-70'
                >
                    {editingOrdenCompra.estado === 'pendiente' || editingOrdenCompra.estado === 'parcial' ? (
                        <OrdenServicioDetalle 
                            ordenCompra={editingOrdenCompra} 
                            onSuccess={handleRefresh}
                            onClose={handleCloseDetalleModal}
                        />
                    ) : (
                        <OrdenServicioDetalleReadOnly ordenCompra={editingOrdenCompra} />
                    )}
                </Modal>
            )}
        </div>
    );
};

export default OrdenServicioPage;