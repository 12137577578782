import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { getPartidasByProyecto, Titulo } from '../../../slices/tituloSlice';
import { getComposicionesApuByTitulo } from '../../../slices/composicionApuSlice';
import { BiSearchAlt } from 'react-icons/bi';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import ViewToggleButton from './componentsPartida/ViewToggleButton';
import RecursosDetail from './componentsPartida/RecursosPartidaDetail';

interface PartidasListProps {
    id_proyecto: string;
    onToggleView: () => void;
    requerimiento_id: string;
    selectedPartidaId: string | null;
    onClearSelectedPartida: () => void;  // Nueva prop
    onSelectPartida: (partidaId: string) => void;
}

const PartidasList: React.FC<PartidasListProps> = ({ 
    id_proyecto, 
    onToggleView, 
    requerimiento_id,
    selectedPartidaId,
    onClearSelectedPartida,
    onSelectPartida  // Añadir esta prop al destructuring
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { titulos: partidas, loading } = useSelector((state: RootState) => state.titulo);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState<{
        key: 'item' | 'descripcion' | 'id_titulo';
        direction: 'asc' | 'desc';
    }>({ key: 'item', direction: 'asc' });

    useEffect(() => {
        if (partidas.length === 0) {
            dispatch(getPartidasByProyecto(id_proyecto));
        }
    }, [dispatch, id_proyecto]);

    useEffect(() => {
        if (selectedPartidaId) {
            dispatch(getComposicionesApuByTitulo({
                id_titulo: selectedPartidaId,
                id_proyecto
            }));
        }
    }, [selectedPartidaId, id_proyecto, dispatch]);

    const filteredPartidas = partidas
        .filter(partida => partida.tipo === 'PARTIDA')
        .filter(partida =>
            partida.descripcion.toLowerCase().includes(searchTerm.toLowerCase()) ||
            partida.id_titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
            partida.item.toLowerCase().includes(searchTerm.toLowerCase()) 
        );

    const sortedPartidas = [...filteredPartidas].sort((a, b) => {
        if (sortConfig.direction === 'asc') {
            return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
        }
        return a[sortConfig.key] < b[sortConfig.key] ? -1 : 1;
    });

    const handleSort = (key: 'item' | 'descripcion' | 'id_titulo') => {
        setSortConfig(current => ({
            key,
            direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handlePartidaClick = async (partida: Titulo) => {     
        dispatch(getComposicionesApuByTitulo({
            id_titulo: partida.id_titulo,
            id_proyecto
        }));
        
        setTimeout(() => {
            onSelectPartida(partida.id_titulo); // Usar la prop aquí
        }, 100);
    };

    const SortIcon = ({ column }: { column: string }) => {
        if (sortConfig.key !== column) return null;
        return sortConfig.direction === 'asc' ? <FaSortAmountUp /> : <FaSortAmountDown />;
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (selectedPartidaId) {
        const selectedPartida = partidas.find(p => p.id_titulo === selectedPartidaId);
        if (selectedPartida) {
            return (
                <div>
                    <button
                        onClick={onClearSelectedPartida}
                        className="mb-4 px-4 py-2 text-sm bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
                    >
                        ← Volver a lista de partidas
                    </button>
                    <RecursosDetail
                        partidaInfo={selectedPartida}
                        requerimiento_id={requerimiento_id}
                        onBack={onToggleView}
                    />
                </div>
            );
        }
    }

    return (
        <div className="w-full rounded-lg shadow-lg p-1">
            <ViewToggleButton
                activeView="partidas"
                onToggle={onToggleView}
            />

            <div className="p-0">
                <div className="mb-2 sticky top-0 bg-white/80 p-1 shadow-md rounded-lg">
                    <div className="flex flex-col gap-3 p-2">
                        <div className="relative flex-1">
                            <input
                                type="text"
                                placeholder="Buscar partidas..."
                                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <div className='absolute right-3 top-2.5 text-gray-400'>
                            <BiSearchAlt />
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="overflow-y-auto max-h-[calc(100vh-28rem)]">
                    <div className="relative px-0.5">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50 sticky top-0 z-10">
                                <tr className="grid grid-cols-12">
                                    <th
                                        className="col-span-3 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 bg-gray-50"
                                        onClick={() => handleSort('item')}
                                    >
                                        <div className="flex items-center gap-2">
                                            Ítem
                                            <SortIcon column="item" />
                                        </div>
                                    </th>
                                    <th
                                        className="col-span-3 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 bg-gray-50"
                                        onClick={() => handleSort('id_titulo')}
                                    >
                                        <div className="flex items-center gap-2">
                                            Codigo
                                            <SortIcon column="codigo" />
                                        </div>
                                    </th>
                                    <th
                                        className="col-span-6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 bg-gray-50"
                                        onClick={() => handleSort('descripcion')}
                                    >
                                        <div className="flex items-center gap-2">
                                            Descripción
                                            <SortIcon column="descripcion" />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 relative">
                                <AnimatePresence>
                                    {sortedPartidas.map((partida) => (
                                        <motion.tr
                                            key={partida.id_titulo}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            whileHover={{ scale: 1.01 }}
                                            className="grid grid-cols-12 hover:bg-gray-50 transition-colors cursor-pointer"
                                            onClick={() => handlePartidaClick(partida)}
                                        >
                                            <td className="col-span-3 px-1 pl-4 py-3 text-xs text-gray-900">
                                                {partida.item}
                                            </td>
                                            <td className="col-span-3 px-1 py-3 text-xs text-gray-900">
                                                {partida.id_titulo}
                                            </td>
                                            <td className="col-span-6 px-1 py-3 pr-3 text-xs text-gray-900">
                                                {partida.descripcion}
                                            </td>
                                        </motion.tr>
                                    ))}
                                </AnimatePresence>
                            </tbody>
                        </table>
                    </div>
                </div>

                {sortedPartidas.length === 0 && (
                    <div className="text-center py-10">
                        <p className="text-gray-500">No se encontraron partidas</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PartidasList;
