import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { consultarRucService } from '../../../services/proveedorService';
import { fetchTipoComprobantes } from '../../../slices/tipoComprobanteSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { addComprobante } from '../../../slices/comprobanteSlice';
import { uploadComprobanteArchivo } from '../../../slices/comprobanteArchivosSlice';
import MultipleFileUploader from '../FileUploader/MultipleFileUploader';
import { FiFolderPlus, FiLoader } from 'react-icons/fi'; 
import Toast from '../../../components/Toast/Toast';
import { addComentario } from '../../../slices/comentarioSlice'; 
//import { addComprobanteOrdenCompra } from '../../../slices/comprobanteOrdenCompraSlice';

interface OrdenCompraEntry {
  id: string;
  codigo: string;
  monto: number;
}

interface SubirComprobanteProps {
  ordenCompraId: string | undefined;
  onClose: () => void;
  setDocumentoInput: (value: string) => void;
  setDocumentoMonto: (value: string) => void;
  ordenCompraEntries: OrdenCompraEntry[]; // Nueva prop
  onComprobanteCreated?: (comprobanteId: string, serie: string, monto: number, documentoData: any) => void; // Nueva prop
  documentoInfo: any;
  mode?: 'create' | 'edit';
  initialData?: {
    id?: string;
    fecha_creacion?: string;
    fecha_emision?: string;
    comprobante_tipo_id?: {
      id: string;
      nombre: string;
    };
    tipo_moneda_id?: number;
    serie?: string;
    monto?: number;
    fecha_vencimiento?: string;
  };
}

const SubirComprobante: React.FC<SubirComprobanteProps> = ({ 
  ordenCompraId, 
  onClose,
  //setDocumentoInput,
  //setDocumentoMonto,
  ordenCompraEntries,
  onComprobanteCreated, // Nuevo parámetro
  documentoInfo,
  mode = 'create',
  initialData
}) => {
  const [rucValue, setRucValue] = useState(documentoInfo.ruc || ''); // Inicializar como string vacío
  const [razonSocialValue, setRazonSocialValue] = useState(documentoInfo.razonSocial || ''); // Inicializar como string vacío
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();  // Tipamos el dispatch
  const tipoComprobantes = useSelector((state: RootState) => state.tipoComprobante.tipoComprobantes);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    fecha_creacion: new Date(),
    fecha_emision: documentoInfo.fechaEmision || '',
    order_compra_id: ordenCompraId, // Inicializamos con el ID recibido
    comprobante_tipo_id: documentoInfo.tipoComprobante || '',
    tipo_moneda_id: 1, // Valor por defecto
    serie: documentoInfo.serie || '',
    monto: documentoInfo.monto ? Number(documentoInfo.monto) : 0,
    fecha_vencimiento: documentoInfo.fechaVencimiento || '',  // Añadir fecha de vencimiento
    monto_sin_igv: documentoInfo.montoSinIgv ? Number(documentoInfo.montoSinIgv) : 0,      // Añadir monto sin IGV
  });
 // const [savedComprobanteId, setSavedComprobanteId] = useState<string | null>(null);
  const [additionalFiles, setAdditionalFiles] = useState<File[]>([]);
  const [toast, setToast] = useState<{
    show: boolean;
    message: string;
    variant: 'success' | 'danger' | 'warning' | 'info';
  }>({
    show: false,
    message: '',
    variant: 'info'
  });

  const showToast = (message: string, variant: 'success' | 'danger' | 'warning' | 'info') => {
    setToast({ show: true, message, variant });
  };

   const userId = useSelector((state: RootState) => state.user.id);

  // Actualizamos formData cuando cambia ordenCompraId
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      order_compra_id: ordenCompraId
    }));
  }, [ordenCompraId]);

  useEffect(() => {
    if (mode === 'edit' && initialData) {
      setFormData({
        fecha_creacion: new Date(initialData.fecha_creacion || ''),
        fecha_emision: initialData.fecha_emision || '',
        comprobante_tipo_id: initialData.comprobante_tipo_id?.id || '',
        tipo_moneda_id: initialData.tipo_moneda_id || 1,
        serie: initialData.serie || '',
        monto: initialData.monto || 0,
        fecha_vencimiento: initialData.fecha_vencimiento || '',
        monto_sin_igv: 0, // Ajustar según tus necesidades
        order_compra_id: ordenCompraId
      });
    }
  }, [mode, initialData, ordenCompraId]);

  useEffect(() => {
    if (mode === 'edit' && initialData) {
      // Establecer los valores iniciales del formulario
      setFormData({
        fecha_creacion: new Date(initialData.fecha_creacion || ''),
        fecha_emision: initialData.fecha_emision || '',
        comprobante_tipo_id: initialData.comprobante_tipo_id?.id || '',
        tipo_moneda_id: initialData.tipo_moneda_id || 1,
        serie: initialData.serie || '',
        monto: initialData.monto || 0,
        fecha_vencimiento: initialData.fecha_vencimiento || '',
        monto_sin_igv: documentoInfo.montoSinIgv ? Number(documentoInfo.montoSinIgv) : 0,
        order_compra_id: ordenCompraId
      });

      // Establecer otros valores iniciales
      setRucValue(documentoInfo.ruc || '');
      setRazonSocialValue(documentoInfo.razonSocial || '');
      setDescripcion(documentoInfo.descripcion || '');
    }
  }, [mode, initialData, documentoInfo, ordenCompraId]);

  useEffect(() => {
    if (mode === 'edit' && initialData) {
      setFormData({
        fecha_creacion: new Date(),
        fecha_emision: initialData.fecha_emision || '',
        comprobante_tipo_id: initialData.comprobante_tipo_id?.id || '',
        tipo_moneda_id: initialData.tipo_moneda_id || 1,
        serie: initialData.serie || '',
        monto: initialData.monto || 0,
        fecha_vencimiento: initialData.fecha_vencimiento || '',
        monto_sin_igv: documentoInfo.montoSinIgv ? Number(documentoInfo.montoSinIgv) : 0,
        order_compra_id: ordenCompraId
      });

      // Establecer otros valores iniciales
      setRucValue(documentoInfo.ruc || '');
      setRazonSocialValue(documentoInfo.razonSocial || '');
      setDescripcion(documentoInfo.descripcion || '');
    }
  }, [mode, initialData, documentoInfo, ordenCompraId]);

  const consultarRuc = async (rucNumber: string) => {
    if (rucNumber.length === 11) {
      setLoading(true);
      setError(null);
      try {
        const response = await consultarRucService(rucNumber);
        if (response && response.razonSocial) {
          setRazonSocialValue(response.razonSocial);
        } else {
          setRazonSocialValue(''); // Limpiar el campo si no hay razón social
          setError('RUC no encontrado');
        }
      } catch (err) {
        setRazonSocialValue(''); // Limpiar el campo en caso de error
        setError('Error al consultar RUC');
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      setRazonSocialValue(''); // Limpiar el campo si el RUC no tiene 11 dígitos
    }
  };

  const handleRucChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRucValue(value);
    consultarRuc(value);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      
      // Crear URL para preview y mostrar automáticamente
      if (file.type === 'application/pdf') {
        const fileUrl = URL.createObjectURL(file);
        setPreviewUrl(fileUrl);
      } else {
        setPreviewUrl(null);
      }
    }
  };

  // Limpiar URL al desmontar
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    dispatch(fetchTipoComprobantes() as any);
  }, [dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'monto' || name === 'monto_sin_igv' ? parseFloat(value) || 0 : value
    }));
  };

  // Añadir este estado para la descripción
  const [descripcion, setDescripcion] = useState(documentoInfo.descripcion || '');

  // Añadir el manejador para la descripción
  const handleDescripcionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescripcion(e.target.value);
  };

  // Calcular el total de órdenes de compra
  const totalOrdenesCompra = ordenCompraEntries.reduce((sum, entry) => sum + entry.monto, 0);

  const validateForm = (): boolean => {
    if (!rucValue || rucValue.length !== 11) {
      showToast('El RUC debe tener 11 dígitos', 'warning');
      return false;
    }

    if (!razonSocialValue) {
      showToast('La razón social es requerida', 'warning');
      return false;
    }

    if (!formData.comprobante_tipo_id) {
      showToast('Debe seleccionar un tipo de comprobante', 'warning');
      return false;
    }

    if (!formData.serie) {
      showToast('El número de serie es requerido', 'warning');
      return false;
    }

    if (!formData.fecha_emision) {
      showToast('La fecha de emisión es requerida', 'warning');
      return false;
    }

    if (!formData.fecha_vencimiento) {
      showToast('La fecha de vencimiento es requerida', 'warning');
      return false;
    }

    if (formData.monto <= 0) {
      showToast('El monto debe ser mayor a 0', 'warning');
      return false;
    }

    if (formData.monto_sin_igv <= 0) {
      showToast('El monto sin IGV debe ser mayor a 0', 'warning');
      return false;
    }

    if (!selectedFile) {
      showToast('Debe subir una factura', 'warning');
      return false;
    }

    // Validar que fecha de vencimiento sea posterior a fecha de emisión
    const fechaEmision = new Date(formData.fecha_emision);
    const fechaVencimiento = new Date(formData.fecha_vencimiento);
    if (fechaVencimiento < fechaEmision) {
      showToast('La fecha de vencimiento debe ser posterior a la fecha de emisión', 'warning');
      return false;
    }

    return true;
  };

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    if (formData.monto > totalOrdenesCompra) {
      showToast('El monto no puede exceder el total de las órdenes de compra', 'warning');
      return;
    }

    setIsSaving(true); // Activar loading

    try {
      const comprobanteData = {
        ...formData,
        fecha_emision: formData.fecha_emision ? new Date(formData.fecha_emision) : new Date(),
        fecha_vencimiento: new Date(formData.fecha_vencimiento),
        ruc: rucValue,
        razon_social: razonSocialValue,
      };
      
      delete comprobanteData.order_compra_id;
      
      const savedComprobante = await dispatch(addComprobante(comprobanteData)).unwrap();

      if (savedComprobante?.id) {
        // Subir archivos y guardar comentarios
        if (descripcion.trim()) {
          await dispatch(addComentario({
            usuario_id: userId || 'no-user-id',
            referencia_id: savedComprobante.id,
            tabla: 'comprobante',
            comentario: descripcion
          })).unwrap();
        }

        if (selectedFile) {
          await dispatch(uploadComprobanteArchivo({
            tipo: 'FACTURA',
            comprobante_id: savedComprobante.id,
            file: selectedFile
          })).unwrap();
        }

        // Subir archivos adicionales
        if (additionalFiles.length > 0) {
          const uploadPromises = additionalFiles.map(file => 
            dispatch(uploadComprobanteArchivo({
              tipo: 'OTROS',
              comprobante_id: savedComprobante.id,
              file: file
            })).unwrap()
          );

          await Promise.all(uploadPromises);
        }

        // Llamar al callback con la información
        const documentoData = {
          ruc: rucValue,
          razonSocial: razonSocialValue,
          tipoComprobante: formData.comprobante_tipo_id,
          fechaEmision: formData.fecha_emision,
          fechaVencimiento: formData.fecha_vencimiento,
          montoSinIgv: formData.monto_sin_igv.toString(),
          serie: formData.serie,
          monto: formData.monto.toString(),
          descripcion: descripcion
        };

        onComprobanteCreated?.(
          savedComprobante.id, 
          formData.serie, 
          formData.monto,
          documentoData
        );

        showToast('Comprobante guardado exitosamente', 'success');
        setTimeout(() => onClose?.(), 1000);
      }
    } catch (error) {
      console.error('Error al guardar:', error);
      showToast('Error al guardar el comprobante', 'danger');
    } finally {
      setIsSaving(false); // Desactivar loading
    }
  };

  const handleClearForm = () => {
    const confirmar = window.confirm('¿Está seguro que desea limpiar todos los campos del formulario?');
    if (confirmar) {
      // Limpiar todos los campos
      setFormData({
        fecha_creacion: new Date(),
        fecha_emision: '',
        order_compra_id: '',
        comprobante_tipo_id: '',
        tipo_moneda_id: 1,
        serie: '',
        monto: 0,
        fecha_vencimiento: '',
        monto_sin_igv: 0,
      });
      setRucValue('');
      setRazonSocialValue('');
      setSelectedFile(null);
      setPreviewUrl(null);
      setAdditionalFiles([]);
      setDescripcion(''); // Limpiar la descripción
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      showToast('Se han limpiado todos los campos', 'info');
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      {toast.show && (
        <Toast
          message={toast.message}
          variant={toast.variant}
          duration={3000}
          index={0}
          onClose={() => setToast(prev => ({ ...prev, show: false }))}
        />
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm mb-1">Ruc</label>
              <input 
                type="text" 
                value={rucValue}
                onChange={handleRucChange}
                className="w-full border-[#192E58] border rounded-md p-2"
                maxLength={11}
                placeholder="Ingrese RUC"
              />
              {loading && <span className="text-sm text-blue-500">Consultando RUC...</span>}
              {error && <span className="text-sm text-red-500">{error}</span>}
            </div>

            <div>
              <label className="block text-sm mb-1">Tipo de Comprobante</label>
              <select 
                name="comprobante_tipo_id"
                value={formData.comprobante_tipo_id}
                onChange={handleInputChange}
                className="w-full border-[#192E58] border rounded-md p-2 appearance-none bg-white"
              >
                <option value="">Seleccionar</option>
                {tipoComprobantes.map((tipo) => (
                  <option key={tipo.id} value={tipo.id}>
                    {tipo.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm mb-1">Fecha emisión</label>
              <input 
                type="date"
                name="fecha_emision"
                value={formData.fecha_emision}
                onChange={handleInputChange}
                className="w-full border-[#192E58] border rounded-md p-2"
              />
            </div>

            <div>
              <label className="block text-sm mb-1">Monto</label>
              <input 
                type="number"
                name="monto"
                value={formData.monto}
                onChange={handleInputChange}
                className="w-full border-[#192E58] border rounded-md p-2"
              />
            </div>

            <div>
              <label className="block text-sm mb-1">Condicion de Pago</label>
              <input 
                type="text"
                className="w-full border-[#192E58] border rounded-md p-2"
              />
            </div>

            <div className="relative">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                className="hidden"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
              />
              <button 
                type="button" 
                onClick={handleUploadClick}
                className={`flex items-center border-2 border-[#192E58] rounded-md p-2 space-x-2 w-[80%] ${
                  selectedFile ? 'bg-[#192E58] text-white' : 'text-[#192E58]'
                }`}
              >
                <span className='ml-5 mr-20'>{selectedFile ? selectedFile.name : 'Subir Factura'}</span>
                <FiFolderPlus className="w-5 h-5" />
              </button>
              
              {previewUrl && (
                <div className="mt-4 border rounded-md overflow-hidden h-50">
                  <iframe
                    src={previewUrl}
                    className="w-full h-full border-0"
                    title="Vista previa del documento"
                  />
                </div>
              )}
            </div>

          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm mb-1">Razon Social</label>
              <input 
                type="text"
                value={razonSocialValue}
                className="w-full border-[#192E58] border rounded-md p-2"
                readOnly
              />
            </div>

            <div>
              <label className="block text-sm mb-1">N° Serie</label>
              <input 
                type="text"
                name="serie"
                value={formData.serie}
                onChange={handleInputChange}
                className="w-full border-[#192E58] border rounded-md p-2"
              />
            </div>

            <div>
              <label className="block text-sm mb-1">Fecha vencimiento</label>
              <div className="relative">
                <input 
                  type="date"
                  name="fecha_vencimiento"
                  value={formData.fecha_vencimiento}
                  onChange={handleInputChange}
                  className="w-full border-[#192E58] border rounded-md p-2"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm mb-1">Monto sin IGV</label>
              <input 
                type="number"
                name="monto_sin_igv"
                value={formData.monto_sin_igv}
                onChange={handleInputChange}
                className="w-full border-[#192E58] border rounded-md p-2"
              />
            </div>

            <div>
              <label className="block text-sm mb-1">Agente de retencion</label>
              <input 
                type="text"
                className="w-full border-[#192E58] border rounded-md p-2"
              />
            </div>

            <div className="flex items-center space-x-2 text-[#192E58]">
              <MultipleFileUploader 
                onFilesSelected={setAdditionalFiles}
                onUploadComplete={() => {
          
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm mb-1">Descripcion</label>
          <textarea 
            value={descripcion}
            onChange={handleDescripcionChange}
            className="w-full border-[#192E58] border rounded-md p-2 h-24"
          />
        </div>

        <div className="flex justify-end space-x-4">
          <button 
            type="submit" 
            disabled={isSaving}
            className="px-8 py-1 bg-[#192E58] text-white rounded-md disabled:opacity-50 flex items-center justify-center"
          >
            {isSaving ? (
              <>
                <FiLoader className="animate-spin mr-2" />
                Guardando...
              </>
            ) : 'Guardar'}
          </button>
          <button 
            type="button" 
            onClick={handleClearForm}
            disabled={isSaving}
            className="px-8 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 disabled:opacity-50"
          >
            Eliminar
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubirComprobante;
