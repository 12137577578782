import React from 'react';
import SolicitudList from './solicitudComprasComponents/SolicitudList';
import RecursosSolicitudList from './solicitudComprasComponents/RecursosSolicitudList';
import RecursosAcumuladosList from './solicitudComprasComponents/RecursosAcumuladosList';
import GuardarSolicitudCompras from './solicitudComprasComponents/GuardarSolicitudCompras';

const SolicitudCompras: React.FC = () => {

    return (
        // Contenedor principal con altura máxima ajustada
        <div className="w-full px-4 h-[calc(100vh-6rem)]">
            {/* Grid Container */}
            <div className="grid grid-cols-12 gap-2">
                {/* Div 1 - Title Section */}
                <div className="col-span-12 bg-gradient-to-r from-blue-900 to-slate-800 rounded-lg shadow-lg p-4 h-[4rem] flex items-center">
                    <h1 className="text-2xl font-bold text-white">Gestión de Solicitudes de Compra</h1>
                </div>

                {/* Div 2 - Solicitudes List */}
                <div className="h-[calc((100vh-17rem)/2)] md:h-[calc((100vh-28rem))] lg:h-[calc((100vh-17rem))] 
                col-span-12 md:col-span-12 lg:col-span-2">
                    <SolicitudList />
                </div>

                {/* Contenedor para div3 y div4 en columna */}
                <div className="col-span-12 lg:col-span-10 flex flex-col gap-2">
                    {/* Div 3 - Middle Content - Detalles de Solicitud */}
                    <div className="col-span-6 md:col-span-9 lg:col-span-7 bg-white/60 backdrop-blur-sm rounded-lg shadow-lg border border-slate-200 
                    h-[calc((100vh-17rem)*0.6-0.5rem)] md:h-[calc((100vh-28rem))] lg:h-[calc((100vh-17rem)*0.6-0.25rem)] overflow-y-auto transition-all duration-300">
                        <RecursosSolicitudList />
                    </div>

                    {/* Div 4 - Bottom Right Content - Resumen */}
                    <div className="bg-gradient-to-br from-slate-800 to-blue-900 text-white rounded-lg shadow-lg 
                    h-[calc((100vh-17rem)*0.4-0.5rem)] md:h-[calc((100vh-28rem))] lg:h-[calc((100vh-17rem)*0.4-0.25rem)] overflow-y-auto transition-all duration-300">
                        <RecursosAcumuladosList />
                    </div>
                </div>

                {/* Div 5 - Footer Content */}
                <div className="col-span-12 bg-white/90 backdrop-blur-sm rounded-lg shadow-lg border border-slate-200 px-6 h-[5rem] ">
                    <GuardarSolicitudCompras />
                </div>
            </div >
        </div >
    );
};

export default SolicitudCompras;