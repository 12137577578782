import { motion } from 'framer-motion';
import { useState } from 'react';

// Tipos para los eventos
interface CalendarEvent {
  id: number;
  date: Date;
  title: string;
  type: string;
  description?: string;
}

const HomePage = () => {
  // Estado para el calendario
  const [currentDate, setCurrentDate] = useState(new Date());
  
  // Eventos simulados (mockdata)
  const [events] = useState<CalendarEvent[]>([
    { 
      id: 1, 
      date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 5), 
      title: "Certificación ISO 9001", 
      type: "Taller",
      description: "Taller de preparación para la certificación internacional ISO 9001."
    },
    { 
      id: 2, 
      date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 12), 
      title: "Consultoría Empresarial", 
      type: "Reunión",
      description: "Reunión con directivos de empresas locales para presentar servicios de consultoría."
    },
    { 
      id: 3, 
      date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18), 
      title: "Presentación de Proyectos", 
      type: "Evento",
      description: "Presentación de los nuevos proyectos desarrollados durante el último trimestre."
    },
    { 
      id: 4, 
      date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 24), 
      title: "Capacitación Técnica", 
      type: "Taller",
      description: "Capacitación sobre nuevas tecnologías de construcción sostenible."
    },
    { 
      id: 5, 
      date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8), 
      title: "Visita a Obra", 
      type: "Actividad",
      description: "Visita al proyecto de construcción del nuevo centro comercial."
    },
    { 
      id: 6, 
      date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15), 
      title: "Reunión de Planificación", 
      type: "Reunión",
      description: "Planificación estratégica para el próximo trimestre."
    },
    { 
      id: 7, 
      date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 20), 
      title: "Webinar: Innovación", 
      type: "Webinar",
      description: "Webinar sobre innovación en la industria de la construcción."
    },
    { 
      id: 8, 
      date: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 3), 
      title: "Feria de Construcción", 
      type: "Evento",
      description: "Participación en la feria internacional de construcción."
    }
  ]);

  // Funciones para el calendario
  const goToPreviousMonth = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() - 1);
      return newDate;
    });
  };

  const goToNextMonth = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() + 1);
      return newDate;
    });
  };

  // Obtener la información del calendario
  const getCalendarDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    
    // Primer día del mes (0=Domingo, 1=Lunes, etc.)
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    
    // Último día del mes
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    
    // Último día del mes anterior
    const lastDayOfPrevMonth = new Date(year, month, 0).getDate();
    
    const days = [];
    
    // Días del mes anterior
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      days.push({
        day: lastDayOfPrevMonth - i,
        isCurrentMonth: false,
        date: new Date(year, month - 1, lastDayOfPrevMonth - i)
      });
    }
    
    // Días del mes actual
    for (let i = 1; i <= lastDayOfMonth; i++) {
      days.push({
        day: i,
        isCurrentMonth: true,
        isToday: i === new Date().getDate() && 
                month === new Date().getMonth() && 
                year === new Date().getFullYear(),
        date: new Date(year, month, i)
      });
    }
    
    // Días del mes siguiente (completar hasta 42 días = 6 semanas)
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      days.push({
        day: i,
        isCurrentMonth: false,
        date: new Date(year, month + 1, i)
      });
    }
    
    return days;
  };

  // Verificar si un día tiene eventos
  const hasEvent = (date: Date) => {
    return events.some(event => 
      event.date.getDate() === date.getDate() && 
      event.date.getMonth() === date.getMonth() && 
      event.date.getFullYear() === date.getFullYear()
    );
  };

  // Obtener eventos para mostrar en la lista
  const getUpcomingEvents = () => {
    const today = new Date();
    return events
      .filter(event => event.date >= today)
      .sort((a, b) => a.date.getTime() - b.date.getTime())
      .slice(0, 5); // Mostrar solo los próximos 5 eventos
  };

  // Formatear fecha para mostrar
  const formatEventDate = (date: Date) => {
    return `${date.getDate()} ${date.toLocaleString('es-ES', { month: 'short' })}`;
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  // Estilos para la galería de imágenes
  const imageGalleryStyle = `
    @keyframes fadeInOut {
      0%, 20% { opacity: 0; transform: scale(1.1) rotate(-1deg); }
      5%, 15% { opacity: 1; transform: scale(1) rotate(0deg); }
    }
    
    @keyframes floatEffect {
      0% { transform: translateY(0px); }
      50% { transform: translateY(-10px); }
      100% { transform: translateY(0px); }
    }
    
    @keyframes shine {
      0% { mask-position: -50%; }
      100% { mask-position: 150%; }
    }
    
    .image-container:nth-child(1) img { animation: fadeInOut 20s infinite; }
    .image-container:nth-child(2) img { animation: fadeInOut 20s infinite 5s; }
    .image-container:nth-child(3) img { animation: fadeInOut 20s infinite 10s; }
    .image-container:nth-child(4) img { animation: fadeInOut 20s infinite 15s; }
    
    .floating-effect { animation: floatEffect 6s ease-in-out infinite; }
    
    .shine-effect {
      position: relative;
      overflow: hidden;
    }
    
    .shine-effect::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent);
      mask: linear-gradient(#fff 0 0);
      animation: shine 8s infinite;
    }

    /* Animación mejorada para el slideshow automático */
    @keyframes imageChange {
      0%, 19% { opacity: 1; transform: scale(1.05); }
      20%, 99% { opacity: 0; transform: scale(1.1); }
      100% { opacity: 0; }
    }
    
    .image-container {
      opacity: 0;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
    
    .image-container:nth-child(1) { animation: imageChange 20s infinite 0s; }
    .image-container:nth-child(2) { animation: imageChange 20s infinite 5s; }
    .image-container:nth-child(3) { animation: imageChange 20s infinite 10s; }
    .image-container:nth-child(4) { animation: imageChange 20s infinite 15s; }
  `;

  return (
    <div className="h-[89vh] overflow-y-auto bg-gradient-to-b from-blue-900/20 to-blue-800/30 text-white">
      {/* Navbar placeholder con líneas grises */}
      {/* <div className="border-b border-gray-400/20">
        <div className="container mx-auto py-4 px-6">
          <div className="flex justify-between items-center">
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="text-2xl font-bold text-blue-300"
            >
              INACONS
            </motion.div>
            <div className="hidden md:flex space-x-8">
              {["Inicio", "Servicios", "Proyectos", "Contacto"].map((item, i) => (
                <motion.a
                  key={i}
                  href="#"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: i * 0.1, duration: 0.5 }}
                  className="text-blue-100 hover:text-blue-300 transition-colors"
                >
                  {item}
                </motion.a>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      
      {/* Estilos CSS inline para las animaciones */}
      <style>{imageGalleryStyle}</style>

      {/* Hero Section */}
      <motion.div 
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        className="container mx-auto px-6 py-16 md:py-24"
      >
        <div className="grid md:grid-cols-2 gap-10 items-center">
          <div>
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-300"
            >
              Bienvenido a INACONS
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-lg md:text-xl text-blue-100 mb-8"
            >
              Transformamos ideas en soluciones innovadoras para impulsar su empresa hacia el futuro.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              {/* <button className="bg-blue-400 hover:bg-blue-500 text-blue-900 font-bold py-3 px-8 rounded-lg shadow-lg transform transition hover:scale-105">
                Comenzar Ahora
              </button> */}
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="hidden md:block"
          >
            {/* Nueva galería de imágenes mejorada */}
            <div className="relative h-[400px] w-full rounded-2xl overflow-hidden shadow-2xl shine-effect floating-effect">
              {/* Efecto de glassmorphism para el marco */}
              <div className="absolute inset-0 z-10 rounded-2xl bg-gradient-to-br from-blue-400/10 to-blue-900/50 border border-blue-400/20"></div>
              
              {/* Imágenes con mejor animación y efectos */}
              <div className="absolute inset-0 flex items-center justify-center z-0">
                <div className="relative w-full h-full overflow-hidden">
                  {[
                    "https://inacons.com.pe/public/imagen/slider/inacons_certificacia_n_2521270407.jpg",
                    "https://inacons.com.pe/public/imagen/slider/inacons_4_7421880482.jpg",
                    "https://inacons.com.pe/public/imagen/slider/inacons_3_5462778954.jpg",
                    "https://inacons.com.pe/public/imagen/slider/inacons_1_1809621340.jpg"
                  ].map((src, index) => (
                    <div 
                      key={index}
                      className="image-container"
                    >
                      <img
                        src={src}
                        alt={`Imagen INACONS ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Elementos decorativos modernos */}
              <div className="absolute inset-0 z-20 pointer-events-none">
                {/* Esquinas decorativas */}
                <div className="absolute top-6 left-6 w-16 h-16">
                  <div className="absolute top-0 left-0 w-8 h-1 bg-blue-300/60"></div>
                  <div className="absolute top-0 left-0 w-1 h-8 bg-blue-300/60"></div>
                </div>
                <div className="absolute top-6 right-6 w-16 h-16">
                  <div className="absolute top-0 right-0 w-8 h-1 bg-blue-300/60"></div>
                  <div className="absolute top-0 right-0 w-1 h-8 bg-blue-300/60"></div>
                </div>
                <div className="absolute bottom-6 left-6 w-16 h-16">
                  <div className="absolute bottom-0 left-0 w-8 h-1 bg-blue-300/60"></div>
                  <div className="absolute bottom-0 left-0 w-1 h-8 bg-blue-300/60"></div>
                </div>
                <div className="absolute bottom-6 right-6 w-16 h-16">
                  <div className="absolute bottom-0 right-0 w-8 h-1 bg-blue-300/60"></div>
                  <div className="absolute bottom-0 right-0 w-1 h-8 bg-blue-300/60"></div>
                </div>
                
                {/* Efecto de partículas o puntos */}
                <div className="absolute top-1/4 left-1/4 w-2 h-2 rounded-full bg-blue-300/40"></div>
                <div className="absolute top-1/3 left-2/3 w-3 h-3 rounded-full bg-blue-200/30"></div>
                <div className="absolute top-2/3 left-1/3 w-2 h-2 rounded-full bg-blue-300/40"></div>
                <div className="absolute top-3/4 left-3/4 w-1 h-1 rounded-full bg-blue-200/30"></div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>

      {/* Calendario Section */}
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
        className="container mx-auto px-6 pb-24"
      >
        <h2 className="text-3xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-300">
          Calendario de Eventos
        </h2>
        
        <div className="relative w-full max-w-4xl mx-auto rounded-2xl overflow-hidden shadow-2xl shine-effect">
          {/* Efecto de glassmorphism para el calendario */}
          <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-blue-400/10 to-blue-900/60 border border-blue-400/20 backdrop-blur-sm z-0"></div>
          
          {/* Header del calendario */}
          <div className="relative z-10 p-6 border-b border-blue-300/20">
            <div className="flex justify-between items-center">
              <button 
                className="text-blue-200 hover:text-blue-100 p-2 transition-colors"
                onClick={goToPreviousMonth}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <h3 className="text-xl font-semibold text-blue-100">
                {currentDate.toLocaleString('es-ES', { month: 'long', year: 'numeric' }).charAt(0).toUpperCase() + 
                 currentDate.toLocaleString('es-ES', { month: 'long', year: 'numeric' }).slice(1)}
              </h3>
              <button 
                className="text-blue-200 hover:text-blue-100 p-2 transition-colors"
                onClick={goToNextMonth}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
          
          {/* Días de la semana */}
          <div className="relative z-10 grid grid-cols-7 text-center py-4 text-blue-200 font-medium border-b border-blue-300/20">
            {["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"].map((day, i) => (
              <div key={i} className="py-2">{day}</div>
            ))}
          </div>
          
          {/* Días del mes */}
          <div className="relative z-10 grid grid-cols-7 text-center py-2">
            {getCalendarDays().map((dayInfo, i) => (
              <div 
                key={i} 
                className={`p-3 relative cursor-pointer hover:bg-blue-500/10 transition-colors
                  ${dayInfo.isCurrentMonth ? "text-blue-100" : "text-blue-500/40"}
                  ${dayInfo.isToday ? "bg-blue-400/20 rounded-lg font-bold text-white" : ""}
                `}
              >
                <span className="relative z-10">{dayInfo.day}</span>
                
                {/* Indicador de evento */}
                {dayInfo.isCurrentMonth && hasEvent(dayInfo.date) && (
                  <motion.div 
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.3, delay: (i % 10) * 0.05 }}
                    className="absolute bottom-1 left-1/2 transform -translate-x-1/2 w-1.5 h-1.5 bg-blue-300 rounded-full"
                  ></motion.div>
                )}
              </div>
            ))}
          </div>
          
          {/* Próximos eventos */}
          <div className="relative z-10 p-6 border-t border-blue-300/20">
            <h4 className="text-lg font-semibold text-blue-100 mb-4">Próximos Eventos</h4>
            <div className="space-y-3">
              {getUpcomingEvents().map((event, i) => (
                <motion.div 
                  key={event.id}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4, delay: i * 0.1 }}
                  className="flex items-center p-3 rounded-lg bg-blue-700/30 hover:bg-blue-600/30 transition-colors"
                >
                  <div className="w-12 h-12 rounded-lg bg-blue-500/20 flex items-center justify-center text-blue-300 font-medium">
                    {formatEventDate(event.date)}
                  </div>
                  <div className="ml-4 flex-1">
                    <h5 className="text-blue-100 font-medium">{event.title}</h5>
                    <p className="text-blue-300 text-sm">{event.type}</p>
                    {event.description && (
                      <p className="text-blue-200/70 text-xs mt-1">{event.description}</p>
                    )}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
          
          {/* Elementos decorativos */}
          <div className="absolute top-1/4 right-4 w-2 h-2 rounded-full bg-blue-300/40"></div>
          <div className="absolute bottom-1/3 left-5 w-3 h-3 rounded-full bg-blue-200/30"></div>
          <div className="absolute top-6 left-6 w-16 h-16 pointer-events-none">
            <div className="absolute top-0 left-0 w-8 h-1 bg-blue-300/60"></div>
            <div className="absolute top-0 left-0 w-1 h-8 bg-blue-300/60"></div>
          </div>
          <div className="absolute bottom-6 right-6 w-16 h-16 pointer-events-none">
            <div className="absolute bottom-0 right-0 w-8 h-1 bg-blue-300/60"></div>
            <div className="absolute bottom-0 right-0 w-1 h-8 bg-blue-300/60"></div>
          </div>
        </div>
      </motion.div>

      {/* Footer con líneas grises */}
      <div className="border-t border-gray-400/20 py-8">
        <div className="container mx-auto px-6">
          <div className="text-center text-blue-200/70 text-sm">
            © 2025 INACONS. Todos los derechos reservados.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;


// import React, { useState, ChangeEvent } from 'react';
// import Card from '../../components/Card/Card';
// import { FaBriefcase, FaGraduationCap, FaBuilding } from 'react-icons/fa'; // Usando react-icons que sí está en package.json

// interface FormData {
//   nombre: string;
//   email: string;
//   telefono: string;
//   experiencia: string;
//   educacion: string;
//   mensaje: string;
// }

// const CareerPage = () => {
//   const [formData, setFormData] = useState<FormData>({
//     nombre: '',
//     email: '',
//     telefono: '',
//     experiencia: '',
//     educacion: '',
//     mensaje: ''
//   });

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//   };

//   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value
//     });
//   };

//   return (
//     <div className="min-h-[calc(100vh-6.2rem)] bg-zinc-300 relative overflow-y-auto">
//       {/* Olas decorativas */}
//       <div className="absolute w-full min-h-full overflow-hidden z-0">
//         <svg className="absolute top-0 left-0 w-full filter drop-shadow-lg" viewBox="0 0 1440 320" preserveAspectRatio="none">
//           <path fill="rgba(59, 130, 246, 0.8)" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z" filter="drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1))"></path>
//         </svg>
//         <svg className="absolute bottom-0 left-0 w-full filter drop-shadow-lg" viewBox="0 0 1440 320" preserveAspectRatio="none">
//           <path fill="rgba(59, 130, 246, 0.8)" d="M0,64L48,96C96,128,192,192,288,192C384,192,480,128,576,128C672,128,768,192,864,192C960,192,1056,128,1152,106.7C1248,85,1344,107,1392,117.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z" filter="drop-shadow(0 -4px 6px rgba(0, 0, 0, 0.1))"></path>
//         </svg>
//       </div>

//       <div className="relative z-10 max-w-6xl mx-auto py-8 px-4 sm:px-6 lg:px-8 h-[calc(100vh-6.5rem)]">
//         {/* Header Section con imagen */}
//         <div className="text-center mb-16">
//           <h1 className="text-5xl font-bold text-white mb-4">
//             Únete a Nuestro Equipo
//           </h1>
//             <p className="text-xl text-white drop-shadow-lg">
//             Construyendo el futuro juntos
//             </p>
//           <div className="mt-8 grid grid-cols-3 gap-4">
//             <img 
//               src="https://www.descripciondepuestos.org/wp-content/uploads/2024/09/e8t7305HJ7kzU_1C4I_iV.png.webp" 
//               alt="Ingeniero" 
//               className="rounded-lg shadow-xl object-cover w-full h-[300px]" 
//             />
//             <img 
//               src="https://ucal.edu.pe/blog/sites/default/files/inline-images/requisitos-para-convertirse-en-arquitecto2.jpg" 
//               alt="Arquitecto" 
//               className="rounded-lg shadow-xl object-cover w-full h-[300px]" 
//             />
//             <img 
//               src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFEU_U0JF4P3V9PrmxroXVeDBjWfThVlPiICyp1w8V0dSGE806SsFvUsfiqIk30-2ppu0&usqp=CAU" 
//               alt="Constructor" 
//               className="rounded-lg shadow-xl object-cover w-full h-[300px]" 
//             />
//           </div>
//         </div>

//         {/* Benefits Section */}
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
//           <Card className="bg-white/90 backdrop-blur-sm p-6 hover:shadow-xl transition-shadow border border-blue-200">
//             <FaBuilding className="h-12 w-12 text-blue-600 mb-4" />
//             <h3 className="text-xl font-semibold mb-2 text-blue-900">Proyectos Innovadores</h3>
//             <p className="text-blue-700">Trabaja en proyectos que transforman ciudades y comunidades.</p>
//           </Card>
          
//           <Card className="bg-white/90 backdrop-blur-sm p-6 hover:shadow-xl transition-shadow border border-blue-200">
//             <FaBriefcase className="h-12 w-12 text-blue-600 mb-4" />
//             <h3 className="text-xl font-semibold mb-2 text-blue-900">Crecimiento Profesional</h3>
//             <p className="text-blue-700">Desarrollo continuo y oportunidades de aprendizaje.</p>
//           </Card>
          
//           <Card className="bg-white/90 backdrop-blur-sm p-6 hover:shadow-xl transition-shadow border border-blue-200">
//             <FaGraduationCap className="h-12 w-12 text-blue-600 mb-4" />
//             <h3 className="text-xl font-semibold mb-2 text-blue-900">Capacitación Continua</h3>
//             <p className="text-blue-700">Programas de formación y certificaciones.</p>
//           </Card>
//         </div>

//         {/* Application Form */}
//         <Card className="bg-white/95 backdrop-blur-sm p-8 shadow-xl border border-blue-200">
//           <h2 className="text-3xl font-bold mb-6 text-center text-blue-900">Postúlate Ahora</h2>
//           <form onSubmit={handleSubmit} className="space-y-6">
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//               <div>
//                 <label className="block text-sm font-medium text-blue-700 mb-2">
//                   Nombre Completo
//                 </label>
//                 <input
//                   type="text"
//                   name="nombre"
//                   value={formData.nombre}
//                   onChange={handleChange}
//                   className="w-full border-blue-900 focus:border-blue-950 rounded-xl shadow-xl focus:ring-blue-500 bg-white"
//                   required
//                 />
//               </div>
              
//               <div>
//                 <label className="block text-sm font-medium text-blue-700 mb-2">
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   className="w-full border-blue-900 focus:border-blue-950 rounded-xl shadow-xl focus:ring-blue-500 bg-white"
//                   required
//                 />
//               </div>
              
//               <div>
//                 <label className="block text-sm font-medium text-blue-700 mb-2">
//                   Teléfono
//                 </label>
//                 <input
//                   type="tel"
//                   name="telefono"
//                   value={formData.telefono}
//                   onChange={handleChange}
//                   className="w-full border-blue-900 focus:border-blue-950 rounded-xl shadow-xl focus:ring-blue-500 bg-white"
//                   required
//                 />
//               </div>
              
//               <div>
//                 <label className="block text-sm font-medium text-blue-700 mb-2">
//                   Años de Experiencia
//                 </label>
//                 <input
//                   type="text"
//                   name="experiencia"
//                   value={formData.experiencia}
//                   onChange={handleChange}
//                   className="w-full border-blue-900  focus:border-blue-950 rounded-xl shadow-xl focus:ring-blue-500 bg-white"
//                   required
//                 />
//               </div>
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-blue-700 mb-2">
//                 Educación
//               </label>
//               <textarea
//                 name="educacion"
//                 value={formData.educacion}
//                 onChange={handleChange}
//                 className="w-full min-h-[100px] p-3 border border-blue-200 rounded-md focus:border-blue-500 focus:ring-blue-500 shadow-xl"
//                 required
//               />
//             </div>

//             <div>
//               <label className="block text-sm font-medium text-blue-700 mb-2">
//                 ¿Por qué quieres unirte a nuestro equipo?
//               </label>
//               <textarea
//                 name="mensaje"
//                 value={formData.mensaje}
//                 onChange={handleChange}
//                 className="w-full min-h-[150px] p-3 border border-blue-200 rounded-md focus:border-blue-500 focus:ring-blue-500 shadow-xl"
//                 required
//               />
//             </div>

//             <div className="text-center">
//               <button 
//                 className='bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl'
//               >
//                 Enviar Solicitud
//               </button>
//             </div>
//           </form>
//         </Card>
//       </div>
//     </div>
//   );
// };

// export default CareerPage;

