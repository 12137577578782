import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchRequerimientoRecursos } from '../../../slices/requerimientoRecursoSlice';
import { type ItemPadreEstado, type RequerimientoRecursoAllResponseTyped, setRequerimientosRecursoIds, fetchRequerimientosRecursoAll } from '../../../slices/requerimientoRecursoAllSlice';
import LoaderPage from '../../../components/Loader/LoaderPage';

interface Resource {
    id: string;
    name: string;
    total: number;
    summary?: RequerimientoRecursoAllResponseTyped;
    ordenCompraData: Array<{
        id: string;
        date: string;
        quantity: number;
        status: string;
    }>;
    cotizacionData: Array<{
        id: string;
        date: string;
        quantity: number;
        fromWarehouse: string;
        toWarehouse: string;
        status: string;
    }>;
    transfereciaData: Array<{
        id: string;
        date: string;
        quantity: number;
        warehouse: string;
    }>;
}

interface RequerimientoResumenProps {
    id: string;
    onClose: () => void;
}

const RequerimientoResumen = ({ id }: RequerimientoResumenProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { requerimientoRecursos, loading } = useSelector((state: RootState) => state.requerimientoRecurso);
    const { requerimientosRecursoIds, data, dataBruta } = useSelector((state: RootState) => state.requerimientoRecursoAll);
    console.log(dataBruta);
    const [selectedResource, setSelectedResource] = React.useState<Resource | null>(null);

    useEffect(() => {
        // dispatch(clearRequerimientoRecursoData());
        dispatch(setRequerimientosRecursoIds(requerimientoRecursos.map(recurso => recurso.id)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requerimientoRecursos]);
    useEffect(() => {
        // dispatch(clearRequerimientoRecursoData());
        dispatch(fetchRequerimientosRecursoAll());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requerimientosRecursoIds]);

    useEffect(() => {
        if (id) {
            dispatch(fetchRequerimientoRecursos(id));
        }
    }, [id, dispatch]);

    // Función para generar datos simulados
    const generateMockData = (requerimiento_recurso_id: string) => {

        // Verificamos que dataBruta y cotizacion existan y sean un array
        const cotizaciones = Array.isArray(dataBruta[requerimiento_recurso_id]?.cotizacion)
            ? dataBruta[requerimiento_recurso_id]?.cotizacion
            : [];
        const ordenesCompra = Array.isArray(dataBruta[requerimiento_recurso_id]?.orden_compra)
            ? dataBruta[requerimiento_recurso_id]?.orden_compra
            : [];
        const transferencias = Array.isArray(dataBruta[requerimiento_recurso_id]?.transferencia)
            ? dataBruta[requerimiento_recurso_id]?.transferencia
            : [];

        const cotizacionData = cotizaciones.map((cotizacion: ItemPadreEstado, index: number) => ({
            id: `COT-${cotizacion.padreEstado}-${index + 1}`,
            date: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
            quantity: cotizacion.cantidad || Math.floor(Math.random() * 30) + 10,
            status: cotizacion.padreEstado || 'unknown',
            fromWarehouse: 'Almacén Central',
            toWarehouse: 'Obra Principal',
            warehouse: 'Almacén Principal'
        }));

        const ordenCompraData = ordenesCompra.map((orden: ItemPadreEstado, index: number) => ({
            id: `OC-${orden.padreEstado}-${index + 1}`,
            date: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
            quantity: orden.cantidad || Math.floor(Math.random() * 30) + 10,
            status: orden.padreEstado || 'unknown',
            fromWarehouse: 'Proveedor',
            toWarehouse: 'Almacén Principal'
        }));

        const transfereciaData = transferencias.map((transferencia: ItemPadreEstado, index: number) => ({
            id: `TRS-${transferencia.padreEstado}-${index + 1}`,
            date: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
            quantity: transferencia.cantidad || Math.floor(Math.random() * 30) + 10,
            status: transferencia.padreEstado || 'unknown',
            warehouse: 'Almacén Obra'
        }));

        return {
            cotizacionData,
            ordenCompraData,
            transfereciaData
        };
    };

    // Combinar datos reales con simulados
    const enrichedResources = requerimientoRecursos.map(recurso => ({
        id: recurso.id,
        name: recurso.nombre,
        total: recurso.cantidad,
        summary: data[recurso.id],
        ...generateMockData(recurso.id)
    }));

    if (loading) {
        return <LoaderPage />;
    }

    return (
        <div className="max-w-full mx-auto flex space-x-4 h-[calc(100vh-12rem)] custom-scrollbar">
            {/* Lista de recursos */}
            <div className="max-w-[20vw] min-w-[calc(19vw)] flex flex-col bg-gradient-to-b from-blue-100 to-white rounded-2xl shadow-lg border border-slate-200/60 p-4 m-1">
                <h2 className="text-xl font-bold mb-6 p-3 text-center bg-gradient-to-r from-slate-800 to-slate-700 rounded-xl text-white shadow-sm">
                    Resumen de Recursos
                </h2>

                <div className="space-y-3 overflow-y-auto pr-2">
                    {enrichedResources.map((resource, index) => (
                        <div
                            key={resource.id}
                            className={`
                    relative bg-white rounded-xl p-4
                    border transition-all duration-200 ease-in-out
                    hover:shadow-lg hover:scale-[0.98]
                    cursor-pointer
                    ${selectedResource?.id === resource.id
                                    ? 'border-slate-600 border-2 shadow-md'
                                    : 'border-slate-200 shadow-sm hover:border-slate-400'
                                }
                `}
                            onClick={() => setSelectedResource(resource)}
                        >
                            <div className="flex justify-between items-start mb-3">
                                <div className="flex-1 pr-6">
                                    <h3 className="text-xs font-medium text-slate-700 mb-1">
                                        {resource.name}
                                    </h3>
                                    <p className="text-xs font-semibold text-slate-900">
                                        Total: {resource.total}
                                    </p>
                                </div>

                                {/* Indicador numérico */}
                                <div className="absolute top-3 right-3 w-6 h-6 bg-gradient-to-br from-slate-700 to-slate-800 rounded-full flex items-center justify-center shadow-sm">
                                    <span className="text-xs text-white font-medium">
                                        {index + 1}
                                    </span>
                                </div>
                            </div>

                            {/* Barra de progreso */}
                            <div className="w-full bg-slate-100 rounded-full h-2 overflow-hidden -mt-1.5">
                                <div
                                    className="h-full rounded-full bg-gradient-to-r from-slate-600 to-slate-700 transition-all duration-300 ease-out"
                                    style={{ width: `${resource.summary?.transferencia?.[0]?.cantidad ? (resource.summary.transferencia[0].cantidad / resource.total) * 100 : 0}%` }}
                                ></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Detalle del recurso seleccionado */}
            <div className="max-w-[41vw] min-w-[calc(40vw)] h-full overflow-y-auto pr-2 bg-gradient-to-b from-blue-100 to-white rounded-2xl shadow-lg border border-slate-200/60 p-4">
                {selectedResource ? (
                    <div>
                        <h2 className=" bg-slate-600 text-center rounded-xl p-2 font-semibold mb-4 sticky top-0">
                            <span className='text-white'>
                                {selectedResource.name}
                            </span>
                            <br />
                            <span className="text-xs text-slate-200 ml-2">

                                Código: {selectedResource.id}
                            </span>
                        </h2>

                        {/* Resumen General */}
                        <div className="grid grid-cols-4 gap-2 mb-4 text-xs">
                            <div className="bg-blue-50 p-2 rounded-lg">
                                <div className="text-xl font-bold text-blue-700">{selectedResource.total}</div>
                                <div className="text-blue-600">Total Requerido</div>
                            </div>
                            <div className="bg-fuchsia-50 p-2 rounded-lg">
                                <div className="text-xl font-bold text-fuchsia-700">
                                    {(() => {
                                        // Verificamos cada nivel de forma segura
                                        if (!data) return 0;
                                        if (!selectedResource.summary || !selectedResource.summary.requerimiento_recurso_id) return 0;

                                        const dataItem = data[selectedResource.summary.requerimiento_recurso_id];
                                        if (!dataItem) return 0;

                                        const cotizacion = dataItem.cotizacion;
                                        if (!cotizacion || cotizacion.length === 0) return 0;

                                        // Si llegamos aquí, tenemos cotizacion para mostrar
                                        return cotizacion.map((cot, index) => (
                                            <span key={index || `${cot.padreEstado || 'unknown'}-${cot.cantidad || 0}-${index}`}>
                                                {cot.padreEstado || 'N/A'}: {cot.cantidad || 0}
                                                {index < cotizacion.length - 1 && ", "}
                                            </span>
                                        ));
                                    })()}
                                </div>
                                <div className="text-fuchsia-600">Cotizacion</div>
                            </div>
                            <div className="bg-yellow-50 p-2 rounded-lg">
                                <div className="text-xl font-bold text-yellow-700">
                                    {(() => {
                                        // Verificamos cada nivel de forma segura
                                        if (!data) return 0;
                                        if (!selectedResource.summary || !selectedResource.summary.requerimiento_recurso_id) return 0;

                                        const dataItem = data[selectedResource.summary.requerimiento_recurso_id];
                                        if (!dataItem) return 0;

                                        const orden_compra = dataItem.orden_compra;
                                        if (!orden_compra || orden_compra.length === 0) return 0;

                                        // Si llegamos aquí, tenemos orden_compra para mostrar
                                        return orden_compra.map((cot, index) => (
                                            <span key={index || `${cot.padreEstado || 'unknown'}-${cot.cantidad || 0}-${index}`}>
                                                {cot.padreEstado || 'N/A'}: {cot.cantidad || 0}
                                                {index < orden_compra.length - 1 && ", "}
                                            </span>
                                        ));
                                    })()}
                                </div>
                                <div className="text-yellow-600">OC generadas</div>
                            </div>
                            <div className="bg-green-50 p-2 rounded-lg">
                                <div className="text-lg font-bold text-green-700">
                                    {(() => {
                                        // Verificamos cada nivel de forma segura
                                        if (!data) return 0;
                                        if (!selectedResource.summary || !selectedResource.summary.requerimiento_recurso_id) return 0;

                                        const dataItem = data[selectedResource.summary.requerimiento_recurso_id];
                                        if (!dataItem) return 0;

                                        const transferencia = dataItem.transferencia;
                                        if (!transferencia || transferencia.length === 0) return 0;

                                        // Si llegamos aquí, tenemos transferencia para mostrar
                                        return transferencia.map((cot, index) => (
                                            <span key={index || `${cot.padreEstado || 'unknown'}-${cot.cantidad || 0}-${index}`}>
                                                {cot.padreEstado || 'N/A'}: {cot.cantidad || 0}
                                                {index < transferencia.length - 1 && ", "}
                                            </span>
                                        ));
                                    })()}
                                </div>
                                <div className="text-green-600">Atendidos</div>
                            </div>
                            {/* <div className="bg-red-50 p-2 rounded-lg">
                                <div className="text-xl font-bold text-red-700">
                                {(() => {
                                        // Verificamos cada nivel de forma segura
                                        if (!data) return 0;
                                        if (!selectedResource.summary || !selectedResource.summary.requerimiento_recurso_id) return 0;

                                        const dataItem = data[selectedResource.summary.requerimiento_recurso_id];
                                        if (!dataItem) return 0;

                                        const transferencia = dataItem.transferencia;
                                        if (!transferencia || transferencia.length === 0) return 0;

                                        // Si llegamos aquí, tenemos transferencia para mostrar
                                        return transferencia.map((cot, index) => (
                                            <span key={index || `pending-${cot.padreEstado || 'unknown'}-${index}`}>
                                                {selectedResource.total - (cot.cantidad || 0)}
                                                {index < transferencia.length - 1 && ", "}
                                            </span>
                                        ));
                                    })()}
                                </div>
                                <div className="text-red-600">Pendientes</div>
                            </div> */}
                        </div>

                        {/* Cotizaciones */}
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold mb-2">Cotizaciones</h3>
                            <div className="bg-gray-50 rounded-lg p-3">
                                <table className="w-full text-xs">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="pb-2">N° COT</th>
                                            <th className="pb-2">Fecha</th>
                                            <th className="pb-2">Cantidad</th>
                                            <th className="pb-2">Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedResource.cotizacionData.map(po => (
                                            <tr key={po.id} className="border-t">
                                                <td className="py-2">{po.id}</td>
                                                <td>{po.date}</td>
                                                <td>{po.quantity}</td>
                                                <td>
                                                    <span className={`px-2 py-1 rounded-full text-sm ${po.status === 'pendiente' ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'
                                                        }`}>
                                                        {po.status}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Órdenes de Compra */}
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold mb-2">Órdenes de Compra</h3>
                            <div className="bg-gray-50 rounded-lg p-3">
                                <table className="w-full text-xs">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="pb-2">N° OC</th>
                                            <th className="pb-2">Fecha</th>
                                            <th className="pb-2">Cantidad</th>
                                            <th className="pb-2">Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedResource.ordenCompraData.map(po => (
                                            <tr key={po.id} className="border-t">
                                                <td className="py-2">{po.id}</td>
                                                <td>{po.date}</td>
                                                <td>{po.quantity}</td>
                                                <td>
                                                    <span className={`px-2 py-1 rounded-full text-sm ${po.status === 'completed' ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'
                                                        }`}>
                                                        {po.status === 'completed' ? 'Completado' : 'En Proceso'}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Entregas */}
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Entregas Realizadas</h3>
                            <div className="bg-gray-50 rounded-lg p-3">
                                <table className="w-full text-xs">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="pb-2">N° Entrega</th>
                                            <th className="pb-2">Fecha</th>
                                            <th className="pb-2">Almacén</th>
                                            <th className="pb-2">Cantidad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedResource.transfereciaData.map(del => (
                                            <tr key={del.id} className="border-t">
                                                <td className="py-2">{del.id}</td>
                                                <td>{del.date}</td>
                                                <td>{del.warehouse}</td>
                                                <td>{del.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="p-4 text-gray-500 text-center">
                        Selecciona un recurso para ver el detalle
                    </div>
                )}
            </div>
        </div>
    );
}

export default RequerimientoResumen;