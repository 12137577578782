import React from 'react';
import { SolicitudCompra } from '../../../slices/solicitudCompraSlice';
import { formatDate } from '../../../components/Utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { BsPerson } from 'react-icons/bs';
import { setObraFilter } from '../../../slices/compraSelectorSlice';
interface SolicitudCompraCardProps {
  solicitud: SolicitudCompra;
  isSelected: boolean;
  onCheckboxChange: (id: string, checked: boolean) => void; // Agregar prop para manejar checkbox
  isSelectionLocked: boolean; // Nueva prop para el estado de bloqueo
}

const SolicitudCompraCard: React.FC<SolicitudCompraCardProps> = ({ 
  solicitud, 
  onCheckboxChange,
  isSelectionLocked // Recibir la prop de bloqueo
}) => {
  // Extraer datos relevantes
  const dispatch = useDispatch<AppDispatch>();
  const codigo = solicitud.requerimiento_id?.codigo || 'Sin código';
  const fecha = formatDate(solicitud.fecha,"dd/mm/yyyy");
  const fechaFinal = formatDate(solicitud.requerimiento_id?.fecha_final,"dd/mm/yyyy");
  const estado = solicitud.estado;
  const obraId = solicitud.requerimiento_id?.obra_id || 'Sin obra';
  const obra = useSelector((state: RootState) => state.obra.obras.find(obra => obra.id === obraId));
  const requiere = solicitud.requerimiento_id.usuario;
  // Obtener del estado si esta solicitud está seleccionada en el array de selecciones múltiples
  const multiSelectedSolicitudes = useSelector((state: RootState) => state.compraSelector.selectedSolicitudes);
  const isMultiSelected = multiSelectedSolicitudes.includes(solicitud.id);
  
  // Obtener el color asignado a esta solicitud
  const solicitudColors = useSelector((state: RootState) => state.compraSelector.solicitudColors);
  const colorAssignment = solicitudColors.find(sc => sc.solicitudId === solicitud.id);
  const assignedColorClass = colorAssignment?.colorClass || 'bg-blue-50';
  
  // Determinar color de fondo según el estado
  let estadoColor = 'bg-gray-200';
  let estadoText = 'text-gray-700';
  
  switch (estado) {
    case 'PENDIENTE':
      estadoColor = 'bg-gray-100';
      estadoText = 'text-gray-700';
      break;
    case 'COTIZACION_PARCIAL':
      estadoColor = 'bg-yellow-100';
      estadoText = 'text-yellow-700';
      break;
    case 'COTIZACION_COMPLETA':
      estadoColor = 'bg-blue-100';
      estadoText = 'text-blue-700';
      break;
    case 'FIN_ATENCION_PARCIAL':
      estadoColor = 'bg-orange-100';
      estadoText = 'text-orange-700';
      break;
    case 'FIN_COMPLETO':
      estadoColor = 'bg-green-100';
      estadoText = 'text-green-700';
      break;
    case 'ABORTADA':
      estadoColor = 'bg-red-100';
      estadoText = 'text-red-700';
      break;
    default:
      break;
  }

  // Manejar cambio de checkbox sin propagar el evento al card
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Si la selección está bloqueada, no hacer nada
    if (isSelectionLocked) return;
    
    e.stopPropagation(); // Evitar que se propague al card
    onCheckboxChange(solicitud.id, e.target.checked);
    dispatch(setObraFilter(obraId));
  };
  
  return (
    <div 
      className={`cursor-pointer rounded-lg p-2 transition duration-200 ease-in-out
        ${isMultiSelected ? assignedColorClass + ' border-2 border-blue-500' : 'bg-white border border-gray-200 hover:bg-blue-50'} 
        shadow-sm hover:shadow-md`}
    >
      <div className="flex justify-between items-start mb-1">
        {/* Checkbox para selección múltiple */}
        <div onClick={(e) => e.stopPropagation()} className="flex items-center">
          <input
            type="checkbox"
            checked={isMultiSelected}
            onChange={handleCheckboxChange}
            disabled={isSelectionLocked} // Deshabilitar cuando la selección está bloqueada
            className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 
                       ${isSelectionLocked && !isMultiSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
          />
        </div>
        <div className={`text-[9px] font-medium px-1.5 py-0.5 rounded-full ${estadoColor} ${estadoText} w-fit`}>
          {estado}
        </div>
      </div>
      
      <div className="flex flex-col space-y-1">
        {/* Código */}
        <div className="font-semibold text-blue-800 truncate text-xs">
          {codigo}
        </div>
        
        {/* Fechas */}
        <div className="text-xs text-gray-600" style={{ fontSize: '12px' }}>
          <div className="flex items-center space-x-1">
            <svg className="w-2.5 h-2.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
            <span>Creado: {fecha}</span>
          </div>
          <div className="flex items-center space-x-1 mt-0.5">
            <svg className="w-2.5 h-2.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>Vence: {fechaFinal}</span>
          </div>
          <div className="flex items-center space-x-1 mt-0.5">
            <BsPerson className="w-2.5 h-2.5" />
            <span>Requiere: {requiere || "Sin nombre"}</span>
          </div>
        </div>
        
        {/* Obra ID */}
        <div className="text-xs bg-gray-100 rounded px-1.5 py-0.5 text-gray-600 truncate" style={{ fontSize: '12px' }}>
          Obra: {obra?.nombre || 'Sin obra'}
        </div>
      </div>
    </div>
  );
};

export default SolicitudCompraCard;
