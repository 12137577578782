import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../store/store';
import Button from '../../../components/Buttons/Button';
import { addValoracionProveedor, updateValoracionProveedor, ValoracionProveedor } from '../../../slices/valoracionProveedorSlice';

// Interfaz para los criterios de evaluación
interface CriterioEvaluacion {
  name: string;
  label: string;
  max: number;
  categoria: 'seleccion' | 'servicio' | 'ambiental';
  descripciones: { puntos: number; texto: string }[];
}

// Componente para renderizar cada criterio
interface CriterioInputProps {
  criterio: CriterioEvaluacion;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CriterioInput: React.FC<CriterioInputProps> = ({ criterio, value, onChange }) => {
  return (
    <div className="group">
      <label className="block text-sm font-medium text-gray-800 mb-1">
        {criterio.label} (Máx: {criterio.max})
      </label>
      <div className="flex items-center">
        <div className="ml-2 w-full text-sm text-gray-600">
          {criterio.descripciones.map((desc, i) => (
            <div key={i}>{desc.texto}</div>
          ))}
        </div>
        <input
          type="number"
          name={criterio.name}
          min="0"
          max={criterio.max}
          step="0.5"
          value={value}
          onChange={onChange}
          className="block w-20 rounded-lg border-gray-300 shadow-sm px-3 py-2
                  focus:ring-4 focus:ring-blue-500/30 focus:border-blue-500
                  hover:border-blue-400 transition-all duration-300
                  bg-white/90 backdrop-blur-sm"
        />
      </div>
    </div>
  );
};

// Array de criterios de evaluación
const criteriosEvaluacion: CriterioEvaluacion[] = [
  // Criterios de Selección
  {
    name: 'experiencia',
    label: 'Experiencia en el Mercado',
    max: 15,
    categoria: 'seleccion',
    descripciones: [
      { puntos: 15, texto: 'Más de 3 años: 15 puntos' },
      { puntos: 7.5, texto: '2 años: 7.5 puntos' },
      { puntos: 2, texto: 'Menos de 1 año: 2 puntos' }
    ]
  },
  {
    name: 'condiciones_pago',
    label: 'Condiciones de Pago',
    max: 15,
    categoria: 'seleccion',
    descripciones: [
      { puntos: 15, texto: 'Crédito a más de 30 días: 15 puntos' },
      { puntos: 7.5, texto: 'Contado: 7.5 puntos' }
    ]
  },
  {
    name: 'garantia',
    label: 'Garantía del producto/servicio',
    max: 10,
    categoria: 'seleccion',
    descripciones: [
      { puntos: 10, texto: 'Si ofrece: 10 puntos' },
      { puntos: 5, texto: 'No ofrece: 5 puntos' }
    ]
  },
  {
    name: 'control',
    label: 'Controles en Materia de Calidad, MA y SST',
    max: 10,
    categoria: 'seleccion',
    descripciones: [
      { puntos: 10, texto: 'Si: 10 puntos' },
      { puntos: 5, texto: 'En Proceso: 5 puntos' },
      { puntos: 1.5, texto: 'No: 1.5 puntos' }
    ]
  },
  {
    name: 'infraestructura',
    label: 'Infraestructura y capacidad',
    max: 15,
    categoria: 'seleccion',
    descripciones: [
      { puntos: 15, texto: 'Si: 15 puntos' },
      { puntos: 7.5, texto: 'A veces: 7.5 puntos' },
      { puntos: 1.5, texto: 'No: 1.5 puntos' }
    ]
  },
  {
    name: 'cuestionario',
    label: 'Cuestionario socio de negocio',
    max: 10,
    categoria: 'seleccion',
    descripciones: [
      { puntos: 10, texto: 'Si: 10 puntos' },
      { puntos: 5, texto: 'Solo 01: 5 puntos' },
      { puntos: 2, texto: 'No: 2 puntos' }
    ]
  },
  // Criterios de Servicio/Producto
  {
    name: 'disponibilidad',
    label: 'Disponibilidad del producto o servicio',
    max: 15,
    categoria: 'servicio',
    descripciones: [
      { puntos: 15, texto: 'Siempre tiene disponibilidad: 15 puntos' },
      { puntos: 7.5, texto: 'Pocas veces no cuentan: 7.5 puntos' },
      { puntos: 1.5, texto: 'No tiene disponibilidad: 1.5 puntos' }
    ]
  },
  {
    name: 'asesoramiento',
    label: 'Asesoramiento personalizado',
    max: 10,
    categoria: 'servicio',
    descripciones: [
      { puntos: 10, texto: 'Cuenta con asesoramiento: 10 puntos' },
      { puntos: 5, texto: 'Demora en atender: 5 puntos' },
      { puntos: 1, texto: 'No cuenta con asesoramiento: 1 punto' }
    ]
  },
  {
    name: 'especificaciones',
    label: 'Cumple especificaciones de OC',
    max: 20,
    categoria: 'servicio',
    descripciones: [
      { puntos: 20, texto: 'Siempre cumple: 20 puntos' },
      { puntos: 10, texto: 'A veces: 10 puntos' },
      { puntos: 1.5, texto: 'No cumple: 1.5 puntos' }
    ]
  },
  {
    name: 'tiempos',
    label: 'Cumple tiempos establecidos',
    max: 20,
    categoria: 'servicio',
    descripciones: [
      { puntos: 20, texto: 'Siempre cumple: 20 puntos' },
      { puntos: 10, texto: 'A veces: 10 puntos' },
      { puntos: 1.5, texto: 'No cumple: 1.5 puntos' }
    ]
  },
  {
    name: 'certificados',
    label: 'Entrega de certificados/documentación',
    max: 15,
    categoria: 'servicio',
    descripciones: [
      { puntos: 15, texto: 'Siempre cumple: 15 puntos' },
      { puntos: 7.5, texto: 'Parcialmente: 7.5 puntos' },
      { puntos: 1.5, texto: 'No cumple: 1.5 puntos' }
    ]
  },
  {
    name: 'precio',
    label: 'Precio',
    max: 25,
    categoria: 'servicio',
    descripciones: [
      { puntos: 25, texto: 'Mejor precio del mercado: 25 puntos' },
      { puntos: 15, texto: 'Precio promedio: 15 puntos' },
      { puntos: 5, texto: 'Precio por encima del mercado: 5 puntos' }
    ]
  },
  // Criterios Ambientales y SST
  {
    name: 'ambiental',
    label: 'Cumplimiento Ambiental',
    max: 10,
    categoria: 'ambiental',
    descripciones: [
      { puntos: 10, texto: 'Cumple completamente: 10 puntos' },
      { puntos: 5, texto: 'Cumple parcialmente: 5 puntos' },
      { puntos: 0, texto: 'No cumple: 0 puntos' }
    ]
  },
  {
    name: 'sst',
    label: 'Cumplimiento de Seguridad y Salud en el Trabajo',
    max: 10,
    categoria: 'ambiental',
    descripciones: [
      { puntos: 10, texto: 'Cumple completamente: 10 puntos' },
      { puntos: 5, texto: 'Cumple parcialmente: 5 puntos' },
      { puntos: 0, texto: 'No cumple: 0 puntos' }
    ]
  }
];

interface ValoracionProveedorForm {
  usuario_id: string;
  proveedor_id: string;
  puntuacion: number;
  fecha_inicio: string;
  fecha_fin: string;
  fecha_ingreso: string;
  p_s_brindado: string;
  notas?: string;
  condiciones_pago?: number;
  precio?: number;
  control?: number;
  garantia?: number;
  experiencia?: number;
  infraestructura?: number;
  cuestionario?: number;
  disponibilidad?: number;
  asesoramiento?: number;
  especificaciones?: number;
  tiempos?: number;
  certificados?: number;
  ambiental?: number;
  sst?: number;
}

interface ValoracionProveedorFormProps {
  onCancel: () => void;
  valoracionData?: ValoracionProveedor;
  proveedorId?: string;
  productoServicio?: string;
}

// Tipo para el producto/servicio después de convertirlo de string a array
type ProductoServicio = [string, boolean];

// Componente principal
const ValoracionProveedorForm: React.FC<ValoracionProveedorFormProps> = ({
  onCancel,
  valoracionData,
  proveedorId,
  productoServicio
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user);
  
  // Definir un valor por defecto para p_s_brindado
  const PSDef = productoServicio === "" ? 'Ingrese producto o servicio' : productoServicio;
  const defaultPSBrindado = `[["${PSDef}",false]]`;
  
  // Función para convertir el string p_s_brindado a un array
  const stringToArray = (psBrindadoString: string): ProductoServicio[] => {
    if (!psBrindadoString || psBrindadoString === '') return [['', false]];

    try {
      // El string ya viene como un array JSON, simplemente lo parseamos
      return JSON.parse(psBrindadoString);
    } catch (error) {
      console.error("Error al convertir p_s_brindado a array:", error);
      return [['', false]];
    }
  };

  // Función para convertir el array a string para guardar
  const arrayToString = (psArray: ProductoServicio[]): string => {
    try {
      // Convertir el array directamente a string JSON
      console.log(psArray, JSON.stringify(psArray));
      return JSON.stringify(psArray);
    } catch (error) {
      console.error("Error al convertir array a string:", error);
      return '';
    }
  };

  // Productos/Servicios convertidos a array para su manipulación - Usar el valor por defecto
  const [productosServicios, setProductosServicios] = useState<ProductoServicio[]>(
    stringToArray(valoracionData?.p_s_brindado || defaultPSBrindado)
  );

  // Estado inicial del formulario
  const [formData, setFormData] = useState<ValoracionProveedorForm>(() => {
    // Helper function to add 6 months to a date string
    const addSixMonths = (dateStr: string) => {
      const date = new Date(dateStr);
      date.setMonth(date.getMonth() + 6);
      return date.toISOString().split('T')[0];
    };
    
    const today = new Date().toISOString().split('T')[0];
    const defaultEndDate = addSixMonths(today);
    
    return {
      usuario_id: valoracionData?.usuario_id.id || user.id,
      proveedor_id: valoracionData?.proveedor_id.id || proveedorId || '',
      puntuacion: valoracionData?.puntuacion || 0,
      fecha_inicio: valoracionData?.fecha_inicio.split('T')[0] || today,
      fecha_fin: valoracionData?.fecha_fin.split('T')[0] || defaultEndDate,
      fecha_ingreso: valoracionData?.fecha_ingreso.split('T')[0] || today,
      p_s_brindado: valoracionData?.p_s_brindado || defaultPSBrindado, // Usar la misma constante
      notas: valoracionData?.notas || '',
      condiciones_pago: valoracionData?.condiciones_pago || 0,
      precio: valoracionData?.precio || 0,
      control: valoracionData?.control || 0,
      garantia: valoracionData?.garantia || 0,
      experiencia: valoracionData?.experiencia || 0,
      infraestructura: valoracionData?.infraestructura || 0,
      cuestionario: valoracionData?.cuestionario || 0,
      disponibilidad: valoracionData?.disponibilidad || 0,
      asesoramiento: valoracionData?.asesoramiento || 0,
      especificaciones: valoracionData?.especificaciones || 0,
      tiempos: valoracionData?.tiempos || 0,
      certificados: valoracionData?.certificados || 0,
      ambiental: valoracionData?.ambiental || 0,
      sst: valoracionData?.sst || 0,
    };
  });

  // Actualizar p_s_brindado en formData cuando cambia productosServicios
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      p_s_brindado: arrayToString(productosServicios)
    }));
  }, [productosServicios]);

  // Funciones para manejar productos/servicios
  const handlePSChange = (index: number, value: string) => {
    const newPS = [...productosServicios];
    newPS[index] = [value, newPS[index][1]];
    setProductosServicios(newPS);
  };

  const handlePSCriticoChange = (index: number, esCritico: boolean) => {
    const newPS = [...productosServicios];
    newPS[index] = [newPS[index][0], esCritico];
    setProductosServicios(newPS);
  };

  const addPS = () => {
    setProductosServicios([...productosServicios, ['', false]]);
  };

  const removePS = (index: number) => {
    if (productosServicios.length > 1) {
      const newPS = [...productosServicios];
      newPS.splice(index, 1);
      setProductosServicios(newPS);
    }
  };

  // Cálculo del puntaje total actualizado para usar criteriosEvaluacion
  const puntajeTotal = useMemo(() => {
    let suma = 0;
    criteriosEvaluacion.forEach(criterio => {
      if (formData[criterio.name as keyof ValoracionProveedorForm] !== undefined) {
        suma += Number(formData[criterio.name as keyof ValoracionProveedorForm]);
      }
    });
    return suma;
  }, [formData]);

  // Estado del puntaje calificación
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      puntuacion: puntajeTotal
    }));
  }, [puntajeTotal]);

  // Determinar estatus según el puntaje
  const getEstatus = useMemo(() => {
    if (puntajeTotal <= 65) {
      return { text: 'NO ACEPTABLE', color: 'text-red-600 font-bold' };
    } else if (puntajeTotal <= 100) {
      return { text: 'ACEPTADO', color: 'text-green-600 font-bold' };
    } else if (puntajeTotal < 135) {
      return { text: 'DESAPROBADO', color: 'text-red-600 font-bold' };
    } else if (puntajeTotal <= 170) {
      return { text: 'REGULAR', color: 'text-yellow-600 font-bold' };
    } else {
      return { text: 'BUEN PROVEEDOR', color: 'text-green-600 font-bold' };
    }
  }, [puntajeTotal]);

  // Determinar próxima evaluación
  const getProximaEvaluacion = useMemo(() => {
    if (puntajeTotal <= 65) {
      return 'A los 6 Meses';
    } else if (puntajeTotal <= 100) {
      return 'A los 6 Meses';
    } else if (puntajeTotal < 135) {
      return '-';
    } else if (puntajeTotal <= 170) {
      return 'A los 12 meses';
    } else {
      return 'A los 18 meses';
    }
  }, [puntajeTotal]);

  // Manejar cambios en los inputs
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (type === 'number') {
      // Validar rango para inputs numéricos
      const numValue = parseFloat(value);
      const max = e.target.getAttribute('max') ? parseInt(e.target.getAttribute('max') || '100') : 100;

      if (!isNaN(numValue) && numValue >= 0 && numValue <= max) {
        setFormData(prev => ({ ...prev, [name]: numValue }));
      }
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  // Función para enviar el formulario
  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    // Asegurar que p_s_brindado esté actualizado antes de enviar
    const dataToSubmit: ValoracionProveedorForm = {
      ...formData,
      p_s_brindado: arrayToString(productosServicios)
    };

    console.log(dataToSubmit);
    
    // Determinar si estamos creando o actualizando
    if (valoracionData?.id && valoracionData.id !== "nuevo") {
      // Actualizar valoración existente
      dispatch(updateValoracionProveedor({
        id: valoracionData.id,
        ...dataToSubmit
      }));
    } else {
      // Crear nueva valoración
      dispatch(addValoracionProveedor(dataToSubmit));
    }
    
    onCancel();
  };

  // Renderizado del componente
  return (
    <form className="w-full max-w-5xl mx-auto space-y-4 p-3 md:p-6 bg-white/95 backdrop-blur-sm rounded-xl shadow-2xl">
      <div className="flex flex-col md:flex-row justify-between border-b border-gray-300 pb-4 mb-6">
        <div>
          <h3 className="text-xl md:text-2xl font-bold text-gray-800">
            {valoracionData?.id ? 'Editar Evaluación de Proveedor' : 'Nueva Evaluación de Proveedor'}
          </h3>
          <p className="text-sm text-gray-600">Formato FO-LOC-004 - Ficha de Proveedor</p>
        </div>
        <div className="mt-4 md:mt-0 flex flex-col items-end">
          <div className="text-lg font-bold">Puntaje Total: <span className="text-blue-600">{puntajeTotal}</span></div>
          <div>Estatus: <span className={getEstatus.color}>{getEstatus.text}</span></div>
          <div>Próxima Evaluación: <span className="font-medium">{getProximaEvaluacion}</span></div>
        </div>
      </div>

      {/* Sección de datos generales */}
      <div className="bg-gray-50 p-4 rounded-lg shadow-md">
        <h4 className="text-lg font-semibold text-gray-800 mb-4 border-b pb-2">Datos Generales</h4>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-2">
          <div className="group">
            <label className="block text-sm font-medium text-gray-800 mb-1">
              Fecha de Ingreso <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="fecha_ingreso"
              value={formData.fecha_ingreso}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-sm px-3 py-2
                       focus:ring-4 focus:ring-blue-500/30 focus:border-blue-500
                       hover:border-blue-400 transition-all duration-300
                       bg-white/90 backdrop-blur-sm"
              required
            />
          </div>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-800 mb-1">
            Productos/Servicios Brindados <span className="text-red-500">*</span>
          </label>

          {productosServicios.map((ps, index) => (
            <div key={index} className="flex flex-col md:flex-row items-start md:items-center mb-4 border-b pb-2">
              <div className="flex-1 mb-2 md:mb-0 md:mr-4 w-full md:w-auto">
                <input
                  type="text"
                  value={ps[0]}
                  onChange={(e) => handlePSChange(index, e.target.value)}
                  placeholder="Ingrese producto o servicio"
                  className={`${ps[0]==="Ingrese producto o servicio" ? "text-red-500": "text-green-500"} w-full rounded-lg border-gray-300 shadow-sm px-3 py-2
                           focus:ring-4 focus:ring-blue-500/30 focus:border-blue-500
                           hover:border-blue-400 transition-all duration-300
                           backdrop-blur-sm bg-white/90`}
                  required
                />
              </div>

              <div className="flex items-center space-x-4 mb-2 md:mb-0 mr-4">
                <label className="text-sm font-medium text-gray-800">¿Es Crítico?</label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name={`es_critico_${index}`}
                      checked={ps[1] === true}
                      onChange={() => handlePSCriticoChange(index, true)}
                      className="mr-2"
                    />
                    <span>Sí</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name={`es_critico_${index}`}
                      checked={ps[1] === false}
                      onChange={() => handlePSCriticoChange(index, false)}
                      className="mr-2"
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>

              <button
                type="button"
                onClick={() => removePS(index)}
                className="ml-2 text-red-500 hover:text-red-700 flex-shrink-0"
                disabled={productosServicios.length <= 1}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          ))}

          <button
            type="button"
            onClick={addPS}
            className="mt-1 text-blue-600 hover:text-blue-800 flex items-center text-sm"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            Agregar Producto/Servicio
          </button>
        </div>
      </div>

      {/* Sección de Evaluación de Selección - Refactorizada */}
      <div className="bg-blue-50 p-4 rounded-lg shadow-md">
        <h4 className="text-lg font-semibold text-gray-800 mb-4 border-b border-blue-200 pb-2">Evaluación de Selección</h4>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-2">
          {criteriosEvaluacion
            .filter(criterio => criterio.categoria === 'seleccion')
            .map(criterio => (
              <CriterioInput
                key={criterio.name}
                criterio={criterio}
                value={formData[criterio.name as keyof ValoracionProveedorForm] as number || 0}
                onChange={handleChange}
              />
            ))
          }
        </div>
      </div>

      {/* Sección de Evaluación del Servicio/Producto - Refactorizada */}
      <div className="bg-green-50 p-4 rounded-lg shadow-md">
        <h4 className="text-lg font-semibold text-gray-800 mb-4 border-b border-green-200 pb-2">Evaluación del Servicio/Producto</h4>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-2">
          {criteriosEvaluacion
            .filter(criterio => criterio.categoria === 'servicio')
            .map(criterio => (
              <CriterioInput
                key={criterio.name}
                criterio={criterio}
                value={formData[criterio.name as keyof ValoracionProveedorForm] as number || 0}
                onChange={handleChange}
              />
            ))
          }
        </div>
      </div>

      {/* Sección de Evaluación Ambiental y SST - Refactorizada */}
      <div className="bg-yellow-50 p-4 rounded-lg shadow-md">
        <h4 className="text-lg font-semibold text-gray-800 mb-4 border-b border-yellow-200 pb-2">Evaluación Ambiental y SST</h4>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-2">
          {criteriosEvaluacion
            .filter(criterio => criterio.categoria === 'ambiental')
            .map(criterio => (
              <CriterioInput
                key={criterio.name}
                criterio={criterio}
                value={formData[criterio.name as keyof ValoracionProveedorForm] as number || 0}
                onChange={handleChange}
              />
            ))
          }
        </div>
      </div>

      {/* Sección de Período de Evaluación */}
      <div className="bg-purple-50 p-4 rounded-lg shadow-md">
        <h4 className="text-lg font-semibold text-gray-800 mb-4 border-b border-purple-200 pb-2">Período de Evaluación</h4>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-2">
          <div className="group">
            <label className="block text-sm font-medium text-gray-800 mb-1">
              Fecha Evaluación <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="fecha_inicio"
              value={formData.fecha_inicio}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-sm px-3 py-2
                     focus:ring-4 focus:ring-blue-500/30 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/90 backdrop-blur-sm"
              required
            />
          </div>

          <div className="group">
            <label className="block text-sm font-medium text-gray-800 mb-1">
              Fecha Proxima Evaluación <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="fecha_fin"
              value={formData.fecha_fin}
              onChange={handleChange}
              className="block w-full rounded-lg border-gray-300 shadow-sm px-3 py-2
                     focus:ring-4 focus:ring-blue-500/30 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/90 backdrop-blur-sm"
              required
            />
          </div>
        </div>
      </div>

      {/* Sección de notas adicionales */}
      <div className="bg-gray-50 p-4 rounded-lg shadow-md">
        <h4 className="text-lg font-semibold text-gray-800 mb-4 border-b border-gray-200 pb-2">Observaciones Adicionales</h4>

        <div className="group">
          <label className="block text-sm font-medium text-gray-800 mb-1">
            Notas
          </label>
          <textarea
            name="notas"
            value={formData.notas || ''}
            onChange={handleChange}
            rows={4}
            className="block w-full rounded-lg border-gray-300 shadow-sm px-3 py-2
                     focus:ring-4 focus:ring-blue-500/30 focus:border-blue-500
                     hover:border-blue-400 transition-all duration-300
                     bg-white/90 backdrop-blur-sm"
            placeholder="Ingrese observaciones o notas adicionales sobre el proveedor..."
          />
        </div>
      </div>

      {/* Sección de resumen y acciones */}
      <div className="bg-gray-100 p-4 rounded-lg shadow-md">
        <div className="flex flex-col md:flex-row justify-between mb-4">
          <div>
            <h4 className="text-lg font-semibold text-gray-800 mb-2">Resumen de Evaluación</h4>
            <div className="text-md">Puntaje Total: <span className="font-bold text-blue-600">{puntajeTotal}</span></div>
            <div>Estatus: <span className={getEstatus.color}>{getEstatus.text}</span></div>
            <div>Próxima Evaluación: <span className="font-medium">{getProximaEvaluacion}</span></div>
          </div>

          <div className="flex flex-col space-y-2 mt-4 md:mt-0 md:space-y-0 md:space-x-3 md:flex-row">
            <Button
              onClick={onCancel}
              className="px-4 py-2 text-gray-700 border border-gray-300 hover:bg-gray-100"
              text="Cancelar"
            />
            <button
              onClick={(e) => handleSubmit(e)}
              className="px-4 py-2 bg-blue-600 text-white hover:bg-blue-700"
              type="button"
            >
              {valoracionData?.id && valoracionData.id !== "nuevo" ? 'Actualizar Evaluación' : 'Guardar Evaluación'}
            </button>
          </div>
        </div>

        <div className="text-xs text-gray-500 mt-2">
          <p>* Los campos marcados con asterisco son obligatorios.</p>
          <p>* La evaluación se basa en los criterios establecidos en el formato FO-LOC-004.</p>
        </div>
      </div>
    </form>
  );
};

export default ValoracionProveedorForm;