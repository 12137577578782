import React, { useState, useEffect } from 'react';

interface PropiedadesAdicionalesFormProps {
  tipo: string;
  propiedadesAdicionales: string;
  onChange: (jsonString: string) => void;
}

interface PropiedadesJson {
  etiquetas?: Record<string, string>;
  [key: string]: any;
}

const PropiedadesAdicionalesForm: React.FC<PropiedadesAdicionalesFormProps> = ({
  tipo,
  propiedadesAdicionales,
  onChange
}) => {
  const [propiedades, setPropiedades] = useState<PropiedadesJson>({});
  const [etiquetas, setEtiquetas] = useState<Array<{valor: string, texto: string}>>([]);

  useEffect(() => {
    try {
      // Si la cadena contiene caracteres de escape, primero la decodificamos
      let jsonStr = propiedadesAdicionales;
      if (propiedadesAdicionales.includes('\\n') || propiedadesAdicionales.includes('\\"')) {
        jsonStr = JSON.parse(propiedadesAdicionales);
      }
      
      // Si es una cadena, intentamos parsearlo como JSON
      if (typeof jsonStr === 'string') {
        const props: PropiedadesJson = JSON.parse(jsonStr || '{}');
        setPropiedades(props);
        
        // Si hay etiquetas, convertirlas a formato de array
        if (props.etiquetas) {
          const etiquetasArray = Object.entries(props.etiquetas).map(([valor, texto]) => ({
            valor,
            texto: texto as string
          }));
          setEtiquetas(etiquetasArray);
        }
      } else {
        // Si ya es un objeto, lo usamos directamente
        const props = jsonStr as PropiedadesJson;
        setPropiedades(props);
        if (props.etiquetas) {
          const etiquetasArray = Object.entries(props.etiquetas).map(([valor, texto]) => ({
            valor,
            texto: texto as string
          }));
          setEtiquetas(etiquetasArray);
        }
      }
    } catch (error) {
      console.error('Error al parsear propiedades adicionales:', error);
      // En caso de error, intentamos establecer un objeto vacío
      setPropiedades({});
      setEtiquetas([]);
    }
  }, [propiedadesAdicionales]);

  const actualizarPropiedades = (nuevasProps: any) => {
    setPropiedades(nuevasProps);
    onChange(JSON.stringify(nuevasProps, null, 2));
  };

  const actualizarEtiquetas = (nuevasEtiquetas: Array<{valor: string, texto: string}>) => {
    setEtiquetas(nuevasEtiquetas);
    const etiquetasObj = nuevasEtiquetas.reduce((acc, {valor, texto}) => {
      acc[valor] = texto;
      return acc;
    }, {} as Record<string, string>);
    
    actualizarPropiedades({
      ...propiedades,
      etiquetas: etiquetasObj
    });
  };

  const agregarEtiqueta = () => {
    setEtiquetas([...etiquetas, { valor: '', texto: '' }]);
  };

  const eliminarEtiqueta = (index: number) => {
    setEtiquetas(etiquetas.filter((_, i) => i !== index));
  };

  const actualizarEtiqueta = (index: number, campo: 'valor' | 'texto', valor: string) => {
    const nuevasEtiquetas = [...etiquetas];
    nuevasEtiquetas[index] = { ...nuevasEtiquetas[index], [campo]: valor };
    actualizarEtiquetas(nuevasEtiquetas);
  };

  const renderFormularioRango = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Valor mínimo</label>
          <input
            type="number"
            value={propiedades.min || 0}
            onChange={(e) => actualizarPropiedades({ ...propiedades, min: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Valor máximo</label>
          <input
            type="number"
            value={propiedades.max || 10}
            onChange={(e) => actualizarPropiedades({ ...propiedades, max: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Paso</label>
          <input
            type="number"
            value={propiedades.paso || 1}
            onChange={(e) => actualizarPropiedades({ ...propiedades, paso: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <label className="block text-sm font-semibold text-gray-700">Etiquetas</label>
          <button
            type="button"
            onClick={agregarEtiqueta}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 ease-in-out flex items-center gap-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            Agregar etiqueta
          </button>
        </div>
        <div className="space-y-3">
          {etiquetas.map((etiqueta, index) => (
            <div key={index} className="flex gap-3 items-center bg-gray-50 p-3 rounded-lg">
              <input
                type="number"
                value={etiqueta.valor}
                onChange={(e) => actualizarEtiqueta(index, 'valor', e.target.value)}
                placeholder="Valor"
                className="flex-1 px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
              />
              <input
                type="text"
                value={etiqueta.texto}
                onChange={(e) => actualizarEtiqueta(index, 'texto', e.target.value)}
                placeholder="Texto"
                className="flex-1 px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
              />
              <button
                type="button"
                onClick={() => eliminarEtiqueta(index)}
                className="p-2 text-red-600 hover:text-red-800 hover:bg-red-50 rounded-lg transition-colors duration-200 ease-in-out"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderFormularioSelect = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Placeholder</label>
        <input
          type="text"
          value={propiedades.placeholder || ''}
          onChange={(e) => actualizarPropiedades({ ...propiedades, placeholder: e.target.value })}
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
      </div>
      <div className="space-y-4">
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="checkbox"
            checked={propiedades.busqueda || false}
            onChange={(e) => actualizarPropiedades({ ...propiedades, busqueda: e.target.checked })}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
          />
          <label className="text-sm text-gray-700">Permitir búsqueda</label>
        </div>
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="checkbox"
            checked={propiedades.permitirLimpiar || false}
            onChange={(e) => actualizarPropiedades({ ...propiedades, permitirLimpiar: e.target.checked })}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
          />
          <label className="text-sm text-gray-700">Permitir limpiar selección</label>
        </div>
      </div>
    </div>
  );

  const renderFormularioArchivo = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Tipos de archivo permitidos</label>
        <input
          type="text"
          value={propiedades.tiposPermitidos?.join(', ') || ''}
          onChange={(e) => actualizarPropiedades({
            ...propiedades,
            tiposPermitidos: e.target.value.split(',').map(t => t.trim())
          })}
          placeholder="image/jpeg, image/png, application/pdf"
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Tamaño máximo (MB)</label>
          <input
            type="number"
            value={propiedades.tamañoMaximo ? propiedades.tamañoMaximo / 1024 / 1024 : 5}
            onChange={(e) => actualizarPropiedades({
              ...propiedades,
              tamañoMaximo: Number(e.target.value) * 1024 * 1024
            })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Cantidad máxima de archivos</label>
          <input
            type="number"
            value={propiedades.cantidadMaxima || 1}
            onChange={(e) => actualizarPropiedades({
              ...propiedades,
              cantidadMaxima: Number(e.target.value)
            })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
    </div>
  );

  const renderFormularioPuntuacion = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Valor máximo</label>
          <input
            type="number"
            value={propiedades.maximo || 5}
            onChange={(e) => actualizarPropiedades({ ...propiedades, maximo: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Color</label>
          <input
            type="color"
            value={propiedades.color || '#FFD700'}
            onChange={(e) => actualizarPropiedades({ ...propiedades, color: e.target.value })}
            className="w-full h-10 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
      <div className="space-y-4">
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="checkbox"
            checked={propiedades.estrellas || true}
            onChange={(e) => actualizarPropiedades({ ...propiedades, estrellas: e.target.checked })}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
          />
          <label className="text-sm text-gray-700">Usar estrellas</label>
        </div>
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="checkbox"
            checked={propiedades.mostrarNumeros || true}
            onChange={(e) => actualizarPropiedades({ ...propiedades, mostrarNumeros: e.target.checked })}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
          />
          <label className="text-sm text-gray-700">Mostrar números</label>
        </div>
      </div>
    </div>
  );

  const renderFormularioCheckbox = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Alineación</label>
        <select
          value={propiedades.alineacion || 'horizontal'}
          onChange={(e) => actualizarPropiedades({ ...propiedades, alineacion: e.target.value })}
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        >
          <option value="horizontal">Horizontal</option>
          <option value="vertical">Vertical</option>
        </select>
      </div>
      <div className="space-y-4">
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="checkbox"
            checked={propiedades.mostrarTodos || false}
            onChange={(e) => actualizarPropiedades({ ...propiedades, mostrarTodos: e.target.checked })}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
          />
          <label className="text-sm text-gray-700">Mostrar opción "Todos"</label>
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Máximo de selección</label>
          <input
            type="number"
            value={propiedades.maximoSeleccion || 0}
            onChange={(e) => actualizarPropiedades({ ...propiedades, maximoSeleccion: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
          <p className="text-xs text-gray-500">0 = sin límite</p>
        </div>
      </div>
    </div>
  );

  const renderFormularioTexto = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Mínimo de caracteres</label>
          <input
            type="number"
            value={propiedades.minCaracteres || 0}
            onChange={(e) => actualizarPropiedades({ ...propiedades, minCaracteres: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Máximo de caracteres</label>
          <input
            type="number"
            value={propiedades.maxCaracteres || 0}
            onChange={(e) => actualizarPropiedades({ ...propiedades, maxCaracteres: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Patrón de validación (regex)</label>
        <input
          type="text"
          value={propiedades.patron || ''}
          onChange={(e) => actualizarPropiedades({ ...propiedades, patron: e.target.value })}
          placeholder="^[a-zA-Z0-9]+$"
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
        <p className="text-xs text-gray-500">Ejemplo: ^[a-zA-Z0-9]+$ para solo letras y números</p>
      </div>
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Mensaje de error</label>
        <input
          type="text"
          value={propiedades.mensajeError || ''}
          onChange={(e) => actualizarPropiedades({ ...propiedades, mensajeError: e.target.value })}
          placeholder="Solo se permiten letras y números"
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
      </div>
    </div>
  );

  const renderFormularioNumero = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Valor mínimo</label>
          <input
            type="number"
            value={propiedades.min || 0}
            onChange={(e) => actualizarPropiedades({ ...propiedades, min: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Valor máximo</label>
          <input
            type="number"
            value={propiedades.max || 0}
            onChange={(e) => actualizarPropiedades({ ...propiedades, max: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Decimales</label>
          <input
            type="number"
            value={propiedades.decimales || 0}
            onChange={(e) => actualizarPropiedades({ ...propiedades, decimales: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Separador de miles</label>
          <input
            type="text"
            value={propiedades.separadorMiles || ','}
            onChange={(e) => actualizarPropiedades({ ...propiedades, separadorMiles: e.target.value })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Separador decimal</label>
          <input
            type="text"
            value={propiedades.separadorDecimal || '.'}
            onChange={(e) => actualizarPropiedades({ ...propiedades, separadorDecimal: e.target.value })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
    </div>
  );

  const renderFormularioEmail = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Dominios permitidos</label>
        <input
          type="text"
          value={propiedades.dominiosPermitidos?.join(', ') || ''}
          onChange={(e) => actualizarPropiedades({
            ...propiedades,
            dominiosPermitidos: e.target.value.split(',').map(d => d.trim())
          })}
          placeholder="@empresa.com, @cliente.com"
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
        <p className="text-xs text-gray-500">Separe los dominios con comas</p>
      </div>
      <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
        <input
          type="checkbox"
          checked={propiedades.validacionPersonalizada || false}
          onChange={(e) => actualizarPropiedades({ ...propiedades, validacionPersonalizada: e.target.checked })}
          className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
        />
        <label className="text-sm text-gray-700">Usar validación personalizada</label>
      </div>
    </div>
  );

  const renderFormularioTelefono = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Formato</label>
        <input
          type="text"
          value={propiedades.formato || '(XXX) XXX-XXXX'}
          onChange={(e) => actualizarPropiedades({ ...propiedades, formato: e.target.value })}
          placeholder="(XXX) XXX-XXXX"
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
      </div>
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">País</label>
        <input
          type="text"
          value={propiedades.pais || 'MX'}
          onChange={(e) => actualizarPropiedades({ ...propiedades, pais: e.target.value })}
          placeholder="MX"
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
      </div>
      <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
        <input
          type="checkbox"
          checked={propiedades.validacionInternacional || false}
          onChange={(e) => actualizarPropiedades({ ...propiedades, validacionInternacional: e.target.checked })}
          className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
        />
        <label className="text-sm text-gray-700">Validación internacional</label>
      </div>
    </div>
  );

  const renderFormularioFecha = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Formato</label>
        <select
          value={propiedades.formato || 'DD/MM/YYYY'}
          onChange={(e) => actualizarPropiedades({ ...propiedades, formato: e.target.value })}
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        >
          <option value="DD/MM/YYYY">DD/MM/YYYY</option>
          <option value="MM/DD/YYYY">MM/DD/YYYY</option>
          <option value="YYYY-MM-DD">YYYY-MM-DD</option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Fecha mínima</label>
          <input
            type="date"
            value={propiedades.min || ''}
            onChange={(e) => actualizarPropiedades({ ...propiedades, min: e.target.value })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Fecha máxima</label>
          <input
            type="date"
            value={propiedades.max || ''}
            onChange={(e) => actualizarPropiedades({ ...propiedades, max: e.target.value })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
      <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
        <input
          type="checkbox"
          checked={propiedades.mostrarCalendario || true}
          onChange={(e) => actualizarPropiedades({ ...propiedades, mostrarCalendario: e.target.checked })}
          className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
        />
        <label className="text-sm text-gray-700">Mostrar selector de calendario</label>
      </div>
    </div>
  );

  const renderFormularioHora = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Formato</label>
        <select
          value={propiedades.formato || '24h'}
          onChange={(e) => actualizarPropiedades({ ...propiedades, formato: e.target.value })}
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        >
          <option value="24h">24 horas</option>
          <option value="12h">12 horas</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Intervalo (minutos)</label>
        <input
          type="number"
          value={propiedades.intervalo || 30}
          onChange={(e) => actualizarPropiedades({ ...propiedades, intervalo: Number(e.target.value) })}
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
      </div>
      <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
        <input
          type="checkbox"
          checked={propiedades.mostrarReloj || true}
          onChange={(e) => actualizarPropiedades({ ...propiedades, mostrarReloj: e.target.checked })}
          className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
        />
        <label className="text-sm text-gray-700">Mostrar selector de reloj</label>
      </div>
    </div>
  );

  const renderFormularioEtiqueta = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Color</label>
          <input
            type="color"
            value={propiedades.color || '#000000'}
            onChange={(e) => actualizarPropiedades({ ...propiedades, color: e.target.value })}
            className="w-full h-10 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Tamaño</label>
          <input
            type="text"
            value={propiedades.tamaño || '16px'}
            onChange={(e) => actualizarPropiedades({ ...propiedades, tamaño: e.target.value })}
            placeholder="16px"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
      <div className="space-y-4">
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="checkbox"
            checked={propiedades.negrita || false}
            onChange={(e) => actualizarPropiedades({ ...propiedades, negrita: e.target.checked })}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
          />
          <label className="text-sm text-gray-700">Negrita</label>
        </div>
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="checkbox"
            checked={propiedades.cursiva || false}
            onChange={(e) => actualizarPropiedades({ ...propiedades, cursiva: e.target.checked })}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
          />
          <label className="text-sm text-gray-700">Cursiva</label>
        </div>
      </div>
    </div>
  );

  const renderFormularioSeccion = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Estilo</label>
        <select
          value={propiedades.estilo || 'card'}
          onChange={(e) => actualizarPropiedades({ ...propiedades, estilo: e.target.value })}
          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        >
          <option value="card">Tarjeta</option>
          <option value="simple">Simple</option>
          <option value="bordered">Con borde</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-700">Color de fondo</label>
        <input
          type="color"
          value={propiedades.colorFondo || '#F5F5F5'}
          onChange={(e) => actualizarPropiedades({ ...propiedades, colorFondo: e.target.value })}
          className="w-full h-10 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
        />
      </div>
      <div className="space-y-4">
        <div className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <input
            type="checkbox"
            checked={propiedades.borde || true}
            onChange={(e) => actualizarPropiedades({ ...propiedades, borde: e.target.checked })}
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-colors duration-200 ease-in-out"
          />
          <label className="text-sm text-gray-700">Mostrar borde</label>
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-semibold text-gray-700">Espaciado (px)</label>
          <input
            type="number"
            value={propiedades.espaciado || 20}
            onChange={(e) => actualizarPropiedades({ ...propiedades, espaciado: Number(e.target.value) })}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
    </div>
  );

  const renderFormularioDefault = () => (
    <div className="bg-white rounded-xl shadow-sm p-6 space-y-4">
      <textarea
        value={propiedadesAdicionales}
        onChange={(e) => onChange(e.target.value)}
        rows={6}
        placeholder="{}"
        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ease-in-out font-mono text-sm"
      />
      <p className="text-sm text-gray-500 italic">
        Ingrese las propiedades adicionales en formato JSON
      </p>
    </div>
  );

  const renderFormulario = () => {
    switch (tipo) {
      case 'rango':
        return renderFormularioRango();
      case 'select':
      case 'select_multiple':
        return renderFormularioSelect();
      case 'archivo':
        return renderFormularioArchivo();
      case 'puntuacion':
        return renderFormularioPuntuacion();
      case 'checkbox':
        return renderFormularioCheckbox();
      case 'texto':
        return renderFormularioTexto();
      case 'numero':
        return renderFormularioNumero();
      case 'email':
        return renderFormularioEmail();
      case 'telefono':
        return renderFormularioTelefono();
      case 'fecha':
        return renderFormularioFecha();
      case 'hora':
        return renderFormularioHora();
      case 'etiqueta':
        return renderFormularioEtiqueta();
      case 'seccion':
        return renderFormularioSeccion();
      default:
        return renderFormularioDefault();
    }
  };

  return (
    <div className="mt-4">
      {renderFormulario()}
    </div>
  );
};

export default PropiedadesAdicionalesForm; 