import { gql } from '@apollo/client';
import client from '../apolloClient';

export const LIST_RECURSOS_PRESUPUESTO_QUERY = gql`
  query ListRecursoPresupuesto {
    listRecursoPresupuesto {
      id
      id_recurso_presupuesto
      recurso_id
      nombre
      recurso {
        id
        recurso_id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
        imagenes {
          file
          id
        }
      }
    }
  }
`;

export const GET_RECURSO_PRESUPUESTO_QUERY = gql`
  query GetRecursoPresupuesto($getRecursoPresupuestoId: ID!) {
    getRecursoPresupuesto(id: $getRecursoPresupuestoId) {
      id
      id_recurso_presupuesto
      recurso_id
      nombre
      recurso {
        id
        recurso_id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
        imagenes {
          file
          id
        }
      }
    }
  }
`;

export const CREATE_RECURSO_PRESUPUESTO_MUTATION = gql`
  mutation CreateRecursoPresupuesto($recursoId: ID!) {
    createRecursoPresupuesto(recurso_id: $recursoId) {
      id
      id_recurso_presupuesto
      recurso_id
      nombre
      recurso {
        id
        recurso_id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
        imagenes {
          id
          file
        }
      }
    }
  }
`;

export const UPDATE_RECURSO_PRESUPUESTO_MUTATION = gql`
  mutation UpdateRecursoPresupuesto($nombre: String, $updateRecursoPresupuestoId: ID!) {
    updateRecursoPresupuesto(nombre: $nombre, id: $updateRecursoPresupuestoId) {
      id
      id_recurso_presupuesto
      recurso_id
      nombre
      recurso {
        id
        recurso_id
        codigo
        nombre
        descripcion
        fecha
        cantidad
        unidad_id
        precio_actual
        vigente
        tipo_recurso_id
        tipo_costo_recurso_id
        clasificacion_recurso_id
        imagenes {
          id
          file
        }
      }
    }
  }
`;

export const DELETE_RECURSO_PRESUPUESTO_MUTATION = gql`
  mutation DeleteRecursoPresupuesto($deleteRecursoPresupuestoId: ID!) {
    deleteRecursoPresupuesto(id: $deleteRecursoPresupuestoId) {
      id
    }
  }
`;

export const listRecursosPresupuestoService = async () => {
  try {
    const response = await client.query({
      query: LIST_RECURSOS_PRESUPUESTO_QUERY,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.listRecursoPresupuesto;
  } catch (error) {
    console.error('Error al obtener la lista de recursos presupuesto:', error);
    throw error;
  }
};

export const getRecursoPresupuestoService = async (id: string) => {
  try {
    const response = await client.query({
      query: GET_RECURSO_PRESUPUESTO_QUERY,
      variables: { getRecursoPresupuestoId: id },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.getRecursoPresupuesto;
  } catch (error) {
    console.error('Error al obtener el recurso presupuesto:', error);
    throw error;
  }
};

export const createRecursoPresupuestoService = async (recursoId: string) => {
  try {
    const response = await client.mutate({
      mutation: CREATE_RECURSO_PRESUPUESTO_MUTATION,
      variables: { recursoId },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.createRecursoPresupuesto;
  } catch (error) {
    console.error('Error al crear el recurso presupuesto:', error);
    throw error;
  }
};

export const updateRecursoPresupuestoService = async (id: string, nombre: string) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_RECURSO_PRESUPUESTO_MUTATION,
      variables: { 
        updateRecursoPresupuestoId: id,
        nombre 
      },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.updateRecursoPresupuesto;
  } catch (error) {
    console.error('Error al actualizar el recurso presupuesto:', error);
    throw error;
  }
};

export const deleteRecursoPresupuestoService = async (id: string) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_RECURSO_PRESUPUESTO_MUTATION,
      variables: { deleteRecursoPresupuestoId: id },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.deleteRecursoPresupuesto;
  } catch (error) {
    console.error('Error al eliminar el recurso presupuesto:', error);
    throw error;
  }
};
