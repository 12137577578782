import { gql } from '@apollo/client';
import client from '../apolloClient';
import { OrdenServicioFecha } from '../types/ordenServicioFecha';

const LIST_ORDEN_SERVICIO_FECHA_QUERY = gql`
  query ListOrdenServicioFechas {
    listOrdenServicioFechas {
      orden_servicio_recurso_id
      fecha_inicio
      fecha_fin
      created_at
      updated_at
    }
  }
`;

const GET_ORDEN_SERVICIO_FECHA_QUERY = gql`
  query GetOrdenServicioFecha($getOrdenServicioFechaId: ID!) {
    getOrdenServicioFecha(id: $getOrdenServicioFechaId) {
      orden_servicio_recurso_id
      fecha_inicio
      fecha_fin
      created_at
      updated_at
    }
  }
`;

const ADD_ORDEN_SERVICIO_FECHA_MUTATION = gql`
  mutation AddOrdenServicioFecha($ordenServicioRecursoId: String!, $fechaInicio: Date!, $fechaFin: Date!) {
    addOrdenServicioFecha(orden_servicio_recurso_id: $ordenServicioRecursoId, fecha_inicio: $fechaInicio, fecha_fin: $fechaFin) {
      orden_servicio_recurso_id
      fecha_inicio
      fecha_fin
      created_at
      updated_at
    }
  }
`;

const UPDATE_ORDEN_SERVICIO_FECHA_MUTATION = gql`
  mutation UpdateOrdenServicioFecha($updateOrdenServicioFechaId: String!, $fechaInicio: Date, $fechaFin: Date) {
    updateOrdenServicioFecha(id: $updateOrdenServicioFechaId, fecha_inicio: $fechaInicio, fecha_fin: $fechaFin) {
      orden_servicio_recurso_id
      fecha_inicio
      fecha_fin
      created_at
      updated_at
    }
  }
`;

const GET_ORDEN_SERVICIO_FECHAS_BY_ORDEN_ID_QUERY = gql`
  query GetOrdenServicioFechasByOrdenId($ordenServicioRecursoId: String!) {
    getOrdenServicioFechasByOrdenId(orden_servicio_recurso_id: $ordenServicioRecursoId) {
      orden_servicio_recurso_id
      fecha_inicio
      fecha_fin
      created_at
      updated_at
    }
  }
`;

export const listOrdenServicioFechasService = async (): Promise<OrdenServicioFecha[]> => {
  try {
    const response = await client.query({
      query: LIST_ORDEN_SERVICIO_FECHA_QUERY,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    
    return response.data.listOrdenServicioFechas;
  } catch (error) {
    console.error('Error al obtener la lista de fechas de órdenes de servicio:', error);
    throw error;
  }
};

export const getOrdenServicioFechaService = async (id: string): Promise<OrdenServicioFecha> => {
  try {
    const response = await client.query({
      query: GET_ORDEN_SERVICIO_FECHA_QUERY,
      variables: { getOrdenServicioFechaId: id },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.getOrdenServicioFecha;
  } catch (error) {
    console.error('Error al obtener la fecha de orden de servicio:', error);
    throw error;
  }
};

export const addOrdenServicioFechaService = async (ordenServicioFechaData: {
  ordenServicioRecursoId: string;
  fechaInicio: Date;
  fechaFin: Date;
}): Promise<OrdenServicioFecha> => {
  try {
    const response = await client.mutate({
      mutation: ADD_ORDEN_SERVICIO_FECHA_MUTATION,
      variables: ordenServicioFechaData,
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.addOrdenServicioFecha;
  } catch (error) {
    console.error('Error al crear la fecha de orden de servicio:', error);
    throw error;
  }
};

export const updateOrdenServicioFechaService = async (ordenServicioFecha: {
  id: string;
  fechaInicio?: Date;
  fechaFin?: Date;
}): Promise<OrdenServicioFecha> => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_ORDEN_SERVICIO_FECHA_MUTATION,
      variables: {
        updateOrdenServicioFechaId: ordenServicioFecha.id,
        fechaInicio: ordenServicioFecha.fechaInicio,
        fechaFin: ordenServicioFecha.fechaFin
      },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.updateOrdenServicioFecha;
  } catch (error) {
    console.error('Error al actualizar la fecha de orden de servicio:', error);
    throw error;
  }
};

export const getOrdenServicioFechasByOrdenIdService = async (ordenServicioRecursoId: string): Promise<OrdenServicioFecha[]> => {
  try {
    const response = await client.query({
      query: GET_ORDEN_SERVICIO_FECHAS_BY_ORDEN_ID_QUERY,
      variables: { ordenServicioRecursoId },
    });
    if (response.errors) {
      throw new Error(response.errors[0]?.message || 'Error desconocido');
    }
    return response.data.getOrdenServicioFechasByOrdenId;
  } catch (error) {
    console.error('Error al obtener las fechas de orden de servicio por ID:', error);
    throw error;
  }
}; 