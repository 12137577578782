import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ComprobanteArchivos,
  ComprobanteArchivosInput,
  listComprobanteArchivosService,
  getComprobanteArchivosByComprobanteService,
  uploadComprobanteArchivoService,
  deleteComprobanteArchivoService,
} from '../services/comprobanteArchivosService';

interface ComprobanteArchivosState {
  archivos: ComprobanteArchivos[];
  archivosByComprobante: ComprobanteArchivos[];
  loading: boolean;
  error: string | null;
  loadingByComprobante: boolean;
  errorByComprobante: string | null;
}

const initialState: ComprobanteArchivosState = {
  archivos: [],
  archivosByComprobante: [],
  loading: false,
  error: null,
  loadingByComprobante: false,
  errorByComprobante: null
};

export const fetchComprobanteArchivos = createAsyncThunk(
  'comprobanteArchivos/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await listComprobanteArchivosService();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchComprobanteArchivosByComprobante = createAsyncThunk(
  'comprobanteArchivos/fetchByComprobante',
  async (comprobanteId: string, { rejectWithValue }) => {
    try {
      return await getComprobanteArchivosByComprobanteService(comprobanteId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const uploadComprobanteArchivo = createAsyncThunk(
  'comprobanteArchivos/upload',
  async (input: ComprobanteArchivosInput, { rejectWithValue }) => {
    try {
      return await uploadComprobanteArchivoService(input);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteComprobanteArchivo = createAsyncThunk(
  'comprobanteArchivos/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteComprobanteArchivoService(id);
      return id;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const comprobanteArchivosSlice = createSlice({
  name: 'comprobanteArchivos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchComprobanteArchivos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchComprobanteArchivos.fulfilled, (state, action: PayloadAction<ComprobanteArchivos[]>) => {
        state.loading = false;
        state.archivos = action.payload;
      })
      .addCase(fetchComprobanteArchivos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch by comprobante
      .addCase(fetchComprobanteArchivosByComprobante.pending, (state) => {
        state.loadingByComprobante = true;
        state.errorByComprobante = null;
      })
      .addCase(fetchComprobanteArchivosByComprobante.fulfilled, (state, action: PayloadAction<ComprobanteArchivos[]>) => {
        state.loadingByComprobante = false;
        state.archivosByComprobante = action.payload;
      })
      .addCase(fetchComprobanteArchivosByComprobante.rejected, (state, action) => {
        state.loadingByComprobante = false;
        state.errorByComprobante = action.payload as string;
      })
      // Upload
      .addCase(uploadComprobanteArchivo.fulfilled, (state, action: PayloadAction<ComprobanteArchivos>) => {
        state.archivos.push(action.payload);
        if (state.archivosByComprobante.length > 0 &&
            state.archivosByComprobante[0].comprobante_id.id === action.payload.comprobante_id.id) {
          state.archivosByComprobante.push(action.payload);
        }
      })
      // Delete
      .addCase(deleteComprobanteArchivo.fulfilled, (state, action: PayloadAction<string>) => {
        state.archivos = state.archivos.filter(item => item.id !== action.payload);
        state.archivosByComprobante = state.archivosByComprobante.filter(item => item.id !== action.payload);
      });
  },
});

export const comprobanteArchivosReducer = comprobanteArchivosSlice.reducer;
