import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Provincia } from '../../../../slices/provinciaSlice';

interface ProvinciaFormProps {
  onSubmit: (data: Omit<Provincia, 'id_provincia'>) => void;
}

const ProvinciaForm: React.FC<ProvinciaFormProps> = ({ onSubmit }) => {
  const { departamentos } = useSelector((state: RootState) => state.departamento);
  const [formData, setFormData] = useState({
    nombre_provincia: '',
    id_departamento: ''
  });

  useEffect(() => {
    if (departamentos.length > 0) {
      setFormData(prev => ({
        ...prev,
        id_departamento: departamentos[0].id_departamento
      }));
    }
  }, [departamentos]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6 max-w-full mx-auto">
      <h2 className="text-xl font-semibold mb-6 text-gray-800 dark:text-white">Nueva Provincia</h2>
      
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Nombre de la Provincia
          </label>
          <input
            type="text"
            value={formData.nombre_provincia}
            onChange={(e) => setFormData({...formData, nombre_provincia: e.target.value})}
            className="w-full px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 
                     focus:ring-2 focus:ring-cyan-500 focus:border-transparent
                     bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100
                     transition duration-150 ease-in-out"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Departamento
          </label>
          <select
            value={formData.id_departamento}
            onChange={(e) => setFormData({...formData, id_departamento: e.target.value})}
            className="w-full px-4 py-2 rounded-md border border-gray-300 dark:border-gray-600 
                     focus:ring-2 focus:ring-cyan-500 focus:border-transparent
                     bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100
                     transition duration-150 ease-in-out"
            required
          >
            {departamentos.map(dep => (
              <option key={dep.id_departamento} value={dep.id_departamento}>
                {dep.nombre_departamento}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-8">
        <button
          type="submit"
          className="w-full px-4 py-2.5 bg-gradient-to-r from-cyan-500 to-cyan-600 
                   text-white font-medium rounded-md hover:from-cyan-600 hover:to-cyan-700
                   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500
                   transform transition duration-150 ease-in-out hover:scale-[1.02]
                   shadow-md"
        >
          Crear Provincia
        </button>
      </div>
    </form>
  );
};

export default ProvinciaForm;
