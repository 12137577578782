import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../../components/Buttons/Button';
import Modal from '../../components/Modal/Modal';
import Toast from '../../components/Toast/Toast';
import TableComponent from '../../components/Table/TableComponent';
import DescuentoPagosPage from './DescuentosPagosPage';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { addOrdenPago, deleteOrdenPago, fetchOrdenPagosByOrdenCompra, IOrdenPago, OrdenPago } from '../../slices/ordenPagoSlice';
import { RootState, AppDispatch } from '../../store/store';
import LoaderPage from '../../components/Loader/LoaderPage';
import { TbEyeDiscount } from "react-icons/tb";
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import UpdateOrdenPagoModal from './modals/UpdateOrdenPagoModal';
import { addTipoCambio, TipoCambioInput } from '../../slices/tipoCambioOrdenPagoSlice';
import { uploadComprobante } from '../../slices/comprobantePagoSlice';
import { fetchDescuentosByOrdenPago, OrdenPagoDescuento } from '../../slices/descuentoPagoSlice';
import RecursosOrdenCompraModal from './RecursosOrdenCompraModal';
import { getProveedorByOrdenCompraIdService } from '../../services/ordenCompraService';
import { fetchOrdenCompraRecursosByOrdenId } from '../../slices/ordenCompraRecursosSlice';
//import { set } from 'zod';

interface CustomError {
  message: string;
}

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5
};

const OrdenPagoPage: React.FC = () => {
  const location = useLocation();
  const { 
    ordenCompraId, 
    codigoOrden,
  } = location.state || {};
  
  // Mover esta declaración aquí arriba junto con los otros estados
  const [isRecursosModalOpen, setIsRecursosModalOpen] = useState(false);
    
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedOrdenPago, setSelectedOrdenPago] = useState<OrdenPago | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: RootState) => state.ordenPago);
  const { 
    ordenPagosByOrdenCompra, 
  } = useSelector((state: RootState) => state.ordenPago);
  
  const userId = useSelector((state: RootState) => state.user.id);
  const [moneda, setMoneda] = useState<string>('');
  const [tipoCambio, setTipoCambio] = useState(0);
  const [monto, setMonto] = useState<number | ''>('');  // Cambiar el tipo y valor inicial
  const [tipoComprobante, setTipoComprobante] = useState<string>('');
  const [tipoPago, setTipoPago] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // Función para validar que solo se ingresen números
  const handleMontoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Solo permitir números y punto decimal
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setMonto(value === '' ? '' : Number(value));
    }
  };

  // Estados para el Toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState<'success' | 'danger' | 'warning' | 'info'>('info');
    
  // Estados para el modal de descuentos
  const [isDescuentoModalOpen, setIsDescuentoModalOpen] = useState(false);
  const [tipoDescuento, setTipoDescuento] = useState<'detracciones' | 'retenciones' | null>(null);

  const [proveedorData, setProveedorData] = useState<{ razon_social: string }>({ razon_social: '' });

 // Agregar selector para recursos
 const { ordenCompraRecursosByOrdenId } = useSelector(
  (state: RootState) => state.ordenCompraRecursos
);

  // Mover el useMemo aquí, antes de los retornos anticipados
  const totales = useMemo(() => {
    const totalBruto = ordenCompraRecursosByOrdenId.reduce(
      (sum, recurso) => sum + (recurso.costo_real || 0), 
      0
    );
    return {
      bruto: totalBruto,
      conIGV: totalBruto * 1.18
    };
  }, [ordenCompraRecursosByOrdenId]);

  // Función para abrir el modal de descuentos
  const handleOpenDescuentoModal = (ordenPago: IOrdenPago, tipo: 'detracciones' | 'retenciones') => {
    const ordenPagoConvertido: OrdenPago = {
      id: ordenPago._id,
      codigo: ordenPago.codigo,
      monto_solicitado: ordenPago.monto_solicitado,
      tipo_moneda: ordenPago.tipo_moneda,
      tipo_pago: ordenPago.tipo_pago,
      estado: ordenPago.estado,
      comprobante: ordenPago.comprobante,
      fecha: ordenPago.fecha || new Date().toISOString(), // Agregar fecha
      orden_compra: ordenPago.orden_compra,
      orden_compra_id: {
        id: ordenPago.orden_compra.id || ordenPago._id,
        codigo_orden: ordenPago.orden_compra.codigo_orden
      },
      usuario_id: {
        id: ordenPago.usuario_id.id,
        nombres: ordenPago.usuario_id.nombres,
        apellidos: '',
        dni: '',
        usuario: '',
      }
    };
    setSelectedOrdenPago(ordenPagoConvertido);
    setTipoDescuento(tipo);
    setIsDescuentoModalOpen(true);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setSelectedFile(null);
      return;
    }
    setSelectedFile(files[0]);
  };

  // Función para manejar el envío de datos
  const handleAgregar = async () => {
    try {
      if (!userId) {
        setToastMessage('Error: No hay información de usuario disponible');
        setToastVariant('danger');
        setShowToast(true);
        return;
      }

      // Validar que monto no esté vacío y sea un número
      if (monto === '' || !moneda || !tipoComprobante || !tipoPago) {
        setToastMessage('Por favor complete todos los campos');
        setToastVariant('warning');
        setShowToast(true);
        return;
      }

      const nuevaOrdenPago = {
        monto_solicitado: Number(monto),
        tipo_moneda: moneda,
        tipo_pago: tipoPago,
        orden_compra_id: ordenCompraId || '',
        estado: 'PENDIENTE',
        usuario_id: userId,
        comprobante: tipoComprobante
      };

      const result = await dispatch(addOrdenPago(nuevaOrdenPago)).unwrap();
      
      // Si hay un archivo seleccionado, subirlo
      if (selectedFile) {
        await dispatch(uploadComprobante({
          ordenPagoId: result.id,
          file: selectedFile
        }));
      }

      // Si es en dólares y hay tipo de cambio, guardar el tipo de cambio y el monto en soles
      if (moneda === 'dolares' && tipoCambio > 0) {
        const montoSoles = tipoCambio * monto;
        const tipoCambioData: TipoCambioInput = {
          cambio: tipoCambio,
          monto_soles: montoSoles,
          orden_pago_id: result.id
        };
        await dispatch(addTipoCambio(tipoCambioData));
      }

      setToastMessage('Orden de pago creada exitosamente');
      setToastVariant('success');
      setShowToast(true);
      
      // Limpiar formulario
      setMonto('');
      setMoneda('');
      setTipoComprobante('');
      setTipoPago('');
      setTipoCambio(0);
      setSelectedFile(null);
      if (document.getElementById('comprobante-file') as HTMLInputElement) {
        (document.getElementById('comprobante-file') as HTMLInputElement).value = '';
      }
      
      // Recargar datos
      if (ordenCompraId) {
        dispatch(fetchOrdenPagosByOrdenCompra(ordenCompraId));
      }
    } catch (error) {
      const customError = error as CustomError;
      console.error('Error al crear orden de pago:', customError);
      setToastMessage(`Error al crear la orden de pago: ${customError.message}`);
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  useEffect(() => {
    if (ordenCompraId) {
      dispatch(fetchOrdenPagosByOrdenCompra(ordenCompraId)).then(() => {
        // Cuando se cargan los datos, actualizar el monto con el monto_total
        if (ordenPagosByOrdenCompra[0]?.monto_total) {
          setMonto(ordenPagosByOrdenCompra[0].monto_total);
        }
      });
      getProveedorByOrdenCompraIdService(ordenCompraId)
        .then((data) => {
          setProveedorData({razon_social: data.proveedor.razon_social});
        })
        .catch((error) => {
          console.error('Error al obtener proveedor:', error);
        });
      dispatch(fetchOrdenCompraRecursosByOrdenId(ordenCompraId));
    }
  }, [dispatch, ordenCompraId]);

  const [montosAPagar, setMontosAPagar] = useState<{ [key: string]: number }>({});
  const [totalDescuentos, setTotalDescuentos] = useState<{ [key: string]: number }>({});
  // Efecto para calcular los montos a pagar
  useEffect(() => {
    const calcularTotalDescuentos = async (ordenPagoId: string) => {
      try {
        const response = await dispatch(fetchDescuentosByOrdenPago(ordenPagoId)).unwrap();
        // Asegurarse de que los montos se suman correctamente usando parseFloat
        const total = response.reduce((sum: number, descuento: OrdenPagoDescuento) => {
          const montoDescuento = parseFloat(descuento.monto.toString());
          return sum + (isNaN(montoDescuento) ? 0 : montoDescuento);
        }, 0);
        return Number(total.toFixed(2)); // Redondear a 2 decimales
      } catch (error) {
        const customError = error as CustomError;
        console.error('Error al obtener descuentos:', customError);
        return 0;
      }
    };

    const calcularMontos = async () => {
      const montos: { [key: string]: number } = {};
      const totaldescuento: { [key: string]: number } = {};
      
      for (const ordenPago of ordenPagosByOrdenCompra) {
        const descuentos = await calcularTotalDescuentos(ordenPago._id);
        montos[ordenPago._id] = Number((ordenPago.monto_solicitado - descuentos).toFixed(2));
        totaldescuento[ordenPago._id] = descuentos;
      }
      
      setMontosAPagar(montos);
      setTotalDescuentos(totaldescuento);
    };

    if (ordenPagosByOrdenCompra.length > 0) {
      calcularMontos();
    }
  }, [ordenPagosByOrdenCompra, dispatch]);

  const handleEdit = (ordenPago: IOrdenPago) => {
    const ordenPagoConvertido: OrdenPago = {
      id: ordenPago._id,
      codigo: ordenPago.codigo,
      monto_solicitado: ordenPago.monto_solicitado,
      tipo_moneda: ordenPago.tipo_moneda,
      tipo_pago: ordenPago.tipo_pago,
      estado: ordenPago.estado,
      comprobante: ordenPago.comprobante || '', // Proporcionar valor por defecto
      fecha: ordenPago.fecha || new Date().toISOString(), // Agregar fecha
      orden_compra: ordenPago.orden_compra,
      orden_compra_id: {
        id: ordenPago.orden_compra.id ?? '',
        codigo_orden: ordenPago.orden_compra.codigo_orden
      },
      usuario_id: {
        id: ordenPago.usuario_id.id,
        nombres: ordenPago.usuario_id.nombres,
        apellidos: '',
        dni: '',
        usuario: '',
      }
    };
    setSelectedOrdenPago(ordenPagoConvertido);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    // Recargar datos después de una actualización exitosa
    if (ordenCompraId) {
      dispatch(fetchOrdenPagosByOrdenCompra(ordenCompraId));
    }
    setToastMessage('Orden de pago actualizada exitosamente');
    setToastVariant('success');
    setShowToast(true);
  };

  const handleDelete = (id: string) => {
    if (window.confirm('¿Está seguro de eliminar esta orden de pago?')) {
      dispatch(deleteOrdenPago(id));
    }
  };

  if (loading) return <LoaderPage />;
  if (error) return <div>Error: {error}</div>;

  const tableData = {
    filter: [true, true, true, true, true, true, true, true, true, false, false],
    headers: [
      "código",
      "fecha",
      "Monto solicitado",
      "Total Descuentos",
      "Monto a pagar",
      "moneda",
      "tipo pago",
      "orden compra",
      "estado",
      "Generado Por:",
      "descuentos",
      "opciones"
    ],
    rows: ordenPagosByOrdenCompra.map(ordenPago => ({
      código: ordenPago.codigo,
      fecha: new Date(ordenPago.fecha).toLocaleString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/[/]/g, '-'),
      "Monto solicitado": ordenPago.monto_solicitado,
      "Monto a pagar": montosAPagar[ordenPago._id] ,
      "Total Descuentos": totalDescuentos[ordenPago._id],
      moneda: (
        <span className={`font-medium px-2 py-1 rounded-full ${
          ordenPago.tipo_moneda === 'soles' 
            ? 'bg-green-100 text-green-800'
            : 'bg-blue-100 text-blue-800'
        }`}>
          {ordenPago.tipo_moneda === 'soles' ? 'Soles' : 'Dólares'}
        </span>
      ),
      "tipo pago": ordenPago.tipo_pago,
      "orden compra": ordenPago.orden_compra.codigo_orden,
      estado: (
        <span className={`font-medium ${ordenPago.estado === 'PENDIENTE' ? 'text-red-600' : 'text-gray-700'}`}>
          {ordenPago.estado}
        </span>
      ),
      "Generado Por:": ordenPago.usuario_id.nombres,
      descuentos: (
        <div className="flex space-x-2 justify-center">
          <button
            className="text-black hover:text-blue-600 transition-colors"
            onClick={() => handleOpenDescuentoModal(ordenPago, 'detracciones')}
            title="Detracciones"
          >
            <TbEyeDiscount size={18} className="text-green-500" />  
          </button>
         
        </div>
      ),
      opciones: (
        <div className="flex space-x-2 justify-center">
          <button
            className="text-black"
            onClick={() => handleEdit(ordenPago)}
          >
            <FiEdit size={18} className="text-blue-500" />
          </button>
          <button
            className="text-black"
            onClick={() => handleDelete(ordenPago._id)} 
          >
            <FiTrash2 size={18} className="text-red-500" />
          </button>
        </div>
      )
    }))
  };

  // Agregar el cálculo de los totales antes del return
  const totalesTabla = ordenPagosByOrdenCompra.reduce((acc, ordenPago) => {
    const montoSolicitado = acc.montoSolicitado + ordenPago.monto_solicitado;
    const montoConIGV = acc.montoSolicitado + ordenPago.monto_solicitado;
    return {
      montoSolicitado: montoSolicitado,
      totalDescuentos: acc.totalDescuentos + (totalDescuentos[ordenPago._id] || 0),
      montoAPagar: acc.montoAPagar + (montosAPagar[ordenPago._id] || 0),
      montoConIGV: montoConIGV,
      deudaPagar: montoConIGV - montoSolicitado // Calcular la diferencia
    };
  }, { montoSolicitado: 0, totalDescuentos: 0, montoAPagar: 0, montoConIGV: 0, deudaPagar: 0 });

  return (
    <motion.div
      className="flex flex-col h-full"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <motion.div className="fixed top-5 right-0 z-50">
        {showToast && (
          <Toast
            message={toastMessage}
            variant={toastVariant}
            position="top-right"
            duration={3000}
            onClose={() => setShowToast(false)}
            isVisible={showToast}
            index={0}
          />
        )}
      </motion.div>

      <motion.div className="text-white pb-4 px-4 flex items-center justify-between">
        <h1 className="text-2xl font-bold">Órdenes de Pago</h1>
      </motion.div>

      <div className="bg-white/80 p-4 rounded-lg shadow-sm mb-4">
	<div className="flex flex-row gap-4 items-center">
	  <div className="flex-1 min-w-[200px]">
	  <label className="text-sm font-medium text-gray-700 mb-1">
	  Orden  Compra :
	    </label>
              <label className="text-sm font-medium text-gray-700 mb-1">
	  &nbsp; {codigoOrden || 'No disponible'}
	      </label>
	  </div>

	  <div className="flex-1 min-w-[200px]">
	    <label className="text-sm font-medium text-gray-700 mb-1">
	  Total Bruto  :
              </label>
	        <label className="text-sm font-medium text-gray-700 mb-1">
	         &nbsp; S/ {totales.bruto.toFixed(2)}
              </label>
	  </div>


    <div className="flex-1 min-w-[200px]">
      <label className="text-sm font-medium text-gray-700 mb-1">
        Total Con igv (18%)  :
      </label>
      <label className="text-sm font-bold text-green-600 mb-1 bg-green-50 pr-2 ml-3 py-1 rounded-md border border-green-200">   
        &nbsp;&nbsp;  S/ {totales.conIGV.toFixed(2)}
      </label>
    </div>


	  <div className="flex-1 min-w-[200px]">
	    <label className="text-sm font-medium text-gray-700 mb-1">
	  Proveedor :
	    </label>
               <label className="text-sm font-medium text-gray-700 mb-1">   
	        &nbsp; {proveedorData.razon_social || 'No disponible'}
	      </label>
	  </div>

            <div className="flex-1 min-w-[100px]">
	      <Button 
               text='Ver recursos' 
	       color='azul' 
	       onClick={() => setIsRecursosModalOpen(true)}
	       className="rounded w-[150px] bg-blue-500 hover:bg-blue-600 text-white"
	     />

	  </div>
	  	  
	</div>
      </div>
	  	  
      {/* Formulario */}
      <motion.div className="bg-white/80 p-4 rounded-lg shadow-sm mb-4">
        <div id="seleccionables" className="flex flex-row gap-4 items-center">
          <div className="flex-1 min-w-[150px]">
            <label htmlFor="monto" className="block text-sm font-medium text-gray-700 mb-1">
              Monto
            </label>
            <input 
              type="text" // Cambiar a type="text" para mejor control
              id="monto"
              name="monto"
              value={monto}
              onChange={handleMontoChange}
              placeholder="0.00"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            {typeof monto === 'number' && monto > totales.conIGV && (
              <p className="text-red-500 text-sm mt-1">
                El monto no debe sobrepasar el total con IGV
              </p>
            )}
          </div>

          <div className="flex-1 min-w-[150px]">
            <label htmlFor="moneda" className="block text-sm font-medium text-gray-700 mb-1">
              Moneda
            </label>
            <select 
              id="moneda" 
              name="moneda"
              value={moneda}
              onChange={(e) => setMoneda(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">--Seleccione--</option>
              <option value="soles">Soles</option>
              <option value="dolares">Dólares</option>
            </select>
          </div>

          <div className="flex-1 min-w-[150px]">
            <label htmlFor="comprobante" className="block text-sm font-medium text-gray-700 mb-1">
              Comprobante
            </label>
            <select 
              id="comprobante" 
              name="comprobante"
              value={tipoComprobante}
              onChange={(e) => setTipoComprobante(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">--Seleccione--</option>
              <option value="factura">Factura</option>
              <option value="recibo">Recibo por honorarios</option>
            </select>
          </div>

          <div className="flex-1 min-w-[150px]">
            <label htmlFor="tipo" className="block text-sm font-medium text-gray-700 mb-1">
              Tipo de Pago
            </label>
            <select 
              id="tipo" 
              name="tipo"
              value={tipoPago}
              onChange={(e) => setTipoPago(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">--Seleccione--</option>
              <option value="adelanto">Adelanto</option>
              <option value="contraentrega">Contra Entrega</option>
              <option value="credito">Crédito</option>
            </select>
          </div>
        </div>



     	  <div className="mt-5">

                  <label htmlFor="comprobante-file" className="block text-sm font-medium text-gray-700 mb-1">
	  Subir Comprobante (Factura /Boleta - no es obligatorio)
  </label>
  <div className="relative">
    <input
      type="file"
      id="comprobante-file"
      name="comprobante-file"
      onChange={handleFileChange}
      className="block w-full text-sm text-gray-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-md file:border-0
        file:text-sm file:font-semibold
        file:bg-indigo-50 file:text-indigo-700
        hover:file:bg-indigo-100
        focus:outline-none focus:ring-2 focus:ring-indigo-500
        border border-gray-300 rounded-md shadow-sm"
      accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
    />
  </div>
          </div>	  
       
	  {moneda === 'dolares' && (
  <div className="calculo-dolares mt-4">
    <label className="block text-sm font-medium text-gray-700 mb-1">
      Tipo de Cambio
    </label>
    <div className="flex space-x-4 items-center">
      <div className="flex-1">
        <input 
          type="number"
          id="tipoCambio"
              name="tipoCambio"
	      value={tipoCambio}
	     onChange={(e) => setTipoCambio(Number(e.target.value))}
          min="0"
          step="0.01"
          placeholder="Tipo de cambio"
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
      <div className="flex-1">
        <input 
          type="number"
          id="resultadoSoles"
          name="resultadoSoles"
          disabled
          value={tipoCambio * (typeof monto === 'number' ? monto : 0)} // Aquí va el cálculo
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50"
          placeholder="Resultado en soles"
        />
      </div>
     </div>
  </div>
)}

	  
        <div className="mt-5 flex justify-center">
          <Button 
            text="Agregar"
            color='azul' 
            onClick={handleAgregar}
            className="rounded w-[400px] bg-blue-500 hover:bg-blue-600 text-white"
          />
        </div>
      </motion.div>

      {/* Tabla o mensaje de sin datos */}
      <motion.div
        className="flex flex-1 overflow-hidden rounded-xl"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4 }}
      >
        <main className="w-full flex flex-col flex-grow p-4 bg-white/80 overflow-hidden">
          {ordenPagosByOrdenCompra.length === 0 ? (
            <div className="flex justify-center items-center p-8 bg-white/80 rounded-lg shadow-sm">
              <p className="text-gray-500 text-lg">Sin órdenes de Pago</p>
            </div>
          ) : (
            <motion.div
              className="flex-grow border rounded-lg overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
            >
              <div className="h-full overflow-auto">
                <TableComponent tableData={tableData} />
                <div className="mt-4 p-4 bg-gray-50 border-t border-gray-200">
                  <div className="grid grid-cols-3 gap-8">
                    {/* Primera columna */}
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700">Total Monto Solicitado:</span>
                        <input
                          type="text"
                          value={`S/ ${totalesTabla.montoSolicitado.toFixed(2)}`}
                          className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-right font-bold text-gray-700 w-[150px]"
                          readOnly
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700">Total orden pago ( con igv):</span>
                        <input
                          type="text"
                          value={`S/ ${totalesTabla.montoConIGV.toFixed(2)}`}
                          className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-right font-bold text-blue-600 w-[150px]"
                          readOnly
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700">Deuda a pagar:</span>
                        <input
                          type="text"
                          value={`S/ ${totalesTabla.deudaPagar.toFixed(2)}`}
                          className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-right font-bold text-red-600 w-[150px]"
                          readOnly
                        />
                      </div>
                    </div>

                    {/* Segunda columna */}
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center justify-between">
                      <span className="text-sm font-medium text-gray-700 pl-20">Total Descuentos:</span>
                      <input
                        type="text"
                        value={`S/ ${totalesTabla.totalDescuentos.toFixed(2)}`}
                        className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-right font-bold text-blue-600 w-[150px]"
                        readOnly
                      />
                      </div>
                    </div>

                    {/* Tercera columna */}
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center justify-between">
                      <span className="text-sm font-medium text-gray-700 pl-20">Total Monto a Pagar:</span>
                      <input
                        type="text"
                        value={`S/ ${totalesTabla.montoAPagar.toFixed(2)}`}
                        className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-right font-bold text-green-600 w-[150px]"
                        readOnly
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </main>
      </motion.div>
      {/* Modal para Descuentos */}
      <AnimatePresence>
        {isDescuentoModalOpen && selectedOrdenPago && (
          <Modal 
            title="Descuentos"  
            isOpen={isDescuentoModalOpen}
            onClose={() => setIsDescuentoModalOpen(false)}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
            >
              <DescuentoPagosPage 
                ordenPagoId={selectedOrdenPago.id}
                tipoDescuento={tipoDescuento}
                handleClose={() => setIsDescuentoModalOpen(false)} 
                montoSolicitado={selectedOrdenPago.monto_solicitado}
                tipoMoneda={selectedOrdenPago.tipo_moneda}
                tipoComprobante={selectedOrdenPago.comprobante}
              />
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>
      {/* Add UpdateOrdenPagoModal */}
      {selectedOrdenPago && isUpdateModalOpen && (
        <UpdateOrdenPagoModal
          isOpen={isUpdateModalOpen}
          onClose={() => setIsUpdateModalOpen(false)}
          ordenPago={selectedOrdenPago}
          onSuccess={handleUpdateSuccess}
        />
      )}
      {ordenCompraId && (
        <RecursosOrdenCompraModal
          isOpen={isRecursosModalOpen}
          onClose={() => setIsRecursosModalOpen(false)}
          ordenCompraId={ordenCompraId}
        />
      )}
    </motion.div>
  );
};

export default OrdenPagoPage;