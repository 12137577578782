import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiTrash2, FiUpload, FiFile } from 'react-icons/fi';
import { uploadFile, deleteFile, fetchFilesByReferencia } from '../../slices/archivoSustentoSlice';
import { AppDispatch } from '../../store/store';
import Modal from '../Modal/Modal';
import ModalAlert from '../Modal/ModalAlert';
import LoaderOverlay from '../Loader/LoaderOverlay';

interface FileUploadProps {
  tipo: string;
  referencia_id: string;
  maxFileSize?: number; // en MB
  accept?: string;
  multiple?: boolean;
  onFilesChange?: (files: ArchivoPreview[]) => void;
}

interface ArchivoPreview {
  id: string;
  url: string;
  name: string;
  size: string;
  type: 'image' | 'document';
  preview?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  tipo,
  referencia_id,
  maxFileSize = 10,
  accept = '.pdf,.doc,.docx,.png,.jpg,.jpeg,.gif',
  multiple = true,
  onFilesChange
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isDragging, setIsDragging] = useState(false);
  const [archivos, setArchivos] = useState<ArchivoPreview[]>([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [archivoToDelete, setArchivoToDelete] = useState<ArchivoPreview | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const compressImage = (file: File): Promise<File> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (!ctx) {
            resolve(file);
            return;
          }

          // Calcular las nuevas dimensiones manteniendo la proporción
          let width = img.width;
          let height = img.height;
          const maxDimension = 1920; // Máxima dimensión permitida
          
          if (width > height && width > maxDimension) {
            height = Math.round((height * maxDimension) / width);
            width = maxDimension;
          } else if (height > maxDimension) {
            width = Math.round((width * maxDimension) / height);
            height = maxDimension;
          }

          canvas.width = width;
          canvas.height = height;

          // Aplicar suavizado para mejor calidad
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          ctx.drawImage(img, 0, 0, width, height);

          // Convertir a WebP con alta calidad
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                resolve(file);
                return;
              }
              const newFileName = file.name.replace(/\.[^.]+$/, ".webp");
              resolve(
                new File([blob], newFileName, {
                  type: "image/webp",
                  lastModified: Date.now(),
                })
              );
            },
            "image/webp",
            0.6 // Calidad muy alta para mantener la fidelidad visual
          );
        };
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  // Obtener archivos existentes al cargar el componente
  useEffect(() => {
    const fetchArchivos = async () => {
      if (referencia_id) {
        setIsLoading(true);
        try {
          const response = await dispatch(fetchFilesByReferencia({
            referencia_id,
            tipo
          })).unwrap();

          const archivosFormateados: ArchivoPreview[] = response.map((archivo: any) => ({
            id: archivo.id,
            url: archivo.url,
            name: archivo.fileName || 'Archivo',
            size: formatFileSize(0),
            type: archivo.url.match(/\.(jpg|jpeg|png|gif)$/i) ? 'image' as const : 'document' as const
          }));

          setArchivos(archivosFormateados);
          // Solo llamar a onFilesChange si hay cambios reales
          if (JSON.stringify(archivosFormateados) !== JSON.stringify(archivos)) {
            onFilesChange?.(archivosFormateados);
          }
        } catch (error) {
          console.error('Error al obtener archivos:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchArchivos();
  }, [dispatch, referencia_id, tipo]); // Removemos onFilesChange de las dependencias

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const newFiles: ArchivoPreview[] = Array.from(files).map(file => ({
        id: `temp-${Date.now()}-${Math.random()}`,
        url: URL.createObjectURL(file),
        name: file.name,
        size: formatFileSize(file.size),
        type: file.type.startsWith('image/') ? 'image' as const : 'document' as const,
        preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : undefined
      }));

      const updatedFiles = multiple ? [...archivos, ...newFiles] : newFiles;
      setArchivos(updatedFiles);
      onFilesChange?.(updatedFiles);

      // Subir archivos al servidor
      for (const file of files) {
        if (file.size <= maxFileSize * 1024 * 1024) {
          try {
            let fileToUpload = file;
            // Comprimir solo si es una imagen
            if (file.type.startsWith('image/')) {
              fileToUpload = await compressImage(file);
            }

            await dispatch(uploadFile({
              tipo,
              referencia_id,
              file: fileToUpload
            })).unwrap();

            // Después de subir exitosamente, actualizar los archivos
            const response = await dispatch(fetchFilesByReferencia({
              referencia_id,
              tipo
            })).unwrap();
            
            const archivosFormateados: ArchivoPreview[] = response.map((archivo: any) => ({
              id: archivo.id,
              url: archivo.url,
              name: archivo.fileName || 'Archivo',
              size: formatFileSize(0),
              type: archivo.url.match(/\.(jpg|jpeg|png|gif|webp)$/i) ? 'image' as const : 'document' as const
            }));
            
            setArchivos(archivosFormateados);
            onFilesChange?.(archivosFormateados);
          } catch (error) {
            console.error('Error al subir el archivo:', error);
          }
        }
      }
    }
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      const newFiles: ArchivoPreview[] = files.map(file => ({
        id: `temp-${Date.now()}-${Math.random()}`,
        url: URL.createObjectURL(file),
        name: file.name,
        size: formatFileSize(file.size),
        type: file.type.startsWith('image/') ? 'image' as const : 'document' as const,
        preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : undefined
      }));

      const updatedFiles = multiple ? [...archivos, ...newFiles] : newFiles;
      setArchivos(updatedFiles);
      onFilesChange?.(updatedFiles);

      // Subir archivos al servidor
      for (const file of files) {
        if (file.size <= maxFileSize * 1024 * 1024) {
          try {
            let fileToUpload = file;
            // Comprimir solo si es una imagen
            if (file.type.startsWith('image/')) {
              fileToUpload = await compressImage(file);
            }

            await dispatch(uploadFile({
              tipo,
              referencia_id,
              file: fileToUpload
            })).unwrap();
          } catch (error) {
            console.error('Error al subir el archivo:', error);
          }
        }
      }
    }
  };

  const handleDeleteFile = async (archivo: ArchivoPreview) => {
    setArchivoToDelete(archivo);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (archivoToDelete) {
      try {
        if (archivoToDelete.id.startsWith('temp-')) {
          const updatedFiles = archivos.filter(a => a.id !== archivoToDelete.id);
          setArchivos(updatedFiles);
          onFilesChange?.(updatedFiles);
        } else {
          await dispatch(deleteFile(archivoToDelete.id)).unwrap();
          const updatedFiles = archivos.filter(a => a.id !== archivoToDelete.id);
          setArchivos(updatedFiles);
          onFilesChange?.(updatedFiles);
        }
      } catch (error) {
        console.error('Error al eliminar el archivo:', error);
      } finally {
        setShowDeleteModal(false);
        setArchivoToDelete(null);
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setArchivoToDelete(null);
  };

  return (
    <div className="w-full">
      {isLoading && <LoaderOverlay message="Cargando archivos..." />}
      <div
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={`relative overflow-hidden rounded-3xl p-8 text-center cursor-pointer transition-all duration-500 ease-in-out
                  ${isDragging 
                    ? 'bg-gradient-to-br from-blue-500/30 via-purple-500/20 to-pink-500/30 border-2 border-blue-500 shadow-2xl scale-[1.02]' 
                    : 'bg-gradient-to-br from-gray-200/75 via-white/75 to-gray-300/75 border-2 border-gray-200 hover:border-blue-400 hover:shadow-2xl hover:scale-[1.01]'}`}
      >
        <div className={`absolute inset-0 bg-gradient-to-br from-blue-500/10 via-purple-500/10 to-pink-500/10 transition-all duration-500 ${isDragging ? 'opacity-100' : 'opacity-0'}`} />
        
        <input
          type="file"
          onChange={handleFileSelect}
          accept={accept}
          className="hidden"
          id={`file-upload-${tipo}`}
          multiple={multiple}
        />
        <label htmlFor={`file-upload-${tipo}`} className="cursor-pointer relative z-10">
          <div className="space-y-6">
            {archivos.length > 0 ? (
              <div className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {archivos.map((archivo) => (
                    <div 
                      key={archivo.id} 
                      className="group relative bg-white/75 p-5 rounded-2xl shadow-md hover:shadow-2xl transition-all duration-500 transform hover:-translate-y-1 hover:bg-gradient-to-br hover:from-white hover:to-gray-50 border border-gray-100"
                    >
                      <div className="absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 transition-all duration-300 transform scale-90 group-hover:scale-100">
                        <button
                          type="button"
                          onClick={() => handleDeleteFile(archivo)}
                          className="p-2.5 bg-red-500 text-white rounded-xl hover:bg-red-600 transition-colors duration-300 shadow-lg hover:shadow-xl"
                        >
                          <FiTrash2 className="w-4 h-4" />
                        </button>
                      </div>
                      <div className="flex-1 mb-3">
                        <p className="text-sm font-semibold text-gray-800 truncate group-hover:text-blue-600 transition-colors duration-300">{archivo.name}</p>
                        <p className="text-xs text-gray-500 mt-1">{archivo.size}</p>
                      </div>
                      <div 
                        className="cursor-pointer overflow-hidden rounded-xl group-hover:ring-2 group-hover:ring-blue-400 transition-all duration-300"
                        onClick={() => {
                          setSelectedImage(archivo.url);
                          setShowPreviewModal(true);
                        }}
                      >
                        {archivo.type === 'image' ? (
                          <img 
                            src={archivo.preview || archivo.url} 
                            alt={archivo.name}
                            className="w-full h-32 object-cover transform group-hover:scale-105 transition-transform duration-500"
                          />
                        ) : (
                          <div className="w-full h-32 bg-gradient-to-br from-gray-50 to-gray-100 rounded-xl flex items-center justify-center group-hover:from-blue-50 group-hover:to-purple-50 transition-all duration-300">
                            <FiFile className="h-12 w-12 text-gray-400 group-hover:text-blue-500 transition-colors duration-300" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="py-12">
                <div className="flex items-center justify-center h-full mb-6">
                  <div className="relative mx-auto w-28 h-28 filter drop-shadow-[0_0_15px_rgba(150,0,168,0.5)]">
                    <div className={`absolute inset-0 bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 rounded-full opacity-20 ${isDragging ? 'animate-ping' : 'animate-pulse'}`} />
                    <div className={`absolute inset-0 bg-gradient-to-br from-blue-400 via-purple-400 to-pink-400 rounded-full opacity-20 ${isDragging ? 'animate-pulse' : ''}`} />
                    <FiUpload className="mt-5 w-16 h-16 text-gray-50 mx-auto relative z-10 transform transition-transform duration-500 hover:scale-110" />
                  </div>
                </div>
                <div className="text-xl font-medium text-gray-900 mb-3">
                  {isDragging ? (
                    <p className="text-blue-500 animate-bounce">¡Suelta los archivos aquí!</p>
                  ) : (
                    <p>
                      Arrastra y suelta archivos aquí, o{' '}
                      <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 font-semibold hover:from-purple-500 hover:to-pink-500 transition-all duration-300 cursor-pointer">
                        selecciona archivos
                      </span>
                    </p>
                  )}
                </div>
                <p className="text-sm text-gray-600">
                  Máximo {maxFileSize}MB por archivo
                </p>
                <p className="text-xs text-gray-500 mt-2">
                  Formatos permitidos: {accept}
                </p>
              </div>
            )}
          </div>
        </label>
      </div>

      {/* Modal de previsualización */}
      {showPreviewModal && selectedImage && (
        <Modal
          title="Previsualización de Archivo"
          isOpen={showPreviewModal}
          onClose={() => {
            setShowPreviewModal(false);
            setSelectedImage(null);
          }}
        >
          <div className="flex justify-center items-center p-4 bg-gradient-to-br from-gray-50 to-white rounded-xl">
            {selectedImage.match(/\.(jpg|jpeg|png|gif)$/i) ? (
              <img
                src={selectedImage}
                alt="Previsualización"
                className="max-w-full max-h-[80vh] rounded-lg shadow-2xl"
              />
            ) : (
              <iframe
                src={selectedImage}
                className="w-full h-[80vh] rounded-lg shadow-2xl"
                title="Documento"
              />
            )}
          </div>
        </Modal>
      )}

      {/* Modal de confirmación de eliminación */}
      <ModalAlert
        isOpen={showDeleteModal}
        title="Eliminar Archivo"
        message="¿Está seguro de eliminar este archivo?"
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        variant="red"
      />
    </div>
  );
};

export default FileUpload; 