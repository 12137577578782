import React, { useState } from 'react';
import Button from '../../components/Buttons/Button';
import { consultarRucService } from '../../services/proveedorService';

export type EmpresaFormData = {
  nombre_comercial: string;
  razon_social: string;
  descripcion?: string;
  estado: string;
  regimen_fiscal: string;
  ruc: string;
  color?: string;
  imagenes?: File;
};

interface EmpresaFormProps {
  initialValues?: Partial<Omit<EmpresaFormData, 'imagenes'> & { imagenes?: string }>;
  onSubmit: (data: EmpresaFormData) => void;
}

const EmpresaForm: React.FC<EmpresaFormProps> = ({ initialValues, onSubmit }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [formData, setFormData] = useState<Omit<EmpresaFormData, 'imagenes'>>({
    nombre_comercial: initialValues?.nombre_comercial || '',
    razon_social: initialValues?.razon_social || '',
    estado: initialValues?.estado || '',
    regimen_fiscal: initialValues?.regimen_fiscal || '',
    ruc: initialValues?.ruc || '',
    descripcion: initialValues?.descripcion || '',
    color: initialValues?.color || '#2C5282',
  });
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      
      // Crear preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      
      console.log('Archivo seleccionado:', {
        nombre: file.name,
        tipo: file.type,
        tamaño: `${(file.size / 1024).toFixed(2)} KB`
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    const submitData: EmpresaFormData = {
      ...formData,
      imagenes: selectedFile,
    };
    
    console.log('Datos a enviar:', {
      ...submitData,
      imagenes: selectedFile ? {
        nombre: selectedFile.name,
        tipo: selectedFile.type,
        tamaño: `${(selectedFile.size / 1024).toFixed(2)} KB`
      } : null
    });
    
    onSubmit(submitData);
  };

  const handleRucSearch = async () => {
    if (!formData.ruc || formData.ruc.length !== 11) {
      return;
    }

    try {
      setIsSearching(true);
      const result = await consultarRucService(formData.ruc);
      setFormData(prev => ({
        ...prev,
        razon_social: result.razonSocial,
        nombre_comercial: result.razonSocial,
      }));
    } catch (error) {
      console.error('Error al consultar RUC:', error);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="mx-auto max-w-lg bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4 text-center">Datos de la Empresa</h2>
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 gap-6"
      >
        {/* Sección RUC */}
        <div>
          <label className="block text-sm font-semibold mb-1" htmlFor="ruc">
            RUC:
          </label>
          <div className="flex gap-2 mt-2">
            <input
              id="ruc"
              name="ruc"
              value={formData.ruc}
              onChange={handleChange}
              className="border p-2 w-full rounded"
              maxLength={11}
            />
            <div
              onClick={handleRucSearch}
              className={`${
                isSearching ? "cursor-not-allowed bg-gray-400" : "cursor-pointer bg-blue-500"
              } rounded-xl text-white w-auto px-4 py-2 text-sm font-medium`}
            >
              {isSearching ? "Buscando..." : "Buscar"}
            </div>
          </div>
        </div>

        {/* Sección Nombre Comercial y Razón Social en grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-semibold mb-1" htmlFor="nombre_comercial">
              Nombre Comercial:
            </label>
            <input
              id="nombre_comercial"
              name="nombre_comercial"
              value={formData.nombre_comercial}
              onChange={handleChange}
              className="border p-2 w-full rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1" htmlFor="razon_social">
              Razón Social:
            </label>
            <input
              id="razon_social"
              name="razon_social"
              value={formData.razon_social}
              onChange={handleChange}
              className="border p-2 w-full rounded"
            />
          </div>
        </div>

        {/* Sección Estado, Régimen Fiscal, Color y Descripción */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-semibold mb-1" htmlFor="estado">
              Estado:
            </label>
            <input
              id="estado"
              name="estado"
              value={formData.estado}
              onChange={handleChange}
              className="border p-2 w-full rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1" htmlFor="regimen_fiscal">
              Régimen Fiscal:
            </label>
            <input
              id="regimen_fiscal"
              name="regimen_fiscal"
              value={formData.regimen_fiscal}
              onChange={handleChange}
              className="border p-2 w-full rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1" htmlFor="color">
              Color:
            </label>
            <input
              id="color"
              name="color"
              type="color"
              value={formData.color}
              onChange={handleChange}
              className="border p-2 h-10 w-full rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1" htmlFor="descripcion">
              Descripción:
            </label>
            <textarea
              id="descripcion"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleChange}
              className="border p-2 w-full rounded"
            />
          </div>
        </div>

        {/* Sección Imagen */}
        <div className="mt-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="imagenes">
            Imagen:
          </label>
          <div className="space-y-2">
            <input
              id="imagenes"
              name="imagenes"
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              className="border p-2 w-full rounded"
            />
            {selectedFile && (
              <div className="text-sm text-gray-600">
                Archivo seleccionado: {selectedFile.name} ({(selectedFile.size / 1024).toFixed(2)} KB)
              </div>
            )}
            <div className="mt-2 flex space-x-4">
              {previewUrl && (
                <div>
                  <p className="text-sm font-semibold mb-1">Nueva imagen:</p>
                  <img 
                    src={previewUrl} 
                    alt="Vista previa" 
                    className="w-32 h-32 object-cover rounded border"
                  />
                </div>
              )}
              {initialValues?.imagenes ? (
                <div>
                  <p className="text-sm font-semibold mb-1">Imagen actual:</p>
                  <img
                    src={initialValues.imagenes}
                    alt="Imagen actual"
                    className="w-auto max-h-32 object-cover rounded border"
                  />
                </div>
              ) : (
                <p className="text-xs text-gray-500 italic">No hay imagen actual</p>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <Button text={initialValues ? 'Actualizar Empresa' : 'Crear Empresa'} color="verde" />
        </div>
      </form>
    </div>
  );
};

export default EmpresaForm;
