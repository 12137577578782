import React, { useState } from 'react';
import FiltroFechas from './FiltroFechas';


interface TransferFilterProps {
  tipoFiltro: 'TODOS' | 'COMPRAS' | 'RECEPCIONES' | 'PRESTAMOS' | 'SALIDA';
  onChange: (tipo: 'TODOS' | 'COMPRAS' | 'RECEPCIONES' | 'PRESTAMOS' | 'SALIDA', startDate: Date | null, endDate: Date | null) => void;
}

export const TransferFilter: React.FC<TransferFilterProps> = ({ tipoFiltro, onChange }) => {
  const [selectedTipo, setSelectedTipo] = useState(tipoFiltro);

  const handleTipoChange = (tipo: 'TODOS' | 'COMPRAS' | 'RECEPCIONES' | 'PRESTAMOS' | 'SALIDA') => {
    setSelectedTipo(tipo);
    onChange(tipo, null, null); 
  };

  return (
    <div className=" mb-4 flex justify-end space-x-4">
      <div className= "mb-4 flex justify-end space-x-4">
      <select 
        value={selectedTipo}
        onChange={(e) => handleTipoChange(e.target.value as 'TODOS' | 'COMPRAS' | 'RECEPCIONES' | 'PRESTAMOS' | 'SALIDA')}
        className="p-2 border rounded text-sm"
      >
        <option value="TODOS">Todos los movimientos</option>
        <option value="COMPRAS">Compras</option>
        <option value="RECEPCIONES">Recepciones</option>
        <option value="PRESTAMOS">Préstamos</option>
        <option value="SALIDA">Transferencias</option>
      </select>
      </div>
    
      <FiltroFechas onChange={(start, end) => onChange(selectedTipo, start, end)} /> 
    </div>
  );
};
