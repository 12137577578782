import React, { useState, useEffect } from 'react';
import type { PrefijoFormData } from '../../slices/prefijoSlice';

interface FormComponentProps {
  initialValues?: PrefijoFormData;  // Ya está correctamente tipado
  onSubmit: (data: PrefijoFormData) => void;
}

const PrefijoForm: React.FC<FormComponentProps> = ({ initialValues, onSubmit }) => {
  const [formData, setFormData] = useState<PrefijoFormData>({
    nombre_tabla: '',
    prefijo: '',
    cantidad_caracteres: 0
  });
  
  const [errors, setErrors] = useState({
    nombre_tabla: '',
    prefijo: '',
    cantidad_caracteres: ''
  });

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    }
  }, [initialValues]);

  const validateForm = (): boolean => {
    const newErrors = {
      nombre_tabla: '',
      prefijo: '',
      cantidad_caracteres: ''
    };

    if (!formData.nombre_tabla.trim() && !initialValues) {
      newErrors.nombre_tabla = 'El nombre de la tabla es requerido';
    }
    if (!formData.prefijo.trim()) {
      newErrors.prefijo = 'El prefijo es requerido';
    }
    if (formData.cantidad_caracteres <= 0) {
      newErrors.cantidad_caracteres = 'La cantidad de caracteres debe ser mayor a 0';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== '');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'cantidad_caracteres' ? parseInt(value) || 0 : value
    }));
    
    if (errors[name as keyof typeof errors]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto"
    >
      <div className="mb-4">
        <label htmlFor="nombre_tabla" className="block text-gray-700 text-sm font-bold mb-2">
          Nombre de Tabla:
        </label>
        <input
          id="nombre_tabla"
          name="nombre_tabla"
          type="text"
          placeholder="Nombre de la tabla"
          value={formData.nombre_tabla}
          onChange={handleChange}
          readOnly={!!initialValues}
          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
            initialValues ? 'bg-gray-100' : ''
          }`}
        />
        {errors.nombre_tabla && !initialValues && (
          <p className="text-red-500 text-xs italic mt-1">{errors.nombre_tabla}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="prefijo" className="block text-gray-700 text-sm font-bold mb-2">
          Prefijo:
        </label>
        <input
          id="prefijo"
          name="prefijo"
          type="text"
          placeholder="Prefijo"
          value={formData.prefijo}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        {errors.prefijo && (
          <p className="text-red-500 text-xs italic mt-1">{errors.prefijo}</p>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="cantidad_caracteres" className="block text-gray-700 text-sm font-bold mb-2">
          Cantidad de Caracteres:
        </label>
        <input
          id="cantidad_caracteres"
          name="cantidad_caracteres"
          type="number"
          min="1"
          value={formData.cantidad_caracteres}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        {errors.cantidad_caracteres && (
          <p className="text-red-500 text-xs italic mt-1">{errors.cantidad_caracteres}</p>
        )}
      </div>

      <div className="flex items-center justify-center mt-6">
        <button
          type="submit"
          className="w-auto px-6 py-2 text-sm font-medium bg-green-500 text-white rounded-lg focus:outline-none"
        >
          {initialValues ? 'Actualizar Prefijo' : 'Crear Prefijo'}
        </button>
      </div>
    </form>
  );
};

export default PrefijoForm;
