import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { getFormulario, addRespuesta, updateRespuesta } from '../../slices/formularioSlice';
import Card from '../../components/Card/Card';
import { Campo, RespuestaFormulario } from '../../slices/formularioSlice';

interface FormData {
  [key: string]: string | number;
}

const ResponderFormulario: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { selectedFormulario, loading, error, respuestas } = useSelector((state: RootState) => state.formulario);
  const [formData, setFormData] = useState<FormData>({});
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [respuestaExistente, setRespuestaExistente] = useState<RespuestaFormulario | null>(null);
  const userId = useSelector((state: RootState) => state.user.id);

  useEffect(() => {
    if (id) {
      dispatch(getFormulario(id));
      // Buscar si existe una respuesta previa para este formulario y usuario
      const respuesta = respuestas.find(
        resp => resp.formularioId === id && resp.usuarioId === userId
      );
      if (respuesta) {
        setRespuestaExistente(respuesta);
        try {
          const datos = JSON.parse(respuesta.datosJson);
          setFormData(datos);
        } catch (error) {
          console.error('Error al parsear datos JSON:', error);
        }
      }
    }
  }, [dispatch, id, userId, respuestas]);

  const handleInputChange = (campo: Campo, value: string | number) => {
    setFormData((prev) => ({
      ...prev,
      [campo.nombre]: value,
    }));
  };

  const validateForm = (): boolean => {
    if (!selectedFormulario) return false;

    for (const seccion of selectedFormulario.secciones) {
      for (const campo of seccion.campos) {
        if (campo.requerido && !formData[campo.nombre]) {
          setSubmitError(`El campo "${campo.etiqueta}" es requerido`);
          return false;
        }
      }
    }
    return true;
  };

  const evaluarCondicion = (condicion: string, formData: FormData): boolean => {
    if (!condicion || condicion.trim() === '') return true;

    try {
      // Limpiar la condición de caracteres especiales y espacios innecesarios
      const condicionLimpia = condicion
        .replace(/[^\w\s<>===!+()\[\]'"]/g, '') // Permitir corchetes y comillas para formData
        .trim();

      // Validar que la condición no esté vacía después de limpiarla
      if (!condicionLimpia) return true;

      // Crear una función que evalúe la condición usando los datos del formulario
      const evaluar = new Function('formData', `
        try {
          const ${Object.keys(formData).map(key => `${key} = formData['${key}']`).join(',\n')};
          return ${condicionLimpia.replace(/\\"/g, '"')};
        } catch (e) {
          console.error('Error al evaluar condición:', e);
          return false;
        }
      `);

      return evaluar(formData);
    } catch (error) {
      console.error('Error al procesar condición:', error);
      return false;
    }
  };

  const evaluarCondicionSeccion = (seccion: any, formData: FormData): boolean => {
    if (!seccion?.condicionalMostrar || seccion.condicionalMostrar.trim() === '') return true;

    try {
      // Limpiar la condición de caracteres especiales y espacios innecesarios
      const condicionLimpia = seccion.condicionalMostrar
        .replace(/[^\w\s<>===!+()\[\]'"]/g, '') // Permitir corchetes y comillas para formData
        .trim();

      // Validar que la condición no esté vacía después de limpiarla
      if (!condicionLimpia) return true;

      // Crear una función que evalúe la condición usando los datos del formulario
      const evaluar = new Function('formData', `
        try {
          return ${condicionLimpia};
        } catch (e) {
          console.error('Error al evaluar condición de sección:', e);
          return false;
        }
      `);

      return evaluar(formData);
    } catch (error) {
      console.error('Error al procesar condición de sección:', error);
      return false;
    }
  };

  const renderCampo = (campo: Campo) => {
    if (campo.condicionalMostrar && !evaluarCondicion(campo.condicionalMostrar, formData)) {
      return null;
    }

    const props = campo.propiedadesAdicionales
      ? JSON.parse(campo.propiedadesAdicionales)
      : null;

    switch (campo.tipo) {
      case 'texto':
        return (
          <div className="w-full group">
            <label className="block text-sm font-medium text-gray-700 mb-2 group-hover:text-blue-600 transition-colors duration-200">
              {campo.etiqueta}
              {campo.requerido && <span className="text-red-500 ml-1">*</span>}
            </label>
            <input
              type="text"
              className={`w-full px-4 py-3 rounded-xl border-2 ${
                campo.requerido && !formData[campo.nombre]
                  ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-200 focus:ring-blue-500 focus:border-blue-500'
              } shadow-sm transition-all duration-200 focus:shadow-md group-hover:border-blue-300`}
              placeholder={campo.placeholder}
              value={formData[campo.nombre] || ''}
              onChange={(e) => handleInputChange(campo, e.target.value)}
            />
            {campo.requerido && !formData[campo.nombre] && (
              <p className="mt-2 text-sm text-red-500 flex items-center">
                <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                Este campo es requerido
              </p>
            )}
          </div>
        );

      case 'fecha':
        return (
          <div className="w-full group">
            <label className="block text-sm font-medium text-gray-700 mb-2 group-hover:text-blue-600 transition-colors duration-200">
              {campo.etiqueta}
              {campo.requerido && <span className="text-red-500 ml-1">*</span>}
            </label>
            <input
              type="date"
              className={`w-full px-4 py-3 rounded-xl border-2 ${
                campo.requerido && !formData[campo.nombre]
                  ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-200 focus:ring-blue-500 focus:border-blue-500'
              } shadow-sm transition-all duration-200 focus:shadow-md group-hover:border-blue-300`}
              value={formData[campo.nombre] || ''}
              onChange={(e) => handleInputChange(campo, e.target.value)}
            />
            {campo.requerido && !formData[campo.nombre] && (
              <p className="mt-2 text-sm text-red-500 flex items-center">
                <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                Este campo es requerido
              </p>
            )}
          </div>
        );

      case 'rango':
        return (
          <div className="w-full group">
            <label className="block text-sm font-medium text-gray-700 mb-2 group-hover:text-blue-600 transition-colors duration-200">
              {campo.etiqueta}
              {campo.requerido && <span className="text-red-500 ml-1">*</span>}
            </label>
            <input
              type="range"
              className={`w-full h-3 bg-gray-200 rounded-full appearance-none cursor-pointer ${
                campo.requerido && !formData[campo.nombre]
                  ? 'accent-red-500'
                  : 'accent-blue-500'
              } transition-all duration-200`}
              min={props?.min || 0}
              max={props?.max || 100}
              step={props?.paso || 1}
              value={formData[campo.nombre] ? Number(formData[campo.nombre]) : 0}
              onChange={(e) => handleInputChange(campo, Number(e.target.value))}
            />
            <div className="flex justify-between text-sm text-gray-600 mt-2">
              {Object.entries(props?.etiquetas || {}).map(([value, label]) => (
                <span key={value} className="group-hover:text-blue-600 transition-colors duration-200">{label as string}</span>
              ))}
            </div>
            {campo.requerido && !formData[campo.nombre] && (
              <p className="mt-2 text-sm text-red-500 flex items-center">
                <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                Este campo es requerido
              </p>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitError(null);
    setIsSubmitting(true);

    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    if (!id) {
      setSubmitError('ID del formulario no encontrado');
      setIsSubmitting(false);
      return;
    }

    try {
      if (respuestaExistente) {
        // Actualizar respuesta existente
        await dispatch(updateRespuesta({
          updateRespuestaId: respuestaExistente.id,
          datosJson: JSON.stringify(formData),
        })).unwrap();
      } else {
        // Crear nueva respuesta
        await dispatch(addRespuesta({
          formularioId: id,
          usuarioId: userId,
          datosJson: JSON.stringify(formData),
        })).unwrap();
      }
      
      navigate('/dashboard/formulariosToDo');
    } catch (error) {
      setSubmitError(error instanceof Error ? error.message : 'Error al guardar las respuestas');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
        <p className="text-red-600">Error: {error}</p>
      </div>
    );
  }

  if (!selectedFormulario) {
    return (
      <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
        <p className="text-yellow-600">No se encontró el formulario</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gradient-to-br from-gray-50 to-blue-50 min-h-screen">
      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8 border border-gray-100 transform hover:scale-[1.01] transition-transform duration-300">
        <h1 className="text-4xl font-bold text-gray-900 mb-3 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          {selectedFormulario.titulo}
        </h1>
        <p className="text-gray-600 text-lg">
          {selectedFormulario.descripcion}
        </p>
      </div>

      {submitError && (
        <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 rounded-lg shadow-md">
          <p className="text-red-600 flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
            {submitError}
          </p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-8">
        {selectedFormulario.secciones
          .filter(seccion => evaluarCondicionSeccion(seccion, formData))
          .map((seccion, index) => (
            <Card key={seccion.id} className="p-8 bg-white border border-gray-100 shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                  <span className="text-blue-600 font-bold text-xl">{index + 1}</span>
                </div>
                <h2 className="text-2xl font-semibold text-gray-900">
                  {seccion.titulo}
                </h2>
              </div>
              {seccion.descripcion && (
                <p className="text-gray-600 mb-6 pl-16 text-lg">
                  {seccion.descripcion}
                </p>
              )}
              <div className="border-t border-gray-200 my-6"></div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {seccion.campos.map((campo) => (
                  <div key={campo.id} className={`col-span-${campo.ancho || 1}`}>
                    {renderCampo(campo)}
                  </div>
                ))}
              </div>
            </Card>
          ))}

        <div className="flex flex-col sm:flex-row justify-end gap-4 mt-12">
          <button
            type="button"
            onClick={() => navigate('/dashboard/formulariosToDo')}
            disabled={isSubmitting}
            className="px-8 py-3 border-2 border-gray-300 rounded-xl text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200 disabled:opacity-50 font-medium text-lg shadow-sm hover:shadow-md"
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={Object.keys(formData).length === 0 || isSubmitting}
            className="px-8 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-xl hover:from-blue-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 disabled:opacity-50 font-medium text-lg shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
          >
            {isSubmitting ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Guardando...
              </span>
            ) : respuestaExistente ? 'Actualizar Respuestas' : 'Enviar Respuestas'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResponderFormulario;
