import React, { useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import { FiEye, FiFileText, FiPlus } from 'react-icons/fi';
import Modal from '../../../components/Modal/Modal';

interface Constancia {
    __typename?: string;
    codigo: string;
    archivo_url: string | null;
    fecha_archivo: string;
    fecha_pago: string;
    monto_pago: number | null;
    estado_verificacion: string | null;
    estado_orden: string;
    archivo_pago_id?: string | null;
    pago_orden_id?: string | null;
}

interface ListConstanciasProps {
    constancias: Constancia[];
    loading: boolean;
    onConstanciaSelect: (constancia: Constancia) => void;
}

const ListConstancias: React.FC<ListConstanciasProps> = ({ 
    constancias, 
    loading,
    onConstanciaSelect 
}) => {
    const [selectedConstancia, setSelectedConstancia] = useState<Constancia | null>(null);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [confirmConstancia, setConfirmConstancia] = useState<Constancia | null>(null);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const handleViewClick = (constancia: Constancia) => {
        setSelectedConstancia(constancia);
        setIsViewModalOpen(true);
    };

    const handleConfirmSelect = (constancia: Constancia) => {
        setConfirmConstancia(constancia);
        setIsConfirmModalOpen(true);
    };

    const handleConfirm = () => {
        if (confirmConstancia) {
            console.log('Constancia a enviar:', confirmConstancia); // Debug
            onConstanciaSelect({
                ...confirmConstancia,
                monto_pago: confirmConstancia.monto_pago || 0,
                archivo_pago_id: confirmConstancia.archivo_pago_id || '',
                pago_orden_id: confirmConstancia.pago_orden_id || ''
            });
            setIsConfirmModalOpen(false);
            setConfirmConstancia(null);
        }
    };

    const tableData = {
        filter: [true, true, true, true, true, true],
        headers: [
            "Código",
            "Fecha emisión",
            "Fecha pago",
            "Estado verificación vaucher",
            "Estado de pago",
            "Acciones"
        ],
        rows: constancias.map(constancia => ({
            Código: constancia.codigo || '-',
            "Fecha emisión": formatDate(constancia.fecha_archivo),
            "Fecha pago": formatDate(constancia.fecha_pago),
            "Estado verificación vaucher": (
                <span className={`font-medium px-2 py-1 rounded-full ${
                    constancia.estado_verificacion === null 
                        ? 'bg-gray-100 text-gray-800'
                        : constancia.estado_verificacion === 'APROBADO'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                }`}>
                    {constancia.estado_verificacion === null 
                        ? 'No hay voucher para verificar' 
                        : constancia.estado_verificacion}
                </span>
            ),
            "Estado de pago": (
                <span className={`font-medium px-2 py-1 rounded-full ${
                    constancia.estado_orden === 'pagado' || constancia.estado_orden === 'FINALIZADO'
                        ? 'bg-green-100 text-green-800'
                        : constancia.estado_orden === 'pendiente'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-red-100 text-red-800'
                }`}>
                    {constancia.estado_orden || 'No registrado'}
                </span>
            ),
            "Acciones": (
                <div className="flex items-center space-x-2 justify-center">
                    <button
                        onClick={() => handleViewClick(constancia)}
                        className="text-blue-500 hover:text-blue-700 transition-colors"
                        title="Ver detalle"
                    >
                        <FiEye size={18} />
                    </button>
                    <button
                        onClick={() => handleConfirmSelect(constancia)}
                        className="text-green-500 hover:text-green-700 transition-colors"
                        title="Agregar archivo"
                    >
                        <div className="relative">
                            <FiFileText size={18} />
                            <FiPlus className="w-2 h-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                        </div>
                    </button>
                </div>
            )
        }))
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-900"></div>
            </div>
        );
    }

    if (constancias.length === 0) {
        return (
            <div className="text-center text-gray-500 py-8">
                No hay constancias disponibles
            </div>
        );
    }

    return (
        <>
            <div className="min-h-[300px] max-h-[500px] overflow-auto">
                <TableComponent tableData={tableData} />
            </div>

            {/* Modal para ver la imagen */}
            <Modal
                isOpen={isViewModalOpen}
                onClose={() => setIsViewModalOpen(false)}
                title="Constancia de Pago"
            >
                <div className="flex justify-center items-center p-4">
                    {selectedConstancia?.archivo_url ? (
                        <img 
                            src={selectedConstancia.archivo_url} 
                            alt="Constancia de pago"
                            className="max-w-full max-h-[70vh] object-contain"
                        />
                    ) : (
                        <p className="text-gray-500">No hay imagen disponible</p>
                    )}
                </div>
            </Modal>

            {/* Modal de confirmación */}
            <Modal
                isOpen={isConfirmModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
            >
                <div className="p-6">
                    <p className="text-gray-700 text-lg text-center mb-6">
                        ¿Estás seguro de que deseas agregar la Constancia de Pago #{confirmConstancia?.codigo}?
                    </p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setIsConfirmModalOpen(false)}
                            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={handleConfirm}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ListConstancias;
