// Sidebar.tsx
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  FaUsers,
  FaUserShield,
  FaBriefcase,
  FaBoxOpen,
  FaTags,
  FaBalanceScale,
  FaLayerGroup,
  FaMoneyBillWave,
  FaShoppingCart,
  FaWarehouse,
  FaClipboardList,
  FaHandshake,
  FaWpforms
} from "react-icons/fa";
import { IoIosArchive } from "react-icons/io";
import { FiHome } from "react-icons/fi";
import { GiConcreteBag, GiPartyFlags } from "react-icons/gi";
import { MdViewKanban } from "react-icons/md";
import DropdownMenu from "./DropdownMenu";
import { motion } from "framer-motion";
import { FaListCheck, FaSackDollar } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

// Interface para representar un item del menú
interface MenuItem {
  to: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  text: string;
  slug?: string; // Slug para verificar permisos
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [isLocked, setIsLocked] = useState(false);

  // Obtener información del usuario desde Redux
  const user = useSelector((state: RootState) => state.user);

  // Función para verificar si un usuario tiene acceso a un slug específico
  const hasAccess = (slug: string): boolean => {
    if (!user.role || !user.role.menusPermissions) return false;

    const menuPermission = user.role.menusPermissions.find(
      (menu) => menu.menuID.slug.toLowerCase() === slug.toLowerCase()
    );

    return menuPermission?.permissions.ver || false;
  };

  const moduloOtrosItems: MenuItem[] = [
    // { to: "/dashboard/calendar", icon: FaRegCalendarAlt, text: "Forma Básica" },
    { to: "/dashboard/sorteo", icon: GiPartyFlags, text: "Sorteo" },
    //{ to: "/dashboard/almacenbetha", icon: GiFireworkRocket, text: "Alm2" },
  ];

  const moduloAdministracionItems: MenuItem[] = [
    {
      to: "/dashboard/empresa",
      icon: FaUsers,
      text: "Empresas",
      slug: "empresa",
    },
    {
      to: "/dashboard/obras",
      icon: GiConcreteBag,
      text: "Obras",
      slug: "obras",
    },
    {
      to: "/dashboard/roles",
      icon: FaUserShield,
      text: "Crear Roles",
      slug: "roles",
    },
    { to: "/dashboard/cargo", icon: FaBriefcase, text: "Cargo", slug: "cargo" },
    {
      to: "/dashboard/usuario",
      icon: FaUsers,
      text: "Usuario",
      slug: "usuario",
    },
    {
      to: "/dashboard/prefijos",
      icon: FaUsers,
      text: "Configuración de IDs",
      slug: "prefijos",
    },
  ];

  const moduloRecursosItems: MenuItem[] = [
    {
      to: "/dashboard/recurso",
      icon: FaBoxOpen,
      text: "Recursos",
      slug: "recurso",
    },
    {
      to: "/dashboard/tipoRecurso",
      icon: FaTags,
      text: "Tipo Recurso",
      slug: "tipoRecurso",
    },
    {
      to: "/dashboard/unidad",
      icon: FaBalanceScale,
      text: "Unidades",
      slug: "unidad",
    },
    {
      to: "/dashboard/clasificacionRecurso",
      icon: FaLayerGroup,
      text: "Clase de Recurso",
      slug: "clasificacionRecurso",
    },
    {
      to: "/dashboard/tipoCostoRecurso",
      icon: FaMoneyBillWave,
      text: "Tipo de Costo",
      slug: "tipoCostoRecurso",
    },
  ];

  const moduloCompras: MenuItem[] = [
    {
      to: "/dashboard/solicitudCompra",
      icon: FaSackDollar,
      text: "Solicitudes de Compra",
      slug: "solicitudCompra",
    },
    {
      to: "/dashboard/compra",
      icon: FaShoppingCart,
      text: "Cotizaciones",
      slug: "compra",
    },
    {
      to: "/dashboard/proveedor",
      icon: FaHandshake,
      text: "Proveedores",
      slug: "proveedor",
    },
    {
      to: "/dashboard/ordenCompra",
      icon: FaListCheck,
      text: "Ordenes De Compra",
      slug: "ordenCompra",
    },
    {
      to: "/dashboard/ordenServicio",
      icon: FaListCheck,
      text: "Ordenes De Servicio",
      slug: "ordenServicio",
    },
  ];

  const moduloPagos: MenuItem[] = [
    {
      to: "/dashboard/ordenPago",
      icon: FaListCheck,
      text: "Ordenes De Pagos",
      slug: "ordenPago",
    },
    {
      to: "/dashboard/listaOrdenPago",
      icon: FaClipboardList,
      text: "Lista de Pagos",
      slug: "listaOrdenPago",
    },
  ];

  const moduloPresupuestos: MenuItem[] = [
    {
      to: "/dashboard/presupuestos",
      icon: GiConcreteBag,
      text: "Datos Generales",
    },
    { to: "/dashboard/apu", icon: GiConcreteBag, text: "Analisis Precios Unitarios" },
  ];

  const moduloAlmacen: MenuItem[] = [
    {
      to: "/dashboard/bodegas",
      icon: FaWarehouse,
      text: "Gestion de Bodegas",
      slug: "bodegas",
    },
    {
      to: "/dashboard/transferencia",
      icon: FaClipboardList,
      text: "Movimientos RQ",
      slug: "transferencia",
    },
    {
      to: "/dashboard/almacen",
      icon: FaClipboardList,
      text: "Inventarios",
      slug: "almacen",
    },
    {
      to: "/dashboard/tipoAlmacen",
      icon: IoIosArchive,
      text: "Tipos De Almacen",
      slug: "tipoAlmacen",
    },
    {
      to: "/dashboard/empleados",
      icon: IoIosArchive,
      text: "Gestion de Personal",
      slug: "empleados",
    },
  ];

  const moduloRequerimiento: MenuItem[] = [
    {
      to: "/dashboard/requerimiento",
      icon: FaClipboardList,
      text: "Requerimiento",
      slug: "requerimiento",
    },
  ];

  const moduloComprobantes: MenuItem[] = [
    {
      to: "/dashboard/comprobantes",
      icon: FaClipboardList,
      text: "Comprobantes",
      slug: "comprobantes",
    },
  ];

  const moduloFormularios: MenuItem[] = [

    {
      to: "/dashboard/formularioDetalle",
      icon: FaWpforms,
      text: "Gestion de Formularios",
      slug: "formularios",
    },
    {
      to: "/dashboard/formulariosToDo",
      icon: FaWpforms,
      text: "Ver Formularios",
      slug: "formularios",
    },

  ];

  // Filtrar menús según permisos
  const getFilteredItems = (items: MenuItem[]): MenuItem[] => {
    return items.filter((item) => !item.slug || hasAccess(item.slug));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isSidebarOpen) {
      setIsLocked(false);
    } else if (!isHovered) {
      setIsLocked(true);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (!isMobile && isHovered && !isSidebarOpen) {
      toggleSidebar();
    }
  }, [isHovered, isSidebarOpen, toggleSidebar, isMobile]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
      if (isSidebarOpen && !isLocked) {
        toggleSidebar();
      }
    }
  };

  const activeStyle = "bg-sky-300 bg-opacity-50 rounded-xl py-2 pl-2 -m-2 pr-2";

  return (
    <motion.div
      initial={false}
      animate={{
        width:
          isSidebarOpen || (!isMobile && isHovered)
            ? window.innerWidth < 768
              ? "100%"
              : "16rem"
            : window.innerWidth < 768
              ? "0"
              : "4rem",
      }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className={`bg-black/50 backdrop-blur-lg select-none ${isSidebarOpen || (!isMobile && isHovered)
          ? "w-full md:w-64 lg:w-64"
          : "w-0 md:w-0 lg:w-16 hidden lg:block"
        }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <motion.nav
        className="flex flex-col items-start py-4 pl-2 h-full overflow-y-auto"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        <motion.div
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="flex flex-col w-[230px] mb-2"
        >
          <NavLink
            to="/dashboard"
            className={`${isSidebarOpen
                ? "w-[248px] justify-between"
                : "w-[58px] justify-center"
              } rounded-t-xl rounded-l-xl flex items-center mb-0 mt-1 pr-4 py-1.5 ${location.pathname === "/dashboard" ? activeStyle : ""
              }`}
          >
            <div className="flex items-center">
              <FiHome
                className={`w-6 h-6 ${location.pathname === "/dashboard"
                    ? "text-black"
                    : "text-white"
                  }`}
              />
              {(isSidebarOpen || (!isMobile && isHovered)) && (
                <span
                  className={`pl-3 ${location.pathname === "/dashboard"
                      ? "text-black"
                      : "text-white"
                    }`}
                >
                  Inicio
                </span>
              )}
            </div>
          </NavLink>
        </motion.div>

        {/* Kanban NavLink */}
        {hasAccess("kanban") && (
          <motion.div
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="flex flex-col w-[230px] mb-2"
          >
            <NavLink
              to="/dashboard/kanban"
              className={({ isActive }) =>
                `${isSidebarOpen
                  ? "w-[248px] justify-between"
                  : "w-[58px] justify-center"
                } rounded-t-xl rounded-l-xl flex items-center mb-0 mt-1 pr-4 py-1.5 ${isActive ? activeStyle : ""
                }`
              }
            >
              {({ isActive }) => (
                <div className="flex items-center">
                  <MdViewKanban
                    className={`w-6 h-6 ${isActive ? "text-black" : "text-white"
                      }`}
                  />
                  {(isSidebarOpen || (!isMobile && isHovered)) && (
                    <span
                      className={`pl-3 ${isActive ? "text-black" : "text-white"}`}
                    >
                      Kanban
                    </span>
                  )}
                </div>
              )}
            </NavLink>
          </motion.div>
        )}

        {/* Solo mostrar menús si el usuario tiene acceso */}
        {hasAccess("formularios") && (
          <DropdownMenu
            title="Formularios"
            items={getFilteredItems(moduloFormularios)}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}


        {hasAccess("requerimiento") && (
          <DropdownMenu
            title="Requerimiento"
            items={getFilteredItems(moduloRequerimiento)}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}

        {hasAccess("compra") && (
          <DropdownMenu
            title="Compras"
            items={getFilteredItems(moduloCompras)}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}

        {hasAccess("listaOrdenPago") && (
          <DropdownMenu
            title="Pagos"
            items={getFilteredItems(moduloPagos)}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}

        {hasAccess("almacen") && (
          <DropdownMenu
            title="Almacen"
            items={getFilteredItems(moduloAlmacen)}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}

        {hasAccess("presupuestos") && (
          <DropdownMenu
            title="Presupuestos"
            items={moduloPresupuestos}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}


        <DropdownMenu
          title="Otros"
          items={moduloOtrosItems}
          isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
          toggleSidebar={toggleSidebar}
          openDropdown={openDropdown}
          setOpenDropdown={setOpenDropdown}
        />

        {hasAccess("recurso") && (
          <DropdownMenu
            title="Recursos"
            items={getFilteredItems(moduloRecursosItems)}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}

        {hasAccess("comprobantes") && (
          <DropdownMenu
            title="Comprobantes"
            items={moduloComprobantes}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}

        {hasAccess("panelAdministrativo") && (
          <DropdownMenu
            title="Administración"
            items={getFilteredItems(moduloAdministracionItems)}
            isSidebarOpen={isSidebarOpen || (!isMobile && isHovered)}
            toggleSidebar={toggleSidebar}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        )}
      </motion.nav>
    </motion.div>
  );
};

export default Sidebar;
