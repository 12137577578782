import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchObraBodegas } from '../../slices/obraBodegaSlice';
import { updateObraBodegaRecurso, addObraBodegaRecurso } from '../../slices/obraBodegaRecursoSlice';
import Button from '../Buttons/Button';

interface TransferirRecursoProps {
  recurso: any; // ObraBodegaRecurso
  onClose: () => void;
  obraId: string;
}

const TransferirRecurso: React.FC<TransferirRecursoProps> = ({ recurso, onClose, obraId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [cantidad, setCantidad] = useState<number>(1);
  const [bodegaDestinoId, setBodegaDestinoId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  // Selectors
  const obraBodegas = useSelector((state: RootState) => state.obraBodega.obraBodegas)
    .filter(bodega => bodega.obra_id.id === obraId && bodega.id !== recurso.obra_bodega_id.id);
  
  const obraBodegaRecursosMap = useSelector((state: RootState) => state.obraBodegaRecurso.obraBodegaRecursosMap);
  
  // Obtener todas las bodegas de la obra actual
  useEffect(() => {
    if (obraBodegas.length === 0) {
      dispatch(fetchObraBodegas());
    }
  }, [dispatch, obraBodegas.length]);

  const handleCantidadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value > 0 && value <= recurso.cantidad) {
      setCantidad(value);
      setErrorMsg('');
    } else {
      setErrorMsg('La cantidad debe ser mayor a 0 y no exceder el stock disponible');
    }
  };

  const calcularCostoPromedio = (cantidadExistente: number, costoExistente: number, cantidadNueva: number, costoNuevo: number) => {
    const costoTotal = (cantidadExistente * costoExistente) + (cantidadNueva * costoNuevo);
    const cantidadTotal = cantidadExistente + cantidadNueva;
    return costoTotal / cantidadTotal;
  };

  const handleTransferir = async () => {
    if (cantidad <= 0 || cantidad > recurso.cantidad) {
      setErrorMsg('La cantidad debe ser mayor a 0 y no exceder el stock disponible');
      return;
    }

    if (!bodegaDestinoId) {
      setErrorMsg('Debe seleccionar una bodega de destino');
      return;
    }

    setIsLoading(true);
    try {
      // Actualizar el recurso de origen (disminuir cantidad)
      await dispatch(updateObraBodegaRecurso({
        updateObraBodegaRecursoId: recurso.id,
        cantidad: recurso.cantidad - cantidad
      }));

      // Buscar si el recurso ya existe en la bodega destino
      const recursos = obraBodegaRecursosMap[obraId] || [];
      const recursoEnDestino = recursos.find(r => 
        r.obra_bodega_id.id === bodegaDestinoId && 
        r.recurso_id.id === recurso.recurso_id.id
      );

      if (recursoEnDestino) {
        // Si existe, actualizar cantidad y calcular costo promedio
        const nuevoCosto = calcularCostoPromedio(
          recursoEnDestino.cantidad,
          recursoEnDestino.costo,
          cantidad,
          recurso.costo
        );
        
        await dispatch(updateObraBodegaRecurso({
          updateObraBodegaRecursoId: recursoEnDestino.id,
          cantidad: recursoEnDestino.cantidad + cantidad,
          costo: nuevoCosto
        }));
      } else {
        // Si no existe, crear nuevo recurso en bodega destino
        await dispatch(addObraBodegaRecurso({
          obraBodegaId: bodegaDestinoId,
          recursoId: recurso.recurso_id.id,
          cantidad: cantidad,
          costo: recurso.costo,
          estado: recurso.estado
        }));
      }

      onClose();
    } catch (error) {
      setErrorMsg('Error al transferir recurso');
      console.error('Error transferring resource:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Transferir Recurso</h2>
      
      <div className="mb-4">
        <p className="text-lg font-semibold">{recurso.recurso_id.nombre}</p>
        <p className="text-sm text-gray-600">Código: {recurso.recurso_id.codigo}</p>
        <p className="text-sm text-gray-600">Bodega actual: {recurso.obra_bodega_id.nombre}</p>
        <p className="text-sm text-gray-600">Stock disponible: {recurso.cantidad}</p>
        <p className="text-sm text-gray-600">Costo unitario: S/ {recurso.costo.toFixed(2)}</p>
      </div>
      
      <div className="mb-4">
        <label htmlFor="cantidad" className="block text-sm font-medium text-gray-700 mb-1">
          Cantidad a transferir
        </label>
        <input
          type="number"
          id="cantidad"
          value={cantidad}
          onChange={handleCantidadChange}
          min="1"
          max={recurso.cantidad}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      
      <div className="mb-4">
        <label htmlFor="bodegaDestino" className="block text-sm font-medium text-gray-700 mb-1">
          Bodega destino
        </label>
        <select
          id="bodegaDestino"
          value={bodegaDestinoId}
          onChange={(e) => setBodegaDestinoId(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="">Seleccione una bodega</option>
          {obraBodegas.map((bodega) => (
            <option key={bodega.id} value={bodega.id}>
              {bodega.nombre}
            </option>
          ))}
        </select>
      </div>

      {bodegaDestinoId && (
        <div className="mb-4">
          <h3 className="text-md font-semibold mb-2">Información de destino</h3>
          {(() => {
            const recursos = obraBodegaRecursosMap[obraId] || [];
            const recursoEnDestino = recursos.find(r => 
              r.obra_bodega_id.id === bodegaDestinoId && 
              r.recurso_id.id === recurso.recurso_id.id
            );
            
            if (recursoEnDestino) {
              const nuevoCosto = calcularCostoPromedio(
                recursoEnDestino.cantidad,
                recursoEnDestino.costo,
                cantidad,
                recurso.costo
              );
              
              return (
                <div className="p-3 bg-blue-50 rounded">
                  <p className="text-sm">Cantidad actual en destino: <span className="font-semibold">{recursoEnDestino.cantidad}</span></p>
                  <p className="text-sm">Costo actual en destino: <span className="font-semibold">S/ {recursoEnDestino.costo.toFixed(2)}</span></p>
                  <p className="text-sm mt-2">Después de la transferencia:</p>
                  <p className="text-sm">Nueva cantidad: <span className="font-semibold">{recursoEnDestino.cantidad + cantidad}</span></p>
                  <p className="text-sm">Nuevo costo (promedio): <span className="font-semibold">S/ {nuevoCosto.toFixed(2)}</span></p>
                </div>
              );
            } else {
              return (
                <div className="p-3 bg-yellow-50 rounded">
                  <p className="text-sm">El recurso no existe en la bodega destino.</p>
                  <p className="text-sm mt-2">Se creará un nuevo registro con:</p>
                  <p className="text-sm">Cantidad: <span className="font-semibold">{cantidad}</span></p>
                  <p className="text-sm">Costo: <span className="font-semibold">S/ {recurso.costo.toFixed(2)}</span></p>
                </div>
              );
            }
          })()}
        </div>
      )}
      
      {errorMsg && (
        <div className="mb-4 p-2 bg-red-100 text-red-700 rounded-md">
          {errorMsg}
        </div>
      )}
      
      <div className="flex justify-end space-x-2">
        <Button 
          text="Cancelar" 
          color="blanco" 
          onClick={onClose} 
        />
        <Button 
          text="Transferir" 
          color="azul" 
          onClick={handleTransferir} 
          disabled={isLoading || cantidad <= 0 || cantidad > recurso.cantidad || !bodegaDestinoId}
        />
      </div>
    </div>
  );
};

export default TransferirRecurso; 