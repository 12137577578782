import React, { useState } from 'react';
import Button from '../../components/Buttons/Button';

interface FormComponentProps {
  initialValues?: { nombre: string };
  onSubmit: (data: { nombre: string }) => void;
}

const TipoRecursoFormComponent: React.FC<FormComponentProps> = ({ initialValues, onSubmit }) => {
  const [nombre, setNombre] = useState(initialValues?.nombre || '');
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!nombre.trim()) {
      setError('El nombre es requerido');
      return;
    }
    
    setError('');
    onSubmit({ nombre });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-md mx-auto"
    >
      <div className="mb-4">
        <label htmlFor="nombre" className="block text-gray-700 text-sm font-bold mb-2">
          Nombre:
        </label>
        
        <input
          id="nombre"
          placeholder="Nombre del tipo de recurso"
          value={nombre}
          onChange={(e) => {
            setNombre(e.target.value);
            setError('');
          }}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        {error && (
          <p className="text-red-500 text-xs italic mt-1">{error}</p>
        )}
      </div>
      <div className="flex items-center justify-center mt-6">
        <Button
          text={initialValues ? 'Actualizar Tipo de Recurso' : 'Crear Tipo de Recurso'}
          color="verde"
          className="w-auto px-6 py-2 text-sm font-medium"
        />
      </div>
    </form>
  );
};

export default TipoRecursoFormComponent;