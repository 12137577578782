import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { FiFile, FiDownload } from 'react-icons/fi';

// Types
import { Unidad } from '../../types/ordenCompra.types';
import { OrdenCompra } from '../../types/ordenCompra';
// import type { UsuarioCargo } from '../../slices/usuarioSlice';
// Store
import { AppDispatch, RootState } from '../../store/store';
import { fetchOrdenCompraRecursosByOrdenId } from '../../slices/ordenCompraRecursosSlice';
// import { createOrdenServicioFecha } from '../../slices/ordenServicioFechaSlice';
import { fetchUsuariosAndCargos } from '../../slices/usuarioSlice';
import { updateOrdenCompra } from '../../slices/ordenCompraSlice';
import { getFilesByReferenciaService, ArchivoSustento } from '../../services/archivoSustentoService';
// import { updateCotizacion } from '../../slices/cotizacionSlice';
// import { createAprobacionGeneral } from '../../slices/aprobacionGeneralSlice';

// Components
import LoaderOverlay from '../../components/Loader/LoaderOverlay';
import ModalAlert from '../../components/Modal/ModalAlert';

// Utils
import { formatDate } from '../../components/Utils/dateUtils';

// interface AprobacionAsignada {
//   usuario: UsuarioCargo;
//   aprobacion_id: string;
// }

interface OrdenServicioDetalleProps {
  ordenCompra: OrdenCompra;
}

const OrdenServicioDetalleReadOnly: React.FC<OrdenServicioDetalleProps> = ({ ordenCompra }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { ordenCompraRecursosByOrdenId, loading } = useSelector((state: RootState) => state.ordenCompraRecursos);
  // const { usuariosCargo } = useSelector((state: RootState) => state.usuario);
  const unidades = useSelector((state: RootState) => state.unidad.unidades) as Unidad[];
  // const [archivos, setArchivos] = useState<{ [key: string]: File[] }>({});
  const [isParcial, setIsParcial] = useState(ordenCompra?.estado === 'parcial');
  const [fechasRecursos, setFechasRecursos] = useState<{ [key: string]: { inicio: string; fin: string } }>({});
  // const [gerentesAsignados] = useState<AprobacionAsignada[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedResponsable, setSelectedResponsable] = useState<string>('');
  const [archivosSustento, setArchivosSustento] = useState<{ [key: string]: ArchivoSustento[] }>({
    'servicio/informe': [],
    'servicio/valorizacion': [],
    'servicio/otros': [],
    'servicio/imagenes': []
  });
  const [loadingArchivos, setLoadingArchivos] = useState(false);

  // const gerentes = useMemo(() =>
  //   usuariosCargo.filter(usuario => usuario.cargo_id.gerarquia === 4),
  //   [usuariosCargo]
  // );

  const formatCurrency = (value: number) => `S/ ${value.toFixed(2)}`;

  // const handleFileChange = (index: number, files: FileList) => {
  //   setArchivos(prev => ({
  //     ...prev,
  //     [index]: [...(prev[index] || []), ...Array.from(files)]
  //   }));
  // };

  // const handleRemoveFile = (index: number, fileIndex: number) => {
  //   setArchivos(prev => ({
  //     ...prev,
  //     [index]: prev[index].filter((_, idx) => idx !== fileIndex)
  //   }));
  // };

  const handleFechaChange = (recursoId: string, tipo: 'inicio' | 'fin', value: string) => {
    setFechasRecursos(prev => ({
      ...prev,
      [recursoId]: {
        ...prev[recursoId],
        [tipo]: value
      }
    }));
  };

  // const gerentesDisponibles = gerentes.filter(
  //   gerente => !gerentesAsignados.some(
  //     asignado => asignado.usuario.id === gerente.id
  //   )
  // );

  // const handleParcialChange = async (isChecked: boolean) => {
  //   if (!isChecked || !ordenCompra?.id) {
  //     setIsParcial(false);
  //     return;
  //   }

  //   setIsModalOpen(true);
  // };

  const handleConfirmParcial = async () => {
    try {
      if (ordenCompra?.id) {
        await dispatch(updateOrdenCompra({
          id: ordenCompra.id,
          estado: 'parcial' //pendiente, parcial, proceso, completado
        }));
        setIsParcial(true);
      }
    } catch (error) {
      console.error('Error al guardar:', error);
      setIsParcial(false);
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleCancelParcial = () => {
    setIsModalOpen(false);
    setIsParcial(false);
  };

  // const handleResponsableChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedResponsable(e.target.value);
  // };

  // const handleSubmit = async () => {
  //   try {
  //     if (!selectedResponsable) {
  //       alert('Por favor seleccione un responsable de aprobación');
  //       return;
  //     }

  //     if (ordenCompra?.id) {
  //       await dispatch(updateOrdenCompra({
  //         id: ordenCompra.id,
  //         estado: 'proceso' //pendiente, parcial, proceso, completado
  //       }));
  //     }

  //     if (isParcial && ordenCompra?.id) {
  //       // Crear un array con las fechas de cada recurso
  //       const fechasPromises = Object.entries(fechasRecursos).map(([recursoId, fechas]) => {
  //         return dispatch(createOrdenServicioFecha({
  //           ordenServicioRecursoId: recursoId,
  //           fechaInicio: new Date(fechas.inicio),
  //           fechaFin: new Date(fechas.fin)
  //         }));
  //       });

  //       // Esperar a que todas las fechas se guarden
  //       await Promise.all(fechasPromises);
  //     }

  //     // Crear la aprobación general
  //     if (ordenCompra?.id) {
  //       await dispatch(createAprobacionGeneral({
  //         referencia_id: ordenCompra.id,
  //         referencia: "servicio",
  //         usuario_id: selectedResponsable,
  //         fecha: new Date()
  //       }));
  //     }

  //     setIsModalOpen(false);
  //   } catch (error) {
  //     console.error('Error al guardar:', error);
  //   }
  // };

  const cargarArchivos = async () => {
    if (!ordenCompra?.id) return;

    setLoadingArchivos(true);
    try {
      const tipos = ['servicio/informe', 'servicio/valorizacion', 'servicio/otros', 'servicio/imagenes'];
      const archivosPromises = tipos.map(tipo =>
        getFilesByReferenciaService(ordenCompra.id, tipo)
          .then(archivos => ({ tipo, archivos }))
      );

      const resultados = await Promise.all(archivosPromises);

      const archivosAgrupados = resultados.reduce((acc, { tipo, archivos }) => ({
        ...acc,
        [tipo]: archivos
      }), {});

      setArchivosSustento(archivosAgrupados);
    } catch (error) {
      console.error('Error al cargar archivos:', error);
    } finally {
      setLoadingArchivos(false);
    }
  };

  useEffect(() => {
    if (ordenCompra?.id) {
      dispatch(fetchOrdenCompraRecursosByOrdenId(ordenCompra.id));
      setIsParcial(ordenCompra.estado === 'parcial');
      dispatch(fetchUsuariosAndCargos());
      cargarArchivos();
    }
  }, [ordenCompra?.id, ordenCompra?.estado, dispatch]);

  if (!ordenCompra) return <LoaderOverlay message="Cargando Información" />;

  const totales = ordenCompraRecursosByOrdenId.reduce((acc, item) => ({
    real: acc.real + (item.costo_real * item.cantidad),
    aprox: acc.aprox + (item.costo_aproximado * item.cantidad)
  }), { real: 0, aprox: 0 });

  const renderInformacionGeneral = () => (
    <div className="bg-white p-6 rounded-lg border border-gray-200">
      <div className='flex justify-end'>
        <span className={`bg-gray-100 px-2 py-1 rounded-md font-medium font-bold ps-1 capitalize ${ordenCompra.estado === 'proceso' ? 'text-green-500' :
          ordenCompra.estado === 'completada' ? 'text-blue-500' :
            'text-gray-500'
          }`}>
          {ordenCompra.estado}
        </span>
      </div>

      <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">
        Información General
      </h3>
      <div className="text-xs grid grid-cols-2 gap-4">

        <div className='flex flex-col gap-1'>

          <div className="flex justify-between">
            <span className="text-gray-600">Código:</span>
            <span className="font-medium">{ordenCompra.codigo_orden}</span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Emisión:</span>
            <span className="font-medium">{formatDate(ordenCompra?.fecha_ini || "", 'dd/mm/yyyy')}</span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Entrega Deseable:</span>
            <span className="font-medium">{formatDate(ordenCompra?.fecha_fin || "", 'dd/mm/yyyy')}</span>
          </div>

          {/* <div className="flex justify-between">
            <span className="text-gray-600">Obra:</span>
            <span className="font-medium">
              -
            </span>
          </div> */}

        </div>

        <div className='flex flex-col gap-1'>

          <div className="flex justify-between">
            <span className="text-gray-600">Moneda:</span>
            <span className="font-medium">
              S/
            </span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Monto total:</span>
            <span className="font-medium">{formatCurrency(totales.real)}</span>
          </div>

          {/* <div className="flex justify-between">
            <span className="text-gray-600">Usuario:</span>
            <span className="font-medium"></span>
          </div> */}


        </div>

      </div>
    </div>
  );

  const renderTablaRecursos = () => (
    <div className="bg-white p-6 rounded-lg border border-gray-200">
      <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Servicios</h3>

      {/* <div className='flex items-center gap-2 mb-3'>
        <input
          type="checkbox"
          checked={isParcial}
          onChange={(e) => handleParcialChange(e.target.checked)}
        />
        <label className='text-sm font-semibold text-red-600'>Parcial</label>
      </div> */}

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Código</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Nombre</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Unidad</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Cantidad</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Costo Real</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Costo Aprox.</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Estado</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Fecha Inicio</th>
              <th className="px-3 text-center text-xs text-semibold text-gray-600 capitalize">Fecha Fin</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {ordenCompraRecursosByOrdenId.map((recurso) => (
              <tr key={recurso.id} className="hover:bg-gray-50 text-xs">
                <td className="px-4 py-2 text-center whitespace-nowrap font-medium text-gray-900">{recurso.id_recurso.codigo}</td>
                <td className="px-4 py-2 text-gray-700">{recurso.id_recurso.nombre}</td>
                <td className="px-4 py-2 text-center text-gray-600">
                  {unidades.find(u => u.id === recurso.id_recurso.unidad_id)?.nombre || 'N/A'}
                </td>
                <td className="px-4 py-2 text-center text-gray-700">{recurso.cantidad}</td>
                <td className="px-4 py-2 text-center font-medium text-gray-900">{formatCurrency(recurso.costo_real)}</td>
                <td className="px-4 py-2 text-center text-gray-600">{formatCurrency(recurso.costo_aproximado)}</td>
                <td className="px-4 py-2 text-center">
                  <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                    ${recurso.estado === 'pendiente' ? 'bg-yellow-100 text-yellow-800' :
                      recurso.estado === 'completado' ? 'bg-green-100 text-green-800' :
                        'bg-gray-100 text-gray-800'}`}>
                    {recurso.estado}
                  </span>
                </td>
                <td className="px-4 py-2 text-center">
                  <input
                    type="date"
                    className='w-full border border-gray-300 rounded-md p-1'
                    value={fechasRecursos[recurso.id]?.inicio || formatDate(ordenCompra?.fecha_ini, 'yyyy-mm-dd')}
                    disabled={!isParcial}
                    onChange={(e) => handleFechaChange(recurso.id, 'inicio', e.target.value)}
                  />
                </td>
                <td className="px-4 py-2 text-center">
                  <input
                    type="date"
                    className='w-full border border-gray-300 rounded-md p-1'
                    value={fechasRecursos[recurso.id]?.fin || formatDate(ordenCompra?.fecha_fin, 'yyyy-mm-dd')}
                    disabled={!isParcial}
                    onChange={(e) => handleFechaChange(recurso.id, 'fin', e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="bg-gray-50 font-medium text-xs">
              <td colSpan={4} className="px-4 py-3 text-right text-gray-700">Totales:</td>
              <td className="px-4 py-3 text-center text-gray-900">{formatCurrency(totales.real)}</td>
              <td className="px-4 py-3 text-center text-gray-600">{formatCurrency(totales.aprox)}</td>
              <td colSpan={3}></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );

  const renderPagos = () => (
    <div className="bg-white p-6 rounded-lg border border-gray-200">
      <h3 className="text-base font-semibold text-gray-800 mb-4 pb-2 border-b">Archivos de sustento</h3>

      {loadingArchivos ? (
        <div className="flex justify-center items-center p-4">
          <span className="text-gray-500">Cargando archivos...</span>
        </div>
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-3'>
          {[
            { title: 'Informe', tipo: 'servicio/informe' },
            { title: 'Valorización', tipo: 'servicio/valorizacion' },
            { title: 'Otros Archivos', tipo: 'servicio/otros' },
            { title: 'Imágenes', tipo: 'servicio/imagenes' }
          ].map(({ title, tipo }) => (
            <div key={tipo} className='w-full'>
              <div className="flex flex-col gap-2">
                <div className="p-4 border border-gray-200 rounded-lg">
                  <h4 className="text-sm font-semibold text-gray-700 mb-2">{title}</h4>
                  {archivosSustento[tipo]?.length > 0 ? (
                    <div className="max-h-48 overflow-y-auto">
                      <ul className="space-y-2">
                        {archivosSustento[tipo].map((archivo) => (
                          <li key={archivo.id} className="flex items-center justify-between p-2 bg-gray-50 rounded-md text-xs">
                            <div className="flex items-center space-x-2">
                              <FiFile className="text-blue-500" />
                              <span className="truncate max-w-[150px]" title={archivo.url.split('/').pop()}>
                                {archivo.url.split('/').pop()}
                              </span>
                            </div>
                            <a
                              href={archivo.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="p-1 text-blue-500 hover:text-blue-700"
                              title="Descargar archivo"
                            >
                              <FiDownload />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p className="text-xs text-gray-500 text-center py-2">No hay archivos</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="max-w-7xl mx-auto bg-gray-50 p-6"
    >
      <ModalAlert
        isOpen={isModalOpen}
        title="Confirmar cambio a estado parcial"
        message="Esta acción actualizará el estado de la orden de servicio a parcial"
        onConfirm={handleConfirmParcial}
        onCancel={handleCancelParcial}
        variant="blue"
      />
      {loading ? (
        <LoaderOverlay message="Cargando Detalles" />
      ) : (
        <div className='flex flex-col gap-4'>
          {renderInformacionGeneral()}
          {renderTablaRecursos()}
          {renderPagos()}

          {/* header */}
          <div className='flex justify-end gap-1'>
            <button
              className='bg-gray-200 text-dark px-4 py-2 rounded-md hover:bg-blue-600'
              onClick={() => window.history.back()}
            >
              Salir
            </button>
            {/* <button 
              className='bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600'
              onClick={handleSubmit}
            >
              Enviar
            </button> */}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default OrdenServicioDetalleReadOnly;