import React from 'react';
import AlmacenBodega from './AlmacenBodega';

export const AlmacenBoardPage: React.FC = () => {

  // Estados principales

  return (
    <div>
      {/* Todo esto es la tabla */}
        (<AlmacenBodega />)
    </div>
  );
};

export default AlmacenBoardPage;

