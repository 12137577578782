import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginUserService } from '../services/userService';

interface Permission {
    ver: boolean;
    crear: boolean;
    editar: boolean;
    eliminar: boolean;
}

interface Menu {
    id: string;
    nombre: string;
    slug: string;
    posicion: number;
}

interface MenuPermission {
    menuID: Menu;
    permissions: Permission;
}

interface Role {
    id: string;
    nombre: string;
    descripcion: string;
    menusPermissions: MenuPermission[];
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
}

export interface User {
    id: string;
    token: string;
    usuario: string;
    nombresA: string;
    role: Role | null;
}

const initialState: User = {
    id: '',
    token: '',
    usuario: '',
    nombresA: '',
    role: null
};

// Get initial state from localStorage
const getStoredState = (): User => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        return JSON.parse(storedUser);
    }
    return initialState;
};

export const loginUser = createAsyncThunk(
    'user/login',
    async ({ username, password }: { username: string; password: string }, { rejectWithValue }) => {
        try {
            const loginData = await loginUserService(username, password);
            return loginData;
        } catch (error: unknown) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('An unknown error occurred');
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState: getStoredState(),
    reducers: {
        setUser: (_state, action: PayloadAction<User>) => {
            return { ...action.payload };
        },
        clearUser: () => {
            localStorage.removeItem('user');
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state, action: PayloadAction<User>) => {
            const { usuario, id, token, nombresA, role } = action.payload;
            state.id = id;
            state.usuario = usuario;
            state.token = token;
            state.nombresA = nombresA;
            state.role = role;
         
            localStorage.setItem('user', JSON.stringify({ id, usuario, token, nombresA, role }));
        });
    },
});

export const { setUser, clearUser } = userSlice.actions;
export const userReducer = userSlice.reducer;